import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import React, { useState } from "react";
import "../../CSS/ConnectPeoplePopup.css";
// import { Card, Container, Grid } from '@mui/material';
import PriceChangeIcon from "@mui/icons-material/PriceChange";
// import CalendarMonthRoundedIcon from "@mui/icons-material/CalendarMonthRounded";
import ModeOfTravelRoundedIcon from "@mui/icons-material/ModeOfTravelRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
// import FlightRoundedIcon from "@mui/icons-material/FlightRounded";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import PlaceIcon from "@mui/icons-material/Place";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

function ConnectPeoplePopup({
  onChildClick,
  connectUserData,
  page,
  connectinvite,
  userdetails,
  item,
}) {
  console.log(connectUserData, "connectUserData");
  console.log(userdetails, "im the user ");
  // console.log(item, "im the invite item ");

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  console.log("user_id", user.user_id);

  const [deleteopen, setdeleteOpen] = React.useState(false);

  const handleClickOpen = () => {
    setdeleteOpen(true);
  };

  const handledeleteClose = () => {
    setdeleteOpen(false);
  };
  const [receiveddeleteAgree, setreceivedDeleteAgree] = useState(false);
  const [receiveddeleteId, setreceivedDeleteId] = useState("");

  const [invitebtn, setinvitebtn] = useState([]);

  const acceptinvitationbutton = () => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .post(`${domain}/send-invitation/`, {
        from_user_id: user.user_id,
        to_user_id: connectUserData.user_details.id,
        to_trip_id: connectUserData.id,
      })
      .then((response) => {
        console.log(response, "invite btn trip response");
        // setinvitebtn(response.data);

        if (response.data) {
          console.log(response.data, "invite btn trip response data");
          setinvitebtn(response.data);
          Swal.fire({
            // position: 'center',
            customClass: {
              popup: "popup-class",
            },
            icon: "success",
            iconColor: "#1ec28b",
            title: "successfully invited",
            showCloseButton: true,
            showConfirmButton: false,
          });
        }

        // if (response.data) {
        //   setdeleteOpen(false);

        //   console.log(response.data, " response invite profile");
        //   setreceivedDeleteAgree(true);
        //   Swal.fire({
        //     // position: 'center',
        //     customClass: {
        //       popup: "my-swal",
        //     },
        //     icon: "success",
        //     title: "successfully invited",
        //     showCloseButton: true,
        //     showConfirmButton: false,
        //   });
        // }
      })
      .catch((error) => {
        // Error
        // console.log("status : ",error.response.status);
        // console.log("data : ",error.response.data);
        if (error.response.status === 400) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log("data : ",error.response.data);
          console.log("data : ", error.response.data.message);
          Swal.fire({
            // title: "Error",
            confirmButtonColor: "#ff8d8d",
            text: error.response.data.message,
            type: "error",
          });
          // console.log("status : ",error.response.status);
          // console.log("headers : ",error.response.headers);
        }
      });
  };

  // const deleteinvitation = () => {
  //   //  setOpen(false)
  //   console.log(receiveddeleteId, "card id");
  //   if (receiveddeleteId !== "") {
  //     axios
  //       .delete(
  //         ` http://ec2-54-185-6-32.us-west-2.compute.amazonaws.com:81/delete-invitation/${receiveddeleteId}/`
  //       )
  //       .then((response) => {
  //         console.log(response, "update delete response");

  //         const newList = recievedinvite.filter((card) => {
  //           return card.connection_id !== receiveddeleteId;
  //         });

  //         console.log(newList, "FILTERED CARD...");
  //         setrecievedinvite(newList);

  //         if (response.data) {
  //           setdeleteOpen(false);

  //           console.log(response.data, " response delete profile");
  //           setreceivedDeleteAgree(true);
  //           Swal.fire({
  //             // position: 'center',
  //             customClass: {
  //               popup: "my-swal",
  //             },
  //             icon: "success",
  //             title: "successfully Deleted",
  //             showCloseButton: true,
  //             showConfirmButton: false,
  //           });
  //         }
  //       })
  //       .catch((error) => {
  //         // Error
  //         if (error.response.status === 400) {
  //           console.log(error.response.data, "error");
  //           console.log(error.response.status);
  //           // console.log(error.response.headers);
  //           // setResponseMessage(null);
  //         }
  //       });
  //   }
  // };

  // useEffect(() => {
  //   axios
  //     .post(
  //       `http://ec2-54-185-6-32.us-west-2.compute.amazonaws.com:81/send-invitation/`
  //     )
  //     .then((response) => {
  //       console.log(response, "invite btn trip response");

  //       if (response.data) {
  //         console.log(response.data, "invite btn trip response data");
  //         setinvitebtn(response.data);
  //       }
  //     })
  //     .catch((error) => {
  //       // Error
  //       // console.log("status : ",error.response.status);
  //       // console.log("data : ",error.response.data);
  //       if (error.response.status === 400) {
  //         // The request was made and the server responded with a status code
  //         // that falls out of the range of 2xx
  //         // console.log("data : ",error.response.data);
  //         console.log("data : ", error.response.data.message);
  //         // console.log("status : ",error.response.status);
  //         // console.log("headers : ",error.response.headers);
  //       }
  //     });
  // }, []);

  return (
    // <div className="modalBackground">
    //   <div
    //     className={
    //       page !== "pasttrip" ? "modalContainer" : "modalContainerPasttrip"
    //     }
    //   >
    //     <div className="titleCloseBtn">
    //       <button className="closeIcon" onClick={onChildClick}>
    //         X
    //       </button>
    //     </div>
    //     <div className="popupCatherineImageContainer">
    //       <img
    //         src={
    //           connectUserData.photo
    //             ? connectUserData.photo
    //             : "./Assets/Images/human_dummy_image.jpg"
    //         }
    //         className="popupCatherine"
    //       ></img>
    //     </div>
    //     <div className="titleCatherine">
    //       <h1>{connectUserData.name}</h1>
    //     </div>
    //     <Grid container spacing={2} className="ageAndGenderContainer">
    //       <Grid item lg={2} sm={2} md={2} className="ageGroupPopup">
    //         Age Group : {connectUserData.age_group}
    //       </Grid>
    //       <Grid item lg={2} sm={2} md={2}>
    //         Gender : {connectUserData.gender}
    //       </Grid>

    //       <>
    //         {page == "invitationconnected" ? (
    //           <div>
    //             {" "}
    //             <Grid item lg={2} sm={2} md={2} className="ageGroupPopup">
    //               Mobile:
    //               {userdetails.country_code}&nbsp;{userdetails.mobile_number}
    //             </Grid>
    //             <Grid item lg={2} sm={2} md={2}>
    //               Email:
    //               {userdetails.email_id}
    //             </Grid>
    //           </div>
    //         ) : null}
    //       </>
    //     </Grid>

    //     <div className="PopupflightTakeoff">
    //       <div className="dashedImagediv">
    //         <img
    //           src="./Assets/Images/Image_1.jpg "
    //           className="curvedDashedImage"
    //         ></img>
    //       </div>
    //     </div>
    //     <div className="FlightTakeoffOutlinedContainer">
    //       <FlightTakeoffOutlinedIcon className="FlightTakeoffOutlinedIconpopup" />
    //     </div>
    //     <Grid container spacing={2} className="locationDestinationIcons">
    //       <Grid item lg={2}>
    //         <LocationOnRoundedIcon className="locationDestinationMapIcon1" />
    //       </Grid>
    //       <Grid item lg={2}>
    //         <LocationOnRoundedIcon className="locationDestinationMapIcon2" />
    //       </Grid>
    //     </Grid>
    //     <Grid container spacing={2} className="locationDestinationText">
    //       <Grid item lg={2} className="flyingPheonix">
    //         <div className="flyingFrom">Flying From</div>
    //         <div className="Pheonix">
    //           {connectUserData.flying_from.split(",")[0]}
    //         </div>
    //       </Grid>
    //       {connectUserData.transit1 && connectUserData.transit2 ? (
    //         <Grid item lg={2} className="TransitOneContainer">
    //           <div className="Transit1">Transit 1</div>
    //           <div className="LA1">{connectUserData.transit1}</div>
    //         </Grid>
    //       ) : (
    //         <Grid
    //           item
    //           lg={2}
    //           className="TransitOneContainer"
    //           style={{ visibility: "hidden" }}
    //         >
    //           <div className="Transit1">Transit 1</div>
    //           <div className="LA1">Transit 1</div>
    //         </Grid>
    //       )}
    //       {(connectUserData.transit2 && connectUserData.transti3) ||
    //       connectUserData.transit1 ? (
    //         <Grid item lg={2} className="TransitOneContainer">
    //           <div className="Transit2">
    //             {connectUserData.transit2 ? "Transit 2" : "Transit 1"}
    //           </div>
    //           <div className="LA2">
    //             {connectUserData.transit2
    //               ? connectUserData.transit2
    //               : connectUserData.transit1}
    //           </div>
    //         </Grid>
    //       ) : (
    //         <Grid
    //           item
    //           lg={2}
    //           className="TransitOneContainer"
    //           style={{ visibility: "hidden" }}
    //         >
    //           <div className="Transit2">Transit 2</div>
    //           <div className="LA2">Transit 2</div>
    //         </Grid>
    //       )}
    //       {connectUserData.transit3 && connectUserData.transit2 ? (
    //         <Grid item lg={2} className="TransitOneContainer">
    //           <div className="Transit3">
    //             {connectUserData.transit2 && connectUserData.transit1
    //               ? "Transit 3"
    //               : connectUserData.transit2
    //               ? "Transit 2"
    //               : "Transit 1"}
    //           </div>
    //           <div className="LA3">
    //             {connectUserData.transit2 && connectUserData.transit1
    //               ? connectUserData.transit3
    //               : connectUserData.transit2
    //               ? connectUserData.transit2
    //               : null}
    //           </div>
    //         </Grid>
    //       ) : (
    //         <Grid
    //           item
    //           lg={2}
    //           className="TransitOneContainer"
    //           style={{ visibility: "hidden" }}
    //         >
    //           <div className="Transit3">Transit 3</div>
    //           <div className="LA3">Transit 3</div>
    //           {/* <div className="LAX">(LAX)</div> */}
    //         </Grid>
    //       )}
    //       {connectUserData.transit4 ? (
    //         <Grid item lg={2} className="TransitOneContainer">
    //           <div className="Transit4">Transit 4</div>
    //           <div className="LA4">{connectUserData.transit4}</div>
    //         </Grid>
    //       ) : (
    //         <Grid
    //           item
    //           lg={2}
    //           className="TransitOneContainer"
    //           style={{ visibility: "hidden" }}
    //         >
    //           <div className="Transit4">Transit 4</div>
    //           <div className="LA4">Transit 4</div>
    //         </Grid>
    //       )}

    //       <Grid item lg={2} className="destinationChennaiContainer">
    //         <div className="connectPopupDestination">Destination</div>
    //         <div className="connectPopupChennai ">
    //           {connectUserData.destination.split(",")[0]}
    //         </div>
    //       </Grid>
    //     </Grid>

    //     <Grid container spacing={2} className="airlineIndigoContainer">
    //       {connectUserData.airline ? (
    //         <Grid item lg={2}>
    //           <Grid className="airlineText1">Airline</Grid>
    //           <div style={{ marginLeft: "-47px" }}>
    //             <img
    //               src="./Assets/Images/Image_left_dotted_arrow.webp"
    //               className=""
    //               style={{ width: "44px" }}
    //             ></img>
    //           </div>
    //           <Box className="indigoBox1">
    //             <div className="indigoText">{connectUserData.airline}</div>
    //           </Box>
    //           <div style={{ marginLeft: "80px", marginTop: "-60px" }}>
    //             <img
    //               src="./Assets/Images/Image_right_dotted_arrow.webp"
    //               className=""
    //               style={{ width: "44px" }}
    //             ></img>
    //           </div>
    //         </Grid>
    //       ) : null}
    //       {connectUserData.airline1 ? (
    //         <Grid item lg={2} className="stopOneContainer">
    //           <Grid className="airlineText2">Airline</Grid>
    //           <div style={{ marginLeft: "-28px" }}>
    //             <img
    //               src="./Assets/Images/Image_left_dotted_arrow.webp"
    //               className=""
    //               style={{ width: "44px" }}
    //             ></img>
    //           </div>
    //           <Box className="indigoBox2">
    //             <div className="indigoText">{connectUserData.airline1}</div>
    //           </Box>
    //           <div style={{ marginLeft: "100px", marginTop: "-60px" }}>
    //             <img
    //               src="./Assets/Images/Image_right_dotted_arrow.webp"
    //               className=""
    //               style={{ width: "44px" }}
    //             ></img>
    //           </div>
    //         </Grid>
    //       ) : null}
    //       {connectUserData.airline2 ? (
    //         <Grid item lg={2} className="stopOneContainer">
    //           <Grid className="airlineText3">Airline</Grid>
    //           <div style={{ marginLeft: "-8px" }}>
    //             <img
    //               src="./Assets/Images/Image_left_dotted_arrow.webp"
    //               className=""
    //               style={{ width: "44px" }}
    //             ></img>
    //           </div>
    //           <Box className="indigoBox3">
    //             <div className="indigoText">{connectUserData.airline2}</div>
    //           </Box>
    //           <div style={{ marginLeft: "118px", marginTop: "-60px" }}>
    //             <img
    //               src="./Assets/Images/Image_right_dotted_arrow.webp"
    //               className=""
    //               style={{ width: "44px" }}
    //             ></img>
    //           </div>
    //         </Grid>
    //       ) : null}
    //       {connectUserData.airline3 ? (
    //         <Grid item lg={2} className="stopOneContainer">
    //           <Grid className="airlineText4">Airline</Grid>
    //           <div style={{ marginLeft: "10px" }}>
    //             <img
    //               src="./Assets/Images/Image_left_dotted_arrow.webp"
    //               className=""
    //               style={{ width: "44px" }}
    //             ></img>
    //           </div>
    //           <Box className="indigoBox4">
    //             <div className="indigoText">{connectUserData.airline3}</div>
    //           </Box>
    //           <div style={{ marginLeft: "134px", marginTop: "-60px" }}>
    //             <img
    //               src="./Assets/Images/Image_right_dotted_arrow.webp"
    //               className=""
    //               style={{ width: "44px" }}
    //             ></img>
    //           </div>
    //         </Grid>
    //       ) : null}
    //       {connectUserData.airline4 ? (
    //         <Grid item lg={2} className="stopOneContainer">
    //           <Grid className="airlineText5">Airline</Grid>
    //           <div style={{ marginLeft: "25px" }}>
    //             <img
    //               src="./Assets/Images/Image_left_dotted_arrow.webp"
    //               className=""
    //               style={{ width: "44px" }}
    //             ></img>
    //           </div>
    //           <Box className="indigoBox5">
    //             <div className="indigoText">{connectUserData.airline4}</div>
    //           </Box>
    //           <div style={{ marginLeft: "151px", marginTop: "-60px" }}>
    //             <img
    //               src="./Assets/Images/Image_right_dotted_arrow.webp"
    //               className=""
    //               style={{ width: "44px" }}
    //             ></img>
    //           </div>
    //         </Grid>
    //       ) : null}
    //     </Grid>
    //     <Grid container className="dateRangeOuterContainer">
    //       <Box className="dateBoxContainer">
    //         <Container
    //           className={
    //             connectUserData.category == 1
    //               ? "boxInnerContainerpopupU1"
    //               : "boxInnerContainerpopupU2"
    //           }
    //         >
    //           <Grid container spacing={2} className="OuterContainer">
    //             <Grid item lg={5} xs={5} sm={5} className="iconContainer">
    //               <Icon
    //                 icon="clarity:date-outline-badged"
    //                 className="CalendarMonthRoundedIcon"
    //               />
    //               <div className="dateRangepopup">
    //                 {connectUserData.departing_on
    //                   ? "Departing Date"
    //                   : "Date Range"}
    //               </div>
    //               <div className="dateBetweenpopup">
    //                 {" "}
    //                 {connectUserData.departing_on
    //                   ? connectUserData.departing_on
    //                   : `${connectUserData.date_range_from} to ${connectUserData.date_range_to}`}{" "}
    //               </div>
    //             </Grid>
    //             {connectUserData.category == 1 && (
    //               <Grid item lg={2}>
    //                 <div className="verticleLine"></div>
    //               </Grid>
    //             )}
    //             {connectUserData.category == 1 && (
    //               <Grid item lg={5} xs={5} sm={5}>
    //                 <PriceChangeIcon className="PriceChangeIconConnect" />
    //                 <div className="tippopup">Tip Expected</div>
    //                 <div className="USDpopup">
    //                   {connectUserData.tip_expected}
    //                 </div>
    //               </Grid>
    //             )}
    //           </Grid>
    //         </Container>
    //       </Box>
    //     </Grid>
    //     <Grid container spacing={2} className="iamTextContainer">
    //       {connectUserData.category == 2 ? (
    //         <Grid item lg={6} xs={6} sm={6} className="iamLookingText">
    //           I am looking for a travel companion to explore the various
    //           <div className="placesInChennai">
    //             places in {connectUserData.destination.split(",")[0]}.
    //           </div>
    //         </Grid>
    //       ) : (
    //         <Grid item lg={6} xs={6} sm={6} className="iamLookingText">
    //           I am a student travelling to{" "}
    //           {connectUserData.destination.split(",")[0]} and can be your travel
    //           companion.
    //           {/* <div className="placesInChennai">places in {connectUserData.destination.split(',')[0]}.</div> */}
    //         </Grid>
    //       )}
    //     </Grid>
    //     <Grid item className="iconsOuterContainer1">
    //       <Grid container>
    //         <Grid
    //           sm={6}
    //           lg={3}
    //           md={12}
    //           xs={6}
    //           item
    //           style={{ color: "#1EC28B " }}
    //           className="ModeOfTravelRoundedIconContainer"
    //         >
    //           <ModeOfTravelRoundedIcon className="ModeOfTravelRoundedIcon" />
    //           <div className="tripName">Trip Name</div>
    //           {/* <div className="chennaiExplore">Chennai </div> */}
    //           <div className="Explore"> {connectUserData.trip_name}</div>
    //         </Grid>
    //         <Grid
    //           sm={6}
    //           lg={3}
    //           md={12}
    //           xs={6}
    //           item
    //           style={{ color: "#1EC28B ", marginRight: "-25px" }}
    //         >
    //           <PlaceIcon className="PlaceIcon" />
    //           <div className="meetupLocation">Meetup Location</div>
    //           <div className="airportGate">
    //             {connectUserData.meetup_location}
    //           </div>
    //         </Grid>

    //         <Grid
    //           item
    //           sm={6}
    //           lg={3}
    //           md={12}
    //           xs={6}
    //           // style={{ color: "#1EC28B " }}
    //           className="preferedLanguageGrid"
    //         >
    //           <LanguageRoundedIcon className="LanguageRoundedIcon" />
    //           <div className="preferedLanguage">Prefered Language</div>
    //           <div className="languages">
    //             {connectUserData.prefered_language}
    //           </div>
    //         </Grid>
    //         {connectUserData.booking_status == 2 ? (
    //           <Grid
    //             item
    //             sm={6}
    //             lg={3}
    //             md={12}
    //             xs={6}
    //             style={{ color: "#1EC28B " }}
    //             className="handshakeContainer"
    //           >
    //             <Icon icon="fa-solid:hands-helping" className="handShake" />
    //             <div className="canAssist">Can Assist with</div>
    //             <div className="carrryImportant">
    //               {connectUserData.can_assist_with}{" "}
    //             </div>
    //           </Grid>
    //         ) : (
    //           <Grid
    //             item
    //             sm={6}
    //             lg={3}
    //             md={12}
    //             xs={6}
    //             style={{ color: "#1EC28B " }}
    //             className="handshakeContainer"
    //           >
    //             <Icon icon="fa-solid:hands-helping" className="handShake" />
    //             <div className="canAssist">Assistance Needed</div>
    //             <div className="carrryImportant">
    //               {connectUserData.assistance_needed}{" "}
    //             </div>
    //           </Grid>
    //         )}
    //       </Grid>
    //     </Grid>
    //   </div>
    //   {page !== "pasttrip" && (
    //     <Grid className="inviteContainer">
    //       <div className="popupinviteButton">
    //         <button
    //           className="inviteBtnConnectPeople1"
    //           onClick={() => acceptinvitationbutton()}
    //           // onClick={() => {
    //           //   // setreceivedDeleteId();
    //           //   setreceivedDeleteAgree(false);
    //           //   setdeleteOpen(true);
    //           // }}
    //         >
    //           INVITE
    //         </button>
    //       </div>
    //     </Grid>
    //   )}

    //   {/* <Container>
    //           <Dialog
    //             open={deleteopen}
    //             onClose={handledeleteClose}
    //             // aria-labelledby="alert-dialog-title"
    //             // aria-describedby="alert-dialog-description"
    //             PaperProps={{
    //               style: {
    //                 minHeight: "40vh",
    //                 minWidth: "35vw",
    //                 boxShadow: "none",
    //                 border: "2px solid rgb(128 213 184 / 100%)",
    //                 strokeWidth: "1px",
    //                 stroke: "rgb(128 213 184 )",
    //                 borderRadius: 20,
    //               },
    //             }}
    //             BackdropProps={{
    //               style: {
    //                 backdrop: "blur(2px)",
    //               },
    //             }}
    //           >
    //             <div>
    //               <DialogTitle id="alert-dialog-title">
    //                 <div className="invitation-upcoming-delete-dalog">
    //                   <Icon
    //                     className="invitation-deleteConfirmationIcon"
    //                     icon="fluent:delete-12-filled"
    //                   />
    //                   Delete
    //                 </div>
    //                 <hr
    //                   style={
    //                     {
    //                       // height: "1px",
    //                       // borderWidth: 0,
    //                       // color: "#1ec28b",
    //                       // backgroundColor: "#1ec28b",
    //                       // marginLeft: "50px",
    //                       // marginRight: "50px",
    //                     }
    //                   }
    //                   className="invitation-Hrline"
    //                 />
    //               </DialogTitle>
    //               <DialogContent
    //                 className="invitation-upcoming-delete-dalog"
    //               >
    //                 <h2 className="invitation-areUSure"> Are you sure?</h2>
    //               </DialogContent>
    //               <DialogActions className="invitation-buttonsContainer">
    //                 <Button
    //                   id="btn"
    //                   className="invitation-yesButton"
    //                   onClick={() => {
    //                     acceptinvitationbutton();
    //                   }}
    //                 >
    //                   Yes
    //                 </Button>
    //                 <Button
    //                   className="invitation-noButton"
    //                   onClick={handledeleteClose}
    //                 >
    //                   No
    //                 </Button>
    //               </DialogActions>
    //             </div>
    //           </Dialog>
    //         </Container> */}
    // </div>
    // <>
    //   <div className="connectppl-popup-main-background">
    //     <Container style={{ height: "100%" }}>
    //       <div className="connectppl-popup-close-icon">
    //         <button
    //           onClick={onChildClick}
    //           className="connectppl-popup-close-icon-btn"
    //         >
    //           <Icon icon="material-symbols:close-rounded" />
    //         </button>
    //       </div>
    //       <div className="connectppl-popup-img-main">
    //         <img
    //           src={
    //             connectUserData.photo
    //               ? connectUserData.photo
    //               : "./Assets/Images/human_dummy_image.jpg"
    //           }
    //           className="connectppl-popup-img"
    //         ></img>
    //       </div>
    //       <Card className="connectppl-popup-card">
    //         <Container>
    //           <Grid container spacing={2} style={{ textAlign: "center" }}>
    //             <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
    //               <p className="connectppl-popup-name-text">
    //                 {" "}
    //                 {connectUserData.name}
    //               </p>
    //             </Grid>
    //             <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
    //               <p> age {connectUserData.age_group}</p>
    //             </Grid>
    //             <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
    //               <p> gender {connectUserData.gender}</p>
    //             </Grid>
    //             <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
    //               <p> mobile</p>
    //             </Grid>
    //             <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
    //               <p> email</p>
    //             </Grid>
    //           </Grid>
    //           <Grid container spacing={2}>
    //             <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
    //               <div className="connectppl-popup-flight-icon">
    //                 <FlightTakeoffOutlinedIcon className="FlightTakeoffOutlinedIconpopup" />
    //               </div>
    //               <img
    //                 src="./Assets/Images/Image_1.jpg "
    //                 className="connectppl-popup-curvedDashedImage"
    //               ></img>
    //             </Grid>
    //           </Grid>
    //           <Grid
    //             container
    //             spacing={2}
    //             style={{ textAlign: "center", marginTop: 50 }}
    //           >
    //             <Grid item xl={2} lg={2} md={2} xs={2} sm={2}>
    //               <Icon
    //                 icon="material-symbols:location-on"
    //                 className="connectppl-popup-from-location-icon"
    //               />
    //               <p className="connectppl-popup-from-text"> flying from</p>
    //               <p className="connectppl-popup-from-sub-text">
    //                 {" "}
    //                 {connectUserData.flying_from.split(",")[0]}
    //               </p>
    //             </Grid>
    //             {connectUserData.transit1 && connectUserData.transit2 ? (
    //               <Grid item xl={2} lg={2} md={2} xs={2} sm={2}>
    //                 transit1{" "}
    //                 <p className="">{connectUserData.transit1.split(",")[0]}</p>
    //               </Grid>
    //             ) : (
    //               <Grid
    //                 item
    //                 xl={2}
    //                 lg={2}
    //                 md={2}
    //                 xs={2}
    //                 sm={2}
    //                 className=""
    //                 style={{ visibility: "hidden" }}
    //               >
    //                 <p className="">Transit 1</p>
    //                 <p className="">Transit 1</p>
    //               </Grid>
    //             )}
    //             {(connectUserData.transit2 && connectUserData.transti3) ||
    //             connectUserData.transit1 ? (
    //               <Grid item xl={2} lg={2} md={2} xs={2} sm={2}>
    //                 <p>
    //                   {connectUserData.transit2 ? "Transit 2" : "Transit 1"}
    //                 </p>
    //                 <p className="">
    //                   {connectUserData.transit2.split(",")[0]
    //                     ? connectUserData.transit2.split(",")[0]
    //                     : connectUserData.transit1.split(",")[0]}
    //                 </p>
    //               </Grid>
    //             ) : (
    //               <Grid
    //                 item
    //                 xl={2}
    //                 lg={2}
    //                 md={2}
    //                 xs={2}
    //                 sm={2}
    //                 className=""
    //                 style={{ visibility: "hidden" }}
    //               >
    //                 <p className="">Transit 2</p>
    //                 <p className="">Transit 2</p>
    //               </Grid>
    //             )}
    //             {connectUserData.transit3 && connectUserData.transit2 ? (
    //               <Grid item xl={2} lg={2} md={2} xs={2} sm={2}>
    //                 <p className="">
    //                   {connectUserData.transit2.split(",")[0] &&
    //                   connectUserData.transit1.split(",")[0]
    //                     ? "Transit 3"
    //                     : connectUserData.transit2.split(",")[0]
    //                     ? "Transit 2"
    //                     : "Transit 1"}
    //                 </p>
    //                 <p className="">
    //                   {connectUserData.transit2.split(",")[0] &&
    //                   connectUserData.transit1.split(",")[0]
    //                     ? connectUserData.transit3.split(",")[0]
    //                     : connectUserData.transit2.split(",")[0]
    //                     ? connectUserData.transit2.split(",")[0]
    //                     : null}
    //                 </p>
    //               </Grid>
    //             ) : (
    //               <Grid
    //                 item
    //                 xl={2}
    //                 lg={2}
    //                 md={2}
    //                 xs={2}
    //                 sm={2}
    //                 className=""
    //                 style={{ visibility: "hidden" }}
    //               >
    //                 <p className="">Transit 3</p>
    //                 <p className="">Transit 3</p>
    //               </Grid>
    //             )}
    //             {connectUserData.transit4 ? (
    //               <Grid item xl={2} lg={2} md={2} xs={2} sm={2}>
    //                 <p className="">Transit 4</p>
    //                 <p className="">{connectUserData.transit4.split(",")[0]}</p>
    //               </Grid>
    //             ) : (
    //               <Grid
    //                 item
    //                 xl={2}
    //                 lg={2}
    //                 md={2}
    //                 xs={2}
    //                 sm={2}
    //                 className=""
    //                 style={{ visibility: "hidden" }}
    //               >
    //                 <p className="">Transit 4</p>
    //                 <p className="">Transit 4</p>
    //               </Grid>
    //             )}
    //             <Grid item xl={2} lg={2} md={2} xs={2} sm={2}>
    //               <Icon
    //                 icon="material-symbols:location-on"
    //                 className="connectppl-popup-dest-location-icon"
    //               />
    //               <p className="connectppl-popup-dest-text"> destination</p>
    //               <p className="connectppl-popup-dest-sub-text">
    //                 {connectUserData.destination.split(",")[0]}
    //               </p>
    //             </Grid>
    //           </Grid>

    //           <Grid container spacing={2} style={{ columnGap: 37 }}>
    //             <Grid
    //               item
    //               xl={12}
    //               lg={12}
    //               md={12}
    //               sm={12}
    //               xs={12}
    //               style={{ display: "flex" }}
    //             >
    //               {connectUserData.airline ? (
    //                 <Grid
    //                   container
    //                   spacing={2}
    //                   style={{ justifyContent: "center" }}
    //                   className=""
    //                 >
    //                   <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
    //                     <div style={{ textAlign: "center", marginTop: 29 }}>
    //                       Airline
    //                     </div>
    //                     <div className="connect-ppl-airlines-main">
    //                       <div>
    //                         <img
    //                           src="./Assets/Images/Image_left_dotted_arrow.webp"
    //                           className=""
    //                         ></img>
    //                       </div>
    //                       <Box className="connect-ppl-airlines-box">
    //                         {connectUserData.airline}
    //                       </Box>
    //                       <div>
    //                         <img
    //                           src="./Assets/Images/Image_right_dotted_arrow.webp"
    //                           className=""
    //                         ></img>
    //                       </div>
    //                     </div>
    //                   </Grid>
    //                 </Grid>
    //               ) : null}
    //               {connectUserData.airline1 ? (
    //                 <Grid
    //                   container
    //                   spacing={6}
    //                   style={{ justifyContent: "center" }}
    //                 >
    //                   <Grid
    //                     item
    //                     xl={2}
    //                     lg={2}
    //                     md={2}
    //                     sm={2}
    //                     xs={2}
    //                     className=""
    //                   >
    //                     <p style={{ textAlign: "center" }}>Airline</p>
    //                     <div className="connect-ppl-airlines-main">
    //                       <div>
    //                         <img
    //                           src="./Assets/Images/Image_left_dotted_arrow.webp"
    //                           className=""
    //                         ></img>
    //                       </div>
    //                       <Box className="connect-ppl-airlines-box">
    //                         <div className="">{connectUserData.airline1}</div>
    //                       </Box>
    //                       <div>
    //                         <img
    //                           src="./Assets/Images/Image_right_dotted_arrow.webp"
    //                           className=""
    //                         ></img>
    //                       </div>
    //                     </div>
    //                   </Grid>
    //                 </Grid>
    //               ) : null}
    //               {connectUserData.airline2 ? (
    //                 <Grid
    //                   container
    //                   spacing={4}
    //                   style={{ justifyContent: "center" }}
    //                 >
    //                   <Grid
    //                     item
    //                     xl={2}
    //                     lg={2}
    //                     md={2}
    //                     sm={2}
    //                     xs={2}
    //                     className=""
    //                   >
    //                     <p style={{ textAlign: "center" }}>Airline</p>
    //                     <div className="connect-ppl-airlines-main">
    //                       <div>
    //                         <img
    //                           src="./Assets/Images/Image_left_dotted_arrow.webp"
    //                           className=""
    //                         ></img>
    //                       </div>
    //                       <Box className="connect-ppl-airlines-box">
    //                         <div className="">{connectUserData.airline2}</div>
    //                       </Box>
    //                       <div>
    //                         <img
    //                           src="./Assets/Images/Image_right_dotted_arrow.webp"
    //                           className=""
    //                         ></img>
    //                       </div>
    //                     </div>
    //                   </Grid>
    //                 </Grid>
    //               ) : null}
    //               {connectUserData.airline3 ? (
    //                 <Grid
    //                   container
    //                   spacing={3}
    //                   style={{ justifyContent: "center" }}
    //                 >
    //                   <Grid
    //                     item
    //                     xl={2}
    //                     lg={2}
    //                     md={2}
    //                     sm={2}
    //                     xs={2}
    //                     className=""
    //                   >
    //                     <p style={{ textAlign: "center" }}>Airline</p>
    //                     <div className="connect-ppl-airlines-main">
    //                       <div>
    //                         <img
    //                           src="./Assets/Images/Image_left_dotted_arrow.webp"
    //                           className=""
    //                         ></img>
    //                       </div>
    //                       <Box className="connect-ppl-airlines-box">
    //                         <div className="">{connectUserData.airline3}</div>
    //                       </Box>
    //                       <div>
    //                         <img
    //                           src="./Assets/Images/Image_right_dotted_arrow.webp"
    //                           className=""
    //                         ></img>
    //                       </div>
    //                     </div>
    //                   </Grid>
    //                 </Grid>
    //               ) : null}
    //               {connectUserData.airline4 ? (
    //                 <Grid
    //                   container
    //                   spacing={2}
    //                   style={{ justifyContent: "center" }}
    //                 >
    //                   <Grid
    //                     item
    //                     xl={2}
    //                     lg={2}
    //                     md={2}
    //                     sm={2}
    //                     xs={2}
    //                     className=""
    //                   >
    //                     <p style={{ textAlign: "center" }}>Airline</p>
    //                     <div className="connect-ppl-airlines-main">
    //                       <div>
    //                         <img
    //                           src="./Assets/Images/Image_left_dotted_arrow.webp"
    //                           className=""
    //                         ></img>
    //                       </div>
    //                       <Box className="connect-ppl-airlines-box">
    //                         <div className="">{connectUserData.airline4}</div>
    //                       </Box>
    //                       <div>
    //                         <img
    //                           src="./Assets/Images/Image_right_dotted_arrow.webp"
    //                           className=""
    //                         ></img>
    //                       </div>
    //                     </div>
    //                   </Grid>
    //                 </Grid>
    //               ) : null}
    //             </Grid>
    //           </Grid>

    //           <Grid
    //             container
    //             spacing={2}
    //             className="connectppl-popup-date-tip-main"
    //           >
    //             <Grid
    //               item
    //               xl={5}
    //               lg={5}
    //               md={5}
    //               xs={5}
    //               sm={5}
    //               className="connectppl-popup-date"
    //             >
    //               <Icon
    //                 icon="clarity:date-outline-badged"
    //                 className="connectppl-popup-date-icon"
    //               />
    //               <p className="connectppl-popup-date-expected-text"> Date</p>
    //               <div className="connectppl-popup-date-expected-text-sub-range">
    //                 {connectUserData.departing_on
    //                   ? "Departing Date"
    //                   : "Date Range"}
    //               </div>
    //               <div className="connectppl-popup-date-expected-text-sub">
    //                 {connectUserData.departing_on
    //                   ? connectUserData.departing_on
    //                   : `${connectUserData.date_range_from} to ${connectUserData.date_range_to}`}
    //               </div>
    //             </Grid>
    //             <Grid
    //               item
    //               xl={1}
    //               lg={1}
    //               md={1}
    //               xs={1}
    //               sm={1}
    //               className="connectppl-popup-tip-verticleLine-main"
    //             >
    //               <div className="connectppl-popup-tip-verticleLine"></div>
    //             </Grid>
    //             <Grid
    //               item
    //               xl={6}
    //               lg={6}
    //               md={6}
    //               xs={6}
    //               sm={6}
    //               className="connectppl-popup-tip-expected"
    //             >
    //               <Icon
    //                 icon="material-symbols:price-change-rounded"
    //                 className="connectppl-popup-price-icon"
    //               />
    //               <p className="connectppl-popup-tip-expected-text">
    //                 Tip Expected
    //               </p>
    //               <p className="connectppl-popup-tip-expected-text-sub">
    //                 {connectUserData.tip_expected}
    //               </p>
    //             </Grid>
    //           </Grid>
    //           <Grid container spacing={2} style={{ textAlign: "center" }}>
    //             {connectUserData.category == 2 ? (
    //               <Grid
    //                 item
    //                 xl={12}
    //                 lg={12}
    //                 md={12}
    //                 xs={12}
    //                 sm={12}
    //                 className="connectppl-popup-details-text"
    //               >
    //                 I am looking for a travel companion to explore the various
    //                 places in {connectUserData.destination.split(",")[0]}.
    //               </Grid>
    //             ) : (
    //               <Grid
    //                 item
    //                 xl={12}
    //                 lg={12}
    //                 md={12}
    //                 xs={12}
    //                 sm={12}
    //                 className="connectppl-popup-details-text"
    //               >
    //                 I am a student travelling to{" "}
    //                 {connectUserData.destination.split(",")[0]} and can be your
    //                 travel companion.
    //               </Grid>
    //             )}
    //           </Grid>
    //           <Grid
    //             container
    //             spacing={2}
    //             className="connectppl-popup-trip-details-main"
    //           >
    //             <Grid item xl={3} lg={3} md={3} xs={6} sm={6}>
    //               <ModeOfTravelRoundedIcon className="connectppl-popup-trip-details-icon" />
    //               <div className="connectppl-popup-trip-details">Trip Name</div>
    //               <div className="connectppl-popup-trip-details-sub">
    //                 {connectUserData.trip_name}
    //               </div>
    //             </Grid>
    //             <Grid item xl={3} lg={3} md={3} xs={6} sm={6}>
    //               <PlaceIcon className="connectppl-popup-trip-details-icon" />
    //               <div className="connectppl-popup-trip-details">
    //                 Meetup Location
    //               </div>
    //               <div className="connectppl-popup-trip-details-sub">
    //                 {connectUserData.meetup_location}
    //               </div>
    //             </Grid>

    //             <Grid item xl={3} lg={3} md={3} xs={6} sm={6}>
    //               <LanguageRoundedIcon className="connectppl-popup-trip-details-icon" />
    //               <div className="connectppl-popup-trip-details">
    //                 Prefered Language
    //               </div>
    //               <div className="connectppl-popup-trip-details-sub">
    //                 {connectUserData.prefered_language}
    //               </div>
    //             </Grid>
    //             <Grid item xl={3} lg={3} md={3} xs={6} sm={6}>
    //               <Icon
    //                 icon="fa-solid:hands-helping"
    //                 className="connectppl-popup-trip-details-icon"
    //               />
    //               <div className="connectppl-popup-trip-details">
    //                 Can Assist with
    //               </div>
    //               <div className="connectppl-popup-trip-details-sub">
    //                 {connectUserData.assistance_needed}{" "}
    //               </div>
    //             </Grid>
    //           </Grid>
    //         </Container>
    //       </Card>
    //       <div className="connectppl-popup-trip-invite-main">
    //         <button
    //           className="connectppl-popup-trip-invite-btn"
    //           onClick={() => acceptinvitationbutton()}
    //         >
    //           INVITE
    //         </button>
    //       </div>
    //     </Container>
    //   </div>
    // </>
    <div className="connectppl-popup-main-background">
      <Container style={{ height: "100%" }}>
        <div className="connectppl-popup-close-icon">
          <button
            onClick={onChildClick}
            className="connectppl-popup-close-icon-btn"
          >
            <Icon icon="material-symbols:close-rounded" />
          </button>
        </div>
       
        <div className="connectppl-popup-img-main">
          <img
            src={
              connectUserData.photo
                ? connectUserData.photo
                : "./Assets/Images/Favicon 25 WB _ DV.webp"
            }
            className="connectppl-popup-img"
          ></img>
        </div>
        <Card className="connectppl-popup-card">
          <Container>
            <Grid container spacing={1} style={{ textAlign: "center" }}>
              <Grid item xl={12} lg={12} md={12} xs={12} sm={12}>
                <p className="connectppl-popup-name-text">
                  {connectUserData.name}
                </p>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                xs={6}
                sm={6}
                className="connectppl-popup-user-details-text-maingrid"
              >
                <p className="connectppl-popup-user-details-text">
                  <Icon
                    icon="heroicons-outline:user-group"
                    style={{ color: "1ec28b" }}
                  />{" "}
                  Age Group&nbsp;:&nbsp; {connectUserData.age_group}
                </p>
              </Grid>
              <Grid
                item
                xl={6}
                lg={6}
                md={6}
                xs={6}
                sm={6}
                className="connectppl-popup-user-details-text-maingrid"
              >
                <p className="connectppl-popup-user-details-text">
                  <Icon
                    icon="healthicons:female-and-male"
                    style={{ color: "1ec28b" }}
                  />
                  Gender&nbsp;:&nbsp; {connectUserData.gender}
                </p>
              </Grid>

              {page == "invitationconnected" ? (
                <Grid container spacing={2}>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    xs={12}
                    sm={12}
                    className="connectppl-popup-user-details-text-mobileemail-grid"
                  >
                    <p className="connectppl-popup-user-details-text-mobileemail">
                      <Icon className="mobile-icon-connected-list"
                        icon="carbon:phone-filled"
                        // style={{ color: "#1ec28b", fontSize: 19 }}
                      />{" "}
                      Mobile Number :&nbsp;{userdetails.country_code}&nbsp;{" "}
                      {userdetails.mobile_number}
                      <a  
                      // style={{    paddingTop: 5,color: "#1ec28b"}}
                        href={`https://wa.me/${userdetails.country_code}${
                          userdetails.mobile_number
                        }?text=Hello, this is regarding your Travel Companion post with www.DesiTravelCompanion.com, flying from ${
                          connectUserData.flying_from.split(",")[0]
                        } to  ${
                          connectUserData.destination.split(",")[0]
                        }.`}
                        target={"_blank"}
                      >
                        <Icon icon="ant-design:whats-app-outlined"  className="whatsapp-icon-coonected-List"/>
                      </a>
                    </p>
                  </Grid>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    xs={12}
                    sm={12}
                    className="connectppl-popup-user-details-text-email-grid"
                  >
                    <p className="connectppl-popup-user-details-text-mobileemail">
                      <Icon
                      className="email-icon-connected-list"
                        icon="mdi:email"
                        // style={{ color: "#1ec28b", fontSize: 19 }}
                      />{" "}
                      Email :&nbsp;{userdetails.email}
                    </p>
                  </Grid>
                </Grid>
              ) : null}
            </Grid>

            {/* Desktop view */}
            <div className="desktop-view-transit-part">
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <img
                    src="./Assets/Images/Image_1.webp "
                    className="connectppl-popup-curvedDashedImage"
                  ></img>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ textAlign: "center", marginTop: 50 }}
              >
                <Grid item xl={2} lg={2} md={2} xs={2} sm={2}>
                  <Icon
                    icon="material-symbols:location-on"
                    className="connectppl-popup-from-location-icon"
                  />
                  <p className="connectppl-popup-from-text"> flying from</p>
                  <p className="connectppl-popup-from-sub-text">
                    {connectUserData.flying_from.split(",")[0]}
                  </p>
                </Grid>
                {/* <Grid item xl={8} lg={8} md={8} xs={8} sm={8}></Grid> */}
                {connectUserData.transit1 && connectUserData.transit2 ? (
                  <Grid item xl={2} lg={2} md={2} xs={2} sm={2}>
                    <div className="">Transit 1</div>
                    <span>{connectUserData.transit1.split(",")[0]}</span>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    xs={2}
                    sm={2}
                    className=""
                    style={{ visibility: "hidden" }}
                  >
                    <div className="">Transit 1</div>
                    <div className="=">Transit 1</div>
                  </Grid>
                )}
                {(connectUserData.transit2 && connectUserData.transti3) ||
                connectUserData.transit1 ? (
                  <Grid item lg={2} className="">
                    <div className="">
                      {connectUserData.transit2 ? "Transit 2" : "Transit 1"}
                    </div>
                    <div className="">
                      {connectUserData.transit2
                        ? connectUserData.transit2
                        : connectUserData.transit1}
                    </div>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    xs={2}
                    sm={2}
                    className=""
                    style={{ visibility: "hidden" }}
                  >
                    <div className="">Transit 2</div>
                    <div className="">Transit 2</div>
                  </Grid>
                )}
                {connectUserData.transit3 && connectUserData.transit2 ? (
                  <Grid item lg={2} className="">
                    <div className="">
                      {connectUserData.transit2 && connectUserData.transit1
                        ? "Transit 3"
                        : connectUserData.transit2
                        ? "Transit 2"
                        : "Transit 1"}
                    </div>
                    <div className="">
                      {connectUserData.transit2 && connectUserData.transit1
                        ? connectUserData.transit3
                        : connectUserData.transit2
                        ? connectUserData.transit2
                        : null}
                    </div>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    xs={2}
                    sm={2}
                    className=""
                    style={{ visibility: "hidden" }}
                  >
                    <div className="">Transit 3</div>
                    <div className="">Transit 3</div>
                    {/* <div className="LAX">(LAX)</div> */}
                  </Grid>
                )}
                {connectUserData.transit4 ? (
                  <Grid item lg={2} className="">
                    <div className="">Transit 4</div>
                    <div className="">{connectUserData.transit4}</div>
                  </Grid>
                ) : (
                  <Grid
                    item
                    xl={2}
                    lg={2}
                    md={2}
                    xs={2}
                    sm={2}
                    className=""
                    style={{ visibility: "hidden" }}
                  >
                    <div className="">Transit 4</div>
                    <div className="">Transit 4</div>
                  </Grid>
                )}
                <Grid item xl={2} lg={2} md={2} xs={2} sm={2}>
                  <Icon
                    icon="material-symbols:location-on"
                    className="connectppl-popup-dest-location-icon"
                  />
                  <p className="connectppl-popup-dest-text"> destination</p>
                  <p className="connectppl-popup-dest-sub-text">
                    {connectUserData.destination.split(",")[0]}
                  </p>
                </Grid>
              </Grid>

              <Grid container spacing={2} className="airlineIndigoContainer">
                {connectUserData.airline ? (
                  <Grid item lg={2}>
                    <Grid className="airlineText1">Airline</Grid>
                    <div style={{ marginLeft: "-47px" }}>
                      <img
                        src="./Assets/Images/Image_left_dotted_arrow.webp"
                        className=""
                        style={{ width: "44px" }}
                      ></img>
                    </div>
                    <Box className="indigoBox1">
                      <div className="indigoText">
                        {connectUserData.airline}
                      </div>
                    </Box>
                    <div style={{ marginLeft: "80px", marginTop: "-60px" }}>
                      <img
                        src="./Assets/Images/Image_right_dotted_arrow.webp"
                        className=""
                        style={{ width: "44px" }}
                      ></img>
                    </div>
                  </Grid>
                ) : null}
                {connectUserData.airline1 ? (
                  <Grid item lg={2} className="stopOneContainer">
                    <Grid className="airlineText2">Airline</Grid>
                    <div style={{ marginLeft: "-28px" }}>
                      <img
                        src="./Assets/Images/Image_left_dotted_arrow.webp"
                        className=""
                        style={{ width: "44px" }}
                      ></img>
                    </div>
                    <Box className="indigoBox2">
                      <div className="indigoText">
                        {connectUserData.airline1}
                      </div>
                    </Box>
                    <div style={{ marginLeft: "100px", marginTop: "-60px" }}>
                      <img
                        src="./Assets/Images/Image_right_dotted_arrow.webp"
                        className=""
                        style={{ width: "44px" }}
                      ></img>
                    </div>
                  </Grid>
                ) : null}
                {connectUserData.airline2 ? (
                  <Grid item lg={2} className="stopOneContainer">
                    <Grid className="airlineText3">Airline</Grid>
                    <div style={{ marginLeft: "-8px" }}>
                      <img
                        src="./Assets/Images/Image_left_dotted_arrow.webp"
                        className=""
                        style={{ width: "44px" }}
                      ></img>
                    </div>
                    <Box className="indigoBox3">
                      <div className="indigoText">
                        {connectUserData.airline2}
                      </div>
                    </Box>
                    <div style={{ marginLeft: "118px", marginTop: "-60px" }}>
                      <img
                        src="./Assets/Images/Image_right_dotted_arrow.webp"
                        className=""
                        style={{ width: "44px" }}
                      ></img>
                    </div>
                  </Grid>
                ) : null}
                {connectUserData.airline3 ? (
                  <Grid item lg={2} className="stopOneContainer">
                    <Grid className="airlineText4">Airline</Grid>
                    <div style={{ marginLeft: "10px" }}>
                      <img
                        src="./Assets/Images/Image_left_dotted_arrow.webp"
                        className=""
                        style={{ width: "44px" }}
                      ></img>
                    </div>
                    <Box className="indigoBox4">
                      <div className="indigoText">
                        {connectUserData.airline3}
                      </div>
                    </Box>
                    <div style={{ marginLeft: "134px", marginTop: "-60px" }}>
                      <img
                        src="./Assets/Images/Image_right_dotted_arrow.webp"
                        className=""
                        style={{ width: "44px" }}
                      ></img>
                    </div>
                  </Grid>
                ) : null}
                {connectUserData.airline4 ? (
                  <Grid item lg={2} className="stopOneContainer">
                    <Grid className="airlineText5">Airline</Grid>
                    <div style={{ marginLeft: "25px" }}>
                      <img
                        src="./Assets/Images/Image_left_dotted_arrow.webp"
                        className=""
                        style={{ width: "44px" }}
                      ></img>
                    </div>
                    <Box className="indigoBox5">
                      <div className="indigoText">
                        {connectUserData.airline4}
                      </div>
                    </Box>
                    <div style={{ marginLeft: "151px", marginTop: "-60px" }}>
                      <img
                        src="./Assets/Images/Image_right_dotted_arrow.webp"
                        className=""
                        style={{ width: "44px" }}
                      ></img>
                    </div>
                  </Grid>
                ) : null}
              </Grid>
            </div>
            {/* Mobile View */}
            <div className="mobile-view-transit-part">
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <img
                    src="./Assets/Images/Image_1.webp "
                    className="connectppl-popup-curvedDashedImage"
                  ></img>
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                style={{ textAlign: "center", marginTop: 50 }}
              >
                <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                  <Icon
                    icon="material-symbols:location-on"
                    className="connectppl-popup-from-location-icon"
                  />
                  <p className="connectppl-popup-from-text"> flying from</p>
                  <p className="connectppl-popup-from-sub-text">
                    {connectUserData.flying_from.split(",")[0]}
                  </p>
                </Grid>

                <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                  <Icon
                    icon="material-symbols:location-on"
                    className="connectppl-popup-dest-location-icon"
                  />
                  <p className="connectppl-popup-dest-text"> destination</p>
                  <p className="connectppl-popup-dest-sub-text">
                    {connectUserData.destination.split(",")[0]}
                  </p>
                </Grid>
              </Grid>

              <Container>
                <div className="connect-ppl-airlines-hr-line"></div>
              </Container>
              <Card
                // style={{
                //   height: "auto",
                //   textAlign: "center",
                //   backgroundColor: `rgba( 30,194 ,139 ,50% )`,
                // }}
                className="connect-ppl-pop-up-travel-details-cardmobile"
              >
                <Grid container spacing={2}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <p className="connect-ppl-pop-up-travel-details">
                      Flying from &nbsp;: &nbsp;
                      {connectUserData.flying_from}
                    </p>
                  </Grid>
                  {connectUserData.airline ? (
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <span className="connect-ppl-pop-up-travel-details">
                        Airline
                      </span>
                      &nbsp;: &nbsp;
                      <span className="connect-ppl-pop-up-travel-details">
                        {connectUserData.airline}
                      </span>
                    </Grid>
                  ) : null}
                </Grid>
              </Card>
              <div style={{ textAlign: "center" }}>
                <img
                  src="./Assets/Images/Dotted-icon.webp"
                  className="connect-ppl-dotted-image"
                ></img>
              </div>
              {connectUserData.transit1 && (
                <Grid>
                  <Card
                    // style={{
                    //   height: 120,
                    //   textAlign: "center",
                    //   backgroundColor: `rgba( 30,194 ,139 ,50% )`,
                    // }}
                    className="connect-ppl-pop-up-travel-details-cardmobile"
                  >
                    <Grid container spacing={2}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <p className="connect-ppl-pop-up-travel-details">
                          Transit 1 &nbsp;: &nbsp;
                          {connectUserData.transit1}
                        </p>
                      </Grid>
                      {connectUserData.airline1 ? (
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className=""
                        >
                          <span className="connect-ppl-pop-up-travel-details">
                            Airline
                          </span>
                          &nbsp;: &nbsp;
                          <span className="connect-ppl-pop-up-travel-details">
                            {connectUserData.airline1}
                          </span>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Card>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src="./Assets/Images/Dotted-icon.webp"
                      className="connect-ppl-dotted-image"
                    ></img>
                  </div>
                </Grid>
              )}

              {connectUserData.transit2 && (
                <Grid>
                  <Card
                    // style={{
                    //   height: 120,
                    //   textAlign: "center",
                    //   backgroundColor: `rgba( 30,194 ,139 ,50% )`,
                    // }}
                    className="connect-ppl-pop-up-travel-details-cardmobile"
                  >
                    <Grid container spacing={2}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <p className="connect-ppl-pop-up-travel-details">
                          Transit 2 &nbsp;: &nbsp;
                          {connectUserData.transit2}
                        </p>
                      </Grid>
                      {connectUserData.airline2 ? (
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className=""
                        >
                          <span className="connect-ppl-pop-up-travel-details">
                            Airline
                          </span>
                          &nbsp;: &nbsp;
                          <span className="connect-ppl-pop-up-travel-details">
                            {connectUserData.airline2}
                          </span>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Card>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src="./Assets/Images/Dotted-icon.webp"
                      className="connect-ppl-dotted-image"
                    ></img>
                  </div>
                </Grid>
              )}

              {connectUserData.transit3 && (
                <Grid>
                  <Card
                    // style={{
                    //   height: 120,
                    //   textAlign: "center",
                    //   backgroundColor: `rgba( 30,194 ,139 ,50% )`,
                    // }}
                    className="connect-ppl-pop-up-travel-details-cardmobile"
                  >
                    <Grid container spacing={2}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <p className="connect-ppl-pop-up-travel-details">
                          Transit 3 &nbsp;: &nbsp;
                          {connectUserData.transit3}
                        </p>
                      </Grid>
                      {connectUserData.airline3 ? (
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className=""
                        >
                          <span className="connect-ppl-pop-up-travel-details">
                            Airline
                          </span>
                          &nbsp;: &nbsp;
                          <span className="connect-ppl-pop-up-travel-details">
                            {connectUserData.airline3}
                          </span>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Card>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src="./Assets/Images/Dotted-icon.webp"
                      className="connect-ppl-dotted-image"
                    ></img>
                  </div>
                </Grid>
              )}

              {connectUserData.transit4 && (
                <Grid>
                  <Card
                    // style={{
                    //   height: 120,
                    //   textAlign: "center",
                    //   backgroundColor: `rgba( 30,194 ,139 ,50% )`,
                    // }}
                    className="connect-ppl-pop-up-travel-details-cardmobile"
                  >
                    <Grid container spacing={2}>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <p className="connect-ppl-pop-up-travel-details">
                          Transit 4 &nbsp;: &nbsp;
                          {connectUserData.transit4}
                        </p>
                      </Grid>
                      {connectUserData.airline4 ? (
                        <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          className=""
                        >
                          <span className="connect-ppl-pop-up-travel-details">
                            Airline
                          </span>
                          &nbsp;: &nbsp;
                          <span className="connect-ppl-pop-up-travel-details">
                            {connectUserData.airline4}
                          </span>
                        </Grid>
                      ) : null}
                    </Grid>
                  </Card>
                  <div style={{ textAlign: "center" }}>
                    <img
                      src="./Assets/Images/Dotted-icon.webp"
                      className="connect-ppl-dotted-image"
                    ></img>
                  </div>
                </Grid>
              )}

              <Card
                // style={{
                //   height: 50,
                //   textAlign: "center",
                //   backgroundColor: `rgba( 30,194 ,139 ,50% )`,
                // }}
                className="connect-ppl-pop-up-travel-details-cardmobile"
              >
                <Grid container spacing={2}>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <p className="connect-ppl-pop-up-travel-details">
                      Destination &nbsp;: &nbsp;
                      {connectUserData.destination}
                    </p>
                  </Grid>
                </Grid>
              </Card>
            </div>

            {/* -------date range and tip expected content----- */}
            <Grid
              container
              spacing={2}
              className="connectppl-popup-date-tip-main"
            >
              {/* booking status */}
              {connectUserData.booking_status == 2 ? (
                <div
                  // item
                  // xl={12}
                  // lg={12}
                  // md={12}
                  // xs={12}
                  // sm={12}
                  className="connectppl-popup-date"
                >
                  <Icon
                    icon="clarity:date-outline-badged"
                    className="connectppl-popup-date-icon"
                  />
                  {/* <p className="connectppl-popup-date-expected-text"> Date</p> */}
                  <div className="connectppl-popup-date-expected-text-sub-range">
                    {connectUserData.departing_on
                      ? "Departing Date"
                      : "Date Range"}
                  </div>
                  <div className="connectppl-popup-date-expected-text-sub">
                    {connectUserData.departing_on
                      ? connectUserData.departing_on
                      : `${connectUserData.date_range_from} to ${connectUserData.date_range_to}`}
                  </div>
                </div>
              ) : (
                <div
                  // item
                  // xl={5}
                  // lg={5}
                  // md={5}
                  // xs={5}
                  // sm={5}
                  className="connectppl-popup-date"
                >
                  <Icon
                    icon="clarity:date-outline-badged"
                    className="connectppl-popup-date-icon"
                  />
                  {/* <p className="connectppl-popup-date-expected-text"> Date</p> */}
                  <div className="connectppl-popup-date-expected-text-sub-range">
                    {connectUserData.departing_on
                      ? "Departing Date"
                      : "Date Range"}
                  </div>
                  <div className="connectppl-popup-date-expected-text-sub">
                    {connectUserData.departing_on
                      ? connectUserData.departing_on
                      : `${connectUserData.date_range_from} to ${connectUserData.date_range_to}`}
                  </div>
                </div>
              )}
              {connectUserData.category == 1 && (
                <div
                  // item
                  // xl={2}
                  // lg={2}
                  // md={2}
                  // xs={2}
                  // sm={2}
                  className="connectppl-popup-tip-verticleLine-main"
                >
                  <div className="connectppl-popup-tip-verticleLine"></div>
                </div>
              )}
              {connectUserData.category == 1 && (
                <div
                  // item
                  // xl={5}
                  // lg={5}
                  // md={5}
                  // xs={5}
                  // sm={5}
                  className="connectppl-popup-tip-expected"
                >
                  <Icon
                    icon="material-symbols:price-change-rounded"
                    className="connectppl-popup-price-icon"
                  />
                  <b>
                    {" "}
                    <p className="connectppl-popup-tip-expected-text">
                      Tip Expected
                    </p>
                    <p className="connectppl-popup-tip-expected-text-sub">
                      {/* $ &nbsp;{connectUserData.tip_expected} */}
                      {/^([^0-9]*)$/.test(connectUserData.tip_expected)
                        ? connectUserData.tip_expected
                        : `${connectUserData.tip_expected} USD equiv`}
                    </p>
                  </b>
                </div>
              )}
            </Grid>

            {/* ----------destination text trip ------- */}
            <Grid container spacing={2} style={{ textAlign: "center" }}>
              {/* {connectUserData.category == 2 ? ( */}
              <Grid
                item
                xl={12}
                lg={12}
                md={12}
                xs={12}
                sm={12}
                className="connectppl-popup-details-text"
              >
                <p style={{lineBreak: "anywhere"}}>{connectUserData.short_description}</p>
                {/* <p>
                    I am looking for a travel companion to explore the various
                    places in <b>{connectUserData.destination.split(",")[0]}</b>.
                  </p> */}
              </Grid>
              {/* ) : ( */}
              {/* <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  xs={12}
                  sm={12}
                  className="connectppl-popup-details-text"
                >
                  <p>
                    I am a student travelling to &nbsp;
                    {connectUserData.destination.split(",")[0]} and can be your
                    travel companion.
                  </p>
                </Grid>
              )} */}
            </Grid>
            {/* -----------trip name, preferred language, meetuplocation, can assist with content */}
            <Grid
              container
              spacing={2}
              className="connectppl-popup-trip-details-main"
            >
              <Grid item xl={3} lg={3} md={3} xs={6} sm={6}>
                <ModeOfTravelRoundedIcon className="connectppl-popup-trip-details-icon" />
                <div className="connectppl-popup-trip-details">Trip Name</div>
                <div className="connectppl-popup-trip-details-sub" style={{lineBreak: "anywhere"}}>
                  {connectUserData.trip_name}
                </div>
              </Grid>
              <Grid item xl={3} lg={3} md={3} xs={6} sm={6}>
                <PlaceIcon className="connectppl-popup-trip-details-icon" />
                <div className="connectppl-popup-trip-details">
                  Meetup Location
                </div>
                <div className="connectppl-popup-trip-details-sub">
                  {connectUserData.meetup_location}
                </div>
              </Grid>

              <Grid item xl={3} lg={3} md={3} xs={6} sm={6}>
                <LanguageRoundedIcon className="connectppl-popup-trip-details-icon" />
                <div className="connectppl-popup-trip-details">
                  Prefered Language
                </div>
                <div className="connectppl-popup-trip-details-sub">
                  {connectUserData.prefered_language}
                </div>
              </Grid>
              {/* coming from category */}
              {connectUserData.category == 1 ? (
                <Grid item xl={3} lg={3} md={3} xs={6} sm={6}>
                  <Icon
                    icon="fa-solid:hands-helping"
                    className="connectppl-popup-trip-details-icon"
                  />
                  <div className="connectppl-popup-trip-details">
                    Can Assist with
                  </div>
                  <div className="connectppl-popup-trip-details-sub">
                    {connectUserData.can_assist_with}
                  </div>
                </Grid>
              ) : (
                <Grid item xl={3} lg={3} md={3} xs={6} sm={6}>
                  <Icon
                    icon="fa-solid:hands-helping"
                    className="connectppl-popup-trip-details-icon"
                  />
                  <div className="connectppl-popup-trip-details">
                    Assistance Needed
                  </div>
                  <div className="connectppl-popup-trip-details-sub" style={{lineBreak: "anywhere"}}>
                    {connectUserData.assistance_needed}
                  </div>
                </Grid>
              )}
            </Grid>
          </Container>
        </Card>
        {/* {page !== "pasttrip" && ( */}
        {page == "connectpeople" ? (
          <div className="connectppl-popup-trip-invite-main">
            <button
              className="connectppl-popup-trip-invite-btn"
              onClick={() => acceptinvitationbutton()}
            >
              Invite to be your TC
            </button>
          </div>
        ) : null}
      </Container>
    </div>
  );
}

export default ConnectPeoplePopup;
