import "../CSS/sidebarLink.css";
import React from "react";
import { Link, Navigate, NavLink } from "react-router-dom";
// import "../../CSS/sidebarLink.css";
import { Drawer, Grid, List, Paper } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ListSubheader from "@mui/material/ListSubheader";
// import List from '@mui/material/List';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import ChatBubbleSharpIcon from "@mui/icons-material/ChatBubbleSharp";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";

import { logout } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

function SidebarDesktopMenu() {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const dispatch = useDispatch();

  const [dashboardState, setDashboardState] = React.useState(false);

  const DashboardMenu = () => {
    setDashboardState(!dashboardState);
  };

  const [MyTripsState, setMyTripsState] = React.useState(false);

  const MyTripsMenu = () => {
    setMyTripsState(!MyTripsState);
  };
  const [InvitationsState, setInvitationsState] = React.useState(false);

  const InvitationsMenu = () => {
    setInvitationsState(!InvitationsState);
  };

  const logOut = () => {
    dispatch(logout());
  };
  if (!isLoggedIn) {
    return <Navigate replace to="/login" />;
  }
  return (
    <div className="link">
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItemButton component={NavLink} to="/dashboard" className={({ isActive }) => (isActive ? "active" : "inactive")}
                        >
          <ListItemIcon>
            <Icon
              icon="material-symbols:dashboard-customize-outline"
              className="sidebaricon"
            />
          </ListItemIcon>
          <ListItemText primary="Dashboard" />
        </ListItemButton>
        <ListItemButton component={NavLink} to="/settings"  className={({ isActive }) => (isActive ? "active" : "inactive")}
>
          <ListItemIcon>
            <Icon
              icon="material-symbols:settings-applications-sharp"
              className="sidebaricon"
            />
          </ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItemButton>
        <ListItemButton onClick={logOut}>
          <ListItemIcon>
            <Icon icon="ri:logout-circle-fill" className="sidebaricon" />
          </ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </div>
  );
}
export default SidebarDesktopMenu;
