import { Badge, Box, Button, Container, Grid, Typography } from "@mui/material";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import "../../CSS/notification.css";
import DashboardHeader from "../DashboardHeader";
import EditProfile from "../EditProfile";
import SidebarLink from "../SidebarLink";
import { useSelector } from "react-redux";
import ArrowCircleDownSharpIcon from "@mui/icons-material/ArrowCircleDownSharp";
import { Navigate, useNavigate } from "react-router-dom";

function Notification() {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  console.log("user_id", user.user_id);

  const [notification, setNotification] = useState([]);

  const [updateId, setUpdateId] = useState("");
  const [notify, setNotify] = useState(0);
  const [notificationTriperror, setnotificationTriperror] = useState();

  // const [isOnline, set_isOnline] = useState(true);
  // let interval = null;

  // const InternetErrMessagenger = () => set_isOnline(navigator.onLine === true); // for do like this shortform

  // useEffect(() => {
  //   interval = setInterval(InternetErrMessagenger, 1000);
  //   return () => {
  //     clearInterval(interval); // for component unmount stop the interval
  //   };
  // }, []);

  useEffect(() => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .get(`${domain}/notifications/${user.user_id}/`)
      .then((response) => {
        console.log(response, "notifications response");

        console.log(response.data, "notifications list response data");
        setNotification(response.data);

        // console.log(response.data, "sent List");
      })
      .catch((error) => {
        // Error
        // console.log("status : ",error.response.status);
        // console.log("data : ",error.response.data);
        if (error.response.status === 400) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log("data : ",error.response.data);
          console.log("data : ", error.response.data.message);
          setnotificationTriperror(error.response.data.message);
          // console.log("status : ",error.response.status);
          // console.log("headers : ",error.response.headers);
        }
      });
  }, []);

  const [next, setNext] = useState(4);
  const handleMoreImage = () => {
    setNext(next + 4);
  };

  const updateNotification = (id) => {
    console.log(id, "update Card id");
    setUpdateId(id);

    if (id !== "") {
      console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
      let domain = "";
      console.log(process.env, "domain env", window.location.hostname);
      if (window.location.hostname.trim() === "localhost") {
        domain = process.env.REACT_APP_LOCAL_API;
      } else if (window.location.hostname.trim() === "54.185.6.32") {
        domain = process.env.REACT_APP_UAT_API;
      } else if (
        window.location.hostname.trim() === "uat.desitravelcompanion.com"
      ) {
        domain = process.env.REACT_APP_PROD_UAT_API;
      } else if (
        window.location.hostname.trim() === "desitravelcompanion.com"
      ) {
        domain = process.env.REACT_APP_PROD_API;
      }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
        domain = process.env.REACT_APP_PROD_API;
      }
      console.log(domain, "domain env end");

      axios
        .patch(`${domain}/notifications/${id}/`, {
          is_seen: true,
        })
        .then((response) => {
          console.log(response, "update notification response");
          // setNotify(1);
          localStorage.setItem("notification_count_value", JSON.stringify(id));

          axios
            .get(`${domain}/notifications/${user.user_id}/`)
            .then((response) => {
              console.log(response, "notifications response");

              console.log(response.data, "notifications list response data");
              setNotification(response.data);

              axios
                .get(`${domain}/notifications/unseen/${user.user_id}/`)
                .then((response) => {
                  console.log(response, "notification response");
                  console.log(response.data.unread_count, "not response");
                  if (response.data) {
                    console.log(response.data, "notification response data");
                    setNotify(response.data.unread_count);
                    // setDashboardNotification(response.data);
                  }
                })
                .catch((error) => {
                  if (error.response.status === 400) {
                    console.log("notification : ", error.response.data.message);
                  }
                });
            })

            .catch((error) => {
              if (error.response.status === 400) {
                console.log("data : ", error.response.data.message);
                setnotificationTriperror(error.response.data.message);
              }
            });
        })

        .catch((error) => {
          // Error
          // console.log("status : ",error.response.status);
          // console.log("data : ",error.response.data);
          if (error.response.status === 400) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log("data : ",error.response.data);
            console.log("data : ", error.response.data.message);
            // console.log("status : ",error.response.status);
            // console.log("headers : ",error.response.headers);
          }
        });
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (user.update_profile_details !== "False") {
      return navigate("/edit-profile", { state: "true" });
    }
  }, []);

  // if(user.update_profile_details=="False"){
  return (
    <>
      <DashboardHeader dataParentToChild_notify={notify}></DashboardHeader>
      <div className="notify-dashboard-main">
        <div className="notification-sidebar-links-main">
          <Box
            sx={{
              width: 300,
              height: 600,
              backgroundColor: "",
              "&:hover": {
                backgroundColor: "aliceblue",
              },
            }}
          >
            <div>
              {/* <div>Dashboard</div>
              <div>Setting</div>
              <div>Logout</div> */}
              <SidebarLink></SidebarLink>
            </div>
          </Box>
        </div>

        {/* <div className="notification-formobile-version">
          <div>
            <Box className="notify">
              <p className="notifyText">Notifications</p>
              <Grid container spacing={2} className="notifyContainer">
                <Grid item xs={12} className="notifyGrid">
                  {notification.length > 0 ? (
                    <Grid container spacing={2} className="">
                      <Grid item lg={4} className=" ">
                        {notification.slice(0, next).map((notify) => (
                          <Badge
                            sx={{
                              "& .MuiBadge-badge": {
                                backgroundColor: "red",
                                marginTop: "40px !important",
                                marginRight: "45px",
                                width: "15px",
                                height: "15px",
                                borderRadius: "7px",
                              },
                            }}
                            variant="dot"
                            invisible={notify.is_seen || notify.id == updateId}
                            onClick={() => {
                              updateNotification(notify.id);
                            }}
                          >
                            <div className="notifybox">
                              <div className="notifycontainer">
                                <div className="sentListmessageinner">
                                  <img
                                    className={
                                      notify.user2.profile_picture
                                        ? "notifyIMG"
                                        : "sentListmessageImg"
                                    }
                                    variant="top"
                                    src={
                                      notify.user2.profile_picture
                                        ? notify.user2.profile_picture
                                        : "./Assets/Images/Favicon 25 WB _ DV.webp"
                                    }
                                    alt="..."
                                  />
                                </div>
                              </div>
                              <Grid
                                item
                                lg={8}
                                className="sentListTextContainer"
                              >
                                <Typography className="notifyAnu">
                                  {" "}
                                  {notify.user2.full_name}
                                </Typography>
                                <Typography className="notifyAnuText">
                                  {" "}
                                  {notify.notification}
                                </Typography>
                              </Grid>
                            </div>
                          </Badge>
                        ))}
                      </Grid>
                    </Grid>
                  ) : (
                    <div>
                      <p>{notification.message}</p>
                    </div>
                  )}
                </Grid>
              </Grid>
              {next < notification.length && (
                <div style={{ margin: "auto" }}>
                  <Button
                    className="notificationLoadMore"
                    onClick={handleMoreImage}
                  >
                    Load more Results
                    <div>
                      <ArrowCircleDownSharpIcon />
                    </div>
                  </Button>
                </div>
              )}
            </Box>
          </div>
          <div>
            <EditProfile></EditProfile>
          </div>
        </div> */}
        <div className="notify-formob">
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box className="notifyedListBox">
                  <p className="notifyedListText">Notifications</p>
                  <Grid
                    container
                    spacing={2}
                    className="notifyedListmessageContainer-main"
                  >
                    <Grid item xs={12} className="notifyedListmessageGrid">
                      {notification.slice(0, next).map((post, index) => {
                        return (
                          <div className="notifyedList-messageMainbox">
                            <Badge
                              sx={{
                                "& .MuiBadge-badge": {
                                  backgroundColor: "red",
                                  width: "10px",
                                  height: "10px",
                                  borderRadius: "7px",
                                  transform: "translate(3px, 6px)",
                                },
                              }}
                              style={{ display: "block" }}
                              variant="dot"
                              invisible={post.is_seen || post.id == updateId}
                              onClick={() => {
                                if (!post.is_seen || !(post.id == updateId))
                                  updateNotification(post.id);
                              }}
                            >
                              <Grid container spacing={2}>
                                <Grid item lg={5} xl={5} md={5} sm={4} xs={4}>
                                  <div className="notifyedListmessagecontainer">
                                    <div className="notifyedListmessageinner">
                                      <img
                                        className="notifyedListmessageImg"
                                        variant="top"
                                        // src="./Assets/Images/I1.webp"
                                        src={
                                          post.user2.profile_picture
                                            ? post.user2.profile_picture
                                            : "./Assets/Images/Favicon 25 WB _ DV.webp"
                                        }
                                        alt="..."
                                      />
                                    </div>
                                  </div>
                                </Grid>

                                <Grid item lg={7} xl={7} md={7} sm={8} xs={8}>
                                  <Typography className="notifyedListmessageThara">
                                    {post.user2.full_name}
                                  </Typography>
                                  <Typography className="notifyedListTharaText">
                                    {post.notification}
                                  </Typography>
                                </Grid>
                              </Grid>
                            </Badge>
                          </div>
                        );
                      })}
                      <div
                        style={{
                          margin: "auto",
                          justifyContent: "center",
                          display: "flex",
                          marginTop: 45,
                        }}
                      >
                        {notificationTriperror}
                      </div>
                    </Grid>
                  </Grid>
                  {next < notification.length && (
                    <div>
                      <Button
                        className="notificationLoadMore"
                        onClick={handleMoreImage}
                      >
                        Load More
                        <div>
                          <ArrowCircleDownSharpIcon
                            style={{ marginTop: 4, fontSize: 18 }}
                          />
                        </div>
                      </Button>
                    </div>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
          <div>
            <EditProfile></EditProfile>
          </div>
        </div>
      </div>
    </>
  );
  // }else{

  //   return navigate('/edit-profile',{state:'true'})
  // }
}
export default Notification;
