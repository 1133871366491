import {
    ADMIN_LOGIN_SUCCESS,
    ADMIN_LOGIN_FAIL,
    ADMIN_LOGOUT,
  } from "../AdminDashboard/AdminRedux/actions/types";
  const admin = JSON.parse(localStorage.getItem("admin"));
  const initialState =admin
  ? { isadminLoggedIn: true, admin }
  : { isadminLoggedIn: false, admin: null };
  export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
    
      case ADMIN_LOGIN_SUCCESS:
        return {
          ...state,
          isadminLoggedIn: true,
          admin: payload.admin,
        };
      case ADMIN_LOGIN_FAIL:
        return {
          ...state,
          isadminLoggedIn: false,
          admin: null,
        };
      case ADMIN_LOGOUT:
        return {
          ...state,
          isadminLoggedIn: false,
          admin: null,
        };
      default:
        return state;
    }
  }