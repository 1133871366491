import axios from "axios"

let domain=''
if (window.location.hostname.trim() === "localhost") {
   domain = process.env.REACT_APP_LOCAL_API;
 } else if (window.location.hostname.trim() === "54.185.6.32") {
   domain = process.env.REACT_APP_UAT_API;
 } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
   domain = process.env.REACT_APP_PROD_UAT_API;
 }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
   domain = process.env.REACT_APP_PROD_API;
 }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
   domain = process.env.REACT_APP_PROD_API;
 }
 console.log(domain, "domain env end");

export const getAirports = async(value) => {
    console.log(value,"countVleu")
let response;
 await axios.get(`${domain}/airports?airport=${value}`).then(res=>{
    console.log(res,"countryResponse")
    response=res
 })
 return response
}

export const getLanguages = async(value) => {
    console.log(value,"countVleu")
    let response;
     await axios.get(`${domain}/languages?language=${value}`).then(res=>{
        console.log(res,"countryResponse")
        response=res
     })
     return response
 
}


export const getAirlines = async (value) => {
   console.log(value,"countVleu")
   let response;
    await axios.get(`${domain}/airlines?airlines=${value}`).then(res=>{
       console.log(res,"countryResponse")
       response=res
    })
    return response
 
}


