import React, { useEffect, useState } from "react";
import ArrowDropDownTwoToneIcon from "@mui/icons-material/ArrowDropDownTwoTone";
import ArrowDropUpTwoToneIcon from "@mui/icons-material/ArrowDropUpTwoTone";
import SortSharpIcon from "@mui/icons-material/SortSharp";
import "../../CSS/filters.css";
import {Formik,Form} from "formik"
import { Link } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Slider from "@mui/material/Slider";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import PublicIcon from "@mui/icons-material/Public";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
import FlightIcon from "@mui/icons-material/Flight";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
// import airlines from "../../json/Airlines.json";
import { Grid, Select, MenuItem } from "@mui/material";
// import languages from "../../json/languages.json";
import { getAirlines,getLanguages} from "../CreateTripNew/OptionsUtility/OptionsUtil";
import { Icon } from "@iconify/react";
import WcIcon from '@mui/icons-material/Wc';


import {
  Box,
  Button,
  Collapse,
  Container,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  TextField,
} from "@mui/material";


const Filter = (props) => {
  const [bookingstatus, setBookingStatus] = useState(false);
  const [airlinesState, setAirlinesState] = useState(false);
  const [tipAmtState, setTipAmtState] = useState(false);
  const [genderState, setGenderState] = useState(false);

  const [allFieldValues, setAllFieldValues] = useState({
    airline: "",
    prefered_language: [],
    tip_expected: "",
    booking_status: "",
  });

  const [langValue,setLangValue]=useState("")
  const [langResponse,setLangResponse]=useState([])


  const [airlineValue,setAirlineValue]=useState("")
  const [airlineResponse,setairlineResponse]=useState([])

    //------------------- Date Range From---------------------------//
    const [openAirline, setopenAirline] = React.useState(false);
  //------------------- Date Range From---------------------------//
  
  
  //------------------- Date Range From---------------------------//
  const [openlang, setOpenLang] = React.useState(false);
  //------------------- Date Range From---------------------------//


  const TipAmtMenu = () => {
    setTipAmtState(!tipAmtState);
  };

  const genderMenu = () => {
    setGenderState(!genderState);
  };

  const bookingStatusMenu = () => {
    setBookingStatus(!bookingstatus);
  };


  const handleSubmit = (values) => {
    // event.preventDefault();
    console.log(values,"valuesinfilter")
    let finalFilter={}
    Object.entries(values).map((field)=>{
    if(field[0]==="prefered_language"){
     
        if(field[1].length>0){
          const array = []
        field[1].map((lang) => {
          array.push(lang.name);
        });
        finalFilter[`${field[0]}`]=array
      }else{
        finalFilter[`${field[0]}`]=[]
      }
        
    }
    else if(field[0]==="airline"){
        if(field[1]!==""&&field[1]!==null){
          finalFilter[`${field[0]}`]=`${field[1].label}`
        }else{
          finalFilter[`${field[0]}`]=""

        }
        

    }
    else{
      finalFilter[`${field[0]}`]=field[1]
    }
     

    })
    
console.log(finalFilter,"finalFilter")
    props.onSubmit(finalFilter);
    
    setBookingStatus(false);
    setAirlinesState(false);
    setTipAmtState(false);
    // console.log(allFieldValues ,"rrrrrrrrrrrr")
  };



  
console.log(airlineValue,"airlineValue")
  

  return (
    <>
      <Container>
        <Formik
        initialValues={{
           airline: "",
           prefered_language: [],
           tip_expected: "",
           booking_status: "",
           gender:""
        }}
        onSubmit={handleSubmit}
        >
          {
            (formik)=>{
              const {values,setFieldValue}=formik

              const tipamountHandler=(e)=>{
                const{name,value}=e.target
                if(values.tip_expected===value){
                  setFieldValue(name,"")
                  cancelFilter(name,"")  
                }
                else{
                  setFieldValue(name,value)
                  cancelFilter(name,value)  

                }
                  
     
            
              }


              const cancelFilter=(name,value)=>{
                
                let isValue=false
                Object.entries(values).map((field)=>{
                  if(field[0]==="prefered_language"){
                         if(field[1].length!==0){
                          if(name==="prefered_language"&&value.length===0)
                              isValue=false
                           else   
                           isValue=true
                         }
                         
                  }else{
                     if(field[1]!==""){
                      if(value===""&&name===field[0]){
                        isValue=false
                      }
                      else{
                        isValue=true
                      }
                     }
                     else{
                      if(value) isValue=true
                     }
                  }
                })
                // console.log(isValue,"flagincancel")
                console.log(values,name,value,isValue,"valuesIncancel")
                if(isValue===false){
                  props.onSubmit({  
                    airline: "",
                    prefered_language: [],
                    tip_expected: "",
                    booking_status: "",
                    gender:""
                 });
                }

              }

              console.log("filterValues",values)
              return (
                <Form>
          <div className="filter-main-desktop">
            <div className="filter-second">
              <div className="filter-icon-section">
                <SortSharpIcon
                  fontSize="large"
                  style={{ fill: "white" }}
                  className=" SortSharpIcon"
                />
              </div>

              <div className="filter-text-section">Filters....</div>
            </div>
           

            <div style={{ display: "flex" }}>
              <div>
                <TravelExploreIcon
                  style={{ marginLeft: 10, marginTop: 7, fill: "#1ec28b" }}
                />
              </div>
              <div className="tipamt-listed">
                <ListItemButton onClick={bookingStatusMenu}>
                  <ListItemText
                    primary="Booking Status"
                    style={{ marginTop: 0, marginBottom: 0 }}
                  />
                  {airlinesState ? (
                    <ArrowDropUpTwoToneIcon style={{ fill: "#1ec28b" }} />
                  ) : (
                    <ArrowDropDownTwoToneIcon style={{ fill: "#1ec28b" }} />
                  )}
                </ListItemButton>
                <Collapse in={bookingstatus} timeout="auto" unmountOnExit>
                  <Grid className="checkBox">
                  <input
                    type="checkbox"
                    name="booking_status"
                    id="booked"
                    value={1}
                    checked={values["booking_status"] === 1}
                    onClick={(e) => {
                      const{name,value}=e.target
                      if(values.booking_status===1){
                        setFieldValue(name,"")
                        cancelFilter(name,"") 
                      }
                      else{
                        setFieldValue(name,parseInt(value))
                        cancelFilter(name,parseInt(value)) 

                      }

                      
                    }}
                  />
                   <label>Booked</label>
                  </Grid>
                  <Grid className="checkBox">
                  <input
                    type="checkbox"
                    id="yet to book"
                    name="booking_status"
                    value={2}
                    checked={values["booking_status"] === 2}
                    onClick={(e) => {
                      const{name,value}=e.target
                      if(values.booking_status===2){
                        setFieldValue(name,"")
                        cancelFilter(name,"")  
                      }
                      else{
                        setFieldValue(name,parseInt(value))
                        setFieldValue("airline","")
                        cancelFilter(name,parseInt(value))  
                      }
                    }}               />
                  <label>Yet to book</label>
                  </Grid>
                </Collapse>
              </div>
            </div>
            <hr />
            <div style={{ display: "flex" }}>
              <FlightIcon
                style={{ marginLeft: 10,marginRight: 10, marginTop: 7, fill: "#1ec28b" }}
                />
              <Autocomplete
              popupIcon={<Icon icon="gridicons:dropdown" style={{color:"#1ec28b",fontSize:25}} />}
                sx={{ width: 162 }}
                options={airlineResponse}
                autoHighlight
                open={openAirline}
                onOpen={() => {
                  if (airlineValue) {
                    setopenAirline(true);
                  }
                }}
                onClose={() => setopenAirline(false)}
                name="airline"
                disabled={values.booking_status===2}
                // freeSolo={true}
                size={"small"}
                value={values.airline ? values.airline : null}
                onChange={(e, value) => {
                  console.log(value,"airlinevalue")
                  if(value){
                    setFieldValue("airline", value);
                  cancelFilter("airline",value)  
                }else{
                  setFieldValue("airline", value);
                  cancelFilter("airline","")  

                }
                  
                }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      "& > img": { mr: 2, flexShrink: 0 },
                    }}
                    {...props}
                  >
                    <img loading="lazy" width="20" src={option.logo} alt="" />
                    {option.label}
                  </Box>
                )}
                onInputChange={(e, value, reason) => {
                 setAirlineValue(value)
                 if(!value){
                  setAirlineValue("")
                  setopenAirline(false)
                 }
                 getAirlines(value).then(res=>{
                   if(res.data.message)
                      setairlineResponse([])
                    else   
                     setairlineResponse(res.data.response)    
               })
                }}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                            {...params}
                            label="Airline"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                )}
              />
            </div>
            <hr />
            <div style={{ display: "flex" }}>
              <LanguageRoundedIcon
                style={{ marginLeft: 10,marginRight: 10, marginTop: 7, fill: "#1ec28b" }}
              />
              {/* {console.log(allFieldValues,allFieldValues.prefered_language,"allFieldValues.prefered_language")} */}
              <Autocomplete
                popupIcon={<Icon icon="gridicons:dropdown" style={{color:"#1ec28b",fontSize:25}} />}
                id="language-select"
                sx={{ width: 162 }}
                options={langResponse}
                name="prefered_language"
                multiple={true}
                size={"small"}
                open={openlang}
                onOpen={() => {
                  if (langValue) {
                    setOpenLang(true);
                  }
                }}
                onClose={() => setOpenLang(false)}
                // freeSolo={true}
                // autoHighlight
                value={values.prefered_language?values.prefered_language:null}
                onChange={(e, value) => {
                  console.log(value,"languagevalue")
                  if(value.length>0){
                    setFieldValue("prefered_language", value);
                    cancelFilter("prefered_language",value)  
                  }else{
                  setFieldValue("prefered_language", value);
                  cancelFilter("prefered_language","")  

                }
                }}
                onInputChange={(e, value, reason) => {
                  setLangValue(value)
                  if(!value){
                    setLangValue("")
                    setOpenLang(false)
                  }
                  getLanguages(value).then(res=>{
                    if(res.data.message)
                       setLangResponse([])
                    else    
                       setLangResponse(res.data.response)
                  })
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                            {...params}
                            label="Languages"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                )}
              />
            </div>
            <hr />

            <div style={{ display: "flex" }}>
              <div>
                <PriceChangeOutlinedIcon
                  style={{ marginLeft: 10, marginTop: 7, fill: "#1ec28b" }}
                />
              </div>
              <div className="tipamt-listed">
                <ListItemButton onClick={TipAmtMenu}>
                  <ListItemText
                    primary="Tip Amount"
                    style={{ marginTop: 0, marginBottom: 0 }}
                  />
                  {airlinesState ? (
                    <ArrowDropUpTwoToneIcon style={{ fill: "#1ec28b" }} />
                  ) : (
                    <ArrowDropDownTwoToneIcon style={{ fill: "#1ec28b" }} />
                  )}
                </ListItemButton>
                <Collapse in={tipAmtState} timeout="auto" unmountOnExit>
                  <Grid className="checkBox">
                    <input
                      type="checkbox"
                      name="tip_expected"
                      id="tip_expected"
                      value="50"
                      checked={values["tip_expected"] === "50"}
                      onClick={(e) => tipamountHandler(e)}
                    />
                    <label>50 USD</label>
                  </Grid>
                  <Grid className="checkBox">
                    <input
                      type="checkbox"
                      name="tip_expected"
                      id="tip_expected"
                      value="75"
                      checked={values["tip_expected"] === "75"}
                      onClick={(e) => tipamountHandler(e)}
                    />
                    <label>75 USD</label>
                  </Grid>
                  <Grid className="checkBox">
                    <input
                      type="checkbox"
                      name="tip_expected"
                      id="tip_expected"
                      value="100"
                      checked={values["tip_expected"] === "100"}
                      onClick={(e) => tipamountHandler(e)}
                    />
                    <label>100 USD</label>
                  </Grid>
                  <Grid className="checkBox">
                    <input
                      type="checkbox"
                      name="tip_expected"
                      id="tip_expected"
                      value="Just helping"
                      checked={values["tip_expected"] === "Just helping"}
                      onClick={(e) => tipamountHandler(e)}
                    />
                    {/* "Just helping">Just helping */}
                    <label>Just helping</label>
                  </Grid>
                  <Grid className="checkBox">
                    <input
                      type="checkbox"
                      name="tip_expected"
                      id="tip_expected"
                      value="Negotiable"
                      checked={values["tip_expected"] === "Negotiable"}
                      onClick={(e) => tipamountHandler(e)}
                    />
                    <label>Negotiable</label>
                  </Grid>
                  


                </Collapse>
              </div>
            </div>
            <hr />
            <div style={{ display: "flex" }}>
              <div>
                <WcIcon
                  style={{ marginLeft: 10, marginTop: 7, fill: "#1ec28b" }}
                />
              </div>
              <div className="tipamt-listed">
                <ListItemButton onClick={genderMenu}>
                  <ListItemText
                    primary="Gender"
                    style={{ marginTop: 0, marginBottom: 0 }}
                  />
                  {airlinesState ? (
                    <ArrowDropUpTwoToneIcon style={{ fill: "#1ec28b" }} />
                  ) : (
                    <ArrowDropDownTwoToneIcon style={{ fill: "#1ec28b" }} />
                  )}
                </ListItemButton>
                <Collapse in={genderState} timeout="auto" unmountOnExit>
                  <Grid className="checkBox">
                    <input
                      type="checkbox"
                      name="gender"
                      id="gender"
                      value="Male"
                      checked={values["gender"] === "Male"}
                      onClick={(e) => {
                        const{name,value}=e.target
                        if(values[name]==="Male"){
                          setFieldValue(name,"")
                          cancelFilter(name,"") 
                        }
                        else{
                          setFieldValue(name,value)
                          cancelFilter(name,value) 
                        }
                      }}
                    />
                    <label>Male</label>
                  </Grid>
                  <Grid className="checkBox">
                    <input
                      type="checkbox"
                      name="gender"
                      value="Female"
                      checked={values["gender"] === "Female"}
                      onClick={(e) => {
                      const{name,value}=e.target
                      if(values[name]==="Female"){
                        setFieldValue(name,"")
                        cancelFilter(name,"") 
                      }
                      else{
                        setFieldValue(name,value)
                        cancelFilter(name,value) 
                      }
                    }}
                    />
                    <label>Female</label>
                  </Grid>
                </Collapse>
              </div>
            </div>
            <hr />
            <div>
              <Button className="apply-filter-btn" type="submit">
                Apply Filter
              </Button>
            </div>
          </div>
        </Form>
              )
            }
          }
        </Formik>
        
      </Container>
    </>
  );
};

export default Filter;