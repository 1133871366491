import React, {useEffect, useState} from "react";
import Adminsidebar from "./Adminsidebar";
import "../../src/AdminDashboard/CSS/admindashboard.css";
import {DatePicker, Space} from "antd";
import dayjs from "dayjs";
import {
  Card,
  Grid,
  Breadcrumbs,
  Typography,
  Link,
  Container,
  Button,
  CircularProgress,
} from "@mui/material";
import Box from "@mui/material/Box";
import {Icon} from "@iconify/react";
import AdminDashBarGraph from "./AdminDashBarGraph";
import "react-circular-progressbar/dist/styles.css";
import axios from "axios";
import AdminHeader from "./Components/AdminHeader";

function Admindashboard() {

  const firstDay = dayjs().startOf('week');
  const [weekwise, setWeekwise] = useState({
    startDate: firstDay,
    endDate: firstDay.add(6, "day"),
  });
  const [datewise, setDatewise] = useState("");
  const [barweekwise, setbarWeekwise] = useState({
    startDate: firstDay,
    endDate: firstDay.add(6, "day"),
  });
  const [bardatewise, setbarDatewise] = useState("");
  const [weekDetails, setWeekDetails] = useState({});
  const [createdTrips,setCreatedTrips]=useState()
  const [admindashData, setadmindashData] = useState([]);
  const [currentWeek_data,setCurrentWeek_data]=useState([])






  const {RangePicker} = DatePicker;
  useEffect(() => {
    let domain = "";
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }

    axios
      .get(`${domain}/admin/dashboard/`)
      .then(response => {

        if (response.data) {
          console.log(response.data, "profile response data");
          setadmindashData(response.data);
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
        }
      });
    showResults("useEffect");
    BarshowResults();
  }, []);

  const showResults = (loc) => {
    let domain = "";
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }

    if (!datewise) {
      axios
        .get(
          `${domain}/admin/dashboard/?from_date=${weekwise.startDate.format(
            "DD-MM-YYYY"
          )}&to_date=${weekwise.endDate.format("DD-MM-YYYY")}`
        )
        .then(response => {
          if(loc!=="useEffect")
               setWeekDetails(response.data["trips_created(week_or_date_wise)"]);
          else setCurrentWeek_data(response.data["trips_created(week_or_date_wise)"])
        });
    } else {
      axios
        .get(`${domain}/admin/dashboard/?date=${datewise.format("DD-MM-YYYY")}`)
        .then(response => {
          console.log(response, "aaaa");
          setWeekDetails(response.data);
        });
    }
  };
  const BarshowResults = () => {
    let domain = "";
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    if (!bardatewise) {
      axios
        .get(
          `${domain}/admin/dashboard/?user_from_date=${barweekwise.startDate.format(
            "DD-MM-YYYY"
          )}&user_to_date=${barweekwise.endDate.format("DD-MM-YYYY")}`
        )
        .then(response => {
          const data = response.data["trips_created(date_wise)"];
          let array=[]
          Object.entries(data).map((item)=>{
            let obj={
              day:dayjs(item[0]).format("DD"),
              IAC:item[1].iam_a_travelcompanion,
              NAC:item[1].need_a_travelcompanion
            }
            array.push(obj)

          })
          setCreatedTrips(array);
        });
    } else {
      axios
        .get(
          `${domain}/admin/dashboard/?user_date=${bardatewise.format(
            "DD-MM-YYYY"
          )}`
        )
        .then(response => {
          const data=response.data
          let array=[];
          Object.entries(data).map((item)=>{
            let obj={
              day:dayjs(item[0]).format("DD"),
              IAC:item[1].iam_a_travelcompanion,
              NAC:item[1].need_a_travelcompanion
            }
            array.push(obj)
          })
         setCreatedTrips(array);
        });
    }
  };

  function findDuplicateWords(value) {
    const startDate=dayjs(value).startOf("week").format("MMM DD, YYYY");
    const endDate=dayjs(value).endOf("week").format("MMM DD, YYYY");
    let sentence=`${startDate} to ${endDate}`
    const wordArray = sentence.match(/\w+/g);
    const wordMap = new Map(); 
    let dateString ="";
    wordArray.forEach(word => {
      if (!wordMap.has(word)) {
        dateString=`${dateString} ${word}`;
        wordMap.set(word, 1);
      } else {
        if(/^-?\d+(\.\d+)?(e[-+]?\d+)?$/i.test(word)&&word.length===4){
          dateString=`${dateString.replace(word,"")}, ${word}`      
        }
        wordMap.set(word, wordMap.get(word) + 1);
      }
    });
  
    return dateString.replace("  "," ");
  }

const customWeekStartEndFormat = value =>{
 
    return value?`Select Week: ${findDuplicateWords(value)}`:"Select Week: Mon dd to dd, YYYY";
  }
    
 const customDayStartEndFormat = value =>
      `Select Day: ${value.format("DD/MM/YYYY")}`;

const dateHandler = (value, picker,dayFun,weekFun) => {
   
 const date=value.split(":")[1]
  console.log(value,"days")
    if (value) {
      if(picker==="week"){
        let startDate='';
        let endDate="";
        const days=[
          date.match(/(?<!\d)\d{2}(?!\d)/g),
          date.match(/([A-Z][a-z]{2})/g),
          date.match(/\d{4}/g)
        ]
        console.log(days,"days")
        days.map((dayArray,index)=>{
          switch(index){
            case 0:
              startDate=dayArray[0]
              endDate=dayArray[1]
              break;
            case 1:
              startDate=`${startDate}/${dayArray[0]}`
              endDate=`${endDate}/${dayArray[1]?dayArray[1]:dayArray[0]}`
              break;
            case 2:
              startDate=`${startDate}/${dayArray[0]}`
              endDate=`${endDate}/${dayArray[1]?dayArray[1]:dayArray[0]}`
              break;
          }

        })
        console.log(startDate,endDate,"ssss")
        weekFun({
          startDate:dayjs(startDate),
          endDate:dayjs(endDate),
        })
        dayFun("");
      }else{
        const regex = /\d{2}\/\d{2}\/\d{4}/;
        const found = value.match(regex);
        dayFun(dayjs(found[0],"DD/MM/YYYY"));
        weekFun("")
      };
    }}

  return (
    <>
      <AdminHeader></AdminHeader>
      <div className="admindashboard-profile-main">
        <div style={{borderRight: "groove", minHeight: "90vh"}}>
          <Adminsidebar />
        </div>
        <div style={{width: "100%", backgroundColor: "#f5f5f5"}}>
          <Container>
            <div className="admindashboard-heading">
              Travel Companion Dashboard
            </div>
            <div>
              <Breadcrumbs
                className="admindashboard-icon"
                separator="›"
                aria-label="breadcrumb"
              >
                <Link to={""} underline="hover" color="inherit" href=">">
                  Home
                </Link>
                <Typography className="admin-subhead">Dashboard</Typography>
              </Breadcrumbs>
            </div>

            <Container>
              {admindashData && Object.entries(admindashData).length > 0 && (
                <Box className="admindashboard-box">
                  <div className="admindashboard-register">
                    Registered Users
                  </div>
                  <Card
                    className="admindashboard-card1"
                    sx={{
                      "&.css-bhp9pd-MuiPaper-root-MuiCard-root ": {
                        boxShadow: "none",
                      },
                    }}
                  >
                    <div>
                      <p className="admindashboard-para1">Total Number of</p>
                      <p className="admindashboard-subpara1">
                        {" "}
                        Registered Users
                      </p>
                    </div>
                    <div className="admindashboard-highlight">
                      {admindashData.total_number_of_users}
                    </div>
                  </Card>
                  <div className="admindashboard-circularinputs">
                    <Grid
                      container
                      spacing={1}
                      className="admindashboard-gap-alignment"
                    >
                      <Grid item lg={4} xl={4} md={4} xs={4} sm={4}>
                        <div className="admindashboard-circularinputsouter">
                          <CircularProgress
                            size="7rem"
                            variant="determinate"
                            value={9}
                            thickness={1}
                            className="admindashboard-circularinputsprogressbar"
                          />
                          <Typography className="admindashboard-circularinputsTextMain">
                            <div className="admindashboard-circularinputsText">
                              Registered Users
                              <br /> in UK
                            </div>
                            <div className="admindashboard-counts">
                              {admindashData.registered_users_in_uk}
                            </div>{" "}
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={4} xl={4} md={4} xs={4} sm={4}>
                        {" "}
                        <div className="admindashboard-circularinputsouter">
                          <CircularProgress
                            variant="determinate"
                            value={78}
                            thickness={1}
                            className="admindashboard-circularinputsprogressbar"
                          />
                          <Typography className="admindashboard-circularinputsTextMain">
                            <div className="admindashboard-circularinputsText">
                              Registered Users
                              <br /> in India
                            </div>
                            <div className="admindashboard-counts">
                              {admindashData.registered_users_in_india}
                            </div>
                          </Typography>
                        </div>
                      </Grid>
                      <Grid item lg={4} xl={4} md={4} xs={4} sm={4}>
                        {" "}
                        <div className="admindashboard-circularinputsouter">
                          <CircularProgress
                            variant="determinate"
                            value={14}
                            thickness={1}
                            className="admindashboard-circularinputsprogressbar"
                          />
                          <Typography className="admindashboard-circularinputsTextMain">
                            <div className="admindashboard-circularinputsText">
                              Registered Users
                              <br /> in USA
                            </div>
                            <div className="admindashboard-counts">
                              {admindashData.registered_users_in_usa}
                            </div>
                          </Typography>
                        </div>
                      </Grid>
                    </Grid>
                  </div>

                  <div className="admindashboard-register">
                    Trips Created during this week
                  </div>
                  <Card className="admindashboard-card2">
                    <div>
                      <p className="admindashboard-para1">Total Number of</p>
                      <p className="admindashboard-subpara1">
                        {" "}
                        Trips created during this week
                      </p>
                    </div>
                    <div className="admindashboard-highlight">
                     {currentWeek_data?.total_number_of_trips}
                    </div>
                  </Card>
                  <Grid
                    container
                    spacing={2}
                    style={{marginTop: 30, marginLeft: -13}}
                  >
                    <Grid item lg={4} xl={4} md={4} xs={4} sm={4}>
                      <Card className="admindashboard-grid1">
                        <div className="admindashboard-place1">
                        {currentWeek_data?.trips_created_in_uk}
                        </div>
                        <div className="admindashboard-grid-subpara1">
                          Trips Created in UK
                        </div>
                      </Card>
                    </Grid>
                    <Grid item lg={4} xl={4} md={4} xs={4} sm={4}>
                      <Card className="admindashboard-grid1">
                        <div className="admindashboard-place1">
                        {currentWeek_data?.trips_created_in_india}
                        </div>
                        <div className="admindashboard-grid-subpara1">
                          Trips Created in India
                        </div>
                      </Card>
                    </Grid>
                    <Grid item lg={4} xl={4} md={4} xs={4} sm={4}>
                      <Card className="admindashboard-grid1">
                        <div className="admindashboard-place1">
                          {/* {admindashData.trips_created_in_usa} */}
                          {currentWeek_data?.trips_created_in_usa}
                        </div>
                        <div className="admindashboard-grid-subpara1">
                          Trips Created in USA
                        </div>
                      </Card>
                    </Grid>
                  </Grid>

                  <div className="admindashboard-register">Trips Created</div>
                  <Card className="admindashboard-card3">
                    <div>
                      <p className="admindashboard-para1">Total Number of</p>
                      <p className="admindashboard-subpara1">Trips Created</p>
                    </div>
                    <div className="admindashboard-highlight">
                      {admindashData.total_number_of_trips_created}
                    </div>
                  </Card>
                  <Grid container spacing={2} style={{marginTop: 30}}>
                    <Grid item lg={4} xl={4} md={4} xs={4} sm={4}>
                      <Card className="admindashboard-grid1">
                        <div className="admindashboard-place1">
                          {admindashData.trips_created_in_uk}
                        </div>
                        <div className="admindashboard-grid-subpara1">
                          Trips Created in UK
                        </div>
                      </Card>
                    </Grid>
                    <Grid item lg={4} xl={4} md={4} xs={4} sm={4}>
                      <Card className="admindashboard-grid1">
                        <div className="admindashboard-place1">
                          {" "}
                          {admindashData.trips_created_in_india}
                        </div>
                        <div className="admindashboard-grid-subpara1">
                          Trips Created in India
                        </div>
                      </Card>
                    </Grid>
                    <Grid item lg={4} xl={4} md={4} xs={4} sm={4}>
                      <Card className="admindashboard-grid1">
                        <div className="admindashboard-place1">
                          {admindashData.trips_created_in_usa}
                        </div>
                        <div className="admindashboard-grid-subpara1">
                          Trips Created in USA
                        </div>
                      </Card>
                    </Grid>
                  </Grid>

                  <div className="admindashboard-register">
                    Trips Created (Week or Date wise)
                  </div>
                  <Grid
                    container
                    spacing={2}
                    className="admindashboard-tripceated-datewise"
                  >
                    <Grid item lg={5.5} xl={5.5} md={5.5} xs={5.5} sm={5.5}>
                      <Card className="admindashboard-tripsdatecard">
                        <div className="admindashboard-weekanddate">
                          Trip Created Details during this Week/Day
                        </div>
                        <Grid
                          container
                          spacing={2}
                          style={{marginBottom: 20, fontSize: 20}}
                        >
                          <Grid
                            className="admindashboard-trip1"
                            item
                            lg={3}
                            xl={3}
                            md={3}
                            xs={3}
                            sm={3}
                          >
                            {weekDetails.total_number_of_trips}
                          </Grid>
                          <Grid
                            className="admindashboard-total-trip1"
                            item
                            lg={9}
                            xl={9}
                            md={9}
                            xs={9}
                            sm={9}
                            style={{textAlign: "justify"}}
                          >
                            Total No. of Trips
                          </Grid>
                          <Grid
                            className="admindashboard-trip1"
                            item
                            lg={3}
                            xl={3}
                            md={3}
                            xs={3}
                            sm={3}
                          >
                            {weekDetails.trips_created_in_india}
                          </Grid>
                          <Grid
                            className="admindashboard-total-trip1"
                            item
                            lg={9}
                            xl={9}
                            md={9}
                            xs={9}
                            sm={9}
                            style={{textAlign: "justify"}}
                          >
                            Trips Created in
                            <span className="admindashboard-trip-place1">
                              {" "}
                              India
                            </span>
                          </Grid>
                          <Grid
                            className="admindashboard-trip1"
                            item
                            lg={3}
                            xl={3}
                            md={3}
                            xs={3}
                            sm={3}
                          >
                            {weekDetails.trips_created_in_usa}
                          </Grid>
                          <Grid
                            className="admindashboard-total-trip1"
                            item
                            lg={9}
                            xl={9}
                            md={9}
                            xs={9}
                            sm={9}
                            style={{textAlign: "justify"}}
                          >
                            Trips Created in
                            <span className="admindashboard-trip-place1">
                              {" "}
                              USA
                            </span>
                          </Grid>
                          <Grid
                            className="admindashboard-trip1"
                            item
                            lg={3}
                            xl={3}
                            md={3}
                            xs={3}
                            sm={3}
                          >
                            {weekDetails.trips_created_in_uk}
                          </Grid>
                          <Grid
                            className="admindashboard-total-trip1"
                            item
                            lg={9}
                            xl={9}
                            md={9}
                            xs={9}
                            sm={9}
                            style={{textAlign: "justify"}}
                          >
                            Trips Created in
                            <span className="admindashboard-trip-place1">
                              {" "}
                              UK
                            </span>
                          </Grid>
                        </Grid>
                      </Card>
                    </Grid>

                    <Grid
                      item
                      lg={5.5}
                      xl={5.5}
                      md={5.5}
                      xs={5.5}
                      sm={5.5}
                      className="admindashboard-dategrid"
                    >
                      <div className="custom-date-picker">
                        <Space direction="vertical" size={15}>
                          <DatePicker
                            className="custom-date-text-alignment"
                            format={customWeekStartEndFormat}
                            picker="week"
                            onChange={(e, value,date) =>
                              dateHandler(value,"week",setDatewise,setWeekwise)
                            }
                            placeholder={"Select Week: Mon dd to dd, YYYY"}
                            value={weekwise.startDate}
                            suffixIcon={
                              <Icon
                              icon="simple-line-icons:calender"
                              color="#545454"
                              width="20"
                              height="20"
                              />
                            }
                          />
                        </Space>
                      </div>


                      <div
                        style={{
                          marginBottom: 18,
                          marginTop: 19,
                          fontStyle: "italic",
                          fontWeight: 600,

                          marginLeft: -30,
                        }}
                      >
                        OR
                      </div>


                      <div className="custom-date-picker small-size">
                        <Space direction="vertical" size={15}>
                          <DatePicker
                            className="custom-date-text-alignment"
                            format={customDayStartEndFormat}
                            picker="day"
                            onChange={(e, value) =>
                              dateHandler(value,"day",setDatewise,setWeekwise)
                            }
                            placeholder={"Select Day: DD/MM/YYYY"}
                            value={datewise}
                            suffixIcon={
                              <Icon
                              icon="simple-line-icons:calender"
                              color="#545454"
                              width="20"
                              height="20"
                              />
                            }
                          />
                        </Space>
                      </div>
                      <div style={{marginTop: 35}} className="dashboard-btn-container">
                        <button
                          className="dashboard-btn"
                          onClick={()=>showResults("button")}
                          type="button"
                        >
                          Show Results
                        </button>
                      </div>
                    </Grid>
                  </Grid>

                  <div className="admindashboard-register">
                    Trip Created Need a Companion{" "}
                    <i
                      style={{
                        fontWeight: 400,
                      }}
                    >
                      vs
                    </i>{" "}
                    I am a Companion
                  </div>
                  <Grid
                    container
                    spacing={2}
                    className="admindashboard-tripceated-datewise"
                  >
                    <Grid item lg={5.5} xl={5.5} md={5.5} xs={5.5} sm={5.5}>
                      <Card className="admindashboard-tripsbarcard">
                      <div className="squareBar-dashboard"></div>
                        <AdminDashBarGraph 
                        state={createdTrips} 
                        dates={bardatewise?bardatewise:barweekwise}
                        dateFormatter={customWeekStartEndFormat}
                         />
                      </Card>
                    </Grid>

                    <Grid
                      item
                      lg={5.5}
                      xl={5.5}
                      md={5.5}
                      xs={5.5}
                      sm={5.5}
                      className="admindashboard-dategrid"
                    >
                      <div className="custom-date-picker">
                        <Space direction="vertical" size={12}>
                          <DatePicker
                            format={customWeekStartEndFormat}
                            picker="week"
                            onChange={(e, value) =>
                              dateHandler(value,"week", setbarDatewise, setbarWeekwise)
                            }
                            placeholder={"Select Week: Mon dd to dd, YYYY"}
                            value={barweekwise.startDate}
                            suffixIcon={
                              <Icon
                              icon="simple-line-icons:calender"
                              color="#545454"
                              width="20"
                              height="20"
                              />
                            }
                          />
                        </Space>
                      </div>


                      <div
                        style={{
                          marginBottom: 18,
                          marginTop: 19,
                          fontStyle: "italic",
                          fontWeight: 600,
                          marginLeft: -30,
                        }}
                      >
                        OR
                      </div>
                      <div className="custom-date-picker small-size">
                        <Space direction="vertical" size={15}>
                          <DatePicker
                            className="custom-date-text-alignment"
                            format={customDayStartEndFormat}
                            onChange={(e, value) =>
                              dateHandler(value,"day", setbarDatewise, setbarWeekwise)
                            }
                            placeholder={"Select Day: DD/MM/YYYY"}
                            // value={bardatewise}
                            suffixIcon={
                              <Icon
                              icon="simple-line-icons:calender"
                              color="#545454"
                              width="15"
                              height="15"
                              />
                            }
                          />
                        </Space>
                      </div>
                      <div style={{marginBottom: 26, marginTop: 33}} className="dashboard-btn-container">
                        <button
                          className="dashboard-btn"
                          onClick={BarshowResults}
                          type="button"
                        >
                          Show Results
                        </button>
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Container>
          </Container>
        </div>
      </div>
    </>
  );
}

export default Admindashboard;