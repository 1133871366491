import React, { useState } from "react";
import { Icon } from "@iconify/react";
import "../../CSS/navbar.css";
// import { NavLink } from "react-bootstrap";
import {
  Badge,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Menu,
} from "@mui/material";
import { NavLink, Link } from "react-router-dom";
import DrawerComponent from "./DrawerComponent";
import {
  AppBar,
  Toolbar,
  CssBaseline,
  Typography,
  useTheme,
  useMediaQuery,
} from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useEffect } from "react";
import axios from "axios";
import SidebarDesktopMenu from "../../Dashboard/SidebarDesktopMenu";
function NavBar() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dashboardProfile, setdashboardProfile] = useState([]);
  const [headerNotification, setHeaderNotification] = useState(0);
  const [canCreate,setCancreate]=useState(Boolean(JSON.parse(localStorage.getItem("can"))))

  const [invitopen, setinvitopen] = React.useState(false);
  const handleinvitClose = () => {
    setinvitopen(false);
  };
  const handleClickk = () => {
    if (!isLoggedIn) {
         setinvitopen(true);
    }
    else

    if(!canCreate)
    setinvitopen(true);


  };


 useEffect(() => {
  
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");
  
  if(isLoggedIn){
     
        axios.get(`${domain}/upcoming-trips-count/${user.user_id}/`)
      .then((countResponse)=>{
              console.log(countResponse,"countResponse_in_header")
             
              localStorage.setItem("can", JSON.stringify(countResponse.data.can_create))
      }).catch(e=>{
        console.log(e,"countError")
      })
      
  
    }

  }, []);






  useEffect(() => {

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");
	
	


    if (isLoggedIn) {
      axios
        .get(
          `${domain}/user/profile/${user.user_id}/`
        )
        .then((response) => {
          console.log(response, "dashboard img response");

          if (response.data) {
            console.log(response.data, "dashboard img response data");
            setdashboardProfile(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            console.log("data : ", error.response.data.message);
          }
        });
    }
  }, []);

  const HandleHeaderNotification = () => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");
	
	
    if (isLoggedIn) {
      axios
        .get(
          `${domain}/notifications/unseen/${user.user_id}/`
        )
        .then((response) => {
          console.log(response, "notification response");
          console.log(response.data.unread_count, "not response");
          if (response.data) {
            console.log(response.data, "notification response data");
            setHeaderNotification(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            console.log("notification : ", error.response.data.message);
          }
        });
    }
  };

  useEffect(() => {
    HandleHeaderNotification();
  }, []);

  return (
    <>
      <Container style={{ display: "initial" }}>
        <div>
          <Container style={{ padding: "inherit" }} className="main-header-container">
            <AppBar
              position="sticky"
              style={{
                backgroundColor: "#ffffff",
                boxShadow: "1px 1px 1px #ffffff",
                marginTop: 15,
              }}
            >
              <Toolbar>
                <Link to="/" className="nav-logo">
                  <img
                    src="./Assets/Images/desi-travel-campanion-logo.png"
                    alt="desi-travel-campanion-logo"
                    className="navbarImage"
                  />
                </Link>

                {isMobile ? (
                  <DrawerComponent />
                ) : (
                  <div className="links-main">
                    <Link
                  to={isLoggedIn
                    &&Boolean(JSON.parse(localStorage.getItem("can")))
                    ?"/create-a-trip":''}
                  className="create-links"
                      onClick={handleClickk}
                    >
                      <b>Create a Trip</b> &nbsp;&nbsp;
                    </Link>
                    <Link 
                    to="/companion-search" className="connect-links"
                    >
                      <b>Companion Search</b> &nbsp;&nbsp;
                    </Link>
                    {isLoggedIn ? (
                      <>
                        <Link to="/notification">
                          <Badge
                            color="error"
                            badgeContent={headerNotification.unread_count}
                            sx={{
                              "& .MuiBadge-badge": {
                                backgroundColor: "red",
                                width: "5px",
                                transform: "translate(5px, -5px)",
                              },
                            }}
                          >
                            <Icon
                              style={{ fontSize: 30 }}
                              activeClassName="active"
                              className="nav-links-notofication-icon"
                              onClick={handleClick}
                              icon="clarity:notification-solid"
                            />
                          </Badge>
                        </Link>
                        &nbsp;&nbsp;
                        {dashboardProfile.map((post, index) => {
                          return (
                            <div className="nav-item-header-icon-container">
                              <div
                                className="nav-item-header-icon-inner"
                                onClick={handleOpen}
                              >
                                <img
                                  className="nav-item-header-icon-Img"
                                  variant="top"
                                  src={
                                    post.profile_picture == null
                                      ? "./Assets/Images/Favicon 25 WB _ DV.webp"
                                      : post.profile_picture
                                  }
                                  alt="..."
                                />
                              </div>
                            </div>
                          );
                        })}
                      </>
                    ) : (
                      <>
                        <Link
                          to="/login"
                          className="nav-logo"
                          style={{ textDecoration: "none" }}
                        >
                          <Button
                            style={{ borderRadius: 5, height: 30 }}
                            onClick={handleClick}
                            activeClassName="active"
                            className="nav-links-button-login"
                          >
                            Login
                          </Button>
                        </Link>
                        &nbsp;&nbsp;
                        <a href="https://www.facebook.com/gDesiTravelCompanion" target="_blank">
                          <Icon
                            icon="fa:facebook-official"
                            style={{ fontSize: 30 }}
                            className="nav-links-notofication-icon"
                          />
                        </a>
                      </>
                    )}
                  </div>
                )}
                <Menu
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  transformOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                  }}
                  PaperProps={{
                    style: {
                      width: "25ch",
                      background: "#ffffff",
                    },
                  }}
                  className="dashboard-img-icon-modal"
                >
                  <SidebarDesktopMenu></SidebarDesktopMenu>
                </Menu>
              </Toolbar>
            </AppBar>
          </Container>
        </div>
      </Container>
      <div>
        <Container>
          <Dialog
            open={invitopen}
            onClose={handleinvitClose}
            // {/* // aria-labelledby="alert-dialog-title"
            // // aria-describedby="alert-dialog-description" */}
            PaperProps={{
              style: {
                minHeight: "40vh",
                minWidth: "35vw",
                boxShadow: "none",
                border: "2px solid rgb(128 213 184 / 100%)",
                strokeWidth: "1px",
                stroke: "rgb(128 213 184 )",
                borderRadius: 20,
              },
            }}
            BackdropProps={{
              style: {
                backdrop: "blur(2px)",
              },
            }}
          >
            <div>
              <DialogTitle id="alert-dialog-title">
                {/* {handleinvitClose ? (
        <IconButton
          aria-label="close"
          onClick={handleinvitClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
                {/* <div className="invitation-upcoming-delete-dalog">
                      <Icon
                        className="invitation-deleteConfirmationIcon"
                        icon="fluent:delete-12-filled"
                      />
                      Delete
                    </div> */}
              </DialogTitle>
              <DialogContent className="invitation-upcoming-delete-dalog">
                <h2 className="invitation-areUSure" style={{ color: "red" }}>
                  {" "}
                  { !isLoggedIn?"please login to create a trip":
                  !canCreate?"You have reached the maximum limit of 4 trips, So you cannot create trip":""}
                </h2>
              </DialogContent>
              <DialogActions className="invitation-buttonsContainer">
                {/* <Button
                      id="btn"
                      className="invitation-yesButton"
                      onClick={() => {
                        settingdeleteinvitation();
                      }}
                    >
                      Yes
                    </Button> */}
                <Button
                  className="invitation-noButton"
                  onClick={handleinvitClose}
                >
                  OK
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </Container>
      </div>
    </>
  );
}

export default NavBar;
