import React, { useState } from "react";
import { Icon } from "@iconify/react";
import "../CSS/dashboardHeader.css";
// import { NavLink } from "react-bootstrap";
import {
  Button,
  Container,
  Menu,
  Modal,
  useTheme,
  useMediaQuery,
  Badge,
  DialogTitle,
  DialogActions,
  Dialog,
  DialogContent,
} from "@mui/material";
import { Link, Navigate, NavLink } from "react-router-dom";
import SidebarLink from "./SidebarLink";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import SidebarDesktopMenu from "./SidebarDesktopMenu";
import SidebarMobileMenu from "./SidebarMobileMenu";

function DashboardHeader({ dataParentToChild_notify,can}) {
  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [canCreate,setCancreate]=useState(Boolean(JSON.parse(localStorage.getItem("can"))))
  // console.log("user_id", user.user_id);
  const [dashboardProfile, setdashboardProfile] = useState([]);

  const [dashboardNotification, setDashboardNotification] = useState(0);


  const [invitopen, setinvitopen] = React.useState(false);
  const handleinvitClose = () => {
    setinvitopen(false);
  };
const handleClickk=()=>{
     if (!isLoggedIn) {
         setinvitopen(true);
       }

   else if(!canCreate)
    setinvitopen(true);
  }   
  useEffect(() => {

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");


    if (isLoggedIn) {
      axios
        .get(
          `${domain}/user/profile/${user.user_id}/`
        )
        .then((response) => {
          console.log(response, "dashboard img response");

          if (response.data) {
            console.log(response.data, "dashboard img response data");
            setdashboardProfile(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            console.log("data : ", error.response.data.message);
          }
        });
    }
  }, []);

  const HandleDashNotification = () => {

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");


    if (isLoggedIn) {
      axios
        .get(`${domain}/notifications/unseen/${user.user_id}/`)
        .then((response) => {
          console.log(response, "notification response");
          console.log(response.data.unread_count, "not response");
          if (response.data) {
            console.log(response.data, "notification response data");
            setDashboardNotification(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            console.log("notification : ", error.response.data.message);
          }
        });
    }
  };

  useEffect(() => {
    HandleDashNotification();
  }, [dataParentToChild_notify]);


  return (
    <>
      <Container className="dash-navbar-container">
        <nav className="navbar-dash">
          <div className="nav-container-dash">
            <NavLink to="/" className="nav-logo-dash">
              <img
                src="../Assets/Images/desi-travel-campanion-logo.png"
                alt="logo-img"
                className="navbar-image-dash"
              />
            </NavLink>

            <div className="nav-item-dash">
              <Button
                style={{}}
                // activeClassName="active"
                className="nav-links-button-login-dash"
              >
                {" "}
                Login
              </Button>
            </div>

            <ul className={click ? "nav-menu-dash  active" : "nav-menu-dash "}>
              <li className="nav-item-dash ">
                <NavLink
                  to={isLoggedIn
                    &&Boolean(JSON.parse(localStorage.getItem("can")))
                    ?"/create-a-trip":''}
                  // activeClassName="active"
                  className="nav-links-dash "
                  onClick={handleClickk}
                >
                  Create a Trip
                </NavLink>
              </li>
              <li className="nav-item-dash ">
                <NavLink
                  to="/companion-search"
                  // activeClassName="active"
                  className="nav-links-dash "
                  onClick={handleClick}
                >
                  Companion Search
                </NavLink>
              </li>
            </ul>
            {/* <li className="nav-item">
                <Button style={{marginLeft:20}}
                  activeClassName="active"
                  className="nav-links-button"
                  onClick={handleClick}
                >
                  {" "}
                  Login
                </Button>
              </li> */}
            <div className="nav-item-dash ">
              <Link to="/notification">
                <Badge
                  color="error"
                  badgeContent={
                    dataParentToChild_notify
                      ? dataParentToChild_notify
                      : dashboardNotification.unread_count
                  }
                  sx={{
                    "& .MuiBadge-badge": {
                      backgroundColor: "red",
                      width: "5px",
                      transform: "translate(5px, -5px)",
                    },
                  }}
                >
                  <Icon
                    icon="clarity:notification-solid"
                    className="nav-links-icon-dash "
                    onClick={handleClick}
                  />
                  {/* <Icon
                    className="nav-links-icon-dash "
                    onClick={handleClick}
                    icon="clarity:notification-solid-badged"
                  /> */}
                </Badge>
              </Link>
            </div>
            {dashboardProfile.map((post, index) => {
              return (
                <div className="nav-item-dash ">
                  <div className="nav-item-dash-container">
                    <div className="nav-item-dash-inner" onClick={handleOpen}>
                      <img
                        className="nav-item-dash-Img"
                        variant="top"
                        src={
                          post.profile_picture == null
                            ? "../Assets/Images/Favicon 25 WB _ DV.webp"
                            : post.profile_picture
                        }
                        alt="..."
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </nav>
      </Container>
      {isMobile ? (
        <Menu
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              width: "32ch",
              background: "#ffffff",
            },
          }}
          className="dashboard-img-icon-modal"
        >
          <SidebarMobileMenu></SidebarMobileMenu>
        </Menu>
      ) : ( 
        <Menu
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          PaperProps={{
            style: {
              width: "20ch",
              background: "#ffffff",
            },
          }}
          className="dashboard-img-icon-modal"
        >
          <SidebarDesktopMenu></SidebarDesktopMenu>
        </Menu>
      )}
         <div>
            <Container>
              <Dialog
                open={invitopen}
                onClose={handleinvitClose}
                // {/* // aria-labelledby="alert-dialog-title"
                // // aria-describedby="alert-dialog-description" */}
                PaperProps={{
                  style: {
                    minHeight: "40vh",
                    minWidth: "35vw",
                    boxShadow: "none",
                    border: "2px solid rgb(128 213 184 / 100%)",
                    strokeWidth: "1px",
                    stroke: "rgb(128 213 184 )",
                    borderRadius: 20,
                  },
                }}
                BackdropProps={{
                  style: {
                    backdrop: "blur(2px)",
                  },
                }}
              >
                <div>
                  <DialogTitle id="alert-dialog-title">
                  {/* {handleinvitClose ? (
        <IconButton
          aria-label="close"
          onClick={handleinvitClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
                    {/* <div className="invitation-upcoming-delete-dalog">
                      <Icon
                        className="invitation-deleteConfirmationIcon"
                        icon="fluent:delete-12-filled"
                      />
                      Delete
                    </div> */}
                    
                  </DialogTitle>
                  <DialogContent
                    className="invitation-upcoming-delete-dalog"
                  >
                    <h2 className="invitation-areUSure" style={{color:"red"}}> 
                    { !isLoggedIn?"please login to create a trip":!canCreate?
                    "You have reached the maximum limit of 4 trips, So you cannot create trip":""}
                    </h2>
                  </DialogContent>
                  <DialogActions className="invitation-buttonsContainer">
                    {/* <Button
                      id="btn"
                      className="invitation-yesButton"
                      onClick={() => {
                        settingdeleteinvitation();
                      }}
                    >
                      Yes
                    </Button> */}
                    <Button
                      className="invitation-noButton"
                      onClick={handleinvitClose}
                    >
                      OK
                    </Button>
                  </DialogActions>
                </div>
              </Dialog>
            </Container>
</div> 
    </>
  );
}

export default DashboardHeader;