import React, { useEffect, useState } from "react";
import "../../CSS/createTripCSS/destinationDetails.css";
import AddIcon from "@mui/icons-material/Add";
import { useFieldArray } from "react-hook-form";
import "./CreateTripNew.css";
import { pink } from "@mui/material/colors";

import {
  Autocomplete,
  Box,
  Button,
  Card,
  Container,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  inputLabelClasses,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
// import { pink } from "@mui/material/colors";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import { color } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Controller } from "react-hook-form";
// import "../../CSS/tripDetailself.css"
// import CountryJson from "../../json/Airports.json";
import InputAdornment from '@mui/material/InputAdornment';
// import IconButton from '@mui/material/IconButton';
import EventIcon from '@mui/icons-material/Event';
import { Icon } from "@iconify/react";
import isMobile from "./Assets/Ismobile"
import { getAirlines,getAirports,getLanguages} from "./OptionsUtility/OptionsUtil";


function StartTrip(props) {
  const color = "#ff8d8d";
    const [inputValue, setInputValue] = useState("");
    const [openField,setOpenField]=useState("")

    const [langValue,setLangValue]=useState("")
    const [langResponse,setLangResponse]=useState([])


    const [airlineValue,setAirlineValue]=useState("")
    const [airlineResponse,setairlineResponse]=useState([])


    const [airportValue,setAirportValue]=useState("")
    const [airportResponse,setPortresponse]=useState([])


  const {
    control,
    values,
    setValue,
    errors,
    register,
    defaultdata,
    customError,
    setCustomError,
  } = props;


  //------------------- Date Range From---------------------------//
  const [openFrom, setOpenFrom] = React.useState(false);
  const handleOpenFrom = () => setOpenFrom(true);
  const handleCloseFrom = () => setOpenFrom(false);
//------------------- Date Range From---------------------------//

  // const [value, setValues] = React.useState<Date | null>(new Date());

//------------------- Date Range From---------------------------//
const [openTo, setOpenTo] = React.useState(false);
const handleOpenTo = () => setOpenTo(true);
const handleCloseTo = () => setOpenTo(false);
//------------------- Date Range From---------------------------//

  // const [value, setValues] = React.useState<Date | null>(new Date());

//------------------- Date Range From---------------------------//
const [openOn, setOpenOn] = React.useState(false);
const handleOpenOn = () => setOpenOn(true);
const handleCloseOn = () => setOpenOn(false);
//------------------- Date Range From---------------------------//

  // console.log(defaultdata,"defaultdata")

  const { fields, append, prepend, remove, swap, move, insert } = useFieldArray(
    {
      control,
      name: "airAndTrans",
    }
  );
  const deleteTransit = (index) => {
    remove(index);
  };
  useEffect(() => {
    if(isMobile()){
      window.scrollTo(0, 900);
      console.log("Mobile....")
     }else{
      window.scrollTo(0, 1300);
      console.log("Desktop....")
  }
  }, []);

  useEffect(() => { 
  console.log(getLanguages(langValue).then(res=>{
    if(res.data.message)
       setLangResponse([])
    else    
       setLangResponse(res.data.response)
    // console.log(res,"resinpromiseLang")
  }),"countryResponseTC")
  }, [langValue]);

  useEffect(() => { 
    console.log(getAirlines(airlineValue).then(res=>{
      if(res.data.message)
      setairlineResponse([])
       else   
        setairlineResponse(res.data.response)    
  }),"countryResponseTC")
    }, [airlineValue]);


    useEffect(() => { 
      console.log(getAirports(airportValue).then(res=>{
        if(res.data.message)
           setPortresponse([])
        else   
            setPortresponse(res.data.response)
        // console.log(res,"resinpromiseair")
      }),"countryResponseTC")
      }, [airportValue]);


  const today = moment(moment(new Date()).format("DD-MMM-yyyy"));

  var today_2month = moment().add(2, "months");
  var today_6month = moment().add(6, "months");

  console.log(today, "today");
  const addHandler = () => {
    append({
      transit: "",
      airline: "",
    });
  };

  const handleDisable = (option) => {
    let flag = false;
    if (
      JSON.stringify(option) === JSON.stringify(values.destination) ||
      JSON.stringify(option) === JSON.stringify(values.flying_from)
    ) {
      flag = true;
    }
    values.airAndTrans.map((trans) => {
      if (JSON.stringify(option) === JSON.stringify(trans.transit)) {
        flag = true;
      }
    });

    return flag;
  };

  const findErrorMessage=(name,index)=>{
    let message=""
  if(errors["airAndTrans"]&&errors["airAndTrans"].length>0){
    if(errors["airAndTrans"][index]){
      if(errors["airAndTrans"][index][`${name}`]){
        message=errors["airAndTrans"][index][`${name}`]["message"]
      }
    }

  }


  return message
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    if (name === "booking_status" && value === "1") {
      setValue("airline", "");
      setValue("departing_on", "");
      setValue("date_range_from", null);
      setValue("date_range_to", null);
      setValue(name, value);
    } else if (name === "booking_status" && value === "2") {
      setValue("airline", "Yet to Book");
      if(values.booking_status){
        setValue("date_range_from", "");
        setValue("date_range_to", "");
      }
      setValue("departing_on", null);
      setValue(name, value);
    }
    setValue(name, value,{shouldValidate:true});
  };
  // const mycountry = CountryJson;
  const style = { width: "90%", marginTop: "4rem" };
  return (
    <div className="responsive">
      <Grid>
        <Typography
          variant="h6"
          style={{ textAlign: "initial", fontWeight: "bold" }}
        >
          Travel Date/Booking status
        </Typography>
      </Grid>
<Grid>
{values.category === "2" && (
        <Box className="center mt-2">
          <Controller
            name="booking_status"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <Grid className="radio_card">
                <Typography style={{ textAlign: "initial" }}>
                 Ticket Booking Status <span style={{ color: "#ff8d8d" }}>*</span>
                </Typography>
                <Grid className="radio_container">
                  <Grid className="Radio">
                    <Typography variant="h6">Yet to book</Typography>
                    <Radio
                      checked={values.booking_status === "2"}
                      onChange={handleChange}
                      value="2"
                      name={name}
                      //  inputProps={{ 'aria-label': 'A' }}
                      sx={{
                        color: "#ff8d8d",
                        "&.Mui-checked": {
                          color: "#ff8d8d",
                        },
                      }}
                    />
                  </Grid>

                  <Grid className="Radio">
                    <Typography variant="h6">Booked</Typography>
                    <Radio
                      checked={values.booking_status === "1"}
                      onChange={handleChange}
                      value="1"
                      name={name}
                      sx={{
                        color: "#ff8d8d",
                        "&.Mui-checked": {
                          color: "#ff8d8d",
                        },
                      }}

                      //  inputProps={{ 'aria-label': 'A' }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
          />
        </Box>
      )}
             <div className="error_w_100">
              {errors.booking_status && errors.booking_status.message}
            </div>

</Grid>
     

      {(values.booking_status === "2"||values.booking_status === "")? (
        <Grid>
          <Grid className="field2">
            <label className="" style={{ textAlign: "start" }}>
              Date Range from
              <span style={{ color: "#ff8d8d", textAlign: "start" }}>*</span>
            </label>
            <Controller
              name="date_range_from"
              control={control}
              render={({ field: { ref, value, name, ...rest } }) => (
                <LocalizationProvider style={{ backgroundColor: "white" }} dateAdapter={AdapterMoment}>
                  <DatePicker
                    // disableFuture
                    PaperProps={
                      {
                        sx:{backgroundColor:"white !important"}
                      }
                      
                    }                   
                   name="date_range_from"
                    inputFormat="DD-MMM-yyyy"
                    value={value ? value : null}
                    open={openFrom}
                    onOpen={handleOpenFrom}
                    onClose={handleCloseFrom}
                    minDate={today}
                    maxDate={today_2month}
                    onChange={(newValue) => {
                      setValue(name, newValue.format("YYYY-MM-DD"),{shouldValidate:true});
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          name="dateFrom"
                          className="xxx"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" onClick={handleOpenFrom}>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}

                          // label="Date Range from"
                          variant="standard"
                          fullWidth
                          // color="success"
                          style={{ backgroundColor: "white" }}
                          sx={{
                            svg: { color },
                          }}
                        />
                      );
                    }}
                    {...rest}
                  />
                </LocalizationProvider>
              )}
            />

            <div className="error_w_100">
              {errors.date_range_from && errors.date_range_from.message}
            </div>
          </Grid>

          <Grid className="field2">
            <label className="">
              Date Range To<span style={{ color: "#ff8d8d" }}>*</span>
            </label>
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <Controller
                name="date_range_to"
                control={control}
                render={({ field: { ref, value, name, ...rest } }) => (
                  <DatePicker
                    inputFormat="DD-MMM-yyyy"
                    value={value ? value : null}
                    PaperProps={
                      {
                        sx:{backgroundColor:"white !important"}
                      }
                      
                    }
                    open={openTo}
                    onOpen={handleOpenTo}
                    onClose={handleCloseTo}
                    minDate={moment(
                      moment(values.date_range_from).add(1,"days").format("DD-MMM-yyyy")
                    )}
                    maxDate={today_6month}
                    onChange={(newValue) => {
                      setValue(name, newValue);
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          name="dateFrom"
                          className="xxx"
                          // label="Date Range To"
                          variant="standard"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" onClick={handleOpenTo}>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            svg: { color },
                          }}
                        />
                      );
                    }}
                    {...rest}
                  />
                )}
              />
            </LocalizationProvider>
            <div className="error_w_100">
              {errors.date_range_to && errors.date_range_to.message}
            </div>
          </Grid>

          <Grid className="field2">
            <label className="">
              Flying from<span style={{ color: "#ff8d8d" }}>*</span>
            </label>
            <Controller
              control={control}
              name="flying_from"
              render={({ field: { onChange, value, name } }) => (
                <Autocomplete
                popupIcon={<Icon icon="gridicons:dropdown" style={{color:"#ff8d8d",fontSize:30}} />}
                  value={value ? value : null}
                  open={openField===name}
                  onOpen={() => {
                    console.log(inputValue,"inputValue")
                    if (airportValue) {
                        setOpenField(name)
                      }
                     }}
                    onClose={() =>{
                      setAirportValue("");
                      setOpenField("")
                    }}
                    onInputChange={(e, value, reason) => {
                      setAirportValue(value);
                      if (!value) {
                        setAirportValue("")
                       }
                        }}
                  freeSolo={true}
                  sx={{ width: "100%" }}
                  options={airportResponse}
                  onChange={(event, item) => {
                    if (values.destination !== item) {
                      setValue(name, item, { shouldValidate: true });
                      // setValue(`${name}-help`, event.target.innerText);
                    }
                  }}
                  getOptionLabel={(option) =>
                    `${option.city},${option.country}(${option.iata_code}-${option.name})`
                  }
                  getOptionDisabled={(option) => {
                    // console.log(option, values.destination, "optionDisable");

                    return (
                      JSON.stringify(option) ===
                      JSON.stringify(values.destination)
                    );
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.city},{option.country}({option.iata_code}-
                      {option.name})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      className="xxx"
                      {...params}
                      // label="Flying from"
                      InputLabelProps={{
                        sx: {
                          color: "green",
                          borderColor: "green",
                          [`&.${inputLabelClasses.shrink}`]: {
                            // set the color of the label when shrinked (usually when the TextField is focused)
                            color: "black",
                          },
                        },
                      }}
                    />
                  )}
                />
              )}
            />

            <div className="error_w_100">
              {errors.flying_from && errors.flying_from.message}
            </div>
          </Grid>

          <Grid className="field2">
            <label className="">
              Destination<span style={{ color: "#ff8d8d" }}>*</span>
            </label>
            <Controller
              control={control}
              name="destination"
              render={({ field: { onChange, value, name } }) => (
                <Autocomplete
                popupIcon={<Icon icon="gridicons:dropdown" style={{color:"#ff8d8d",fontSize:30}} />}
                  // name="countries"
                  open={openField===name}
                  onOpen={() => {
                    console.log(inputValue,"inputValue")
                    if (airportValue) {
                      setOpenField(name)
                    }
                  }}
                  onClose={() =>{
                    setAirportValue("");
                    setOpenField("")
                  }}
                  onInputChange={(e, value, reason) => {
                    setAirportValue(value);

                    if (!value) {
                      setOpenField("")
                      setAirportValue("")
                    }
                  }}
                  value={value ? value : null}
                  sx={{ width: "100%" }}
                  options={airportResponse}
                  autoHighlight
                  freeSolo={true}
                  getOptionLabel={(option) =>
                    `${option.city},${option.country}(${option.iata_code}-${option.name})`
                  }
                  getOptionDisabled={(option) =>
                    JSON.stringify(option) ===
                    JSON.stringify(values.flying_from)
                  }
                  // value={value}
                  // getOptionLabel={(option) => option.label}
                  onChange={(event, item) => {
                    console.log(item, event.target.innerText, "itemValue");
                    // setValue(name, item?`${item.city},${item.country}(${item.iata_code}-${item.name})`:'', { shouldValidate: true });
                    console.log(
                      JSON.stringify(values.flying_from) ===
                        JSON.stringify(item),
                      "sameCity"
                    );
                    if (
                      JSON.stringify(values.flying_from) !==
                      JSON.stringify(item)
                    ) {
                      setValue(name, item, { shouldValidate: true });
                      // setValue(`${name}-help`, event.target.innerText);
                    }
                  }}
                  renderOption={(props, option) => (
                    <Box
                      component="li"
                      sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                      {...props}
                    >
                      {option.city},{option.country}({option.iata_code}-
                      {option.name})
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      className="xxx"
                      value={value}
                      {...params}
                      // label="Destination"
                      InputLabelProps={{
                        sx: {
                          // set the color of the label when not shrinked
                          color: "black",
                          [`&.${inputLabelClasses.shrink}`]: {
                            // set the color of the label when shrinked (usually when the TextField is focused)
                            color: "black",
                          },
                        },
                      }}
                      // inputProps={{
                      //   ...params.inputProps,
                      //   autoComplete: "new-password", // disable autocomplete and autofill
                      // }}
                    />
                  )}
                />
              )}
            />
            <div className="error_w_100">
              {errors.destination && errors.destination.message}
            </div>
          </Grid>

          <Grid className="field2 inputField_l">
            <label className="">
              Airline<span style={{ color: "#ff8d8d" }}>*</span>
            </label>
            <Controller
              name="airline"
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField
                  fullWidth
                  onChange={onChange}
                  value={value}
                  disabled={true}
                  // label="Airline"
                  className="xxx"
                  variant="standard"
                  focused
                />
              )}
            />

            <div className="error_w_100">
              {errors.airline && errors.airline.message}
            </div>
          </Grid>
        </Grid>
      ) : (
        <Grid>
          <Grid className="field2">
            <label className="">
              Departing On<span style={{ color: "#ff8d8d" }}>*</span>
            </label>

            <Controller
              name="departing_on"
              control={control}
              render={({ field: { ref, value, name, ...rest } }) => (
                <LocalizationProvider dateAdapter={AdapterMoment}>
                  <DatePicker
                    // disableFuture
                    name="departing_on"
                    open={openOn}
                    onOpen={handleOpenOn}
                    onClose={handleCloseOn}
                    PaperProps={
                      {
                        sx:{backgroundColor:"white !important"}
                      }
                      
                    }
                    inputFormat="DD-MMM-yyyy"
                    value={value ? value : null}
                    onChange={(newValue) => {
                      setValue(name, newValue.format("YYYY-MM-DD"));
                    }}
                    minDate={today}
                    maxDate={today_6month}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          name="dateFrom"
                          className="xxx"
                          // label="Departing on"
                          variant="standard"
                          fullWidth
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton edge="end" onClick={handleOpenOn}>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            svg: { color },
                          }}
                          // color="success"
                        />
                      );
                    }}
                    {...rest}
                  />
                </LocalizationProvider>
              )}
            />

            <div className="error_w_100">
              {errors.departing_on && errors.departing_on.message}
            </div>
          </Grid>

          <Grid className="transitCard">
            <Grid className="transit_line1">
              <Grid className="line1_w">
                <Typography className="Auto_w_l">
                  Flying From<span style={{ color: "#ff8d8d" }}>*</span>
                </Typography>
                <Grid className="Auto_w_p">
                  <Controller
                    control={control}
                    name="flying_from"
                    render={({ field: { onChange, value, name } }) => (
                      <Autocomplete
                      popupIcon={<Icon icon="gridicons:dropdown" style={{color:"#ff8d8d",fontSize:30}} />}
                        id="airlines"
                        autoHighlight
                        name={name}
                        value={value ? value : null}
                        open={openField===name}
                        onOpen={() => {
                          // console.log(inputValue,"inputValue")
                          if (airportValue) {
                            setOpenField(name)
                          }
                        }}
                        onClose={() =>{
                          setAirportValue("");
                          setOpenField("")
                        }}
                        onInputChange={(e, value, reason) => {
                          setAirportValue(value);
    
                          if (!value) {
                            setAirportValue("")
                          }
                        }}
                        // id="country-select-demo"
                        // defaultValue={defaultdata?defaultdata.airline:null}
                        freeSolo={true}

                        // id="country-select-demo"
                        // defaultValue={defaultdata?defaultdata.airline:null}
                        getOptionDisabled={(option) => {
                          return handleDisable(option);
                        }}
                        options={airportResponse}
                        getOptionLabel={(option) =>
                          `${option.city},${option.country}(${option.iata_code}-${option.name})`
                        }
                        getOptionSelected={(option, value) =>
                          value === undefined ||
                          value === "" ||
                          option.id === value.name
                        }
                        className="Auto_w"
                        onChange={(event, item) => {
                          console.log(
                            item,
                            event.target.innerText,
                            "itemValue"
                          );
                          // setValue(name, item?`${item.city},${item.country}(${item.iata_code}-${item.name})`:'', { shouldValidate: true });
                          setValue(name, item, { shouldValidate: true });
                          // setValue(`${name}-help`, event.target.innerText);
                        }}
                        // value={value}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    )}
                  />

                  <div className="error">
                    {errors.flying_from && errors.flying_from.message}
                  </div>
                </Grid>
              </Grid>

              <Grid className="line1_w">
                <Typography className="Auto_w_l">Airline <span style={{ color: "#ff8d8d" }}>*</span></Typography>
                <Grid className="Auto_w_p">
                  <div className="col-md-6">
                    <div className="form-group">
                      <Controller
                        control={control}
                        name="airline"
                        render={({ field: { name, value } }) => (
                          <Autocomplete
                          popupIcon={<Icon icon="gridicons:dropdown" style={{color:"#ff8d8d",fontSize:30}} />}
                          id="country-select-demo"
                          value={value ? value : null}
                          open={openField===name}
                          onOpen={() => {
                            if (airlineValue) {
                              setOpenField(name)
                            }
                          }}
                          onClose={() =>{
                            setAirlineValue("");
                            setOpenField("")
                          }}
                          freeSolo={true}
                          onInputChange={(e, value, reason) => {
                            setAirlineValue(value);
      
                            if (!value) {
                              setAirlineValue("")
                            }
                          }}
                          className="Auto_w"
                          options={airlineResponse}
                          autoHighlight
                          getOptionLabel={(option) => option.label}
                             getOptionSelected={(option, value) =>
                              value === undefined ||
                              value === "" ||
                              option.id === value.label
                            }
                          onChange={(event, item) => {
                            console.log(
                              item,
                              event.target.innerText,
                              "itemValue"
                            );
                            // setValue(name, item?`${item.city},${item.country}(${item.iata_code}-${item.name})`:'', { shouldValidate: true });
                            setValue(name, item, { shouldValidate: true });
                            // setValue(`${name}-help`, event.target.innerText);
                          }}
                          renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                              <img
                                loading="lazy"
                                width="20"
                                src={option.logo}
                                // srcSet={`https://flagcdn.com/w40/${option.code.toLowerCase()}.png 2x`}
                                alt=""
                              />
                              {option.label}
                            </Box>
                          )}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Select"
                              inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password', // disable autocomplete and autofill
                              }}
                            />
                          )}
                        />
                        )}
                      />
                    </div>
                  </div>
                  <div style={{ color: "red" }} className="error">
                    {errors.airline && errors.airline.message}
                  </div>
                </Grid>
              </Grid>
              <Grid className="dummy">{""}</Grid>
            </Grid>
            {fields.map((field, index) => {
              return (
                <Grid className="transit_linem">
                  <Grid className="line1_w">
                    <Typography className="Auto_w_l">
                      {`Transit${index + 1}`}{" "}
                      <span style={{ color: "#ff8d8d" }}>*</span>
                    </Typography>
                    <Grid className="Auto_w_p">
                      <Controller
                        control={control}
                        name={`airAndTrans[${index}].transit`}
                        render={({ field: { onChange, value, name } }) => (
                          <Autocomplete
                          popupIcon={<Icon icon="gridicons:dropdown" style={{color:"#ff8d8d",fontSize:30}} />}
                            id="country-select-demo"
                            // defaultValue={defaultdata?defaultdata[`transit${index+1}`]:null}
                            className="Auto_w"
                            options={airportResponse}
                            open={openField===name}
                            onOpen={() => {
                              // console.log(inputValue,"inputValue")
                              if (airportValue) {
                                setOpenField(name)
                              }
                            }}
                            onClose={() =>{
                              setAirportValue("");
                              setOpenField("")
                            }}
                            freeSolo={true}
                            onInputChange={(e, value, reason) => {
                              setAirportValue(value);
        
                              if (!value) {
                                setOpenField("")
                                setAirportValue("");
                              }
                            }}
                            getOptionLabel={(option) =>
                              `${option.city},${option.country}(${option.iata_code}-${option.name})`
                            }
                            autoHighlight
                            getOptionDisabled={(option) => {
                              return handleDisable(option);
                            }}
                            // getOptionLabel={(option) => option.label}
                            getOptionSelected={(option, value) =>
                              value === undefined ||
                              value === "" ||
                              option.id === value.id
                            }
                            onChange={(event, item) => {
                              console.log(
                                item,
                                event.target.innerText,
                                "itemValue"
                              );
                              // setValue(name, item?`${item.city},${item.country}(${item.iata_code}-${item.name})`:'', { shouldValidate: true });
                              setValue(name, item, { shouldValidate: true });
                              // setValue(`${name}-help`, event.target.innerText);
                            }}
                            value={field["transit"]?field["transit"]:(value?value:null)}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                label="Select"
                                inputProps={{
                                  ...params.inputProps,
                                  autoComplete: "new-password", // disable autocomplete and autofill
                                }}
                              />
                            )}
                          />
                        )}
                      />

                      <div className="error">
                        {/* {Array.isArray(errors.airAndTrans) &&
                          errors.airAndTrans[index].transit &&
                          errors.airAndTrans[index].transit.message} */}
                          {findErrorMessage("transit",index)}
                      </div>
                    </Grid>
                  </Grid>

                  <Grid className="line1_w">

                    <Typography className="Auto_w_l">{`Airline${
                      index + 1 
                    }`} <span style={{ color: "#ff8d8d" }}>*</span></Typography>
                    
                    <Grid className="Auto_w_p">
                      <Grid style={{ display: "flex" }}>
                        <Controller
                          control={control}
                          name={`airAndTrans[${index}].airline`}
                          render={({ field: { onChange, value, name } }) => (
                            <Autocomplete
                            popupIcon={<Icon icon="gridicons:dropdown" style={{color:"#ff8d8d",fontSize:30}} />}
                              id="airlines"
                              open={openField===name}
                              onOpen={() => {
                                if (airlineValue) {
                                  setOpenField(name)
                                }
                              }}
                              onClose={() =>{
                                setAirlineValue("");
                                setOpenField("")
                              }}
                              onInputChange={(e, value, reason) => {
                                setAirlineValue(value);
          
                                if (!value) {
                                  setOpenField("")
                                  setAirlineValue("");
                                }
                              }}
                              freeSolo={true}
                              getOptionLabel={(option) => option.label}
                              defaultValue={
                                defaultdata
                                  ? defaultdata[`airline${index + 1}`]
                                  : null
                              }
                              className="Auto_w"
                              // value={value}
                              options={airlineResponse}
                              renderOption={(props, option) => (
                                <Box
                                  component="li"
                                  sx={{
                                    "& > img": {
                                      mr: 2,
                                      flexShrink: 0,
                                    },
                                  }}
                                  {...props}
                                >
                                  <img
                                    loading="lazy"
                                    width="20"
                                    src={option.logo}
                                    alt=""
                                  />
                                  {option.label}
                                </Box>
                              )}
                              getOptionSelected={(option, value) =>
                                value === undefined ||
                                value === "" ||
                                option.id === value.id
                              }
                              value={field["airline"]?field["airline"]:(value?value:null)}
                              onChange={(event, item) => {
                                console.log(
                                  item,
                                  event.target.innerText,
                                  "itemValue"
                                );
                                setValue(name, item, { shouldValidate: true });
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  label="Select"
                                  // inputProps={{
                                  //   ...params.inputProps,
                                  //   autoComplete: "new-password", // disable autocomplete and autofill
                                  // }}
                                />
                              )}
                            />
                          )}
                        />
                        <Grid className="dummy">
                          <Button
                            className="cancel_btn center"
                            onClick={() => deleteTransit(index)}
                          >
                            <ClearIcon />
                          </Button>

                          <Grid
                            className="cancel_div center"
                            onClick={() => deleteTransit(index)}
                          >
                            <ClearIcon />
                          </Grid>
                        </Grid>
                      </Grid>

                      <div className="error">
                        {/* {Array.isArray(errors.airAndTrans) &&
                          errors.airAndTrans[index].airline &&
                          errors.airAndTrans[index].airline.message} */}
                          {findErrorMessage("airline",index)}
                      </div>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
            <Grid
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "92%",
                marginTop: "2rem",
              }}
            >
              <Grid>{""}</Grid>
              <div className="">
                <Button
                  className="destinationSelfBtn"
                  onClick={addHandler}
                  disabled={fields.length > 3}
                >
                  <AddIcon style={{ fontSize: 18, color: "white" }} />
                  Add Transit
                </Button>
                <Grid style={{ color: "#ff8d8d" }}>*max 4 transit</Grid>
              </div>
            </Grid>

            <Grid
              className="transit_linem_dest"
              // style={{display:"flex",alignItems:"center",justifyContent:"space-between",width:"87%"}}
            >
              <Grid
                className="line1_w"
                //  style={{width:"40%"}}
              >
                <Typography
                  // style={{ textAlign: "initial", marginBottom: "1rem" }}
                  className="Auto_w_l"
                >
                  Destination <span style={{ color: "#ff8d8d" }}>*</span>
                </Typography>
                <Grid
                  // style={{width:"100%"}}
                  className="Auto_w_p_dest"
                >
                  <Controller
                    control={control}
                    name="destination"
                    render={({ field: { onChange, value, name } }) => (
                      <Autocomplete
                      popupIcon={<Icon icon="gridicons:dropdown" style={{color:"#ff8d8d",fontSize:30}} />}
                        open={openField===name}
                        onOpen={() => {
                          // console.log(inputValue,"inputValue")
                          if (airportValue) {
                            setOpenField(name)
                          }
                        }}
                        onClose={() =>{
                          setAirportValue("");
                          setOpenField("")
                        }}
                        onInputChange={(e, value, reason) => {
                          setAirportValue(value);
    
                          if (!value) {
                            setOpenField("")
                            setAirportValue("");
                          }
                        }}
                        value={value ? value : null}
                        className="Auto_w"
                        // value={value}
                          // inputValue={inputValu
                        getOptionDisabled={(option) => {
                          return handleDisable(option);
                        }}
                        freeSolo={true}
                        id="country-select-demo"
                        options={airportResponse}
                        autoHighlight
                        onChange={(event, item) => {
                          console.log(
                            item,
                            event.target.innerText,
                            "itemValue"
                          );
                          // setValue(name, item?`${item.city},${item.country}(${item.iata_code}-${item.name})`:'', { shouldValidate: true });
                          setValue(name, item, { shouldValidate: true });
                          // setValue(`${name}-help`, event.target.innerText);
                        }}
                        getOptionLabel={(option) =>
                          `${option.city},${option.country}(${option.iata_code}-${option.name})`
                        }
                        // value={value}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.city},{option.country}({option.iata_code}-
                            {option.name})
                          </Box>
                        )}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Select"
                            className="xxx"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                        )}
                      />
                    )}
                  />

                  <div className="error">
                    {errors.destination && errors.destination.message}
                  </div>
                </Grid>
                <Grid className="dummy">{""}</Grid>
              </Grid>
              
            </Grid>
          </Grid>
        </Grid>
      )}



      <Grid className="field2">
        <label className="">
          Preferred Languages<span style={{ color: "#ff8d8d" }}>*</span>
          {" "}(max 3)
        </label>
        <Controller
          control={control}
          name="prefered_language"
          render={({ field: { onChange, value, name } }) => (
            <Autocomplete
              popupIcon={<Icon icon="gridicons:dropdown" style={{color:"#ff8d8d",fontSize:30}} />}
              name="prefered_language"
              sx={{ width: "100%" }}
              options={langResponse}
              open={openField===name}
              onOpen={() => {
                  if (langValue) {
                     setOpenField(name)
                  }
              }}
              onClose={() =>{
                  setLangValue("");
                  setOpenField("")
              }}
              onInputChange={(e, value, reason) => {
                  setLangValue(value);
                  if (!value) {
                     setOpenField("")
                     setLangValue("");
                  }
              }}
              freeSolo={true}
              // defaultValue={defaultdata?defaultdata.prefered_language:null}
              autoHighlight
              multiple={true}
              getOptionDisabled={(option) => {
                let flag = false;
                console.log(option, values.destination, "optionDisable");
                values.prefered_language.map((lang) => {
                  if (JSON.stringify(lang) === JSON.stringify(option)) {
                    flag = true;
                  }
                });

                return flag;
              }}
              getOptionLabel={(option) => option.name}
              onChange={(event, item) => {
                console.log(item, "item");
                if (item.length <= 3) {
                  setValue(name, item, { shouldValidate: true });
                }
              }}
              value={value}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  className="xxx"
                  {...params}
                  // label="Preferred Language"
                  InputLabelProps={{
                    sx: {
                      color: "black",
                      [`&.${inputLabelClasses.shrink}`]: {
                        color: "black",
                      },
                    },
                  }}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: "new-password", // disable autocomplete and autofill
                  }}
                />
              )}
            />
          )}
        />

        <div style={{ color: "red" }} className="error_w_100">
          {errors.prefered_language && errors.prefered_language.message}
        </div>
      </Grid>
    </div>
  );
}

export default StartTrip;
