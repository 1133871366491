import React, { useState } from "react";
import ArrowDropDownTwoToneIcon from "@mui/icons-material/ArrowDropDownTwoTone";
import ArrowDropUpTwoToneIcon from "@mui/icons-material/ArrowDropUpTwoTone";
import SortSharpIcon from "@mui/icons-material/SortSharp";
import "../../CSS/filters.css";
import { Link } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Slider from "@mui/material/Slider";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import PublicIcon from "@mui/icons-material/Public";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
import FlightIcon from "@mui/icons-material/Flight";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
// import airlines from "../../json/Airlines.json";
// import languages from "../../json/languages.json";
import { Grid, Select, MenuItem } from "@mui/material";
import { getAirlines,getLanguages} from "../CreateTripNew/OptionsUtility/OptionsUtil";
import {
  Box,
  Button,
  Collapse,
  Container,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  TextField,
} from "@mui/material";
import { Icon } from "@iconify/react";
import WcIcon from '@mui/icons-material/Wc';


const language = [
  { label: "english" },
  { label: "Hindi" },
  { label: "french" },
  { label: "spanish" },
  { label: "Telugu" },
];

const MobileFilter = (props) => {
  const [bookingstatus, setBookingStatus] = useState(false);
  const [airlinesState, setAirlinesState] = useState(false);
  const [tipAmtState, setTipAmtState] = useState(false);
  const [genderState, setGenderState] = useState(false);

  const [allFieldValues, setAllFieldValues] = useState({
    airline: "",
    prefered_language: [],
    tip_expected: "",
    booking_status: "",
    gender:"",
  });
  // console.log(airlines, "airlines");

 
  const [langValue,setLangValue]=useState("")
  const [langResponse,setLangResponse]=useState([])


  const [airlineValue,setAirlineValue]=useState("")
  const [airlineResponse,setairlineResponse]=useState([])
  
    //------------------- Date Range From---------------------------//
    const [openAirline, setopenAirline] = React.useState(false);
  //------------------- Date Range From---------------------------//
  
  
  //------------------- Date Range From---------------------------//
  const [openlang, setOpenLang] = React.useState(false);
  //------------------- Date Range From---------------------------//


  const genderMenu = () => {
    setGenderState(!genderState);
  };

  const TipAmtMenu = () => {
    setTipAmtState(!tipAmtState);
  };

  const bookingStatusMenu = () => {
    setBookingStatus(!bookingstatus);
  };

  const airLineChange = (event, value) => {
    console.log(event, value, "eventValue");
    if (value) {
      setAllFieldValues({
        ...allFieldValues,
        airline: value,
      });
    } else {
      setAllFieldValues({
        ...allFieldValues,
        airline: "",
      });
    }
  };

  const handleChange = (event, acValue) => {
    console.log(event, "event");
    const { name, value, id } = event.target;
    console.log(name, value, acValue, id, "inOnchange");
    if (name === undefined || name === "" || name === null) {
      if (id.split("-")[0] === "language") {
        const Array = [];
        acValue.map((field) => {
          Array.push(field.name);
        });
        setAllFieldValues({
          ...allFieldValues,
          prefered_language: Array,
        });
      } else {
        setAllFieldValues({
          ...allFieldValues,
          prefered_language: [],
        });
        console.log(value, "valueforAuto");
      }
    } else if (name === "tip_expected"|| name === "gender") {
      if (allFieldValues[name] === value) {
        setAllFieldValues({
          ...allFieldValues,
          [name]: "",
        });
      } else {
        console.log(value, "booooooked");
        setAllFieldValues({
          ...allFieldValues,
          [name]: value,
        });
      }
    } else {
      if (allFieldValues[name] === parseInt(value)) {
        setAllFieldValues({
          ...allFieldValues,
          [name]: "",
        });
      } else {
        console.log(value, "booooooked");
        setAllFieldValues({
          ...allFieldValues,
          [name]: parseInt(value),
        });
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();

  if(allFieldValues.airline)
       allFieldValues["airline"]=allFieldValues.airline.label
 props.onSubmit(allFieldValues,"mobile");
 console.log(allFieldValues,"allFieldValues...")
   
    setBookingStatus(false);
    setAirlinesState(false);
    setTipAmtState(false);
    // console.log(allFieldValues ,"rrrrrrrrrrrr")
  };
  console.log(allFieldValues, "rrrrrrrrrrrr");



  return (
    <>
      <Container>
        <form onSubmit={handleSubmit}>
          <div className="filter-main-mobile">
            <div className="filter-second">
              <div className="filter-icon-section">
                <SortSharpIcon
                  fontSize="large"
                  style={{ fill: "white" }}
                  className=" SortSharpIcon"
                />
              </div>

              <div className="filter-text-section">Filters....</div>
            </div>
            <div style={{ display: "flex" }}>
              <div>
                <TravelExploreIcon
                  style={{ marginLeft: 10, marginTop: 7, fill: "#1ec28b" }}
                />
              </div>
              <div className="tipamt-listed">
                <ListItemButton onClick={bookingStatusMenu}>
                  <ListItemText
                    primary="Booking Status"
                    style={{ marginTop: 0, marginBottom: 0 }}
                  />
                  {airlinesState ? (
                    <ArrowDropUpTwoToneIcon style={{ fill: "#1ec28b" }} />
                  ) : (
                    <ArrowDropDownTwoToneIcon style={{ fill: "#1ec28b" }} />
                  )}
                </ListItemButton>
                <Collapse in={bookingstatus} timeout="auto" unmountOnExit>
                <Grid className="checkBox">
                  <input
                    type="checkbox"
                    name="booking_status"
                    id="booked"
                    value={1}
                    checked={allFieldValues["booking_status"] === 1}
                    onClick={(e) => handleChange(e)}
                  />
                  <label>Booked</label>
                  </Grid>
                  <Grid className="checkBox">
                  <input
                    type="checkbox"
                    id="yet to book"
                    name="booking_status"
                    value={2}
                    checked={allFieldValues["booking_status"] === 2}
                    onClick={(e) => handleChange(e)}
                  />
                  <label>Yet to book</label>
                  </Grid>
                  {/* <Grid className="checkBox">
                    <input
                      type="checkbox"
                      name="tip_expected"
                      id="tip_expected"
                      value="Negotiable"
                      checked={allFieldValues["tip_expected"] === "Negotiable"}
                      onClick={(e) => handleChange(e)}
                    />
                    <label>Negotiable</label>
                  </Grid> */}
                </Collapse>
              </div>
            </div>
            <hr />
            <div style={{ display: "flex" }}>
              <FlightIcon
                style={{ marginLeft: 10, marginTop: 7, fill: "#1ec28b" }}
              />
              <Autocomplete
                popupIcon={<Icon icon="gridicons:dropdown" style={{color:"#1ec28b",fontSize:25}} />}
                id="airline"
                sx={{ width: 170 }}
                options={airlineResponse}
                name="airline"
                open={openAirline}
                onOpen={() => {
                  if (airlineValue) {
                    setopenAirline(true);
                  }
                }}
                onClose={() => setopenAirline(false)}
                airlines
                size={"small"}
                value={allFieldValues.airline ? allFieldValues.airline : null}
                onChange={(e, value) => {
                  airLineChange(e, value);
                }}
                onInputChange={(e, value, reason) => {
                  setAirlineValue(value)
                 if(!value){
                  setAirlineValue("")
                  setopenAirline(false)

                 }
                  getAirlines(value).then(res=>{
                    if(res.data.message)
                       setairlineResponse([])
                     else   
                      setairlineResponse(res.data.response)    
                })
                 }}
                renderOption={(props, option) => (
                  <Box
                    component="li"
                    sx={{
                      "& > img": { mr: 2, flexShrink: 0 },
                    }}
                    {...props}
                  >
                    <img loading="lazy" width="20" src={option.logo} alt="" />
                    {option.label}
                  </Box>
                )}
                getOptionLabel={(option) => option.label}
                renderInput={(params) => (
                  <TextField
                            {...params}
                            label="Airline"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                )}
              />
            </div>
            <hr />
            <div style={{ display: "flex" }}>
              <LanguageRoundedIcon
                style={{ marginLeft: 10, marginTop: 7, fill: "#1ec28b" }}
              />
              <Autocomplete
               popupIcon={<Icon icon="gridicons:dropdown" style={{color:"#1ec28b",fontSize:25}} />}
                id="language-select"
                sx={{ width: 170 }}
                options={langResponse}
                name="prefered_language"
                airlines
                multiple
                open={openlang}
                onOpen={() => {
                  if (langValue) {
                    setOpenLang(true);
                  }
                }}
                onClose={() => setOpenLang(false)}
                size={"small"}
                value={allFieldValues.language}
                onChange={(e, value) => {
                  handleChange(e, value);
                }}
                onInputChange={(e, value, reason) => {
                  setLangValue(value)
                  if(!value){
                    setLangValue("")
                    setOpenLang(false)
                  }
                     
                  getLanguages(value).then(res=>{
                    if(res.data.message)
                       setLangResponse([])
                    else    
                       setLangResponse(res.data.response)
                  })
                }}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                            {...params}
                            label="Languages"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password", // disable autocomplete and autofill
                            }}
                          />
                )}
              />
            </div>
            <hr />

            <div style={{ display: "flex" }}>
              <div>
                <PriceChangeOutlinedIcon
                  style={{ marginLeft: 10, marginTop: 7, fill: "#1ec28b" }}
                />
              </div>
              <div className="tipamt-listed">
                <ListItemButton onClick={TipAmtMenu}>
                  <ListItemText
                    primary="Tip Amount"
                    style={{ marginTop: 0, marginBottom: 0 }}
                  />
                  {airlinesState ? (
                    <ArrowDropUpTwoToneIcon style={{ fill: "#1ec28b" }} />
                  ) : (
                    <ArrowDropDownTwoToneIcon style={{ fill: "#1ec28b" }} />
                  )}
                </ListItemButton>
                <Collapse in={tipAmtState} timeout="auto" unmountOnExit>
                  <Grid className="checkBox">
                    <input
                      type="checkbox"
                      name="tip_expected"
                      id="tip_expected"
                      value="50"
                      checked={allFieldValues["tip_expected"] === "50"}
                      onClick={(e) => handleChange(e)}
                    />
                    <label>50 USD</label>
                  </Grid>
                  <Grid className="checkBox">
                    <input
                      type="checkbox"
                      name="tip_expected"
                      id="tip_expected"
                      value="75"
                      checked={allFieldValues["tip_expected"] === "75"}
                      onClick={(e) => handleChange(e)}
                    />
                    <label>75 USD</label>
                  </Grid>
                  <Grid className="checkBox">
                    <input
                      type="checkbox"
                      name="tip_expected"
                      id="tip_expected"
                      value="100"
                      checked={allFieldValues["tip_expected"] === "100"}
                      onClick={(e) => handleChange(e)}
                    />
                    <label>100 USD</label>
                  </Grid>
                  <Grid className="checkBox">
                    <input
                      type="checkbox"
                      name="tip_expected"
                      id="tip_expected"
                      value="Just helping"
                      checked={allFieldValues["tip_expected"] === "Just helping"}
                      onClick={(e) => handleChange(e)}
                    />
                    {/* "Just helping">Just helping */}
                    <label>Just helping</label>
                  </Grid>
                  <Grid className="checkBox">
                    <input
                      type="checkbox"
                      name="tip_expected"
                      id="tip_expected"
                      value="Negotiable"
                      checked={allFieldValues["tip_expected"] === "Negotiable"}
                      onClick={(e) => handleChange(e)}
                    />
                    <label>Negotiable</label>
                  </Grid>
                </Collapse>
              </div>
            </div>
            <hr />
            <div style={{ display: "flex" }}>
              <div>
                <WcIcon
                  style={{ marginLeft: 10, marginTop: 7, fill: "#1ec28b" }}
                />
              </div>
              <div className="tipamt-listed">
                <ListItemButton onClick={genderMenu}>
                  <ListItemText
                    primary="Gender"
                    style={{ marginTop: 0, marginBottom: 0 }}
                  />
                  {airlinesState ? (
                    <ArrowDropUpTwoToneIcon style={{ fill: "#1ec28b" }} />
                  ) : (
                    <ArrowDropDownTwoToneIcon style={{ fill: "#1ec28b" }} />
                  )}
                </ListItemButton>
                <Collapse in={genderState} timeout="auto" unmountOnExit>
                  <Grid className="checkBox">
                    <input
                      type="checkbox"
                      name="gender"
                      id="gender"
                      value="Male"
                      checked={allFieldValues["gender"] === "Male"}
                      onClick={(e) => handleChange(e)}
                    />
                    <label>Male</label>
                  </Grid>
                  <Grid className="checkBox">
                    <input
                      type="checkbox"
                      name="gender"
                      value="Female"
                      checked={allFieldValues["gender"] === "Female"}
                      onClick={(e) => handleChange(e)}
                    />
                    <label>Female</label>
                  </Grid>
                </Collapse>
              </div>
            </div>
            <hr />
            <div style={{display:"flex",alignItem:"center",justifyContent:"center"}}>
              <Button className="apply-filter-btn" type="submit">
                Apply Filter
              </Button>
            </div>
          </div>
        </form>
      </Container>
    </>
  );
};

export default MobileFilter;