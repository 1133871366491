import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import "../../CSS/connectpeople.css";
import { Link } from "react-router-dom";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import Filters from "./Filter";
import DashboardHeader from "../../Dashboard/DashboardHeader";
import SearchBar from "../../SearchBar/SearchBar";
import ConnectPeoplePopup from "./ConnectPeoplePopup";
import Popover from "@mui/material/Popover";
import { Icon } from "@iconify/react";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import EastIcon from "@mui/icons-material/East";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FlightIcon from "@mui/icons-material/Flight";
import LanguageIcon from "@mui/icons-material/Language";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import RouteIcon from "@mui/icons-material/Route";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { FilterList } from "@mui/icons-material";
import Filter from "./Filter";
import MobileFilter from "./MobileFilter";
import { useSelector } from "react-redux";
import ScrollTop from "../../ScrollTop";
import searchApi from "../../SearchReqody/searchApi";
import SearchReqbodyGen from "../../SearchReqody/SearchReqbodyGen";
import { useNavigate, useLocation } from "react-router-dom";
import isMobile from "../CreateTripNew/Assets/Ismobile";
import LoadingPage from "./LoadingPage";

function ConnectPeople() {
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const [closeIcon, setCloseicon] = useState(false);
  const [dataToPopup, setDataToPopup] = useState();
  const [allCards, setAllcards] = useState([]);
  const [searchResponse, setSearchResponse] = useState([]);
  const [searchResponseError, setSearchResponseError] = useState("");
  const [filter, setFilter] = useState(false);
  const [next, setNext] = useState(4);
  const [loading, setLoading] = useState(false);
  const [serachFilled, setSearchFilled] = useState({});
  const [searching,setSearching]=useState(false)
  const [filtering,setFiltering]=useState(false)

  const location = useLocation();
  const navigate = useNavigate();

  const [invitopen, setinvitopen] = React.useState(false);
  const handleinvitClose = () => {
    setinvitopen(false);
  };

  const FilterShow = () => {
    setFilter(!filter);
  };

  // const imagePerRow = 4;

  const handleMoreImage = () => {
    setNext(next + 4);
  };

  const mySearchData = (response_search) => {
    setFiltering(false)
    console.log(response_search,"response_search")
    if(isMobile()){
      window.scrollTo(0, 500);
      console.log("Mobile....")
     }
    setAllcards(response_search);
    setSearchResponse(response_search);
    // setSearchResponseError(");
    if (response_search) {
      setFilteredList([]);
      setNext(4);
    }
    console.log(response_search, "searResponse in connect people");
  };

  const mySearchDataError = (response_search_error) => {
    //  take parameter passed from Child component
    console.log(response_search_error,"response_search_error")
    setSearchResponseError(`${response_search_error}`);
    if (response_search_error) {
      setFilteredList([]);
      setNext(4);
    }
  };
  console.log(searchResponseError, "searResponse error in connect people");


  const filterCards = searchResponse;
  const [filteredList, setFilteredList] = useState(filterCards);

  console.log(filteredList.length + "????????");

  const compare = (backengLan, userLanguage) => {
    console.log(userLanguage, "userLanguage");
    const BL = backengLan.split(", ");
    let flag = false;
    if (userLanguage.length < 1) {
      flag = true;
    } else {
      BL.map((field) => {
        userLanguage.map(function(u) {
          if (u == field) {
            flag = true;
          }
        });
      });
    }

    return flag;
  };
  
  const airlineChecking = (card, value) => {
    const airlineList = [
      "airline",
      "airline1",
      "airline2",
      "airline3",
      "airline4",
    ];
    let flag = false;
    airlineList.map((air) => {
      if(card[`${air}`].includes("(")){
        const splitparam=card[`${air}`].split("(")
        if(splitparam[0]===`${value} `){
          flag = true;
        }
      }else{
        if (card[`${air}`] === value) {
          flag = true;
        }
      }
     
    });
    return flag;
  };

  const getFilterData = (filteringValue,device) => {
    setFiltering(true)
    if(device)
      setFilter(!filter);
    console.log(filteringValue, "filteringValue");
    setNext(4);
    console.log(filteringValue, "obj");
    const dataFilter = allCards.filter(
      (card) =>
        (card.booking_status === filteringValue.booking_status ||
          filteringValue.booking_status === "") &&
          (card.gender === filteringValue.gender ||
            filteringValue.gender === "")&&
        airlineChecking(card, filteringValue.airline) &&
        (card.tip_expected === filteringValue.tip_expected ||
          filteringValue.tip_expected === "") &&
        compare(card.prefered_language, filteringValue.prefered_language)
    );
    console.log(dataFilter, "dataFilter");
    setSearchResponse(dataFilter);
   
  };

  const handleClick = (item) => {
    if (isLoggedIn) {
      setDataToPopup(item);
      setCloseicon(true);
    } else {
      setinvitopen(true);
    }
  };
  const handleClose = () => {
    {
      setCloseicon(false);
    }
  };
  const open = Boolean(closeIcon);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    console.log(location.state, "locationState");
    if (location.state) {
      const searchData = SearchReqbodyGen(location.state);
      setSearchFilled(searchData);
      console.log(searchData, "locationState..");
      console.log(searchData, "searchData..");
      const response = searchApi(location.state);
      response.then((data) => {
        if (data.status === "S") {
          // const state=normalized;
          // navigate("/companion-search",{state})
          // if(page!=="home")
          mySearchData(data.data);
        } else {
          // searchResponseError,
          setSearchResponseError(data.data.data.message);
        }
        console.log(data, "data....");
      });
    }
  }, []);

  return (
    <>
      <ScrollTop>
        <DashboardHeader></DashboardHeader>
        <Container>
          <div className="connectpeopleHeading">
            Companion <span style={{ color: "#1ec28b" }}>Search</span>
          </div>
        </Container>
        <div>
          <SearchBar
            mySearchData={mySearchData}
            mySearchDataError={mySearchDataError}
            loading={loading}
            setLoading={setLoading}
            setSearching={setSearching}
            page="connect"
            filledValue={
              Object.entries(serachFilled).length > 0 ? serachFilled : null
            }
          />
        </div>

        <div className="connectpeole-main">
          <div>
            <Box
              sx={{
                height: "auto",
                marginBottom: 10,
                backgroundColor: "",
                "&:hover": {
                  backgroundColor: "",
                },
              }}
            >
              <Container>
                <Filters onSubmit={getFilterData} />
              </Container>
            </Box>
          </div>

          <div className="loadMoreReasultsDiv">
            <div>
              <Container>
                <Grid
                  container
                  spacing={2}
                  // style={{ display: "flex", flexWrap: "nowrap", margin: "auto" }}
                >
                  <Grid item xl={6} lg={6} md={6} sm={6} xs={6}></Grid>
                  <Grid
                    item
                    xl={6}
                    lg={6}
                    md={6}
                    sm={6}
                    xs={6}
                    className="filter-mobile"
                  >
                    <button
                      onClick={() => {
                        setFilter(!filter);
                      }}
                      className="filter-btn"
                    >
                      <Icon icon="ci:menu-alt-01" rotate={2} vFlip={true} />
                      &nbsp; Filters
                    </button>
                  
                  </Grid>
                </Grid>
              </Container>
            </div>
            {(searchResponse.length>0&&!searching)?<div>
              <Typography variant="h5" sx={{textAlign:"initial",fontWeight:600,marginLeft:"0.3rem"}}>Search Results</Typography>
            </div>:
            <div >
            {searchResponseError&&<Typography variant="h6" sx={{color:"gray",width:"100%"}}>{searchResponseError}</Typography>}
            {(searchResponse.length==0&&filtering)&&<Typography variant="h6" sx={{color:"gray",width:"100%"}}>No Results Found</Typography>}
          </div>
            }
            {searching?<LoadingPage></LoadingPage>:
             <Box
             sx={{
               width: "auto",
               height: "auto",
               backgroundColor: "rgb(30 194 139 / 10%)",
               "&:hover": {
                 backgroundColor: "",
               },
             }}
           >
              <Container>
               <div>
                 {searchResponse.length > 0 ? (
                   <Grid className="connectpeople-MainCard">
                        <Grid
                     container
                     spacing={2}
                   >
                     {Object.entries(searchResponse).length > 0 &&
                       searchResponse.slice(0, next).map((item, index) => (
                         <Grid
                           item
                           xl={6}
                           lg={6}
                           md={6}
                           sm={12}
                           xs={12}
                           key={index}
                         >
                           <Card className="connectpeople-Card">
                             {item.category == 1 && item.user_type == 1 ? (
                               <CardContent className="connectpeople-contentplace-main-name">
                                 <span>
                                   <b className="connectpeople-contentplace-name">
                                     {item.name}
                                   </b>{" "}
                                   can be your travel companion to{" "}
                                   <b className="connectpeople-contentplace-name">
                                     {item.destination.split(",")[0]}
                                   </b>
                                 </span>
                               </CardContent>
                             ) : item.category == 2 && item.user_type == 1 ? (
                               <CardContent className="connectpeople-contentplace-main-name">
                                 <span>
                                   <b className="connectpeople-contentplace-name">
                                     {item.name}
                                   </b>{" "}
                                   <span>
                                     is looking for travel companion to{" "}
                                   </span>
                                   <span>
                                     <b className="connectpeople-contentplace-name">
                                       {item.destination.split(",")[0]}
                                     </b>
                                   </span>
                                 </span>
                               </CardContent>
                             ) : (
                               <CardContent className="connectpeople-contentplace-main-name">
                                 <span>
                                   <b className="connectpeople-contentplace-name">
                                     {item.user_details.full_name}
                                   </b>{" "}
                                   <span>
                                     is looking for travel companion to{" "}
                                   </span>
                                   <span>
                                     <b className="connectpeople-contentplace-name">
                                       {item.destination.split(",")[0]}
                                     </b>{" "}
                                     on behalf of{" "}
                                     <b className="connectpeople-contentplace-name">
                                       {item.name}
                                     </b>
                                   </span>
                                 </span>
                               </CardContent>
                             )}


                             <div className="connectpeople-container">
                               <div className="connectpeople-inner">
                                 <img
                                   className="connectpeople-img"
                                   variant="top"
                                   src={
                                     item.photo
                                       ? item.photo
                                       : "./Assets/Images/Favicon 25 WB _ DV.webp"
                                   }
                                   alt="..."
                                 />
                               </div>
                             </div>

                             <CardContent
                               style={{
                                 fontSize: 15,
                               }}
                             >
                               <Grid container spacing={2}>
                                 <Grid
                                   item
                                   xl={12}
                                   lg={12}
                                   md={12}
                                   sm={12}
                                   xs={12}
                                   style={{ display: "flex", columnGap: 16 }}
                                 >
                                   <ModeOfTravelIcon
                                     style={{
                                       color: "#1ec38b",
                                     }}
                                   ></ModeOfTravelIcon>
                                   <div className="connnect-ppl-card-places" style={{lineBreak: "anywhere"}}>
                                     {item.trip_name}
                                   </div>
                                 </Grid>
                                 <Grid
                                   item
                                   xl={12}
                                   lg={12}
                                   md={12}
                                   sm={12}
                                   xs={12}
                                   style={{ display: "flex", columnGap: 16 }}
                                 >
                                   <RouteIcon
                                     style={{
                                       color: "#1ec28b",
                                       transform: "rotate(90deg)",
                                     }}
                                   />
                                   <div className="connnect-ppl-card-places">
                                     {item.flying_from.split(",")[0]}
                                     {item.transit1 ? (
                                       <span>
                                         <EastIcon
                                           style={{
                                             color: "#1ec38b",
                                             marginBottom: "-7px",
                                           }}
                                         />
                                         {item.transit1.split(",")[0]}
                                       </span>
                                     ) : null}
                                     {item.transit2 ? (
                                       <span>
                                         <EastIcon
                                           style={{
                                             color: "#1ec38b",
                                             marginBottom: "-7px",
                                           }}
                                         />
                                         {item.transit2.split(",")[0]}
                                       </span>
                                     ) : null}
                                     {item.transit3 ? (
                                       <span>
                                         <EastIcon
                                           style={{
                                             color: "#1ec38b",
                                             marginBottom: "-7px",
                                           }}
                                         />
                                         {item.transit3}
                                       </span>
                                     ) : null}
                                     {item.transit4 ? (
                                       <span>
                                         <EastIcon
                                           style={{
                                             color: "#1ec38b",
                                             marginBottom: "-7px",
                                           }}
                                         />
                                         {item.transit4.split(",")[0]}
                                       </span>
                                     ) : null}
                                     <EastIcon
                                       style={{
                                         color: "#1ec38b",
                                         marginBottom: "-7px",
                                       }}
                                     />
                                     {item.destination.split(",")[0]}
                                   </div>
                                 </Grid>

                                 <Grid
                                   item
                                   xl={12}
                                   lg={12}
                                   md={12}
                                   sm={12}
                                   xs={12}
                                   style={{ display: "flex", columnGap: 16 }}
                                 >
                                   <CalendarMonthOutlinedIcon
                                     style={{
                                       color: "#1ec38b",
                                     }}
                                   ></CalendarMonthOutlinedIcon>
                                 {  console.log(item.booking_status,'item.booking_status')}
                                   <div className="connnect-ppl-card-places">
                                     {item.booking_status===1
                                       ? `Departing Date : ${item.departing_on}`
                                       : `Date Range : ${item.date_range_from} to ${item.date_range_to}`}
                                   </div>
                                 </Grid>
                                 <Grid
                                   item
                                   xl={12}
                                   lg={12}
                                   md={12}
                                   sm={12}
                                   xs={12}
                                   style={{ display: "flex", columnGap: 16 }}
                                 >
                                   <FlightIcon
                                     style={{
                                       color: "#1ec38b",
                                     }}
                                   ></FlightIcon>
                                   <div className="connnect-ppl-card-places">
                                     {item.booking_status == 1
                                       ? "Booked"
                                       : "Yet to book"}
                                   </div>
                                 </Grid>
                                 <Grid
                                   item
                                   xl={12}
                                   lg={12}
                                   md={12}
                                   sm={12}
                                   xs={12}
                                   style={{ display: "flex", columnGap: 16 }}
                                 >
                                   <LanguageIcon
                                     style={{
                                       color: "#1ec38b",
                                     }}
                                   ></LanguageIcon>
                                   <div className="connnect-ppl-card-places">
                                     {item.prefered_language}
                                   </div>
                                 </Grid>
                                 {item.category == 1 ? (
                                   <Grid
                                     item
                                     xl={12}
                                     lg={12}
                                     md={12}
                                     sm={12}
                                     xs={12}
                                     style={{ display: "flex", columnGap: 16 }}
                                   >
                                     <AttachMoneyIcon
                                       style={{ color: "#1ec38b" }}
                                     ></AttachMoneyIcon>
                                     <div className="connnect-ppl-card-places">
                                       Tip I Expect -{" "}
                                       {/* {item.tip_expected}  */}
                                       {/^([^0-9]*)$/.test(item.tip_expected)
                                         ? item.tip_expected
                                         : `${item.tip_expected} USD equiv`}
                                     </div>
                                   </Grid>
                                 ) : null}
                               </Grid>
                             </CardContent>

                             <Button
                               aria-describedby={id}
                               variant="contained"
                               onClick={() => handleClick(item)}
                               className="connectpeople-cardButton"
                             >
                               More Info
                             </Button>

                             <Modal
                               id={id}
                               open={open}
                               closeIcon={closeIcon}
                               style={{ zIndex: 1 }}
                             >
                               <ConnectPeoplePopup
                                 onChildClick={handleClose}
                                 connectUserData={dataToPopup}
                                 page="connectpeople"
                               />
                             </Modal>
                           </Card>
                         </Grid>
                       ))}

                     <div className="loadmoreDiv" style={{ color: "#1ec28b" }}>
                       {Object.entries(searchResponse).length > 0 ? (
                         <p className="loadMoreText">
                           {next < searchResponse.length && (
                             <Button
                               variant="outlined"
                               sx={{
                                 color: "black",
                                 borderColor: "black",
                                 borderRadius: "5px",
                                 fontSize: "12px",
                               }}
                               onClick={handleMoreImage}
                             >
                               Load MORE
                             </Button>
                           )}
                         </p>
                       ) : null}
                     </div>
                     <div className="loadmoreDiv" style={{ color: "#1ec28b" }}>
                       {Object.entries(filteredList).length > 0 ? (
                         <p className="loadMoreText">
                           {next < filteredList.length && (
                             <Button onClick={handleMoreImage}>
                               Load more
                             </Button>
                           )}
                         </p>
                       ) : null}
                     </div>
                   </Grid>
                   </Grid>
                
                 ) : (
                   <Grid>{loading ? `Loading.....` : null}</Grid>
                 )}
               </div>
              
             </Container>
           </Box>
            }
           
          </div>
        </div>
        
        <div>
          <Container>
            <Dialog
              open={invitopen}
              onClose={handleinvitClose}
              // {/* // aria-labelledby="alert-dialog-title"
              // // aria-describedby="alert-dialog-description" */}
              PaperProps={{
                style: {
                  minHeight: "40vh",
                  minWidth: "35vw",
                  boxShadow: "none",
                  border: "2px solid rgb(128 213 184 / 100%)",
                  strokeWidth: "1px",
                  stroke: "rgb(128 213 184 )",
                  borderRadius: 20,
                },
              }}
              BackdropProps={{
                style: {
                  backdrop: "blur(2px)",
                },
              }}
            >
              <div>
                <DialogTitle id="alert-dialog-title">
                  {/* {handleinvitClose ? (
        <IconButton
          aria-label="close"
          onClick={handleinvitClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
                  {/* <div className="invitation-upcoming-delete-dalog">
                      <Icon
                        className="invitation-deleteConfirmationIcon"
                        icon="fluent:delete-12-filled"
                      />
                      Delete
                    </div> */}
                </DialogTitle>
                <DialogContent className="invitation-upcoming-delete-dalog">
                  <h2 className="invitation-areUSure" style={{ color: "red" }}>
                    {" "}
                    Please login for More Info
                  </h2>
                </DialogContent>
                <DialogActions className="invitation-buttonsContainer">
                  {/* <Button
                      id="btn"
                      className="invitation-yesButton"
                      onClick={() => {
                        settingdeleteinvitation();
                      }}
                    >
                      Yes
                    </Button> */}
                  <Button
                    className="invitation-noButton"
                    onClick={handleinvitClose}
                  >
                    OK
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          </Container>

          <Container>
              <Dialog
                open={filter}
                onClose={()=>{setFilter(!filter)}}
                // {/* // aria-labelledby="alert-dialog-title"
                // // aria-describedby="alert-dialog-description" */}
                PaperProps={{
                  style: {
                    backgroundColor:"transparent",
                    // minHeight: "100vh",
                    // width: "100%",
                    boxShadow: "none",
                    // padding:"2rem",
                    // // border: "2px solid rgb(128 213 184 / 100%)",
                    // // strokeWidth: "1px",
                    // stroke: "rgb(128 213 184 )",
                    // borderRadius: 20,

                  },
                }}
                BackdropProps={{
                  style: {
                    backdrop: "blur(2px)",
                  },
                }}
              >
               
                  <DialogTitle id="alert-dialog-title">
                  </DialogTitle>
                  <DialogContent
                    className=""
                  >
                    <MobileFilter
                      setFilter={FilterShow}
                      onSubmit={getFilterData}
                    />
                  </DialogContent>
                  <DialogActions className="">
                   
                  </DialogActions>
                
              </Dialog>
      </Container>
        </div>
      </ScrollTop>
    </>
  );
}

export default ConnectPeople;