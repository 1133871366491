import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from "@mui/material";
import DashboardHeader from "../Dashboard/DashboardHeader";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
// import "../../CSS/DesiCommunity.css";
import "../CSS/DesiCommunity.css";
import React, { useState } from "react";
import ScrollTop from "../ScrollTop";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import TripCount from "../UpcomingTrip_count/TripCount";

function DesiCommunity() {
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const [canCreate,setCancreate]=useState(Boolean(JSON.parse(localStorage.getItem("can"))))

  const [invitopen, setinvitopen] = React.useState(false);
  const handleinvitClose = () => {
    setinvitopen(false);
  };
  
  const handleClickk = () => {
    if (!isLoggedIn) {
         setinvitopen(true);
    } else

    if(!canCreate)
    setinvitopen(true);
  }

  return (
    <>
    <ScrollTop>
      <div>
        <DashboardHeader />
      </div>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="desicom-studentMainHeading">
              Travel Companion by &nbsp;
              <span style={{ color: "#1ec28b" }}> Desi</span>&nbsp;Community
            </p>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="desicom-hrDiv">
              <hr className="desicom-hrStudent"></hr>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="desicom-makeMoneyText">
              Make money on your travel back home (India, Pakistan, Bangladesh,
              Nepal, Sri Lanka) or to your university abroad by being a paid
              travel companion to others.
            </p>
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div className="desicom-homeheading">
              <img
                className="desicom-globeFlightImage"
                src="./Assets/Images/I_2.webp"
                alt="logo"
              />
            </div>
          </Grid>

          

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <p className="desicom-itServiceText">
              Our desi nature is always to help others in need, being a paid
              travel companion is like getting paid to help someone on your
              travel abroad or back home. No additional efforts other than
              assisting a travel companion and being a support. Getting paid is
              an additional perk for the help that most of desi’s are always
              willing to do!!
            </p>

           
          </Grid>

          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div>
              <img
                className="desicom-ruReadyText"
                src="./Assets/Images/I_4.webp"
                alt="logo"
              />
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            xl={12}
            className="desicom-addYourTrip"
          >
            <span>
              Just add your <span style={{ color: "#1ec28b" }}>trip</span> with
              us and select your{" "}
              <span style={{ color: "#1ec28b" }}>payment</span> you expect!!
            </span>
          </Grid>
        </Grid>
        <div>
          <Link to={isLoggedIn&&Boolean(JSON.parse(localStorage.getItem("can"))) ? "/create-a-trip" :""} style={{textDecoration:"none"}} onClick={handleClickk}>
          <button className="desicom-createBtn">
            CREATE A TRIP
            <span className="desicom-ArrowCircleRightRoundedIcon">
              <ArrowCircleRightRoundedIcon />
            </span>
          </button>
          </Link>
        </div>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div>
              <img
                className="desicom-ruReadyText"
                src="./Assets/Images/Desi comm.webp"
                alt="logo"
              />
            </div>
          </Grid>

          {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div>
              <img
                className="desicom-ruReadyText"
                src="./Assets/Images/I_6.jpg"
                alt="logo"
              />
            </div>
          </Grid> */}
        </Grid>
      </Container>
      <div>
        <Container>
          <Dialog
            open={invitopen}
            onClose={handleinvitClose}
            // {/* // aria-labelledby="alert-dialog-title"
            // // aria-describedby="alert-dialog-description" */}
            PaperProps={{
              style: {
                minHeight: "40vh",
                minWidth: "35vw",
                boxShadow: "none",
                border: "2px solid rgb(128 213 184 / 100%)",
                strokeWidth: "1px",
                stroke: "rgb(128 213 184 )",
                borderRadius: 20,
              },
            }}
            BackdropProps={{
              style: {
                backdrop: "blur(2px)",
              },
            }}
          >
            <div>
              <DialogTitle id="alert-dialog-title">
                {/* {handleinvitClose ? (
        <IconButton
          aria-label="close"
          onClick={handleinvitClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
                {/* <div className="invitation-upcoming-delete-dalog">
                      <Icon
                        className="invitation-deleteConfirmationIcon"
                        icon="fluent:delete-12-filled"
                      />
                      Delete
                    </div> */}
              </DialogTitle>
              <DialogContent className="invitation-upcoming-delete-dalog">
                <h2 className="invitation-areUSure" style={{ color: "red" }}>
                  {" "}
                  { !isLoggedIn?"please login to create a trip":
                  !canCreate?"You have reached the maximum limit of 4 trips, So you cannot create trip":""}                </h2>
              </DialogContent>
              <DialogActions className="invitation-buttonsContainer">
                {/* <Button
                      id="btn"
                      className="invitation-yesButton"
                      onClick={() => {
                        settingdeleteinvitation();
                      }}
                    >
                      Yes
                    </Button> */}
                <Button
                  className="invitation-noButton"
                  onClick={handleinvitClose}
                >
                  OK
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </Container>
      </div>
      </ScrollTop>
    </>
  );
}
export default DesiCommunity;