
import React, { useEffect, useState } from "react";
import "../../CSS/footer.css";
import {
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import MailIcon from "@mui/icons-material/Mail";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { NavLink, Link, useLocation } from "react-router-dom";
// import Button from '@material-ui/core/Button';
import { Button } from "@mui/material";
import FooterAds from "../FooterAds/FooterAds";
import { useSelector, useDispatch } from "react-redux";
import TripCount from "../../UpcomingTrip_count/TripCount";

function Footer(props) {
  const [click, setClick] = useState(false);

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [canCreate,setCancreate]=useState(Boolean(JSON.parse(localStorage.getItem("can"))))

  const [invitopen, setinvitopen] = React.useState(false);

  const handleinvitClose = () => {
    setinvitopen(false);
  };
  const handleClickk = () => {
    if (!isLoggedIn) {
         setinvitopen(true);
    }
    else

    if(!Boolean(JSON.parse(localStorage.getItem("can")))){
    setinvitopen(true);
    }
    // if(!Boolean(JSON.parse(localStorage.getItem("can"))))
    //    setinvitopen(true);


  };
  useEffect(() => {
    localStorage.removeItem("can");

    if(user)
       TripCount(user.user_id)
  
  }, []);
  const theLocation = useLocation()
  const currentLocation = theLocation.pathname.split("/")[1];
  // const handleClick = () => setClick(!click);
  return (
    <>
      <FooterAds></FooterAds>
      <div className="footerParentContainer">
        <Container>
          <Grid container spacing={2} className="footercontainer">
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <div className="firstcolumn">
                <div className="footerImg">
                  <img
                    src="../../Assets/Images/desi-travel-campanion-logo.png"
                    alt="desi-travel-campanion-logo"
                    className="footerLogo"
                  />
                </div>
                <p className="footer-logo-content">
                  {/* Desi Travel Companion (Desi TC) is a free to use platform to
                  find Travel Companion to / from India, Pakistan, Bangladesh,
                  Srilanka and Nepal. */}
                  {/* Desi Travel Companion (Desi TC) is a free to use platform to find
Travel Companion to / from India, Pakistan, Bangladesh, Srilanka,
Nepal and beyond. */}
Desi Travel Companion (Desi TC) is a free to use platform to find Travel Companion / Travel Buddy to / from India, USA, UK, Canada, UAE, Australia, Malaysia, Singapore, Germany, New Zealand, Pakistan, Bangladesh, Sri Lanka, Nepal and beyond.
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <p className="secondcolumn">LINKS</p>
              <div className="footerLinks">
                <p>
                  <NavLink
                 
                      to={isLoggedIn
                        &&Boolean(JSON.parse(localStorage.getItem("can")))
                         ? "/create-a-trip" :'/'}
                      className="footer-nav-links"
                      onClick={handleClickk}
                  >
                    Create a Trip
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/companion-search" className="footer-nav-links">
                    Companion Search
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/student-community" className="footer-nav-links">
                    Student Community
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/desi-community" className="footer-nav-links">
                    Desi Community
                  </NavLink>
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <p className="thirdcolumn">SUPPORT</p>
              <div className="footerLinks2">
                <p>
                  <NavLink to="/about-us" className="footer-nav-links">
                    About Us
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/privacy-policy" className="footer-nav-links">
                    Privacy Policy
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/faq" className="footer-nav-links">
                    FAQs
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/ads" className="footer-nav-links">
                    Ad’s Enquiry
                  </NavLink>
                </p>
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={3} lg={3}>
              <p className="fourthcolumn">CONTACT US</p>
              <div className="thirdcolumncontent">
                <p className="fourthCoulmn-query">
                  Do you have any queries? Tap the button below to contact us
                  and we&#39;ll get back to you !!
                </p>
                <Button
                  LinkComponent={Link}
                  exact
                  to="/contactus"
                  // activeClassName="active"
                  className="footer-contact-button"
                  // onClick={handleClick}
                >
                  <NavLink
                    to="/contactus"
                    style={{ textDecoration: "none", color: "black" }}
                  >
                    Contact Us / FEEDBACK
                    <ExpandLessIcon
                      style={{
                        transform: "rotate(90deg)",
                        marginBottom: "-7px",
                      }}
                    />
                  </NavLink>
                </Button>
                {/* <div className="emailUnderLine"></div> */}
                {/* <a
                href="mailto:contact@auxtomate.com?subject=New%20Enquiry%20from%20the%20website"
                style={{
                  textDecoration: "none",
                }}
              >  */}

                {/* <div className="footer-contact-mail">
                  <MailIcon style={{ fontSize: 22, color: "#1EC28B" }} />
                  &nbsp;
                  <span
                    className="footerMail"
                    style={{
                      color: "white",
                    }}
                  >
                    contact@desitravelcompanion.com
                  </span>
                </div> */}

                {/* <div className="footer-number-main">
                  <PhoneIcon style={{ fontSize: 24, color: "#1EC28B" }} />
                  &nbsp;&nbsp;
                  <span className="footer-number">+91-99XXXXXXXX </span>
                </div> */}
              </div>
            </Grid>
          </Grid>
          <div className="underlinefooter"></div>
          <div>
            <Grid container spacing={2} className="footer-bottom-icon-content">
              <Grid item xs={12} sm={9} lg={9}>
                <div className="footerDisclaimer">
                  {new Date().getFullYear()} Desi Travel Companion. All rights
                  reserved.
                </div>
              </Grid>
              <Grid item xs={12} sm={12} lg={3}>
                <Grid container className="FooterIcons">
                  <Grid item xs={3} style={{ color: "#1EC28B " }}>
                    {/* <InstagramIcon style={{ fontSize: 35 }} /> */}
                  </Grid>

                  <Grid item xs={3} style={{ color: "#1EC28B " }}>
                    {/* <TwitterIcon style={{ fontSize: 35 }} /> */}
                  </Grid>
                  <Grid item xs={3} style={{ color: "#1EC28B " }}>
                    {/* <LinkedInIcon style={{ fontSize: 35 }} /> */}
                  </Grid>
                  <a href="https://www.facebook.com/gDesiTravelCompanion" target="_blank">
                    <Grid item xs={3} style={{ color: "#1EC28B " }}>
                      <FacebookIcon style={{ fontSize: 35 }} />
                    </Grid>
                  </a>
                </Grid>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="footer-bottom-icon-content">
            <Grid item xs={12} sm={9} lg={9}>
                <div className="footerDisclaimerProperty">
            All logos and brand names are the property of their respective owners.
            </div>
              </Grid>
            </Grid>
          </div>
        </Container>
      </div>
      <div>
        <Container>
          <Dialog
            open={invitopen}
            onClose={handleinvitClose}
            // {/* // aria-labelledby="alert-dialog-title"
            // // aria-describedby="alert-dialog-description" */}
            PaperProps={{
              style: {
                minHeight: "40vh",
                minWidth: "35vw",
                boxShadow: "none",
                border: "2px solid rgb(128 213 184 / 100%)",
                strokeWidth: "1px",
                stroke: "rgb(128 213 184 )",
                borderRadius: 20,
              },
            }}
            BackdropProps={{
              style: {
                backdrop: "blur(2px)",
              },
            }}
          >
            <div>
              <DialogTitle id="alert-dialog-title">
                {/* {handleinvitClose ? (
        <IconButton
          aria-label="close"
          onClick={handleinvitClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
                {/* <div className="invitation-upcoming-delete-dalog">
                      <Icon
                        className="invitation-deleteConfirmationIcon"
                        icon="fluent:delete-12-filled"
                      />
                      Delete
                    </div> */}
              </DialogTitle>
              <DialogContent className="invitation-upcoming-delete-dalog">
                <h2 className="invitation-areUSure" style={{ color: "red" }}>
                  {" "}
                  { !isLoggedIn?"please login to create a trip":
                  !Boolean(JSON.parse(localStorage.getItem("can")))?
                    "You have reached the maximum limit of 4 trips, So you cannot create trip":""}                </h2>
              </DialogContent>
              <DialogActions className="invitation-buttonsContainer">
                {/* <Button
                      id="btn"
                      className="invitation-yesButton"
                      onClick={() => {
                        settingdeleteinvitation();
                      }}
                    >
                      Yes
                    </Button> */}
                <Button
                  className="invitation-noButton"
                  onClick={handleinvitClose}
                >
                  OK
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </Container>
      </div>
    </>
  );
}

export default Footer;
