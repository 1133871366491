import { FormControl, Grid, StepConnector, Typography } from "@mui/material";
import React, { useState } from "react";
// import "../../CSS/createTripCSS/createTrip.css";
// import DashboardHeader from "../../Dashboard/DashboardHeader";
import VerticalStepper from "./VerticalStepper";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
import DashboardHeader from "../../Dashboard/DashboardHeader";
import "./CreateTripNew.css";
import panel from "./Assets/Images/FlightLand.webp";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import StepCircle from "./Assets/Stepper/StepCircle";
import CustomizedSteppers from "./Assets/Stepper/customStepper";
import ScrollTop from "../../ScrollTop";

import {
  Box,
  Button,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
} from "@mui/material";
import { useEffect } from "react";
import { useSelector } from "react-redux";

function CreateTripNew() {

  const { isLoggedIn, user } = useSelector((state) => state.auth);


  const [activeStep, setActiveStep] = React.useState(0);
  const [stepCompleted, setStepCompleted] = useState(["1", "0", "0"]);
  const [customError, setCustomError] = useState({});

  const langArray = [
    "Arabic",
    "Assamese",
    "Awadhi",
    "Azerbaijani",
    "Belarusan",
    "Bengali",
    "Bhojpuri",
    "Bulgarian",
    "Burmese",
    "Cebuano",
    "Chhattisgarhi",
    "Chittagonian",
    "Czech",
    "Deccan",
    "Dutch",
    "English",
    "Farsi",
    "Farsi, Western",
    "French",
    "Fulfulde",
    "Gan",
    "German",
    "Greek",
    "Gujarati",
    "Hakka",
    "Haryanvi",
    "Hausa",
    "Hiligaynon",
    "Hindi",
    "Hungarian",
    "Igbo",
    "Ilocano",
    "Indonesian",
    "Italian",
    "Japanese",
    "Javanese",
    "Jinyu",
    "Kannada",
    "Kazakh",
    "Khmer",
    "Korean",
    "Kurmanji",
    "Lombard",
    "Madura",
    "Magahi",
    "Maithili",
    "Malagasy",
    "Malay",
    "Malayalam",
    "Mandarin",
    "Marathi",
    "Marwari",
    "Min Bei",
    "Min Nan",
    "Napoletano",
    "Nepali",
    "Oriya",
    "Panjabi",
    "Pashto",
    "Polish",
    "Portuguese",
    "Romanian",
    "Russian",
    "Rwanda",
    "Saraiki",
    "Sindhi",
    "Sinhala",
    "Somali",
    "Spanish",
    "Sunda",
    "Swedish",
    "Tagalog",
    "Tamil",
    "Tatar",
    "Telugu",
    "Thai",
    "Turkish",
    "Ukrainian",
    "Urdu",
    "Uyghur",
    "Uzbek",
    "Vietnamese",
    "Wu",
    "Xiang",
    "Yoruba",
    "Yue",
    "Zhuang",
    "Zulu",
  ];

  const langNewArray = [];

  langArray.map((lang, index) => {
    const obj = {
      label: lang,
      name: lang,
      id: index,
    };
    langNewArray.push(obj);
  });
  console.log(JSON.stringify(langNewArray), "langJSON");
  function getSteps() {
    return [
      {
        circleIcon: (
          <b style={{ color: "darkgreen" }}>
            <CheckCircleIcon />
          </b>
        ),
        title: "Traveler Details",
        subTitle: "Give the Traveler Details",
      },
      {
        circleIcon: (
          <b style={{ color: "darkgreen" }}>
            <CheckCircleIcon />
          </b>
        ),
        title: "Airport, Airline & Language",
        subTitle: "Select your airport, airline and language details",
      },
      {
        circleIcon: (
          <b style={{ color: "darkgreen" }}>
            <CheckCircleIcon />
          </b>
        ),
        title: "Trip Details",
        subTitle: "Give your trip name,short description & Meetup location",
      },
      {
        circleIcon: (
          <b style={{ color: "darkgreen" }}>
            <CheckCircleIcon />
          </b>
        ),
        title: "Full Details about Trip",
        subTitle: "Details about your full trip",
      },
      // <b style={{ color: "grey" }}>Step(5/5)</b>,
    ];
  }

  const steps = getSteps();

  const stepperData = [
    {
      Maintext: "Traveller Details",
      subText: "Give the traveller details",
    },
    {
      Maintext: "Airport, Airline& Language",
      subText: "Select your airport, airline and language details",
    },
    {
      Maintext: "Trip Details",
      subText: "Give your trip name and short description & Meetup location",
    },
    {
      Maintext: "Full Details about Trip",
      subText: "Details about your full trip",
    },
  ];


  const navigate = useNavigate();
  useEffect(
     ()=>{if(user.update_profile_details!=="False"){
     return navigate('/edit-profile',{state:'true'})
    }}
  ,[]
  )

  return (
    <Grid>
      <ScrollTop>
        <Grid>
          <DashboardHeader />
        </Grid>

        <div className="createTrip_body">
          <Grid className="createTripHeading responsive">
            Create a <span style={{ color: "#1ec28b" }}>Trip</span>
          </Grid>

          <Grid className="responsive">
            <Grid>
              <h3 className="createTripHeadingText">
                "The world is a book and those who do not travel read only one
                page."
              </h3>
            </Grid>
          </Grid>

          <Grid className="Panel_Container">
            <Grid className="panel_image">
              <img src={panel} />
            </Grid>
          </Grid>

          <Grid className="Stepper_Container mt-2 responsive">
            <Grid className="Stepper_heading">
              {`Follow the simple steps to create a trip. (${activeStep +
                1}/4)`}
            </Grid>
          </Grid>

          <Grid className="Stepper mt-2">
            <Stepper
              activeStep={activeStep}
              className="S_H"
              alternativeLabel
              connector={<div></div>}
              sx={{ width: "100%", marginTop: "2rem" }}
            >
              {steps.map((label, i) => (
                <Step>
                  <div
                    // style={{backgroundColor:"green"}}
                    className="center_column"
                  >
                    <div className={`stepTitle${i}`}>{label.title}</div>
                    <div className={`mt-1 step_subTitle${i}`}>
                      {label.subTitle}
                    </div>
                  </div>
                </Step>
              ))}
            </Stepper>

            <div className="mt-2">
              <CustomizedSteppers
                activeStep={activeStep}
                stepCompleted={stepCompleted}
              />
            </div>
          </Grid>

          <Grid className="Stepper_Container mt-4 responsive">
            <Typography
              variant="h5"
              className="stepper-steps-text"
              style={{ textAlign: "initial" }}
            >{`Step (${activeStep + 1}/4)`}</Typography>
            <Typography variant="h5" className="Stepper_heading">
              {steps[activeStep].subTitle}
            </Typography>
          </Grid>

          <Grid className="mt-3">
            <VerticalStepper
              steps={steps}
              activeStep={activeStep}
              setActiveStep={setActiveStep}
              stepCompleted={stepCompleted}
              setStepCompleted={setStepCompleted}
              customError={customError}
              setCustomError={setCustomError}
            />
          </Grid>
        </div>
      </ScrollTop>
    </Grid>
  );
}
export default CreateTripNew;
