import React from 'react'
import "./YearRangePicker.css"
import { Icon } from "@iconify/react";
import dayjs from 'dayjs';
import { Button, Divider, Popover, Segmented } from 'antd';
import { isClickableInput } from '@testing-library/user-event/dist/utils';

const YearRangePicker = ({settingState,name,place,wholeYears,background}) => {

    const [current,setCurrent]=React.useState(0)
    const [yearsArray,setyearsArray]=React.useState()
    const [yearRange,setYearRange]=React.useState([])
    const [open,setOpen]=React.useState(false)
    const [showArrow, setShowArrow] = React.useState(false);
  const [arrowAtCenter, setArrowAtCenter] = React.useState(false);
  const[openPop,setOpenPop]=React.useState(false)
  const mergedArrow = React.useMemo(() => {
    if (arrowAtCenter)
      return {
        pointAtCenter: true,
      };
    return showArrow;
  }, [showArrow, arrowAtCenter]);

    console.log(yearsArray,'yearsArray')

   React.useEffect(() => {
    const Totalears=Years('range')
    let currentYear=dayjs().format("YYYY")
    Totalears.map((array,index)=>{
        if(array.includes(parseInt(currentYear))){
            setCurrent(index)
            setyearsArray(array)
        }
    })
   }, [])

const arrowDisable=()=>{
    let flag=false
    if(current===0){
        flag=true
    }
    return flag
}
   const gettingYearRange=()=>{
    let result;
    if(Array.isArray(wholeYears[name])){
        result=wholeYears[name]
    }else if(typeof wholeYears[name]==="object"){
        if(Array.isArray(wholeYears[name].year))
             result=wholeYears[name].year
    }
    return typeof result==="object"?result:null
    }

   const ArrowHandler=(dir)=>{
    const Totalears=Years('range')
    if(dir==="left"){

        if(current!==0){
            setCurrent(current-1)
            setyearsArray(Totalears[current-1])
        }
    }

    if((dir==="right"&&(Totalears[current+1]))){
        if(current<=Totalears[current+1].length){
            setCurrent(current+1)
            setyearsArray(Totalears[current+1])
        }
       
    }
   }
   const checkDate=(yearArray)=>{
    let array=yearArray
    if(yearArray.length!==1) {
        array=deselectYear(array);
        console.log(array,"duplicate")
        if(array.length===2){
            if(array[1]-array[0]<0)
                 array=[array[1],array[0]]
        } 
    }
    return array
   }

   const deselectYear=(array2filter)=>{
    const frequencyMap = array2filter.reduce((acc, value) => {
        acc[value] = (acc[value] || 0) + 1;
        return acc;
      }, {});
      const arrayWithoutDuplicates = array2filter.filter(value => frequencyMap[value] === 1);
     return arrayWithoutDuplicates
   }

   const handleYearChange=(year)=>{
    if(!isDisabled(year)){
        if(yearRange.length===1){
            setYearRange(checkDate([...yearRange,year]))
            if(checkDate([...yearRange,year]).length===2) setTimeout(() => {
                setOpenPop(false)
                if(name==='source_destination'){
                    settingState((pre)=>{
                        return {
                         ...pre,
                         [name]:{
                            ...pre[name],
                            year:checkDate([...yearRange,year])
                         }
                     } 
                     })
                }else{

                    settingState((pre)=>{
                        return {
                         ...pre,
                         [name]:checkDate([...yearRange,year])
                     } 
                     })
                }
            }, 800);
        }else{
                    if(yearRange.length>1){
                        setYearRange(checkDate([...yearRange,year]))
                    }
                    else{
                        setYearRange(checkDate([year]))
                    }
                        
        }
    }
   }
   const checkingDiff=(year)=>{
    let flag=false
    if(yearRange.length===2){
        if(year>yearRange[0]&&year<yearRange[1]){
            flag=true
        }
    }
    return flag
   }

   const content=()=>{
    return <>
               <div className='calender-con'>
                <div className='calendar-nav'>
                    <span className='yearLabel'>Year</span>
                    <div className='arrows-calender'>
                        <Icon 
                        icon="solar:alt-arrow-left-outline" 
                        className={arrowDisable()?'calender-left-arrow-disable':'calender-left-arrow'} 
                        onClick={()=>{
                            ArrowHandler("left")
                        }}/>
                        <Icon 
                        icon="solar:alt-arrow-right-outline" 
                        className='calender-right-arrow' 
                        onClick={()=>{
                            ArrowHandler("right")
                        }}/>
                    </div>
                </div>
                <div className='calender'>
                {yearsArray?yearsArray.map((year)=>{
                           return <div className={yearRange.includes(year)?'yearString-selected yearString-range yearString':(checkingDiff(year)?'yearString-selected yearString':'yearString')} onClick={()=>{
                                handleYearChange(year)
                           }}><div className={yearRange.includes(year)?'year-span year-span-selected':(isDisabled(year)?'year-span year-span-disabled':'year-span')}>{year}</div></div>
                    }):null}
               </div>
               </div>
          </>
   }

   const handleOpenChange = (newOpen) => {
    setOpenPop(newOpen);
  };
  
  return (
    <div>
        <div>
           { place==='yearRange'?<Popover 
           content={content} 
           arrow={mergedArrow} 
           placement="bottom" 
           trigger="click"
           onOpenChange={handleOpenChange}
           open={openPop}
           >
           <div className='yerString' onClick={()=>{setOpen(true)}}>
            {console.log(yearRange.length===2,"check-yearRange")}
               <div>{`Select year range : ${gettingYearRange()?gettingYearRange()[0] :'yyyy'} to ${gettingYearRange()?gettingYearRange()[1]:'yyyy'}`}</div> 
               <Icon icon="uis:calender" className='calender-icon' color='#6c6c6c' id="calender-back" onClick={(e)=>{
               console.log(e.target.id,"backing-c")
               if(e.target.id==="calender-back")
                     setOpen(!open)
               }}></Icon>
            </div>
           </Popover>
         
            :
            <div className="trip-select-box">
                      <label for="year">Select Year : </label>
                      <select name="year" id="year" value={Array.isArray(wholeYears[name])?" ":(typeof wholeYears[name]==='object'?wholeYears[name].year:wholeYears[name])} className="trip-select" 
                      onChange={(e)=>{
                        setYearRange([])
                       if(name==='source_destination'){
                            settingState((pre)=>{
                                return {
                                 ...pre,
                                 [name]:{
                                    ...pre[name],
                                    year:e.target.value
                                 }
                             } 
                             })
                        }else{
                            setYearRange([])
                            settingState((pre)=>{
                                return {
                                 ...pre,
                                 [name]:e.target.value
                             } 
                             })
                        }
                      }}>
                       {Years('select').map((year,i)=>{
                        return  <option value={year.value} disabled={i===0}>{year.label}</option>
                       }) }
                        
                      </select>
                    </div>}
        </div>
    </div>
  )
}

const Years=(type)=>{
    let startYear=2023
    const a = dayjs()
     const b = a.add(37, 'Year').format("YYYY")
     const c=a.format("YYYY")
    let start=startYear
    let s_start=startYear
    let array=[start]
    let selectArray=[{label:"YYYY",value:" "},{
        label:s_start,
        value:s_start
    }]
    for(let year=startYear;year<=parseInt(b);year++){
        start=parseInt(start)+1
        array.push(start)
    }
        for(let year=startYear;year<parseInt(c);year++){
        s_start=parseInt(s_start)+1
        let obj={
            label:s_start,
            value:s_start
        }
        selectArray.push(obj)
    }
    function chunkArray(arr, chunkSize) {
        const result = [];
        for (let i = 0; i < arr.length; i += chunkSize) {
            result.push(arr.slice(i, i + chunkSize));
        }
        return result;
    }
    
    const chunks = chunkArray(array, 20);
    
   return type==='range'?chunks:selectArray
}

const isDisabled=(year)=>{
    const a = dayjs().format("YYYY")
   return  parseInt(year)>parseInt(a)
          
}

export default YearRangePicker