import React from "react";
import "../../CSS/ads.css";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Fragment } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Select,
  InputLabel,
  Autocomplete,
  inputLabelClasses,
  Container,
  Input,
} from "@mui/material";
import { useState } from "react";
import { useEffect } from "react";
import Captcha from "demos-react-captcha";
import { Icon } from "@iconify/react";
import axios from "axios";
// import Captcha from "demos-react-captcha";

function AdsForm(props) {
  const {
    control,
    values,
    setValue,
    errors,
    register,
    reset,
    resetField,
  } = props;

  const [mycountry, setCountries] = useState([]);
  const [state, setState] = useState([]);
  const [location, setLocation] = useState([]);

  // useEffect(() => {
  //   const data = require("../../json/Country-State-City.json");
  //   console.log("data", data);
  //   setCountries(data);
  // }, []);

  const [inputValue, setInputValue] = React.useState();
  const [country_search_response, setcountry_search_response] = useState([]);


  let ad_location_array = [
    {  name: "Banner ad on Home Page" },
    {  name: "Footer ad1" },
    { name: "Footer ad2" },
    { name: "Footer ad3" },
    { name: "Student Community ad" },
    { name: "Other" },
 ]


 let ad_duration_array = [
  {  name: "1 month (Premium)" },
  {  name: "3 months" },
  { name: "6 months" },
  { name: "1 Year (discounted)" },
]


const countrystatepincode=()=>{
  console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
  let domain = "";
  console.log(process.env, "domain env", window.location.hostname);
  if (window.location.hostname.trim() === "localhost") {
    domain = process.env.REACT_APP_LOCAL_API;
  } else if (window.location.hostname.trim() === "54.185.6.32") {
    domain = process.env.REACT_APP_UAT_API;
  } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_UAT_API;
  }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_API;
  }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_API;
  }
  console.log(domain, "domain env end");

  axios.get( `${domain}/country-state?country_name=${inputValue}
  `).then(res=>{
    console.log(res,"c response")
    if(res.data.response){
      setCountries(res.data.response)}
  }
)
}

 useEffect(() => {
    // document.title =  "Desi Travel Companion-signup";
   countrystatepincode();

  }, [inputValue]);

  const ariaLabel = { "aria-label": "description" };
  const [answer, setAnswer] = React.useState("");

  return (
    <Container>
      <Fragment>
        <Box px={3} py={2} className="ads-box">
          <Typography variant="h1" align="left" margin="dense">
            <span className="ads-headingText ">
              Enquiry for<span style={{ color: "#1ec28b" }}> Ad's</span>
            </span>
          </Typography>

          <Grid container spacing={2} className="ads-mainPage">
            <Grid item xs={12} sm={12}>
              <label className="ads-Text">
                Name<span style={{ color: "#ff8d8d" }}>*</span>
              </label>
              <TextField
                required
                id="name"
                name="name"
                // label="Enter Your Name"
                placeholder="Enter Your Name"
                fullWidth
                margin="dense"
                {...register("name")}
                inputProps={{
                  maxLength: 32,
                }}
                error={errors.name ? true : false}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: "#1ec28b",
                    },
                  },
                  "& .MuiInputLabel-root": { color: "#1ec28b" }, //styles the label
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { borderColor: "#1ec28b" },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: "#1ec28b",
                    },
                  },
                }}
              />
              <Typography variant="inherit" color="red">
                {errors.name && errors.name.message}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="ads-Text">Company Name</label>
              <TextField
                required
                id="company_name"
                name="company_name"
                // label="Enter Company Name"
                placeholder="Enter Company Name"
                // inputProps={ariaLabel}
                fullWidth
                margin="dense"
                {...register("company_name")}
                inputProps={{
                  maxLength: 32,
                }}
                // error={errors.companyname ? true : false}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: "#1ec28b",
                    },
                  },
                  "& .MuiInputLabel-root": { color: "green" }, //styles the label
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { borderColor: "#1ec28b" },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: "#1ec28b",
                    },
                  },
                }}
              />
               <Typography variant="inherit" color="red">
              {errors.companyname && errors.companyname.message}
            </Typography> 
            </Grid>
            <Grid item xs={12} sm={12}>
              <label className="ads-Text">Website Name</label>
              <TextField
                // required
                id="website"
                name="website"
                // label="Enter your Website"
                placeholder="Enter your Website"
                fullWidth
                margin="dense"
                {...register("website")}
                inputProps={{
                  maxLength: 32,
                }}

                error={errors.website ? true : false}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: "#1ec28b",
                    },
                  },
                  "& .MuiInputLabel-root": { color: "green" }, //styles the label
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { borderColor: "#1ec28b" },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: "#1ec28b",
                    },
                  },
                }}
              />
              <Typography variant="inherit" color="red">
                {errors.website && errors.website.message}
              </Typography>
            </Grid>

            <Grid item xl={6} lg={12} md={6} sm={12} xs={12}>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="ads-Text">
                    Country<span style={{ color: "#ff8d8d" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="country"
                    render={({ field: { onChange, value, name } }) => (
                      <Autocomplete
                      popupIcon={
                        <Icon
                          icon="gridicons:dropdown"
                          style={{ color: "#ff8d8d", fontSize: 30 }}
                        />
                      }

                      inputValue={inputValue}
                      onInputChange={(event, newInputValue) => {
                        setInputValue(newInputValue);
                      }}
                        options={mycountry}
                        getOptionLabel={(option) => option.name||option}
                        onChange={(event, item) => {
                          console.log(item, "countyItem");
                          if (item !== null) {
                            setState();
                            setValue("state", "");
                            resetField("state");
                            setState(item.states);

                            setValue("country_code", item.phone_code, {
                              shouldValidate: true,
                            });
                            console.log(item.phone_code, "countyItem");
                            setValue("country", item?item.name:'', {
                              shouldValidate: true,
                            });
                          }
                        }}
                        // value={value}
                        value={value ? value : null}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            className=""
                            placeholder="Select Country"
                            {...params}
                            InputLabelProps={{
                              sx: {
                                color: "black",
                                [`&.${inputLabelClasses.shrink}`]: {
                                  color: "black",
                                },
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  borderColor: "#1ec28b",
                                },
                              },
                              "& .MuiInputLabel-root": { color: "green" }, //styles the label
                              "& .MuiOutlinedInput-root": {
                                "& > fieldset": { borderColor: "#1ec28b" },
                              },
                              "& .MuiOutlinedInput-root:hover": {
                                "& > fieldset": {
                                  borderColor: "#1ec28b",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  />
                </div>
              </div>
              <div style={{ color: "red" }}>
                {errors.country && errors.country.message}
              </div>
            </Grid>

            <Grid item xl={6} lg={12} md={6} sm={12} xs={12}>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="ads-Text">
                    State/Province<span style={{ color: "#ff8d8d" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="state"
                    render={({ field: { onChange, value, name } }) => (
                      <Autocomplete
                      popupIcon={
                        <Icon
                          icon="gridicons:dropdown"
                          style={{ color: "#ff8d8d", fontSize: 30 }}
                        />
                      }
                        options={state}
                        getOptionLabel={(option) => option.name || option}
                        onChange={(event, item) => {
                          console.log(item, "countyItem");
                          setValue("state", item?item.name:'', { shouldValidate: true });
                        }}
                        // value={value}
                        value={value ? value : null}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            className=""
                            placeholder="Select State"
                            {...params}
                            InputLabelProps={{
                              sx: {
                                // set the color of the label when not shrinked
                                color: "black",
                                [`&.${inputLabelClasses.shrink}`]: {
                                  // set the color of the label when shrinked (usually when the TextField is focused)
                                  color: "black",
                                },
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  borderColor: "#1ec28b",
                                },
                              },
                              "& .MuiInputLabel-root": { color: "green" }, //styles the label
                              "& .MuiOutlinedInput-root": {
                                "& > fieldset": { borderColor: "#1ec28b" },
                              },
                              "& .MuiOutlinedInput-root:hover": {
                                "& > fieldset": {
                                  borderColor: "#1ec28b",
                                },
                              },
                            }}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   autoComplete: "new-password", // disable autocomplete and autofill
                            // }}
                          />
                        )}
                      />
                    )}
                  />
                </div>
              </div>
              <div style={{ color: "red" }}>
                {errors.state && errors.state.message}
              </div>
            </Grid>

            {/* <Grid item xs={12} sm={12}>
              <label className="ads-Text">
                Country<span style={{ color: "#ff8d8d" }}>*</span>
              </label>
              <div className="col-md-6">
                <div className="form-group">
                  <Controller
                    control={control}
                    name="country"
                    render={({ field: { onChange, value, name } }) => (

                  <Autocomplete
                    options={mycountry}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, item) => {
                      console.log(item, "countyItem");
                      if (item !== null) {
                        setState();
                        setValue("state", "");

                        resetField("state");

                        setState(item.states);
                        setValue("country_code", item.phone_code, {
                          shouldValidate: true,
                        });
                        console.log(item.phone_code, "countyItem");
                        setValue("country", item, {
                          shouldValidate: true,
                        });
                      }
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        className=""
                        {...params}
                        id="country"
                        name="country"
                        // label="Select Country"
                        placeholder="Select Country"
                        margin="dense"
                        {...register("country")}
                        error={errors.country ? true : false}
                        InputLabelProps={{
                          sx: {
                            // set the color of the label when not shrinked
                            color: "black",
                            [`&.${inputLabelClasses.shrink}`]: {
                              // set the color of the label when shrinked (usually when the TextField is focused)
                              color: "black",
                            },
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "#1ec28b",
                            },
                          },
                          "& .MuiInputLabel-root": { color: "green" }, //styles the label
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": { borderColor: "#1ec28b" },
                          },
                          "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                              borderColor: "#1ec28b",
                            },
                          },
                        }}
                        // inputProps={{
                        //   ...params.inputProps,
                        //   autoComplete: "new-password", // disable autocomplete and autofill
                        // }}
                      />
                    )}
                  />
                
                </div>
              </div>
              <div style={{ color: "red" }}>
                {errors.country && errors.country.message}
              </div>
            </Grid> */}

            {/* <Grid item xs={12} sm={12}>
              <label className="ads-Text">
                State/ Province<span style={{ color: "#ff8d8d" }}>*</span>
              </label>
              <div className="col-md-6">
                <div className="form-group">
                  <Controller
                    control={control}
                    name="state"
                    // defaultValue=""
                    render={({ field: { onChange, value } }) => (
                      <Autocomplete
                        value={value ? value : null}
                        options={state}
                        getOptionLabel={(option) => option.name}
                        // defaultValue={[{}]}
                        // onChange={(event, item) => {
                        //   console.log(item, "countyItem");
                        //   setValue("state", item.name, { shouldValidate: true });
                        // }}
                        onChange={(event, item) => {
                          console.log(item, "countyItem");
                          // if (item !== null) {
                          //   setLocation(item.cities);
                          console.log(item, "countyItem");
                          setValue("state", item, { shouldValidate: true });
                          // }
                        }}
                        renderInput={(params) => (
                          <TextField
                            required
                            className=""
                            {...params}
                            id="state"
                            name="state"
                            // label="Select State"
                            placeholder="Select State"
                            {...register("state")}
                            error={errors.state ? true : false}
                            InputLabelProps={{
                              sx: {
                                // set the color of the label when not shrinked
                                color: "black",
                                [`&.${inputLabelClasses.shrink}`]: {
                                  // set the color of the label when shrinked (usually when the TextField is focused)
                                  color: "black",
                                },
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  borderColor: "#1ec28b",
                                },
                              },
                              "& .MuiInputLabel-root": { color: "green" }, //styles the label
                              "& .MuiOutlinedInput-root": {
                                "& > fieldset": { borderColor: "#1ec28b" },
                              },
                              "& .MuiOutlinedInput-root:hover": {
                                "& > fieldset": {
                                  borderColor: "#1ec28b",
                                },
                              },
                            }}
                          />
                        )}
                      />
                    )}
                  ></Controller>

                
                </div>
              </div>
              <div style={{ color: "red" }}>
                {errors.state && errors.state.message}
              </div>
            </Grid> */}
            {/* <Grid item xs={11} sm={11}>
            <label className="ads-PhoneText">
              Phone Number<span style={{ color: "#ff8d8d" }}>*</span>
            </label>
            <div className="ads-code">
              <Grid item xl={2} lg={2} md={2} sm={3} xs={3}>
                <TextField
                  required
                  id="country_code"
                  name="country_code"
                  margin="dense"
                  {...register("country_code")}
                  error={errors.country_code ? true : false}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused": {
                      "& > fieldset": {
                        borderColor: "#1ec28b",
                      },
                    },
                    "& .MuiInputLabel-root": { color: "green" }, //styles the label
                    "& .MuiOutlinedInput-root": {
                      "& > fieldset": { borderColor: "#1ec28b" },
                    },
                  }}
                />
              </Grid>
              <div>
                <Grid  item xs={11} sm={11}>
                  <TextField
                    className="ads-phone"
                    required
                    id="phone_number"
                    name="phone_number"
                    margin="dense"
                    {...register("phone_number")}
                    error={errors.phone_number ? true : false}
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          borderColor: "#1ec28b",
                        },
                      },
                      "& .MuiInputLabel-root": { color: "green" }, //styles the label
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { borderColor: "#1ec28b" },
                      },
                      "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                          borderColor: "#1ec28b",
                        },
                      },
                    }}
                  />
                </Grid>
              </div>
              </div>
              <div style={{ color: "red" }}>
                {errors.phone_number && errors.phone_number.message}
              </div>
            </Grid> */}

            <Grid item xs={12} sm={12} className="ads-phone-text-label">
              <label>
                Phone Number<span style={{ color: "#ff8d8d" }}>*</span>
              </label>

              <div className="ads-phone ">
                <Grid tem xl={2} lg={2} md={2} sm={4.5} xs={4.5}>
                  <TextField
                    required
                    id="country_code"
                    name="country_code"
                    // placeholder="code"
                    margin="dense"
                    className="ads-code "
                    disabled
                    {...register("country_code")}
                    error={errors.country_code ? true : false}
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          borderColor: "#1ec28b",
                        },
                      },
                      "& .MuiInputLabel-root": { color: "green" }, //styles the label
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { borderColor: "#1ec28b" },
                      },
                    }}
                  />
                </Grid>
                <Grid item xl={10} lg={10} md={10} sm={7.5} xs={7.5}>
                  <TextField
                    // className="ads-phone"
                    required
                    id="phone_number"
                    className="ads-PhoneText"
                    placeholder="Enter Phone Number"
                    name="phone_number"
                    margin="dense"
                    {...register("phone_number")}
                    error={errors.phone_number ? true : false}
                    sx={{
                      "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                          borderColor: "#1ec28b",
                        },
                      },
                      "& .MuiInputLabel-root": { color: "green" }, //styles the label
                      "& .MuiOutlinedInput-root": {
                        "& > fieldset": { borderColor: "#1ec28b" },
                      },
                      "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                          borderColor: "#1ec28b",
                        },
                      },
                    }}
                  />
                </Grid>
              </div>
              <div style={{ color: "red", textAlign: "center" }}>
                {errors.phone_number && errors.phone_number.message}
              </div>
            </Grid>
            <Grid item xs={12} sm={12}>
              <label className="ads-Text">
                Email<span style={{ color: "#ff8d8d" }}>*</span>
              </label>
              <TextField
                required
                id="name"
                name="email"
                // label="Enter Your Name"
                placeholder="Enter Your Email"
                fullWidth
                margin="dense"
                {...register("email")}
                inputProps={{
                  maxLength: 32,
                }}
                error={errors.email ? true : false}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: "#1ec28b",
                    },
                  },
                  "& .MuiInputLabel-root": { color: "#1ec28b" }, //styles the label
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { borderColor: "#1ec28b" },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: "#1ec28b",
                    },
                  },
                }}
              />
              <Typography variant="inherit" color="red">
                {errors.email && errors.email.message}
              </Typography>
            </Grid>
            {/* <Grid item xs={12} sm={12}>
              <label className="ads-Text">
                Ad Location<span style={{ color: "#ff8d8d" }}>*</span>
              </label>
              <div className="col-md-6">
                <div className="form-group">
                  <Autocomplete
                    options={location}
                    getOptionLabel={(option) => option.name}
                    onChange={(event, item) => {
                      console.log(item, "countyItem");
                      setValue("ad_location", item.name, {
                        shouldValidate: true,
                      });
                    }}
                    renderInput={(params) => (
                      <TextField
                        required
                        className=""
                        {...params}
                        id="ad_location"
                        name="ad_location"
                        
                        placeholder="Location"
                        {...register("ad_location")}
                        error={errors.ad_location ? true : false}
                        InputLabelProps={{
                          sx: {
                          
                            color: "black",
                            [`&.${inputLabelClasses.shrink}`]: {
                              
                              color: "black",
                            },
                          },
                        }}
                        sx={{
                          "& .MuiOutlinedInput-root.Mui-focused": {
                            "& > fieldset": {
                              borderColor: "#1ec28b",
                            },
                          },
                          "& .MuiInputLabel-root": { color: "green" }, 
                          "& .MuiOutlinedInput-root": {
                            "& > fieldset": { borderColor: "#1ec28b" },
                          },
                          "& .MuiOutlinedInput-root:hover": {
                            "& > fieldset": {
                              borderColor: "#1ec28b",
                            },
                          },
                        }}
                      />
                    )}
                  />
                  
                </div>
              </div>
              <div style={{ color: "red" }}>
                {errors.ad_location && errors.ad_location.message}
              </div>
            </Grid> */}
 <Grid item xl={6} lg={12} md={6} sm={12} xs={12}>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="ads-Text">
                  Ad Location<span style={{ color: "#ff8d8d" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="ad_location"
                    render={({ field: { onChange, value, name } }) => (
                      <Autocomplete
                      popupIcon={
                        <Icon
                          icon="gridicons:dropdown"
                          style={{ color: "#ff8d8d", fontSize: 30 }}
                        />
                      }
                        options={ad_location_array}
                        getOptionLabel={(option) => option.name || option}
                        onChange={(event, item) => {
                          console.log(item, "countyItem");
                          setValue("ad_location", item?item.name:'', { shouldValidate: true });
                        }}
                        // value={value}
                        value={value ? value : null}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            className=""
                            placeholder="Select Ad Location"
                            {...params}
                            InputLabelProps={{
                              sx: {
                                // set the color of the label when not shrinked
                                color: "black",
                                [`&.${inputLabelClasses.shrink}`]: {
                                  // set the color of the label when shrinked (usually when the TextField is focused)
                                  color: "black",
                                },
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  borderColor: "#1ec28b",
                                },
                              },
                              "& .MuiInputLabel-root": { color: "green" }, //styles the label
                              "& .MuiOutlinedInput-root": {
                                "& > fieldset": { borderColor: "#1ec28b" },
                              },
                              "& .MuiOutlinedInput-root:hover": {
                                "& > fieldset": {
                                  borderColor: "#1ec28b",
                                },
                              },
                            }}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   autoComplete: "new-password", // disable autocomplete and autofill
                            // }}
                          />
                        )}
                      />
                    )}
                  />
                </div>
              </div>
              <div style={{ color: "red" }}>
                {errors.ad_location && errors.ad_location.message}
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={12}>
              <label className="ads-Text">
                Ad Location<span style={{ color: "#ff8d8d" }}>*</span>
              </label>
              <Controller
                control={control}
                name="ad_location"
                defaultValue=""
                placeholder="Enter Ad Location"
                render={({ field: { onChange, value } }) => (
                  
                  <Select
                    // required
                    fullWidth
                    margin="dense"
                    id="ad_location"
                    name="ad_location"
                    onChange={(e) => {
                      setValue("ad_location", e.target.value);
                    }}
                    value={value}
                    //defaultValue={()=>{setValue("ad_location",'')} }
                    // placeholder="Select Location"
                    {...register("ad_location")}
                    error={errors.ad_location ? true : false}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1ec28b",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1ec28b",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1ec28b",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#ff8d8d",
                      },
                    }}
                    // displayEmpty
                    // onSelect={(event) => setAnswer(event.target.value)}
                    // renderValue={
                    //   answer !== ""
                    //     ? undefined
                    //     : () => (
                    //         <placeholder
                    //           style={{
                    //             fontStyle: "inherit",
                    //             color: "darkgray",
                    //             display: "block",
                    //             textAlign: "initial",
                    //           }}
                    //         >
                    //           {" "}
                    //           Select Location
                    //         </placeholder>
                    //       )
                    // }

                    // defaultValue={0}
                    // displayEmpty
                    // inputProps={{ "aria-label": "Without label" }}
                  >
                    {/* <MenuItem disabled value="0">
                      <em
                        style={{
                          fontStyle: "inherit",
                          color: "darkgray",
                          display: "block",
                          textAlign:"initial"
                        }}
                      >
                        Select Location
                      </em>
                    </MenuItem> */}
                    {/* <MenuItem value="Banner ad on Home Page">Banner ad on Home Page</MenuItem>
                    <MenuItem value="Footer ad1">Footer ad1</MenuItem>
                    <MenuItem value="Footer ad2">Footer ad2</MenuItem>
                    <MenuItem value="Footer ad3">Footer ad3</MenuItem>
                    <MenuItem value="Student Community ad">Student Community ad</MenuItem>
                    <MenuItem value="Other">Other</MenuItem>
                  </Select>
                )}
              ></Controller>

              <Typography variant="inherit" color="red">
                {errors.ad_location && errors.ad_location.message}
              </Typography>
            </Grid> */} 
 <Grid item xl={6} lg={12} md={6} sm={12} xs={12}>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="ads-Text">
                  Ad Duration<span style={{ color: "#ff8d8d" }}>*</span>
                  </label>
                  <Controller
                    control={control}
                    name="ad_duration"
                    render={({ field: { onChange, value, name } }) => (
                      <Autocomplete
                      popupIcon={
                        <Icon
                          icon="gridicons:dropdown"
                          style={{ color: "#ff8d8d", fontSize: 30 }}
                        />
                      }
                        options={ad_duration_array}
                        getOptionLabel={(option) => option.name || option}
                        onChange={(event, item) => {
                          console.log(item, "countyItem");
                          setValue("ad_duration", item?item.name:'', { shouldValidate: true });
                        }}
                        // value={value}
                        value={value ? value : null}
                        renderInput={(params) => (
                          <TextField
                            variant="outlined"
                            className=""
                            placeholder="Select Ad Duration"
                            {...params}
                            InputLabelProps={{
                              sx: {
                                // set the color of the label when not shrinked
                                color: "black",
                                [`&.${inputLabelClasses.shrink}`]: {
                                  // set the color of the label when shrinked (usually when the TextField is focused)
                                  color: "black",
                                },
                              },
                            }}
                            sx={{
                              "& .MuiOutlinedInput-root.Mui-focused": {
                                "& > fieldset": {
                                  borderColor: "#1ec28b",
                                },
                              },
                              "& .MuiInputLabel-root": { color: "green" }, //styles the label
                              "& .MuiOutlinedInput-root": {
                                "& > fieldset": { borderColor: "#1ec28b" },
                              },
                              "& .MuiOutlinedInput-root:hover": {
                                "& > fieldset": {
                                  borderColor: "#1ec28b",
                                },
                              },
                            }}
                            // inputProps={{
                            //   ...params.inputProps,
                            //   autoComplete: "new-password", // disable autocomplete and autofill
                            // }}
                          />
                        )}
                      />
                    )}
                  />
                </div>
              </div>
              <div style={{ color: "red" }}>
                {errors.ad_location && errors.ad_location.message}
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={12}>
              <label className="ads-Text">
                Ad Duration<span style={{ color: "#ff8d8d" }}>*</span>
              </label>
              <Controller
                control={control}
                name="ad_duration"
                defaultValue=""
                placeholder="Enter Ad Duration"
                render={({ field: { onChange, value } }) => (
                  <Select
                    // required
                    id="ad_duration"
                    name="ad_duration"
                    value={value}
                    // label="Select"
                    placeholder="Select"
                    fullWidth
                    margin="dense"
                    {...register("ad_duration")}
                    error={errors.ad_duration ? true : false}
                    sx={{
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1ec28b",
                      },
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1ec28b",
                      },
                      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#1ec28b",
                      },
                      "& .MuiSvgIcon-root": {
                        color: "#ff8d8d",
                      },
                    }}
                    // displayEmpty
                    // onChange={(event) => setAnswer(event.target.value)}
                    // renderValue={
                    //   answer !== ""
                    //     ? undefined
                    //     : () => (
                    //         <placeholder
                    //           style={{
                    //             fontStyle: "inherit",
                    //             color: "darkgray",
                    //             display: "block",
                    //             textAlign: "initial",
                    //           }}
                    //         >
                    //           {" "}
                    //           Select{" "}
                    //         </placeholder>
                    //       )
                    // }
                    // defaultValue={0}
                    // displayEmpty
                    // inputProps={{ "aria-label": "Without label" }}
                  >
                    {/* <MenuItem disabled value="0">
                      <em
                        style={{
                          fontStyle: "inherit",
                          color: "darkgray",
                          display: "block",
                          textAlign:"initial"
                        }}
                      >
                        Select 
                      </em>
                    </MenuItem> */}
                    {/* <MenuItem value="1 month (Premium)">1 month (Premium)</MenuItem>
                    <MenuItem value="3 months">3 months</MenuItem>
                    <MenuItem value="6 months">6 months</MenuItem>
                    <MenuItem value="1 Year (discounted)">1 Year (discounted)</MenuItem>
                    {/* <MenuItem value="5">5 min</MenuItem>
                    <MenuItem value="6">6 min</MenuItem> */}
                  {/* </Select> */} 
                {/* )}
              ></Controller>
              <Typography variant="inherit" color="red">
                {errors.ad_duration && errors.ad_duration.message}
              </Typography>
            </Grid> */}

            <Grid item xs={12} sm={12}>
              <label className="ads-Text">
                Ad Price I can offer (in USD)
                <span style={{ color: "#ff8d8d" }}>*</span>
              </label>
              <TextField
                required
                id="ad_price"
                name="ad_price"
                // label="Enter Price in USD"
                placeholder="Enter Price in USD"
                fullWidth
                margin="dense"
                {...register("ad_price")}
                error={errors.ad_price ? true : false}
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: "#1ec28b",
                    },
                  },
                  "& .MuiInputLabel-root": { color: "green" }, //styles the label
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { borderColor: "#1ec28b" },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: "#1ec28b",
                    },
                  },
                }}
              />
              <Typography variant="inherit" color="red">
                {errors.ad_price && errors.ad_price.message}
              </Typography>
            </Grid>
          </Grid>
        </Box>

        <Grid container spacing={2} className="ads-Capthch-mainPage">
          <Grid item xs={11} className="ads-Captcha">
            <Typography
              color={errors.acceptTerms ? "error" : "inherit"}
              className="ads-CaptchaLabel"
            >
              Captcha
            </Typography>

            <FormControlLabel
              control={
                <Controller
                  control={control}
                  name="acceptTerms"
                  defaultValue="false"
                  inputRef={register()}
                  render={({ field: { onChange } }) => (
                    // <Checkbox
                    //   color="primary"
                    //   onChange={(e) => onChange(e.target.checked)}
                    // />
                    <Captcha
                      onChange={(e) => onChange(e)}
                      placeholder="Enter Captcha here"
                      length={6}
                    />
                  )}
                />
              }
            />
            <br />
          </Grid>
        </Grid>
        <Typography variant="inherit" color="red">
          {errors.acceptTerms ? errors.acceptTerms.message : ""}
        </Typography>
      </Fragment>
    </Container>
  );
}

export default AdsForm;