import {
  Box,
  Button,
  Card,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
} from "@mui/material";
import "../AdminDashboard/CSS/userFilter.css";
import "../AdminDashboard/CSS/trippopup.css";
import React, { useState } from "react";
import PriceChangeIcon from "@mui/icons-material/PriceChange";
import ModeOfTravelRoundedIcon from "@mui/icons-material/ModeOfTravelRounded";
import LocationOnRoundedIcon from "@mui/icons-material/LocationOnRounded";
import LanguageRoundedIcon from "@mui/icons-material/LanguageRounded";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import PlaceIcon from "@mui/icons-material/Place";
import { Icon } from "@iconify/react";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import axios from "axios";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

function ConnectPop({
  onChildClick,
  connectUserData,
  page,
  connectinvite,
  userdetails,
  item,
}) {
  console.log(connectUserData, "connectUserData");
  console.log(userdetails, "im the user ");

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  // console.log("user_id", user.user_id);

  const [deleteopen, setdeleteOpen] = React.useState(false);

  const handleClickOpen = () => {
    setdeleteOpen(true);
  };

  const handledeleteClose = () => {
    setdeleteOpen(false);
  };
  const [receiveddeleteAgree, setreceivedDeleteAgree] = useState(false);
  const [receiveddeleteId, setreceivedDeleteId] = useState("");

  const [invitebtn, setinvitebtn] = useState([]);

  const acceptinvitationbutton = () => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .get(`${domain}/admin/overall/user/details/`, {
        from_user_id: user.user_id,
        to_user_id: userdetails.user_details.id,
        to_trip_id: userdetails.id,
      })
      .then((response) => {
        console.log(response, "invite btn trip response");

        if (response.data) {
          console.log(response.data, "invite btn trip response data");
          setinvitebtn(response.data);
          Swal.fire({
            customClass: {
              popup: "popup-class",
            },
            icon: "success",
            iconColor: "#1ec28b",
            title: "successfully invited",
            showCloseButton: true,
            showConfirmButton: false,
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          console.log("data : ", error.response.data.message);
          Swal.fire({
            confirmButtonColor: "#ff8d8d",
            text: error.response.data.message,
            type: "error",
          });
        }
      });
  };

  return (
    <div className="connect-pop">
      <Container style={{ height: "100%" }}>
        <Container>
          <div className="desktop-view-transit-part">
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <img
                  src="/Assets/Images/Image_1.webp "
                  className="connectppl-popup-curvedDashedImage"
                ></img>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ textAlign: "center", marginTop: 50,lineBreak: "anywhere" }}
            >
              <Grid item xl={2} lg={2} md={2} xs={2} sm={2}>
                <Icon
                  icon="material-symbols:location-on"
                  className="connectppl-popup-from-location-icon"
                />
                <p className="connectppl-popup-from-text"> flying from</p>
                <p className="connectppl-popup-from-sub-text">
                  {userdetails.flying_from.split(",")[0]}
                </p>
              </Grid>
              {userdetails.transit1 && userdetails.transit2 ? (
                <Grid item xl={2} lg={2} md={2} xs={2} sm={2}>
                  <div className="">Transit 1</div>
                  <span>{userdetails.transit1.split(",")[0]}</span>
                </Grid>
              ) : (
                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  xs={2}
                  sm={2}
                  className=""
                  style={{ visibility: "hidden" }}
                >
                  <div className="">Transit 1</div>
                  <div className="=">Transit 1</div>
                </Grid>
              )}
              {(userdetails.transit2 && userdetails.transti3) ||
             userdetails.transit1 ? (
                <Grid item lg={2} className="">
                  <div className="">
                    {userdetails.transit2 ? "Transit 2" : "Transit 1"}
                  </div>
                  <div className="">
                    {userdetails.transit2
                      ? userdetails.transit2
                      : userdetails.transit1}
                  </div>
                </Grid>
              ) : (
                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  xs={2}
                  sm={2}
                  className=""
                  style={{ visibility: "hidden" }}
                >
                  <div className="">Transit 2</div>
                  <div className="">Transit 2</div>
                </Grid>
              )}
              {userdetails.transit3 &&userdetails.transit2 ? (
                <Grid item lg={2} className="">
                  <div className="">
                    {userdetails.transit2 &&userdetails.transit1
                      ? "Transit 3"
                      :userdetails.transit2
                      ? "Transit 2"
                      : "Transit 1"}
                  </div>
                  <div className="">
                    {userdetails.transit2 && userdetails.transit1
                      ? userdetails.transit3
                      : userdetails.transit2
                      ? userdetails.transit2
                      : null}
                  </div>
                </Grid>
              ) : (
                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  xs={2}
                  sm={2}
                  className=""
                  style={{ visibility: "hidden" }}
                >
                  <div className="">Transit 3</div>
                  <div className="">Transit 3</div>
                </Grid>
              )}
              {userdetails.transit4 ? (
                <Grid item lg={2} className="">
                  <div className="">Transit 4</div>
                  <div className="">{userdetails.transit4}</div>
                </Grid>
              ) : (
                <Grid
                  item
                  xl={2}
                  lg={2}
                  md={2}
                  xs={2}
                  sm={2}
                  className=""
                  style={{ visibility: "hidden" }}
                >
                  <div className="">Transit 4</div>
                  <div className="">Transit 4</div>
                </Grid>
              )}
              <Grid item xl={2} lg={2} md={2} xs={2} sm={2}>
                <Icon
                  icon="material-symbols:location-on"
                  className="connectppl-popup-dest-location-icon"
                />
                <p className="connectppl-popup-dest-text"> destination</p>
                <p className="connectppl-popup-dest-sub-text">
                  {userdetails.destination.split(",")[0]}
                </p>
              </Grid>
            </Grid>

            <Grid container spacing={2} className="airlineIndigoContainer">
              {userdetails.airline ? (
                <Grid item lg={2}>
                  <Grid className="airlineText1">Airline</Grid>
                  <div style={{ marginLeft: "-47px" }}>
                    <img
                      src="/Assets/Images/Image_left_dotted_arrow.webp"
                      className=""
                      style={{ width: "44px" }}
                    ></img>
                  </div>
                  <Box className="usertrip-indigoBox1">
                    <div className="indigoText">{userdetails.airline}</div>
                  </Box>
                  <div style={{ marginLeft: "80px", marginTop: "-60px" }}>
                    <img
                      src="/Assets/Images/Image_right_dotted_arrow.webp"
                      className=""
                      style={{ width: "44px" }}
                    ></img>
                  </div>
                </Grid>
              ) : null}
              {userdetails.airline1 ? (
                <Grid item lg={2} className="stopOneContainer">
                  <Grid className="airlineText2">Airline</Grid>
                  <div style={{ marginLeft: "-28px" }}>
                    <img
                      src="/Assets/Images/Image_left_dotted_arrow.webp"
                      className=""
                      style={{ width: "44px" }}
                    ></img>
                  </div>
                  <Box className="usertrip-indigoBox2">
                    <div className="indigoText">{userdetails.airline1}</div>
                  </Box>
                  <div style={{ marginLeft: "93px", marginTop: "-63px" }}>
                    <img
                      src="/Assets/Images/Image_right_dotted_arrow.webp"
                      className=""
                      style={{ width: "44px" }}
                    ></img>
                  </div>
                </Grid>
              ) : null}
              {userdetails.airline2 ? (
                <Grid item lg={2} className="stopOneContainer">
                  <Grid className="airlineText3">Airline</Grid>
                  <div style={{ marginLeft: "-8px" }}>
                    <img
                      src="/Assets/Images/Image_left_dotted_arrow.webp"
                      className=""
                      style={{ width: "44px" }}
                    ></img>
                  </div>
                  <Box className="usertrip-indigoBox3">
                    <div className="indigoText">{userdetails.airline2}</div>
                  </Box>
                  <div style={{ marginLeft: "104px", marginTop: "-60px" }}>
                    <img
                      src="/Assets/Images/Image_right_dotted_arrow.webp"
                      className=""
                      style={{ width: "44px" }}
                    ></img>
                  </div>
                </Grid>
              ) : null}
              {userdetails.airline3 ? (
                <Grid item lg={2} className="stopOneContainer">
                  <Grid className="airlineText4">Airline</Grid>
                  <div style={{ marginLeft: "10px" }}>
                    <img
                      src="/Assets/Images/Image_left_dotted_arrow.webp"
                      className=""
                      style={{ width: "44px" }}
                    ></img>
                  </div>
                  <Box className="usertrip-indigoBox4">
                    <div className="indigoText">{userdetails.airline3}</div>
                  </Box>
                  <div style={{ marginLeft: "134px", marginTop: "-60px" }}>
                    <img
                      src="/Assets/Images/Image_right_dotted_arrow.webp"
                      className=""
                      style={{ width: "44px" }}
                    ></img>
                  </div>
                </Grid>
              ) : null}
              {userdetails.airline4 ? (
                <Grid item lg={2} className="stopOneContainer">
                  <Grid className="airlineText5">Airline</Grid>
                  <div style={{ marginLeft: "25px" }}>
                    <img
                      src="/Assets/Images/Image_left_dotted_arrow.webp"
                      className=""
                      style={{ width: "44px" }}
                    ></img>
                  </div>
                  <Box className="usertrip-indigoBox5">
                    <div className="indigoText">{userdetails.airline4}</div>
                  </Box>
                  <div style={{ marginLeft: "151px", marginTop: "-60px" }}>
                    <img
                      src="/Assets/Images/Image_right_dotted_arrow.webp"
                      className=""
                      style={{ width: "44px" }}
                    ></img>
                  </div>
                </Grid>
              ) : null}
            </Grid>
          </div>
          {/* Mobile View */}
          <div className="mobile-view-transit-part">
            <Grid container spacing={2}>
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <img
                  src="/Assets/Images/Image_1.webp "
                  className="connectppl-popup-curvedDashedImage"
                ></img>
              </Grid>
            </Grid>

            <Grid
              container
              spacing={2}
              style={{ textAlign: "center", marginTop: 50 }}
            >
              <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                <Icon
                  icon="material-symbols:location-on"
                  className="connectppl-popup-from-location-icon"
                />
                <p className="connectppl-popup-from-text"> flying from</p>
                <p className="connectppl-popup-from-sub-text">
                  {userdetails.flying_from.split(",")[0]}
                </p>
              </Grid>

              <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                <Icon
                  icon="material-symbols:location-on"
                  className="connectppl-popup-dest-location-icon"
                />
                <p className="connectppl-popup-dest-text"> destination</p>
                <p className="connectppl-popup-dest-sub-text">
                  {userdetails.destination.split(",")[0]}
                </p>
              </Grid>
            </Grid>

            <Container>
              <div className="connect-ppl-airlines-hr-line"></div>
            </Container>
            <Card className="connect-ppl-pop-up-travel-details-cardmobile">
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <p className="connect-ppl-pop-up-travel-details">
                    Flying from &nbsp;: &nbsp;
                    {userdetails.flying_from}
                  </p>
                </Grid>
                {userdetails.airline ? (
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <span className="connect-ppl-pop-up-travel-details">
                      Airline
                    </span>
                    &nbsp;: &nbsp;
                    <span className="connect-ppl-pop-up-travel-details">
                      {userdetails.airline}
                    </span>
                  </Grid>
                ) : null}
              </Grid>
            </Card>
            <div style={{ textAlign: "center" }}>
              <img
                src="/Assets/Images/Dotted-icon.webp"
                className="connect-ppl-dotted-image"
              ></img>
            </div>
            {userdetails.transit1 && (
              <Grid>
                <Card className="connect-ppl-pop-up-travel-details-cardmobile">
                  <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <p className="connect-ppl-pop-up-travel-details">
                        Transit 1 &nbsp;: &nbsp;
                        {userdetails.transit1}
                      </p>
                    </Grid>
                    {userdetails.airline1 ? (
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className=""
                      >
                        <span className="connect-ppl-pop-up-travel-details">
                          Airline
                        </span>
                        &nbsp;: &nbsp;
                        <span className="connect-ppl-pop-up-travel-details">
                          {userdetails.airline1}
                        </span>
                      </Grid>
                    ) : null}
                  </Grid>
                </Card>
                <div style={{ textAlign: "center" }}>
                  <img
                    src="/Assets/Images/Dotted-icon.webp"
                    className="connect-ppl-dotted-image"
                  ></img>
                </div>
              </Grid>
            )}

            {userdetails.transit2 && (
              <Grid>
                <Card className="connect-ppl-pop-up-travel-details-cardmobile">
                  <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <p className="connect-ppl-pop-up-travel-details">
                        Transit 2 &nbsp;: &nbsp;
                        {userdetails.transit2}
                      </p>
                    </Grid>
                    {userdetails.airline2 ? (
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className=""
                      >
                        <span className="connect-ppl-pop-up-travel-details">
                          Airline
                        </span>
                        &nbsp;: &nbsp;
                        <span className="connect-ppl-pop-up-travel-details">
                          {userdetails.airline2}
                        </span>
                      </Grid>
                    ) : null}
                  </Grid>
                </Card>
                <div style={{ textAlign: "center" }}>
                  <img
                    src="/Assets/Images/Dotted-icon.webp"
                    className="connect-ppl-dotted-image"
                  ></img>
                </div>
              </Grid>
            )}

            {userdetails.transit3 && (
              <Grid>
                <Card className="connect-ppl-pop-up-travel-details-cardmobile">
                  <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <p className="connect-ppl-pop-up-travel-details">
                        Transit 3 &nbsp;: &nbsp;
                        {userdetails.transit3}
                      </p>
                    </Grid>
                    {userdetails.airline3 ? (
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className=""
                      >
                        <span className="connect-ppl-pop-up-travel-details">
                          Airline
                        </span>
                        &nbsp;: &nbsp;
                        <span className="connect-ppl-pop-up-travel-details">
                          {userdetails.airline3}
                        </span>
                      </Grid>
                    ) : null}
                  </Grid>
                </Card>
                <div style={{ textAlign: "center" }}>
                  <img
                    src="/Assets/Images/Dotted-icon.webp"
                    className="connect-ppl-dotted-image"
                  ></img>
                </div>
              </Grid>
            )}

            {userdetails.transit4 && (
              <Grid>
                <Card className="connect-ppl-pop-up-travel-details-cardmobile">
                  <Grid container spacing={2}>
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <p className="connect-ppl-pop-up-travel-details">
                        Transit 4 &nbsp;: &nbsp;
                        {userdetails.transit4}
                      </p>
                    </Grid>
                    {userdetails.airline4 ? (
                      <Grid
                        item
                        xl={12}
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        className=""
                      >
                        <span className="connect-ppl-pop-up-travel-details">
                          Airline
                        </span>
                        &nbsp;: &nbsp;
                        <span className="connect-ppl-pop-up-travel-details">
                          {userdetails.airline4}
                        </span>
                      </Grid>
                    ) : null}
                  </Grid>
                </Card>
                <div style={{ textAlign: "center" }}>
                  <img
                    src="/Assets/Images/Dotted-icon.webp"
                    className="connect-ppl-dotted-image"
                  ></img>
                </div>
              </Grid>
            )}





            <Card className="connect-ppl-pop-up-travel-details-cardmobile">
              <Grid container spacing={2}>
                <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                  <p className="connect-ppl-pop-up-travel-details">
                    Destination &nbsp;: &nbsp;
                    {userdetails.destination}
                  </p>
                </Grid>
              </Grid>
            </Card>
          </div>

          <Grid
            container
            spacing={2}
            className="connectppl-popup-date-tip-main"
          >
            {userdetails.booking_status == 2 ? (
              <div className="connectppl-popup-date">
                <Icon
                  icon="clarity:date-outline-badged"
                  className="connectppl-popup-date-icon"
                />

                <div className="connectppl-popup-date-expected-text-sub-range">
                  {userdetails.departing_on
                    ? "Departing Date"
                    : "Date Range"}
                </div>
                <div className="connectppl-popup-date-expected-text-sub">
                  {userdetails.departing_on
                    ? userdetails.departing_on
                    : `${userdetails.date_range_from} to ${userdetails.date_range_to}`}
                </div>
              </div>
            ) : (
              <div className="connectppl-popup-date">
                <Icon
                  icon="clarity:date-outline-badged"
                  className="connectppl-popup-date-icon"
                />

                <div className="connectppl-popup-date-expected-text-sub-range">
                  {userdetails.departing_on
                    ? "Departing Date"
                    : "Date Range"}
                </div>
                <div className="connectppl-popup-date-expected-text-sub">
                  {userdetails.departing_on
                    ? userdetails.departing_on
                    : `${userdetails.date_range_from} to ${userdetails.date_range_to}`}
                </div>
              </div>
            )}
            {userdetails.category == 1 && (
              <div className="connectppl-popup-tip-verticleLine-main">
                <div className="connectppl-popup-tip-verticleLine"></div>
              </div>
            )}
            {userdetails.category == 1 && (
              <div className="connectppl-popup-tip-expected">
                <Icon
                  icon="material-symbols:price-change-rounded"
                  className="connectppl-popup-price-icon"
                />
                <b>
                  {" "}
                  <p className="connectppl-popup-tip-expected-text">
                    Tip Expected
                  </p>
                  <p className="connectppl-popup-tip-expected-text-sub">
                    {/^([^0-9]*)$/.test(userdetails.tip_expected)
                      ? userdetails.tip_expected
                      : `${userdetails.tip_expected} USD equiv`}
                  </p>
                </b>
              </div>
            )}
          </Grid>

          <Grid container spacing={2} style={{ textAlign: "center" }}>
            <Grid
              item
              xl={12}
              lg={12}
              md={12}
              xs={12}
              sm={12}
              className="utips-connectppl-popup-details-text"
            >
              <p>{userdetails.short_description}</p>
            </Grid>
          </Grid>

          <Grid
            container
            spacing={2}
            className="utrips-connectppl-popup-trip-details-main"
          >
            <Grid item xl={3} lg={3} md={3} xs={6} sm={6}>
              <ModeOfTravelRoundedIcon className="connectppl-popup-trip-details-icon" />
              <div className="connectppl-popup-trip-details">Trip Name</div>
              <div className="utrips-connectppl-popup-trip-details-sub" style={{lineBreak: "anywhere"}}>
                {userdetails.trip_name}
              </div>
            </Grid>
            <Grid item xl={3} lg={3} md={3} xs={6} sm={6}>
              <PlaceIcon className="connectppl-popup-trip-details-icon" />
              <div className="connectppl-popup-trip-details">
                Meetup Location
              </div>
              <div className="utrips-connectppl-popup-trip-details-sub" style={{lineBreak: "anywhere"}}>
                {userdetails.meetup_location}
              </div>
            </Grid>

            <Grid item xl={3} lg={3} md={3} xs={6} sm={6}>
              <LanguageRoundedIcon className="connectppl-popup-trip-details-icon" />
              <div className="connectppl-popup-trip-details">
                Prefered Language
              </div>
              <div className="utrips-connectppl-popup-trip-details-sub" style={{lineBreak: "anywhere"}}>
                {userdetails.prefered_language}
              </div>
            </Grid>

            {userdetails.category == 1 ? (
              <Grid item xl={3} lg={3} md={3} xs={6} sm={6}>
                <Icon
                  icon="fa-solid:hands-helping"
                  className="connectppl-popup-trip-details-icon"
                />
                <div className="connectppl-popup-trip-details">
                  Can Assist with
                </div>
                <div className="utrips-connectppl-popup-trip-details-sub" style={{lineBreak: "anywhere"}}>
                  {userdetails.can_assist_with}
                </div>
              </Grid>
            ) : (
              <Grid item xl={3} lg={3} md={3} xs={6} sm={6}>
                <Icon
                  icon="fa-solid:hands-helping"
                  className="connectppl-popup-trip-details-icon"
                />
                <div className="connectppl-popup-trip-details">
                  Assistance Needed
                </div>
                <div className="utrips-connectppl-popup-trip-details-sub" style={{lineBreak: "anywhere"}}>
                  {userdetails.assistance_needed}
                </div>
              </Grid>
            )}
          </Grid>
        </Container>

        {page == "connectpeople" ? (
          <div className="connectppl-popup-trip-invite-main">
            <button
              className="connectppl-popup-trip-invite-btn"
              onClick={() => acceptinvitationbutton()}
            >
              Invite to be your TC
            </button>
          </div>
        ) : null}
      </Container>
    </div>
  );
}

export default ConnectPop;
