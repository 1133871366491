import axios from "axios";

const login = (username, password) => {
  console.log("login called")


  console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
  let domain = "";
  console.log(process.env, "domain env", window.location.hostname);
  if (window.location.hostname.trim() === "localhost") {
    domain = process.env.REACT_APP_LOCAL_API;
  } else if (window.location.hostname.trim() === "54.185.6.32") {
    domain = process.env.REACT_APP_UAT_API;
  } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_UAT_API;
  }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_API;
  }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_API;
  }
  console.log(domain, "domain env end");




  return axios
    .post(`${domain}/admin/login/`, {
      email:username,
      password:password
    })
    .then((response) => {
      console.log(response,"login response")
      if (response.data) {
        localStorage.setItem("admin", JSON.stringify(response.data));
      }
      return response.data;
    }) 
  //   .catch((error) => {
  //     // Error
  //     if (error.response.status===400) {
  //         // The request was made and the server responded with a status code
  //         // that falls out of the range of 2xx
  //         // console.log(error.response.data);
  //         // console.log(error.response.status);
  //         // console.log(error.response.headers);
  //         // setRegisterResponseMsg("");
  //         // setRegisterErrorResponse(error.response.data.message)
  //         // alert(error.response.data.message)
  //     }
  // });
};
const logout = () => {

  console.log("login called")


  console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
  let domain = "";
  console.log(process.env, "domain env", window.location.hostname);
  if (window.location.hostname.trim() === "localhost") {
    domain = process.env.REACT_APP_LOCAL_API;
  } else if (window.location.hostname.trim() === "54.185.6.32") {
    domain = process.env.REACT_APP_UAT_API;
  } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_UAT_API;
  }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_API;
  }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_API;
  }
  console.log(domain, "domain env end");
  
  return axios
    .get(`${domain}/admin/logout/`)
    .then((response) => {
      console.log(response,"log out")
      if (response.data) {
        // localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.removeItem("admin")
      }
      // return response.data;
    }) 


  // localStorage.removeItem("user");
};
export default {
  login,
  logout,
};