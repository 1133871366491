import React, { useEffect, useState } from "react";
// import "../../CSS/createTripCSS/fullDetails.css";
import moment from "moment";
import {
  Box,
  Button,
  Card,
  Container,
  Grid,
  // Icon,
  IconButton,
  MenuItem,
  Radio,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ShareOutlinedIcon from "@mui/icons-material/ShareOutlined";
import FlightTakeoffOutlinedIcon from "@mui/icons-material/FlightTakeoffOutlined";
import Swal from "sweetalert2";
import FacebookIcon from "@mui/icons-material/Facebook";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import { Link, useNavigate } from "react-router-dom";
import { borderRadius } from "@mui/system";
import Tc from "./Tc.PNG";
import { Grid3x3, Language } from "@mui/icons-material";
import "./CreateTripNew.css";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import FulldetailBody from "./Assets/FullDetail/FulldetailBody";
import StepCircle from "./Assets/Stepper/StepCircle";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Icon } from "@iconify/react";
import { RemoveExtraField,FormattingField,Formatting_Fulldetail} from "./RemoveExtraField";
import isMobile from "./Assets/Ismobile"
import "./FullDetail.css"
import FirstCon from "./FullDetails/FirstCon";
import SecondCon from "./FullDetails/SecondCon";
import { FacebookShareButton, InstapaperShareButton, TwitterShareButton } from "react-share";



function FullDetails(props) {
  const { values, activeStep, setActiveStep, page } = props;
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [open, setOpen] = React.useState(false);
  const [deleteopen, setdeleteOpen] = React.useState(false);

  const [fullDetail, setFulldetail] = useState({});
  console.log(values, "fullDetail...");

  const navigate = useNavigate();

  const DeleteHandler = () => {

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .delete(
        `${domain}/create-trip/${
          values.trip_id ? values.trip_id : values.id
        }/`
      )
      .then((response) => {
        setOpen(false);
        navigate("/create-a-trip");
        window.location.reload();
      })
      .catch((error) => {
        // Error
        if (error.response.status === 400) {
          console.log(error.response.data, "error");
          console.log(error.response.status);
          // console.log(error.response.headers);
          // setResponseMessage(null);
        }
      });
  };

  const btnEl = document.querySelector(".btn");
  const toggleOptions = () => {
    const wrapperEl = document.querySelector(".wrapper");
    const iconEl = btnEl.querySelector("i");
    wrapperEl.classList.toggle("active");
    if (iconEl.classList.contains("ri-share-line")) {
      iconEl.classList.replace("ri-share-line", "ri-close-line");
    } else {
      iconEl.classList.replace("ri-close-line", "ri-share-line");
    }
  };
  // btnEl.addEventListener('click',toggleOptions);

  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    // 👇️ toggle shown state
    setIsShown((current) => !current);
  };
  const handledeleteClose = () => {
    setdeleteOpen(false);
  };

  console.log(fullDetail, "fullDetail*");
  console.log(fullDetail["tip_expected"], "tipExpectedCheckiubf");

  useEffect(() => {
    if(isMobile()){
      window.scrollTo(0, 900);
      console.log("Mobile....")
     }else{
      window.scrollTo(0, 1300);
      console.log("Desktop....")
  
     }

    const removed_infull=RemoveExtraField(values,"4")
    const formatted_full=FormattingField(removed_infull,"4")
    const Final_data=Formatting_Fulldetail(formatted_full)
    setFulldetail(Final_data)
    console.log(Final_data,"removed_infull")
  }, [])

  const firstCon = [
    { label: "Name", key: "name" },
    { label: "Age Group", key: "age_group" },
    { label: "Gender", key: "gender" },
    { label: "Tip I Expected", key: "tip_expected" },
  ];

  let secondCon = [
    { label: "Booking Status", key: "booking_status" },
    { label: "Date Range From", key: "date_range_from" },
    { label: "Date Range to", key: "date_range_to" },
    { label: "Travel date", key: "departing_on" },
    { label: "Flying from", key: "flying_from" },
    { label: "Airline", key: "airline" },
    { label: "transit", key: "airAndTrans" },
    { label: "Destination", key: "destination" },
    { label: "Prefered language", key: "prefered_language" },
    { label: "Trip Name", key: "trip_name" },
    { label: "Assistance Needed", key: "assistance_needed" },
    { label: "Description", key: "short_description" },
    { label: "Meetup Location", key: "meetup_location" },
    { label: "Can Assist with", key: "can_assist_with" },
  ];

  if (fullDetail["airAndTrans"]) {
    const newList = [];
    secondCon.map((field) => {
      if (field.label === "Flying from" || field.label === "Airline") {
      } else {
        newList.push(field);
      }
    });
    // console.log(newList,"newList")
    secondCon = newList;
  }

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div className="_border_s4_con">
    <Box className="_border_s4 rsponsive">
    <Grid><Typography sx={{textAlign:"initial"}} className="s4_heading">Your Trip has been created as below</Typography></Grid>
    <Grid className="Share">
   <Grid className="btn_con">
    <Button
       // LinkComponent={Link}
       // to={`/create-a-trip-edit/${values.trip_id?values.trip_id:values.id}`}
         className="fullDetailEdit"
         endIcon={
           // <Link to="/travellerDetails">
           <EditIcon fontSize="initial" style={{ fill: "white" }} />
           // </Link>
         }
         onClick={()=>{
           if(page!=="createTrip")
              window.location.reload()
           else
              navigate(`/create-a-trip-edit/${values.trip_id?values.trip_id:values.id}`)   
         }}
       >
         Edit
     </Button>
     {/* <TwitterShareButton
      url={'www.DesiTravelCompanion.com'}
      // quote={'Dummy text! jhdsjhdfgjfgdjfg'}
      // hashtag="#muo"
      title="Hello, this is regarding your Travel Companion post with www.DesiTravelCompanion.com, flying from hyd to banglore "
      // body={"i am calling"}
      >
      <TwitterIcon size={32} round />
      </TwitterShareButton> */}
      {/* <InstapaperShareButton
      url={'https://www.example.com'}
      // quote={'Dummy text! jhdsjhdfgjfgdjfg'}
      // hashtag="#muo"
      // body={"i am calling"}
      title="Check out this iHateReading custom repository "

      >
      <InstagramIcon size={32} round />
      </InstapaperShareButton> */}
      {/* <FacebookShareButton
      url={'www.DesiTravelCompanion.com'}
//         quote={'Dummy text!'}
//         hashtag="#muo"
title="Hello, this is regarding your Travel Companion post with www.DesiTravelCompanion.com, flying from hyd to banglore "
>
<FacebookIcon size={32} round />
</FacebookShareButton> */}
   </Grid>
   {/* <Grid className="share_con">
     <Grid className="icon_con">
   
                 <FacebookIcon style={{ fill: "#1344be",marginRight:"1rem" }} />
                 <InstagramIcon style={{ fill: "#d001e3",marginRight:"1rem" }}/>
                 <TwitterIcon style={{ fill: "#1b9ceb",marginRight:"1rem" }}/>
                 {/* <LinkedInIcon style={{ fill: "#0e5c9f",marginRight:"1rem" }}/> 
              
     </Grid>
   </Grid> */}
 </Grid>
    
    
    <Grid className="flight_dir">
   <Grid style={{width:"100%"}}>
     <img src={Tc} style={{height:"100%",width:"100%"}}></img>
   </Grid>

 {Object.entries(fullDetail).length>0?<Grid className="country_con">
     <Grid>
       <Typography>{fullDetail.flying_from?fullDetail.flying_from:fullDetail["airAndTrans"][0]["flying_from"]}</Typography>
       <Typography style={{color:"#1ec28b"}}>Flying from</Typography>
     </Grid>
     <Grid>
     <Grid>
       <Typography>{fullDetail.destination}</Typography>
       <Typography style={{color:"#1ec28b"}}>Destination</Typography>
     </Grid>
     </Grid>
 </Grid>:null}
 </Grid>
 
 {Object.entries(fullDetail).length>0?<Container className="main_con_s4">   {/*light green */}


 <Grid style={{marginBottom:"0.5rem"}}>
   <FirstCon fullDetail={fullDetail} keyArray={firstCon}/>
  </Grid>
  <Grid >
   <SecondCon fullDetail={fullDetail} keyArray={secondCon}/>
  </Grid>

  
 </Container>:null}
 
            
 
     </Box>
        <Grid className="backTohome" style={{cursor: "pointer"}} onClick={()=>{
            navigate("/dashboard")
        }}> 
        <ArrowBackIcon style={{color:"#1ec28b"}}/> Back to Dashboard</Grid>
 
 </div>
  );
}

export default FullDetails;
