import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import "../../CSS/pastTripPopup.css";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
// import Captcha from "demos-react-captcha";

import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  inputLabelClasses,
  MenuItem,
  Radio,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import { Controller, useForm } from "react-hook-form";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";

import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import axios from "axios";
import { useSelector } from "react-redux";

function PastTripPopinfo(props) {
  console.log("1234567", props.createTripid);
  //   const { isLoggedIn, user } = useSelector((state) => state.auth);
  //   console.log("user_id", user.user_id);
  // console.log("user_id", user.id);
  const [pastTrippopInfo, setpastTrippopInfo] = useState([]);
  //   useEffect(() => {
  //     axios
  //       .get(
  //         `http://ec2-54-185-6-32.us-west-2.compute.amazonaws.com:81/past-trips/${props.createTripid}/`
  //       )
  //       .then((response) => {
  //         console.log(response, "pastpopInfo trip response");

  //         if (response.data) {
  //           console.log(response.data, "pastpopInfo trip response data");
  //         //   console.log(response.data.message, "1234popInfo");
  //           setpastTrippopInfo(response.data);
  //           // setpastTrip(response.data).message;
  //         }
  //       })
  //       .catch((error) => {
  //         // Error
  //         // console.log("status : ",error.response.status);
  //         // console.log("data : ",error.response.data);
  //         if (error.response.status === 400) {
  //           // The request was made and the server responded with a status code
  //           // that falls out of the range of 2xx
  //           // console.log("data : ",error.response.data);
  //           console.log("data : ", error.response.data.message);
  //           // console.log("status : ",error.response.status);
  //           // console.log("headers : ",error.response.headers);
  //         }
  //       });
  //   }, []);
  //   const [closeIcon, setCloseicon] = useState(false);
  //   const open = Boolean(closeIcon);
  //   const id = open ? "simple-popover" : undefined;

  return (
    <div className="pastTripPopupinfo-modalBackground">
      <div className="pastTripPopupinfo-modalContainer">
        <Grid container spacing={2}>
          <Grid
            item
            xl={9}
            lg={9}
            md={9}
            sm={9}
            xs={9}
            className="pastTripPopupinfo-text"
          >
            Trip Memories
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            style={{ textAlign: "end" }}
          >
            <div className="pastTripPopupinfo-titleCloseBtn">
              <button onClick={props.setOpenModalpopInfo}>X</button>
            </div>
          </Grid>
        </Grid>
        <div className="pastTripPopupinfo-hrlines"></div>

        {/* {pastTrippopInfo.map((post, index) => {
          return ( */}
        <Grid container spacing={2}>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="pastTripPopupinfo-imagebox">
              <div className="pastTripPopupinfo-imageboxinner">
                <img
                  className="pastTripPopupinfo-image"
                  src={
                    props.createTripid.trip_photos == null
                      ? "./Assets/Images/Favicon 25 WB _ DV.webp"
                      : props.createTripid.trip_photos
                  }
                  //   src={props.createTripid.trip_photos}
                  alt="selfie"
                />
              </div>
            </div>
          </Grid>
          <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
            <div className="pastTripPopupinfo-description">
              <div className="pastTripPopupinfo-descriptioninner">
               <p>
                {props.createTripid.whats_on_ur_mind}
                </p>
              </div>
            </div>
          </Grid>
        </Grid>
        {/* );
        })} */}
      </div>
    </div>
  );
}

export default PastTripPopinfo;
