import { Container, Grid } from '@mui/material'
import React from 'react'

const SecondCon = (props) => {
    const {fullDetail,keyArray}=props
    return(
       
        <Container>
        
              
              {keyArray.map((field,index)=>{
                return(
                  <Grid>
                    {(fullDetail[`${field.key}`]&&field.key!=="airAndTrans")?
                     <div style={{display:"flex",marginBottom:"1rem"}}>
                     <div style={{marginRight:"3px"}} className="text_style_full"><span>{`${field.label+" "}${""}`}</span></div>
                     <div className="text_style_full"><div className='data-fulldetail'>{`: ${fullDetail[`${field.key}`]}`}</div></div>
                  </div>:
                  <div>
                    {field.key=="airAndTrans"?
                    <div>
                      <AirTransCon data={fullDetail[`${field.key}`]?fullDetail[`${field.key}`]:[]}/>
                    </div>
                   
                    :null}
                  </div>
                  }
                  </Grid>
               )
              })
            }
        </Container>
      )
  
}

export default SecondCon


  const AirTransCon=(props)=>{
    const {data}=props
    console.log(data,"dataInAir")
  
  return  <div>
    {
      data.map((field,index)=>{
        return <div>
    <div className="tansit_con_s4">
        <div className="transit_content_s4">
        <div style={{marginRight:"3px"}} className="text_style_full"><span>{index===0?"Flying From":"Transit"}</span></div>
        <div className="text_style_full"><span>{`:  ${index===0?field[`flying_from`]:field[`transit`]}`}</span></div>
        </div>
  
        <div className="transit_content_s4">
        <div style={{marginRight:"3px"}} className="text_style_full"><span>{"Airline"}</span></div>
        <div className="text_style_full"><span>{`:  ${field[`airline`]}`}</span></div>
        </div>
        <div className="line_s4"></div>
  </div>
  
  
        </div>
        
      })
    }
    </div>
  
  
  }