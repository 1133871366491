import { Icon } from "@iconify/react";
import { Box, Button, Container } from "@mui/material";
import React from "react";
import "../../src/AdminDashboard/CSS/admincompanionselfie.css";
import axios from "axios";
import tcicon from "./Images/Favicon 25 WB _ DV.webp";
import moment from "moment";
function Admincompanionpopup(props) {
  console.log("1234567", props.createAdtrTripid);
  const approvalhandler = (id, value) => {


    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
	


    axios
      .post(
        `${domain}/admin/companion-selfie-approval/${id}/`,
        {
          approval_status: value,
        }
      )

      .then((response) => {
        props.setOpenModalpopinfo(false);
        props.setTrip();
        console.log(response, "Approve-response");
        console.log(response, "Reject-response");
      })
      .catch((error) => {});
  };

  return (
    <div className="admin-companion-modalBackground">
      <div className="admin-companion-modalContainer">
        <Box className="admin-companion-box">
          <span className="admin-wrong">
            <Icon
              icon="basil:cross-solid"
              className="admin-companion-wrong"
              onClick={props.setOpenModalpopinfo}
            />
          </span>
          <Container>
            <div className="admin-companion-img-start1">
              <div>
                <b>{props.createAdtrTripid.name}</b>
              </div>
            </div>
            <div className="admin-heading1">Companion Selfie</div>
            <div className="admin-companion-img">
              <img
                accept="jfif"
                src={
                  props.createAdtrTripid.trip_photos
                    ? props.createAdtrTripid.trip_photos
                    : tcicon
                }
                className="admin-src-image"
              />
            </div>
            <div className="admin-desc">Travel Experience</div>
            <div className="admin-descr">
              {props.createAdtrTripid.whats_on_ur_mind}
            </div>
            <div className="admin-companion-update">
              <div style={{ width: "100%" }}>
                Created on :{" "}
                {moment(props.createAdtrTripid.created_on).format(
                  "DD/MM/yyyy hh:mm:ss"
                )}{" "}
              </div>
              <div style={{ width: "100%" }}>
                Updated on :{" "}
                {moment(props.createAdtrTripid.updated_on).format(
                  "DD/MM/yyyy hh:mm:ss"
                )}{" "}
              </div>
            </div>
            <div className="admin-mainbtn">
              {(props.createAdtrTripid.approval_status === 1 ||
                props.createAdtrTripid.approval_status === 3) && (
                <Button
                  onClick={() => {
                    approvalhandler(props.createAdtrTripid.trip_id, 2);
                  }}
                  className="admin-button"
                >
                  APPROVE
                </Button>
              )}
              {(props.createAdtrTripid.approval_status === 1 ||
                props.createAdtrTripid.approval_status === 2) && (
                <Button
                  onClick={() => {
                    approvalhandler(props.createAdtrTripid.trip_id, 3);
                  }}
                  className="admin-button"
                >
                  REJECT
                </Button>
              )}
            </div>
          </Container>
        </Box>
      </div>
    </div>
  );
}

export default Admincompanionpopup;
