import React, { useEffect } from "react";
import * as Yup from "yup";
import KeyboardBackspaceSharpIcon from "@mui/icons-material/KeyboardBackspaceSharp";
import { Field, Form, Formik } from "formik";
import { Button, Container, Grid, Snackbar } from "@mui/material";
import "../../CSS/forgetPassword.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { Icon } from "@iconify/react";
import ScrollTop from "../../ScrollTop";
function ForgetPasswordAdmin() {

  const [forgotPassowrdRes, setforgotPassowrdRes] = useState("");
  const [forgotPassowrdResErr, setforgotPassowrdResErr] = useState("");
  const [invitopen, setinvitopen] = React.useState(false);

  const handleinvitClose = () => {
    setinvitopen(false);
  };

  // useEffect(() => {
  //   document.title =  "Desi Travel Companion-forgot password";
  // }, []);



  const ContactSchema = Yup.object().shape({
    email_id: Yup.string().email("Invalid email").required("Email is required"),
  });

  const handleForgotLink = (values,{resetForm}) => {
    console.log(values, "forgot");
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");
	

    axios
      .post(
        `${domain}/password-reset-email/`,
        {
          email: values.email_id,
          // email_id: "sandeepjugali@gmail.com",
        }
      )
      .then((response) => {
        if(response.data){
          // alert(response.data.message);}
          setforgotPassowrdRes(response.data.message)
          setforgotPassowrdResErr('')
          setinvitopen(true)
          resetForm();
          }
  
      })
      .catch((err) => {
        if (err.response.data) {
          setforgotPassowrdResErr(err.response.data.message)
          setforgotPassowrdRes('')
          setinvitopen(true)
          // alert(err.response.data.message);
        } 
      });
  };
  // const navigate = useNavigate();
  return (
    <div>
       <ScrollTop>
      <div className="imageDiv">
        <img
          src="./Assets/Images/desi-travel-campanion-logo.png"
          alt="first"
          className="forgotImage"
        />
      </div>
      <div className="forgotContainer">
        <hr className="forgotHr"></hr>
        <Container>
          <div>
            <div className="forgotOuterContainer">
              <Link
                to="/admin/login"
                style={{ textDecoration: "none", color: "black" }}
              >
                <div className="forgot-backIcon">
                <Icon icon="ion:arrow-back-sharp" />
                  <span className="backLoginText">Back to Login</span>
                </div>
              </Link>

              <div className="forgotInnerContainer">
                <p className="forgetPasswordHeading">
                  &nbsp;&nbsp;Forgot Password!!!
                </p>
                <p className="pleaseText">
                  &nbsp;&nbsp;Please enter your registered email to reset the
                  password...
                </p>
                <Formik
                  initialValues={{
                    email_id: "",
                  }}
                  validationSchema={ContactSchema}
                  onSubmit={(values,{resetForm})=>{{handleForgotLink(values,{resetForm})}}}
                >
                  {({ errors, touched, isValid, isSubmitting,resetForm }) => (
                    <Form>
                      <Grid container spacing={2}>
                        <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                          <label className="forgetEmailLabel">
                            Email<span style={{ color: "red" }}>*</span>
                          </label>
                          <div>
                            <Field
                              className="forgot-inputEmail"
                              type="email"
                              data-testid="email"
                              name="email_id"
                              margin="normal"
                              placeholder="Enter your mail id"
                            />
                          </div>
                          <div id="errormessage" className="forgot-emailError">
                            {errors.email_id && touched.email_id ? (
                              <div>&nbsp;{errors.email_id}{setforgotPassowrdRes("")}{setforgotPassowrdResErr("")}</div>
                            ) : null}
                          </div>
                          {forgotPassowrdRes? (<div className="" style={{color:"green",marginRight: "100px",
    marginTop: "inherit"}}> {forgotPassowrdRes}</div>):
            (<div className="" style={{color:"red",marginRight: "273px",
            marginTop: "inherit"}}> {forgotPassowrdResErr}</div>)}
                          {/* <Link to='/ResetPassword'> */}
                          <div>
                            <Grid container spacing={2}>
                              <Grid item xs={12}>
                                <button
                                  className="forgot-resetButton cursor_point"
                                  name="submit"
                                  type="submit"
                                  // onClick={handleForgotLink}
                                  // onClick={resetForm}
                                >
                                  Request reset link
                                </button>
                              </Grid>
                              <div />
                            </Grid>
                          </div>
                          {/* </Link> */}
                        </Grid>
                      </Grid>
                    </Form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </Container>
      </div>
      </ScrollTop>
    </div>
  );
}

export default ForgetPasswordAdmin;
