import React from 'react'
import moment from "moment/moment";
import { countryObject,categryObject } from '../Pages/CreateTripNew/RemoveExtraField';



const SearchReqbodyGen = (data) => {
 
console.log(data,"dataInsearchReq")
const serachValueafter={}
Object.entries(data).map((item)=>{
    if(item[0]==="flyingFrom"){
        if(item[1]){

            if(typeof item[1] === "object")
                 serachValueafter["flyingFrom"]=`${item[1].city},${item[1].country}(${item[1].iata_code}-${item[1].name})`
           else
                 serachValueafter["flyingFrom"]=countryObject(item[1])

        }else{
            serachValueafter["flyingFrom"]="" 
        }
        
           
    }
    else if(item[0]==="Destination"){
        if(item[1]){
            if(typeof item[1] === "object")
            serachValueafter["Destination"]=`${item[1].city},${item[1].country}(${item[1].iata_code}-${item[1].name})`
            else
            serachValueafter["Destination"]=countryObject[item[1]]
        }
        else{
            serachValueafter["Destination"]=""

        }
       }
    else if(item[0]==="FromDate"){
        console.log(typeof item[1],"momentChecking")

        if(item[1]){
            if(typeof item[1]==="string"){
                const datee=item[1].split("-")
                const formatted=`${datee[1]}/${datee[0]}/${datee[2]}`
                serachValueafter["FromDate"]=moment(new Date(formatted))
            }else{
               
                    serachValueafter["FromDate"]=moment(item[1]).format("DD-MM-YYYY")
    
                
                
            }
    

        }else{
            serachValueafter["FromDate"]=""

        }
   
    }
    else if(item[0]==="ToDate"){
        if(item[1]){

            if(typeof item[1]==="string"){
                const datee=item[1].split("-")
                const formatted=`${datee[1]}/${datee[0]}/${datee[2]}`    
                serachValueafter["ToDate"]=moment(new Date(formatted))
            }else{
               
                    serachValueafter["ToDate"]=moment(item[1]).format("DD-MM-YYYY")
    
    
                }

        }else{
            serachValueafter["ToDate"]=""
        }
       
           
          
    }else if(item[0]==="Category"){
        if(item[1]){
            if(typeof item[1] === "object")
            serachValueafter["Category"]=item[1].value
            else
            serachValueafter["Category"]=categryObject(item[1])
        }
        else{
            serachValueafter["Category"]=""

        }
    }
})

    return serachValueafter
}



export default SearchReqbodyGen