import React, { Fragment, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import axios from "axios";
import "../Pages/cityregcountry.css"

const Cityregcountry = () => {

  const validationSchema = Yup.object().shape({
    region: Yup.string().required("Region is required"),
    country: Yup.string().required("Country is required"),
    city: Yup.string().required("City is required"),

    // acceptTerms: Yup.bool().oneOf([true], "Captcha is required"),
  });

  const {
    register,
    control,
    Controller,
    reset,
    handleSubmit,
    getValues,
    setValue,
    watch,
    methods,
    onBlur,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      city: "",
      region: "",
      country: "",
    },
  });
  
  const values = getValues();
  console.log("errors", errors);
  console.log("values..", values);

  const [loading, setLoading] = useState(true);
  const [cityregcountry, setcityregcountry] = useState([]);
  const [location, setLocation] = useState([]);
  const [ad_image, setAd_image] = useState();

  useEffect(() => {
   
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");
    const load = async () => {
      const responseData = await axios
        .get(
          `${domain}/user-location/`
        )
        .then((response) => {
          console.log(response, "response");
          setLocation(response.data)
        setValue("city",response.data.city );
        setValue("region",response.data.region );
        setValue("country",response.data.country );
        setAd_image(response.data.ad)
          return response.data;
        })
        .catch((error) => {
          console.log(error);
        });
      setcityregcountry(responseData.cityregcountry);
      setLoading(false);
    };
    if (loading) {
      load();
    }
  }, [loading]);

  const onSubmit = (values) => {
    console.log(values, "values");
  };

  return (
    <Fragment>
      <form style={{ marginTop: "1rem" }} onSubmit={handleSubmit(onSubmit)}>
        <Box px={3} py={2} className="cityregcountryText">
          <Grid container spacing={2} className="ads-mainPage">
            <Grid item xs={12} sm={12}>
              <label className="ads-Text">
                City<span style={{ color: "hotpink" }}>*</span>
              </label>
              <TextField
                id="city"
                name="city"
                fullWidth
                margin="dense"
                disabled
                // defaultValue="Hyderbad"
                {...register("city")}
                error={errors.city ? true : false}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: "pink",
                    },
                  },
                  "& .MuiInputLabel-root": { color: "pink" }, //styles the label
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { borderColor: "pink" },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: "pink",
                    },
                  },
                }}
              ></TextField>
              <Typography variant="inherit" color="red">
                {errors.city && errors.city.message}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="ads-Text">
                Region<span style={{ color: "hotpink" }}>*</span>
              </label>
              <TextField
                id="region"
                name="region"
                // label="Enter Your Name"
                // placeholder="Region"
                fullWidth
                margin="dense"
                disabled
                {...register("region")}
                error={errors.region ? true : false}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: "pink",
                    },
                  },
                  "& .MuiInputLabel-root": { color: "pink" }, //styles the label
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { borderColor: "pink" },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: "pink",
                    },
                  },
                }}
              />
              <Typography variant="inherit" color="red">
                {errors.region && errors.region.message}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={12}>
              <label className="ads-Text">
                Country<span style={{ color: "hotpink" }}>*</span>
              </label>
              <TextField
                id="country"
                name="country"
                // label="Enter Your Name"
                // placeholder="Country"
                
                fullWidth
                margin="dense"
                disabled
                {...register("country")}
                error={errors.country ? true : false}
                variant="outlined"
                sx={{
                  "& .MuiOutlinedInput-root.Mui-focused": {
                    "& > fieldset": {
                      borderColor: "pink",
                    },
                  },
                  "& .MuiInputLabel-root": { color: "pink" }, //styles the label
                  "& .MuiOutlinedInput-root": {
                    "& > fieldset": { borderColor: "pink" },
                  },
                  "& .MuiOutlinedInput-root:hover": {
                    "& > fieldset": {
                      borderColor: "pink",
                    },
                  },
                }}
              />
              <Typography variant="inherit" color="red">
                {errors.country && errors.country.message}
              </Typography>
            </Grid>

             <Grid item xs={12} sm={12}>
              <label className="ads-Text">
                Ad image<span style={{ color: "hotpink" }}>*</span>
              </label>
              {/* <Grid> */}
               {/* <Button href="http://infodrivesystems.com/" target="_blank">  */}
               <Box
                width="100%"
                
                  component="img"
                  className="studentpro-itTechImage"
                  sx={{
                    content: {
                      xs:`url('${ad_image}')`,
                      md:`url('${ad_image}')`,
                      // xs: "url('./Assets/Images/Infodrive_ad_MV.webp')", //img src from xs up to md
                      // md: "url('./Assets/Images/Infodrive_ad.webp')", //img src from md and up
                      // lg: "url('./Assets/Images/Infodrive_ad.png')",
                      // xl: "url('./Assets/Images/Infodrive_ad.png')",
                    },
                  }}
                  alt="Logo"
                />
               {/* </Button> */}
               {/* </Grid> */}

            {/* <img src={ad_image} alt="ad_image"></img> */}
            </Grid> 
          </Grid>
         
        </Box>

       
      </form>
    </Fragment>
  );
};

export default Cityregcountry;