import { Icon } from "@iconify/react";
import { Box, Button, Container, Grid } from "@mui/material";
import React from "react";
import "../../AdminDashboard/CSS/managepopup.css";
import axios from "axios";
import { useState } from "react";

function ManagePopup(props) {
  console.log(props.createAdtrTripid, "profilepic");
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);

  console.log("1234567", props.createAdtrTripid);
  const approvalhandler = (id, value) => {


    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }

    axios
      .get(
        `${domain}/admin/manage-members/?page=${currentPage}`
      )
      .then((response) => {
        console.log(response, "manage members");
        props.setProfilePic();
        setFilteredData(response.data.results.data);
      });
  };

  return (
    <div>
      <div className="managemembers-modalBackground">
        <div className="managemembers-modalContainer">
          <Box className="manage-members-popup-box">
            {/* <Box className="profileedit-popupbackground"> */}
            <Grid container spacing={2} className="profileedit-main-heading">
              <Grid
                item
                xl={10}
                lg={10}
                md={10}
                sm={10}
                xs={10}
                className="profileedit-heading"
              >
                <div>Members Detail</div>
              </Grid>
              <Grid item xl={2} lg={2} md={2} sm={2} xs={2}>
                <span className="profiledit-main-icon">
                  <Icon
                    icon="iconoir:cancel"
                    className="profieedit-profile-icon"
                    onClick={props.setOpenModalpopinfo}
                  />
                </span>
              </Grid>
            </Grid>
            <div>
              {" "}
              <Grid container spacing={2}>
                <Grid
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="profilepopup-editpopup-outer"
                >
                  <div className="profilepopup-editpopup-inner">
                    <div className="profilepopupEditImg-con">
                      <img
                        className="profilepopupEditImg"
                        accept="jfif"
                        src={
                          props.createAdtrTripid.profile_picture
                            ? props.createAdtrTripid.profile_picture
                            : "../../Assets/Images/Favicon 25 WB _ DV.webp"
                        }
                      />
                    </div>
                  </div>
                </Grid>
              </Grid>
            </div>
            <div className="manage-members-fullname">
              {props.createAdtrTripid.full_name}
            </div>
            <div className="manage-members-icon-main-div">
              <Icon
                icon="basil:phone-solid"
                className="manage-members-phone-icon"
              />
              <div>Mobile Number :</div>
              <div style={{ fontWeight: 600, spacing: "2" }}>
                {props.createAdtrTripid.country_code} &nbsp;
              </div>
              <div style={{ fontWeight: 600 }}>
                {props.createAdtrTripid.mobile_number}
              </div>
            </div>
            <div className="manage-members-createdon-div">
              <Icon
                icon="bx:calendar"
                className="manage-members-calendar-icon"
              />
              <div>Created On : </div>
              <div style={{ fontWeight: 600 }}>
                {props.createAdtrTripid.created_at}
              </div>
            </div>
          </Box>
        </div>
      </div>
    </div>
  );
}

export default ManagePopup;