import React from 'react'
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'
import { Navigate } from 'react-router-dom';
import { useEffect } from 'react';




function GSingIn_PopUp({mydata}) {

const [invitopen, setinvitopen] = React.useState(true);
const handleinvitClose = () => {
    // return <Navigate replace to={"/edit-profile"}></Navigate>;
  setinvitopen(false);
  mydata='false';
// editme()
  

};
// const editme=()=>{return <Navigate replace to={"/edit-profile"}></Navigate>;}
// useEffect(

// ()=>{ if(!invitopen){
//     return <Navigate replace to={"/edit-profile"}></Navigate>  
// }},[invitopen])


  return (
    <div>
    <Container>
      <Dialog
        open={invitopen}
        onClose={handleinvitClose}
        // {/* // aria-labelledby="alert-dialog-title"
        // // aria-describedby="alert-dialog-description" */}
        PaperProps={{
          style: {
            minHeight: "40vh",
            minWidth: "35vw",
            boxShadow: "none",
            border: "2px solid rgb(128 213 184 / 100%)",
            strokeWidth: "1px",
            stroke: "rgb(128 213 184 )",
            borderRadius: 20,
          },
        }}
        BackdropProps={{
          style: {
            backdrop: "blur(2px)",
          },
        }}
      >
        <div>
          <DialogTitle id="alert-dialog-title">
            {/* {handleinvitClose ? (
    <IconButton
      aria-label="close"
      onClick={handleinvitClose}
      sx={{
        position: 'absolute',
        right: 8,
        top: 8,
        color: (theme) => theme.palette.grey[500],
      }}
    >
      <CloseIcon />
    </IconButton>
  ) : null} */}
            {/* <div className="invitation-upcoming-delete-dalog">
                  <Icon
                    className="invitation-deleteConfirmationIcon"
                    icon="fluent:delete-12-filled"
                  />
                  Delete
                </div> */}
          </DialogTitle>
          <DialogContent className="invitation-upcoming-delete-dalog">
            <h2 className="invitation-areUSure" style={{ color: "red" }}>
              {" "}
              Please update your profile details to continue
            </h2>
          </DialogContent>
          <DialogActions className="invitation-buttonsContainer">
            {/* <Button
                  id="btn"
                  className="invitation-yesButton"
                  onClick={() => {
                    settingdeleteinvitation();
                  }}
                >
                  Yes
                </Button> */}
            <Button
              className="invitation-noButton"
              onClick={() =>handleinvitClose()}
             
            >
              OK
            </Button>
          </DialogActions>
        </div>
      </Dialog>
    </Container>
  </div>  )
}

export default GSingIn_PopUp