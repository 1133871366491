import React from 'react'
import { Select } from 'antd';
import './SelectComponent.css'
import { Icon } from '@iconify/react';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
// var XLSX = require("xlsx");

const SelectComponent = ({idName,data}) => {
  console.log(window,"windowChecking")
  const exportToExcel = (datas) => {
    const ws = XLSX.utils.json_to_sheet(datas);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'DataSheet');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    saveAs(data, 'data.xlsx'); // You'll need to use the FileSaver library or a similar library to save the file.
  };
  return (
    <div>
<Select
    style={{
      width: 150,
      textAlign:'initial',
    }}
    className='customSelect'
    placeholder="Export"
    suffixIcon={<Icon icon='ep:arrow-down' style={{color:'#20c28c'}}/>}
    options={[
      {
        value: 'export to excel',
        label: 'export to excel',
      },
      {
        value: 'export as pdf',
        label: 'export as pdf',
      },
    ]}
    onChange={(value)=>{
      console.log(idName,"windowChecking")
      if(value==="export as pdf"){
        let page=document.getElementById(idName)
        console.log(page,"windowCheckingPage")
        if(page){
          //window.html2pdf().from(page).save()
          let element = document.getElementById('my_invoice');
let opt = {
//margin:       [2,2,10,10],
  filename:     'my-invoice.pdf',
  //image:        { type: 'jpeg', quality: 0.98 },
  //html2canvas:  { backgroundColor:"#FF0000",width:'100%'},
  
jsPDF:        { unit: 'mm', hotfixes :["px_scaling"],format: [230,297] }
};

// New Promise-based usage:
window.html2pdf().set(opt).from(page).save();
// window.print()
        }
      }else{
        exportToExcel(data)
      }

    }}
  />
    </div>
  )
}

export default SelectComponent