import React from 'react'
import axios from "axios";


const searchApi = async(normalized) => {
    const apiResponse={}



    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");


    
   await axios
    .get(
      `${domain}/search?flying_from=${
        normalized.flyingFrom?normalized.flyingFrom:""
      }&destination=${normalized.Destination?normalized.Destination:""}&category=${
        normalized.Category
      }&date_range_from=${normalized.FromDate ? normalized.FromDate : ""}&date_range_to=${
        normalized.ToDate ? normalized.ToDate : ""
      }`
    )
    .then((response) => {
      console.log(response, "searchresponse");
      // setSearchResponse(response.data)
      // console.log(searchResponse,"SearchResponse state")
      apiResponse["status"]="S"
      apiResponse["data"]=response.data
     
    })
    .catch((error) => {
    //   Error
      if (error.response.status === 400) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        apiResponse["status"]="F"
      apiResponse["data"]=error.response
        console.log(error.response.data);
        // mySearchDataError(error.response.data.message);
        // console.log(error.response.status);
        // console.log(error.response.headers);
      }
    });

    return apiResponse
}

export default searchApi