import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import { Gif, Style } from "@mui/icons-material";
import { Box, Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Grid, Icon, Modal, styled } from "@mui/material";
import "../CSS/StudentProfile.css";
import React, { useEffect, useState } from "react";
import DashboardHeader from "../Dashboard/DashboardHeader";
import ScrollTop from "../ScrollTop";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import TripCount from "../UpcomingTrip_count/TripCount";
import axios from "axios";

function StudentProfile() {
  const { isLoggedIn, user } = useSelector((state) => state.auth);


  const [canCreate,setCancreate]=useState(Boolean(JSON.parse(localStorage.getItem("can"))))


  const [invitopen, setinvitopen] = React.useState(false);
  const handleinvitClose = () => {
    setinvitopen(false);
  };

  const handleClickk = () => {
    if (!isLoggedIn) {
         setinvitopen(true);
    }  else

    if(!canCreate)
    setinvitopen(true);
  
  }


  const [student_community_ad_image_DV, setstudent_community_ad_image_DV] = useState();
  const [student_community_ad_image_MV, setstudent_community_ad_image_MV] = useState();
  
  const [student_community_ad_image_link, setstudent_community_ad_image_link] = useState("");
  

  const [adsopen, setadsOpen] = React.useState(false);
  const handleadsOpen = () => setadsOpen(true);
  const handleadsClose = () => setadsOpen(false);


  const [homeadsopen, sethomeadsOpen] = React.useState(false);
  const handlehomeadsOpen = () => sethomeadsOpen(true);
  const handlehomeadsClose = () => sethomeadsOpen(false);

  useEffect(() => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");



    const responseData1 = axios
      .get(`${domain}/admin/ads-location/`)
      .then((response) => {
        console.log(response, "response ad student");
        let i;
        if (response) {
          for (i = 0; i < 7; i++) {
            if (response.data.response_data[i].ad_placement === "student_community_ad") {
              console.log(
                response.data.response_data[i].ad_placement,
                "addd student_community_ad"
              );

              setstudent_community_ad_image_DV(response.data.response_data[i].desktop_ad);
              setstudent_community_ad_image_MV(response.data.response_data[i].mobile_ad);
              setstudent_community_ad_image_link(response.data.response_data[i].ad_link);
            }
           
           
          }
        }
        // setAd1_image(response.data.response_data[0].desktop_ad)
        // setAd2_image(response.data.response_data[1].desktop_ad)
        // setAd1_image_link(response.data.response_data[0].ad_link)
        // setAd2_image_link(response.data.response_data[1].ad_link)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);


  return (
    <>
      <ScrollTop>
        <div>
          <DashboardHeader />
        </div>
        <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <p className="studentpro-studentMainHeading">
                Travel Companion by &nbsp;
                <span style={{ color: "#1ec28b" }}> Students</span>
                &nbsp;Community
              </p>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="studentpro-hrDiv">
                <hr className="studentpro-hrStudent"></hr>
              </div>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <p className="studentpro-makeMoneyText">
                Make money on your travel back home (India, Pakistan,
                Bangladesh, Nepal, Sri Lanka) or to your university abroad by
                being a paid travel companion to others.
              </p>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div className="studentpro-homeheading">
                <img
                  className="studentpro-fortImage"
                  src="./Assets/Images/University image.webp"
                  alt="logo"
                />
              </div>
            </Grid>
            {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <div className="studentpro-homeheading">
              <img
                className="studentpro-globeFlightImage"
                src="./Assets/Images/I_2.webp"
                alt="logo"
              />
            </div>
          </Grid> */}

            {/* <Grid item xs={10} sm={10} md={10} lg={10} xl={10}>
              // <div className="studentpro-homeheading"> */}
                {/* <img
                  className="studentpro-itTechImage"
                  src="./Assets/Images/I_3.webp"
                  alt="logo"
                /> */}
                               {/* <a href={student_community_ad_image_link} target="_blank"> 

                <Grid container>
               <Box width="100%"
                  component="img"
                  className="studentpro-itTechImage"
                  sx={{
                    content: {
                      // xs: "url('./Assets/Images/Infodrive_ad_MV.webp')", //img src from xs up to md
                      xs: `url(${student_community_ad_image_MV !=="default"?student_community_ad_image_MV:`"${Poster_MV}"`})`, //img src from xs up to md

                      // md: "url('./Assets/Images/Infodrive_ad.webp')", //img src from md and up
                      md: `url(${student_community_ad_image_DV !=="default"?student_community_ad_image_DV:Poster_DV})`, //img src from md and up

                      // lg: "url('./Assets/Images/Infodrive_ad.png')",
                      // xl: "url('./Assets/Images/Infodrive_ad.png')",src\StudentProfile\StudentProfile.js
                    },
                  }}
                  alt="student_community_ad"
                />
              
               </Grid>
               </a> */}
              {/* </div>
            </Grid> */}
    <Container className="student-ads-image-desktopversion">
        {/* <div>
          <img
            src="./Assets/Images/Poster 1 _ DV.png"
            alt="Add"
            className="homepage-ads-image"
          />
        </div> */}
         <div className="homepage-ads-image-img-one-container">
         <a href={student_community_ad_image_link} target="_blank">

              <img
                // src="./Assets/Images/Poster 1 _ DV.webp"
                src={
                  student_community_ad_image_DV != "default"
                    ? student_community_ad_image_DV
                    : "./Assets/Images/Poster 1 _ DV.webp"
                }

                alt="ad's"
                className="homepage-ads-image"
              />
              </a>
               {student_community_ad_image_DV != "default" ? (
                ""
              ) : (
              <button
                onClick={handlehomeadsOpen}
                className="homepage-ads-image-img-one-btn"
              >
                More Info
              </button>
              )}
            </div>

      </Container>

      <Container className="student-ads-image-Mobileversion">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="home-footer-ads-img-one-container">
            <a href={student_community_ad_image_link} target="_blank">

              <img
                // src="../../Assets/Images/Poster 1 _ MV.webp"

                src={
                  student_community_ad_image_MV != "default"
                    ? student_community_ad_image_MV
                    : "../../Assets/Images/Poster 1 _ MV.webp"
                }
                alt="ad's"
                className="home-footer-ads-img-one"
              />
              </a>
               {student_community_ad_image_MV != "default" ? (
                ""
              ) : (
              <button
                onClick={handleadsOpen}
                className="home-footer-ads-img-one-btn"
              >
                More Info
              </button>
              )}
            </div>

           
          </Grid>
        </Grid>
      </Container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <p className="studentpro-itServiceText">
                Students are always the strongest pillars of volunteering, being
                a paid travel companion is like getting paid to help someone on
                your travel to university or back home.
              </p>

              <p className="studentpro-itServiceSecondText">
                No additional efforts other than assisting a travel companion
                and being a support. Getting paid is an additional perk for the
                help that students are always willing to do!!
              </p>
            </Grid>

            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div>
                <img
                  className="studentpro-ruReadyText"
                  src="./Assets/Images/I_4.webp"
                  alt="logo"
                />
              </div>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              className="studentpro-addYourTrip"
            >
              <span>
                Just add your <span style={{ color: "#1ec28b" }}>trip</span>{" "}
                with us and select your{" "}
                <span style={{ color: "#1ec28b" }}>payment</span> you expect!!
              </span>
            </Grid>
          </Grid>
          <div>
            <Link to={isLoggedIn&&Boolean(JSON.parse(localStorage.getItem("can"))) ? "/create-a-trip" :""} style={{ textDecoration: "none" }} onClick={handleClickk}>
              <button className="studentpro-createBtn">
                CREATE A TRIP
                <span className="studentpro-ArrowCircleRightRoundedIcon">
                  <ArrowCircleRightRoundedIcon />
                </span>
              </button>
            </Link>
          </div>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <div>
                <img
                  className="studentpro-ruReadyText"
                  src="./Assets/Images/Student comm.webp"
                  alt="logo"
                />
              </div>
            </Grid>

            {/* <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <div>
              <img
                className="studentpro-ruReadyText"
                src="./Assets/Images/I_6.webp"
                alt="logo"
              />
            </div>
          </Grid> */}
          </Grid>
        </Container>
      
      </ScrollTop>
      <div>
        <Container>
          <Dialog
            open={invitopen}
            onClose={handleinvitClose}
            // {/* // aria-labelledby="alert-dialog-title"
            // // aria-describedby="alert-dialog-description" */}
            PaperProps={{
              style: {
                minHeight: "40vh",
                minWidth: "35vw",
                boxShadow: "none",
                border: "2px solid rgb(128 213 184 / 100%)",
                strokeWidth: "1px",
                stroke: "rgb(128 213 184 )",
                borderRadius: 20,
              },
            }}
            BackdropProps={{
              style: {
                backdrop: "blur(2px)",
              },
            }}
          >
            <div>
              <DialogTitle id="alert-dialog-title">
                {/* {handleinvitClose ? (
        <IconButton
          aria-label="close"
          onClick={handleinvitClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
                {/* <div className="invitation-upcoming-delete-dalog">
                      <Icon
                        className="invitation-deleteConfirmationIcon"
                        icon="fluent:delete-12-filled"
                      />
                      Delete
                    </div> */}
              </DialogTitle>
              <DialogContent className="invitation-upcoming-delete-dalog">
                <h2 className="invitation-areUSure" style={{ color: "red" }}>
                  {" "}
                  {/* { !isLoggedIn?"please login to create a trip":"You have reached the maximum limit of 4 trips, So you cannot create Trip"} */}
                  { !isLoggedIn?"please login to create a trip":
                  !canCreate?"You have reached the maximum limit of 4 trips, So you cannot create trip":""}
                </h2>
              </DialogContent>
              <DialogActions className="invitation-buttonsContainer">
                {/* <Button
                      id="btn"
                      className="invitation-yesButton"
                      onClick={() => {
                        settingdeleteinvitation();
                      }}
                    >
                      Yes
                    </Button> */}
                <Button
                  className="invitation-noButton"
                  onClick={handleinvitClose}
                >
                  OK
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </Container>
      </div>
    </>
  );
}

export default StudentProfile;