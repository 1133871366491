import React from 'react'
import axios from 'axios';
import moment from "moment";
import { FormattingField, RemoveExtraField, airlineObject, countryObject, languageObject } from './RemoveExtraField';

export const EditTrip = async(requestBody) => {
    let obj={}
    let domain = "";

    const removed=RemoveExtraField(requestBody)
    const finalBody=FormattingField(removed)

    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }


    await axios
    .patch(
      `${domain}/create-trip/${requestBody.id}/`,
      finalBody
    )
    .then((response) => { 
        obj["status"]="S"
        obj["id"]=response.data.trip_id
        
     })
    .catch((error) => {
      obj["status"]="F"
      obj["message"]=error.values
    });


  return obj
}


export const getTripdata = async(id,page) => {
    const obj={
    }
    let data;
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");
    
    await axios
    .get(
      `${domain}/create-trip/${id}`,
      
    )
    .then((response) => {
        console.log("responseEdit",response.data)
        data=response.data[0]
      const array=[]
      const defaultObj={}
      Object.entries(response.data[0]).map((field,index)=>{
        if(field[0].slice(0,7)==="transit"||field[0].slice(0,7)==="airline"){
        if(field[0].charAt(7)!==""){
            if(field[1]!==""){
              const number=parseInt(field[0].charAt(7))
              if(field[0].slice(0,7)==="transit"){
                    array[number-1]={...array[number-1],[`${field[0].slice(0,7)}`]:countryObject(field[1])}
              }else{
               
                    array[number-1]={...array[number-1],[`${field[0].slice(0,7)}`]:airlineObject(field[1])}
               
              }
             
            }
                 
          
          }
          else{
            if(field[1]==="Yet to Book"){
              obj[`${field[0]}`]=field[1]
            }else{
              obj[`${field[0]}`]=airlineObject(field[1])

            }
           

          }
           
        }
        else if(field[0]==="category"||field[0]==="user_type"||field[0]==="booking_status"){
          obj[`${field[0]}`] =field[1].toString()
        }else if(field[0]==="flying_from"||field[0]==="destination"){
                obj[`${field[0]}`]=countryObject(field[1])
        }else if(field[0]==="prefered_language"){
          console.log(languageObject(field[1]),"languageObject(field[1])")
          obj[`${field[0]}`]=languageObject(field[1])
        }else if(field[0]==="departing_on"||field[0]==="date_range_from" ||field[0]==="date_range_to"){
          if(field[1]){
            obj[field[0]]=moment(new Date (field[1]))
          }else{
            obj[field[0]]=""
          }
        }
        else{
           
            obj[`${field[0]}`] =field[1]    
        }
      })
      obj[`airAndTrans`]=array
      obj["Declaration"]=true
    })
    .catch((error) => {
      console.log(error.values);
    });
  
  return page==='user-trip'?data:obj
}