import React from 'react'
import { Icon } from "@iconify/react";
import {
    Autocomplete,
    Grid,
    TextField,
    Box,
    InputAdornment,
    IconButton
  } from "@mui/material";
  import { getAirports } from '../../../Pages/CreateTripNew/OptionsUtility/OptionsUtil';
  import EventIcon from '@mui/icons-material/Event';

const Preference = ({name,settingState}) => {
    const [fieldData,setFieldData]=React.useState([
        {
            label:'Flying From',
            icon:'mdi:aeroplane-takeoff',
            name:'source'
        },
        {
            label:'Destination',
            icon:'humbleicons:location',
            name:'destination'
        }
    ])
    const [open,setOpen]=React.useState(false)
    const [airportValue,setAirportValue]=React.useState("")
    const [openField,setOpenField]=React.useState(false)
    const [prefer,setPrefer]=React.useState()
    const [airportResponse,setPortresponse]=React.useState([])

    const handleDisable = (option) => {
        let flag = false;
        if (
          JSON.stringify(option) === JSON.stringify() ||
          JSON.stringify(option) === JSON.stringify()
        ) {
          flag = true;
        }
       [].map((trans) => {
          if (JSON.stringify(option) === JSON.stringify(trans.transit)) {
            flag = true;
          }
        });
    
        return flag;
      };
console.log(airportResponse,'airportResponse')
      
    React.useEffect(() => { 
        console.log(getAirports(airportValue).then(res=>{
          if(res.data.message)
             setPortresponse([])
          else   
              setPortresponse(res.data.response)
        }),"countryResponseTC")
        }, [airportValue]);

        const AutoComtrip=(data)=>{
            return  <div style={{width:"45%"}} className='auto-back-trip'>
                 
                      
            <Autocomplete
                    popupIcon={<Icon icon={`${data.icon}`} style={{color:"#36E196",fontSize:30}} />}
                   open={openField===data.name}
                   onOpen={() => {
                     if (airportValue) {
                       setOpenField(data.name)
                     }
                   }}
                   sx={{width:'100%'}}
                   onClose={() =>{
                     setAirportValue("");
                     setOpenField('')
                   }}
                   onInputChange={(e, value, reason) => {
                     setAirportValue(value);
                     if (!value) {
                       setOpenField('')
                       setAirportValue("");
                     }
                   }}
                   className="Auto_w Auto_w_reports-trips"
                   getOptionDisabled={(option) => {
                     return handleDisable(option);
                   }}
                   freeSolo={true}
                   id="country-select-demo"
                   options={airportResponse}
                   autoHighlight
                   onChange={(event, item) => {
                     if(item){
                      settingState((pre)=>{
                        return {
                          ...pre,
                          [name]:{
                              ...pre[name],
                              [data.name]:`${item.city},${item.country}(${item.city}--${item.country})`
                          }
                        } 
                   })
                     }
               
                   }}
                   getOptionLabel={(option) =>
                     `${option.city},${option.country}`
                   }
                   renderOption={(props, option) => (
                     <Box
                       component="li"
                       sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                       {...props}
                     >
                       {option.city},{option.country}
                     </Box>
                   )}
                   renderInput={(params) => (
                     <TextField
                       {...params}
                       label={`${data.label}`}
                       className="trip_auto"
                       inputProps={{
                         ...params.inputProps,
                         autoComplete: "new-password",
                       }}
                       variant="standard"
                    //    InputProps={{
                    //        endAdornment: (
                    //          <InputAdornment position="end">
                    //            <IconButton edge="end">
                    //            <Icon icon={`${data.icon}`} style={{color:"#36E196",fontSize:30}} />
                    //            </IconButton>
                    //          </InputAdornment>
                    //        ),
                    //      }}
                     />
                   )}
                 />
                 <Icon icon={`${data.icon}`} style={{color:"#36E196",fontSize:30}}/>
        </div>
        }

       const preferenceHandler=(value)=>{
        setOpen(false)
        setPrefer(value)
         settingState((pre)=>{
              return {
                ...pre,
                [name]:{
                    ...pre[name],
                    ['preference']:value
                }
              } 
         })
       }

       function titleCase(str) {
        return str
          .split(' ')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
          .join(' ');
      }
  
  return (
    <div className='prefer-con'>
          <div className='yerString prefer'>
               <div>{`Select Preference :  `}<span className='prefer-text'>{prefer?titleCase(prefer):''}</span></div> 
               <Icon 
               icon="dashicons:arrow-down" 
               className='calender-icon' 
              //  color='#6c6c6c' 
               onClick={()=>{
                    setOpen(!open)
               }}
               style={{color:"black",fontSize:30}}
               ></Icon>
               {open && 
               <div className='prefer-drop-down-con'>
                <div className='prefer-drop-down'>
                   <div className='prefer-option preoption' onClick={()=>{
                     preferenceHandler('airport')
                   }} >Airports wise</div>
                   <div className='prefer-option' onClick={()=>{
                     preferenceHandler('country')
                   }}>Country wise</div>
                </div>
               </div>}
            </div>
<div className='autoomplte-con'>
{AutoComtrip(fieldData[0]) } 
{AutoComtrip(fieldData[1])}
</div>
        
    </div>
  )
}



export default Preference