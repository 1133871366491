import React, {useState, useEffect} from "react";
import * as Yup from "yup";
import {Icon} from "@iconify/react";
// import axiosInstance from "../axios";
import {useFormik} from "formik";
import axios from "axios";
import "../../CSS/login.css";
import {
  Button,
  Container,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
// import { Link } from "react-router-dom";
import {Link, Navigate, useNavigate} from "react-router-dom";
import {useSelector, useDispatch} from "react-redux";
import {login, glogin} from "../../actions/auth";
import GoogleLogin from "react-google-login";
import {gapi} from "gapi-script";
import ScrollTop from "../../ScrollTop";
import userEvent from "@testing-library/user-event";
import {clearMessage} from "../../actions/messege";
import TripCount from "../../UpcomingTrip_count/TripCount";
import {Visibility, VisibilityOff} from "@mui/icons-material";

function Login(props) {
  const navigate = useNavigate();

  const {isLoggedIn, user} = useSelector(state => state.auth);
  const {message} = useSelector(state => state.message);
  console.log(message, "error check");
  const dispatch = useDispatch();
  const [gloginErrorMessage, setgloginErrorMessage] = React.useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleInputChange = event => {
    setShowPassword(false);

    formik.handleChange(event);
  };

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const validationSchema = Yup.object({
    email: Yup.string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: Yup.string("Enter your password")
      // .min(8, 'Password should be of minimum 8 characters length')
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
      // )
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      // alert(JSON.stringify(values, null, 2));
      console.log(JSON.stringify(values));
      dispatch(login(values.email, values.password));
    },
  });

  // useEffect(() => {
  //   document.title =  "Desi Travel Companion-login";
  // }, []);

  const [profile, setProfile] = useState([]);
  //Uat-glogin
  // const clientId =
  //   "1069593416306-cc1foo49l43cfd3euunv6dhh397jsqda.apps.googleusercontent.com";
  //prod-glogin
  // const clientId =
  // "562795218212-jv1emrbt3jeosgqel9kjpmojoojjai5a.apps.googleusercontent.com";

  let clientId = "";
  if (window.location.hostname.trim() === "localhost") {
    clientId = process.env.REACT_APP_UAT_API_Google_SIGNIN;
  } else if (window.location.hostname.trim() === "54.185.6.32") {
    clientId = process.env.REACT_APP_UAT_API_Google_SIGNIN;
  } else if (
    window.location.hostname.trim() === "uat.desitravelcompanion.com"
  ) {
    clientId = process.env.REACT_APP_UAT_API_Google_SIGNIN;
  } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
    clientId = process.env.REACT_APP_PROD_API_Google_SIGNIN;
  } else if (
    window.location.hostname.trim() === "www.desitravelcompanion.com"
  ) {
    clientId = process.env.REACT_APP_PROD_API_Google_SIGNIN;
  }
  console.log(clientId, "clientId env end");

  // useEffect(() => {
  //   const initClient = () => {
  //     gapi.client.init({
  //       clientId: clientId,
  //       scope: "",
  //     });
  //   };
  //   gapi.load("client:auth2", initClient);
  // });

  // const onSuccess = (res) => {
  //   console.log("success", res);
  //   setProfile(res.profileObj);
  // };

  const onSuccess = res => {
    console.log("success", res);
    console.log("AccessToken", res.xc.access_token);

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .post(` ${domain}/rest-auth/google/`, {
        access_token: res.xc.access_token,
        // 'Access-token':"ya29.a0AX9GBdXonCkMac-rDkI64MxY9-dNoK_o7KPOW3Fmx-gVWfRlUruNwPuuMQ5r4jC_qN0wh30h8ohvIsUdpSDWtDFhMx2nmtHeYHwBE0RYJYu1mhLNCNyOXgifcDu3cQHCgG4VIYtrnVuoBi2NdVCPWG1enIWRaCgYKAW0SARISFQHUCsbCSyGFCCZZyKKPJRgPdcTKeA0163"
      })
      .then(res => {
        console.log(res, "login response from python api");
        if (res.data) {
          console.log(res.data, "login res data");
          // return <Navigate replace to="/edit-profile" />;
          localStorage.setItem("user", JSON.stringify(res.data));
          dispatch(glogin(res.data));
        }
        // if (res.profileObj) {
        //   console.log(res.profileObj, "login response data");
        //   setProfile(res.profileObj);
        // }
      })
      .catch(err => {
        console.log("failed from python api", err);
        // console.log(err.response)
        if (err.response.status === 400) {
          console.log(err.response.data.message);
          dispatch(clearMessage());
          setgloginErrorMessage(err.response.data.message);
        }
      });
  };

  const onFailure = err => {
    console.log("failed", err);
  };

  useEffect(() => {
    dispatch(clearMessage());
    // if (lastLocation === 'specialLocation') {
    //   // call your API
    // }
    // setLastLocation(location)
  }, []);

  if (isLoggedIn) {
    TripCount(user.user_id);
    if (user.update_profile_details == "False") {
      return <Navigate replace to="/dashboard" />;
    } else {
      return <Navigate replace to="/edit-profile" />;
    }
  } else {
    return (
      <div className="mainLogin">
        <ScrollTop>
          <Container>
            <Grid container spacing={2} className="containerMainGrid">
              <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
                <div>
                  <Link to="/">
                    <img
                      src="./Assets/Images/desi-travel-campanion-logo.png"
                      alt="first"
                      className="travelCompanionImg"
                    />
                  </Link>
                </div>
                <div>
                  <img
                    src="./Assets/Images/Login Illustraion Image PNG - Copy.png"
                    alt="first"
                    className="loginsecondimg"
                  />
                </div>
              </Grid>

              <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
                <div
                  className="loginsection"
                  style={{border: "1px solid #4F8DF9"}}
                >
                  {/* <Formik
                // enableReinitialize
                initialValues={{ initialValues }}
                validationSchema={ContactSchema}
                onSubmit={
                  // (values) => console.log(values)
                  handleSubmit
                }
              > */}
                  {/* {({ errors, touched, isValid, isSubmitting }) => */}
                  <form onSubmit={formik.handleSubmit}>
                    <h2 className="tagLine">Login</h2>
                    <p>by using :</p>
                    <Grid
                      container
                      spacing={2}
                      className="login-socialIcons"
                      style={{marginLeft: 0, marginTop: 20}}
                    >
                      {/* <Grid >
                    <div className="google-btn cursor_point">
                      <div className="google-icon-wrapper">
                       
                        <Icon
                          icon="logos:google-icon"
                          className="google-icon"
                        />
                      </div>
                      <p className="btn-text">
                        <b>Sign in with google</b>
                      </p>
                    </div>
                  </Grid> */}
                      <GoogleLogin
                        clientId={clientId}
                        buttonText="Sign in with Google"
                        onSuccess={onSuccess}
                        // onClick={onSuccess}
                        // onFailure={onFailure}
                        cookiePolicy={"single_host_origin"}
                        isSignedIn={false}
                        className="login-google-sign"
                      ></GoogleLogin>
                      {/* <Grid item xs={2} style={{ color: "#1EC28B ", fontSize: 45 }}>
                    <Icon
                      icon="ant-design:google-square-filled"
                      color="#1ec28b"
                    />{" "}
                  </Grid>
                  <Grid item xs={2} style={{ color: "#1EC28B ", fontSize: 45 }}>
                    <Icon icon="ant-design:facebook-filled" color="#1ec28b" />{" "}
                  </Grid>
                  <Grid
                    item
                    xs={2}
                    style={{ color: "#1EC28B ", fontSize: 41, marginTop: 2 }}
                  >
                    <Icon icon="akar-icons:linkedin-box-fill" color="#1ec28b" />{" "}
                  </Grid> */}
                    </Grid>

                    <div className="login-or-text">or</div>
                    <Container>
                      <hr className="hrLine"></hr>
                    </Container>

                    <Grid container spacing={2} className="login-emailMainGrid">
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <label className="emailLabelLogin">
                          Email<span style={{color: "#ff0000"}}>*</span>
                        </label>
                        <div>
                          <TextField
                            className="emailLoginInput"
                            type="email"
                            name="email"
                            id="email"
                            margin="normal"
                            onBlur={formik.handleBlur}
                            touched={formik.touched}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.email &&
                              Boolean(formik.errors.email)
                            }
                            helperText={
                              formik.touched.email && formik.errors.email
                            }
                          />
                        </div>
                        <div
                          id="errormessage"
                          className="loginEmailError"
                        ></div>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <label className="emailLabelpassword">
                          Password
                          <span style={{color: "red"}}>*</span>
                        </label>
                        <div>
                          <TextField
                            className="passwordLoginInput"
                            name="password"
                            id="password"
                            autoFocus={false}
                            type={showPassword ? "text" : "password"}
                            onBlur={formik.handleBlur}
                            touched={formik.touched}
                            value={formik.values.password}
                            onChange={handleInputChange}
                            // onInput={handleInputChange}
                            sx={{
                              "& .MuiInputBase-inputAdornedEnd": {},
                            }}
                            error={
                              formik.touched.password &&
                              Boolean(formik.errors.password)
                            }
                            helperText={
                              formik.touched.password && formik.errors.password
                            }
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    edge="end"
                                  >
                                    {showPassword ? (
                                      <Icon
                                        icon="ph:eye"
                                        style={{color: "grey"}}
                                      />
                                    ) : (
                                      <Icon
                                        icon="iconamoon:eye-off-light"
                                        style={{color: "grey"}}
                                      />
                                    )}
                                    {/* {showPassword ? (
                                      <Visibility />
                                    ) : (
                                      <VisibilityOff />
                                    )} */}
                                  </IconButton>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </div>
                        <div id="errormessage" className="passwordDanger"></div>
                      </Grid>
                      <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                        <Grid>
                          <Link
                            to="/forgot-password"
                            style={{textDecoration: "none"}}
                          >
                            <p className="forgetPasswordText">
                              Forgot password?
                            </p>
                          </Link>
                        </Grid>
                        <div>
                          <span
                            style={{
                              color: "red",
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: "5px",
                            }}
                          >
                            {message}
                          </span>
                          <span
                            style={{
                              color: "red",
                              display: "flex",
                              justifyContent: "center",
                              marginBottom: "5px",
                            }}
                          >
                            {gloginErrorMessage}
                          </span>
                        </div>
                      </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xl={6}
                        lg={12}
                        md={6}
                        sm={12}
                        xs={12}
                        className="login-signing-confrim"
                        style={{marginBottom: "9px"}}
                      >
                        By signing in, I agree to the{" "}
                        <a
                          href="/terms-and-conditions"
                          style={{textDecoration: "none"}}
                        >
                          Terms and Conditions, Privacy Statement
                        </a>
                      </Grid>
                    </Grid>

                    <Grid container spacing={2}>
                      <Grid item xl={6} lg={12} md={6} sm={12} xs={12}>
                        <button
                          className="loginBtn cursor_point"
                          id="getButton"
                          name="Submit"
                          type="submit"
                          onClick={() => {
                            setgloginErrorMessage("");
                          }}
                        >
                          Login
                        </button>

                        <div className="notRegisterText">
                          {/* Not register yet? */}
                          Sign up, it’s free
                          <Link to="/signup" style={{textDecoration: "none"}}>
                            <span className="createText">Create Account</span>
                          </Link>
                        </div>
                      </Grid>
                    </Grid>
                  </form>
                </div>
              </Grid>
            </Grid>
          </Container>
        </ScrollTop>
      </div>
    );
  }
}

export default Login;