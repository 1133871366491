import React from "react";
import {
  Box,
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Modal,
  Popover,
  Typography,
} from "@mui/material";
import "../../CSS/explorecarousle.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ArrowLeftRoundedIcon from "@mui/icons-material/ArrowLeftRounded";
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import RouteIcon from "@mui/icons-material/Route";
import EastIcon from "@mui/icons-material/East";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FlightIcon from "@mui/icons-material/Flight";
import LanguageIcon from "@mui/icons-material/Language";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { useEffect } from "react";
import { useState } from "react";
import axios from "axios";
import ConnectPeoplePopup from "../ConnectPeople/ConnectPeoplePopup";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const ExploreCarousle = () => {
  const [value, setValue] = React.useState(0);
  const navigate=useNavigate()

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const PreviousBtn = (props) => {
    const { onClick } = props;
    return (
      <div className="Prev_btn">
        <ArrowLeftRoundedIcon
          onClick={onClick}
          className="arrowleft"
          style={{ width: "6em", color: "#1ec", height: "6em", marginLeft: 65 }}
        />
      </div>
    );
  };
  const NextBtn = (props) => {
    const { onClick } = props;
    return (
      <div className="Next_btn">
        <ArrowRightRoundedIcon
          onClick={onClick}
          style={{
            width: "6em",
            color: "#1ec",
            height: "6em",
            marginRight: 65,
          }}
          className="arrowright"
        />
      </div>
    );
  };
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const [invitopen, setinvitopen] = React.useState(false);
  const handleinvitClose = () => {
    setinvitopen(false);
  };
  const [unitedKingdom, setUnitedKingdom] = useState([]);
  const [india, setIndia] = useState([]);
  const [unitedStates, setUnitedStates] = useState([]);

  const [closeIcon, setCloseicon] = useState(false);
  const [dataToPopup, setDataToPopup] = useState();

  const handleClick = (item) => {
    if (isLoggedIn) {
      setDataToPopup(item);
      setCloseicon(true);
    } else {
      setinvitopen(true);
    }
  };
  const handleClose = () => {
    {
      setCloseicon(false);
    }
  };
  const open = Boolean(closeIcon);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");
    axios
      .get(
        ` ${domain}/homepage/`
      )
      .then((response) => {
        console.log(response, "country trip response");

        console.log(
          response.data.explore_by_country[0],
          "country trip response data"
        );
        setUnitedKingdom(response.data.explore_by_country[0]["united kingdom"]);
        setIndia(response.data.explore_by_country[0].india);
        setUnitedStates(response.data.explore_by_country[0]["united states"]);

        console.log(
          response.data.explore_by_country[0]["United Kingdom"],
          "Explore BY country"
        );
      })
      .catch((error) => {
        // Error
        // console.log("status : ",error.response.status);
        // console.log("data : ",error.response.data);
        if (error.response.status === 400) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log("data : ",error.response.data);
          console.log("data : ", error.response.data.message);
          // console.log("status : ",error.response.status);
          // console.log("headers : ",error.response.headers);
        }
      });
  }, []);

  return (
    <>
      <div>
        <div style={{ marginTop: 50 }}>
          <Container>
            <Box className="explore-boxdiv">
              <Tabs
                value={value}
                onChange={handleChange}
                TabIndicatorProps={{
                  style: {
                    backgroundColor: "#ff8d8d",
                    height: 6,
                  },
                }}
              >
                   <Tab
                  className="explore-tabName-india"
                  label="India"
                  {...a11yProps(0)}
                />
                <Tab
                  className="explore-tabName-us"
                  label="United States"
                  {...a11yProps(1)}
                />
             
                <Tab
                  className="explore-tabName"
                  label="United Kingdom"
                  {...a11yProps(2)}
                />
              </Tabs>
            </Box>
          </Container>
          <div className="explore-tabPnelContainer">
            <TabPanel value={value} index={0}>
              {india.length > 0 ? (
                <Grid container spacing={2} className="explore-MainContainer">
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="explore-PostContainer"
                  >
                    {india.map((india, index) => (
                      <Card className="explore-PostsCard" key={india.id}>
                        {(india.category == 1 && india.user_type == 1) ||
                        (india.category == 1 && india.user_type == 2) ? (
                          <CardContent className="homePageContent">
                            <span>
                              <b className="latestPostBold">{india.name}</b> can
                              be your travel companion to{" "}
                              <b className="latestPostBold">
                                {india.destination.split(",")[0]}
                              </b>
                            </span>
                          </CardContent>
                        ) : india.category == 2 && india.user_type == 1 ? (
                          <CardContent className="homePageContent">
                            <span>
                              <b className="latestPostBold">{india.name}</b>{" "}
                              <span>is looking for travel companion to </span>
                              <span>
                                {" "}
                                <b className="latestPostBold">
                                  {india.destination.split(",")[0]}
                                </b>
                              </span>
                            </span>
                          </CardContent>
                        ) : (
                          <CardContent className="homePageContent">
                            <span>
                              <b className="latestPostBold">
                                {india.user_details.full_name}
                              </b>{" "}
                              <span>is looking for travel companion to </span>
                              <span>
                                {" "}
                                <b className="latestPostBold">
                                  {india.destination.split(",")[0]}
                                </b>{" "}
                                on behalf of{" "}
                                <b className="latestPostBold">{india.name}</b>
                              </span>
                            </span>
                          </CardContent>
                        )}

                        <div className="explore-homepageIMGContainer">
                          <div className="explore-latestpostsImage">
                            <img
                              className="explore-latestpostsIMG"
                              variant="top"
                              src={
                                india.photo
                                  ? india.photo
                                  : "./Assets/Images/Favicon 25 WB _ DV.webp"
                              }
                              alt="..."
                            />
                          </div>
                        </div>

                        <CardContent
                          style={{
                            fontSize: 15,
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <ModeOfTravelIcon
                                style={{
                                  color: "#1ec38b",
                                }}
                              ></ModeOfTravelIcon>
                              <div className="explore-card-places">
                                {india.trip_name}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <RouteIcon
                                style={{
                                  color: "#1ec28b",
                                  transform: "rotate(90deg)",
                                }}
                              />
                              <div className="explore-card-places">
                                {india.flying_from.split(",")[0]}
                                {india.transit1 ? (
                                  <span>
                                    <EastIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginBottom: "-7px",
                                      }}
                                    />
                                    {india.transit1.split(",")[0]}
                                  </span>
                                ) : null}
                                {india.transit2 ? (
                                  <span>
                                    <EastIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginBottom: "-7px",
                                      }}
                                    />
                                    {india.transit2.split(",")[0]}
                                  </span>
                                ) : null}
                                {india.transit3 ? (
                                  <span>
                                    <EastIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginBottom: "-7px",
                                      }}
                                    />
                                    {india.transit3.split(",")[0]}
                                  </span>
                                ) : null}
                                {india.transit4 ? (
                                  <span>
                                    <EastIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginBottom: "-7px",
                                      }}
                                    />
                                    {india.transit4.split(",")[0]}
                                  </span>
                                ) : null}
                                <EastIcon
                                  style={{
                                    color: "#1ec38b",
                                    marginBottom: "-7px",
                                  }}
                                />
                                {india.destination.split(",")[0]}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <CalendarMonthOutlinedIcon
                                style={{
                                  color: "#1ec38b",
                                }}
                              ></CalendarMonthOutlinedIcon>
                              <div className="explore-card-places">
                                {india.departing_on
                                  ? `Departing Date : ${india.departing_on}`
                                  : `Date Range :${india.date_range_from} to ${india.date_range_to}`}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <FlightIcon
                                style={{
                                  color: "#1ec38b",
                                }}
                              ></FlightIcon>
                              <div className="explore-card-places">
                                {india.booking_status == 1
                                  ? "Booked"
                                  : "Yet to book"}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <LanguageIcon
                                style={{
                                  color: "#1ec38b",
                                }}
                              ></LanguageIcon>
                              <div className="explore-card-places">
                                {india.prefered_language}
                              </div>
                            </Grid>

                            {india.category == 1 ? (
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                style={{ display: "flex", columnGap: 16 }}
                              >
                                <AttachMoneyIcon
                                  style={{ color: "#1ec38b" }}
                                ></AttachMoneyIcon>

                                <div className="explore-card-places">
                                  Tip I Expect -{/* {india.tip_expected} */}{" "}
                                  {/^([^0-9]*)$/.test(india.tip_expected)
                                    ? india.tip_expected
                                    : `${india.tip_expected} USD equiv`}
                                </div>
                              </Grid>
                            ) : null}
                          </Grid>
                        </CardContent>
                        <div style={{ marginTop: 70, marginBottom: 35 }}>
                          <Button
                            aria-describedby={id}
                            variant="contained"
                            onClick={() => handleClick(india)}
                            className="explore-homePageConnectBtn"
                          >
                            More Info
                          </Button>
                        </div>
                        <Modal
                          id={id}
                          open={open}
                          closeIcon={closeIcon}
                          style={{ zIndex: 1 }}
                        >
                          <ConnectPeoplePopup
                            onChildClick={handleClose}
                            connectUserData={dataToPopup}
                            page="connectpeople"
                          />
                        </Modal>
                      </Card>
                    ))}
                  </Grid>
                </Grid>
              ) : null}
            </TabPanel>

            <TabPanel value={value} index={2}>
              {unitedKingdom.length > 0 ? (
                <Grid container spacing={2} className="explore-MainContainer">
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="explore-PostContainer"
                  >
                    {unitedKingdom.map((uk, index) => (
                      <Card className="explore-PostsCard" key={uk.id}>
                        {/* <CardContent style={{ textAlign: "initial" }}>
                      <b className="explore-contentCat">{uk.name}</b>
                    </CardContent> */}

                        {(uk.category == 1 && uk.user_type == 1) ||
                        (uk.category == 1 && uk.user_type == 2) ? (
                          <CardContent className="homePageContent">
                            <span>
                              <b className="latestPostBold">{uk.name}</b> can be
                              your travel companion to{" "}
                              <b className="latestPostBold">
                                {uk.destination.split(",")[0]}
                              </b>
                            </span>
                          </CardContent>
                        ) : uk.category == 2 && uk.user_type == 1 ? (
                          <CardContent className="homePageContent">
                            <span>
                              <b className="latestPostBold">{uk.name}</b>{" "}
                              <span>is looking for travel companion to </span>
                              <span>
                                {" "}
                                <b className="latestPostBold">
                                  {uk.destination.split(",")[0]}
                                </b>
                              </span>
                            </span>
                          </CardContent>
                        ) : (
                          <CardContent className="homePageContent">
                            <span>
                              <b className="latestPostBold">
                                {uk.user_details.full_name}
                              </b>{" "}
                              <span>is looking for travel companion to </span>
                              <span>
                                {" "}
                                <b className="latestPostBold">
                                  {uk.destination.split(",")[0]}
                                </b>{" "}
                                on behalf of{" "}
                                <b className="latestPostBold">{uk.name}</b>
                              </span>
                            </span>
                          </CardContent>
                        )}

                        {/* {uk.category == 2 ? (
                        <CardContent className="explore-homePageContent">
                          <p>
                            “Is looking for a travel companion to{" "}
                            {uk.destination.split(",")[0]}”
                          </p>
                        </CardContent>
                      ) : (
                        <CardContent className="explore-homePageContent">
                          <p>
                            “Can be your travel companion to{" "}
                            {uk.destination.split(",")[0]}”
                          </p>
                        </CardContent>
                      )} */}

                        <div className="explore-homepageIMGContainer">
                          <div className="explore-latestpostsImage">
                            <img
                              className="explore-latestpostsIMG"
                              variant="top"
                              src={
                                uk.photo
                                  ? uk.photo
                                  : "./Assets/Images/Favicon 25 WB _ DV.webp"
                              }
                              alt="..."
                            />
                          </div>
                        </div>

                        <CardContent
                          style={{
                            fontSize: 15,
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <ModeOfTravelIcon
                                style={{
                                  color: "#1ec38b",
                                }}
                              ></ModeOfTravelIcon>
                              <div className="explore-card-places">
                                {uk.trip_name}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <RouteIcon
                                style={{
                                  color: "#1ec28b",
                                  transform: "rotate(90deg)",
                                }}
                              />
                              <div className="explore-card-places">
                                {uk.flying_from.split(",")[0]}
                                {uk.transit1 ? (
                                  <span>
                                    <EastIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginBottom: "-7px",
                                      }}
                                    />
                                    {uk.transit1.split(",")[0]}
                                  </span>
                                ) : null}
                                {uk.transit2 ? (
                                  <span>
                                    <EastIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginBottom: "-7px",
                                      }}
                                    />
                                    {uk.transit2.split(",")[0]}
                                  </span>
                                ) : null}
                                {uk.transit3 ? (
                                  <span>
                                    <EastIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginBottom: "-7px",
                                      }}
                                    />
                                    {uk.transit3.split(",")[0]}
                                  </span>
                                ) : null}
                                {uk.transit4 ? (
                                  <span>
                                    <EastIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginBottom: "-7px",
                                      }}
                                    />
                                    {uk.transit4.split(",")[0]}
                                  </span>
                                ) : null}
                                <EastIcon
                                  style={{
                                    color: "#1ec38b",
                                    marginBottom: "-7px",
                                  }}
                                />
                                {uk.destination.split(",")[0]}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <CalendarMonthOutlinedIcon
                                style={{
                                  color: "#1ec38b",
                                }}
                              ></CalendarMonthOutlinedIcon>
                              <div className="explore-card-places">
                                {uk.departing_on
                                  ? `Departing Date : ${uk.departing_on}`
                                  : `Date Range : ${uk.date_range_from} to ${uk.date_range_to}`}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <FlightIcon
                                style={{
                                  color: "#1ec38b",
                                }}
                              ></FlightIcon>
                              <div className="explore-card-places">
                                {uk.booking_status == 1
                                  ? "Booked"
                                  : "Yet to book"}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <LanguageIcon
                                style={{
                                  color: "#1ec38b",
                                }}
                              ></LanguageIcon>
                              <div className="explore-card-places">
                                {uk.prefered_language}
                              </div>
                            </Grid>

                            {uk.category == 1 ? (
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                style={{ display: "flex", columnGap: 16 }}
                              >
                                <AttachMoneyIcon
                                  style={{ color: "#1ec38b" }}
                                ></AttachMoneyIcon>

                                <div className="explore-card-places">
                                  Tip I Expect -{/* {uk.tip_expected} */}{" "}
                                  {/^([^0-9]*)$/.test(uk.tip_expected)
                                    ? uk.tip_expected
                                    : `${uk.tip_expected} USD equiv`}
                                </div>
                              </Grid>
                            ) : null}
                          </Grid>
                        </CardContent>
                        <div style={{ marginTop: 70, marginBottom: 35 }}>
                          <Button
                            aria-describedby={id}
                            variant="contained"
                            onClick={() => handleClick(uk)}
                            className="explore-homePageConnectBtn"
                          >
                            More Info
                          </Button>
                        </div>

                        <Modal
                          id={id}
                          open={open}
                          closeIcon={closeIcon}
                          style={{ zIndex: 1 }}
                        >
                          <ConnectPeoplePopup
                            onChildClick={handleClose}
                            connectUserData={dataToPopup}
                            page="connectpeople"
                          />
                        </Modal>
                      </Card>
                    ))}
                  </Grid>
                </Grid>
              ) : null}
            </TabPanel>

            <TabPanel value={value} index={1}>
              {unitedStates.length > 0 ? (
                <Grid container spacing={2} className="explore-MainContainer">
                  <Grid
                    item
                    xl={12}
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="explore-PostContainer"
                  >
                    {unitedStates.map((us, index) => (
                      <Card className="explore-PostsCard" key={us.id}>
                        {(us.category == 1 && us.user_type == 1) ||
                        (us.category == 1 && us.user_type == 2) ? (
                          <CardContent className="homePageContent">
                            <span>
                              <b className="latestPostBold">{us.name}</b> can be
                              your travel companion to{" "}
                              <b className="latestPostBold">
                                {us.destination.split(",")[0]}
                              </b>
                            </span>
                          </CardContent>
                        ) : us.category == 2 && us.user_type == 1 ? (
                          <CardContent className="homePageContent">
                            <span>
                              <b className="latestPostBold">{us.name}</b>
                              <span> is looking for travel companion to </span>
                              <span>
                                {" "}
                                <b className="latestPostBold">
                                  {us.destination.split(",")[0]}
                                </b>
                              </span>
                            </span>
                          </CardContent>
                        ) : (
                          <CardContent className="homePageContent">
                            <span>
                              <b className="latestPostBold">
                                {us.user_details.full_name}
                              </b>{" "}
                              <span>is looking for travel companion to </span>
                              <span>
                                {" "}
                                <b className="latestPostBold">
                                  {us.destination.split(",")[0]}
                                </b>{" "}
                                on behalf of{" "}
                                <b className="latestPostBold">{us.name}</b>
                              </span>
                            </span>
                          </CardContent>
                        )}
                        <div className="explore-homepageIMGContainer">
                          <div className="explore-latestpostsImage">
                            <img
                              className="explore-latestpostsIMG"
                              variant="top"
                              src={
                                us.photo
                                  ? us.photo
                                  : "./Assets/Images/Favicon 25 WB _ DV.webp"
                              }
                              alt="..."
                            />
                          </div>
                        </div>

                        <CardContent
                          style={{
                            fontSize: 15,
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <ModeOfTravelIcon
                                style={{
                                  color: "#1ec38b",
                                }}
                              ></ModeOfTravelIcon>
                              <div className="explore-card-places">
                                {us.trip_name}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <RouteIcon
                                style={{
                                  color: "#1ec28b",
                                  transform: "rotate(90deg)",
                                }}
                              />
                              <div className="explore-card-places">
                                {us.flying_from.split(",")[0]}
                                {us.transit1 ? (
                                  <span>
                                    <EastIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginBottom: "-7px",
                                      }}
                                    />
                                    {us.transit1.split(",")[0]}
                                  </span>
                                ) : null}
                                {us.transit2 ? (
                                  <span>
                                    <EastIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginBottom: "-7px",
                                      }}
                                    />
                                    {us.transit2.split(",")[0]}
                                  </span>
                                ) : null}
                                {us.transit3 ? (
                                  <span>
                                    <EastIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginBottom: "-7px",
                                      }}
                                    />
                                    {us.transit3.split(",")[0]}
                                  </span>
                                ) : null}
                                {us.transit4 ? (
                                  <span>
                                    <EastIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginBottom: "-7px",
                                      }}
                                    />
                                    {us.transit4.split(",")[0]}
                                  </span>
                                ) : null}
                                <EastIcon
                                  style={{
                                    color: "#1ec38b",
                                    marginBottom: "-7px",
                                  }}
                                />
                                {us.destination.split(",")[0]}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <CalendarMonthOutlinedIcon
                                style={{
                                  color: "#1ec38b",
                                }}
                              ></CalendarMonthOutlinedIcon>
                              <div className="explore-card-places">
                                {us.departing_on
                                  ? `Departing Date : ${us.departing_on}`
                                  : `Date Range : ${us.date_range_from} to ${us.date_range_to}`}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <FlightIcon
                                style={{
                                  color: "#1ec38b",
                                }}
                              ></FlightIcon>
                              <div className="explore-card-places">
                                {us.booking_status == 1
                                  ? "Booked"
                                  : "Yet to book"}
                              </div>
                            </Grid>
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <LanguageIcon
                                style={{
                                  color: "#1ec38b",
                                }}
                              ></LanguageIcon>
                              <div className="explore-card-places">
                                {us.prefered_language}
                              </div>
                            </Grid>

                            {us.category == 1 ? (
                              <Grid
                                item
                                xl={12}
                                lg={12}
                                md={12}
                                sm={12}
                                xs={12}
                                style={{ display: "flex", columnGap: 16 }}
                              >
                                <AttachMoneyIcon
                                  style={{ color: "#1ec38b" }}
                                ></AttachMoneyIcon>

                                <div className="explore-card-places">
                                  Tip I Expect -{/* {us.tip_expected} */}{" "}
                                  {/^([^0-9]*)$/.test(us.tip_expected)
                                    ? us.tip_expected
                                    : `${us.tip_expected} USD equiv`}
                                </div>
                              </Grid>
                            ) : null}
                          </Grid>
                        </CardContent>
                        <div style={{ marginTop: 70, marginBottom: 35 }}>
                          <Button
                            aria-describedby={id}
                            variant="contained"
                            onClick={() => handleClick(us)}
                            className="explore-homePageConnectBtn"
                          >
                            More Info
                          </Button>
                        </div>
                        <Modal
                          id={id}
                          open={open}
                          closeIcon={closeIcon}
                          style={{ zIndex: 1 }}
                        >
                          <ConnectPeoplePopup
                            onChildClick={handleClose}
                            connectUserData={dataToPopup}
                            page="connectpeople"
                          />
                        </Modal>
                      </Card>
                    ))}
                  </Grid>
                </Grid>
              ) : null}
              
            </TabPanel>
            <button className="home-explore" onClick={()=>{
              navigate('/companion-search')
            }}>EXPLORE MORE</button>
          </div>
        </div>
      </div>
      <div>
     
        <Container>
          <Dialog
            open={invitopen}
            onClose={handleinvitClose}
            // {/* // aria-labelledby="alert-dialog-title"
            // // aria-describedby="alert-dialog-description" */}
            PaperProps={{
              style: {
                minHeight: "40vh",
                minWidth: "35vw",
                boxShadow: "none",
                border: "2px solid rgb(128 213 184 / 100%)",
                strokeWidth: "1px",
                stroke: "rgb(128 213 184 )",
                borderRadius: 20,
              },
            }}
            BackdropProps={{
              style: {
                backdrop: "blur(2px)",
              },
            }}
          >
            <div>
              <DialogTitle id="alert-dialog-title">
                {/* {handleinvitClose ? (
<IconButton
aria-label="close"
onClick={handleinvitClose}
sx={{
position: 'absolute',
right: 8,
top: 8,
color: (theme) => theme.palette.grey[500],
}}
>
<CloseIcon />
</IconButton>
) : null} */}
                {/* <div className="invitation-upcoming-delete-dalog">
      <Icon
        className="invitation-deleteConfirmationIcon"
        icon="fluent:delete-12-filled"
      />
      Delete
    </div> */}
              </DialogTitle>
              <DialogContent className="invitation-upcoming-delete-dalog">
                <h2 className="invitation-areUSure" style={{ color: "red" }}>
                  {" "}
                  Please login for More Info
                </h2>
              </DialogContent>
              <DialogActions className="invitation-buttonsContainer">
                {/* <Button
      id="btn"
      className="invitation-yesButton"
      onClick={() => {
        settingdeleteinvitation();
      }}
    >
      Yes
    </Button> */}
                <Button
                  className="invitation-noButton"
                  onClick={handleinvitClose}
                >
                  OK
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </Container>
      </div>
    </>
  );
};

export default ExploreCarousle;