import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import "../../CSS/pastTripPopup.css";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
// import Captcha from "demos-react-captcha";

import {
  Autocomplete,
  Box,
  Button,
  Card,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  IconButton,
  InputLabel,
  inputLabelClasses,
  MenuItem,
  Radio,
  Select,
  Stack,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { pink } from "@mui/material/colors";
import { Controller, useForm } from "react-hook-form";
import PhotoCameraBackIcon from "@mui/icons-material/PhotoCameraBack";

import { yupResolver } from "@hookform/resolvers/yup";
import Swal from "sweetalert2";
import axios from "axios";
import Captcha from "demos-react-captcha";
import { Icon } from "@iconify/react";
import { Navigate } from "react-router-dom";
function PastTripPopup(props) {
  console.log(props.createTripid, "12344");
  const validationSchema = Yup.object().shape({
    trip_photos:Yup.mixed().required("Trip Photo is required"),
    whats_on_ur_mind: Yup.string()
      .required("whats on your mind is required")
      .matches(/^[a-zA-Z ]*$/, "special characters and numbers are not allowed")
      .min(5, "whats on your mind must be at least 5 characters")
      .max(50, "whats on your mind must be at most 15 characters"),
    // Captcha: Yup.string().required("Enter Captcha"),
    Captcha:Yup.bool().oneOf([true],"Enter Captcha"),
    acceptTerms: Yup.string().required("Need to agree terms and conditions"),
  });

  const {
    register,
    setValue,
    control,
    getValues,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const imageUploader = (id) => {
    const fileInput = document.getElementById(id);
    fileInput.click();
  };
  const watchFields = watch();
  const values = getValues();
  console.log("errors", errors);
  console.log("values..", values);
  const deleteimg = (name) => {
    setValue(name, "");
  };

  const onSubmit = (values) => {
    console.log(values, "values past trip id");

    // let finalVal = {};
    // finalVal["user_id"] = 2;
    // const newdata = new FormData();

    // Object.entries(finalVal).forEach(([key, value]) =>
    //   newdata.append(key, value)
    // );
    // console.log(finalVal, "finalVal");
    let finaldata = values;
    if (values.trip_photos) {
      const newdata = new FormData();
      newdata.append("trip_id", props.createTripid);
      newdata.append("trip_photos", values.trip_photos);
      newdata.append("whats_on_ur_mind", values.whats_on_ur_mind);
      finaldata = newdata;
    }
    console.log(finaldata, "finaldata pasttrip");

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .post(
        // `${domain}/create-trip/${props.createTripid}/`,
        `${domain}/companion-selfie-post/`,

        finaldata
      )
      .then((response) => {
        console.log(response, "response");
        if (values)
          Swal.fire({
            // title: "Thanks for your enquiry",
            text: "Memory is updated",
            icon: "success",
            buttons: {
              ok: {
                text: "Yes",
                value: "yes",
              },
              cancel: "No",
            },
          });
        else Swal.fire("Hi", "Please enter values", "error");
      })
      .catch((error) => {
        console.log(error.values);
      });
  };

  //   axios
  //   .patch(
  //     `http://ec2-54-185-6-32.us-west-2.compute.amazonaws.com:81/user/profile/${user.user_id}/`,

  //     file != null
  //       ? formData
  //       : {
  //           full_name: values.full_name,
  //           age_group: values.age_group,
  //           gender: values.gender,
  //           country: values.country,
  //           mobile_number: values.mobile_number,
  //           country_code: values.country_code,
  //           state: values.state,
  //           email_id: values.email_id,
  //           about: values.about,
  //         }

  //   )
  //   .then((response) => {
  //     console.log(response, "update profile response");

  //     if (response.data) {
  //       console.log(response.data, " response update profile");
  //       setResponseMessage(response.data.message);
  //     }
  //   })
  //   .catch((error) => {
  //     // Error
  //     if (error.response.status === 400) {
  //       // The request was made and the server responded with a status code
  //       // that falls out of the range of 2xx
  //       console.log(error.response.data, "error");
  //       console.log(error.response.status);
  //       console.log(error.response.headers);
  //       setResponseMessage(null);
  //     }
  //   });
  // };

  return (
    <div className="pastTripPopup-modalBackground">
      <div className="pastTripPopup-modalContainer">
        {/* <div className="pastTripPopup-titleCloseBtn">
          <button onClick={props.setOpenModal}>X</button>
        </div>

        <h4 className="pastTripPopupHeading">Upload your Trip Memories</h4>
        <div className="pastTripPopuphrlines"></div> */}
        <Grid container spacing={2}>
          <Grid
            item
            xl={9}
            lg={9}
            md={9}
            sm={9}
            xs={9}
            className="pastTripPopupinfo-text"
          >
            Upload your Trip Memories
          </Grid>
          <Grid
            item
            xl={3}
            lg={3}
            md={3}
            sm={3}
            xs={3}
            style={{ textAlign: "end" }}
          >
            <div className="pastTripPopup-titleCloseBtn">
              <button onClick={props.setOpenModal}>X</button>
            </div>
          </Grid>
        </Grid>
        <div className="pastTripPopuphrlines"></div>
        <form style={{ marginTop: "1rem" }} onSubmit={handleSubmit(onSubmit)}>
          <div className="">
            <Grid container spacing={2} className="pastTripPopupPageContainer">
              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <div className="pastTripPopupUpload">
                  {!values.trip_photos ? (
                    <div className="pastTripPopupUploadinnerCard">
                      <div className="pastTripPopupUpcontainer">
                        <div onClick={() => imageUploader("trip_photos")}>
                          <Icon
                            className="past-trip-popup-image-add-icon"
                            icon="mdi:image-add"
                            width={32}
                            height={32}
                          />

                          <Controller
                            name="trip_photos"
                            control={control}
                            render={({ field: { onChange, value, name } }) => (
                              <input
                                accept="image/*"
                                id="trip_photos"
                                hidden={true}
                                type="file"
                                // style={{display:"none"}}
                                onChange={(e) => {
                                  console.log(e.target.files[0], "pricture");
                                  setValue("trip_photos", e.target.files[0]);
                                }}
                              />
                            )}
                          />
                        </div>

                        {/* <div className="pastTripPopupinner"> */}
                        {/* <Controller
                          name="trip_photos"
                          control={control}
                          render={({ field: { onChange, value, name } }) => (
                            <input
                              accept="image/*"
                              id="icon-button-file"
                              type="file"
                              // style={{display:"none"}}
                              onChange={(e) => {
                                console.log(e.target.files[0], "pricture");
                                setValue("trip_photos", e.target.files[0]);
                              }}
                            />
                          )}
                        /> */}
                        {/* </div> */}
                      </div>
                      <div className="past-trip-popup-add-photo">Add Photo</div>
                      <div className="past-trip-popup-drag-drop">
                        or drag and drop
                      </div>
                    </div>
                  ) : (
                    <>
                      <div>
                        <div className="past-trips-img-div">
                          <Icon
                            icon="icons8:cancel"
                            onClick={() => deleteimg("trip_photos")}
                          />
                        </div>
                      </div>
                      <img
                        src={
                          typeof values.trip_photos === "object"
                            ? URL.createObjectURL(values.trip_photos)
                            : values.trip_photos
                        }
                        {...register("trip_photos")}
                        error={errors.trip_photos ? true : false}
                        className="past-trip-popup-img"
                        height={100}
                        width={100}
                      />
                    </>
                  )}

                  <div></div>
                </div>
                <Typography variant="inherit" color="red" textAlign="start">
                  {errors.trip_photos && errors.trip_photos.message}
                </Typography>
              </Grid>

              <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                <div>
                  <TextareaAutosize
                    minRows={3}
                    placeholder="Whats on your mind??"
                    className="pastTripPopupdescription"
                    id="whats_on_ur_mind
                    "
                    name="whats_on_ur_mind
                    "
                    {...register("whats_on_ur_mind")}
                    error={errors.whats_on_ur_mind ? true : false}
                  />
                </div>
                <Typography variant="inherit" color="red" textAlign="start">
                  {errors.whats_on_ur_mind && errors.whats_on_ur_mind.message}
                </Typography>
              </Grid>

              {/* <div className="pastTripPopupCaptchaMain">
                <div className="pastTripPopupCaptchaText">Captcha</div>
                <div className="pastTripPopupCaptcha">
                  <Captcha
                    onChange={(e) => onChange1(e)}
                    placeholder="Enter Captcha here"
                    length={6}
                    name="captha"
                  />
                </div>
              </div> */}
            </Grid>
            <Grid container spacing={2} className="pastTripPopupCaptchaMain">
              <Grid item xs={11} className="pastTripPopupCaptcha">
                <Typography
                  color={errors.acceptTerms ? "error" : "inherit"}
                  className="pastTripPopupCaptchaText"
                >
                  Captcha
                </Typography>

                <FormControlLabel
                  control={
                    <Controller
                      id="Captcha"
                      control={control}
                      name="acceptTerms"
                      defaultValue="false"
                      {...register("Captcha")}
                      error={errors.Captcha ? true : false}
                      inputRef={register()}
                      render={({ field: { onChange } }) => (
                        <Captcha
                          onChange={(e) => onChange(e)}
                          placeholder="Enter Captcha here"
                          length={6}
                        />
                      )}
                    />
                  }
                />

                <br />
              </Grid>
              <Typography
                variant="inherit"
                color="red"
                textAlign="start"
                className="error-message"
              >
                {errors.Captcha && errors.Captcha.message}
              </Typography>
            </Grid>

            <div className="pastTripPopupBox">
              <FormGroup className="pastTripPopupCheckBox">
                <FormControlLabel
                  control={
                    <Controller
                      control={control}
                      name="acceptTerms"
                      defaultValue="false"
                      {...register("acceptTerms")}
                      error={errors.acceptTerms ? true : false}
                      inputRef={register()}
                      render={({ field: { onChange } }) => (
                        <Checkbox
                          style={{ color: "#1ec28b" }}
                          onChange={(e) => onChange(e.target.checked)}
                        />
                      )}
                    />
                  }
                />
              </FormGroup>
              <div className="pastTripPopupBoxText">
                By clicking, you are confirming that you have read, understood
                and agree{" "}
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  style={{ textDecoration: "none" }}
                >
                  {" "}
                  Terms and Conditions.
                </a>
                <Typography
                  variant="inherit"
                  color="red"
                  textAlign="start"
                  className="error-message"
                >
                  {errors.acceptTerms && errors.acceptTerms.message}
                </Typography>
              </div>
            </div>

            <div>
              <Button
                className="pastTripPopupBtn"
                type="submit"
              >
                Post
              </Button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default PastTripPopup;