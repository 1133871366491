import React, { useEffect, useState } from "react";
import Adminsidebar from "./Adminsidebar";
import "../../src/AdminDashboard/CSS/usertrip.css";
import {
  Box,
  Breadcrumbs,
  Button,
  Card,
  Collapse,
  Container,
  FormControlLabel,
  Grid,
  styled,
  IconButton,
  InputLabel,
  Link,
  List,
  ListItemButton,
  ListItemText,
  MenuItem,
  Modal,
  Pagination,
  Stack,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import { Spa, Style, TextFields } from "@mui/icons-material";
import Autocomplete from "@mui/material/Autocomplete";
import { Icon } from "@iconify/react";
import { orange } from "@mui/material/colors";
import { Breadcrumb, Form, FormControl } from "react-bootstrap";
import Slider from "react-slick";
import usePagination from "@mui/material/usePagination/usePagination";
import { color } from "@mui/system";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import axios from "axios";
import Filter from "./Usertripfilter";
import Usertrippopup from "./Usertrippopup";
import { Formik } from "formik";
import moment from "moment";
import tcicon from "./Images/Favicon 25 WB _ DV.webp";
import Usertripfilter from "./Usertripfilter";
import { useNavigate } from "react-router-dom";
import { getTripdata } from "../Pages/CreateTripNew/CreateTripApiCall";
import AdminHeader from "./Components/AdminHeader";






import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// import moment from "moment/moment";
import {
  // Grid,
  // Select,
  // MenuItem,
  // InputLabel,
  InputAdornment,
  // IconButton,
  // Icon,
} from "@mui/material";

import { DatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import EventIcon from "@mui/icons-material/Event";
// import { Icon } from "@iconify/react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import "../AdminDashboard/CSS/userFilter.css";




function UserandTripDetails() {
  const Navigate = useNavigate();
  const [modalOpen, setModalOpen] = useState(false);
  const [createTripid, setCreateTripid] = useState({});
  const [tripimg, setTripImg] = useState({});
  const [tripimage, setTripImage] = useState({});
  const [open, setOpen] = React.useState(false);
  const [createAdtrTripid, setCreateAdtrTripid] = useState({});
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [searchValue, setSearchValue] = useState("");






  const [Country, setCountries] = useState([]);

  var today_2month = moment().add(2, "months");

  const today = new Date();

  const color = "#1ec28b";
  const [openFrom, setOpenFrom] = React.useState(false);
  const handleOpenFrom = () => setOpenFrom(true);
  const handleCloseFrom = () => setOpenFrom(false);
  const validationSchema = yup.object().shape({
    // age: yup.string().required("Age is required"),
    // // date: yup.string().required("Date is required"),
    // country: yup.string().required("Country is required"),
  });
  console.log(validationSchema, "validation");
  const {
    register,
    getValues,
    setValue,
    watch,
    reset,
    control,
    formState: { errors },
    handleSubmit,
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      age: "",
      gender: "",
      country: "",
    },
  });
  const values = getValues();
console.log(values,"values")
  let Age = [
    {
      label: "20-25",
      value: "20-25",
    },
    {
      label: "26-30",
      value: "26-30",
    },
    {
      label: "31-35",
      value: "31-35",
    },
    {
      label: "36-40",
      value: "36-40",
    },
   
    {
      label: "41-45",
      value: "41-45",
    },
    {
      label: "46-50",
      value: "46-50",
    },
    {
      label: "51-60",
      value: "51-60",
    },
    {
      label: "61-70",
      value: "61-70",
    },
    {
      label: "71-80",
      value: "71-80",
    },
  ];
  let Gender = [
    {
      label: "Male",
      value: "Male",
    },
    {
      label: "Female",
      value: "Female",
    }
  ];
  const getCountry =()=>{
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }



axios.get(`${domain}/country-code/`).then((response)=>{
  console.log(response,"countriesss")
  if(response)
  setCountries(response.data.response)
}).catch((error) => {
 
});

  }
   
const isSearching=()=>{
  let flag=false;
  Object.values(values).map((item)=>{
     if(item) flag=true
  })
 return (searchValue||flag)?true:false
}

useEffect(
 ()=>{ 
  getCountry();
},[])  


const onSubmit=(data)=>{

  let domain = "";
  console.log(process.env, "domain env", window.location.hostname);
  if (window.location.hostname.trim() === "localhost") {
    domain = process.env.REACT_APP_LOCAL_API;
  } else if (window.location.hostname.trim() === "54.185.6.32") {
    domain = process.env.REACT_APP_UAT_API;
  } else if (
    window.location.hostname.trim() === "uat.desitravelcompanion.com"
  ) {
    domain = process.env.REACT_APP_PROD_UAT_API;
  } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_API;
  } else if (
    window.location.hostname.trim() === "www.desitravelcompanion.com"
  ) {
    domain = process.env.REACT_APP_PROD_API;
  }


   axios
    .get(
      `${domain}/admin/overall/user/details/?country=${data.country}&search=${searchValue?searchValue:''}&age_group=${data.age}&gender=${data.gender}`
    )

    .then((response) => {
      console.log(response.data.results, "Usertripdetails filter");
      if(response.data.results){
      setTripImg(response.data.results);
      setData(response.data.results);
      setFilteredData(response.data.results);
      setData(response.data);
      console.log(data,"dataaa")
      // setData(response.data);
      reset()
      setModalOpen(!modalOpen);

      }else{
        setFilteredData(null);
        
        reset()
      setModalOpen(!modalOpen);
      }
    })
    .catch((error) => {});
  

  



}
  
  const CssTextField = styled(TextField)({
    "& label.Mui-focused": {
      color: "black",
    },
    "& .MuiSvgIcon-root": {
      color: "#1ec28b",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "#1ec28b",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#1ec28b",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#1ec28b",
      },
      "&:hover fieldset": {
        borderColor: "#1ec28b",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#1ec28b",
      },
    },
  });







  const viewTripimage = async () => {
    let pagenumber = 1;



    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }



    axios
      .get(
        isSearching()?`${domain}/admin/overall/user/details/?page=${currentPage}&search=${searchValue}&country=${values.country}&gender=${values.gender}&age_group=${values.age}`
        :`${domain}/admin/overall/user/details/?page=${currentPage}`
      )

      .then((response) => {
        console.log(response, "Usertripdetails");
        setTripImg(response.data.results);
        setData(response.data.results);
        setFilteredData(response.data.results);

        setData(response.data);
      })
      .catch((error) => {});
  };
  useEffect(() => {
    viewTripimage();
  }, [currentPage]);

  const handleSearch = (event) => {
    const { name, value } = event.target;
    setSearchValue(value);


    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }


     axios
      .get(
        `${domain}/admin/overall/user/details/?search=${searchValue}`
      )

      .then((response) => {
        console.log(response, "Usertripdetails search");
        if(response.data.results){
        setTripImg(response.data.results);
        setData(response.data.results);
        setFilteredData(response.data.results);
        setData(response.data);
        // setData(response.data);
        }else{
          setFilteredData(null);
        }
      })
      .catch((error) => {});
    
  
    

  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const [modalOpenAdtrpopInfo, setModalOpenAdtrpopInfo] = useState(false);
  const showModalAdtrpopInfo = () => {
    setModalOpenAdtrpopInfo(!modalOpenAdtrpopInfo);
  };

  const popup = (id) => {
    setModalOpenAdtrpopInfo(!modalOpenAdtrpopInfo);
    const profiledataAdtrpopInfo = data.filter((past) => {
      return past.id === id;
    });
    setCreateAdtrTripid(profiledataAdtrpopInfo);
  };

  const showModal = () => {
    setModalOpen(!modalOpen);
  };
console.log(modalOpen,"modalOpen")
  // const popupfilter = (id) => {
  //   setModalopenpopupfilter(!modalOpenpopupfilter);
  //   const profiledatapopfilterInfo = data.filter((past) => {
  //     return past.id === id;
  //   });
  //   setCreateAdtrTripid(profiledatapopfilterInfo);
  // };
  return (
    <><AdminHeader></AdminHeader><div className="user-trip-main">
      <div style={{ borderRight: "groove", minHeight: "90vh" }}>
        <Adminsidebar />
      </div>
      <div style={{ width: "100%" }}>
        <div className="user-trip-heading">User and Trip Details</div>
        <div className="user-subheading">
          <Container className="user-trip-subheading">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href=">">
                Home
              </Link>
              <Typography className="user-subheading">
                User and Trip Details
              </Typography>
            </Breadcrumbs>
          </Container>
        </div>

        <Card style={{ width: "95%", margin: "auto",height:"auto",minHeight: "377px" }}>
          <Container style={{paddingLeft:"0px",paddingRight: "3px"}}>
            <div className="user-search-main">
              <Container className="user-trip-search">
                <div>
                  <Grid container spacing={2}>
                    <Grid item xl={8} lg={8} md={8} xs={8} sm={8}>
                      <label htmlFor="search">
                        <TextField
                          className="user-trip-srch"
                          id="search"
                          variant="outlined"
                          placeholder="Search by Name"
                          name="name"
                          onChange={handleSearch}
                          fullWidth
                          inputProps={{
                            style: {
                              height: "2px",
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <Icon
                                onClick={()=>{viewTripimage()}}
                                className="admin-icon"
                                icon="ic:round-search" 
                                style={{cursor:"pointer"}}
                                />
                              
                            ),
                          }} />
                      </label>
                    </Grid>

                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      xs={4}
                      sm={4}
                      className="user-trip-status"
                    >
                      <div className="user-trip-filter">
                        <div>
                          <Icon
                            icon="bi:filter-right"
                            className="user-trip-stat"
                            onClick={() => {
                              setModalOpen(!modalOpen);
                            } } />
                        </div>
                        <div className="user-filter">Filters</div>
                      </div>
                    </Grid>
                  </Grid>
                </div>
              </Container>
            </div>
           

            <div className="">
              {modalOpenAdtrpopInfo && (
                <Usertrippopup
                  createAdtrTripid={createAdtrTripid[0]}
                  setOpenModalpopinfo={showModalAdtrpopInfo}
                  setTrip={viewTripimage} />
              )}
            </div>
          </Container>
       
        <div className="user-trip-modal">
              <div className="user-popupContainer">
                {modalOpen && (
                   <div className="admin-ModalBackground">
                   <div className="admin-ModalContainer">
                     <Container>
                       <form onSubmit={handleSubmit(onSubmit)}>
                         <div className="user-filter-main-desktop">
                           <div className="user-filter-second">
                             <div>
                               <Icon icon="bx:filter"  onClick={() => {
                              setModalOpen(!modalOpen);
                            } }  />
                             </div>
               
                             <div>Filters....</div>

                             <div>
                               <Icon style={{marginRight: "-120px",cursor:"pointer"}} icon="akar-icons:cross"  onClick={() => {
                              setModalOpen(!modalOpen);
                            } }  />
                             </div>
                           </div>
                           <div>
                             <div className="user-filterage">
                               <Icon className="user-icon-age" icon="octicon:people-24" />
               
                               <Autocomplete
                                 className="user-filter-age"
                                 id="language-select"
                                 options={Gender}
                                 name="gender"
                                 onChange={(e, value) => {
                                   console.log(value?value.label:'', "gender");
                                   setValue("gender",value? value.label:'');
                                 }}
                                //  {...register("age")}
                                //  error={errors.age ? true : false}

                                renderOption={(props, option) => (
                                  <Box
                                    className=""
                                    component="li"
                                    sx={{
                                      "& > img": { mr: 2, flexShrink: 0 },
                                    }}
                                    {...props}
                                  >
                                    {/* <img loading="lazy" width="20" src={option.logo} alt="" /> */}
                                    {option?option.value:''} 
                                  </Box>
                                )}

                                 getOptionLabel={(option) => option.label}
                                 renderInput={(params) => (
                                   <CssTextField
                                     className="user-filter-Age"
                                     {...params}
                                     label="Gender"
                                     varient="standard"
                                     inputProps={{
                                       style: {
                                         height: 1,
                                       },
                                       ...params.inputProps,
                                       autoComplete: "new-password",
                                     }}
                                   />
                                 )}
                               />
                             </div>
                             {/* <p className="user-validate-age">
                               {" "}
                               {errors.age && errors.age.message}
                             </p> */}
                           </div>
                           <hr />
                           <div>
                             <div className="user-filterage">
                               <Icon className="user-icon-age" icon="octicon:people-24" />
               
                               <Autocomplete
                                 className="user-filter-age"
                                 id="language-select"
                                 options={Age}
                                 name="age"
                                 onChange={(e, value) => {
                                   console.log(value?value.label:'', "age-group");
                                   setValue("age",value? value.label:'');
                                 }}
                                //  {...register("age")}
                                //  error={errors.age ? true : false}

                                renderOption={(props, option) => (
                                  <Box
                                    className=""
                                    component="li"
                                    sx={{
                                      "& > img": { mr: 2, flexShrink: 0 },
                                    }}
                                    {...props}
                                  >
                                    {/* <img loading="lazy" width="20" src={option.logo} alt="" /> */}
                                    {option?option.value:''} 
                                  </Box>
                                )}

                                 getOptionLabel={(option) => option.label}
                                 renderInput={(params) => (
                                   <CssTextField
                                     className="user-filter-Age"
                                     {...params}
                                     label="Age Group"
                                     varient="standard"
                                     inputProps={{
                                       style: {
                                         height: 1,
                                       },
                                       ...params.inputProps,
                                       autoComplete: "new-password",
                                     }}
                                   />
                                 )}
                               />
                             </div>
                             {/* <p className="user-validate-age">
                               {" "}
                               {errors.age && errors.age.message}
                             </p> */}
                           </div>
                           <hr />
                         
                         
                           
               
                           <div className="user-filter-Country">
                             <Icon
                               className="user-icon-country"
                               icon="ph:globe"
                               rotate={3}
                             />
                             <Autocomplete
                               className="userfilter-Country"
                               id="Country"
                               options={Country}
                               name="country"
                               onChange={(e, value) => {
                                 console.log(value?value.name:'', "countryvalue");
                                 setValue("country",value?value.name:'')
                               }}
                              //  {...register("country")}
                              //  error={errors.country ? true : false}
                               renderOption={(props, option) => (
                                 <Box
                                   className=""
                                   component="li"
                                   sx={{
                                     "& > img": { mr: 2, flexShrink: 0 },
                                   }}
                                   {...props}
                                 >
                                   {/* <img loading="lazy" width="20" src={option.logo} alt="" /> */}
                                   {option?option.name:''} 
                                 </Box>
                               )}
                               getOptionLabel={(option) => option.name}
                               renderInput={(params) => (
                                 <CssTextField
                                   className=""
                                   {...params}
                                   label="Country"
                                   varient="standard"
                                   inputProps={{
                                     ...params.inputProps,
                                     autoComplete: "new-password",
                                   }}
                                 />
                               )}
                             />
                           </div>
                           {/* <p className="user-validate-age">
                             {" "}
                             {errors.country && errors.country.message}
                           </p> */}
               
                           <hr />
               
                           <div>
                             <Button className="apply-filter-btn" type="submit">
                               Apply Filter
                             </Button>
                           </div>
                         </div>
                       </form>
                     </Container>
                   </div>
                 </div>
                   
                )}
              </div>
              {filteredData !==null ?  (
              <div className="tablee-container">
                <Container className="user-trip-tbl">
                  <table className="user-trip-table">
                    <tr className="user-trip-border ">
                      <th>Name & User Id</th>
                      {/* <th>Image</th> */}
                      <th>Age group</th>
                      <th>Country</th>
                      <th>Last Login</th>
                      <th>Action</th>
                    </tr>
                    <tbody>
                      {filteredData.map((item) => (
                        <>
                          <tr className="user-trip-border1">
                            <td className="user-table-name">
                              <div className="user-trip-dtls-img">
                                <div className="user-details-img">
                                  <img
                                    accept="jfif"
                                    src={item.profile_picture
                                      ? item.profile_picture
                                      : tcicon}
                                    className="user-trip-image" />
                                </div>
                                <div className="user-dtls-name">
                                  <b className="user-dtls-fulname">
                                    {item.full_name}
                                  </b>
                                  <div>{item.id}</div>
                                </div>
                              </div>
                            </td>
                            {/* <td >
              
            </td> */}

                            <td className="user-td-image">{item.age_group}</td>
                            <td className="user-description">{item.country}</td>

                            <td>
                              {moment(item.last_login).format(
                                "DD/MM/yyyy"
                              )}{" "}
                            </td>
                            <td className="admin-trip-cmicon">
                              <Icon
                                className="admin-trip-icon1"
                                icon="ic:round-remove-red-eye"
                                onClick={() => {
                                  console.log("running  useEffect");
                                  getTripdata(911, "user-trip").then(
                                    (response) => {
                                      // setDataToPopup(response);
                                      console.log(response, "responseinedit");
                                      Navigate(
                                        `/admin/Users-and-trips/${item.id}`,
                                        {
                                          state: {
                                            data: JSON.stringify(response),
                                          },
                                        }
                                      );
                                    }
                                  );
                                } } />
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                  {data && !(data.count <= 5) && (
                    <Pagination
                      className="admin-trip-bttn"
                      count={Math.round(data.count / 5)}
                      page={currentPage}
                      onChange={handlePageChange} />
                  )}
                </Container>
              </div>):(
                  <Container>
                    <div className="search-bar-no-results-found-main-div">
                      {" "}
                      <img
                        src="../../Assets/Images/Search illustration.jpg"
                        className="search-illustration-img"
                      ></img>
                      <div className="adsmanagement-no-results-found">
                        Ups!... no results found
                      </div>
                      <div>
                        No content matched your keyword. Please try searching
                        for something else
                      </div>
                    </div>
                  </Container>
                ) }
            </div>
            </Card>
      </div>
    </div>
    



   
    
    
    </>
    
  );
}

export default UserandTripDetails;