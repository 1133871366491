// import CountryJson from "../../json/Airports.json";
// import airlines from "../../json/Airlines.json";
// import languages from "../../json/languages.json";
import moment from "moment";

//transit------>`${option.city},${option.country}(${option.iata_code}-${option.name})`
//airline------>option.label
//flyFrom,destination------>`${option.city},${option.country}(${option.iata_code}-${option.name})`

export const RemoveExtraField = (data,step) => {
    const newData=data;
    if(newData.category==="2"){
        delete newData.tip_expected
        delete newData.can_assist_with
     }else{
        delete newData.assistance_needed
     }

     if(newData.booking_status==="2"||newData.booking_status===2){
        delete newData.airAndTrans
        delete newData.departing_on
        if(step && typeof newData.airline==="string"){
            delete newData.airline   
        }
     }else{
        delete newData.date_range_from
        delete newData.date_range_to

     }

     if(newData.photo===""||newData.photo===null || typeof newData.photo==="string"){
        if(!step)
            delete newData.photo
     }
     if(newData.trip_photos===""||newData.trip_photos===null|| typeof trip_photos==="string"){
        delete newData.trip_photos
     }
    //  trip_photos
    
return newData;
 
}

export const FormattingField=(value,step)=>{
    const formatted={}
    Object.entries(value).map((field, index) => {

        if (field[0] === "departing_on" ||field[0] === "date_range_from" || field[0] == "date_range_to") {
          if (field[1] !== "" && field[1] !== null) {
            formatted[`${field[0]}`] = field[1].format("YYYY-MM-DD");
          }
        } 
        else if (field[0] === "airAndTrans") {
            // if(step){
            //     field[1].map((transits, ind) => {
            //         transits[`airline`]=transits["airline"]
            //         transits[`transit`]=transits["transit"]
            // })
            // formatted[`${field[0]}`]=field[1]
            
                field[1].map((transits, ind) => {
                    formatted[`airline${ind+1}`]=airlineString(transits["airline"])
                    formatted[`transit${ind+1}`]=countryString(transits["transit"])
                  });

                  if(!step){
                    const array=new Array(4-field[1].length).fill("*")
                    array.map((elem,index)=>{
                        formatted[`airline${index+field[1].length+1}`]=""
                        formatted[`transit${index+field[1].length+1}`]=""   
                    })
                  }
            
         
        } 
        else if (field[0] === "prefered_language") {
         
          formatted[`${field[0]}`] = languageString(field[1]);
        } 
        else if(field[0]==="flying_from"||field[0]==="destination"){
            formatted[`${field[0]}`] =countryString(field[1]) ;
        }
        else if(field[0]==="airline"){
            formatted[`${field[0]}`] =airlineString(field[1]) ;
        }
        else{
          formatted[`${field[0]}`] = field[1];
      
        }
});

const isFile=(fileValue)=>{
    let flag
    if(typeof fileValue==="string"){
        flag=false
    }else{
        flag=true
    }
   return flag
}

let finalOne=formatted
if(!step){

    const photoIsfile=value.photo?isFile(value.photo):false
const trip_photoIsfile=value.trip_photos?isFile(value.trip_photos):false

if(photoIsfile||trip_photoIsfile){
const newdata = new FormData();
Object.entries(formatted).forEach(([key, value]) => {
       newdata.append(key, value);
});
finalOne=newdata
}else{
finalOne=formatted
}


}


      return finalOne

}

export const Formatting_Fulldetail=(data)=>{
    const formatted_full={}
    const array=[]
    Object.entries(data).map((item)=>{
       
        
        if(item[0]==="tip_expected"){
            if(/^([^0-9]*)$/.test(item[1])){
                formatted_full["tip_expected"]=item[1]
            }
            else{
                formatted_full["tip_expected"]=`${item[1]} USD equiv`
            }

        }
        else if(item[0]==="booking_status"){
            if(item[1]==="2"){
                formatted_full["booking_status"]="Yet to Book"
            }else{
                formatted_full["booking_status"]="Booked" 
            }
        }
        else if (item[0] === "departing_on" ||item[0] === "date_range_from" || item[0] == "date_range_to"){
            formatted_full[`${item[0]}`]=dateFormat(item[1])
        }
        else if (item[0] === "flying_from" ||item[0] === "destination"){
            formatted_full[`${item[0]}`]=item[1].split(",")[0]
        }
        else if(item[0].slice(0,7)==="transit"||item[0].slice(0,7)==="airline"){
         
            if(item[0].charAt(7)!==""){
              if(item[1]!==""){
                const number=parseInt(item[0].charAt(7))
                   
                // array[number-1]={...array[number-1],[`${item[0]}`]:item[1]}
                
                if(item[0].slice(0,7)==="transit"){
                 
                      array[number-1]={...array[number-1],[`${item[0].slice(0,7)}`]:item[1].split(",")[0]}
                      // defaultObj[`${field[0]}`]=country
                  
  
                }else{
                
                      array[number-1]={...array[number-1],[`${item[0].slice(0,7)}`]:item[1].split("(")[0]}
                      // defaultObj[`${field[0]}`]=airline
                 
                }
               
              }
                   
              // array[number][field[0]]=field[1]
            
            }
            else{
                formatted_full[`${item[0]}`]=item[1].split("(")[0]
             
  
            }
             
          }
         else{
        formatted_full[`${item[0]}`]=item[1]
         }
    })


    if(data.booking_status==="1"){
        const obj={
            flying_from:formatted_full["flying_from"],
            airline:formatted_full["airline"]
        }
        formatted_full["airAndTrans"]=[obj,...array]
        delete formatted_full.flying_from
        delete formatted_full.airline
    }else{
        delete formatted_full.airAndTrans

    }
   

    return formatted_full
}

const dateFormat=(dateString)=>{
    const s_date=dateString.split("-")
    // `${s_date[2]}/${s_date[1]}/${s_date[0]}`
    const newString=moment(dateString).format("DD-MMM-yyyy")
     return newString
}

const countryString=(option)=>{


    return `${option.city},${option.country}(${option.iata_code}-${option.name})`

}

export const countryObject=(coun_String)=>{
    console.log(coun_String,"coun_String")
    const splitComma=coun_String.split(",")
    const splitParan=splitComma[1].split("(")
    const splithypen=splitParan[1].split("-")

    let obj={
        city:coun_String.split(",")[0],
        country:splitParan[0],
        iata_code:splithypen[0],
        name:splithypen[1].substring(0,splithypen[1].length-1)
    }

    console.log(obj,"coun_String")

    return obj
}

export const airlineObject=(airline_String)=>{
    let label=airline_String;
   if(airline_String.includes("(")){
     let splitparem=airline_String.split("(")
    label=splitparem[0]
   }

    let obj={
      logo:"",
      label:label
    }

    console.log(obj,"coun_String")

    return obj
}

export const languageObject=(lang_String)=>{
   const array=lang_String.split(",")
   array.map((lang,ind)=>{
    array[ind]={name:lang}
   })
    return array
}
export const categryObject=(cat_string)=>{
    
    let obj={
      }
      if(cat_string==="2") {
        obj['label']='Looking for Travel Companion'
        obj['value']='2'
      }
      else{
        obj['label']='Available Travel Companion'
        obj['value']='1'
      }
     return obj
 }

const airlineString=(option)=>{
    let val
   if(typeof option==="object"){
    val= `${option.label}`
   }else{
    val=option
   }
    return val
}

const languageString=(option)=>{
    let languages = [];
    option.map((language, index) => {
      languages.push(language.name);
    });
    const afterJoin = languages.join(", ");
    return afterJoin
}

