import { ADMIN_SET_MESSAGE, ADMIN_CLEAR_MESSAGE } from "../AdminDashboard/AdminRedux/actions/types";
const initialState = {};
export default function (state = initialState, action) {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_SET_MESSAGE:
      return { admin_message: payload };
    case ADMIN_CLEAR_MESSAGE:
      return { admin_message: "" };
    default:
      return state;
  }
}