import { Icon } from "@iconify/react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  Card,
  Checkbox,
  Container,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import "../AdminDashboard/CSS/usertrip.css";
import axios from "axios";
import Adminsidebar from "./Adminsidebar";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AdminProfile from "./Images/Favicon 25 WB _ DV.webp";
// import ConnectPeoplePop from "../../src/AdminDashboard/ConnectPeoplepop";
// import ConnectPeoplePop from "../../src/AdminDashboard/ConnectPeoplepop";
import { Navigate, useNavigate } from "react-router-dom";
import { getTripdata } from "../Pages/CreateTripNew/CreateTripApiCall";
import { useParams, useLocation } from "react-router-dom";
import { useEffect } from "react";
import ConnectPeoplePopup from "../Pages/ConnectPeople/ConnectPeoplePopup";
import ConnectPop from "./ConnectPop";
import AdminHeader from "./Components/AdminHeader";

const Usertrippopup = (props) => {
  const { id } = useParams();
  // console.log("tripdetails", props.createTripid);
  const userdetails = () => {
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }

    axios
      .get(
        `${domain}/admin/user-trip/details/${id}/`
      )

      .then((response) => {
        // props.setOpenModalpopinfo(false);
        setData(response.data.User_Details);
        setAccordiandata(response.data.Trips_created);

        console.log(response.data, "Approve-response");
        console.log(response, "Reject-response");
      })
      .catch((error) => {});
  };
  useEffect(() => {
    userdetails();
  }, [id]);

  const { state } = useLocation();

  const handleCloseDetails = () => {
    {
      setCloseicon(false);
    }
  };
  // console.log(JSON.parse(state.data), "state");
  const Navigate = useNavigate();
  const [closeIcon, setCloseicon] = useState(false);
  const [dataToPopup, setDataToPopup] = useState();
  const [profiledata, setprofiledata] = useState({});
  const [modalOpen, setModalOpen] = useState(false);
  const [data, setData] = useState(JSON.parse(state.data));
  const [accordaindata, setAccordiandata] = useState([]);



  return (
    <><AdminHeader></AdminHeader><div className="user-trip-main">
      <div style={{ borderRight: "groove", minHeight: "90vh" }}>
        <Adminsidebar />
      </div>
      <div className="userpopup-margin">
        <div className="user-trip-heading">User and Trip Details</div>
        <div className="user-subheading">
          <Container className="user-trip-subheading">
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              <Link underline="hover" color="inherit" href=">">
                Home
              </Link>
              <Typography className="user-trip-subheading">
                User and Trip Details
              </Typography>
              <Typography className="user-subheading">{data && data.full_name}</Typography>
            </Breadcrumbs>
          </Container>
        </div>

        {data && (
          <Card className="userpopup-heading">
            <div className="user-filter-initial" style={{cursor: "pointer"}}  onClick={() => {
                    Navigate(`/admin/Users-and-trips`);
                  } }>
              <div>
                <Icon
                 
                  className="user-icon"
                  icon="material-symbols:arrow-back" />
              </div>
              <div style={{ width: "5%" }}>Back</div>
            </div>
            <Container>
              <div>
                <div className="user-popup-text1">
                  <img src={data.profile_picture?data.profile_picture:AdminProfile} className="usertrip-image" />
                  <div className="user-popup-text2">User</div>
                </div>
              </div>
              <div className="user-popup-id">User ID : {id}</div>
            </Container>
            <div className="user-popup-text">
              <div className="userpopup-name">
              <div style={{ display: "flex" }}> <div> Name </div> <div style={{marginLeft: "91px"}}>: {data.full_name}</div></div>
              <div style={{ display: "flex" }}>  <div>Age Group </div> <div style={{marginLeft: "60px"}}>: {data.age_group}</div></div>
              <div style={{ display: "flex" }}> <div>Gender </div> <div style={{marginLeft: "81px"}}>: {data.gender} </div></div>
              <div style={{ display: "flex" }}> <div>Country </div> <div style={{marginLeft: "79px"}}>: {data.country} </div></div>
              <div style={{ display: "flex" }}><div>State </div> <div style={{marginLeft: "97px"}}>: {data.state}</div></div>
              <div style={{ display: "flex" }}> <div>Mobile Number </div> <div style={{marginLeft: "30px"}}>: {data.country_code} {data.mobile_number}</div></div>
              <div style={{ display: "flex" }}> <div>Email Id </div> <div style={{marginLeft: "77px"}}>: {data.email}</div></div>
                <div className="user-pop-icon" style={{marginLeft: "139px"}}>
                 <div style={{paddingRight:"8px"}}><Icon icon="mdi:email-check" /></div> 
                  <div>Email Verified</div>
                </div>
              </div>
              <div style={{ width: "97%" }}>
               <div style={{ display: "flex" }}> <div>About</div>  <div style={{marginLeft: "131px"}}>: {data.about}</div></div>
               <div style={{ display: "flex"  }}><div>Created at </div>  <div style={{marginLeft: "100px"}}>: {data.created_at?data.created_at.substring(0, data.created_at.indexOf('T')):''}</div></div>
               <div style={{ display: "flex"  }}><div>Updated at </div>  <div style={{marginLeft: "100px"}}>: {data.updated_at?data.updated_at.substring(0, data.updated_at.indexOf('T')):''}</div></div>
               <div style={{ display: "flex"  }}><div>Last Login </div>  <div style={{marginLeft: "100px"}}>: {data.last_login?data.last_login.substring(0, data.last_login.indexOf('T')):''}</div></div>
               <div style={{ display: "flex"  }}><div>Last login IP address </div>  <div style={{marginLeft: "30px"}}>: {data.ip_address?data.ip_address:"---"}</div></div>
              </div>
            </div>
            <hr color="#1ec28b" width="95%" />
            <div className="user-popup-trip">Trip Details</div>
            <Card className="user-card">
              <div className="user-popup-card">
                <div style={{width: "-webkit-fill-available"}}>
                  <div className="userpopup-card">
                    <div className="userfilter-popup">
                      <div style={{ width: "22%" }}>User/Traveller</div>
                      <div>User type</div>
                      <div>Connect with</div>
                      <div>Trip Date</div>
                      <div>Details</div>
                    </div>

                    {accordaindata.map((trip) => {
                      return (
                        <Accordion className="usertrip-accordian" style={{paddingBottom: "21px"}}>
                          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                            <div className="user-trip-accordian">
                              <Typography>{trip.name}</Typography>
                              <Typography className="user-popup-usertype">
                                {trip.user_type === 1
                                  ? "i am a tc"
                                  : "need a tc"}
                              </Typography>
                              <Typography className="user-popup-person">
                                {trip.connected_trips_count} person
                              </Typography>
                              <Typography className="user-popup-date">
                                {/* 21 oct 2014 to 24 oct 2015 */}
                                {trip.departing_on
                    ? trip.departing_on
                    : `${trip.date_range_from} to ${trip.date_range_to}`}
                              </Typography>
                            </div>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Typography className="user-popup-textig">
                              <div className="user-popup-img">
                                <Container>
                                  <div>
                                    <img
                                      src={trip.photo?trip.photo:AdminProfile}
                                      className="user-trip-image1" />
                                  </div>
                                </Container>
                              </div>
                              <div className="user-popup-txt">
                              <div style={{ display: "flex" }}> <div>Traveler Name</div>  <div style={{marginLeft: "50px"}}>: {trip.name}</div></div>
                              <div style={{ display: "flex" }}> <div>Age Group</div>  <div style={{marginLeft: "73px"}}>: {trip.age_group}</div></div>
                              <div style={{ display: "flex" }}> <div>Gender</div>  <div style={{marginLeft: "92px"}}>: {trip.gender}</div></div>
                              <div style={{ display: "flex" }}> <div>Created on</div>  <div style={{marginLeft: "70px"}}>: {trip.created_on?trip.created_on.substring(0, trip.created_on.indexOf('T')):''}</div></div>
                              <div style={{ display: "flex" }}> <div>Edited on</div>  <div style={{marginLeft: "79px"}}>: {trip.updated_on?trip.updated_on.substring(0, trip.updated_on.indexOf('T')):''}</div></div>
                              <div style={{ display: "flex" }}> <div>User type</div>  <div style={{marginLeft: "77px"}}>: {trip.user_type === 1? "i am a tc": "need a tc"}</div></div>

                                {/* <div>Traveler Name : {trip.name}</div> */}
                                {/* <div>Age Group : {trip.age_group}</div> */}
                                {/* <div>Gender : {trip.gender}</div> */}
                                {/* <div>Created on : {trip.updated_on?trip.updated_on.substring(0, trip.updated_on.indexOf('T')):''}</div> */}
                                {/* <div>Edited on :{trip.created_on?trip.created_on.substring(0, trip.created_on.indexOf('T')):''}</div> */}
                                {/* <div>User type :  {trip.user_type === 1
                                  ? "i am a tc"
                                  : "need a tc"}</div> */}
                                <div>
                                  <div className="user-popup-conn">
                                    Connect with
                                  </div>
                                  <div>
                                    <List
                                      sx={{
                                        width: "100%",
                                        maxWidth: 360,
                                        bgcolor: "background.paper",
                                        color: "blue",
                                      }}
                                    >
                                      {trip.connected_trips.map((value) => {
                                        return (
                                          <ListItem
                                            className="user-trip-icon1"
                                            icon="ic:round-remove-red-eye"
                                            onClick={() => {
                                              console.log("running  useEffect");
                                              getTripdata(
                                                911,
                                                "user-trip"
                                              ).then((response) => {
                                                Navigate(
                                                  `/admin/Users-and-trips/${value.user_id}`,
                                                  {
                                                    state: {
                                                      data: JSON.stringify(
                                                        response
                                                      ),
                                                      
                                                    },
                                                    
                                                  }
                                                );
                                              });
                                            } }
                                            // onBlur={raloadpopup}
                                            disablePadding
                                          >
                                            <ListItemButton
                                              role={undefined}
                                              dense
                                            >
                                              <ListItemIcon>
                                                <Icon icon="icon-park:dot" />
                                              </ListItemIcon>
                                              <ListItemText
                                                // id={labelId}
                                                primary={value.name} 
                                                />
                                            </ListItemButton>
                                          </ListItem>
                                        );
                                      })}
                                    </List>
                                  </div>
                                </div>
                              </div>
                            </Typography>
                          </AccordionDetails>
                          <hr color="#1ec28b" width="95%" />
                          <div>
                            <ConnectPop
                              onChildClick={handleCloseDetails}
                              connectUserData={JSON.parse(state.data)}
                              userdetails={trip} />
                          </div>
                        </Accordion>
                      );
                    })}
                  </div>
                </div>
              </div>
            </Card>
          </Card>
        )}
      </div>
    </div></>
  );
};

export default Usertrippopup;
