import { Box, Container, Grid } from "@mui/material";
import Header from "../../Components/Header/Header";
import "../../CSS/Aboutus.css";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import React from "react";
import { Link } from "react-router-dom";
import ScrollTop from "../../ScrollTop";

function AboutUs() {
  return (
    <>
      <ScrollTop>
        <div>
          <Header />
        </div>
        <div>
          <Container>
            <Grid container spacing={2}>
              <Grid
                item
                lg={12}
                xs={12}
                md={12}
                sm={12}
                xl={12}
                className="aboutus-aboutusHeadingText"
              >
                <div className="aboutus-aboutusHeadingTextSub">
                  Hello, We are{" "}
                  <span style={{ color: "#1ec28b" }}>
                    Desi Travel Companion..
                  </span>
                </div>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="aboutus-firstdiv"
              >
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <img
                    className="aboutus-aboutGuideImg"
                    src="./Assets/Images/Image 1.webp"
                    alt="logo"
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="aboutus-desi-travel-text-container"
                >
                  <p className="aboutus-travelAboutusContent">
                    {/* Desi Travel Companion (Desi TC) is a free to use platform to
                    find Travel Companion to / from India, Pakistan, Bangladesh,
                    Sri Lanka, Nepal and beyond. */}
                    Desi Travel Companion (Desi TC) is a free to use platform to find Travel Companion / Travel Buddy to / from India, USA, UK, Canada, UAE, Australia, Malaysia, Singapore, Germany, New Zealand, Pakistan, Bangladesh, Sri Lanka, Nepal and beyond.
                  </p>
                </Grid>
              </Grid>

              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                lg={12}
                xl={12}
                className="aboutus-maindiv"
              >
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="aboutus-wercommited-text"
                >
                  <p className="aboutus-travelAboutusContent1">
                    We are committed to solve the desi community pain points one
                    at a time with the help of technology.
                  </p>
                  <p className="aboutus-travelAboutusContent11">
                    Desi Travel Companion is our solution to help the Global
                    Desi Community looking for a travel companion to abroad or
                    back home.
                  </p>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={12}
                  lg={12}
                  xl={12}
                  className="aboutus-image2-container"
                >
                  <img
                    className="aboutus-aboutPhotoClick"
                    src="./Assets/Images/Image 2.webp"
                    alt="logo"
                  />
                </Grid>
              </Grid>

              <Grid item lg={12} xs={12} md={12} sm={12} xl={12}>
                <p className="aboutus-travelAboutusContent-attempt-text">
                  Join our <span style={{ color: "#1ec28b" }}>attempt</span>, to
                  solve the problem of desi travel companion with your feedback,
                  inputs and support{", "}while we come up with more advance
                  features,
                  <span style={{ color: "#1ec28b" }}>contact us!!</span>
                </p>
              </Grid>

              <Grid
                item
                lg={12}
                xs={12}
                md={12}
                sm={12}
                xl={12}
                className="aboutus-joinourText"
              >
                <div>
                  <img
                    src="./Assets/Images/about_6.webp"
                    alt="images"
                    className="aboutus-contactus-IMG"
                  />
                </div>
                <div className="aboutus-btn">
                  <Link to="/contactus">
                    <button className="aboutus-aboutusContactBtn">
                    Contact Us{" "}
                      <KeyboardArrowRightIcon className="aboutus-leftArrowContactBtnIcon" />
                    </button>
                  </Link>
                </div>
              </Grid>
            </Grid>
          </Container>
        </div>
      </ScrollTop>
    </>
  );
}

export default AboutUs;
