import { Container, Grid, ListItem, ListItemText } from '@mui/material'
import React from 'react'
import StepCircle from '../Assets/Stepper/StepCircle'

const FirstCon = (props) => {
  const {fullDetail,keyArray}=props
 
    return (
     
      <Container>
        <div className="first_con_s4">
        <Grid 
          sx={{width:"65%" }}
          >
            <div>
            <ListItem component="div" disablePadding>
            <ListItemText 
                 primary="Traveler Details :"
                 sx={{ width:"50%"}}
                 className="text_style_full"
                  />
            </ListItem>
            {keyArray.map((field,index)=>{
              return(<div>
                {
                  fullDetail[`${field.key}`]&&
                  <ListItem key={index} component="div" disablePadding>
                  <ListItemText 
                  primary={`${field.label}`}  
                  sx={{ width:"50%"}}
                  className="text_style_full"
                   />
                  <ListItemText 
                  primary={`: ${fullDetail[`${field.key}`]}`} 
                  sx={{ width:"60%"}} 
                  className="text_style_full"/>
               </ListItem>
                }
              </div>
                
              
            
             )
            })
          }
            </div>
  
            <div>
       
            </div>
           
        </Grid>
        {fullDetail.photo&& <Grid >
            <StepCircle imageFile={fullDetail.photo}/>
        </Grid>} 
        </div>
        
       
   
      </Container>
    )
    
  
}

export default FirstCon




  
