import { Grid } from '@mui/material'
import React from 'react'
import DashboardHeader from '../Dashboard/DashboardHeader'
import '../CSS/termsConditions.css'
function TermsAndConditions() {
  return (
    <div>
    <DashboardHeader />
    
     <Grid container spacing={2}  className="Tandc-container">
       <Grid className='tandc'> Terms &amp; Conditions</Grid>
       <Grid > The TERMS OF SERVICE  (this “Agreement”) concern the online or
    digital properties, products and services described further below and
    constitute a legally binding contract between you (together with any
    person helping you visit, access, register with or use any of those online
    or digital properties, products or services, “you”) and Desi Travel
    Companion (“Desi TC”).</Grid>
    <Grid className='agreementIncludes'> Agreement includes:</Grid>
    
    </Grid>   
    </div>
  )
}

export default TermsAndConditions