import React, { useEffect } from "react";
import AdminHeader from "../Components/AdminHeader";
import Adminsidebar from "../Adminsidebar";
import Box from "@mui/material/Box";
import SelectComponent from "./Custom/SelectComponent";
import {
  Container,
  Breadcrumbs,
  Link,
  Typography,
  Card,
  Grid,
} from "@mui/material";
import dayjs from "dayjs";
import "../../AdminDashboard/CSS/registereduser.css";
import { useState } from "react";
import axios from "axios";
import YearRangePicker from "./Custom/YearRangePicker";
import { Bar, BarChart, LabelList, Legend, XAxis, YAxis,Tooltip} from "recharts";
import { Icon } from "@iconify/react";
import { Badge } from "antd";


var domain = "";
if (window.location.hostname.trim() === "localhost") {
  domain = process.env.REACT_APP_LOCAL_API;
} else if (window.location.hostname.trim() === "54.185.6.32") {
  domain = process.env.REACT_APP_UAT_API;
} else if (
  window.location.hostname.trim() === "uat.desitravelcompanion.com"
) {
  domain = process.env.REACT_APP_PROD_UAT_API;
} else if (window.location.hostname.trim() === "desitravelcompanion.com") {
  domain = process.env.REACT_APP_PROD_API;
} else if (
  window.location.hostname.trim() === "www.desitravelcompanion.com"
) {
  domain = process.env.REACT_APP_PROD_API;
}


function RegisteredUser() {
  const [heading, setHeading] = useState([
    
      {
        label: "All Countries",
        value: "registeredcountries_All Country -- Registered users",
      },
       {
        value: "topcountries_Top 5 registered countries",
        label: "Top 5 registered countries",
      },
     {
        value: "leastcountries_Least 5 registered countries",
        label: "Least 5 registered countries",
      },
    ]
  );
  const [selectInput, setSelectInput] = useState("registeredcountries_All Country -- Registered users");
  const [selected_Countries,set_Selected_Countries]=useState([])
  const [total_Register_users,set_total_Register_users]=useState()
  const [searchCountry,setSearchCountry]=useState()
  const [searchCountries,setSearchCountries]=useState()
  const [yearly, setYearly] = useState();
  const [toolTipContent,setTooltipContent]=useState()
  const currentYear=dayjs().format('YYYY')
  const [dates, setDates] = useState({
    yearly:currentYear
  })

  const noResult=(array,name)=>{
    let flag=false
   array.map((item)=>{
      if(item.value) flag=true
   }) 
   return flag
  }

  const CustomTooltips = ({ active, payload, label }) => {
      return (toolTipContent)?<div className="tooltip_custom-register">
        <div className="gender_count-register">
          {toolTipContent.value}
        </div>
        <div className="users-text">Users</div>
    </div>:null ;
    };
    const fillingData = (array, name, endYear,report_name) => {
      let objKeys=[];
          if(array.length>0)
              objKeys = Object.keys(array[0]);
      let endyear = parseInt(endYear);
      let newArray = array;
      Array(12 - array.length < 0 ? array.length : 12 - array.length)
        .fill("*")
        .map(() => {
          let obj = {};
          objKeys.map((item) => {
            obj[`${item}`] = "";
            if(item==='data_Female'||item==='data_Male'){
              obj[`${item}`] = []
            }
            if (item === name) {
              obj[`${item}`] = parseInt(endyear) + 1;
              endyear = endyear + 1;
            }
          });
          newArray.push(obj);
        });
        if(!noResult(newArray,report_name))
            newArray="No Results Found!"
        
      return newArray;
    };



  const showResults = (report_name) => {

    let domain = "";
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }

    if (!Array.isArray(dates[report_name])) {
      axios
        .get(
          `${domain}/admin/users-reports/?year=${dates[report_name]}&report=yearly_monthlywise`
        )
        .then((response) => {
          const datas = response.data["yearly_monthlywise"];
          let array = [];
          if (datas) {
                datas.data.map((item) => {
                  let obj = { 
                    name: item.month.slice(0,3), 
                    value: item.count!==0?item.count:"" };
                  array.push(obj);
                });
                const finalArray = fillingData(
                  array,
                  "name",
                  dates[report_name][1],
                  report_name
                );
                setYearly(finalArray);
              }
              if(!noResult(array,report_name))
                   array="No Results Found!"
          
        });
    } 
    else {
      axios
        .get(
          `${domain}/admin/users-reports/?report=yearly_monthlywise&start_year=${dates[report_name][0]}&end_year=${dates[report_name][1]}`
        )
        .then((response) => {
            if (response.data["yearly_monthlywise"]) {
              let data = response.data["yearly_monthlywise"];
              let array = [];
              data.data.map((item) => {
                let obj = { 
                  name: item.year, 
                  value: item.data[0].count 
                };
                array.push(obj);
              });
              const finalArray = fillingData(
                array,
                "name",
                dates[report_name][1],
                report_name
              );
              setYearly(finalArray);
            } else {
            }
           
        });
    }
  };



  useEffect(() => {
    if(selectInput)
    axios
      .get(
        `${domain}/admin/users-reports/?report=${selectInput.split("_")[0]}`
      )
      .then((response) => {
        set_Selected_Countries(response.data[selectInput.split("_")[0]]);
      }).catch((error) => {
      });;
  }, [selectInput]);



  useEffect(() => {
    axios
      .get(
        `${domain}/admin/users-reports/?report=countrywise&country=${searchCountry}`
      )
      .then((response) => {
        setSearchCountries(response.data.countrywise);
      }).catch((error) => {
      });
  }, [searchCountry]);


  useEffect(() => {
    showResults("yearly")
    axios
      .get(
        `${domain}/admin/users-reports/?report=totalregisteredusers`
      )
      .then((response) => {
       set_total_Register_users(response.data.totalregisteredusers);
      }).catch((error) => {
      });
  }, []);


  const handleChange = (searchValue) => {
    setSearchCountry(searchValue);
    setSelectInput("");
  };

  const SelecthandleChange = (event) => {
    const {value}=event.target
    setSelectInput(value);
    setSearchCountry("")
  };


  const getKeys=(key)=>{
    let att
    if(key.includes ("_of")){
      let key1=key.replace(/_of/g, ". of")
      let key2=key1.replace(/_/g, " ")
      att=key2
    }
    else{
      var numb = key.match(/\d/g);
      numb = numb.join("");
      let numb_split=`Age between ${numb.slice(0,2)} to ${numb.slice(2,4)} years`
      att=numb_split
    }
    return att

  }




  return (
    <div>
      {total_Register_users && (
        <div>
          <AdminHeader></AdminHeader>
          <div className="dbsql-profile-main">
            <div style={{ borderRight: "groove", minHeight: "100vh" }}>
              <Adminsidebar />
            </div>
            <div style={{ width: "100%", backgroundColor: "#f5f5f5" }}>
              <Container>
                <div className="registeredusers-heading">Reports</div>
                <div>
                  <Breadcrumbs separator="›" aria-label="breadcrumb">
                    <Link to={""} underline="hover" color="inherit" href=">">
                      Home
                    </Link>
                    <Typography className="registeredusers-head">
                      Reports - registered Users
                    </Typography>
                  </Breadcrumbs>
                </div>
                <Box className="registeredusers-box">
                  <div className="registeredusers-subhead">
                    Country-wise registered users
                  </div>
                  <div className="registeredusers-main-heads">
                    <Card className="registeredusers-card1">
                      <div className="cardFields">
                      <div className="registeredusers-search-place">
                        <div className="searchField-registeredUsers-con">
                          <input 
                          className="searchField-registeredUsers"
                          placeholder="Search by Country or Continent"
                          value={searchCountry}
                          onChange={(e) => handleChange(e.target.value)}
                          >
                          </input>
                          <Icon 
                          icon="mingcute:search-line" 
                          color="black" 
                          className="registeredUsers-search-icon"
                          />
                           <Icon 
                          icon="iconoir:cancel" 
                          className="registeredUsers-search-cancel-icon"
                          onClick={()=>{
                            setSearchCountry(null)
                          }}
                          />
                        </div>
                        </div>
                      <div className="registeredusers-dropdown-head">
                        <select
                          className="registeredusers-select-head"
                          style={{ borderColor: "#1ec28b", outline: "none" }}
                          value={selectInput?selectInput:""}
                          onChange={(e)=>SelecthandleChange(e)}
                        >
                          <option value="">Select</option>
                          {heading.map((item, index) => {
                            return (
                              <option value={item.value}>{item.label}</option>
                            );
                          })}
                        </select>

                        
                      </div>
                      </div>
                      <div className="registerCounts-Card">
                      <Card className="registeredusers-card2">
                          Total No. of Registered Users
                          <div className="registeredusers-count">
                            {" "}
                            {total_Register_users}{" "}
                          </div>
                        </Card>
                      </div>
                      
                      {(searchCountry&&searchCountries) && (
                        <Card className="Country_data_card">
                          <div className="Country_data_heading">
                            {searchCountry}
                          </div>
                          <div>
                            {
                            Object.entries(searchCountries).map((item)=>{
                              return (
                                <div className="Country_data">
                              <div className="Country_data_key">
                              {getKeys(item[0])}
                             </div>
                             <div className="Country_data_value">
                              <span>--</span>
                              <span>
                                {item[1]}
                              </span>
                            </div>
                              </div>
                              )
                              
                            })
                          }
                          </div>
                        </Card>
                      )}

                      {(selectInput&&selected_Countries.length>0) && (
                        <div className="registeredusers-card-three">
                              <Card className="registeredusers-card3">
                                <div className="registeredusers-options-heading">
                                  <div className="Heading-Select">
                                    <elementName
                                      className={`registeredusers-option-heading ${
                                        selectInput.value === "registeredcountries"
                                          ? "underline"
                                          : ""
                                      }`}
                                    >
                                      {selectInput.split("_")[1]}
                                    </elementName>
                                  </div>
                                </div>

                                <div
                                  className="registeredusers-regcountries"
                                  style={{
                                    maxHeight: "300px",
                                    overflowY: "auto",
                                    marginTop: "30px",
                                  }}
                                >
                                  { selected_Countries.length>0&&<>
                                  {selectInput.split("_")[0] === "registeredcountries"&&
                                   selected_Countries.map(
                                    (item) => (<>
                                    {item.country&&
                                    <Grid
                                    className="registeredusers-places"
                                    style={{ textAlign: "justify",paddingLeft:"1rem" }}
                                    key={item.id}
                                  >
                                    <div className="All-countryCount">
                                      {item.num_registrations}
                                    </div>
                                    <div className="Users-in">
                                      <span className="Users-in-text"> Users in</span>  {item.country}
                                    </div>
                                  </Grid>
                                    }
                                    </>
                                      
                                    )
                                  )}
                                  <div className="countbox-con">
                                  <div className="Top5-Grid">
                                    {(selectInput.split("_")[0] === "topcountries" ||
                                    selectInput.split("_")[0] === "leastcountries") &&
                                    selected_Countries.map(
                                      (item,i) => (<div>
                                        <Badge
                                           color="#1ec28b"
                                           count={i+1}
                                           placement="start"
                                           offset={[-103, 15]}
                                           sx={{fontWeight:"bold"}}
                                           className="badge-register-user"
                                        >
                                        <Top5_Count_box data={item}/>
                                        </Badge>
                                      </div>
                                      )
                                    )
                                   }
                                  </div>

                                  </div>
                                  </>}
                                </div>
                              </Card>
                        </div>
                      )}
                    </Card>
                  </div>
                  <Grid container style={{ display: "block", padding: 46 }} id="Chart32Pdf">
                <Grid item className="trip-heading">
                  <div
                    className="admindashboard-chart-heading"
                    style={{ fontSize: 20, display: "inline-block" }}
                  >
                    Yearly and Monthly reports
                  </div>
                  <div className="trips-export">
                    <SelectComponent idName="Chart32Pdf" data={yearly}/>
                  </div>
                </Grid>

             
                  <div className="trip-pieChart">
                    <div className="picker-con">
                      <YearRangePicker
                        settingState={setDates}
                        name="yearly"
                        place="select"
                        wholeYears={dates}
                      />
                      <p className="or_seperator"> OR </p>
                      <YearRangePicker
                      settingState={setDates}
                      name="yearly"
                      place="yearRange"
                      wholeYears={dates}
                    />
                    </div>
                    <div className="trips-btn-container">
                      <button
                        className="trips-btn"
                        onClick={() => {
                          showResults("yearly");
                        }}
                      >
                        Show results
                      </button>
                    </div>

                  { yearly&&(typeof yearly!=="string"&&yearly.length)? <div className="trip-piecharts userType-design">
                  <div className="left-label-reports">No. of register users   <Icon icon='tabler:arrow-right'/></div>
                    <div className="bottom-label-reports"> {(!Array.isArray(dates['yearly'])?`Year ${dates['yearly']}`:`Years ${dates['yearly'][0]} to ${dates['yearly'][1]}`) }<Icon icon='tabler:arrow-right'/></div>
                    <div className="squareBar"></div>
                      <BarChart width={550} height={350} data={yearly}>
                        <XAxis
                          dataKey="name"
                          tick={{ fontSize: 10,strokeWidth: 0,color:'#6c6c6c' }}
                          tickLine={false}
                          width={20}
                          scale="point"
                          padding={{ left: 40, right: 10 }}
                          axisLine={false}
                        />
                        <YAxis
                          tick={{ fontSize: 10,color:'#6c6c6c'}}
                          tickLine={false}
                          axisLine={false}
                          domain={[0, 'dataMax + 25']}
                          scale={"linear"}
                        />
                        <Tooltip content={<CustomTooltips/>}/>
                        <Bar
                          dataKey="value"
                          fill="#1ec28b"
                          maxBarSize={10}
                          margin={{ right: 0, left: 0 }}
                          legendType='square'
                          onMouseOver={(props)=>{
                            console.log(props.tooltipPayload,'tooltipProps')
                            let data=props.tooltipPayload[0]
                              setTooltipContent({
                                value:data.payload.value,
                              })
                        }}                         

                        >
                        <LabelList dataKey="self"  position="top" />
                          </Bar>
                      </BarChart>
                    </div>:<div>{yearly}</div>}
                  </div>
            
                  </Grid>
                </Box>
              </Container>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const Top5_Count_box=({data})=>{
  return <div
  className="count-box"
  key={data.id}
>
  <div className="countbox-count">
    {data.num_registrations}
  </div>
  <div className="countbox-usersin">
     Users in  
  </div>
  <div className="countbox-cName">{data.country}</div>
</div>
}

export default RegisteredUser;
