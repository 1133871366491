import React, { useState } from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import "../../CSS/pastTrip.css";
import {
  Button,
  Card,
  CardContent,
  Grid,
  Modal,
  Popover,
  Tooltip,
} from "@mui/material";
import SidebarLink from "../SidebarLink";
import EditProfile from "../EditProfile";
import DashboardHeader from "../DashboardHeader";
import ArrowCircleDownSharpIcon from "@mui/icons-material/ArrowCircleDownSharp";
import RouteIcon from "@mui/icons-material/Route";
import EastIcon from "@mui/icons-material/East";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FlightIcon from "@mui/icons-material/Flight";
import LanguageIcon from "@mui/icons-material/Language";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import PastTripPopup from "./PastTripPopup";
import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ConnectPeoplePopup from "../../Pages/ConnectPeople/ConnectPeoplePopup";
import { Icon } from "@iconify/react";
import PastTripPopinfo from "./PastTripPopinfo";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Navigate, useNavigate } from "react-router-dom";

const PastTrip = () => {
  const [modalOpen, setModalOpen] = useState(false);
  const [createTripid, setCreateTripid] = useState({});
  // const [c, setc] = useState("");
  const [profiledata, setprofiledata] = useState({});
  const showModal = () => {
    setModalOpen(!modalOpen);
  };

  const [modalOpenpopInfo, setModalOpenpopInfo] = useState(false);
  const [createTripidpopInfo, setCreateTripidpopInfo] = useState();
  const [pastTriperror, setpastTriperror] = useState();
  // const [c, setc] = useState("");
  // const [profiledatapopInfo, setprofiledatapopInfo] = useState({});
  const showModalpopInfo = () => {
    setModalOpenpopInfo(!modalOpenpopInfo);
  };
  const [pastTrip, setpastTrip] = useState([]);
  const popup = (id) => {
    setModalOpenpopInfo(!modalOpenpopInfo);
    const profiledatapopInfo = pastTrip.filter((past) => {
      return past.id === id;
    });
    setCreateTripid(profiledatapopInfo);
  };

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  console.log("user_id", user.user_id);

  useEffect(() => {

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");
	

    axios
      .get(
        `${domain}/past-trips/${user.user_id}/`
      )
      .then((response) => {
        console.log(response, "past trip response");

        if (response.data) {
          console.log(response.data, "past trip response data");
          console.log(response.data.message, "1234");
          setpastTrip(response.data);
          // setpastTrip(response.data).message;
        }
      })
      .catch((error) => {
        // Error
        // console.log("status : ",error.response.status);
        // console.log("data : ",error.response.data);
        if (error.response.status === 400) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log("data : ",error.response.data);
          console.log("data : ", error.response.data.message);
          setpastTriperror(error.response.data.message);
          // console.log("status : ",error.response.status);
          // console.log("headers : ",error.response.headers);
        }
      });
  }, []);

  const [showpastTripid, setshowpastTripid] = React.useState();
  const [closeIcon, setCloseicon] = useState(false);
  const [dataToPopup, setDataToPopup] = useState();
  const handleClick = (item, data) => {
    setDataToPopup(item);
    setCloseicon(true);
    setprofiledata(data);
  };
  const handleClose = () => {
    {
      setCloseicon(false);
    }
  };
  const open = Boolean(closeIcon);
  const id = open ? "simple-popover" : undefined;

  // ------------------------- cards for load more result  --------------------------------------------
  const imagePerRow = 2;
  const [next, setNext] = useState(imagePerRow);
  const handleMoreImage = () => {
    setNext(next + imagePerRow);
  };


  const navigate = useNavigate();
  useEffect(
     ()=>{if(user.update_profile_details!=="False"){
     return navigate('/edit-profile',{state:'true'})
    }}
  ,[]
  )

  // ------------------for displaying message while hover on eye icon------------------
  // if(user.update_profile_details=="False"){

  return (
    <>
      <DashboardHeader></DashboardHeader>
      <div className="pasttripdashboard-main">
        <div className="pastrip-dashboardside">
          <Box
            sx={{
              width: 350,
              height: 600,
              backgroundColor: "",
              "&:hover": {
                backgroundColor: "aliceblue",
              },
            }}
          >
            <div>
              {/* <div>Dashboard</div>
                <div>Setting</div>
                <div>Logout</div> */}
              <SidebarLink></SidebarLink>
            </div>
          </Box>
        </div>
        <div className="pasttrip-maincontainer">
          <div>
            <Box className="pastdashbox">
              <div>
                <p className="pasttriprecieved-text">Past Trips</p>
              </div>

              <div className="gap-y-4 flex flex-wrap justify-center">
                {/* {pastTrip.map((post) => ( */}
                {pastTrip.slice(0, next).map((post, index) => {
                  return (
                    <Card className="pasttripcard-past-trip1">
                      <CardContent>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            style={{ textAlign: "initial" }}
                          >
                            {post.name}
                          </Grid>
                          {/* </Grid>
                      <Grid container spacing={2}> */}
                          <Grid
                            item
                            xl={6}
                            lg={6}
                            md={6}
                            sm={6}
                            xs={6}
                            style={{ textAlign: "end" }}
                          >
                            <Tooltip title="view memories">
                              <Icon
                                icon="ic:outline-remove-red-eye"
                                // id={id}
                                onClick={() => popup(post.id)}
                                style={{ color: "#1ec28b" }}
                              />
                            </Tooltip>
                          </Grid>
                        </Grid>
                      </CardContent>
                      {/* <div className="card-recentcontainer"> */}
                      <div className="pasttripcard-past-trip-inner">
                        <img
                          className="pasttripcardrecent-img"
                          variant="top"
                          // src="./Assets/Images/I1.webp"
                          src={
                            post.photo == null
                              ? "./Assets/Images/Favicon 25 WB _ DV.webp"
                              : post.photo
                          }
                          alt="..."
                        />
                      </div>
                      {/* </div> */}

                      {/* <CardContent
                      style={{
                        fontSize: 12,
                        // width: "max-content",
                        lineHeight: "25px",
                      }}
                    >
                      <Grid container style={{ marginTop: 10, marginLeft: 22 }}>
                       
                        <span
                          // item xs={2}
                          style={{}}
                        >
                          <RouteIcon
                            style={{
                              color: "#1ec28b",
                              transform: "rotate(90deg)",
                              marginBottom: "-7px",
                              marginLeft: "-34px",
                            }}
                          />{" "}
                        
                          {post.flying_from}
                          <EastIcon
                            style={{
                              color: "#1ec38b",
                              marginBottom: "-7px",
                            }}
                          />{" "}
                        
                          {post.transit}
                          <EastIcon
                            style={{
                              color: "#1ec38b",
                              marginBottom: "-7px",
                            }}
                          />{" "}
                         
                          {post.destination}
                        </span>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: 10, marginLeft: 0 }}
                      >
                       
                        <span
                        // style={{marginLeft: "15px"}}
                        >
                          {" "}
                          <CalendarMonthOutlinedIcon
                            style={{
                              color: "#1ec38b",
                              marginBottom: "-5px",
                              marginRight: "4px",
                            }}
                          ></CalendarMonthOutlinedIcon>
                          {post.booking_status === 2
                            ? `Date Range - ${post.date_range_from} to ${post.date_range_to}`
                            : `Departing on ${post.departing_on}`}
                          
                        </span>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: 10, marginLeft: 0 }}
                      >
                        
                        <span
                        // style={{marginLeft: "15px"}}
                        >
                          {" "}
                          <FlightIcon
                            style={{
                              color: "#1ec38b",
                              marginBottom: "-5px",
                              marginRight: "4px",
                            }}
                          ></FlightIcon>{" "}
                         
                          {post.booking_status === 2 ? "yet to book" : "booked"}
                        </span>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: 10, marginLeft: 0 }}
                      >
                        
                        <span
                        // style={{marginLeft: "15px"}}
                        >
                          <LanguageIcon
                            style={{
                              color: "#1ec38b",
                              marginBottom: "-5px",
                              marginRight: "4px",
                            }}
                          ></LanguageIcon>{" "}
                          {post.prefered_language}
                          
                        </span>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: 10, marginLeft: 0 }}
                      >
                        
                        <span
                        // style={{marginLeft: "15px"}}
                        >
                          <ModeOfTravelIcon
                            style={{
                              color: "#1ec38b",
                              marginBottom: "-5px",
                              marginRight: "4px",
                            }}
                          ></ModeOfTravelIcon>
                    
                          {post.trip_name}
                        </span>
                      </Grid>
                    </CardContent> */}
                      <CardContent
                        style={{
                          fontSize: 12,
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex", columnGap: 16 }}
                          >
                            <ModeOfTravelIcon
                              style={{
                                color: "#1ec38b",
                                marginTop: 4,
                              }}
                            ></ModeOfTravelIcon>
                            <div className="pasttrip-card-places">
                              {post.trip_name}
                            </div>
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex", columnGap: 16 }}
                          >
                            <RouteIcon
                              style={{
                                color: "#1ec28b",
                                transform: "rotate(90deg)",
                                marginTop: 4,
                              }}
                            />
                            <div className="pasttrip-card-places">
                              {/* ----------------------------------- */}
                              {/* {post.flying_from} */}
                              {post.flying_from.split(",")[0]}
                              {post.transit1 ? (
                                <span>
                                  <EastIcon
                                    style={{
                                      color: "#1ec38b",
                                      marginBottom: "-7px",
                                    }}
                                  />
                                  {post.transit1.split(",")[0]}
                                </span>
                              ) : null}
                              {post.transit2 ? (
                                <span>
                                  <EastIcon
                                    style={{
                                      color: "#1ec38b",
                                      marginBottom: "-7px",
                                    }}
                                  />
                                  {post.transit2.split(",")[0]}
                                </span>
                              ) : null}
                              {post.transit3 ? (
                                <span>
                                  <EastIcon
                                    style={{
                                      color: "#1ec38b",
                                      marginBottom: "-7px",
                                    }}
                                  />
                                  {post.transit3.split(",")[0]}
                                </span>
                              ) : null}
                              {post.transit4 ? (
                                <span>
                                  <EastIcon
                                    style={{
                                      color: "#1ec38b",
                                      marginBottom: "-7px",
                                    }}
                                  />
                                  {post.transit4.split(",")[0]}
                                </span>
                              ) : null}
                              <EastIcon
                                style={{
                                  color: "#1ec38b",
                                  marginBottom: "-7px",
                                }}
                              />
                              {post.destination.split(",")[0]}
                              {/* {post.destination} */}
                              {/* ----------------------------------- */}
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex", columnGap: 16 }}
                          >
                            <CalendarMonthOutlinedIcon
                              style={{
                                color: "#1ec38b",
                                marginTop: 4,
                              }}
                            ></CalendarMonthOutlinedIcon>
                            <div className="pasttrip-card-places">
                              {post.booking_status === 2
                                ? `Date Range : ${post.date_range_from} to ${post.date_range_to}`
                                : `Departing on : ${post.departing_on}`}
                            </div>{" "}
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex", columnGap: 16 }}
                          >
                            <FlightIcon
                              style={{
                                color: "#1ec38b",
                                marginTop: 4,
                              }}
                            ></FlightIcon>
                            <div className="pasttrip-card-places">
                              {post.booking_status === 2
                                ? "yet to book"
                                : "booked"}
                            </div>
                          </Grid>
                        </Grid>
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xs={12}
                            style={{ display: "flex", columnGap: 16 }}
                          >
                            <LanguageIcon
                              style={{
                                color: "#1ec38b",
                                marginTop: 4,
                              }}
                            ></LanguageIcon>{" "}
                            <div className="pasttrip-card-places">
                              {post.prefered_language}
                            </div>
                          </Grid>
                        </Grid>

                        {post.category == 1 ? (
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <AttachMoneyIcon
                                style={{ color: "#1ec38b", marginTop: 4 }}
                              ></AttachMoneyIcon>
                              <div className="pasttrip-card-places">
                                {post.tip_expected}
                              </div>
                            </Grid>
                          </Grid>
                        ) : null}
                        {/* </Grid> */}
                      </CardContent>
                      <Button
                        className="pasttripaccept-btn"
                        onClick={() => handleClick(post)}
                      >
                        Details
                      </Button>
                      <Modal id={id} open={open} closeIcon={closeIcon}>
                        <ConnectPeoplePopup
                          onChildClick={handleClose}
                          connectUserData={dataToPopup}
                          userdetails={profiledata}
                          page="pasttrip"
                        />
                      </Modal>
                      {/* <Button className="cancel-btn">Cancel</Button> */}
                      <Grid style={{ marginTop: 20, marginBottom: 20 }}>
                        <Button
                          id={id}
                          // onClick={showModal}
                          onClick={() => {
                            setModalOpen(!modalOpen);
                            setCreateTripid(post.id);
                          }}
                          variant="outlined"
                          sx={{
                            color: "black",
                            borderColor: "#1ec28b",
                            borderRadius: "18px",
                            fontSize: "11px",
                          }}
                        >
                          UPLOAD YOUR MEMORIES
                        </Button>
                      </Grid>
                    </Card>

                    // ))}
                  );
                })}
                 <div>{pastTriperror}</div>
              </div>
              {modalOpen && (
                <PastTripPopup
                  createTripid={createTripid}
                  setOpenModal={showModal}
                />
              )}
              {modalOpenpopInfo && (
                <PastTripPopinfo
                  createTripid={createTripid[0]}
                  setOpenModalpopInfo={showModalpopInfo}
                />
              )}

              {next < pastTrip.length && (
                <div
                  onClick={handleMoreImage}
                  className="pasttripaccept-load-more-btn"
                >
                  Load more
                  <span>
                    <ArrowCircleDownSharpIcon
                      style={{ marginTop: -1, fontSize: 20 }}
                    />
                  </span>
                </div>
              )}
            </Box>
          </div>
          <div>
            <EditProfile></EditProfile>
          </div>
        </div>
      </div>
    </>
  );

// }else{

//   return <Navigate replace to={"/edit-profile"}></Navigate>
// }
};

export default PastTrip;
