import { Icon } from "@iconify/react";
import AdminPanelSettingsOutlinedIcon from '@mui/icons-material/AdminPanelSettingsOutlined';import {
  Button,
  Collapse,
  // Collapse,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  // ListSubheader,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { NavLink, Navigate, useNavigate } from "react-router-dom";
import { logout } from "./AdminRedux/actions/auth";
import { useDispatch, useSelector } from "react-redux";
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import ErrorOutlineSharpIcon from '@mui/icons-material/ErrorOutlineSharp';
import "./CSS/logoutpopup.css"
import { ExpandLess, ExpandMore } from "@mui/icons-material";
const Adminsidebar = () => {
  const gototop=()=>{
    // alert("clicked")
    window.scrollTo(0, 0)
   }

  const [open, setOpen] = React.useState(false);
  // const [deleteId, setDeleteId] = useState();
  const [canCreate,setCancreate]=useState()
  const [deleteopen, setdeleteOpen] = React.useState(false);
  const [SettingState, setSettingState] = React.useState(false);
  const [MemberState, setMemberState] = React.useState(false);
  const [tripsState, settripsState] = React.useState(false);


  // const [authentication, setAuthentication] = React.useState(false);
  const [dashboard, setdashboard] = React.useState(false);

  const dashboardMenu = () => {
    setdashboard(!dashboard);
  };
  // const authenticateMenu = () => {
  //   setAuthentication(!authentication);
  // };
  const dispatch = useDispatch();

  const logOut = () => {
    dispatch(logout());
  };
  
  const { isadminLoggedIn,admin } = useSelector((state) => state.admin_auth);

  if(!isadminLoggedIn){
    return <Navigate replace to="/admin/login" />;
  
  }


 

  const handleClickOpen = () => {
    // console.log(id, "id");
    setOpen(true);
    // setDeleteId(id);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const handledeleteClose = () => {
    setdeleteOpen(false);
  };
  // const navigate = useNavigate();
  // useEffect(
  //    ()=>{if(admin.redirect_to_change_password =="true"){
  //    return navigate('/admin/change-password')
  //   }}
  // ,[]
  // )


  const SettingMenu = () => {
    setSettingState(!SettingState);
  };

  const MemberMenu = () => {
    setMemberState(!MemberState);
  };
  const TripsMenu = () => {
    settripsState(!tripsState);
  };

  return (
    <div style={{marginBottom:"10px"}}>
      <Container>
        <List
          sx={{ maxWidth: 350 }}
          component="nav"
          aria-labelledby="nested-list-subheader"
        >
       {admin.user_type=="3"?'': <ListItemButton
            onClick={dashboardMenu}
            className="sidebar-auth-main"
            component={NavLink}
            to="/admin/dashboard"
          >
            <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
              <Icon
                icon="ic:outline-space-dashboard"
                style={{ color: "#2278e9" }}
              />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItemButton>}
          <ListItemButton
            component={NavLink}
            to="/admin/trip-image-approval"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
              {/* <Icon
                icon="ant-design:setting-outlined"
                style={{ color: "#2278e9" }}
              /> */}
             <Icon icon="mdi:image-outline" style={{ color: "#2278e9" }}/>
            </ListItemIcon>

            <ListItemText primary="Trip Approval" />
          </ListItemButton>
          <ListItemButton
            component={NavLink}
            to="/admin/users-and-trips"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
              {/* <Icon
                icon="ant-design:setting-outlined"
                style={{ color: "#2278e9" }}
              /> */}
<Icon icon="heroicons:rectangle-stack" style={{ color: "#2278e9" }}/>    
        </ListItemIcon>

            <ListItemText primary="Users and Trip Details" />
          </ListItemButton>
          <ListItemButton
            component={NavLink}
            to="/admin/companion-selfie-approval"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
              {/* <Icon
                icon="ant-design:setting-outlined"
                style={{ color: "#2278e9" }}
              /> */}
              <Icon icon="uil:selfie" style={{ color: "#2278e9" }}/>
            </ListItemIcon>
            <ListItemText primary="Companion Selfie Approval" />
          </ListItemButton>
          {admin.user_type=="3"?'': <ListItemButton
            component={NavLink}
            to="/admin/admin-ads-management"
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
              {/* <Icon
                // icon="ant-design:setting-outlined"
                icon="icon-park-outline:setting-web"
                style={{ color: "#2278e9" }}
              /> */}
              <Icon icon="icon-park-outline:setting-web" style={{ color: "#2278e9" }}/>
            </ListItemIcon>
            <ListItemText primary="Ads Management" />
          </ListItemButton>}


     

          <ListItemButton
            // component={NavLink}
            // to={{}}
            onClick={SettingMenu}
            // className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
              <Icon
                icon="ant-design:setting-outlined"
                style={{ color: "#2278e9" }}
              />
            </ListItemIcon>

            <ListItemText primary="Settings" />

            {SettingState ? (
                <ExpandLess className="sidebaricon" />
              ) : (
                <ExpandMore className="sidebaricon" />
              )}
          </ListItemButton>
          <Collapse in={SettingState} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/upcoming-trips"
                > */}
                  <ListItemButton
                    component={NavLink}
                    className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/admin/profile"
                    // sx={{ pl: 12 }}
                  >
                    <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
                      {/* <StarBorder /> */}
                    <Icon icon="iconamoon:profile" style={{ color: "#2278e9" }}/>
                    </ListItemIcon>
                    <ListItemText primary="My Profile" />
                  </ListItemButton>
                
                  <ListItemButton
                    component={NavLink}
                    className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/admin/change-password"
                    // sx={{ pl: 12 }}
                  >
                    <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
                      {/* <StarBorder /> */}
                      <Icon icon="solar:key-linear"  style={{ color: "#2278e9" }}/>
                    </ListItemIcon>
                    <ListItemText primary="Change Password" />
                  </ListItemButton>
                {/* </NavLink> */}
              </List>
            </Collapse>





            {admin.user_type=="3"?'':    <ListItemButton
            // component={NavLink}
            // to={{}}
            onClick={MemberMenu}
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
              {/* <Icon
                icon="ant-design:setting-outlined"
                style={{ color: "#2278e9" }}
              /> */}
              <AdminPanelSettingsOutlinedIcon style={{ color: "#2278e9" }}></AdminPanelSettingsOutlinedIcon>
            </ListItemIcon>

            <ListItemText primary="Members" />

            {MemberState ? (
                <ExpandLess className="sidebaricon" />
              ) : (
                <ExpandMore className="sidebaricon" />
              )}
          </ListItemButton>}
          <Collapse in={MemberState} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
              
                  <ListItemButton
                    component={NavLink}
                    className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/admin/add-member"
                    // sx={{ pl: 12 }}
                  >
                    <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
                      {/* <StarBorder /> */}
                    <Icon icon="mingcute:user-add-fill" style={{ color: "#2278e9" }}/>
                    </ListItemIcon>
                    <ListItemText primary="Add Member" />
                  </ListItemButton>
                
                  <ListItemButton
                    component={NavLink}
                    className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/admin/manage-Members"
                    // sx={{ pl: 12 }}
                  >
                    <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
                      {/* <StarBorder /> */}
                    <Icon icon="fa-solid:user-cog" style={{ color: "#2278e9" }}/>
                    </ListItemIcon>
                    <ListItemText primary="Manage Members" />
                  </ListItemButton>
                {/* </NavLink> */}
              </List>
            </Collapse>







            {admin.user_type=="3"?'':    <ListItemButton
            // component={NavLink}
            // to={{}}
            onClick={TripsMenu}
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
              {/* <Icon
                icon="ant-design:setting-outlined"
                style={{ color: "#2278e9" }}
              /> */}
              <Icon icon="bi:bar-chart"  style={{ color: "#2278e9" }}/>
            </ListItemIcon>

            <ListItemText primary="Reports" />

            {tripsState ? (
                <ExpandLess className="sidebaricon" />
              ) : (
                <ExpandMore className="sidebaricon" />
              )}
          </ListItemButton>}
          <Collapse in={tripsState} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
              
                  <ListItemButton
                    component={NavLink}
                    className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/admin/registered-users"
                    // sx={{ pl: 12 }}
                  >
                    <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
                      {/* <StarBorder /> */}
                      <Icon icon="ci:users-group" style={{ color: "#2278e9" }}/>
                      </ListItemIcon>
                    <ListItemText primary="Registered Users" />
                  </ListItemButton>
                
                  <ListItemButton
                    component={NavLink}
                    className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/admin/trips"
                    // sx={{ pl: 12 }}
                  >
                    <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
                      {/* <StarBorder /> */}
                      <Icon icon="bx:trip" style={{ color: "#2278e9" }}/>
                    </ListItemIcon>
                    <ListItemText primary="Trips" />
                  </ListItemButton>
                {/* </NavLink> */}
              </List>
            </Collapse>







            <ListItemButton
            // component={NavLink}
            // to=""
            onClick={handleClickOpen}
            className={({ isActive }) => (isActive ? "active" : "inactive")}
          >
            <ListItemIcon style={{ display: "flex", justifyContent: "center" }}>
              {/* <Icon
                icon="ant-design:setting-outlined"
                style={{ color: "#2278e9" }}
              /> */}
              <LogoutRoundedIcon style={{ color: "#2278e9",fontSize: "inherit" }}></LogoutRoundedIcon>
            </ListItemIcon>
            <ListItemText primary="Log out" />
          </ListItemButton>
        </List>

        
      </Container>
      <Container>
                  <Dialog
                    open={open}
                    onClose={handledeleteClose}
                    // aria-labelledby="alert-dialog-title"
                    // aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        minHeight: "40vh",
                        minWidth: "35vw",
                        boxShadow: "none",
                        border: "2px solid rgb(128 213 184 / 100%)",
                        strokeWidth: "1px",
                        stroke: "rgb(128 213 184 )",
                        borderRadius: 20,
                      },
                    }}
                    BackdropProps={{
                      style: {
                        backdrop: "blur(2px)",
                      },
                    }}
                  >
                    <div>
                      <DialogTitle id="alert-dialog-title">
                        <div className="upcoming-upcoming-delete-dalog" >
                          {/* <Icon
                            className="upcoming-deleteConfirmationIcon"
                            icon="fluent:delete-12-filled"
                          /> */}
                          <ErrorOutlineSharpIcon style={{fontSize:100,stroke:'none',color:"#ff8d8d"}} className=""
                           ></ErrorOutlineSharpIcon>
                          {/* Delete */}
                        </div>
                      
                      </DialogTitle>
                      <DialogContent className="upcoming-upcoming-delete-dalog">
                       <Container> 
                      <Grid>  <h2 className="upcoming-areUSure" style={{fontSize: "31px"}}> Are you sure  ?</h2></Grid>
                      <Grid>  <span className="upcoming-areUSure"> Please ensure and then confirm!</span></Grid>
                        </Container>

                      </DialogContent>
                     
                      <DialogActions className="upcoming-buttonsContainer">
                       
                        <Button
                        className="stayin"
                          // className="upcoming-noButton"
                        //   style={{background: "#2196F3!important",
                        //   color:"white!important",
                        //   /* height: 27px !important; */
                        //   // borderRadius: "5px !important"
                        // }}
                      
                          onClick={handleClose}
                        >
                          No, Stay In
                        </Button>
                        <Button
                          id="btn"
                         className="admin-logOut"

                          // className="upcoming-yesButton"
                          style={{background: "#b3b9b7 !important",
                            color:"#060606 !important",
                            /* height: 27px !important; */
                            // borderRadius: "5px !important"
                          }}
                          onClick={logOut}
                        >
                          Yes, Log out
                        </Button>
                      </DialogActions>
                    </div>
                  </Dialog>
                </Container>
                 {/* <div className="admindashboard-side-head" onClick={gototop} style={{cursor:"pointer"}}>Back to Top</div> */}
<div
        className="admindashboard-side-head"
        onClick={gototop}
        style={{cursor: "pointer"}}
      >
        Back to Top
        <span className="back2Top-arrow">
          {"  "}
          <Icon icon="streamline:interface-arrows-up-arrow-up-keyboard" color="#6c6b6b" width="16" height="16" />
        </span>
      </div>
    </div>
  );
};

export default Adminsidebar;
