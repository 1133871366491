import "../CSS/sidebarLink.css";
import React, { useState } from "react";
import { Link, Navigate, NavLink } from "react-router-dom";
// import "../../CSS/sidebarNavLink.css";
import { Button, Container, Dialog, DialogActions, DialogContent, DialogTitle, Drawer, Grid, List, Paper } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ListSubheader from "@mui/material/ListSubheader";
// import List from '@mui/material/List';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import DashboardCustomizeIcon from "@mui/icons-material/DashboardCustomize";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import ChatBubbleSharpIcon from "@mui/icons-material/ChatBubbleSharp";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";

import { logout } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

function SidebarMobileMenu() {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [canCreate,setCancreate]=useState(Boolean(JSON.parse(localStorage.getItem("can"))))



  const [invitopen, setinvitopen] = React.useState(false);
  const handleinvitClose = () => {
    setinvitopen(false);
  };
  const handleClickk = () => {
    if (!isLoggedIn) {
         setinvitopen(true);
    }
    else

    if(!canCreate)
    setinvitopen(true);


  };



  const dispatch = useDispatch();

  const [dashboardState, setDashboardState] = React.useState(false);

  const DashboardMenu = () => {
    setDashboardState(!dashboardState);
  };

  const [MyTripsState, setMyTripsState] = React.useState(false);

  const MyTripsMenu = () => {
    setMyTripsState(!MyTripsState);
  };
  const [InvitationsState, setInvitationsState] = React.useState(false);

  const InvitationsMenu = () => {
    setInvitationsState(!InvitationsState);
  };

  const logOut = () => {
    dispatch(logout());
  };
  if (!isLoggedIn) {
    return <Navigate replace to="/login" />;
  }
  return (
    <><div className="NavLink">
      <List component="nav" aria-labelledby="nested-list-subheader">
        <ListItemButton onClick={DashboardMenu} style={{}}>
          <ListItemIcon className="listItemicon"></ListItemIcon>
          <ListItemText primary="My Categories" />
          {dashboardState ? (
            <ExpandLess className="sidebaricon" />
          ) : (
            <ExpandMore className="sidebaricon" />
          )}
        </ListItemButton>
        <Collapse in={dashboardState} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            <ListItemButton
              component={NavLink}
              to="/dashboard"
              sx={{ pl: 9 }}
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <ListItemIcon>
                <DashboardCustomizeIcon
                  icon="material-symbols:dashboard-customize-outline"
                  className="sidebaricon" />
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton
              component={NavLink}
              to="/profile"
              sx={{ pl: 9 }}
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <ListItemIcon>
                <AccountCircleTwoToneIcon className="sidebaricon" />
              </ListItemIcon>
              <ListItemText primary="My Profile" />
            </ListItemButton>
            <ListItemButton sx={{ pl: 9 }} onClick={MyTripsMenu}>
              <ListItemIcon>
                <StarBorder className="sidebaricon" />
              </ListItemIcon>
              <ListItemText primary="My Trips" />
              {MyTripsState ? (
                <ExpandLess className="sidebaricon" />
              ) : (
                <ExpandMore className="sidebaricon" />
              )}
            </ListItemButton>
            <Collapse in={MyTripsState} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={NavLink}
                  to="/upcoming-trips"
                  sx={{ pl: 9 }}
                  className={({ isActive }) => isActive ? "active" : "inactive"}
                >
                  <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                  <ListItemText primary="Upcoming Trips" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  to="/past-trips"
                  sx={{ pl: 9 }}
                  className={({ isActive }) => isActive ? "active" : "inactive"}
                >
                  <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                  <ListItemText primary="Past Trips" />
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton sx={{ pl: 9 }} onClick={InvitationsMenu}>
              <ListItemIcon>
                <InsertInvitationIcon className="sidebaricon" />
              </ListItemIcon>
              <ListItemText primary="TC Invitations" />
              {InvitationsState ? (
                <ExpandLess className="sidebaricon" />
              ) : (
                <ExpandMore className="sidebaricon" />
              )}
            </ListItemButton>
            <Collapse in={InvitationsState} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                <ListItemButton
                  component={NavLink}
                  to="/connected-list"
                  sx={{ pl: 9 }}
                  className={({ isActive }) => isActive ? "active" : "inactive"}
                >
                  <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                  <ListItemText primary="Connected List" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  to="/sent-list"
                  sx={{ pl: 9 }}
                  className={({ isActive }) => isActive ? "active" : "inactive"}
                >
                  <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                  <ListItemText primary="Sent List" />
                </ListItemButton>
                <ListItemButton
                  component={NavLink}
                  to="/recieved-list"
                  sx={{ pl: 9 }}
                  className={({ isActive }) => isActive ? "active" : "inactive"}
                >
                  <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                  <ListItemText primary="Recieved List" />
                </ListItemButton>
              </List>
            </Collapse>
            <ListItemButton
              component={NavLink}
              to="/notification"
              sx={{ pl: 9 }}
              className={({ isActive }) => (isActive ? "active" : "inactive")}
            >
              <ListItemIcon>
                <NotificationsIcon className="sidebaricon" />
              </ListItemIcon>
              <ListItemText primary="Notifications" />
            </ListItemButton>
          </List>
        </Collapse>

        <ListItemButton
          component={NavLink}
          to="/settings"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <ListItemIcon>{/* <NotificationsIcon /> */}</ListItemIcon>
          <ListItemText primary="Settings" />
        </ListItemButton>
        <ListItemButton
          component={NavLink}
          to={isLoggedIn
            && Boolean(JSON.parse(localStorage.getItem("can")))
            ? "/create-a-trip" : ''}
          onClick={handleClickk}
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <ListItemIcon>{/* <NotificationsIcon /> */}</ListItemIcon>
          <ListItemText primary="Create a Trip" />
        </ListItemButton>
        <ListItemButton
          component={NavLink}
          to="/companion-search"
          className={({ isActive }) => (isActive ? "active" : "inactive")}
        >
          <ListItemIcon>{/* <NotificationsIcon /> */}</ListItemIcon>
          <ListItemText primary="Companion Search" />
        </ListItemButton>
        <ListItemButton onClick={logOut}>
          <ListItemIcon>{/* <DraftsIcon /> */}</ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
      </List>
    </div><div>
        <Container>
          <Dialog
            open={invitopen}
            onClose={handleinvitClose}
          
            PaperProps={{
              style: {
                minHeight: "40vh",
                minWidth: "35vw",
                boxShadow: "none",
                border: "2px solid rgb(128 213 184 / 100%)",
                strokeWidth: "1px",
                stroke: "rgb(128 213 184 )",
                borderRadius: 20,
              },
            }}
            BackdropProps={{
              style: {
                backdrop: "blur(2px)",
              },
            }}
          >
            <div>
              <DialogTitle id="alert-dialog-title">

              </DialogTitle>
              <DialogContent className="invitation-upcoming-delete-dalog">
                <h2 className="invitation-areUSure" style={{ color: "red" }}>
                  {" "}
                  {!isLoggedIn ? "please login to create a trip" :
                    !canCreate ? "You have reached the maximum limit of 4 trips, So you cannot create trip" : ""}
                </h2>
              </DialogContent>
              <DialogActions className="invitation-buttonsContainer">
                {/* <Button
          id="btn"
          className="invitation-yesButton"
          onClick={() => {
            settingdeleteinvitation();
          }}
        >
          Yes
        </Button> */}
                <Button
                  className="invitation-noButton"
                  onClick={handleinvitClose}
                >
                  OK
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </Container>
      </div></>
  );
}
export default SidebarMobileMenu;