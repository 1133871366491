import React from "react";
import { Button, Card, CardContent, Container, Grid } from "@mui/material";
import Box from "@mui/material/Box";
import EditIcon from "@mui/icons-material/Edit";
import { Link } from "react-router-dom";
import "../CSS/sidebarLink.css";
import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useState } from "react";
function EditProfile() {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  // console.log("user_id", user.user_id);
  const [editProfile , seteditProfile]= useState([]);

  useEffect(() => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");


    axios
      .get(
        `${domain}/user/profile/${user.user_id}/`
      )
      .then((response) => {
        console.log(response, "past trip response");

        if (response.data) {
          console.log(response.data, "past trip response data");
          seteditProfile(response.data);
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          console.log("data : ", error.response.data.message);
        }
      });
  }, []);

  
  return (
    <div style={{ marginTop: 17 }}>
      <Box
        sx={{
          height: 250,
          backgroundColor: "white",
          "&:hover": {
            backgroundColor: "aliceblue",
          },
        }}
      >
        <Container>
        {editProfile.map((post, index) => {
      return (
        <Grid container spacing={2}>
        <Grid item xl={12} lg={12} md={12} sm={6} xs={6}>
          {" "}
          <div className="settings-card-profilecontainer">
            <div className="settings-card-profile-inner">
              <img
                className="settings-profile-img"
                variant="top"
                src={
                  post.profile_picture == null
                    ? "./Assets/Images/Favicon 25 WB _ DV.webp"
                    : post.profile_picture
                }
                // src="./Assets/Images/human_dummy_image.jpg"
                alt="..."
              />
            </div>
          </div>
        </Grid>

        <Grid item xl={12} lg={12} md={12} sm={6} xs={6} className="">
          <div className="settings-profile-text-content-main">
            <CardContent className="settings-profile-name">
              {post.full_name}
            </CardContent>
            <Button
              LinkComponent={Link}
              to="/edit-profile"
              className="settings-profile-edit-btn"
              endIcon={
                <EditIcon
                  style={{ marginBottom: "1px", fontSize: "13px" }}
                />
              }
            >
              Edit Profile
            </Button>
          </div>
        </Grid>
      </Grid>
      );
                })}
        </Container>
      </Box>
    </div>
  );
}

export default EditProfile;
