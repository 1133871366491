import React, { useState } from "react";
import "../CSS/FAQAccordion.css";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";

const Accordion = ({ title, content }) => {
  const [isActive, setIsActive] = useState(false);

  return (
    <div>
      <div
        className="faq-accordionTitle"
        onClick={() => setIsActive(!isActive)}
      >
        <div  style={{marginTop:18}}>{title}</div>
        <div className="faq-accordionItem">
          <span className="faq-accordionIcons">
            {isActive ? (
              <RemoveCircleOutlineIcon className="faq-plusMinusIcon" />
            ) : (
              <AddCircleOutlineIcon className="faq-plusMinusIcon" />
            )}
          </span>
        </div>
      </div>
      {isActive && <div className="faq-accordionContent">{content}</div>}
      <hr className="FAQhr"></hr>
    </div>
  );
};

export default Accordion;
