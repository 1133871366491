import React from 'react'
import "./Loading.css"

const Loading = () => {
  return (
    <div class="loader">
  <div class="bar1"></div>
  <div class="bar2"></div>
  <div class="bar3"></div>
  <div class="bar4"></div>
  <div class="bar5"></div>
  <div class="bar6"></div>
</div>  
  )
}

export default Loading