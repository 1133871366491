import React, {useEffect, useState} from "react";
import * as Yup from "yup";
// import axiosInstance from "../axios";
// import { useHistory } from "react-router-dom";
import {Formik, Field, Form, useFormik} from "formik";
import Swal from "sweetalert2";
// import GoogleLogin from "react-google-login";
import "../../CSS/signup.css";
// import { responseGoogle, useGoogleLogin } from "react-google-login";
import GoogleLogin from "react-google-login";

import FacebookLogin from "react-facebook-login";
import {LinkedIn, useLinkedIn} from "react-linkedin-login-oauth2";
import axios from "axios";
import {
  Container,
  Grid,
  MenuItem,
  Select,
  TextField,
  Checkbox,
  Autocomplete,
  Box,
  Tooltip,
  Button,
  IconButton,
  InputAdornment,
} from "@mui/material";
import {CheckBox, NoEncryption} from "@mui/icons-material";
import {useDispatch, useSelector} from "react-redux";
import {Visibility, VisibilityOff} from "@mui/icons-material";

// import showPwdImg from "../images/visible.png";
// import hidePwdImg from "../images/hidevisibility.png";
// import InstagramIcon from "@mui/icons-material/Instagram";
// import FacebookIcon from "@mui/icons-material/Facebook";
// import TwitterIcon from "@mui/icons-material/Twitter";
// import LinkedInIcon from "@mui/icons-material/LinkedIn";
import {Icon} from "@iconify/react";
import {Link, Navigate, useNavigate} from "react-router-dom";
import SelectInput from "@mui/material/Select/SelectInput";
// import CountryJson from "../../json/Country-State-City.json";
import ScrollTop from "../../ScrollTop";
import {glogin} from "../../actions/auth";
import AnnouncementIcon from "@mui/icons-material/Announcement";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
function SignUp(props) {
  // http://127.0.0.1:8000/login/
  // const history = useHistory();
  const {isLoggedIn, user} = useSelector(state => state.auth);
  const navigate = useNavigate();

  const [password, setPassword] = useState("");
  const [passwordConfirmation, setPasswordConfirmation] = useState("");
  const [isRevealPwd, setIsRevealPwd] = useState(false);
  const [isRevealCnfPwd, setIsRevealCnfPwd] = useState(false);
  const [registerResponseMsg, setRegisterResponseMsg] = useState("");
  const [registerErrorResponse, setRegisterErrorResponse] = useState("");
  const [phonecode, setPhonecode] = useState("");
  const {auth} = useSelector(state => state);
  const dispatch = useDispatch();
  console.log(auth.user, "messege from store");
  const [gloginErrorMessage, setgloginErrorMessage] = React.useState("");
  const [mystates, setmystates] = useState([]);

  const [openMobile, setOpenMobile] = React.useState(false);
  const [openEmail, setOpenEmail] = React.useState(false);
  const handleTooltipClose = () => {
    setOpenMobile(false);
    setOpenEmail(false);
  };

  const [inputValue, setInputValue] = React.useState();
  const [country_search_response, setcountry_search_response] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [showCnfPassword, setShowCnfPassword] = useState(false);
  const [isVisible, setIsVisible] = useState(true);

  const handleClickShowPassword = () => setShowPassword(show => !show);

  const handleClickShowCnfPassword = () => setShowCnfPassword(show => !show);

  const handleMouseDownPassword = event => {
    event.preventDefault();
  };

  const handleMouseDownCnfPassword = event => {
    event.preventDefault();
  };
  const countrystatepincode = () => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .get(
        `${domain}/country-state?country_name=${inputValue}
  `
      )
      .then(res => {
        console.log(res, "c response");
        if (res.data.response) {
          setcountry_search_response(res.data.response);
        }
      });
  };

  useEffect(() => {
    // document.title =  "Desi Travel Companion-signup";
    countrystatepincode();
  }, [inputValue]);

  // useEffect(() => {
  //   document.title =  "Desi Travel Companion-signup";
  // }, []);
  //Uat-glogin
  // const clientId =
  //   "1069593416306-cc1foo49l43cfd3euunv6dhh397jsqda.apps.googleusercontent.com";
  //prod-glogin
  // const clientId =
  // "562795218212-jv1emrbt3jeosgqel9kjpmojoojjai5a.apps.googleusercontent.com";

  let clientId = "";
  if (window.location.hostname.trim() === "localhost") {
    clientId = process.env.REACT_APP_UAT_API_Google_SIGNIN;
  } else if (window.location.hostname.trim() === "54.185.6.32") {
    clientId = process.env.REACT_APP_UAT_API_Google_SIGNIN;
  } else if (
    window.location.hostname.trim() === "uat.desitravelcompanion.com"
  ) {
    clientId = process.env.REACT_APP_UAT_API_Google_SIGNIN;
  } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
    clientId = process.env.REACT_APP_PROD_API_Google_SIGNIN;
  } else if (
    window.location.hostname.trim() === "www.desitravelcompanion.com"
  ) {
    clientId = process.env.REACT_APP_PROD_API_Google_SIGNIN;
  }
  console.log(clientId, "clientId env end");

  const onSuccess = res => {
    console.log("success", res);
    console.log("AccessToken", res.xc.access_token);

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .post(` ${domain}/rest-auth/google/`, {
        access_token: res.xc.access_token,
        // 'Access-token':"ya29.a0AX9GBdXonCkMac-rDkI64MxY9-dNoK_o7KPOW3Fmx-gVWfRlUruNwPuuMQ5r4jC_qN0wh30h8ohvIsUdpSDWtDFhMx2nmtHeYHwBE0RYJYu1mhLNCNyOXgifcDu3cQHCgG4VIYtrnVuoBi2NdVCPWG1enIWRaCgYKAW0SARISFQHUCsbCSyGFCCZZyKKPJRgPdcTKeA0163"
      })
      .then(res => {
        console.log(res, "login response from python api");
        if (res.data) {
          console.log(res.data, "login res data");
          // return <Navigate replace to="/edit-profile" />;
          localStorage.setItem("user", JSON.stringify(res.data));
          dispatch(glogin(res.data));

          return navigate("/edit-profile", {state: "true"});
          // <Navigate replace to="/edit-profile" />;
        }
        // if (res.profileObj) {
        //   console.log(res.profileObj, "login response data");
        //   setProfile(res.profileObj);
        // }
      })
      .catch(err => {
        console.log("failed from python api", err);
        if (err.response.status === 400) {
          console.log(err.response.data.message);
          setRegisterResponseMsg("");
          setRegisterErrorResponse("");
          setgloginErrorMessage(err.response.data.message);
        }
      });
  };
  // const initialFormData = Object({
  //   full_Name: "",
  //   dob: "",
  //   gender: "",
  //   phoneNumber: "",
  //   location: "",
  //   email: "",
  //   password: "",
  //   passwordConfirmation: ""
  // });

  // const [formData, updateFormData] = useState(initialFormData);
  // const [formData, setformData] = useState(initialFormData);
  // const [isSubmit, setIsSubmit] = useState(false);
  // const handleSubmit = (e) => {
  // e.preventDefault();
  //   setformData(formData);
  //   if (Object.keys(formData).length === 0 && isSubmit) {
  //     console.log(formData);

  //   }
  //   console.log(formData);
  //   console.log(formData.full_Name);
  //   console.log(formData.lname);
  //   console.log(formData.phoneNumber);
  //   console.log(formData.email);
  //   console.log(formData.password);
  //   setIsSubmit(true);
  // };

  // const handleSubmit = e => {
  //   e.preventDefault();
  //   console.log(formData);

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const passwordRegExp = "^((?=.*d)(?=.*[A-Z])(?=.*W).{8,8})$";

  // const ContactSchema = Yup.object().shape({
  //   full_Name: Yup.string()
  //     .min(2, "Too Short!")
  //     .max(50, "Too Long!")
  //     .required("Required"),

  //   dob: Yup.string()
  //     .min(1, "Too Short!")
  //     .max(50, "Too Long!")
  //     .required("Required"),

  //   phoneNumber: Yup.string()
  //     .matches(phoneRegExp, "Phone number is not valid")
  //     .required("Required")
  //     .min(10)
  //     .max(12),

  //   email: Yup.string().email("Invalid email")
  //   .required("Required"),

  //   password: Yup.string().required(
  //     "Must Contain 16 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
  //   ),
  //   passwordConfirmation: Yup.string().oneOf(
  //     [Yup.ref("password"), null],
  //     "Passwords must match"
  //   )
  // });
  const [matches, setMatches] = useState(
    window.matchMedia("(min-width: 768px)").matches
  );

  useEffect(() => {
    window
      .matchMedia("(min-width: 768px)")
      .addEventListener("change", e => setMatches(e.matches));
  }, [window]);

  const validationSchema = Yup.object({
    full_Name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Full Name is required")
      // .matches(/^[^\s.][a-zA-Z.]*\s?[a-zA-Z]*$/, "Enter valid name"),
      .matches(/^[A-Za-z ]*$/, "only English characters allowed")
      .matches(
        /^[^\s]+(\s+[^\s]+)*$/,
        "Space is not accepted at  starting and ending of the characters"
      ),

    // dob: Yup.string()
    //   .min(1, "Too Short!")
    //   .max(50, "Too Long!")
    //   .required("Required"),

    Gender: Yup.string().required("Gender is required"),
    Age_Group: Yup.string().required("Age Group is required"),
    // country_code:Yup.string().required("Required"),
    mobile_Number: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Mobile Number is required")
      .min(4, "Mobile Number must be at least 4 characters")
      .max(12, "Mobile Number must be at most 12 characters"),
    country: Yup.string().required("Country is required"),
    state: Yup.string().required("State is required"),
    // location: Yup.string()
    //   .min(2, "Too Short!")
    //   .max(50, "Too Long!")
    //   .required("Required"),
    email_Id: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .matches(
        /^[^\s]+(\s+[^\s]+)*$/,
        "Space is not accepted at  starting and ending of the characters"
      ),

    password: Yup.string()
      .required("Password is required")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .required("Passwords must match"),
    signinagreement: Yup.boolean()
      .required()
      .isTrue("Need to agree terms and conditions"),
  });

  // email: Yup
  //   .string('Enter your email')
  //   .email('Enter a valid email')
  //   .required('Email is required'),
  // password: Yup
  //   .string('Enter your password')
  //   .min(8, 'Password should be of minimum 8 characters length')
  //   .required('Password is required'),

  const formik = useFormik({
    initialValues: {
      full_Name: "",
      Age_Group: "",
      Gender: "",
      country_code: "",
      mobile_Number: "",
      // location: "",
      country: "",
      state: "",
      email_Id: "",
      password: "",
      passwordConfirmation: "",
      signinagreement: false,
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      setIsVisible(true)
      setRegisterResponseMsg("");
      // alert(JSON.stringify(values, null, 2));
      console.log("request values", values);
      console.log("request body", JSON.stringify(values));
      // formik.resetForm();
      // const registerResponse=
      let headers = new Headers();

      headers.append("Content-Type", "application/json");
      headers.append("Accept", "application/json");

      console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
      let domain = "";
      console.log(process.env, "domain env", window.location.hostname);
      if (window.location.hostname.trim() === "localhost") {
        domain = process.env.REACT_APP_LOCAL_API;
      } else if (window.location.hostname.trim() === "54.185.6.32") {
        domain = process.env.REACT_APP_UAT_API;
      } else if (
        window.location.hostname.trim() === "uat.desitravelcompanion.com"
      ) {
        domain = process.env.REACT_APP_PROD_UAT_API;
      } else if (
        window.location.hostname.trim() === "desitravelcompanion.com"
      ) {
        domain = process.env.REACT_APP_PROD_API;
      } else if (
        window.location.hostname.trim() === "www.desitravelcompanion.com"
      ) {
        domain = process.env.REACT_APP_PROD_API;
      }
      console.log(domain, "domain env end");

      axios
        .post(
          `${domain}/register/`,
          {
            full_name: values.full_Name,
            age_group: values.Age_Group,
            country: values.country,
            state: values.state,
            mobile_number: values.mobile_Number,
            country_code: values.country_code,
            email: values.email_Id,
            password: values.password,
            gender: values.Gender,
          }
          // {
          //   method: 'POST',
          //   mode: 'no-cors',
          //   headers: {
          //     'Access-Control-Allow-Origin': '*',
          //     'Content-Type': 'application/json',
          //   },
          //   withCredentials: true,
          //   credentials: 'same-origin',}
        )
        .then(response => {
          console.log(response, "register response");
          // if (response.data) {
          // ret  localStorage.setItem("user", JSON.stringify(response.data));
          // }
          if (response.data.message) {
            setRegisterErrorResponse("");
            setgloginErrorMessage("");
            setRegisterResponseMsg(response.data.message);
            formik.resetForm();
            // formik.setFieldValue('signinagreement', checked)
            // values.signinagreement.v
            // formik.values("signinagreement",false)
          }

          // return response.data;
        })
        .catch(error => {
          // Error
          if (error.response.status === 400) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            setRegisterResponseMsg("");
            setgloginErrorMessage("");
            setRegisterErrorResponse(error.response.data.message);
          }
        });
        setTimeout(() => {
          setIsVisible(false);
        }, 7000);
    },
  });

  // useEffect(() => {
  //   // document.title =  "Desi Travel Companion-signup";
  //   setRegisterResponseMsg("");
  //   setRegisterErrorResponse("");
  //   }, [formik.values?formik.values:'']);

  //   const  addPosts = async () => {
  //     var dataObj = {
  //             email_Id : "Prakash05@gmail.com",
  //             password: "jaya1234"
  //           }
  //  try{
  //   let logindata= await axios.post('http://ec2-13-233-80-109.ap-south-1.compute.amazonaws.com/login/',{
  //         email_Id : "prakash@gmail.com",
  //         password: "jaya4321"
  //       } )
  //        .then((response) => {
  //         console.log(response,"hhh");
  //        });
  //       }catch(error){
  //         console.log(error.response,"gggg");
  //       }

  //  };

  //   addPosts();

  //   const manageac = async () => {
  //     try {
  //       let logindata = await axios
  //         .get(
  //           "http://ec2-13-233-80-109.ap-south-1.compute.amazonaws.com/users-details/"
  //         )
  //         .then((response) => {
  //           console.log(response.data, "manage details");

  //        let   name= response.data.map((item)=>[item.full_Name,item.mobile_Number,item.email_Id])

  //     console.log(name,"array of reg")

  //         });
  //     } catch (error) {
  //       console.log(error.response, "gggg");
  //     }
  //   };

  //  manageac();
  // console.log("country state", CountryJson);
  // const mycountry = country_search_response;
  const countrycode = () => {
    country_search_response.map(item => {
      // console.log("country")

      if (item.name === formik.values.country) {
        setPhonecode(item.phone_code);
        return true;
      }
    });

    country_search_response.map(item => {
      if (item.name === formik.values.country) {
        setmystates(item.states);
        //          item.states.map((states) => {
        //           setmystates(states);
        //           // return states.name
        // return true
        // })
      }
    });
  };
  // let mystates
  //   const handlestates=()=>{
  //     mycountry.map((item) => {
  //       if (item.name === formik.values.country) {
  //         setmystates(item.states)
  // //          item.states.map((states) => {
  // //           setmystates(states);
  // //           // return states.name
  // // return true
  //         // })
  //       }})
  //   }

  console.log(formik, "form values");

  return (
    <div className="mainSignup">
      <ScrollTop>
        <Container>
          <Grid container spacing={2}>
            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div>
                <Link to="/" className="nav-logo">
                  <img
                    src="./Assets/Images/desi-travel-campanion-logo.png"
                    alt="first"
                    className="signupFirstimage"
                  />
                </Link>
              </div>
              <h1 className="planForYouHeading">
                <span> Plan For Your </span>
                <span style={{color: "#175993"}}>Trip </span>
                <br />
                <span> And Explore Your </span>
                <br />
                <span style={{color: "#7ec649"}}>Life Now ... </span>
              </h1>
              <div>
                <img
                  src="./Assets/Images/Register Illustraion Image PNG.png"
                  alt="first"
                  className="signupPageSecondimage"
                />
              </div>
            </Grid>

            <Grid item xl={6} lg={6} md={6} sm={12} xs={12}>
              <div className="signupPageContainer-main">
                <form onSubmit={formik.handleSubmit}>
                  <h2 style={{fontWeight: 645, padding: 20}}>
                    Create your account
                  </h2>

                  <Grid
                    container
                    spacing={2}
                    className="signupPageContainer-sub"
                  >
                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <label className="signup-fullNamelabel">
                        Full Name <span style={{color: "#ff0000"}}>*</span>
                      </label>
                      <div>
                        <TextField
                          name="full_Name"
                          className="signup-fullNameInput"
                          type="text"
                          onBlur={formik.handleBlur}
                          touched={formik.touched}
                          value={formik.values.full_Name}
                          onChange={formik.handleChange}
                          inputProps={{
                            maxLength: 32,
                          }}
                          error={
                            formik.touched.full_Name &&
                            Boolean(formik.errors.full_Name)
                          }
                          helperText={
                            formik.touched.full_Name && formik.errors.full_Name
                          }
                        />
                        <div id="errormessage" className="signup-Error"></div>
                      </div>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <label className="signup-ageGroupLabel">
                        Age Group <span style={{color: "#ff0000"}}>*</span>
                      </label>
                      <div>
                        <Select
                          className="signup-ageInput"
                          name="Age_Group"
                          onBlur={formik.handleBlur}
                          touched={formik.touched}
                          value={formik.values.Age_Group}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.Age_Group &&
                            Boolean(formik.errors.Age_Group)
                          }
                          helperText={
                            formik.touched.Age_Group && formik.errors.Age_Group
                          }
                        >
                          <MenuItem value="" label="Select a Gender">
                            Select Age Group
                          </MenuItem>
                          <MenuItem value="20-25" label="20-25">
                            20-25
                          </MenuItem>
                          <MenuItem value="26-30" label="26-30">
                            26-30
                          </MenuItem>
                          <MenuItem value="31-35" label="31-35">
                            31-35
                          </MenuItem>
                          <MenuItem value="36-40" label="36-40">
                            36-40
                          </MenuItem>
                          <MenuItem value="41-45" label="41-45">
                            41-45
                          </MenuItem>
                          <MenuItem value="46-50" label="46-50">
                            46-50
                          </MenuItem>
                          <MenuItem value="51-60" label="51-60">
                            51-60
                          </MenuItem>
                          <MenuItem value="61-70" label="61-70">
                            61-70
                          </MenuItem>
                          <MenuItem value="71-80" label="71-80">
                            71-80
                          </MenuItem>
                        </Select>
                      </div>

                      <div id="errormessage" className="signup-Error">
                        {formik.touched.Age_Group &&
                          formik.errors.Age_Group && (
                            <span
                              className="gender_error"
                              style={{color: "#d32f2f", fontSize: "smaller"}}
                            >
                              {formik.errors.Age_Group}
                            </span>
                          )}
                      </div>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <label className="signup-genderLabel">
                        Gender <span style={{color: "#ff0000"}}>*</span>
                      </label>
                      <div>
                        <Select
                          className="signup-genderInput"
                          name="Gender"
                          onBlur={formik.handleBlur}
                          touched={formik.touched}
                          value={formik.values.Gender}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.Gender &&
                            Boolean(formik.errors.Gender)
                          }
                          helperText={
                            formik.touched.Gender && formik.errors.Gender
                          }
                        >
                          <MenuItem value="" label="Select a Gender">
                            Select a Gender
                          </MenuItem>
                          <MenuItem value="Male" label="Male">
                            Male
                          </MenuItem>
                          <MenuItem value="Female" label="Female">
                            Female
                          </MenuItem>
                        </Select>
                      </div>

                      <div id="errormessage" className="signup-Error">
                        {formik.touched.Gender && formik.errors.Gender && (
                          <span
                            className="gender_error"
                            style={{color: "#d32f2f", fontSize: "smaller"}}
                          >
                            {formik.errors.Gender}
                          </span>
                        )}
                      </div>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <label className="signup-countryLabel">
                        Country <span style={{color: "#ff0000"}}>*</span>
                      </label>
                      {/* <Select
                      className="signup-countryInput"
                      name="country"
                      onBlur={formik.handleBlur && countrycode}
                      touched={formik.touched}
                      value={formik.values.country}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.country && Boolean(formik.errors.country)
                      }
                      helperText={
                        formik.touched.country && formik.errors.country
                      }
                      style={{ background: "white" }}
                    >
                      <MenuItem
                        value=""
                        label=""
                        style={{ background: "white" }}
                      >
                        Select a country
                      </MenuItem>
                      {mycountry.map((item) => {
                        return (
                          <MenuItem
                            value={item.name}
                            label="USA"
                            style={{ background: "white" }}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                    </Select> */}
                      {/* <div id="errormessage" className="signup-Error">
                      {formik.touched.country && formik.errors.country}
                    </div> */}

                      <Autocomplete
                        // onInputChange={(e, value, reason) => {
                        //   setInputValueDestination(value);

                        //   if (!value) {
                        //     setOpenDestination(false);
                        //   }
                        // }}
                        sx={{
                          "& .MuiAutocomplete-popupIndicator": {
                            transform: "none",
                          },
                        }}
                        className="signup-countryInput"
                        // onBlur={formik.handleBlur}
                        onBlur={formik.handleBlur && countrycode}
                        touched={formik.touched}
                        value={formik.values.country}
                        // onChange={formik.handleChange}
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                        helperText={
                          formik.touched.country && formik.errors.country
                        }
                        autoHighlight
                        freeSolo={false}
                        id="country-select-demo"
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}
                        options={
                          country_search_response ? country_search_response : ""
                        }
                        getOptionLabel={option => option.name || option}
                        // console.log(option,"option country")
                        //  return option.name || option}}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{"& > img": {mr: 2, flexShrink: 0}}}
                            {...props}
                          >
                            {option ? option.name : ""}
                          </Box>
                        )}
                        // name="country"
                        onChange={(e, value) => {
                          formik.setFieldValue(
                            "country",
                            value ? value.name : ""
                          );
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            // label="Select a country"
                            name="country"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "country",
                            }}
                          />
                        )}
                      />
                      {/* <div id="errormessage" className="signup-Error" >
                      {formik.touched.country && formik.errors.country}
                    </div> */}
                      <div
                        id="errormessage"
                        className="signup-Error"
                        style={{marginTop: 10}}
                      >
                        {formik.touched.country && formik.errors.country && (
                          <span
                            className="gender_error"
                            style={{color: "#d32f2f", fontSize: "smaller"}}
                          >
                            {formik.errors.country}
                          </span>
                        )}
                      </div>
                    </Grid>

                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <label className="signup-stateLabel">
                        State <span style={{color: "#ff0000"}}>*</span>
                      </label>
                      {/* <Select
                      style={{ background: "white" }}
                      className="signup-stateInput"
                      name="state"
                      onBlur={formik.handleBlur}
                      touched={formik.touched}
                      value={formik.values.state}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.state && Boolean(formik.errors.state)
                      }
                      helperText={formik.touched.state && formik.errors.state}
                    >
                      <MenuItem
                        value=""
                        label="Select a state"
                        style={{ background: "white" }}
                      >
                        Select a state
                      </MenuItem>

                      {country_search_response.map((item) => {
                        if (item.name === formik.values.country) {
                          return item.states.map((states) => {
                            return (
                              <MenuItem
                                value={states.name}
                                label="chennai"
                                style={{ background: "white" }}
                              >
                                {states.name}
                              </MenuItem>
                            );
                          });
                        }
                      })}
                    </Select>  */}
                      {/* <div id="errormessage" className="signup-Error">
                      {formik.touched.state && formik.errors.state}
                    </div> */}

                      <Autocomplete
                        // onInputChange={(e, value, reason) => {
                        //   setInputValueDestination(value);

                        //   if (!value) {
                        //     setOpenDestination(false);
                        //   }
                        // }}
                        sx={{
                          "& .MuiAutocomplete-popupIndicator": {
                            transform: "none",
                          },
                        }}
                        className="signup-stateInput"
                        onBlur={formik.handleBlur}
                        touched={formik.touched}
                        value={
                          (formik.values.state = formik.values.country
                            ? formik.values.state
                            : "")
                        }
                        // onChange={formik.handleChange}
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        helperText={formik.touched.state && formik.errors.state}
                        autoHighlight
                        freeSolo={false}
                        id="country-select-demo"
                        options={formik.values.country ? mystates : []}
                        getOptionLabel={option => option.name || option}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{"& > img": {flexShrink: 0}}}
                            {...props}
                          >
                            {console.log(option ? option.name : "", "options")}
                            {option ? option.name : ""}
                          </Box>
                        )}
                        // name="country"
                        onChange={(e, value) => {
                          formik.setFieldValue(
                            "state",
                            value ? value.name : ""
                          );
                        }}
                        renderInput={params => (
                          <TextField
                            {...params}
                            // label="Select a State"
                            name="state"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                      {/* <div id="errormessage" className="signup-Error">
                      {formik.touched.state && formik.errors.state}
                    </div> */}
                      <div
                        id="errormessage"
                        className="signup-Error"
                        style={{marginTop: 10}}
                      >
                        {formik.touched.state && formik.errors.state && (
                          <span
                            className="gender_error"
                            style={{color: "#d32f2f", fontSize: "smaller"}}
                          >
                            {formik.errors.state}
                          </span>
                        )}
                      </div>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <label className="signup-mobileLabel">
                        Mobile Number
                        <span style={{color: "#ff0000"}}>* </span>
                        {matches ? (
                          <Tooltip
                            //  open={tooltipIsOpenMobile}

                            disableFocusListener
                            disableTouchListener
                            title="Mobile number will not be displayed anywhere on the portal except for a Travel Companion whom you have accepted"
                            placement="bottom-start"
                            arrow
                            PopperProps={{
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  // border: "solid skyblue 1px",
                                  color: "fffff",
                                  backgroundColor: "rgb(57, 101, 86);",
                                },
                              },
                            }}
                          >
                            <InfoOutlinedIcon
                              style={{
                                color: "1EC28B",
                                marginTop: "-1px",
                                fontSize: "larger",
                              }}
                            ></InfoOutlinedIcon>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            arrow
                            PopperProps={{
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  // border: "solid skyblue 1px",
                                  color: "fffff",
                                  backgroundColor: "rgb(57, 101, 86);",
                                  fontSize: "10px",
                                  width: "80%",
                                  marginLeft: "17px",
                                },
                              },
                            }}
                            //  onBlur={handleTooltipClose}
                            onClose={handleTooltipClose}
                            open={openMobile}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Mobile number will not be displayed anywhere on the portal except for a Travel Companion whom you have accepted"
                          >
                            <InfoOutlinedIcon
                              onClick={() => setOpenMobile(!openMobile)}
                              style={{
                                color: "1EC28B",

                                marginTop: "-1px",
                                fontSize: "larger",
                              }}
                            ></InfoOutlinedIcon>
                          </Tooltip>
                        )}
                      </label>
                      <div>
                        <TextField
                          className="signup-countryCodeInput"
                          name="country_code"
                          onBlur={formik.handleBlur}
                          touched={formik.touched}
                          value={
                            (formik.values.country_code = formik.values.country
                              ? phonecode
                              : "")
                          }
                          onChange={formik.handleChange}
                          error={
                            formik.touched.country_code &&
                            Boolean(formik.errors.country_code)
                          }
                          helperText={
                            formik.touched.country_code &&
                            formik.errors.country_code
                          }
                          disabled
                        ></TextField>

                        <TextField
                          contr
                          className="signup-mobileInput"
                          name="mobile_Number"
                          onBlur={formik.handleBlur}
                          touched={formik.touched}
                          value={formik.values.mobile_Number}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.mobile_Number &&
                            Boolean(formik.errors.mobile_Number)
                          }
                          helperText={
                            formik.touched.mobile_Number &&
                            formik.errors.mobile_Number
                          }
                        />
                      </div>
                      <div id="error_phone" className="signup-Error"></div>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <label className="signup-emailIdLabel">
                        Email ID<span style={{color: "#ff0000"}}>*</span>
                        {matches ? (
                          <Tooltip
                            // open={tooltipIsOpenEmail}
                            disableFocusListener
                            disableTouchListener
                            title="Email id will not be displayed anywhere on the portal except for a Travel Companion whom you have accepted"
                            placement="bottom-start"
                            arrow
                            PopperProps={{
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  // border: "solid skyblue 1px",
                                  color: "fffff",
                                  backgroundColor: "rgb(57, 101, 86);",
                                },
                              },
                            }}
                          >
                            <span>
                              {" "}
                              <InfoOutlinedIcon
                                style={{
                                  color: "1EC28B",
                                  marginTop: "-1px",
                                  fontSize: "larger",
                                }}
                              ></InfoOutlinedIcon>
                            </span>
                          </Tooltip>
                        ) : (
                          <Tooltip
                            arrow
                            PopperProps={{
                              sx: {
                                "& .MuiTooltip-tooltip": {
                                  // border: "solid skyblue 1px",
                                  color: "fffff",
                                  backgroundColor: "rgb(57, 101, 86);",
                                  fontSize: "10px",
                                  width: "80%",
                                  marginLeft: "17px",
                                },
                              },
                            }}
                            onClose={handleTooltipClose}
                            open={openEmail}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            title="Email id will not be displayed anywhere on the portal except for a Travel Companion whom you have accepted"
                          >
                            <InfoOutlinedIcon
                              onClick={() => setOpenEmail(!openEmail)}
                              style={{
                                color: "1EC28B",
                                marginTop: "-1px",
                                fontSize: "larger",
                              }}
                            ></InfoOutlinedIcon>
                          </Tooltip>
                        )}
                      </label>
                      <div>
                        <TextField
                          className="signup-emailIdInput"
                          type="email"
                          name="email_Id"
                          margin="normal"
                          onBlur={formik.handleBlur}
                          touched={formik.touched}
                          value={formik.values.email_Id}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.email_Id &&
                            Boolean(formik.errors.email_Id)
                          }
                          helperText={
                            formik.touched.email_Id && formik.errors.email_Id
                          }
                        />
                      </div>
                      <div id="errormessage" className="signup-Error"></div>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <label className="signup-passwordLabel">
                        Password (8+ characters)
                        <span style={{color: "#ff0000"}}>*</span>
                      </label>
                      <div>
                        <TextField
                          className="signup-passwordInput"
                          name="password"
                          type={showPassword ? "text" : "password"}
                          onBlur={formik.handleBlur}
                          touched={formik.touched}
                          value={formik.values.password}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.password &&
                            Boolean(formik.errors.password)
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                >
                                      {showPassword ? (
                                      <Icon
                                        icon="ph:eye"
                                        style={{color: "grey"}}
                                      />
                                    ) : (
                                      <Icon
                                        icon="iconamoon:eye-off-light"
                                        style={{color: "grey"}}
                                      />
                                    )}
                                  {/* {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )} */}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          // helperText={
                          //   formik.touched.password && formik.errors.password
                          // }
                        />
                      </div>
                      <div
                        id="errormessage"
                        className="signup-Error"
                        style={{marginTop: "12px"}}
                      >
                        {formik.touched.password && formik.errors.password}
                      </div>
                    </Grid>

                    <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                      <label className="signup-confirmPasswordLabel">
                        Confirm Password (8+ characters)
                        <span style={{color: "#ff0000"}}>*</span>
                      </label>
                      <div>
                        <TextField
                          className="signup-confirmPasswordInput"
                          name="passwordConfirmation"
                          type={showCnfPassword ? "text" : "password"}
                          onBlur={formik.handleBlur}
                          touched={formik.touched}
                          value={formik.values.passwordConfirmation}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.passwordConfirmation &&
                            Boolean(formik.errors.passwordConfirmation)
                          }
                          helperText={
                            formik.touched.passwordConfirmation &&
                            formik.errors.passwordConfirmation
                          }
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowCnfPassword}
                                  onMouseDown={handleMouseDownCnfPassword}
                                  edge="end"
                                >
                                  {showCnfPassword ? (
                                <Icon
                                icon="ph:eye"
                                style={{color: "grey"}}
                              />
                            ) : (
                              <Icon
                                icon="iconamoon:eye-off-light"
                                style={{color: "grey"}}
                              />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      </div>
                      <div id="errormessage" className="signup-Error"></div>
                    </Grid>
                  </Grid>

                  <div className="agreement">
                    <Checkbox
                      checked={formik.values.signinagreement}
                      name="signinagreement"
                      style={{
                        color:
                          formik.touched.signinagreement &&
                          formik.errors.signinagreement
                            ? "rgb(211, 47, 47)"
                            : "",
                      }}
                      onBlur={formik.handleBlur}
                      touched={formik.touched.signinagreement}
                      value={formik.values.signinagreement}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.signinagreement &&
                        Boolean(formik.errors.signinagreement)
                      }
                      helperText={
                        formik.touched.signinagreement &&
                        formik.errors.signinagreement
                      }
                    />
                    <span className="signupAgreementText">
                      By Signing up, you agree to our &nbsp;
                      <a
                        href="/terms-and-conditions"
                        style={{textDecoration: "none"}}
                      >
                        Terms and Conditions
                      </a>
                      .
                    </span>
                  </div>
                  {formik.touched.signinagreement &&
                    formik.errors.signinagreement && (
                      <span style={{color: "#d32f2f"}}>
                        {formik.errors.signinagreement}
                      </span>
                    )}
                  <div>
                    {" "}
                    {isVisible && registerResponseMsg ? (
                      <span style={{color: "green"}}>
                        {registerResponseMsg}
                      </span>
                    ) : null}
                    {registerErrorResponse ? (
                      <span style={{color: "rgb(211, 47, 47)"}}>
                        {registerErrorResponse}
                      </span>
                    ) : null}{" "}
                  </div>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={12} md={12} sm={12} xl={12}>
                      <button
                        name="Submit"
                        className="signup-registerBtn  cursor_point"
                        type="submit"
                        onClick={() => {
                          setRegisterResponseMsg("");
                          setRegisterErrorResponse("");
                        }}
                      >
                        Register
                      </button>
                    </Grid>
                    <div />
                  </Grid>
                </form>

                {/* SOCIAL ICONS (LINKEDIN,GOOGLE,FACEBOOK) start */}
                <p> or sign up by using :</p>
                <div>
                  <span style={{color: "rgb(211, 47, 47)"}}>
                    {gloginErrorMessage}
                  </span>
                </div>

                <Grid container className="socialIcons" style={{marginLeft: 0}}>
                  <Grid>
                    {/* <div className="signup-google-btn cursor_point"> */}
                    {/* <div className="signup-google-icon-wrapper"> */}
                    {/* <img
                          className="google-icon"
                          src="https://upload.wikimedia.org/wikipedia/commons/5/53/Google_%22G%22_Logo.svg"
                        /> */}
                    {/* <Icon
                        icon="logos:google-icon"
                        className="signup-google-icon"
                      /> */}
                    {/* </div> */}
                    {/* <p className="signup-btn-text"> */}
                    {/* <b>Sign in with google</b> */}
                    <GoogleLogin
                      clientId={clientId}
                      buttonText="Sign in with Google"
                      onSuccess={onSuccess}
                      // onClick={onSuccess}
                      // onFailure={onFailure}
                      cookiePolicy={"single_host_origin"}
                      isSignedIn={false}
                      className="login-google-sign"
                    ></GoogleLogin>
                    {/* </p>
                  </div> */}
                  </Grid>

                  {/* <Grid item xs={2} style={{ color: "#1EC28B ", fontSize: 45 }}>
                  <Icon
                    onClick={signIn}
                    icon="ant-design:google-square-filled"
                    color="#1ec28b"
                  />
                </Grid>
                <Grid item xs={2} style={{ color: "#1EC28B ", fontSize: 45 }}>
                  <Icon icon="ant-design:facebook-filled" color="#1ec28b" />
                  <FacebookLogin
                    appId="825386758444401"
                    fields="name,email,picture"
                    callback={responseFacebook}
                  />
                </Grid>
                <Grid
                  item
                  xs={2}
                  style={{ color: "#1EC28B ", fontSize: 41, marginTop: 4 }}
                >
                  <Icon
                    onClick={linkedInLogin}
                    icon="akar-icons:linkedin-box-fill"
                    color="#1ec28b"
                  />
                </Grid> */}
                </Grid>
                {/* Social icons end */}
                <p>
                  Already have an account?
                  <Link to="/login" style={{textDecoration: "none"}}>
                    <span
                      style={{color: "#4f8df9", fontWeight: 500, marginLeft: 8}}
                    >
                      Log in
                    </span>
                  </Link>
                </p>
              </div>
            </Grid>
          </Grid>
        </Container>
      </ScrollTop>
    </div>
  );
}

export default SignUp;