import React, { useEffect, useState } from "react";
import {
  Button,
} from "@mui/material";
import { useForm} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import TravellerDetails from "./TravellerDetails";
import StartTrip from "./DestinationDetail";
import TripDetails from "./TripDetails";
import FullDetails from "./FullDetails";
import "./CreateTripNew.css";
import validation from "./Validation";
import { useSelector,useDispatch } from "react-redux";
import axios from "axios";
import { Link,useNavigate } from "react-router-dom";
import { RemoveExtraField,FormattingField,countryObject, airlineObject, languageObject } from "./RemoveExtraField";
import CircularProgress from "@mui/material/CircularProgress";
import {EditTrip, getTripdata} from "./CreateTripApiCall";


const VerticalStepperEdit = (props) => {
  const {steps,activeStep,setActiveStep,stepCompleted,setStepCompleted,customError,setCustomError}=props
    const { isLoggedIn, user } = useSelector((state) => state.auth);

  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const navigate=useNavigate()

 
  let validationSchema = validation(activeStep);



  const {
    register,
    control,
    Controller,
    reset,
    handleSubmit,
    getValues,
    setValue,
    watch,
    methods,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    // defaultValues:{...finalIni}
  });
  const values = getValues();
  const watchFields = watch();

  console.log("errors", errors);
  console.log("values..", values);

  
  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ? navigate("/dashboard") : prevActiveStep - 1,
    );
    if(stepCompleted[activeStep+1]!=="1"){
      let array=stepCompleted
      stepCompleted[activeStep]="0"
      setStepCompleted([...array]);
    }
   
  }


  useEffect(() => {
    getTripdata(props.id).then(response=>{
      reset(response)
      console.log(response,"responseinedit")
    })
  
   
    
  }, []);

  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 2;
  };

  const onSubmit = (values) => {
    if(isLastStep()){
      if(isLoggedIn){
        values["user_id"] = user.user_id;
       }
      setLoading(true)
      EditTrip(values).then(response=>{
              console.log(response, "response");
              setLoading(false)
              setValue("trip_id",response.id)
              setActiveStep(activeStep+1)
              let array=stepCompleted
              stepCompleted[activeStep+1]="1"
              setStepCompleted([...array]);
            })
    }
    else {
       let array=stepCompleted
         stepCompleted[activeStep+1]="1"
         setStepCompleted([...array]);
         setActiveStep(activeStep + 1);
     }
   };

  return (
    <div>
     

      <div className="form_body">
      

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div>
            {activeStep === 0 ? (
              <TravellerDetails
                control={control}
                Controller={Controller}
                values={values}
                setValue={setValue}
                reset={reset}
                errors={errors}
                register={register}
                customError={customError}
                setCustomError={setCustomError}  
                page="createTrip_edit"  
              />
            ) : activeStep === 1 ? (
              <StartTrip
                control={control}
                Controller={Controller}
                values={values}
                setValue={setValue}
                errors={errors}
                register={register}
                customError={customError}
                setCustomError={setCustomError}    
              />
            ) : activeStep === 2 ? (
              <TripDetails
                control={control}
                Controller={Controller}
                values={values}
                setValue={setValue}
                errors={errors}
                register={register}
                customError={customError}
                setCustomError={setCustomError}    
              />
            ) : activeStep === 3 ? (
              <FullDetails
              page="createTrip_edit"
                control={control}
                Controller={Controller}
                values={values}
                setValue={setValue}
                errors={errors}
                register={register}
                customError={customError}
                setCustomError={setCustomError}    
              />
            ) : null}
          </div>
          {activeStep !== steps.length - 1 && (
            <div>
               <Button
              style={{margin:"1rem"}}
              type="button"
              onClick={handleBack}
              // disabled={
              //   values.Declaration !== true && activeStep === steps.length - 2
              // }
              className="stepperNextBtn"
              variant="contained"
              color="primary">
                 Back
              </Button>
              <Button
                type="submit"
                disabled={
                  (values.Declaration !== true && activeStep === steps.length - 2)||loading
                }
                className="stepperNextBtn"
                variant="contained"
                color="primary"
              >
                {activeStep === steps.length - 2 ? "Submit" : "Next"}
                {loading&&<CircularProgress
                size={24}
                sx={{
              color: "#ff8d8d",
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />}
              </Button>
             
              {/* {activeStep !== 0 && (
                <button
                  type="button"
                  onClick={() => {
                    setActiveStep(activeStep - 1);
                  }}
                >
                  Back
                </button>
              )} */}
            </div>
          )}
        </form>
      </div>
    </div>
  );
};

export default VerticalStepperEdit;

