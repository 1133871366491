import React from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import "../../CSS/dashboardpage.css";
import {
  Button,
  Card,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  // Icon,
  Modal,
  Popover,
} from "@mui/material";
import SidebarLink from "../SidebarLink";
import NavBar from "../../Components/Header/Header";
import EditProfile from "../EditProfile";
import DashboardHeader from "../DashboardHeader";
import { useDispatch, useSelector } from "react-redux";
import { Link, Navigate,useNavigate } from "react-router-dom";
import ArrowCircleDownSharpIcon from "@mui/icons-material/ArrowCircleDownSharp";
import EastIcon from "@mui/icons-material/East";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FlightIcon from "@mui/icons-material/Flight";
import LanguageIcon from "@mui/icons-material/Language";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import RouteIcon from "@mui/icons-material/Route";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EditIcon from "@mui/icons-material/Edit";
import Swal from "sweetalert2";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ConnectPeoplePopup from "../../Pages/ConnectPeople/ConnectPeoplePopup";
import { Container } from "@mui/system";
import { Icon } from "@iconify/react";
import TripCount from "../../UpcomingTrip_count/TripCount";
import isMobile from "../../Pages/CreateTripNew/Assets/Ismobile";

// import DashboardHeader from "../DashboardHeader"
const Dashboard = () => {
  const { auth } = useSelector((state) => state);
  // if (!auth.user) {
  //   return <Navigate replace to="/" />;
  // }

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  console.log("user_id", user.user_id);

  const [upcomingTrip, setupcomingTrip] = useState([]);
  const [closeIcon, setCloseicon] = useState(false);
  const [deleteAgree, setDeleteAgree] = useState(false);
  const [deleteId, setDeleteId] = useState();
  const [deleteopen, setdeleteOpen] = React.useState(false);
  // const [deleteopen, setDeleteOpen] = React.useState(false);
  const [upcomingTriperror, setupcomingTriperror] = useState();
  const [open, setOpen] = React.useState(false);
  const [canCreate,setCancreate]=useState()


  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");
	

    
    axios
      .get(
        `${domain}/upcoming-trips/${user.user_id}/`
      )
      .then((response) => {
        console.log(response, "upcoming trip response");

        if (response.data.Trips_created_by_me) {
          console.log(
            response.data.Trips_created_by_me,
            "upcoming trip response data"
          );
          setupcomingTrip(response.data.Trips_created_by_me);
        }
      })
      .catch((error) => {
        // Error
        // console.log("status : ",error.response.status);
        // console.log("data : ",error.response.data);
        if (error.response.status === 400) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log("data : ",error.response.data);
          console.log("data : ", error.response.data.message);
          setupcomingTriperror(error.response.data.message);
          // console.log("status : ",error.response.status);
          // console.log("headers : ",error.response.headers);
        }
      });

      if(isMobile()){
        window.scrollTo(0, 0);
        console.log("Mobile....")
       }else{
        window.scrollTo(0,0);
        console.log("Desktop....")
    
       }
  }, []);

  const deleteCard = () => {
    //  setOpen(false)
    console.log(id, "card id");


    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");
	
	



    if (deleteId) {
      axios
        .delete(
          `${domain}/create-trip/${deleteId}/`
        )
        .then((response) => {
          console.log(response, "update delete response");

          const newList = upcomingTrip.filter((card) => {
            return card.id !== deleteId;
          });
          console.log(newList, "FILTERED CARD...");
          setupcomingTrip(newList);

          if (response.data) {
            setOpen(false);

            console.log(response.data, " response delete profile");
            setDeleteAgree(true);
            Swal.fire({
              // position: 'center',
              customClass: {
                popup: "my-swal",
              },
              icon: "success",
              title: "successfully Deleted",
              showCloseButton: true,
              showConfirmButton: false,
              iconColor: "#1ec28b",
            });
            // Swal.fire({
            //   title: "Successully deleted",
            //   icon: "success",
            //   showConfirmButton: false,
            //   iconColor: "#1C7B31",
            //   background: "rgb(128 213 184 /30%)",
            // });
            console.log(TripCount(user.user_id),"afterDelete")
            TripCount(user.user_id).then((response)=>{
              console.log(response,"afterDelete")
               setCancreate(response,"afterDelete")
            })
          }
        })
        .catch((error) => {
          // Error
          // if (error.response.status === 400) {
          //   console.log(error.response.data, "error");
          //   console.log(error.response.status);
          //   // console.log(error.response.headers);
          //   // setResponseMessage(null);
          // }
        });
    }
  };

  const modalOpen = Boolean(closeIcon);
  const id = modalOpen ? "simple-popover" : undefined;

  console.log(upcomingTrip, "FILTERED CARDS");

  // INVITATION RECIEVED LISTS

  // const { isLoggedIn, user } = useSelector((state) => state.auth);
  console.log("user_id", user.user_id);
  const [recievedinvite, setrecievedinvite] = useState([]);
  const [dataToPopup, setDataToPopup] = useState();
  const [profiledata, setprofiledata] = useState({});
  const [receivedTriperror, setreceivedTriperror] = useState();
  // ------------iNVITATION API-----------------//
  useEffect(() => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .get(
        `${domain}/received-list/${user.user_id}/
      `
      )
      .then((response) => {
        console.log(response, "recieved trip response");

        if (response.data) {
          console.log(response.data, "recieved trip response data");
          setrecievedinvite(response.data);
        }
      })
      .catch((error) => {
        // Error
        // console.log("status : ",error.response.status);
        // console.log("data : ",error.response.data);
        if (error.response.status === 400) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log("data : ",error.response.data);
          console.log("data : ", error.response.data.message);
          setreceivedTriperror(error.response.data.message);
          // console.log("status : ",error.response.status);
          // console.log("headers : ",error.response.headers);
        }
      });
  }, []);

  // ---------------- ACCEPT BUTTON API--------------//

  const acceptinvitation = (connection_id) => {
    console.log(connection_id);

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .patch(
        `${domain}/accept-invitation/${connection_id}/`
      )
      .then((response) => {
        console.log(response, "recieved trip response");

        if (response.data) {
          console.log(response.data, "recieved trip response data");
          const newList = recievedinvite.filter((card) => {
            return connection_id !== card.connection_id;
          });
          setrecievedinvite(newList);
          Swal.fire({
            // position: 'center',
            customClass: {
              popup: "my-swal",
            },
            icon: "success",
            iconColor: "#1ec28b",
            title: "Invitation is accepted",
            showCloseButton: true,
            showConfirmButton: false,
            // background: 'red',
          });
        }
      })
      .catch((error) => {
        if (error.response.status === 400) {
          console.log(error.response.data, "error");
          console.log(error.response.status);
        }
      });
  };

  // ---------------- DELETE BUTTON API--------------//

  // const [deleteopen, setdeleteOpen] = React.useState(false);

  const handleClickOpen = () => {
    setdeleteOpen(true);
  };

  const handledeleteClose = () => {
    setdeleteOpen(false);
  };
  const [receiveddeleteAgree, setreceivedDeleteAgree] = useState(false);
  const [receiveddeleteId, setreceivedDeleteId] = useState("");

  const deleteCard1 = () => {
    //  setOpen(false)
    console.log(receiveddeleteId, "card id");
    if (receiveddeleteId !== "") {


      console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
      let domain = "";
      console.log(process.env, "domain env", window.location.hostname);
      if (window.location.hostname.trim() === "localhost") {
        domain = process.env.REACT_APP_LOCAL_API;
      } else if (window.location.hostname.trim() === "54.185.6.32") {
        domain = process.env.REACT_APP_UAT_API;
      } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
        domain = process.env.REACT_APP_PROD_UAT_API;
      }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
        domain = process.env.REACT_APP_PROD_API;
      }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
        domain = process.env.REACT_APP_PROD_API;
      }
      console.log(domain, "domain env end");


      axios
        .delete(
          `${domain}/delete-invitation/${receiveddeleteId}/`
        )
        .then((response) => {
          console.log(response, "update delete response");

          const newList = recievedinvite.filter((card) => {
            return card.connection_id !== receiveddeleteId;
          });

          console.log(newList, "FILTERED CARD...");
          setrecievedinvite(newList);

          if (response.data) {
            setdeleteOpen(false);

            console.log(response.data, " response delete profile");
            setreceivedDeleteAgree(true);
            Swal.fire({
              // position: 'center',
              customClass: {
                popup: "my-swal",
              },
              icon: "success",
              iconColor: "#1ec28b",
              title: "successfully Deleted",
              showCloseButton: true,
              showConfirmButton: false,
              closeButtonColor: "#fffffff",
            });
          }
        })
        .catch((error) => {
          // Error
          if (error.response.status === 400) {
            console.log(error.response.data, "error");
            console.log(error.response.status);
            // console.log(error.response.headers);
            // setResponseMessage(null);
          }
        });
    }
  };

  //  ----------------DETAILS CONNECT PEOPLE POPUP-----------------------//
  const handleClick = (item, data) => {
    setDataToPopup(item);
    setCloseicon(true);
    setprofiledata(data);
  };

  const handleCloseDetails = () => {
    {
      setCloseicon(false);
    }
  };
  // const handleClose = () => {
  //   {
  //     setCloseicon(false);
  //   }
  // };
  const recentOpen = Boolean(closeIcon);
  const recentId = recentOpen ? "simple-popover" : undefined;

  // ------------------------- cards for load more result  --------------------------------------------
  // const imagePerRow = 2;
  const upcomingRow = 2;
  const [upcomingnext, setUpcomingNext] = useState(upcomingRow);
  const handleMoreUpcomingTrip = () => {
    setUpcomingNext(upcomingnext + upcomingRow);
  };

  const recentInviteRow = 2;
  const [recentNext, setRecentNext] = useState(recentInviteRow);
  const handleMoreRecentInvite = () => {
    setRecentNext(recentNext + recentInviteRow);
  };
  const navigate = useNavigate();
useEffect(
   ()=>{if(user.update_profile_details!=="False"){
   return navigate('/edit-profile',{state:'true'})
  }}
,[]
)


  // if (user.update_profile_details == "False") {
    return (
      <>
        <DashboardHeader can={canCreate}></DashboardHeader>
        <div className="dashboard-main">
          <div className="dashbaord-sidebar-links">
            <Box
              sx={{
                width: 300,
                height: 600,
                backgroundColor: "",
                "&:hover": {
                  backgroundColor: "aliceblue",
                },
              }}
            >
              <div>
                {/* <div>Dashboard</div>
                <div>Setting</div>
                <div>Logout</div> */}
                <SidebarLink></SidebarLink>
              </div>
            </Box>
          </div>
          <div className="dashboard-formobile-main">
            <div>
              <Box className="dashbox">
                <InputBase
                  sx={{ p: "10px" }}
                  className="search-btn-dashboard"
                  placeholder="Search"
                />
                <IconButton
                  className="search-btn-icon"
                  type="submit"
                  sx={{ p: "10px" }}
                  aria-label="search"
                >
                  <SearchIcon />
                </IconButton>
                <div>
                  <p className="upcoming-text-dashboard">Upcoming Trips </p>
                </div>
                {/* {upcomingTrip.length > 0 ? ( */}
                {upcomingTrip.length > 0 ? (
                  <Grid container spacing={2} className="">
                    <Grid item lg={12} className="upcomingTripContainer">
                      {upcomingTrip.slice(0, upcomingnext).map((post) => (
                        <Card className="upcoming-card-up-coming1">
                          <CardContent>
                            <Grid
                              container
                              spacing={2}
                              style={{
                                display: "flex",
                                justifyContent: "space-between",
                              }}
                            >
                              <Grid
                                item
                                lg={6}
                                style={{ textAlign: "initial" }}
                              >
                                <b>{post.name}</b>
                              </Grid>
                              <Grid
                                item
                                lg={6}
                                style={{ textAlign: "end", color: "pink" }}
                              >
                                <DeleteForeverIcon
                                  onClick={() => {
                                    setDeleteId(post.id);
                                    setDeleteAgree(false);
                                    setOpen(true);
                                  }}
                                  // onClick={() => deleteCard(post.id)}
                                ></DeleteForeverIcon>

                                {/* <Dialog
                                  classmName="upcoming-delete"
                                  open={deleteopen}
                                  onClose={handleClose}
                                  aria-labelledby="alert-dialog-title"
                                  aria-describedby="alert-dialog-description"
                                  PaperProps={{
                                    style: {
                                      minHeight: "75vh",
                                      minWidth: "40vw",
                                      backgroundColor: "rgb(30 194 139)",
                                    },
                                  }}
                                  BackdropProps={{
                                    style: {
                                      backgroundColor: "transparent",
                                    },
                                  }}
                                >
                                  <div classmName="upcoming-delete-dalog">
                                    <DialogTitle id="alert-dialog-title">
                                      <div>
                                        <Icon icon="mi:delete" />
                                        Delete
                                      </div>
                                      <hr />
                                    </DialogTitle>
                                    <DialogContent>
                                      <DialogContentText id="alert-dialog-description">
                                        Are you sure?
                                      </DialogContentText>
                                    </DialogContent>
                                    <DialogActions>
                                      <Button onClick={handleClose}>No</Button>
                                      <Button
                                        onClick={() => {
                                          deleteCard();
                                        }}
                                        autoFocus
                                      >
                                        Yes
                                      </Button>
                                    </DialogActions>
                                  </div>
                                </Dialog> */}
                              </Grid>
                            </Grid>
                          </CardContent>
                          <div className="upcoming-card-recentcontainer">
                            <div className="upcoming-card-up-coming-inner">
                              <img
                                className="upcoming-cardrecent-img"
                                variant="top"
                                // src="./Assets/Images/I1.png"
                                src={
                                  post.photo == null
                                    ? "./Assets/Images/Favicon 25 WB _ DV.webp"
                                    : post.photo
                                }
                                alt="..."
                              />
                            </div>
                          </div>

                          <CardContent
                            style={{
                              fontSize: 12,
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                style={{ display: "flex", columnGap: 16 }}
                              >
                                <ModeOfTravelIcon
                                  style={{
                                    color: "#1ec38b",
                                    marginTop: 4,
                                  }}
                                ></ModeOfTravelIcon>
                                <div className="invitation-card-places">
                                  {post.trip_name}
                                </div>
                              </Grid>
                              <Grid
                                item
                                xs={12}
                                style={{ display: "flex", columnGap: 16 }}
                              >
                                <RouteIcon
                                  style={{
                                    color: "#1ec28b",
                                    transform: "rotate(90deg)",
                                    marginTop: 4,
                                  }}
                                />
                                <div className="invitation-card-places">
                                  {/* ----------------------------------- */}
                                  {/* {post.trip_details.flying_from} */}
                                  {post.flying_from.split(",")[0]}
                                  {post.transit1 ? (
                                    <span>
                                      <EastIcon
                                        style={{
                                          color: "#1ec38b",
                                          marginBottom: "-7px",
                                        }}
                                      />
                                      {post.transit1.split(",")[0]}
                                    </span>
                                  ) : null}
                                  {post.transit2 ? (
                                    <span>
                                      <EastIcon
                                        style={{
                                          color: "#1ec38b",
                                          marginBottom: "-7px",
                                        }}
                                      />
                                      {post.transit2.split(",")[0]}
                                    </span>
                                  ) : null}
                                  {post.transit3 ? (
                                    <span>
                                      <EastIcon
                                        style={{
                                          color: "#1ec38b",
                                          marginBottom: "-7px",
                                        }}
                                      />
                                      {post.transit3.split(",")[0]}
                                    </span>
                                  ) : null}
                                  {post.transit4 ? (
                                    <span>
                                      <EastIcon
                                        style={{
                                          color: "#1ec38b",
                                          marginBottom: "-7px",
                                        }}
                                      />
                                      {post.transit4.split(",")[0]}
                                    </span>
                                  ) : null}
                                  <EastIcon
                                    style={{
                                      color: "#1ec38b",
                                      marginBottom: "-7px",
                                    }}
                                  />
                                  {post.destination.split(",")[0]}
                                  {/* {post.destination} */}
                                  {/* ----------------------------------- */}
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                style={{ display: "flex", columnGap: 16 }}
                              >
                                <CalendarMonthOutlinedIcon
                                  style={{
                                    color: "#1ec38b",
                                    marginTop: 4,
                                  }}
                                ></CalendarMonthOutlinedIcon>
                                <div className="invitation-card-places">
                                  {post.booking_status === 2
                                    ? `Date Range : ${post.date_range_from} to ${post.date_range_to}`
                                    : `Departing on : ${post.departing_on}`}
                                </div>{" "}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                style={{ display: "flex", columnGap: 16 }}
                              >
                                <FlightIcon
                                  style={{
                                    color: "#1ec38b",
                                    marginTop: 4,
                                  }}
                                ></FlightIcon>
                                <div className="invitation-card-places">
                                  {post.booking_status === 2
                                    ? "yet to book"
                                    : "booked"}
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                style={{ display: "flex", columnGap: 16 }}
                              >
                                <LanguageIcon
                                  style={{
                                    color: "#1ec38b",
                                    marginTop: 4,
                                  }}
                                ></LanguageIcon>{" "}
                                <div className="invitation-card-places">
                                  {post.prefered_language}
                                </div>
                              </Grid>
                            </Grid>

                            {post.category == 1 ? (
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ display: "flex", columnGap: 16 }}
                                >
                                  <AttachMoneyIcon
                                    style={{ color: "#1ec38b", marginTop: 4 }}
                                  ></AttachMoneyIcon>
                                  <div className="invitation-card-places">
                                    {/* $ &nbsp; {post.tip_expected} */}
                                    {/^([^0-9]*)$/.test(post.tip_expected)
                                      ? post.tip_expected
                                      : `${post.tip_expected} USD equiv`}
                                  </div>
                                </Grid>
                              </Grid>
                            ) : null}
                            {/* </Grid> */}
                          </CardContent>

                          {/* <Grid
                          style={{ marginTop: "4px", marginBottom: "32px" }}
                        >
                          <div
                            style={{
                              color: "black",
                              fontSize: "12px",
                            }}
                          >
                            <span
                              style={{ color: "#f27474", fontWeight: "700" }}
                            >
                              ACCEPTED
                            </span>
                          </div>
                        </Grid> */}
                          <Button
                            className="upcoming-accept-btn"
                            // onClick={handleClick}
                            onClick={() => handleClick(post)}
                          >
                            Details
                          </Button>
                          <Modal id={id} open={modalOpen} closeIcon={closeIcon}>
                            <ConnectPeoplePopup
                              onChildClick={handleCloseDetails}
                              connectUserData={dataToPopup}
                              userdetails={profiledata}
                              page="upcomingtrip"
                            />
                          </Modal>

                          <Button
                            className="upcoming-cancel-btn"
                            // onClick={() => patchCard(post.editTripid)}
                            LinkComponent={Link}
                            to={`/create-a-trip-edit/${post.id}`}
                          >
                            edit
                          </Button>
                        </Card>
                      ))}
                    </Grid>
                  </Grid>
                ) : (
                  upcomingTriperror
                )}

                <Container>
                  <Dialog
                    open={open}
                    onClose={handleClose}
                    // aria-labelledby="alert-dialog-title"
                    // aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        minHeight: "40vh",
                        minWidth: "35vw",
                        // backgroundColor: "rgb(128 213 184 /40%)",
                        boxShadow: "none",
                        border: "2px solid rgb(128 213 184 / 100%)",
                        // strokeWidth: "1px",
                        // stroke: "rgb(128 213 184 )",
                        borderRadius: 20,
                      },
                    }}
                    BackdropProps={{
                      style: {
                        // backgroundColor: "rgba(255,255,255,60%)",
                        backdrop: "blur(2px)",
                      },
                    }}
                  >
                    <div className="upcoming-delete-dalog">
                      <DialogTitle id="alert-dialog-title">
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            marginRight: "35px",
                          }}
                        >
                          <Icon
                            className="upcoming-deleteConfirmationIcon"
                            icon="fluent:delete-12-filled"
                          />
                          Delete
                        </div>
                        <hr
                          style={{
                            height: "1px",
                            borderWidth: 0,
                            color: "#1ec28b",
                            backgroundColor: "#1ec28b",
                            marginLeft: "50px",
                            marginRight: "50px",
                          }}
                        />
                      </DialogTitle>
                      <DialogContent
                        style={{
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <h2 className="upcoming-areUSure"> Are you sure?</h2>
                      </DialogContent>
                      <DialogActions className="upcoming-buttonsContainer">
                        <Button
                          id="btn"
                          className="upcoming-yesButton"
                          onClick={() => {
                            deleteCard();
                          }}
                        >
                          Yes
                        </Button>
                        <Button className="upcoming-noButton" onClick={handleClose}>
                          No
                        </Button>
                      </DialogActions>
                    </div>
                  </Dialog>
                </Container>

                {upcomingnext < upcomingTrip.length && (
                  <Grid style={{ marginTop: "20px", marginBottom: "20px" }}>
                    <Button
                      variant="outlined"
                      className="exploreButton"
                      sx={{
                        color: "black",
                        borderColor: "black",
                        borderRadius: "5px",
                        fontSize: "12px",
                      }}
                      onClick={handleMoreUpcomingTrip}
                    >
                      EXPLORE MORE
                    </Button>
                  </Grid>
                )}

                <div>
                  <p className="recent-text-dashboard">Recent Invites</p>
                </div>
                {recievedinvite.length > 0 ? (
                  // <Grid container spacing={2} className="dashboard-page-upcoming">
                  <Grid container spacing={2} className="">
                    <Grid item lg={12} className="dashpageboardTripContainer">
                    {recievedinvite
                        .slice(0, recentNext)
                        .map((received, index) => {
                          return (
                            <Card className="dashboard-invitation-card-receivedList1">
                              <CardContent>
                                <Grid
                                  container
                                  spacing={2}
                                  style={{
                                    display: "flex",
                                    justifyContent: "space-between",
                                  }}
                                >
                                  <Grid
                                    item
                                    xs={6}
                                    style={{ textAlign: "initial" }}
                                  >
                                    <b> {received.trip_details.name}</b>
                                  </Grid>
                                  <Grid
                                    item
                                    xs={6}
                                    className="invitation-card-deletebutton"
                                  >
                                    <DeleteForeverIcon
                                      onClick={() => {
                                        setreceivedDeleteId(
                                          received.connection_id
                                        );
                                        setreceivedDeleteAgree(false);
                                        setdeleteOpen(true);
                                      }}
                                    ></DeleteForeverIcon>
                                  </Grid>
                                </Grid>
                              </CardContent>
                              <div className="invitation-card-recentcontainer">
                                <div className="invitation-card-receivedList-inner">
                                  <img
                                    className="invitation-cardrecent-img"
                                    variant="top"
                                    src={
                                      received.trip_details.photo == null
                                        ? "./Assets/Images/Favicon 25 WB _ DV.webp"
                                        : received.trip_details.photo
                                    }
                                    alt="..."
                                  />
                                </div>
                              </div>

                              <CardContent
                                style={{
                                  fontSize: 12,
                                }}
                              >
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ display: "flex", columnGap: 16 }}
                                  >
                                    <ModeOfTravelIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginTop: 4,
                                      }}
                                    ></ModeOfTravelIcon>
                                    <div className="invitation-card-places">
                                      {received.trip_details.trip_name}
                                    </div>
                                  </Grid>

                                  <Grid
                                    item
                                    xs={12}
                                    style={{ display: "flex", columnGap: 16 }}
                                  >
                                    <RouteIcon
                                      style={{
                                        color: "#1ec28b",
                                        transform: "rotate(90deg)",
                                        marginTop: 4,
                                      }}
                                    />
                                    <div className="invitation-card-places">
                                      {
                                        received.trip_details.flying_from.split(
                                          ","
                                        )[0]
                                      }
                                      {received.trip_details.transit1 ? (
                                        <span>
                                          <EastIcon
                                            style={{
                                              color: "#1ec38b",
                                              marginBottom: "-7px",
                                            }}
                                          />
                                          {received.trip_details.transit1.split(",")[0]}
                                        </span>
                                      ) : null}
                                      {received.trip_details.transit2 ? (
                                        <span>
                                          <EastIcon
                                            style={{
                                              color: "#1ec38b",
                                              marginBottom: "-7px",
                                            }}
                                          />
                                          {received.trip_details.transit2.split(",")[0]}
                                        </span>
                                      ) : null}
                                      {received.trip_details.transit3 ? (
                                        <span>
                                          <EastIcon
                                            style={{
                                              color: "#1ec38b",
                                              marginBottom: "-7px",
                                            }}
                                          />
                                          {received.trip_details.transit3.split(",")[0]}
                                        </span>
                                      ) : null}
                                      {received.trip_details.transit4 ? (
                                        <span>
                                          <EastIcon
                                            style={{
                                              color: "#1ec38b",
                                              marginBottom: "-7px",
                                            }}
                                          />
                                          {received.trip_details.transit4.split(",")[0]}
                                        </span>
                                      ) : null}
                                      <EastIcon
                                        style={{
                                          color: "#1ec38b",
                                          marginBottom: "-7px",
                                        }}
                                      />
                                      {
                                        received.trip_details.destination.split(
                                          ","
                                        )[0]
                                      }
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ display: "flex", columnGap: 16 }}
                                  >
                                    <CalendarMonthOutlinedIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginTop: 4,
                                      }}
                                    ></CalendarMonthOutlinedIcon>
                                    <div className="invitation-card-places">
                                      {received.trip_details.booking_status === 2
                                        ? `Date Range : ${received.trip_details.date_range_from} to ${received.trip_details.date_range_to}`
                                        : `Departing on : ${received.trip_details.departing_on}`}
                                    </div>{" "}
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ display: "flex", columnGap: 16 }}
                                  >
                                    <FlightIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginTop: 4,
                                      }}
                                    ></FlightIcon>
                                    <div className="invitation-card-places">
                                      {received.trip_details.booking_status ===
                                      2
                                        ? "yet to book"
                                        : "booked"}
                                    </div>
                                  </Grid>
                                </Grid>
                                <Grid container spacing={2}>
                                  <Grid
                                    item
                                    xs={12}
                                    style={{ display: "flex", columnGap: 16 }}
                                  >
                                    <LanguageIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginTop: 4,
                                      }}
                                    ></LanguageIcon>{" "}
                                    <div className="invitation-card-places">
                                      {received.trip_details.prefered_language}
                                    </div>
                                  </Grid>
                                </Grid>
                                {received.trip_details.category == 1 ? (
                                  <Grid container spacing={2}>
                                    <Grid
                                      item
                                      xs={12}
                                      style={{ display: "flex", columnGap: 16 }}
                                    >
                                      <AttachMoneyIcon
                                        style={{
                                          color: "#1ec38b",
                                          marginTop: 4,
                                        }}
                                      ></AttachMoneyIcon>
                                      <div className="invitation-card-places">
                                        {/* $&nbsp;{received.trip_details.tip_expected} */}
                                        {/^([^0-9]*)$/.test(
                                          received.trip_details.tip_expected
                                        )
                                          ? received.trip_details.tip_expected
                                          : `${received.trip_details.tip_expected} USD equiv`}
                                      </div>
                                    </Grid>
                                  </Grid>
                                ) : null}{" "}
                              </CardContent>
                              <Button
                                className="invitation-accept-btn"
                                onClick={() =>
                                  handleClick(received.trip_details)
                                }
                              >
                                Details
                              </Button>
                              <Modal
                                id={id}
                                open={modalOpen}
                                closeIcon={closeIcon}
                              >
                                <ConnectPeoplePopup
                                  onChildClick={handleCloseDetails}
                                  connectUserData={dataToPopup}
                                  userdetails={profiledata}
                                  page="upcomingtrip"
                                />
                              </Modal>
                              {/* <Popover id={id} open={recentOpen} closeIcon={closeIcon}>
                        <ConnectPeoplePopup
                          onChildClick={handleClose}
                          connectUserData={dataToPopup}
                          userdetails={profiledata}
                          page="invitation"
                        />
                      </Popover> */}
                              <Button
                                className="invitation-cancel-btn"
                                onClick={() =>
                                  acceptinvitation(received.connection_id)
                                }
                              >
                                Accept
                              </Button>
                            </Card>
                          );
                        })}
                    </Grid>
                  </Grid>
                ) : (
                  receivedTriperror
                )}
                {/* {next < recievedinvite?.length && (
              <Button
                variant="outlined"
                sx={{
                  color: "black",
                  borderColor: "black",
                  borderRadius: "5px",
                  fontSize: "12px",
                }}
                onClick={handleMoreImage}
              >
                EXPLORE MORE
              </Button>
            )} */}
                <Container>
                  <Dialog
                    open={deleteopen}
                    onClose={handledeleteClose}
                    // aria-labelledby="alert-dialog-title"
                    // aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        minHeight: "40vh",
                        minWidth: "35vw",
                        boxShadow: "none",
                        border: "2px solid rgb(128 213 184 / 100%)",
                        // strokeWidth: "1px",
                        // stroke: "rgb(128 213 184 )",
                        borderRadius: 20,
                      },
                    }}
                    BackdropProps={{
                      style: {
                        backdrop: "blur(2px)",
                      },
                    }}
                  >
                    <div>
                      <DialogTitle id="alert-dialog-title">
                        <div className="upcoming-upcoming-delete-dalog">
                          <Icon
                            className="upcoming-deleteConfirmationIcon"
                            icon="fluent:delete-12-filled"
                          />
                          Delete
                        </div>
                        <hr
                          style={
                            {
                              // height: "1px",
                              // borderWidth: 0,
                              // color: "#1ec28b",
                              // backgroundColor: "#1ec28b",
                              // marginLeft: "50px",
                              // marginRight: "50px",
                            }
                          }
                          className="upcoming-Hrline"
                        />
                      </DialogTitle>
                      <DialogContent className="upcoming-upcoming-delete-dalog">
                        <h2 className="upcoming-areUSure"> Are you sure?</h2>
                      </DialogContent>
                      <DialogActions className="upcoming-buttonsContainer">
                        <Button
                          id="btn"
                          className="upcoming-yesButton"
                          // onClick={deleteCard}
                          onClick={() => {
                            deleteCard1();
                          }}
                        >
                          Yes
                        </Button>
                        <Button
                          className="upcoming-noButton"
                          onClick={handledeleteClose}
                        >
                          No
                        </Button>
                      </DialogActions>
                    </div>
                  </Dialog>
                </Container>
                {recentNext < recievedinvite.length && (
                  <Button
                    variant="outlined"
                    className="exploreButton"
                    sx={{
                      color: "black",
                      borderColor: "black",
                      borderRadius: "5px",
                      fontSize: "12px",
                    }}
                    onClick={handleMoreRecentInvite}
                  >
                    EXPLORE MORE
                  </Button>
                )}
              </Box>
            </div>
            <div>
              <EditProfile></EditProfile>
            </div>
          </div>
        </div>
      </>
    );
  // } else {
  //   return navigate('/edit-profile',{state:'true'})
  // }
};

export default Dashboard;
