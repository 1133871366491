import React, { Fragment, useState } from "react";
import {
  AppBar,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Container,
  CssBaseline,
  Drawer,
  FormControl,
  Grid,
  IconButton,
  Paper,
  TextField,
  Toolbar,
  Typography,
  InputLabel,
  MenuItem,
  InputAdornment,
  InputBase,
  styled,
  Popover,
  Modal,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Icon } from "@iconify/react";
import { CardImg } from "react-bootstrap";
import "../../CSS/HomePage.css";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowLeftRoundedIcon from "@mui/icons-material/ArrowLeftRounded";
// import ArrowForwardIcon from "@mui/icons-material/ArrowForward"
import ArrowRightRoundedIcon from "@mui/icons-material/ArrowRightRounded";
// import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ExploreCarousle from "./ExploreCarousle";
import Header from "../../Components/Header/Header";
import MenuIcon from "@mui/icons-material/Menu";
import Select, { SelectChangeEvent } from "@mui/material/Select";
// import { DatePicker, KeyboardDatePicker } from "@material-ui/pickers";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import SearchBar from "../../SearchBar/SearchBar";
import RouteIcon from "@mui/icons-material/Route";
import EastIcon from "@mui/icons-material/East";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FlightIcon from "@mui/icons-material/Flight";
import LanguageIcon from "@mui/icons-material/Language";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import axios from "axios";
import { useEffect } from "react";
import { CompressOutlined } from "@mui/icons-material";
import { useSelector } from "react-redux";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
import { display } from "@mui/system";
import ConnectPeoplePopup from "../ConnectPeople/ConnectPeoplePopup";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #ffffff",
  height: "200px",
  width: "30%",
};

const HomePage = () => {
  // const [selectedDate, handleDateChange] = useState("");
  // const sliderSettings = {
  //   slidesToShow: 3,
  //   slidesToScroll: 1,
  //   infinite: false,
  // };
const navigate=useNavigate()
  const [value, setValue] = React.useState(0);

  const { isLoggedIn, user } = useSelector((state) => state.auth);
  // console.log("user_id", user.user_id);

  const [latestPosts, setLatestPosts] = useState([]);
  const [companionSelfie, setCompanionSelfie] = useState([]);

  const [invitopen, setinvitopen] = React.useState(false);
  const handleinvitClose = () => {
    setinvitopen(false);
  };

  const [closeIcon, setCloseicon] = useState(false);
  const [dataToPopup, setDataToPopup] = useState();

  const handleClick = (item) => {
    if (isLoggedIn) {
      setDataToPopup(item);
      setCloseicon(true);
    } else {
      setinvitopen(true);
    }
  };
  const handleClose = () => {
    {
      setCloseicon(false);
    }
  };
  const open = Boolean(closeIcon);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .get(
        ` ${domain}/homepage/`
      )
      .then((response) => {
        console.log(response, "upcoming trip response");

        console.log(response.data, "upcoming trip response data");
        setLatestPosts(response.data.latest_post);
        setCompanionSelfie(response.data.companion_selfie);
        console.log(response.data.companion_selfie, "companion selfie");
      })
      .catch((error) => {
        // Error
        // console.log("status : ",error.response.status);
        // console.log("data : ",error.response.data);
        if (error.response.status === 400) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log("data : ",error.response.data);
          console.log("data : ", error.response.data.message);
          // console.log("status : ",error.response.status);
          // console.log("headers : ",error.response.headers);
        }
      });
  }, []);

  const PreviousBtn = (props) => {
    const { onClick } = props;
    return (
      <div className="slider-backward-arrow">
        <ArrowCircleLeftIcon className="csleftIcon" onClick={onClick} />
      </div>
    );
  };
  const NextBtn = (props) => {
    const { onClick } = props;
    return (
      <div className="slider-forward-arrow">
        <ArrowCircleRightIcon className="csrightIcon" onClick={onClick} />
      </div>
    );
  };
  const settings = {
    // dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    // nextArrow: <NextBtn />,
    // prevArrow: <PreviousBtn />,
    // autoplay: true,
    // autoplaySpeed: 2000,
    // pauseOnHover: true,
    arrows: true,
  };

  // =====================  ads card after searchBox ==============

  const [adsopen, setadsOpen] = React.useState(false);
  const handleadsOpen = () => setadsOpen(true);
  const handleadsClose = () => setadsOpen(false);


  const [homeadsopen, sethomeadsOpen] = React.useState(false);
  const handlehomeadsOpen = () => sethomeadsOpen(true);
  const handlehomeadsClose = () => sethomeadsOpen(false);



  const [homepage_banner_ad1_image_DV, sethomepage_banner_ad1_image_DV] = useState();
  const [homepage_banner_ad1_image_MV, sethomepage_banner_ad1_image_MV] = useState();
  
  const [homepage_banner_ad1_image_link, sethomepage_banner_ad1_image_link] = useState("");
  

  const [homepage_banner_ad2_image_DV, sethomepage_banner_ad2_image_DV] = useState();
  const [homepage_banner_ad2_image_MV, sethomepage_banner_ad2_image_MV] = useState();
  
  const [homepage_banner_ad2_image_link, sethomepage_banner_ad2_image_link] = useState("");

  useEffect(() => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");



    const responseData1 = axios
      .get(`${domain}/admin/ads-location/`)
      .then((response) => {
        console.log(response, "response ad1");
        let i;
        if (response) {
          for (i = 0; i < 7; i++) {
            if (response.data.response_data[i].ad_placement === "homepage_banner_ad1") {
              console.log(
                response.data.response_data[i].ad_placement,
                "addd homepage_banner_ad1"
              );

              sethomepage_banner_ad1_image_DV(response.data.response_data[i].desktop_ad);
              sethomepage_banner_ad1_image_MV(response.data.response_data[i].mobile_ad);
              sethomepage_banner_ad1_image_link(response.data.response_data[i].ad_link);
            }
            if (response.data.response_data[i].ad_placement === "homepage_banner_ad2") {
              console.log(
                response.data.response_data[i].ad_placement,
                "addd homepage_banner_ad2"
              );

              sethomepage_banner_ad2_image_DV(response.data.response_data[i].desktop_ad);
              sethomepage_banner_ad2_image_MV(response.data.response_data[i].mobile_ad);
              sethomepage_banner_ad2_image_link(response.data.response_data[i].ad_link);
            }
           
          }
        }
        // setAd1_image(response.data.response_data[0].desktop_ad)
        // setAd2_image(response.data.response_data[1].desktop_ad)
        // setAd1_image_link(response.data.response_data[0].ad_link)
        // setAd2_image_link(response.data.response_data[1].ad_link)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);






  return (
    <>
      <Header />

      <Container>
        <Grid container spacing={2} component="main" style={{ marginTop: 40 }}>
          <Grid id="" item xs={12} sm={12} md={6} xl={6} lg={6}>
            <div className="homeheading">
              <p>
                <span className="homeheadingText">
                  Find a &nbsp;
                  <span style={{ color: "#1ec28b" }}>Travel Companion,</span>
                </span>
              </p>
              <span className="homeheadingText">Share an</span>
              <span className="homeheadingText">
                {" "}
                <span style={{ color: "#1ec28b" }}> Experience.</span>{" "}
              </span>
            </div>
            <div className="homelandingContent">
              <p>
                Travel makes one modest. You see what a tiny place you occupy in
                the world”- Gustave Flaubert
              </p>
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            align="center"
            // component={Paper}
          >
            <div>
              <img
                src="./Assets/Images/HomePage_1.webp"
                alt="logo"
                // style={{ height: 510, width: 500, marginTop: -20 }}
                className="homelandingImage"
              />
            </div>
          </Grid>
        </Grid>
      </Container>
      <Container>
        <div className="homepage-searchbar-main">
          <SearchBar page="home" />
        </div>
      </Container>

      {/* desktop version */}

      <Container className="homepage-ads-image-desktopversion">
        {/* <div>
          <img
            src="./Assets/Images/Poster 1 _ DV.png"
            alt="Add"
            className="homepage-ads-image"
          />
        </div> */}
         <div className="homepage-ads-image-img-one-container">
         <a href={homepage_banner_ad1_image_link} target="_blank">

              <img
                // src="./Assets/Images/Poster 1 _ DV.webp"
                src={
                  homepage_banner_ad1_image_DV != "default"
                    ? homepage_banner_ad1_image_DV
                    : "./Assets/Images/Poster 1 _ DV.webp"
                }

                alt="ad's"
                className="homepage-ads-image"
              />
              </a>
               {homepage_banner_ad1_image_MV != "default" ? (
                ""
              ) : (
              <button
                onClick={handlehomeadsOpen}
                className="homepage-ads-image-img-one-btn"
              >
                More Info
              </button>
              )}
            </div>
            <Modal
              open={homeadsopen}
              onClose={handlehomeadsClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="home-footer-ads-box">
                <a
                  href="./student-community"
                  style={{ textDecoration: "none" }}
                >
                  <button className="home-footer-ads-btn">
                    <Icon icon="mdi:account-student" />
                    &nbsp;Student Community
                  </button>
                </a>
                {/* <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 25,
                    color: "#1ec28b",
                    fontWeight: 600,
                  }}
                >
                  OR
                </span> */}
                <div className="home-footer-ads-horizontal-line">
                  <h1 className="home-footer-ads-horizontal-text">
                    <span>OR</span>
                  </h1>
                </div>
                <a href="./desi-community" style={{ textDecoration: "none" }}>
                  <button
                    className="home-footer-ads-btn"
                    style={{ justifyContent: "center" }}
                  >
                    <Icon icon="ic:outline-devices-other" /> &nbsp;Other's
                  </button>
                </a>
              </Box>
            </Modal>
      </Container>

      {/* mobile version */}

      <Container className="homepage-ads-image-Mobileversion">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="home-footer-ads-img-one-container">
            <a href={homepage_banner_ad1_image_link} target="_blank">

              <img
                // src="../../Assets/Images/Poster 1 _ MV.webp"

                src={
                  homepage_banner_ad1_image_MV != "default"
                    ? homepage_banner_ad1_image_MV
                    : "../../Assets/Images/Poster 1 _ MV.webp"
                }
                alt="ad's"
                className="home-footer-ads-img-one"
              />
              </a>
               {homepage_banner_ad1_image_MV != "default" ? (
                ""
              ) : (
              <button
                onClick={handleadsOpen}
                className="home-footer-ads-img-one-btn"
              >
                More Info
              </button>
              )}
            </div>

            <Modal
              open={adsopen}
              onClose={handleadsClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="home-footer-ads-box">
                <a
                  href="./student-community"
                  style={{ textDecoration: "none" }}
                >
                  <button className="home-footer-ads-btn">
                    <Icon icon="mdi:account-student" />
                    &nbsp;Student Community
                  </button>
                </a>
                {/* <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 25,
                    color: "#1ec28b",
                    fontWeight: 600,
                  }}
                >
                  OR
                </span> */}
                <div className="home-footer-ads-horizontal-line">
                  <h1 className="home-footer-ads-horizontal-text">
                    <span>OR</span>
                  </h1>
                </div>
                <a href="./desi-community" style={{ textDecoration: "none" }}>
                  <button
                    className="home-footer-ads-btn"
                    style={{ justifyContent: "center" }}
                  >
                    <Icon icon="ic:outline-devices-other" /> &nbsp;Other's
                  </button>
                </a>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Container>

      <div className="homepage-howit-works-main">
        <div>
          <div className="howDesiContainer">
            <p className="howWorkHeading">How Desi TC Works?</p>
          </div>
          <div className="hrhowworkLine"></div>
        </div>
        <Container className="howItWorksContainer">
          {" "}
          <img
            className="howWorkImage"
            src="./Assets/Images/How it works.webp"
            alt="logo"
          />
          <div className="faqBtnContainer">
            <Link to="/how-it-works" className="faqHomePageLink">
              <Button className="faqHomePageBtn">
                more info
              </Button>
            </Link>
          </div>
        </Container>
      </div>
      <div className="">
        <div className="latestPostHeading">Latest Global Posts</div>
        <div className="hrlatestPostsectionLine"></div>
        <div className="latestPostsection">
          <Container className="">
            {latestPosts.length > 0 ? (
              <Grid container spacing={2} className="latestPostsMainContainer">
                <Grid
                  item
                  xl={12}
                  lg={12}
                  md={12}
                  sm={12}
                  xs={12}
                  className="latestPostContainer"
                >
                  {latestPosts.map((item) => (
                    <Card className="latestPostsCard">
                      {(item.category == 1 && item.user_type == 1) ||
                      (item.category == 1 && item.user_type == 2) ? (
                        <CardContent className="homePageContent">
                          <span>
                            <b className="latestPostBold">{item.name}</b> can be
                            your travel companion to{" "}
                            <b className="latestPostBold">
                              {item.destination.split(",")[0]}
                            </b>
                          </span>
                        </CardContent>
                      ) : item.category == 2 && item.user_type == 1 ? (
                        <CardContent className="homePageContent">
                          <span>
                            <b className="latestPostBold">{item.name}</b>&nbsp;
                            <span>is looking for a travel companion to </span>
                            <span>
                              {" "}
                              <b className="latestPostBold">
                                {item.destination.split(",")[0]}
                              </b>
                            </span>
                          </span>
                        </CardContent>
                      ) : (
                        <CardContent className="homePageContent">
                          <span>
                            <b className="latestPostBold">
                              {item.user_details.full_name}
                            </b>
                            &nbsp;
                            <span>is looking for a travel companion to </span>
                            <span>
                              {" "}
                              <b className="latestPostBold">
                                {item.destination.split(",")[0]}
                              </b>{" "}
                              on behalf of{" "}
                              <b className="latestPostBold">{item.name}</b>
                            </span>
                          </span>
                        </CardContent>
                      )}

                      <div className="homepageIMGContainer">
                        <div className="latestpostsImage">
                          <img
                            className="latestpostsIMG"
                            variant="top"
                            src={
                              item.photo
                                ? item.photo
                                : "./Assets/Images/Favicon 25 WB _ DV.webp"
                                // : "./Assets/Images/favicon.ico"
                            }
                            alt="..."
                          />
                        </div>
                      </div>

                      <CardContent
                        style={{
                          fontSize: 15,
                        }}
                      >
                        <Grid container spacing={2}>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{ display: "flex", columnGap: 16 }}
                          >
                            {/* <ModeOfTravelIcon
                              style={{
                                color: "#1ec38b",
                              }}
                            ></ModeOfTravelIcon>
                            <div className="home-latest-card-places">
                              {item.trip_name}
                            </div> */}
                          </Grid>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{ display: "flex", columnGap: 16 }}
                          >
                            <RouteIcon
                              style={{
                                color: "#1ec28b",
                                transform: "rotate(90deg)",
                              }}
                            />
                            <div className="home-latest-card-places">
                              {item.flying_from.split(",")[0]}
                              {item.transit1 ? (
                                <span>
                                  <EastIcon
                                    style={{
                                      color: "#1ec38b",
                                      marginBottom: "-7px",
                                    }}
                                  />
                                  {item.transit1.split(",")[0]}
                                </span>
                              ) : null}
                              {item.transit2 ? (
                                <span>
                                  <EastIcon
                                    style={{
                                      color: "#1ec38b",
                                      marginBottom: "-7px",
                                    }}
                                  />
                                  {item.transit2.split(",")[0]}
                                </span>
                              ) : null}
                              {item.transit3 ? (
                                <span>
                                  <EastIcon
                                    style={{
                                      color: "#1ec38b",
                                      marginBottom: "-7px",
                                    }}
                                  />
                                  {item.transit3.split(",")[0]}
                                </span>
                              ) : null}
                              {item.transit4 ? (
                                <span>
                                  <EastIcon
                                    style={{
                                      color: "#1ec38b",
                                      marginBottom: "-7px",
                                    }}
                                  />
                                  {item.transit4.split(",")[0]}
                                </span>
                              ) : null}
                              <EastIcon
                                style={{
                                  color: "#1ec38b",
                                  marginBottom: "-7px",
                                }}
                              />
                              {item.destination.split(",")[0]}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{ display: "flex", columnGap: 16 }}
                          >
                            <CalendarMonthOutlinedIcon
                              style={{
                                color: "#1ec38b",
                              }}
                            ></CalendarMonthOutlinedIcon>
                            <div className="home-latest-card-places">
                              {item.departing_on
                                ? `Departing Date : ${item.departing_on}`
                                : `Date Range : ${item.date_range_from} to ${item.date_range_to}`}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{ display: "flex", columnGap: 16 }}
                          >
                            <FlightIcon
                              style={{
                                color: "#1ec38b",
                              }}
                            ></FlightIcon>
                            <div className="home-latest-card-places">
                              {item.booking_status == 1
                                ? "Booked"
                                : "Yet to book"}
                            </div>
                          </Grid>
                          <Grid
                            item
                            xl={12}
                            lg={12}
                            md={12}
                            sm={12}
                            xs={12}
                            style={{ display: "flex", columnGap: 16 }}
                          >
                            <LanguageIcon
                              style={{
                                color: "#1ec38b",
                              }}
                            ></LanguageIcon>
                            <div className="home-latest-card-places">
                              {item.prefered_language}
                            </div>
                          </Grid>
                          {/* <Grid
                          item
                          xl={12}
                          lg={12}
                          md={12}
                          sm={12}
                          xs={12}
                          style={{ display: "flex", columnGap: 16 }}
                        >
                          <ModeOfTravelIcon
                            style={{
                              color: "#1ec38b",
                            }}
                          ></ModeOfTravelIcon>
                          <div className="home-latest-card-places">
                            {item.trip_name}
                          </div>
                        </Grid> */}
                          {item.category == 1 ? (
                            <Grid
                              item
                              xl={12}
                              lg={12}
                              md={12}
                              sm={12}
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <AttachMoneyIcon
                                style={{ color: "#1ec38b" }}
                              ></AttachMoneyIcon>

                              <div className="home-latest-card-places">
                                Tip I Expect -{/* {item.tip_expected} */}{" "}
                                {/^([^0-9]*)$/.test(item.tip_expected)
                                  ? item.tip_expected
                                  : `${item.tip_expected} USD equiv`}
                              </div>
                            </Grid>
                          ) : null}
                        </Grid>
                      </CardContent>

                      {/* <CardContent
                      style={{
                        fontSize: 12,
                        lineHeight: 2,
                      }}
                    >
                      <Grid
                        container
                        // style={{
                        //   marginTop: "10px",
                        //   marginLeft: "19px",
                        //   width: "85%",
                        //   textAlign: "initial",
                        //   lineHeight: "3",
                        // }}
                      >
                        <span style={{ fontWeight: 600 }}>
                          <RouteIcon
                            style={{
                              color: "#1ec28b",
                              transform: "rotate(90deg)",
                              marginBottom: "-7px",
                            }}
                          />
                          {item.flying_from.split(",")[0]}
                          {item.transit1 ? (
                            <span>
                              <EastIcon
                                style={{
                                  color: "#1ec38b",
                                  marginBottom: "-7px",
                                }}
                              />
                              {item.transit1}
                            </span>
                          ) : null}
                          {item.transit2 ? (
                            <span>
                              <EastIcon
                                style={{
                                  color: "#1ec38b",
                                  marginBottom: "-7px",
                                }}
                              />
                              {item.transit2}
                            </span>
                          ) : null}
                          {item.transit3 ? (
                            <span>
                              <EastIcon
                                style={{
                                  color: "#1ec38b",
                                  marginBottom: "-7px",
                                }}
                              />
                              {item.transit3}
                            </span>
                          ) : null}
                          {item.transit4 ? (
                            <span>
                              <EastIcon
                                style={{
                                  color: "#1ec38b",
                                  marginBottom: "-7px",
                                }}
                              />
                              {item.transit4}
                            </span>
                          ) : null}
                          <EastIcon
                            style={{
                              color: "#1ec38b",
                              marginBottom: "-7px",
                            }}
                          />
                          {item.destination.split(",")[0]}
                        </span>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: 10, marginLeft: 0 }}
                      >
                        <span style={{ fontWeight: 600 }}>
                          {" "}
                          <CalendarMonthOutlinedIcon
                            style={{
                              color: "#1ec38b",
                              marginBottom: "-5px",
                              marginRight: "4px",
                            }}
                          ></CalendarMonthOutlinedIcon>
                          {item.departing_on
                            ? `Departing Date - ${item.departing_on}`
                            : `Date Range -${item.date_range_from} to ${item.date_range_to}`}
                        </span>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: 10, marginLeft: 0 }}
                      >
                        <span style={{ fontWeight: 600 }}>
                          {" "}
                          <FlightIcon
                            style={{
                              color: "#1ec38b",
                              marginBottom: "-5px",
                              marginRight: "4px",
                            }}
                          ></FlightIcon>
                          {item.booking_status == 1 ? "Booked" : "Yet to book"}
                        </span>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: 10, marginLeft: 0 }}
                      >
                        <span style={{ fontWeight: 600 }}>
                          <LanguageIcon
                            style={{
                              color: "#1ec38b",
                              marginBottom: "-5px",
                              marginRight: "4px",
                            }}
                          ></LanguageIcon>
                          {item.prefered_language}
                        </span>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        style={{ marginTop: 10, marginLeft: 0 }}
                      >
                        <span style={{ fontWeight: 600 }}>
                          <ModeOfTravelIcon
                            style={{
                              color: "#1ec38b",
                              marginBottom: "-5px",
                              marginRight: "4px",
                            }}
                          ></ModeOfTravelIcon>
                          {item.trip_name}
                        </span>
                      </Grid>
                      {item.category == 1 ? (
                        <Grid
                          container
                          spacing={2}
                          style={{ marginTop: 10, marginLeft: 0 }}
                        >
                          <AttachMoneyIcon
                            style={{ color: "#1ec38b" }}
                          ></AttachMoneyIcon>
                          <span style={{ fontWeight: 600 }}>
                            Tip I Expect - {item.tip_expected}
                          </span>
                        </Grid>
                      ) : null}
                    </CardContent> */}

                      <Button
                        aria-describedby={id}
                        variant="contained"
                        onClick={() => handleClick(item)}
                        className="homePageMoreinfoBtn"
                      >
                        More Info
                      </Button>

                      <Modal
                        id={id}
                        open={open}
                        closeIcon={closeIcon}
                        style={{ zIndex: 1 }}
                      >
                        <ConnectPeoplePopup
                          onChildClick={handleClose}
                          connectUserData={dataToPopup}
                          page="connectpeople"
                        />
                      </Modal>
                    </Card>
                  ))}
                </Grid>
              </Grid>
            ) : null}
            <button className="home-explore" onClick={()=>{
              navigate('/companion-search')
            }}>EXPLORE MORE</button>
          </Container>
        </div>
      </div>

       {/* desktop version */}

  <Container className="homepage-ads-image-desktopversion">
        {/* <div>
          <img
            src="./Assets/Images/Poster 1 _ DV.png"
            alt="Add"
            className="homepage-ads-image"
          />
        </div> */}
         <div className="homepage-ads-image-img-one-container">
         <a href={homepage_banner_ad2_image_link} target="_blank">

              <img
                // src="./Assets/Images/Poster 1 _ DV.webp"
                src={
                  homepage_banner_ad2_image_DV != "default"
                    ? homepage_banner_ad2_image_DV
                    : "./Assets/Images/Poster 1 _ DV.webp"
                }

                alt="ad's"
                className="homepage-ads-image"
              />
              </a>
               {homepage_banner_ad2_image_MV != "default" ? (
                ""
              ) : (
              <button
                onClick={handlehomeadsOpen}
                className="homepage-ads-image-img-one-btn"
              >
                More Info
              </button>
              )}
            </div>
            <Modal
              open={homeadsopen}
              onClose={handlehomeadsClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="home-footer-ads-box">
                <a
                  href="./student-community"
                  style={{ textDecoration: "none" }}
                >
                  <button className="home-footer-ads-btn">
                    <Icon icon="mdi:account-student" />
                    &nbsp;Student Community
                  </button>
                </a>
                {/* <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 25,
                    color: "#1ec28b",
                    fontWeight: 600,
                  }}
                >
                  OR
                </span> */}
                <div className="home-footer-ads-horizontal-line">
                  <h1 className="home-footer-ads-horizontal-text">
                    <span>OR</span>
                  </h1>
                </div>
                <a href="./desi-community" style={{ textDecoration: "none" }}>
                  <button
                    className="home-footer-ads-btn"
                    style={{ justifyContent: "center" }}
                  >
                    <Icon icon="ic:outline-devices-other" /> &nbsp;Other's
                  </button>
                </a>
              </Box>
            </Modal>
      </Container>

      {/* mobile version */}

      <Container className="homepage-ads-image-Mobileversion">
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <div className="home-footer-ads-img-one-container">
            <a href={homepage_banner_ad2_image_link} target="_blank">

              <img
                // src="../../Assets/Images/Poster 1 _ MV.webp"

                src={
                  homepage_banner_ad2_image_MV != "default"
                    ? homepage_banner_ad2_image_MV
                    : "../../Assets/Images/Ads without button (TC).webp"
                }
                alt="ad's"
                className="home-footer-ads-img-one"
              />
              </a>
               {homepage_banner_ad2_image_MV != "default" ? (
                ""
              ) : (
              <button
                onClick={handleadsOpen}
                className="home-footer-ads-img-one-btn"
                style={{marginTop:"78px"}}
              >
                More Info
              </button>
              )}
            </div>

            <Modal
              open={adsopen}
              onClose={handleadsClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="home-footer-ads-box">
                <a
                  href="./student-community"
                  style={{ textDecoration: "none" }}
                >
                  <button className="home-footer-ads-btn">
                    <Icon icon="mdi:account-student" />
                    &nbsp;Student Community
                  </button>
                </a>
                {/* <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 25,
                    color: "#1ec28b",
                    fontWeight: 600,
                  }}
                >
                  OR
                </span> */}
                <div className="home-footer-ads-horizontal-line">
                  <h1 className="home-footer-ads-horizontal-text">
                    <span>OR</span>
                  </h1>
                </div>
                <a href="./desi-community" style={{ textDecoration: "none" }}>
                  <button
                    className="home-footer-ads-btn"
                    style={{ justifyContent: "center" }}
                  >
                    <Icon icon="ic:outline-devices-other" /> &nbsp;Other's
                  </button>
                </a>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Container>

      <div className="exploreSection">
        <div className="exploreSectionHeading">
          Explore by Destination Country
        </div>
        <div className="hrexploreLine"></div>
        <ExploreCarousle />
        
      </div>


 




      <div className="companionSelfieMainContainer">
        <p className="companion-selfie-Heading">Companion Selfie</p>
        <div className="hrcompanion-selfie"></div>
      </div>
      <div className="howItWorksContainer">
        <Container>
          {companionSelfie.length > 0 ? (
            <div className="homepageCompanionHeading">
              <Slider
                {...settings}
                
                nextArrow={<NextBtn></NextBtn>}
                prevArrow={<PreviousBtn></PreviousBtn>}
              >
                {companionSelfie.map((cs, index) => (
                  <Grid
                    container
                    spacing={2}
                    className="homepage-campanion-selfie-grid"
                  >
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <img
                        src={cs.trip_photos}
                        alt="selfie"
                        className="companionSelfieIMG"
                      />
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
                      <div className="homepage-campanion-details-heading">
                        <span style={{ color: "#1ec28b" }}>{cs.trip_details.traveler_name}</span> with
                        Travel Companion
                      </div>

                      <div className="routeMainContainer">
                        <span>
                          <RouteIcon
                            style={{
                              color: "#1ec28b",
                              transform: "rotate(90deg)",
                              marginBottom: "-7px",
                            }}
                          />
                          &nbsp;&nbsp;
                          {cs.trip_details.flying_from.split(",")[0]}
                          {/* {cs.transit1 ? (
                            <span>
                              <EastIcon
                                style={{
                                  color: "#1ec38b",
                                  marginBottom: "-7px",
                                }}
                              />
                              {cs.transit1}
                            </span>
                          ) : null}
                          {cs.transit2 ? (
                            <span>
                              <EastIcon
                                style={{
                                  color: "#1ec38b",
                                  marginBottom: "-7px",
                                }}
                              />
                              {cs.transit2}
                            </span>
                          ) : null}
                          {cs.transit3 ? (
                            <span>
                              <EastIcon
                                style={{
                                  color: "#1ec38b",
                                  marginBottom: "-7px",
                                }}
                              />
                              {cs.transit3}
                            </span>
                          ) : null}
                          {cs.transit4 ? (
                            <span>
                              <EastIcon
                                style={{
                                  color: "#1ec38b",
                                  marginBottom: "-7px",
                                }}
                              />
                              {cs.transit4}
                            </span>
                          ) : null} */}
                          <EastIcon
                            style={{
                              color: "#1ec38b",
                              marginBottom: "-7px",
                            }}
                          />
                          {cs.trip_details.destination.split(",")[0]}
                        </span>
                      </div>
                      <div>
                        <span className="whatsOnURMind">
                          <Icon
                            icon="ci:double-quotes-r"
                            className="homepage-campanion-double-quotes-icon"
                            style={{}}
                          />
                          {cs.whats_on_ur_mind}
                        </span>
                      </div>
                    </Grid>
                  </Grid>
                ))}
              </Slider>
            </div>
          ) : null}
        </Container>
      </div>

      <div>
        <Container>
          <Dialog
            open={invitopen}
            onClose={handleinvitClose}
            // {/* // aria-labelledby="alert-dialog-title"
            // // aria-describedby="alert-dialog-description" */}
            PaperProps={{
              style: {
                minHeight: "40vh",
                minWidth: "35vw",
                boxShadow: "none",
                border: "2px solid rgb(128 213 184 / 100%)",
                strokeWidth: "1px",
                stroke: "rgb(128 213 184 )",
                borderRadius: 20,
              },
            }}
            BackdropProps={{
              style: {
                backdrop: "blur(2px)",
              },
            }}
          >
            <div>
              <DialogTitle id="alert-dialog-title">
                {/* {handleinvitClose ? (
        <IconButton
          aria-label="close"
          onClick={handleinvitClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
                {/* <div className="invitation-upcoming-delete-dalog">
                      <Icon
                        className="invitation-deleteConfirmationIcon"
                        icon="fluent:delete-12-filled"
                      />
                      Delete
                    </div> */}
              </DialogTitle>
              <DialogContent className="invitation-upcoming-delete-dalog">
                <h2 className="invitation-areUSure" style={{ color: "red" }}>
                  {" "}
                  Please login for More Info
                </h2>
              </DialogContent>
              <DialogActions className="invitation-buttonsContainer">
                {/* <Button
                      id="btn"
                      className="invitation-yesButton"
                      onClick={() => {
                        settingdeleteinvitation();
                      }}
                    >
                      Yes
                    </Button> */}
                <Button
                  className="invitation-noButton"
                  onClick={handleinvitClose}
                >
                  OK
                </Button>
              </DialogActions>
            </div>
          </Dialog>
        </Container>
      </div>
    </>
  );
};

export default HomePage;