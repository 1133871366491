import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Accordion from "./FAQAccordian";
import {
  Autocomplete,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
// import ConnectAccordion from "./ConnectAccordion";
import SearchIcon from "@mui/icons-material/Search";
// import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import {Icon} from "@iconify/react";
// import GettingStartedAccordion from "./GettingStartedAccordion";
// import PaymentAccordion from "./PaymentAccodion";
import AccordianContent from "./AccordionContent";
import ClearIcon from "@mui/icons-material/Clear";

function TabPanel(props) {
  const {children, value, index, ...other} = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{p: 3}}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    "aria-controls": `vertical-tabpanel-${index}`,
  };
}

export default function VerticalTabs() {
  const [value, setValue] = React.useState(0);
  const [searchTerm, setSearchTerm] = React.useState("");
  const [searched, setSearched] = React.useState([]);

  console.log(searched, "searched");

  const contents = AccordianContent();

  console.log(contents, "contents");

  let AllOptions = [];

  Object.entries(contents).map(field => {
    field[1].map(title => {
      let obj = {};
      if (title.ConnectTitle) {
        obj = {
          ["content"]: title.ConnectContent,
          ["title"]: title.ConnectTitle,
        };
      } else if (title.title) {
        obj = {["content"]: title.content, ["title"]: title.title};
      } else if (title.GettingStartedTitle) {
        obj = {
          ["content"]: title.GettingStartedContent,
          ["title"]: title.GettingStartedTitle,
        };
      } else if (title.PaymentTitle) {
        obj = {
          ["content"]: title.PaymentContent,
          ["title"]: title.PaymentTitle,
        };
      } else {
      }
      AllOptions.push(obj);
    });
  });
  console.log(AllOptions, "AllOptions");
  const SearchHandler = () => {
    const filtered = !searchTerm
      ? AllOptions.length > 0
      : AllOptions.filter(val =>
          val.title.toLowerCase().includes(searchTerm.toLowerCase())
        );
    setSearched(filtered);
  };

  const handleChange = (event, newValue) => {
    console.log(newValue, "newValue");
    setValue(newValue);
  };
  //  const handleChange = (event,newValue)

  const changeHandler = (event, value) => {
    console.log(value, "value");
    if (value !== null) {
      setSearchTerm(value.title);
      setValue(value.title);

      console.log(value.title);
    } else {
      console.log("88888");
      setSearchTerm(null);
      setSearched([]);
    }
  };

  console.log(searchTerm, "searchTerm");
  console.log(searched, "searched");
  const styles = {
    tab: {
      color: "black",
    },
  };

  return (
    <>
      {/* <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div
          //   className="tabPAnel-searchContainer"
          >
            <Autocomplete
              onChange={changeHandler}
              onClick={SearchHandler}
              sx={{
                ".MuiOutlinedInput-root": {
                  "&:hover": {
                    borderColor: "none",
                    borderWidth: 10,
                  },
                },
              }}
              //   className="tabPAnel-r"

              freeSolo
              options={AllOptions}
              getOptionLabel={(option) => option.title}
              renderInput={(params) => (
                <TextField
                  {...params}
                  placeholder="Search"
                  fullWidth
                  //   className="searchLabel"
                />
              )}
            />
            <div>
              <SearchIcon className="FAQsearchIcon" onClick={SearchHandler} />
            </div>
          </div>
        </Grid>
      </Grid> */}
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <div className="faq-searchContainer ">
            <Autocomplete
              popupIcon={
                <Icon
                  icon="fe:search"
                  style={{color: "1EC28B", fontSize: 30}}
                  onClick={SearchHandler}
                />
              }
              onChange={changeHandler}
              // onClick={SearchHandler}
              sx={{
                // width: 400,
                "& .MuiOutlinedInput-root": {
                  "&:hover": {
                    borderColor: "none",
                    borderWidth: 10,
                  },
                },
                "& .css-154xyx0-MuiInputBase-root-MuiOutlinedInput-root": {
                  width: "108%",
                  // width: "auto !important",
                },
                "& .css-1q60rmi-MuiAutocomplete-endAdornment": {
                  top: "calc(50% - 18px)",
                },
                "& .MuiAutocomplete-popupIndicator": {
                  transform: "none",
                  transition: "none",
                },
              }}
              options={AllOptions}
              getOptionLabel={option => option.title}
              renderOption={(props, option) => {
                console.log(props, "optionProps");
                return (
                  <Box
                    component="li"
                    sx={{
                      "& > img": {
                        mr: 2,
                        flexShrink: 0,
                      },
                    }}
                    {...props}
                  >
                    {`${parseInt(props["data-option-index"]) + 1}. `}
                    {option.title.split(".")[1]}
                  </Box>
                );
              }}
              renderInput={params => (
                <TextField
                  {...params}
                  placeholder="Search"
                  className="searchLabel"
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
            {/*<div>
              <SearchIcon className="FAQsearchIcon" onClick={SearchHandler} />
                </div> */}
          </div>
          {/* <Autocomplete
   
   onChange={changeHandler}
            onClick={SearchHandler}
            sx={{
              ".MuiOutlinedInput-root": {
                "&:hover": {
                  borderColor: "none",
                  borderWidth: 10,
                },
              },
            }}
            freeSolo
            options={AllOptions}
            getOptionLabel={(option) => option.title}
            renderInput={(params) => (
              <TextField
                {...params}
                placeholder="Search"
                fullWidth
                className="faq-searchContainer"
                // InputProps={{
                //   endAdornment: (
                //     <IconButton>
                //       <SearchIcon
                //         className="faq-searchIcon"
                //         // onClick={SearchHandler}
                //       />
                //     </IconButton>
                //   ),
                // }}
              />
            )}
            InputProps={{
              endAdornment: (
                <IconButton>
                  <SearchIcon
                    className="faq-searchIcon"
                    onClick={SearchHandler}
                  />
                </IconButton>
              ),
            }}
          />
          <SearchIcon className="searchicon" onClick={SearchHandler} /> */}
        </Grid>
      </Grid>

      <Box className="tabPanel-Box">
        {/* desktop view */}
        <div className="tabPanel-desktop-view">
          <Tabs
            orientation="vertical"
            variant="scrollable"
            value={value}
            className=""
            inkbarstyle={{background: "black"}}
            // style={{ backgroundColor: "#1ec28b", height: "auto" }}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {display: "none"},
            }}
            aria-label="Vertical tabs example"
            sx={{
              "& button:hover": {backgroundColor: "rgb(30, 194, 139, 5%)"},
              "& button:focus": {
                backgroundColor: "rgb(30, 194, 139, 5%)",
                color: "black ",
                fontWeight: "bold",
              },
              "& button:active": {
                backgroundColor: "rgb(30, 194, 139, 5%)",
                color: "black ",
                fontWeight: "bold",
              },
              display: "flex",
              flexDirection: "column",
              alignItems: "baseline",
            }}
          >
            {/* <div className="tabpanel-mobnddesk"> */}
            <Tab
              className="tabPanel-Account"
              //   className={({ isActive }) => (isActive ? "active " : "inactive")}
              id="tabPanelAccount"
              label={
                <div className="tabpanel-Accounttext">
                  <Icon icon="gg:profile" className="tabpanel-ggProfile" />
                  <p
                    style={styles.tab}
                    className="tabpanel-Accountsettingstext"
                  >
                    Account Settings
                  </p>
                </div>
              }
              {...a11yProps(0)}
            ></Tab>
            <Tab
              className="tabPanel-Account"
              label={
                <div className="tabpanel-Connecttext">
                  <Icon
                    icon="fluent:plug-connected-24-filled"
                    className="tabPanel-connectedFilled"
                  />
                  <p className="tabpanel-Accountsettingstext">Connect</p>
                </div>
              }
              {...a11yProps(1)}
            />
            <Tab
              className="tabPanel-Account"
              label={
                <div className="tabpanel-hourGlassStarttext">
                  <Icon
                    icon="fa-solid:hourglass-start"
                    className="tabPanel-hourGlassStart"
                  />
                  <p className="tabpanel-Accountsettingstext">
                    Getting Started
                  </p>
                </div>
              }
              {...a11yProps(2)}
            />
            <Tab
              className="tabPanel-Account"
              label={
                <div className="tabpanel-Connecttext">
                  <Icon
                    icon="fluent:payment-16-filled"
                    className="tabPanel-connectedFilled"
                  />
                  <p className="tabpanel-Accountsettingstext">Payment </p>
                </div>
              }
              {...a11yProps(3)}
            />
            {/* </div> */}
          </Tabs>
        </div>
        {/* mobile view */}
        <div className="tabPanel-mobile-view">
          <Tabs
            // orientation="vertical"
            // variant="scrollable"
            variant="fullWidth"
            value={value}
            className=""
            inkbarstyle={{background: "black"}}
            // style={{ backgroundColor: "#1ec28b", height: "auto" }}
            onChange={handleChange}
            TabIndicatorProps={{
              style: {display: "none"},
            }}
            // aria-label="Vertical tabs example"
            aria-label="simple tabs example"
            sx={{
              "& button:hover": {backgroundColor: "rgb(30, 194, 139, 5%)"},
              "& button:focus": {
                backgroundColor: "rgb(30, 194, 139, 5%)",
                color: "black ",
                fontWeight: "bold",
              },
              "& button:active": {
                backgroundColor: "rgb(30, 194, 139, 5%)",
                color: "black ",
                fontWeight: "bold",
              },
              "& .MuiTabs-flexContainer": {
                flexWrap: "nowrap",
              },
              display: "flex",
              flexDirection: "column",
              // alignItems: "baseline",
            }}
          >
            {/* <div className="tabpanel-mobnddesk"> */}
            <Tab
              className="tabPanel-Account"
              //   className={({ isActive }) => (isActive ? "active " : "inactive")}
              id="tabPanelAccount"
              label={
                <div className="tabpanel-Accounttext">
                  <Icon icon="gg:profile" className="tabpanel-ggProfile" />
                  <p
                    style={styles.tab}
                    className="tabpanel-Accountsettingstext"
                  >
                    Account Settings
                  </p>
                </div>
              }
              {...a11yProps(0)}
            ></Tab>
            <Tab
              className="tabPanel-Account"
              label={
                <div className="tabpanel-Connecttext">
                  <Icon
                    icon="fluent:plug-connected-24-filled"
                    className="tabPanel-connectedFilled"
                  />
                  <p className="tabpanel-Accountsettingstext">Connect</p>
                </div>
              }
              {...a11yProps(1)}
            />
            <Tab
              className="tabPanel-Account"
              label={
                <div className="tabpanel-Accounttext">
                  <Icon
                    icon="fa-solid:hourglass-start"
                    className="tabPanel-hourGlassStart"
                  />
                  <p className="tabpanel-Accountsettingstext">
                    Getting Started
                  </p>
                </div>
              }
              {...a11yProps(2)}
            />
            <Tab
              className="tabPanel-Account"
              label={
                <div className="tabpanel-Connecttext">
                  <Icon
                    icon="fluent:payment-16-filled"
                    className="tabPanel-connectedFilled"
                  />
                  <p className="tabpanel-Accountsettingstext">Payment </p>
                </div>
              }
              {...a11yProps(3)}
            />
            {/* </div> */}
          </Tabs>
        </div>
        {searched.length === 0 && searchTerm === null ? (
          <Box>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TabPanel value={value} index={0}>
                  <div className="tabPanel-accordion">
                    {contents.accordionData.map(({title, content}) => (
                      <Accordion title={title} content={content} />
                    ))}
                  </div>
                </TabPanel>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TabPanel value={value} index={1}>
                  <div className="tabPanel-accordion">
                    {contents.ConnectedData.map(
                      ({ConnectTitle, ConnectContent}) => (
                        <Accordion
                          title={ConnectTitle}
                          content={ConnectContent}
                        />
                      )
                    )}
                  </div>
                </TabPanel>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TabPanel value={value} index={2}>
                  <div className="tabPanel-accordion">
                    {contents.GettingStartedData.map(
                      ({GettingStartedTitle, GettingStartedContent}) => (
                        <Accordion
                          title={GettingStartedTitle}
                          content={GettingStartedContent}
                        />
                      )
                    )}
                  </div>
                </TabPanel>
              </Grid>
            </Grid>

            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TabPanel value={value} index={3}>
                  <div className="tabPanel-accordion">
                    {contents.PaymentData.map(
                      ({PaymentTitle, PaymentContent}) => (
                        <Accordion
                          title={PaymentTitle}
                          content={PaymentContent}
                        />
                      )
                    )}
                  </div>
                </TabPanel>
              </Grid>
            </Grid>
          </Box>
        ) : (
          <Box>
            <div className={searched.length > 0 ? "tabPanel-accordion" : ""}>
              {searched.length > 0
                ? searched.map(({title, content}) => (
                    <Accordion title={title} content={content} value={value} />
                  ))
                : null}
            </div>
          </Box>
        )}
      </Box>
    </>
  );
}
