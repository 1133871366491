import { Button, Container, Grid } from "@mui/material";
import React from "react";
import { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import "../../CSS/contactUs.css";
import DashboardHeader from "../../Dashboard/DashboardHeader";
import ContactForm from "./ContactForm";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import axios from "axios";
import Swal from "sweetalert2";
import ScrollTop from "../../ScrollTop";

function ContactUs() {
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  // const englishchar = /[A-Za-z]/;
  // const englishchar =  /^(?:[A-Za-z]+|\d+)$/;
  const englishchar =/^[a-zA-Z ]*$/;
  const schema = yup.object().shape({
    name: yup.string()
    .required("Name is required")
    .matches(englishchar, "only English characters allowed"),
    country: yup
      .string()
      .required("Country is required")
      .nullable(),
    feedback: yup
      .string()
      .max(500, "Too Long!")
      .required("Feedback is required")
      .matches(englishchar, "only English characters allowed"),
    // captcha: yup.string().required(),
    state: yup
      .string()
      .required("State is required")
      .nullable(),
    acceptTerms: yup.bool().oneOf([true], "Captcha is required"),
    phone_number: yup
      .string()
      .required("Phone number is required")
      .matches(phoneRegExp, "Phone number not valid")
      .min(4,"Phone number must be at least 4 characters")
      .max(12, "Phone number must be at most 12 characters"),
      email: yup.string()
      .required("Email is required")
      .email("Invalid email"),
  });
  // .required();s

  const {
    register,
    control,
    Controller,
    reset,
    handleSubmit,
    getValues,
    setValue,
    watch,
    methods,
    onBlur,
    resetField,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
      country: "",
      state: "",
      country_code: "",
      phone_number: "",
      feedback: "",
      email:"",
    },
    //   // defaultValues:{...finalIni}
  });

  const watchFields = watch();
  const values = getValues();
  console.log("errors", errors);
  console.log("values..", values);
  useEffect(() => {
    reset({
      name: "",
      country: "",
      state: "",
      country_code: "",
      phone_number: "",
      feedback: "",
      email:"",
      // captcha: "",
    });
  }, []);

  const onSubmit = (values) => {
    reset({
      name: "",
      country: "",
      state: "",
      country_code: "",
      phone_number: "",
      feedback: "",
      email:"",
    });
    console.log(values, "values");
    if (values)
      Swal.fire({
        title: "Thanks for your feedback",
        // text: "Thanks for your feedback",
        icon: "success",
        confirmButtonColor:"#ff8d8d",
        iconColor: "#1ec28b",
        buttons: {
          ok: {
            text: "Yes",
            value: "yes",
          },
          cancel: "No",
        },
      });
    else Swal.fire("Hi", "Please enter values", "error");

    // let finalVal = {};
    // // finalVal["user_id"] = 2;
    // const newdata = new FormData();

    // Object.entries(finalVal).forEach(([key, value]) =>
    //   newdata.append(key, value)
    // );
    // console.log(finalVal, "finalVal");
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .post(
        `${domain}/contact-us/`,
        values
      )
      .then((response) => {
        console.log(response, "response");
      })
      .catch((error) => {
        console.log(error.values);
      });
  };

  return (
    <>
      <ScrollTop>
        <div>
          <DashboardHeader />
        </div>
        <Container>
          <div className="contactUS-heading">
            <h1>
              <span className="contactUS-headingText ">
                Let's <span style={{ color: "#1ec28b" }}>Talk!!</span>
              </span>
            </h1>
          </div>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} align="center">
              <img
                src="./Assets/Images/contactus.webp"
                alt="logo"
                className="contactUS-Image"
              />
            </Grid>
          </Grid>
        </Container>
        <Container>
          <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <ContactForm
              control={control}
              Controller={Controller}
              values={values}
              setValue={setValue}
              errors={errors}
              register={register}
              onBlur={onBlur}
              reset={reset}
              resetField={resetField}
            />

            <div>
              <Button
                type="submit"
                className="contactUS-Btn"
                variant="contained"
                color="primary"
              >
                Submit
              </Button>
            </div>
          </form>
        </Container>
      </ScrollTop>
    </>
  );
}
export default ContactUs;