import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { Icon } from "@iconify/react";
// import axiosInstance from "../axios";
import { useFormik } from "formik";
import axios from "axios";
import "../../CSS/login.css";
import { Button, Container, Grid, TextField } from "@mui/material";
// import { Link } from "react-router-dom";
import { Link, Navigate,useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login } from "../AdminRedux/actions/auth";
import GoogleLogin from "react-google-login";
import { gapi } from "gapi-script";
import ScrollTop from "../../ScrollTop";
import userEvent from "@testing-library/user-event";
// import { clearMessage } from "../../actions/messege";
import TripCount from "../../UpcomingTrip_count/TripCount";
import { clearMessage } from "../AdminRedux/actions/messege";

function AdminLogin(props) {
  const navigate = useNavigate();

  const { isadminLoggedIn,admin } = useSelector((state) => state.admin_auth);
  const { admin_message } = useSelector((state) => state.admin_message);
  // console.log(message, "error check");
  const dispatch = useDispatch();
  // const [gloginErrorMessage, setgloginErrorMessage] = React.useState(''); 

  const validationSchema = Yup.object({
    email: Yup.string("Enter your email")
      .email("Enter a valid email")
      .required("Email is required"),
    password: Yup.string("Enter your password")
      // .min(8, 'Password should be of minimum 8 characters length')
      // .matches(
      //   /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      //   "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character",
      // )
      .required("Password is required"),
  });

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // alert(JSON.stringify(values, null, 2));
      console.log(JSON.stringify(values));
      dispatch(login(values.email, values.password));
     
    },
  });

  // useEffect(() => {
  //   document.title =  "Desi Travel Companion-login";
  // }, []);






  useEffect(() => {
    dispatch(clearMessage())

  },[])






  if (isadminLoggedIn) {
  //  alert("type")
  //   if(admin.redirect_to_change_password =="false"){
  //     return <Navigate replace to="/admin/change-password" />; 
  //    }
  //   else {
    return <Navigate replace to="/admin/dashboard" />;
    //  }
  }
  else{
  return (
    <div className="mainLogin">
       <ScrollTop>
      <Container>
        <Grid container spacing={2} className="containerMainGrid">
          <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
            <div>
              <Link to="/">
                <img
                  src="../../Assets/Images/desi-travel-campanion-logo.png"
                  alt="first"
                  className="travelCompanionImg"
                />
              </Link>
            </div>
            <div>
              <img
                src="../../Assets/Images/Login Illustraion Image PNG - Copy.png"
                alt="first"
                className="loginsecondimg"
              />
            </div>
          </Grid>

          <Grid item xs={12} md={6} lg={6} sm={12} xl={6}>
            <div
              className="loginsection"
              style={{ border: "1px solid #4F8DF9" }}
            >
              {/* <Formik
                // enableReinitialize
                initialValues={{ initialValues }}
                validationSchema={ContactSchema}
                onSubmit={
                  // (values) => console.log(values)
                  handleSubmit
                }
              > */}
              {/* {({ errors, touched, isValid, isSubmitting }) => */}
              <form onSubmit={formik.handleSubmit}>
                <h2 className="tagLine">Admin Login</h2>
              
                
            

                <Container>
                  <hr className="hrLine"></hr>
                </Container>

                <Grid container spacing={2} className="login-emailMainGrid">
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <label className="emailLabelLogin">
                      Email<span style={{ color: "#ff0000" }}>*</span>
                    </label>
                    <div>
                      <TextField
                        className="emailLoginInput"
                        type="email"
                        name="email"
                        id="email"
                        margin="normal"
                        onBlur={formik.handleBlur}
                        touched={formik.touched}
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.email && Boolean(formik.errors.email)
                        }
                        helperText={formik.touched.email && formik.errors.email}
                      />
                    </div>
                    <div id="errormessage" className="loginEmailError"></div>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <label className="emailLabelpassword">
                      Password
                      <span style={{ color: "red" }}>*</span>
                    </label>
                    <div>
                      <TextField
                        className="passwordLoginInput"
                        name="password"
                        id="password"
                        type="password"
                        onBlur={formik.handleBlur}
                        touched={formik.touched}
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        error={
                          formik.touched.password &&
                          Boolean(formik.errors.password)
                        }
                        helperText={
                          formik.touched.password && formik.errors.password
                        }
                      ></TextField>
                    </div>
                    <div id="errormessage" className="passwordDanger"></div>
                  </Grid>
                  <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                    <Grid>
                      <Link
                        to="/forgot-password-admin"
                        style={{ textDecoration: "none" }}
                      >
                        <p className="forgetPasswordText">Forgot password?</p>
                      </Link>
                    </Grid>
                    <div>
                      <span style={{ color: "red",    display: "flex",justifyContent: "center",marginBottom: "5px"
 }}>{admin_message}</span>
  {/* <span style={{ color: "red",    display: "flex",justifyContent: "center",marginBottom: "5px"
 }}>{gloginErrorMessage}</span> */}
                    </div>
                  </Grid>
                </Grid>
                <Grid container spacing={2}>
                  <Grid item xl={6} lg={12} md={6} sm={12} xs={12} className="login-signing-confrim" style={{marginBottom: '9px'}}>
                  By signing in, I agree to the  <a href="/terms-and-conditions" style={{ textDecoration: "none" }}>Terms and Conditions, Privacy Statement</a>
                    </Grid>
                  </Grid>

                <Grid container spacing={2}>
                  <Grid item xl={6} lg={12} md={6} sm={12} xs={12}>
                    <button
                      className="loginBtn cursor_point"
                      id="getButton"
                      name="Submit"
                      type="submit"
                      // onClick={()=>{setgloginErrorMessage('')}}
                    >
                      Login
                    </button>

               
                  </Grid>
                </Grid>
              </form>
            </div>
          </Grid>
        </Grid>
      </Container>
      </ScrollTop>
    </div>
  );
                      }
}

export default AdminLogin;