import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import "../../CSS/ads.css";
import { Fragment } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  MenuItem,
  Paper,
  TextField,
  Typography,
  Select,
} from "@mui/material";
import AdsForm from "./AdsForm";
import DashboardHeader from "../../Dashboard/DashboardHeader";
import axios from "axios";
import Swal from "sweetalert2";
import ScrollTop from "../../ScrollTop";

const Ads = () => {
  const web_valid=/^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
  const onlynumber = /^[0-9]*$/;
  const englishchar = /^[a-zA-Z ]*$/;
  const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .required("Name is required")
      .matches(englishchar, "only English characters allowed"),
    company_name: Yup.string()
      // .required("Company Name is required")
      .matches(englishchar, "only English characters allowed"),

    website: Yup.string()
      // .required("Website Name is required")
      // .matches(englishchar, "only English characters allowed"),
      // .matches(
      //   // /^[a-z]+$/,
      //   // englishchar,
      //   web_valid,
      //   //^[\\w-\\.]+@([\\w-]+\\.)+[\\w-]{2,4}$",
      //   // /^[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(?:\.[a-zA-Z0-9]+)*$/,
      //   // "/^((?!-)[A-Za-z0-9-]{1, 63}(?<!-)\\.)+[A-Za-z]{2, 6}$/" ,
      //   // /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]*$/,
      //   // "^(?=.{1,253}\.?$)(?:(?!-|[^.]+_)[A-Za-z0-9-_]{1,63}(?<!-)(?:\.|$)){2,}$",
      //   "Not a valid website",
      // ),
      .matches(web_valid,{message:"Not a valid website",excludeEmptyString:true}),
    // country: Yup.string().required("Country is required"),
    // state: Yup.object().required("State is required").nullable(),
    country: Yup
    .string()
    .required("Country is required")
    .nullable(),
    // state: Yup.object().required("State is required").nullable(),
    state: Yup
    .string()
    .required("State is required")
    .nullable(),
    phone_number: Yup.string()
      .required("Phone Number is required")
      .matches(phoneRegExp, "Phone number not valid")
      .min(4,"Phone number must be at least 4 characters")
      .max(12, "Phone number must be at most 12 characters"),
    ad_location: Yup.string().required("Location is required"),
    ad_duration: Yup.string().required("Ad duration is required"),
    // .matches("!=0","Ad duration is required"),
    ad_price: Yup.string()
      .required("Ad Price is required")
      .matches(onlynumber, "AD Price not valid"),

    acceptTerms: Yup.bool().oneOf([true], "Enter Captcha"),
    email: Yup.string()
      .required("Email is required")
      .email("Invalid email"),
  });

  const {
    register,
    control,
    Controller,
    reset,
    handleSubmit,
    getValues,
    setValue,
    watch,
    methods,
    resetField,
    onBlur,

    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      ad_location: "",
      name: "",
      company_name: "",
      website: "",
      country: "",
      state: "",
      country_code: "",
      ad_location: "",
      ad_duration: "",
      ad_price: "",
      email:"",
    },
  });

  const defaultValues = {
    // activitiesbefore: "",

    company_name: "",
    country: "",
    country_code: "",
    name: "",
    phone_number: "",
    state: "",
    website: "",
    ad_duration: "",
    ad_location: "",
    ad_price: "",
    email:"",
  };

  const values = getValues();
  console.log("errors", errors);
  console.log("values..", values);
  useEffect(() => {
    // reset({
    //   name: "",
    //   ad_location:"ad1",
    //   country: "",
    //   state: "",
    //   code: "",
    //   phone: "",
    //   short_description: "",
    //   captcha: "",
    // });
  }, []);

  const onSubmit = (values) => {
    // reset(values => ({
    //   ...values,
    //   name: '',
    // }))

    reset({
      ad_location: "",
      name: "",
      company_name: "",
      website: "",
      country: "",
      state: "",
      country_code: "",
      ad_location: "",
      ad_duration: "",
      ad_price: "",
      email:"",
    });
    console.log(values, "values");
    if (values)
      Swal.fire({
        title: "Thanks for your enquiry",
        text: "We will get back to you soon",
        icon: "success",
        confirmButtonColor:"#ff8d8d",
        iconColor: "#1ec28b",
        buttons: {
          ok: {
            text: "Yes",
            value: "yes",
          },
          cancel: "No",
        },
      });
    else Swal.fire("Hi", "Please enter values", "error");

    let finalVal = {};
    // finalVal["user_id"] = 2;
    const newdata = new FormData();

    Object.entries(finalVal).forEach(([key, value]) =>
      newdata.append(key, value)
    );
    console.log(finalVal, "finalVal");


    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");


    axios
      .post(
        `${domain}/ads-enquiry/`,
        values
      )
      .then((response) => {
        console.log(response, "response");
        //uncomment reset function when its working
        reset(defaultValues);
     
      })
      .catch((error) => {
        console.log(error.values);
        //comment reset

        reset(defaultValues);
      });
  };

  // const onSubmit = (data) => {
  //   console.log(data);
  // };
  console.log("values1234..", values);
  return (
    <div>
      <ScrollTop>
        <div>
          {" "}
          <DashboardHeader />
        </div>
        <div>
          <AdsForm
            control={control}
            Controller={Controller}
            values={values}
            setValue={setValue}
            errors={errors}
            register={register}
            onBlur={onBlur}
            reset={reset}
            resetField={resetField}
          />
        </div>
        <Box mt={3}>
          <Button
            variant="contained"
            onClick={handleSubmit(onSubmit)}
            className="ads-btn"
          >
            Submit
          </Button>
        </Box>
      </ScrollTop>
    </div>
  );
};

export default Ads;