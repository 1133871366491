
    import {
        Box,
        Breadcrumbs,
        Button,
        Container,
        Dialog,
        DialogActions,
        DialogContent,
        DialogTitle,
        FormControl,
        Grid,
        MenuItem,
        Select,
        TextField,
        Typography,
      } from "@mui/material";
      import React from "react";
      
      
      import "../CSS/changepassword.css";
      import AddCircleIcon from "@mui/icons-material/AddCircle";
    //   import EditProfile from "../EditProfile";
    //   import SidebarLink from "../SidebarLink";
    //   import DashboardHeader from "../DashboardHeader";
      import { useFormik } from "formik";
      import * as Yup from "yup";
      import axios from "axios";
      import { useSelector, useDispatch } from "react-redux";
      import { useState } from "react";
      import Swal from "sweetalert2";
      import { logout } from "../../actions/auth";
      import { Icon } from "@iconify/react";
      import { Link, useNavigate } from "react-router-dom";
      import { useEffect } from "react";
    import AdminHeader from "../Components/AdminHeader";
import Adminsidebar from "../Adminsidebar";
      
export default function ChangePassword() {
      
      
        const { isLoggedIn, user } = useSelector((state) => state.auth);
        const { isadminLoggedIn,admin } = useSelector((state) => state.admin_auth);

      

        // console.log("user_id", user.user_id);
        const dispatch = useDispatch();
        const logOut = () => {
          dispatch(logout());
        };
        const [passwordMessage, setPasswordMessage] = React.useState(null);
        const [passwordError, setPasswordError] = React.useState(null);
      
////latest 1/29/2024 bug fixed
        const handleChange = (e) => {
          // console.log("e","input valueeee") 
          setPasswordError(null);
          setPasswordMessage(null);
        };
////latest 1/29/2024 bug fixed //for this added oninput change event for 3 password fields

        const validationSchema = Yup.object({
          OldPassword: Yup.string().required("Please enter Old Password"),
          NewPassword: Yup.string()
            // .notOneOf([Yup.ref("OldPassword"), null], "Old and New Password are same")
            .required("Please enter New Password")
            .matches(
              /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
              "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
            ),
          confirmNewpassword: Yup.string()
            .oneOf([Yup.ref("NewPassword"), null], "Passwords must match")
            .required("Passwords must match"),
        });
      
        const formik = useFormik({
          initialValues: {
            OldPassword: "",
            NewPassword: "",
            confirmNewpassword: "",
          },
          validationSchema: validationSchema,
          onSubmit: (values) => {
            // alert(values);
            // const beginDate = moment(values.FromDate).format('YYYY-MM-DD')
            // console.log(beginDate);
            //   const mydate=new Date(values.FromDate)
            //   const thisdate=mydate.getDate()+'-'+(mydate.getMonth()+1)+'-'+mydate.getFullYear()
            //   console.log(thisdate)
            console.log(values);
      
            // console.log(JSON.stringify(values))
            // dispatch(login(values.email, values.password))
      
            console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
            let domain = "";
            console.log(process.env, "domain env", window.location.hostname);
            if (window.location.hostname.trim() === "localhost") {
              domain = process.env.REACT_APP_LOCAL_API;
            } else if (window.location.hostname.trim() === "54.185.6.32") {
              domain = process.env.REACT_APP_UAT_API;
            } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
              domain = process.env.REACT_APP_PROD_UAT_API;
            }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
              domain = process.env.REACT_APP_PROD_API;
            }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
              domain = process.env.REACT_APP_PROD_API;
            }
            console.log(domain, "domain env end");
      
            axios
              .put(
                `${domain}/change-password/${admin.id}/`,
                {
                  old_password: values.OldPassword,
                  new_password: values.NewPassword,
                }
              )
              .then((response) => {
                console.log(response, "change password response");
      
                if (response.data) {
                  formik.resetForm();
                  console.log(
                    response.data.message,
                    "change password response message"
                  );
                  setPasswordMessage(response.data.message);
                  setPasswordError(null);
                }
              })
              .catch((error) => {
                // Error
                if (error.response.status) {
                  // The request was made and the server responded with a status code
                  // that falls out of the range of 2xx
                  console.log(error.response.data, "error message");
                  //  console.log(error.response.status);
                  //  console.log(error.response.headers);
                  setPasswordError(error.response.data.message);
                  setPasswordMessage(null);
                }
              });
          },
        });
      
        const [settingsdeleteopen, setsettingsdeleteOpen] = React.useState(false);
        const [recievedinvite, setrecievedinvite] = useState([]);
      
        const handleClickOpen = () => {
          setsettingsdeleteOpen(true);
        };
      
        const handledeleteClose = () => {
          setsettingsdeleteOpen(false);
        };
        const [settingdeleteAgree, setsettingDeleteAgree] = useState(false);
        const [settingdeleteId, setsettingDeleteId] = useState("");
      
        const settingdeleteinvitation = () => {
          //  setOpen(false)
          console.log(settingdeleteId, "card id");
          if (settingdeleteId !== "") {
      
            console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
            let domain = "";
            console.log(process.env, "domain env", window.location.hostname);
            if (window.location.hostname.trim() === "localhost") {
              domain = process.env.REACT_APP_LOCAL_API;
            } else if (window.location.hostname.trim() === "54.185.6.32") {
              domain = process.env.REACT_APP_UAT_API;
            } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
              domain = process.env.REACT_APP_PROD_UAT_API;
            }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
              domain = process.env.REACT_APP_PROD_API;
            }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
              domain = process.env.REACT_APP_PROD_API;
            }
            console.log(domain, "domain env end");
      
            axios
              .delete(
                `${domain}/delete/user/${settingdeleteId}/`
              )
              .then((response) => {
                console.log(response, "update delete response");
      
                const newList = recievedinvite.filter((user) => {
                  return user.user_id !== settingdeleteId;
                });
      
                console.log(newList, "FILTERED CARD...");
                setrecievedinvite(newList);
      
                if (response.data) {
                  setsettingsdeleteOpen(false);
      
                  console.log(response.data, " response delete profile");
                  setsettingDeleteAgree(true);
                  logOut();
                  Swal.fire({
                    // position: 'center',
                    customClass: {
                      popup: "my-swal",
                    },
                    icon: "success",
                    title: "successfully Deleted",
                    showCloseButton: true,
                    showConfirmButton: false,
                  });
                }
              })
              .catch((error) => {
                // Error
                if (error.response.status === 400) {
                  console.log(error.response.data, "error");
                  console.log(error.response.status);
                  // console.log(error.response.headers);
                  // setResponseMessage(null);
                }
              });
          }
        };
      
      //   const navigate = useNavigate();
      // useEffect(
      //    ()=>{if(user.update_profile_details!=="False"){
      //    return navigate('/edit-profile',{state:'true'})
      //   }}
      // ,[]
      // )
      
        // if(user.update_profile_details=="False"){
        return (
          <>
          <AdminHeader></AdminHeader>
          <div className="dbsql-profile-main">
          <div style={{ borderRight: "groove", minHeight: "90vh" }}>
          <Adminsidebar />
        </div>
        <Container style={{ backgroundColor: "whitesmoke" }}>

        <div className="admindashboard-heading">Settings</div>
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            className="ads-management-subhead"
          >
            {/* {breadcrumbs} */}
            <Link to={{}} className="ads-manage-home">Home</Link>
            <Typography className="ads-management-subheading">
              Change Password
            </Typography>
          </Breadcrumbs>
            {/* <Container>
              <div className="dashboard-main"> */}
                {/* <div className="siderbar-mobile-version">
                  <Box
                    sx={{
                      width: 300,
                      height: 600,
                      backgroundColor: "",
                      "&:hover": {
                        backgroundColor: "aliceblue",
                      },
                    }}
                  >
                  </Box>
                </div> */}
                {/* <Grid container spacing={2}></Grid> */}
                {/* <div className="settings-mobile-version">
                  <div className="setting-outside-container"> */}
                    {/* <p className="setting-heading">Settings</p> */}
                    {/* <b className="setting-changepassword-heading">
                      Change Password
                    </b> */}
      
      
                    <form onSubmit={formik.handleSubmit}>

                      {/* <div className="setting-inside-container">
                        <div className="setting-inside-grid"> */}
                          {/* <b className="setting-changepassword-heading">
                            Change Password
                          </b> */}
  <Box style={{ backgroundColor: "white", border: "1px solid lightgrey", borderRadius: 5, marginTop: 20,marginBottom: "15px" }}>
            <Grid className="admin-changePassword-Heading"style={{ marginTop: 30, marginBottom: 30 }}>Change Password</Grid>
            <Container>

                          <Grid container spacing={2}>
                            <Grid item lg={12} xs={12}>
                              <div>
                                <label className="setting-labels" style={{display: "flex",
    justifyContent: "initial",marginBottom: "10px"}}>
                                  Enter Old Password
                                </label>
                              </div>
                              <TextField
                                type="password"
                                id="standard-basic"
                                // variant="standard"
                                className="setting-textfield"
                                name="OldPassword"
                                onInput={handleChange}
                                onBlur={formik.handleBlur}
                                touched={formik.touched}
                                value={formik.values.OldPassword}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.OldPassword &&
                                  Boolean(formik.errors.OldPassword)
                                }
                                helperText={
                                  formik.touched.OldPassword &&
                                  formik.errors.OldPassword
                                }
                              />
                            </Grid>
                            <Grid item lg={12} xs={12}>
                              <div>
                                <label className="setting-labels" style={{display: "flex",
    justifyContent: "initial",marginBottom: "10px"}}>
                                  Enter New Password
                                </label>
                              </div>
                              <TextField
                                type="password"
                                // variant="standard"
                                className="setting-textfield"
                                name="NewPassword"
                                onBlur={formik.handleBlur}
                                touched={formik.touched}
                                value={formik.values.NewPassword}
                                onInput={handleChange}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.NewPassword &&
                                  Boolean(formik.errors.NewPassword)
                                }
                                helperText={
                                  formik.touched.NewPassword &&
                                  formik.errors.NewPassword
                                }
                              />
                            </Grid>
                            <Grid item lg={12} xs={12}>
                              <div>
                                <label className="setting-labels" style={{display: "flex",
    justifyContent: "initial",marginBottom: "10px"}}>
                                  Confirm New Password
                                </label>
                              </div>
                              <TextField
                                type="password"
                                // variant="standard"
                                className="setting-textfield"
                                name="confirmNewpassword"
                                onInput={handleChange}
                                onBlur={formik.handleBlur}
                                touched={formik.touched}
                                value={formik.values.confirmNewpassword}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.confirmNewpassword &&
                                  Boolean(formik.errors.confirmNewpassword)
                                }
                                helperText={
                                  formik.touched.confirmNewpassword &&
                                  formik.errors.confirmNewpassword
                                }
                              />
                            </Grid>
      
                            <span style={{ color: "green",marginLeft: "37%",maginTop: "10px" }}>{passwordMessage}</span>
                            <span style={{ color: "red",maginTop: "10px" }}>{passwordError}</span>
                            <Grid item lg={12} xs={12} className="save-btn-container">
                              <div>
                                <button
                                  name="Submit"
                                  className="setting-save-btn"
                                  type="submit"
                                  autoFocus
                                >
                                  SAVE
                                </button>
                              </div>
      
                            </Grid>
      
      
      
                          </Grid>
      
                          </Container>
                          </Box>
                        {/* </div>
      
      
                      </div> */}
      
                    </form>
                  
      
                  {/* </div> */}
      
                 
                {/* </div> */}
               {/* </div> */}
              {/* <div className="setting-delete-main">
                <b className="setting-delete-ac-btn">Delete Account</b>
                <button
                  type="submit"
                  className="setting-delete-btn"
                  onClick={(post) => {
                    setsettingDeleteId(user.user_id);
                    setsettingDeleteAgree(false);
                    setsettingsdeleteOpen(true);
                    // logOut();
                  }}
                >
                  Delete
                </button>
              </div> */}
              <Container>
                <Dialog
                  open={settingsdeleteopen}
                  onClose={handledeleteClose}
                  // aria-labelledby="alert-dialog-title"
                  // aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      minHeight: "40vh",
                      minWidth: "35vw",
                      boxShadow: "none",
                      border: "2px solid rgb(128 213 184 / 100%)",
                      strokeWidth: "1px",
                      stroke: "rgb(128 213 184 )",
                      borderRadius: 20,
                    },
                  }}
                  BackdropProps={{
                    style: {
                      backdrop: "blur(2px)",
                    },
                  }}
                >
                  <div>
                    <DialogTitle id="alert-dialog-title">
                      <div className="invitation-upcoming-delete-dalog">
                        <Icon
                          className="invitation-deleteConfirmationIcon"
                          icon="fluent:delete-12-filled"
                        />
                        Delete
                      </div>
                      <hr
                        style={
                          {
                            // height: "1px",
                            // borderWidth: 0,
                            // color: "#1ec28b",
                            // backgroundColor: "#1ec28b",
                            // marginLeft: "50px",
                            // marginRight: "50px",
                          }
                        }
                        className="invitation-Hrline"
                      />
                    </DialogTitle>
                    <DialogContent className="invitation-upcoming-delete-dalog">
                      <h2 className="invitation-areUSure"> Are you sure?</h2>
                    </DialogContent>
                    <DialogActions className="invitation-buttonsContainer">
                      <Button
                        id="btn"
                        className="invitation-yesButton"
                        onClick={() => {
                          settingdeleteinvitation();
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        className="invitation-noButton"
                        onClick={handledeleteClose}
                      >
                        No
                      </Button>
                    </DialogActions>
                  </div>
                </Dialog>
              </Container>
             </Container>

            </div>
          </>
        );
      // }
      // else{
      
      //   return (()=>navigate('/edit-profile',{state:'true'}))
      // }
      }
      
      
      
 
