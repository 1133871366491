import { Box, Container, Grid, TextField } from "@mui/material";
import DashboardHeader from "../Dashboard/DashboardHeader";
import "../CSS/FAQ.css";
import React from "react";
import { useState } from "react";
import VerticalTabs from "./TabPanel";
import AccordianContent from "./AccordionContent";
import ScrollTop from "../ScrollTop";


function FAQ() {
  const [connectShow, setConnectShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const tabAccordionContents = AccordianContent();

  return (
    <ScrollTop>
    <div>
      <DashboardHeader />
      <div>
            
       <Container>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} className="faq-Heading">
              <p className="frequentlyAskedHeading">
                Frequently asked{" "}
                <span style={{ color: "rgb(30,194,139,80%)" }}>
                  Questions (FAQ’s)
                </span>
              </p>
              <p style={{ color: "#000000" }} className="faq-helpText">
                Have questions? We’re here to help.
              </p>
            </Grid>

          </Grid>

          <Grid container spacing={2} className="sideMenuAccordionContainer">
            <VerticalTabs />
          </Grid>
          </Container>
      </div>
    </div>
    </ScrollTop>
  );
}

export default FAQ;
