import React, { useEffect, useState } from "react";
import "../../CSS/invitationSentList.css";
import {
  Box,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  // Icon,
  IconButton,
  InputBase,
  Modal,
  Popover,
  Typography,
} from "@mui/material";
import SidebarLink from "../SidebarLink";
import EditProfile from "../EditProfile";
import DashboardHeader from "../DashboardHeader";
import ArrowCircleDownSharpIcon from "@mui/icons-material/ArrowCircleDownSharp";
import SearchIcon from "@mui/icons-material/Search";
import axios from "axios";
import { useSelector } from "react-redux";
import { Button } from "react-bootstrap";
import ConnectPeoplePopup from "../../Pages/ConnectPeople/ConnectPeoplePopup";
import Swal from "sweetalert2";
import { Icon } from "@iconify/react";
import ScrollTop from "../../ScrollTop";
import { Navigate, useNavigate } from "react-router-dom";

function InvitationSentList() {
  const [sentList, setSentLIst] = useState([]);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  console.log("user_id", user.user_id);

  // const [invitationSentList, setInvitationSentList] = useState([]);
  const [cancelAgree, setCancelAgree] = useState(false);
  const [cancelId, setCancelId] = useState("");
  // const [sentOpen, setSentOpen] = React.useState(false);

  const [closeIcon, setCloseicon] = useState(false);
  const [dataToPopup, setDataToPopup] = useState();
  const [sentDetailsOpen, setSentDetailsOpen] = React.useState(false);
  const [profiledata, setprofiledata] = useState({});
  const [sentTriperror, setsentTriperror] = useState();

  const handleClick = (item, data) => {
    setDataToPopup(item);
    setCloseicon(true);
    setprofiledata(data);
  };
  const handleClose = () => {
    setCloseicon(false);
    setSentDetailsOpen(false);
  };

  // const sentClose = () => {
  //   setSentDetailsOpen(false);
  // }

  const open = Boolean(closeIcon);
  const id = open ? "simple-popover" : undefined;

  useEffect(() => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .get(
        `${domain}/sent-list/${user.user_id}/`
      )
      .then((response) => {
        console.log(response, "sent list response");

        console.log(response.data, "sent list response data");
        setSentLIst(response.data);

        console.log(response.data, "sent List");
        const v = response.data[0].trip_details.destination.split(",")[0];
        const r = response.data[0].trip_details.destination.split(",")[1];
        console.log(v + "," + r.split("(")[0]);
      })
      .catch((error) => {
        // Error
        // console.log("status : ",error.response.status);
        // console.log("data : ",error.response.data);
        if (error.response) {
          // console.log(error.response.data, "error");
          console.log(error.response);
          setsentTriperror(error.response.message)
          // console.log(error.response.headers);
          // setResponseMessage(null);
        }
      });
  }, []);

  const cancelCard = () => {
    //  setOpen(false)
    console.log(cancelId, "card id");
    if (cancelId !== "") {

      console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
      let domain = "";
      console.log(process.env, "domain env", window.location.hostname);
      if (window.location.hostname.trim() === "localhost") {
        domain = process.env.REACT_APP_LOCAL_API;
      } else if (window.location.hostname.trim() === "54.185.6.32") {
        domain = process.env.REACT_APP_UAT_API;
      } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
        domain = process.env.REACT_APP_PROD_UAT_API;
      }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
        domain = process.env.REACT_APP_PROD_API;
      }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
        domain = process.env.REACT_APP_PROD_API;
      }
      console.log(domain, "domain env end");

      axios
        .delete(
          `${domain}/delete-invitation/${cancelId}/`
        )
        .then((response) => {
          console.log(response, "update cancel response");

          const newList = sentList.filter((card) => {
            return card.connection_id !== cancelId;
          });
          console.log(newList, "FILTERED CARD...");
          setSentLIst(newList);

          if (response.data) {
            setSentDetailsOpen(false);

            console.log(response.data, " response delete profile");
            setCancelAgree(true);
            Swal.fire({
              // position: 'center',
              customClass: {
                popup: "my-swal",
              },
              icon: "success",
              title: "successfully cancelled",
              showCloseButton: true,
              showConfirmButton: false,
            });
            // Swal.fire({
            //   title: "Successully deleted",
            //   icon: "success",
            //   showConfirmButton: false,
            //   iconColor: "#1C7B31",
            //   background: "rgb(128 213 184 /30%)",
            // });
          }
        })
        .catch((error) => {
          // Error
          if (error.response.status === 400) {
            console.log(error.response.data, "error");
            console.log(error.response.status);
            // console.log(error.response.headers);
            // setResponseMessage(null);
          }
        });
    }
  };
  const imagePerRow = 4;
  const [next, setNext] = useState(imagePerRow);
  const handleMoreImage = () => {
    setNext(next + imagePerRow);
  };


  const navigate = useNavigate();
  useEffect(
     ()=>{if(user.update_profile_details!=="False"){
     return navigate('/edit-profile',{state:'true'})
    }}
  ,[]
  )

  // if(user.update_profile_details=="False"){

  return (
    <>
      <ScrollTop>
        <DashboardHeader></DashboardHeader>
        <div className="sent-myprofile-main">
          <div className="invitation-sentlist-sidebar-main">
            <Box
              sx={{
                width: 300,
                height: 600,
                backgroundColor: "",
                "&:hover": {
                  backgroundColor: "aliceblue",
                },
              }}
            >
              <div>
                {/* <div>Dashboard</div>
              <div>Setting</div>
              <div>Logout</div> */}
                <SidebarLink></SidebarLink>
              </div>
            </Box>
          </div>

          <div className="invitation-sentlist-formob">
            <div>
              <Box className="sent-ListBox">
                <p className="sent-ListText">Sent List</p>
                <div>
                  <InputBase
                    sx={{ p: "10px" }}
                    className="sent-Listsearchbtn"
                    placeholder="Search"
                  />
                  <IconButton
                    className="sent-Listsearchbtnicon"
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </div>
                <Grid
                  container
                  spacing={2}
                  className="sent-ListmessageContainer-main"
                >
                  <Grid
                    item
                    lg={12}
                    xl={12}
                    md={12}
                    sm={12}
                    xs={12}
                    className="sent-ListmessageGrid"
                  >
                    {sentList.slice(0, next).map((post, index) => {
                      return (
                        <div className="sent-List-messageMainbox">
                          <Grid container spacing={2}>
                            <Grid item lg={2} xl={2} md={2} sm={3} xs={3}>
                              <div className="sent-Listmessagecontainer">
                                <div className="sent-Listmessageinner">
                                  <img
                                    className="sent-ListmessageImg"
                                    variant="top"
                                    // src="./Assets/Images/I1.webp"
                                    src={
                                      post.trip_details.photo
                                        ? post.trip_details.photo
                                        : "./Assets/Images/Favicon 25 WB _ DV.webp"
                                    }
                                    alt="..."
                                  />
                                </div>
                              </div>
                            </Grid>

                            <Grid item lg={6} xl={6} md={6} sm={5} xs={5}>
                              <Typography className="sent-ListmessageThara">
                                {post.trip_details.name}
                              </Typography>
                              <Typography className="sent-ListharaText">
                                {post.trip_details.destination.split("(")[0]}
                                <span
                                  style={{
                                    borderLeft: "0.1em solid pink",
                                    marginLeft: "5px",
                                    paddingLeft: "5px",
                                  }}
                                >
                                  {post.trip_details.departing_on
                                    ? ` ${post.trip_details.departing_on}`
                                    : `${post.trip_details.date_range_from}`}
                                </span>
                              </Typography>
                            </Grid>

                            <Grid item lg={4} xl={4} md={4} sm={4} xs={4}>
                              <Grid
                                container
                                spacing={2}
                                className="sent-ListButton"
                              >
                                <Grid
                                  item
                                  lg={6}
                                  xl={6}
                                  md={6}
                                  sm={5}
                                  xs={5}
                                  className="sent-ListButtonDetailGrid"
                                >
                                  <Button
                                    className="sent-ListProfile"
                                    onClick={() =>
                                      handleClick(
                                        post.trip_details,
                                        post.user_details
                                      )
                                    }
                                  >
                                    DETAILS
                                  </Button>
                                </Grid>
                                <Grid
                                  item
                                  lg={6}
                                  xl={6}
                                  md={6}
                                  sm={5}
                                  xs={5}
                                  className="sent-ListButtonDetailGrid"
                                >
                                  <Button
                                    className="sent-ListProfile"
                                    onClick={() => {
                                      setCancelId(post.connection_id);
                                      setCancelAgree(false);
                                      setSentDetailsOpen(true);
                                    }}
                                  >
                                    CANCEL
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                      );
                    })}
                    <div
                      style={{
                        margin: "auto",
                        justifyContent: "center",
                        display: "flex",
                        marginTop: 45,
                      }}
                    >
                      {sentTriperror}
                    </div>
                  </Grid>
                </Grid>
                <Modal id={id} open={open} closeIcon={closeIcon}>
                  <ConnectPeoplePopup
                    onChildClick={handleClose}
                    connectUserData={dataToPopup}
                    userdetails={profiledata}
                    page="invitationsent"
                  />
                </Modal>
                {next < sentList.length && (
                  <div
                  className="sent-load-more-btn"
                  onClick={handleMoreImage}
                >
                  Load more 
                  <span>
                    <ArrowCircleDownSharpIcon style={{ marginTop: -1, fontSize: 20 }} />
                  </span>
                  </div>
                )}
              </Box>

              <Container>
                <Dialog
                  open={sentDetailsOpen}
                  onClose={handleClose}
                  PaperProps={{
                    style: {
                      minHeight: "40vh",
                      minWidth: "35vw",
                      boxShadow: "none",
                      border: "2px solid rgb(128 213 184 / 100%)",
                      strokeWidth: "1px",
                      stroke: "rgb(128 213 184 )",
                      borderRadius: 20,
                    },
                  }}
                  BackdropProps={{
                    style: {
                      backdrop: "blur(2px)",
                    },
                  }}
                >
                  <div>
                    <DialogTitle id="alert-dialog-title">
                      <div className="sentinvitation-upcoming-delete-dalog">
                        <Icon
                          className="sentinvitation-deleteConfirmationIcon"
                          icon="fluent:delete-12-filled"
                        />
                        Cancel
                      </div>
                      <hr className="sentinvitation-Hrline" />
                    </DialogTitle>
                    <DialogContent className="sentinvitation-upcoming-delete-dalog">
                      <h2 className="sentinvitation-areUSure">
                        {" "}
                        Are you sure?
                      </h2>
                    </DialogContent>
                    <DialogActions className="sentinvitation-buttonsContainer">
                      <Button
                        id="btn"
                        className="sentinvitation-yesButton"
                        onClick={() => {
                          cancelCard();
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        className="sentinvitation-noButton"
                        onClick={handleClose}
                      >
                        No
                      </Button>
                    </DialogActions>
                  </div>
                </Dialog>
              </Container>
            </div>

            {/* <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box className="sent-ListBox">
                  <p className="sent-ListText">Sent List</p>
                  <div>
                    <InputBase
                      sx={{ p: "10px" }}
                      className="sent-Listsearchbtn"
                      placeholder="Search"
                    />
                    <IconButton
                      className="sent-Listsearchbtnicon"
                      type="submit"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                  </div>
                  <Grid
                    container
                    spacing={2}
                    className="sent-ListmessageContainer-main"
                  >
                    <Grid item xs={12} className="sent-ListmessageGrid">
                      {sentList.slice(0, next).map((post, index) => {
                        return (
                          <div className="sent-List-messageMainbox">
                            <Grid container>
                              <Grid item lg={2} xl={2} md={2} sm={2} xs={2}>
                                <div className="sent-Listmessagecontainer">
                                  <div className="sent-Listmessageinner">
                                    <img
                                      className="sent-ListmessageImg"
                                      variant="top"
                                      // src="./Assets/Images/I1.webp"
                                      src={
                                        post.trip_details.photo
                                          ? post.trip_details.photo
                                          : "./Assets/Images/human_dummy_image.jpg"
                                      }
                                      alt="..."
                                    />
                                  </div>
                                </div>
                              </Grid>

                              <Grid item lg={5} xl={5} md={5} sm={5} xs={5}>
                                <Typography className="sent-ListmessageThara">
                                  {post.trip_details.name}
                                </Typography>
                                <Typography className="sent-ListharaText">
                                  {post.trip_details.destination.split("(")[0]}
                                  <span
                                    style={{
                                      borderLeft: "0.1em solid pink",
                                      marginLeft: "5px",
                                      paddingLeft: "5px",
                                    }}
                                  >
                                    {post.trip_details.departing_on
                                      ? ` ${post.trip_details.departing_on}`
                                      : `${post.trip_details.date_range_from} to ${post.trip_details.date_range_to}`}
                                  </span>
                                </Typography>
                              </Grid>
                              <Grid item lg={2} xl={2} md={2} sm={2} xs={2}>
                                <Button
                                  className="sent-ListProfile"
                                  onClick={() =>
                                    handleClick(
                                      post.trip_details,
                                      post.user_details
                                    )
                                  }
                                >
                                  DETAILS
                                </Button>
                              </Grid>
                              <Grid item lg={2} xl={2} md={2} sm={2} xs={2}>
                                <Button
                                  className="sent-ListProfile-btn"
                                  onClick={() => {
                                    setCancelId(post.connection_id);
                                    setCancelAgree(false);
                                    setSentDetailsOpen(true);
                                  }}
                                >
                                  CANCEL
                                </Button>
                              </Grid>
                            </Grid>
                            <Modal id={id} open={open} closeIcon={closeIcon}>
                              <ConnectPeoplePopup
                                onChildClick={handleClose}
                                connectUserData={dataToPopup}
                                userdetails={profiledata}
                                page="invitationconnected"
                              />
                            </Modal>
                          </div>
                        );
                      })}
                    </Grid>
                  </Grid>

                  {next < sentList.length && (
                    <div
                      style={{ color: "pink", marginTop: 35 }}
                      onClick={handleMoreImage}
                    >
                      Load more Results
                      <span>
                        <ArrowCircleDownSharpIcon />
                      </span>
                    </div>
                  )}
                </Box>
              </Grid>
            </Grid>

            <Container>
              <Dialog
                open={sentDetailsOpen}
                onClose={handleClose}
                PaperProps={{
                  style: {
                    minHeight: "40vh",
                    minWidth: "35vw",
                    boxShadow: "none",
                    border: "2px solid rgb(128 213 184 / 100%)",
                    strokeWidth: "1px",
                    stroke: "rgb(128 213 184 )",
                    borderRadius: 20,
                  },
                }}
                BackdropProps={{
                  style: {
                    backdrop: "blur(2px)",
                  },
                }}
              >
                <div>
                  <DialogTitle id="alert-dialog-title">
                    <div className="sentinvitation-upcoming-delete-dalog">
                      <Icon
                        className="sentinvitation-deleteConfirmationIcon"
                        icon="fluent:delete-12-filled"
                      />
                      Cancel
                    </div>
                    <hr className="sentinvitation-Hrline" />
                  </DialogTitle>
                  <DialogContent className="sentinvitation-upcoming-delete-dalog">
                    <h2 className="sentinvitation-areUSure"> Are you sure?</h2>
                  </DialogContent>
                  <DialogActions className="sentinvitation-buttonsContainer">
                    <Button
                      id="btn"
                      className="sentinvitation-yesButton"
                      onClick={() => {
                        cancelCard();
                      }}
                    >
                      Yes
                    </Button>
                    <Button
                      className="sentinvitation-noButton"
                      onClick={handleClose}
                    >
                      No
                    </Button>
                  </DialogActions>
                </div>
              </Dialog>
            </Container>
          </div> */}

            {/* <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box className="sentList">
                  <p className="sentListText">Sent List</p>
                  <InputBase
                    sx={{ p: "10px" }}
                    className="sentListsearchbtn"
                    placeholder="Search"
                  />
                  <IconButton
                    className="sentListsearchbtnicon"
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                  <Grid
                    container
                    spacing={2}
                    className="sentListmessageContainer"
                  >
                  
                    {sentList.length > 0 ? (
                      <Grid container spacing={2} className="">
                        <Grid
                          item
                          lg={12}
                          className=" cardInnerContaineSentlist"
                        >
                          {sentList.map((item) => (
                            <Grid item lg={12} className="sentListmessageGrid">
                              <div className="sentListbox">
                                <div className="sentListcontainer">
                                  <div className="sentListmessageinner">
                                    <img
                                      className="sentListmessageImg"
                                      variant="top"
                                      // src="./Assets/Images/I1.webp"
                                      src={
                                        item.trip_details.photo
                                          ? item.trip_details.photo
                                          : "./Assets/Images/human_dummy_image.jpg"
                                      }
                                      alt="..."
                                    />
                                  </div>
                                </div>
                                <Grid className="sentListTextContainer">
                                  <Typography className="sentListThara">
                                    {" "}
                                    {item.trip_details.name}
                                  </Typography>
                                  <Typography className="sentListharaText">
                                    {
                                      item.trip_details.destination.split(
                                        "("
                                      )[0]
                                    }
                                    <span
                                      style={{
                                        borderLeft: "0.1em solid pink",
                                        marginLeft: "5px",
                                        paddingLeft: "5px",
                                      }}
                                    >
                                      {item.trip_details.departing_on
                                        ? ` ${item.trip_details.departing_on}`
                                        : `${item.trip_details.date_range_from} to ${item.trip_details.date_range_to}`}
                                    </span>
                                  </Typography>
                                </Grid>
                                <div className="sentListBtnContainer">
                                  <Button
                                    className="sentListDetailsBTN"
                                    onClick={() =>
                                      handleClick(item.trip_details)
                                    }
                                  >
                                    DETAILS
                                  </Button>
                                  <Modal
                                    id={id}
                                    open={open}
                                    closeIcon={closeIcon}
                                  >
                                    <ConnectPeoplePopup
                                      onChildClick={handleClose}
                                      connectUserData={dataToPopup}
                                      userdetails={profiledata}
                                      page="sentinvitation"
                                    />
                                  </Modal>
                                  <div
                                    className="sentListCancelBTN"
                                    onClick={() => {
                                      setCancelId(item.connection_id);
                                      setCancelAgree(false);
                                      setSentDetailsOpen(true);
                                    }}
                                  >
                                    CANCEL
                                  </div>
                                </div>
                              </div>
                            </Grid>
                          ))}
                          <div className="loadMoreInvitation">
                            Load more Results
                            <span>
                              <ArrowCircleDownSharpIcon />
                            </span>
                          </div>
                        </Grid>
                      </Grid>
                    ) : (
                      <div>
                        <p>{sentList.message}</p>
                      </div>
                    )}

                    <Container>
                      <Dialog
                        open={sentDetailsOpen}
                        onClose={handleClose}
                        PaperProps={{
                          style: {
                            minHeight: "40vh",
                            minWidth: "35vw",
                            boxShadow: "none",
                            border: "2px solid rgb(128 213 184 / 100%)",
                            strokeWidth: "1px",
                            stroke: "rgb(128 213 184 )",
                            borderRadius: 20,
                          },
                        }}
                        BackdropProps={{
                          style: {
                            backdrop: "blur(2px)",
                          },
                        }}
                      >
                        <div>
                          <DialogTitle id="alert-dialog-title">
                            <div className="sentinvitation-upcoming-delete-dalog">
                              <Icon
                                className="sentinvitation-deleteConfirmationIcon"
                                icon="fluent:delete-12-filled"
                              />
                              Cancel
                            </div>
                            <hr className="sentinvitation-Hrline" />
                          </DialogTitle>
                          <DialogContent className="sentinvitation-upcoming-delete-dalog">
                            <h2 className="sentinvitation-areUSure">
                              {" "}
                              Are you sure?
                            </h2>
                          </DialogContent>
                          <DialogActions className="sentinvitation-buttonsContainer">
                            <Button
                              id="btn"
                              className="sentinvitation-yesButton"
                              onClick={() => {
                                cancelCard();
                              }}
                            >
                              Yes
                            </Button>
                            <Button
                              className="sentinvitation-noButton"
                              onClick={handleClose}
                            >
                              No
                            </Button>
                          </DialogActions>
                        </div>
                      </Dialog>
                    </Container>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </div> */}
            <div>
              <EditProfile></EditProfile>
            </div>
          </div>
        </div>
      </ScrollTop>
    </>
  );

// }else{

//   return <Navigate replace to={"/edit-profile"}></Navigate>
// }
}

export default InvitationSentList;
