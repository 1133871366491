import { Box, Card, Container, Grid } from "@mui/material";
import React from "react";
import Header from "../../Components/Header/Header";
import "../../CSS/HowItWorks.css";
import ScrollTop from "../../ScrollTop";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Link, useNavigate } from "react-router-dom";
function HowItWorks() {
  const navigate = useNavigate();
  return (
    <>
    <ScrollTop>
      <Header></Header>
      <Container>
        <div className="how-it-works-flow-header-text">
          Flow of <span style={{ color: "#1ec28b" }}>Desi</span> Travel
          Companion
        </div>
        <Card className="how-it-works-flow-Card">
          <Container>
            <div className="how-it-works-flow-desitc-labeltext">
              Desi Travel Companion
            </div>
            <Grid container spacing={2}>
              <Grid item xl={7} lg={7} md={7} xs={7} sm={7}>
                <p className="how-it-works-flow-desitc-subtext">
                  Desi Travel Companion (Desi TC) is a free to use platform to
                  find Travel Companion to / from India, Pakistan, Bangladesh,
                  Sri Lanka, Nepal and beyond.
                </p>
              </Grid>
              <Grid item xl={5} lg={5} md={5} xs={5} sm={5}>
                <div className="how-it-works-grid-container-img">
                  <img
                    src="../../Assets/Images/DV 1.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img"
                  />
                </div>
                <div className="how-it-works-grid-container-img-mobile">
                  <img
                    src="../../Assets/Images/MV 1.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img-mv1"
                  />
                </div>
                <div className="how-it-works-hrLine "></div>
              </Grid>
            </Grid>
          </Container>
          <div className="how-it-works-sign-login">
            <Container className="how-it-works-signcontainer">
              <div className="how-it-workscontainer">
                <div className="how-it-worksinner">
                  <div className="how-it-works-number">1</div>
                </div>
              </div>
              <div className="how-it-works-signText">
                Sign Up/Register & Login
              </div>
            </Container>
          </div>
          <Container>
            <div className="how-it-works-flow-signup">Sign Up/Register</div>
            <Grid container spacing={2}>
              <Grid item xl={7} lg={7} md={7} xs={7} sm={7}>
                <p className="how-it-works-flow-signup-subtext">
                  The user will sign up to get the full experience of Travel Companionship.
                </p>
              </Grid>
              <Grid item xl={5} lg={5} md={5} xs={5} sm={5}>
                <div className="how-it-works-grid-container-img">
                  <img
                    src="../../Assets/Images/DV 2.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img2"
                  />
                </div>
                <div className="how-it-works-grid-container-img-mobile">
                  <img
                    src="../../Assets/Images/MV 2.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img-mv2"
                  />
                </div>
              </Grid>
            </Grid>
            <Grid container spacing={2}>
              <Grid item xl={7} lg={7} md={7} xs={7} sm={7}>
                <div className="how-it-works-login">Login</div>
                <p className="how-it-works-login-content">
                  The user will login to enjoy the full customer journey of
                  Travel Companionship.
                </p>
              </Grid>
              <Grid item xl={5} lg={5} md={5} xs={5} sm={5}>
                <div className="how-it-works-grid-container-img">
                  <img
                    src="../../Assets/Images/DV 3.webp"
                    alt="MV 1"
                    className="how-it-works-login-img"
                  />
                </div>
                <div className="how-it-works-grid-container-img-mobile">
                  <img
                    src="../../Assets/Images/MV 3.webp"
                    alt="MV 1"
                    className="how-it-works-login-img-mv3"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>

          <div className="how-it-works-sign-login">
            <Container className="how-it-works-signcontainer">
              <div className="how-it-workscontainer">
                <div className="how-it-worksinner">
                  <div className="how-it-works-number">2</div>
                </div>
              </div>
              <div className="how-it-works-signText">Create a Trip</div>
            </Container>
          </div>
          <Container>
            <Grid container spacing={2}>
              <Grid item xl={7} lg={7} md={7} xs={7} sm={7}>
                <p className="how-it-works-para">
                  The user will fill up the form as Travel Companion or on behalf. 
                  Once submitted , one confirmation mail will go the
                  user. He/She can share on social media as post.
                </p>
              </Grid>
              <Grid item xl={5} lg={5} md={5} xs={5} sm={5}>
                <div className="how-it-works-grid-container-img">
                  <img
                    src="../../Assets/Images/DV 4.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img4"
                  />
                </div>
                <div className="how-it-works-grid-container-img-mobile">
                  <img
                    src="../../Assets/Images/MV 4.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img-mv4"
                  />
                </div>
              </Grid>
            </Grid>
            <div className="how-it-works-login-upcoming">
              My Trips - Upcoming Trips
            </div>
            <Grid container spacing={2}>
              <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                <p className="how-it-works-login-upcoming-content">
                  The user can see all the upcoming trips. He/She can cancel it
                  also.
                </p>
              </Grid>
              <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                <div className="how-it-works-grid-container-img">
                  <img
                    src="../../Assets/Images/DV 5.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img5"
                  />
                </div>
                <div className="how-it-works-grid-container-img-mobile">
                  <img
                    src="../../Assets/Images/MV 5.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img-mv5"
                  />
                </div>
              </Grid>
            </Grid>
            <div className="how-it-works-login-past-trips">
              My Trips-Past Trips
            </div>

            <Grid container spacing={2}>
              <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                <p className="how-it-works-login-past-trips-content">
                  The user can see all the past trips. He/She can upload the
                  picture and short description of it.
                </p>
              </Grid>
              <Grid item xl={6} lg={6} md={6} xs={6} sm={6}>
                <div className="how-it-works-grid-container-img">
                  <img
                    src="../../Assets/Images/DV 6.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img6"
                  />
                </div>
                <div className="how-it-works-grid-container-img-mobile">
                  <img
                    src="../../Assets/Images/MV 6.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img-mv6"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
          <div className="how-it-works-sign-login">
            <Container className="how-it-works-signcontainer">
              <div className="how-it-workscontainer">
                <div className="how-it-worksinner">
                  <div className="how-it-works-number">3</div>
                </div>
              </div>
              <div className="how-it-works-homepagecompanionText">
                Homepage with companion search
              </div>
            </Container>
          </div>
          <Container>
            <Grid container spacing={2}>
              <Grid item xl={7} lg={7} md={7} xs={7} sm={7}>
                {/* <div className="how-it-works-login"></div> */}
                <p className="how-it-works-login-content">
                  The user can look for the TC or being TC for a particular
                  source-destination on a particular date. it navigate to the
                  Connect People page with searched results. He/She can see the
                  latest posts,explore by Destination Countries.
                </p>
              </Grid>
              <Grid item xl={5} lg={5} md={5} xs={5} sm={5}>
                <div className="how-it-works-grid-container-img">
                  <img
                    src="../../Assets/Images/DV 7 a.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img7a"
                  />
                  <img
                    src="../../Assets/Images/DV 7 b.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img7b"
                  />
                </div>
                <div className="how-it-works-grid-container-img-mobile">
                  <img
                    src="../../Assets/Images/MV 7.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img-mv7"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
          <div className="how-it-works-sign-login">
            <Container className="how-it-works-signcontainer">
              <div className="how-it-workscontainer">
                <div className="how-it-worksinner">
                  <div className="how-it-works-number">4</div>
                </div>
              </div>
              <div className="how-it-works-signText">Invitations</div>
            </Container>
          </div>
          <Container>
            <Grid container spacing={2}>
            <Grid item xl={7} lg={7} md={7} xs={7} sm={7}>
                <div className="how-it-works-sentlist">Sent List</div>
                <p className="how-it-works-sentlist-content">
                  The user can see all the sent requests. He/She can see the
                  travel details. He/She can cancel it also.
                </p>
              </Grid>
              <Grid item xl={5} lg={5} md={5} xs={5} sm={5}>
                <div className="how-it-works-grid-container-img">
                  <img
                    src="../../Assets/Images/DV 8.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img8"
                  />
                </div>
                <div className="how-it-works-grid-container-img-mobile">
                  <img
                    src="../../Assets/Images/MV 8.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img-mv8"
                  />
                </div>
              </Grid>
              <Grid item xl={7} lg={7} md={7} xs={7} sm={7}>
                <div className="how-it-works-receivedlist">Received List</div>
                <p className="how-it-works-receivedlist-content">
                  The user can see all the received requests. He/She can see the
                  profile details. He/She can cancel it also.
                </p>
              </Grid>
              <Grid item xl={5} lg={5} md={5} xs={5} sm={5}>
                <div className="how-it-works-grid-container-img">
                  <img
                    src="../../Assets/Images/DV 9.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img9"
                  />
                </div>
                <div className="how-it-works-grid-container-img-mobile">
                  <img
                    src="../../Assets/Images/MV 9.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img-mv9"
                  />
                </div>
              </Grid>
              <Grid item xl={7} lg={7} md={7} xs={7} sm={7}>
                <div className="how-it-works-connectedlist">Connected List</div>
                <p className="how-it-works-connectedlist-content">
                  The user can see the accepted profile,journey details. He/She
                  can communicate with the connected person.
                </p>
              </Grid>
              <Grid item xl={5} lg={5} md={5} xs={5} sm={5}>
                <div className="how-it-works-grid-container-img">
                  <img
                    src="../../Assets/Images/DV 10.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img10"
                  />
                </div>
                <div className="how-it-works-grid-container-img-mobile">
                  <img
                    src="../../Assets/Images/MV 10.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img-mv10"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
          <div className="how-it-works-sign-login">
            <Container className="how-it-works-signcontainer">
              <div className="how-it-workscontainer">
                <div className="how-it-worksinner">
                  <div className="how-it-works-number">5</div>
                </div>
              </div>
              <div className="how-it-works-signText">Dashboard</div>
            </Container>
          </div>
          <Container>
            <Grid container spacing={2}>
            <Grid item xl={7} lg={7} md={7} xs={7} sm={7}>
                <p className="how-it-works-search-content">
                  The user can see the latests posts along with a search.
                </p>
              </Grid>
              <Grid item xl={5} lg={5} md={5} xs={5} sm={5}>
                <div className="how-it-works-grid-container-img">
                  <img
                    src="../../Assets/Images/DV 11.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img11"
                  />
                </div>
                <div className="how-it-works-grid-container-img-mobile">
                  <img
                    src="../../Assets/Images/MV 11.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img-mv11"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
          <div className="how-it-works-sign-login">
            <Container className="how-it-works-signcontainer">
              <div className="how-it-workscontainer">
                <div className="how-it-worksinner">
                  <div className="how-it-works-number">6</div>
                </div>
              </div>
              <div className="how-it-works-signText">Notifications</div>
            </Container>
          </div>
          <Container>
            <Grid container spacing={2}>
            <Grid item xl={7} lg={7} md={7} xs={7} sm={7}>
                <p className="how-it-works-notification-content">
                  All the latest notifications will appear here.
                </p>
              </Grid>
              <Grid item xl={5} lg={5} md={5} xs={5} sm={5}>
                <div className="how-it-works-grid-container-img">
                  <img
                    src="../../Assets/Images/DV 12.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img12"
                  />
                </div>
                <div className="how-it-works-grid-container-img-mobile">
                  <img
                    src="../../Assets/Images/MV 12.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img-mv12"
                  />
                </div>
              </Grid>
            </Grid>
          </Container>
          <div className="how-it-works-sign-login">
            <Container className="how-it-works-signcontainer">
              <div className="how-it-workscontainer">
                <div className="how-it-worksinner">
                  <div className="how-it-works-number">7</div>
                </div>
              </div>
              <div className="how-it-works-signText">Logout</div>
            </Container>
          
          </div>
          <Container>
            <Grid container spacing={2}>
             <Grid item xl={7} lg={7} md={7} xs={7} sm={7}>
                <p className="how-it-works-logout-content">
                  The user can logout
                </p>
              </Grid>
              <Grid item xl={5} lg={5} md={5} xs={5} sm={5}>
                <div className="how-it-works-grid-container-img">
                  <img
                    src="../../Assets/Images/DV 13.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img13"
                  />
                </div>
                <div className="how-it-works-grid-container-img-mobile">
                  <img
                    src="../../Assets/Images/MV 13.webp"
                    alt="MV 1"
                    className="how-it-works-desi-travel-img-mv13"
                  />
                </div>
              </Grid>
              
            </Grid>
          </Container>
        </Card>
      </Container>
      <Grid className="backTohome-howitworks" style={{cursor: "pointer"}} onClick={()=>{
            navigate("/dashboard")
        }}> 
        <ArrowBackIcon style={{color:"#1ec28b"}}/> Back to Dashboard</Grid>
      </ScrollTop>
    </>
  );
}

export default HowItWorks;