import React, {useState} from "react";
import {Icon} from "@iconify/react";
import { Bar, BarChart, LabelList, Legend, XAxis, YAxis,Tooltip} from "recharts";
import dayjs from "dayjs";


function AdminDashBarGraph({state,dates,dateFormatter}) {


  const getDateFormat=(date)=>{
    let str=dateFormatter(dates.startDate)
     return str.split(":")[1];
  }

  const renderLegend = (props) => {
    const { payload } = props;
    let a=[
      {
        name:"I am a Companion",
        color:"#1ec28b"
      },
      {
        name:"Need a Companion",
        color:"#f58a75"
      }]
    return (
      <div className="legend-parent-con">
        {
          a.map((entry, index) => (
            <div key={`item-${index}`} className="legend-con">
              <div className="legend-square" style={{backgroundColor:`${entry.color}`}}></div>
              <div className="legend-name">{entry.name}</div>
            </div>
          ))
        }
      </div>
    );
  }
  return (
    <>
     { state&&(typeof state!=="string"&&state.length)? <div className="barChart-dashboard">
                  <div className="left-label-dashboard">No. of trips created  <Icon icon='tabler:arrow-right' className="dashboard-graph-arrow"/></div>
                    <div className="bottom-label-dashboard"> {(typeof dates==="string"?dayjs(dates).format("MMM DD, YYYY"):getDateFormat(dates)) }<Icon icon='tabler:arrow-right' className="dashboard-graph-arrow"/></div>
                    <div className="squareBar-dashboard"></div>
                      <BarChart 
                      width={300} 
                      height={200} 
                      data={state}
                      barGap={"-25%"}
                      >
                        <XAxis
                          dataKey="day"
                          tick={{ fontSize: 10,strokeWidth: 0,color:'#6c6c6c' }}
                          tickLine={false}
                          width={20}
                          scale="point"
                          padding={{ left: 10, right: 10 }}
                          axisLine={false}
                          className="Admin-bar-xaxis"
                        />
                        <YAxis
                          tick={{ fontSize: 10,color:'#6c6c6c'}}
                          tickLine={false}
                          tickCount={5}
                          type="number"
                          domain={[0, 'dataMax + 25']}
                          scale="linear"
                          axisLine={false}
                          className="Admin-bar-yaxis"
                        />
                        <Legend 
                        align="center" 
                        margin={ {top: 50, left: 20, right: 0, bottom: 0} } 
                        wrapperStyle={{bottom:'-25px',left:"40px"}}
                        className="userType-legend"
                        content={renderLegend}
                        verticalAlign="bottom"
                         />
                        <Bar
                          dataKey="IAC"
                          fill="#1ec28b"
                          maxBarSize={10}
                          margin={{ right: 0, left: 0 }}
                          legendType='square'
                          barGap={0}
                          // onMouseOver={(props)=>{
                          //   console.log(props.tooltipPayload,'tooltipProps')
                          //   let data=props.tooltipPayload[0]
                          //     setTooltipContent({
                          //       value:data.payload.value,
                          //     })
                          //  }}                         

                        >
                        <LabelList dataKey="self"  position="top" />
                          </Bar>
                          <Bar
                          dataKey="NAC"
                          fill="#f58a75"
                          maxBarSize={10}
                          margin={{ right: 0, left: 0 }}
                          legendType='square'
                          barGap={0}
                          // onMouseOver={(props)=>{
                          //   console.log(props.tooltipPayload,'tooltipProps')
                          //   let data=props.tooltipPayload[0]
                          //     setTooltipContent({
                          //       value:data.payload.value,
                          //     })
                          //  }}                         

                        >
                        <LabelList dataKey="self"  position="top" />
                          </Bar>
                      </BarChart>
                    </div>:<div>{state}</div>}
    </>
    // <div className="admin-bar-graph with-margin">
    //   <div className="col-4">
    //     <div className="bar-graph-left-label ">
    //       No. of Trips created
    //       <Icon icon="tabler:arrow-right" />
    //     </div>
    //     <div className="bar-graph-bottom-label">
    //       Oct 2022
    //       <Icon icon="tabler:arrow-right" />
    //     </div>

    //     <div className="bar-graph-top-line"></div>

    //     <Chart
    //       options={state.options}
    //       series={state.series}
    //       type="bar"
    //       width="370"
    //     />
    //   </div>
    // </div>
  );
}

export default AdminDashBarGraph;

