import { Container, Grid } from '@mui/material'
import React from 'react'
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
export default function AdminHeader() {
  return (
    <div style={{borderBottom: "2px #dfd9d3 solid",paddingBottom:"10px",
    paddingTop: "10px"}}>
      {/* <h1 style={{}}>header</h1> */}
      <Container>
      <Grid  container
                spacing={2}> 
        <Grid   item
                  xl={3}
                  lg={3}
                  md={3}
                  xs={3}
                  sm={3}>
                    <img src="../../Assets/Images/desi-travel-campanion-logo.png" 
                    style={{width: "100",height: "40px", display: "flex",    marginLeft: "-57px"}}></img>
                  </Grid>
        <Grid
        item
        xl={9}
        lg={9}
        md={9}
        xs={9}
        sm={9}
        style={{display: "flex",
          justifyContent:" end"}}>
            <AccountCircleOutlinedIcon style={{    backgroundColor: "#f1f1f1",
    padding: "7px",
    color: "#817e7e",
    fontSize: "31px",
    marginRight:"-57px"}}></AccountCircleOutlinedIcon>
          </Grid>
        </Grid> 
      </Container>
      </div>
  )
}
