import React, { useState } from "react";
import {
  Badge,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import { Link } from "react-router-dom";
import "../../CSS/navbar.css";
import { Icon } from "@iconify/react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import axios from "axios";

function DrawerComponent() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { isLoggedIn, user } = useSelector((state) => state.auth);

  const [click, setClick] = useState(false);
  const handleClick = () => setClick(!click);

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [dashboardProfile, setdashboardProfile] = useState([]);
  const [dashboardNotification, setDashboardNotification] = useState(0);
  const [canCreate,setCancreate]=useState(Boolean(JSON.parse(localStorage.getItem("can"))))





  const [invitopen, setinvitopen] = React.useState(false);
  const handleinvitClose = () => {
    setinvitopen(false);
  };
  const handleClickk = () => {
    if (!isLoggedIn) {
         setinvitopen(true);
    }

    else
    if(!canCreate)
    setinvitopen(true);

  };

  // useEffect(() => {
  //   axios
  //     .get(
  //       `http://ec2-54-185-6-32.us-west-2.compute.amazonaws.com:81/user/profile/${user.user_id}/`
  //     )
  //     .then((response) => {
  //       console.log(response, "dashboard img response");

  //       if (response.data) {
  //         console.log(response.data, "dashboard img response data");
  //         setdashboardProfile(response.data);
  //       }
  //     })
  //     .catch((error) => {
  //       if (error.response.status === 400) {
  //         console.log("data : ", error.response.data.message);
  //       }
  //     });
  // }, []);

  const HandleDashNotification = () => {

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");
	
	

    if (isLoggedIn) {
      axios
        .get(
          `${domain}/notifications/unseen/${user.user_id}/`
        )
        .then((response) => {
          console.log(response, "notification response");
          console.log(response.data.unread_count, "not response");
          if (response.data) {
            console.log(response.data, "notification response data");
            setDashboardNotification(response.data);
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            console.log("notification : ", error.response.data.message);
          }
        });
    }
  };

  useEffect(() => {
    HandleDashNotification();
  }, []);

  return (
    <>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          sx: { width: "72%" },
        }}
      >
        <List style={{ background: "#ffffff", height: "100%" }}>
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link 
              to={isLoggedIn
                &&Boolean(JSON.parse(localStorage.getItem("can")))
                ?"/create-a-trip":''}  
              style={{ textDecoration: "none" }} onClick={handleClickk}>
                <span style={{ color: "#1ec28b", fontWeight: "bold" }}>
                  Create a Trip
                </span>
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem onClick={() => setOpenDrawer(false)}>
            <ListItemText>
              <Link to="/companion-search" style={{ textDecoration: "none" }}>
                <span style={{ color: "#1ec28b", fontWeight: "bold" }}>
                  Companion search
                </span>
              </Link>
            </ListItemText>
          </ListItem>
          <Divider />

          {isLoggedIn ? (
            <>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link
                    to="/notification"
                    style={{ textDecoration: "none" }}
                    onClick={handleClick}
                  >
                    {" "}
                    <span style={{ color: "#1ec28b", fontWeight: "bold" }}>
                      Notifications
                    </span>{" "}
                    &nbsp;&nbsp;
                    <Badge
                      color="success"
                      badgeContent={dashboardNotification.unread_count}
                      sx={{
                        "& .MuiBadge-badge": {
                          backgroundColor: "#1ec28b",
                          width: "5px",
                          // transform: "translate(5px, -5px)",
                        },
                      }}
                    ></Badge>
                  </Link>
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link
                    to="/dashboard"
                    style={{ textDecoration: "none" }}
                    onClick={handleClick}
                  >
                    <span style={{ color: "#1ec28b", fontWeight: "bold" }}>
                      Dashboard
                    </span>
                  </Link>
                </ListItemText>
              </ListItem>
            </>
          ) : (
            <>
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                  <Link
                    to="/login"
                    className="nav-logo-desktop"
                    style={{ textDecoration: "none" }}
                  >
                    <Button
                      onClick={handleClick}
                      activeClassName="active"
                      className="nav-links-button-login"
                    >
                      Login
                    </Button>
                  </Link>
                </ListItemText>
              </ListItem>
              <Divider />
              <ListItem onClick={() => setOpenDrawer(false)}>
                <ListItemText>
                <a href="https://www.facebook.com/gDesiTravelCompanion" target="_blank">
                  <Icon
                    icon="fa:facebook-official"
                    style={{ fontSize: 30 }}
                    className="nav-links-notofication-icon"
                  />
                  </a>
                </ListItemText>
              </ListItem>
            </>
          )}

          <Divider />
        </List>
      </Drawer>

      <div className="drawer-icon">
        <IconButton onClick={() => setOpenDrawer(!openDrawer)}>
          <Icon
            icon="ci:menu-alt-01"
            rotate={2}
            hFlip={true}
            style={{ color: "#1ec28b", fontSize: 35 }}
          />
        </IconButton>
      </div>
      <div>
            <Container>
              <Dialog
                open={invitopen}
                onClose={handleinvitClose}
                // {/* // aria-labelledby="alert-dialog-title"
                // // aria-describedby="alert-dialog-description" */}
                PaperProps={{
                  style: {
                    minHeight: "40vh",
                    minWidth: "35vw",
                    boxShadow: "none",
                    border: "2px solid rgb(128 213 184 / 100%)",
                    strokeWidth: "1px",
                    stroke: "rgb(128 213 184 )",
                    borderRadius: 20,
                  },
                }}
                BackdropProps={{
                  style: {
                    backdrop: "blur(2px)",
                  },
                }}
              >
                <div>
                  <DialogTitle id="alert-dialog-title">
                  {/* {handleinvitClose ? (
        <IconButton
          aria-label="close"
          onClick={handleinvitClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null} */}
                    {/* <div className="invitation-upcoming-delete-dalog">
                      <Icon
                        className="invitation-deleteConfirmationIcon"
                        icon="fluent:delete-12-filled"
                      />
                      Delete
                    </div> */}
                    
                  </DialogTitle>
                  <DialogContent
                    className="invitation-upcoming-delete-dalog"
                  >
                    <h2 className="invitation-areUSure" style={{color:"red"}}> 
                    { !isLoggedIn?"please login to create a trip":
                    !canCreate?
                    "You have reached the maximum limit of 4 trips, So you cannot create trip":""}
                    </h2>
                  </DialogContent>
                  <DialogActions className="invitation-buttonsContainer">
                    {/* <Button
                      id="btn"
                      className="invitation-yesButton"
                      onClick={() => {
                        settingdeleteinvitation();
                      }}
                    >
                      Yes
                    </Button> */}
                    <Button
                      className="invitation-noButton"
                      onClick={handleinvitClose}
                    >
                      OK
                    </Button>
                  </DialogActions>
                </div>
              </Dialog>
            </Container>
</div> 
    </>
  );
}
export default DrawerComponent;