import * as React from 'react';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';

export default function LoadingPage() {
  return (
    <div style={{
        height:"100%",
        // backgroundColor:"yellow",
        display:"flex",
        alignItems:"center",
        justifyContent:"center"
        }}>
         <Stack sx={{ color: 'rgb(30 194 139 / 10%)' }} spacing={2} direction="row">
     
         <CircularProgress sx={{color:"#1ec28b",fontSize:"50px"}} />
   
   </Stack>
    </div>
    
  );
}