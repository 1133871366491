const AccordianContent = () => {
  const accordionData = [
    {
      title: "1. How do I change my Password?",
      content: `Click on Menu at the top right corner and click Dashboard
          option if on home page else Click your profile picture at the
          top right corner. Then Click “Settings” to be redirected to
          Settings page to Change Password. Type your Old
          password, new password and Confirm new Password field
          and click “SAVE” to change your password.`,
    },
    {
      title: "2. How do I change my Email ID and / or Mobile number?",
      content: `Click on Menu at the top right corner and click Dashboard
      option if on home page else Click your profile picture at the
      top right corner, then Click “My Categories” and then “My
      Profile”. You can see Edit profile button, press the button to
      change your details along with Email ID, mobile number
      and save the change.`,
    },

    {
      title: "3. How do I delete my account?",
      content: `Click on Menu at the top right corner and click Dashboard
      option if on home page else Click your profile picture at the
      top right corner, then Click “Settings” to be redirected to
      Settings page to Delete account by clicking Delete button.`,
    },
  ];

  const ConnectedData = [
    {
      ConnectTitle: "1. How can I connect with other travelers?",
      ConnectContent: `Click on Menu at the top right corner and click “Companion
      Search”. Search as per the criteria you need to potentially
      see travelers. Click “More Info” to see full itinerary of the
      traveler (User has to be logged in). Click on “Invite to be
      your TC”.
      An invitation will be sent to the traveler you have invited to
      be your Travel Companion.
      This invitation is available by Clicking on Menu at the top
      right corner and under My Categories -> TC Invitations ->
      Sent List.
      Similarly, Invitations received by you are available by
      Clicking on Menu at the top right corner and under My
      Categories -> TC Invitations -> Received List.`,
    },
    {
      ConnectTitle: "2. What is Connected List?",
      ConnectContent: `Trips either accepted by you or trips accepted by other
      travelers invited by you are listed under Connected List.
      Clicking on Menu at the top right corner and under My
      Categories -> TC Invitations -> Connected List.`,
    },
    {
      ConnectTitle: "3. How do I share my Experience?",
      ConnectContent: `Click on Menu at the top right corner and click Dashboard
      Option if on home page else Click your profile picture at the
      top right corner, then Click “My Categories”. Then click “My
      Trips” and “Past Trips”. “Upload your Memories” button is
      available to upload your experience as a selfie you’re your
      travel companion.`,
    },
    // {
    //   ConnectTitle: "4. How do I share my experience?",
    //   ConnectContent: `Click your profile picture at the top right corner, you will be redirected to the
    //   dashboard page. Then Click “Past Trips” under My Trips option on the page. There you
    //   can see past trips with the “Upload your memories” button. Press the button, share your
    //   experience and post it.`,
    // },
    // {
    //   ConnectTitle: "5. How do I edit my trip?",
    //   ConnectContent: `Click your profile picture at the top right corner, you will be redirected to the
    //   dashboard page. Then Click “Upcoming Trips” under My Trips option on the page. There
    //   you can see the trips under “Trips created by me”. You can select and edit a particular trip
    //   you want.`,
    // },
  ];

  const GettingStartedData = [
    {
      GettingStartedTitle: "1. How can I create a Trip?",
      GettingStartedContent: `Click on Menu at the top right corner and click “Create a
      Trip” option. Fill the details as directed and save your trip.`,
    },
    {
      GettingStartedTitle: "2. How do I Edit my Trip?",
      GettingStartedContent: `Click on Menu at the top right corner and click Dashboard
      option if on home page else Click your profile picture at the
      top right corner, then Click “My Categories”. Then click “My
      Trips” and “Upcoming Trips”. Click on Edit to modify your
      trip details.`,
    },
    {
      GettingStartedTitle: "3. How do delete my Trip?",
      GettingStartedContent: `Click on Menu at the top right corner and click Dashboard
      option if on home page else Click your profile picture at the
      top right corner, then Click “My Categories”. Then click “My
      Trips” and “Upcoming Trips”. Click on Edit to modify your
      trip details.`,
    },
    // {
    //   GettingStartedTitle: "4.How do I share my experience?",
    //   GettingStartedContent: `Click your profile picture at the top right corner, you will be redirected to the
    //       dashboard page. Then Click “Past Trips” under My Trips option on the page. There you
    //       can see past trips with the “Upload your memories” button. Press the button, share your
    //       experience and post it.`,
    // },
    // {
    //   GettingStartedTitle: "5. How do I edit my trip?",
    //   GettingStartedContent: `Click your profile picture at the top right corner, you will be redirected to the
    //       dashboard page. Then Click “Upcoming Trips” under My Trips option on the page. There
    //       you can see the trips under “Trips created by me”. You can select and edit a particular trip
    //       you want.`,
    // },
  ];

  const PaymentData = [
    {
      PaymentTitle: "1.Does Desi Travel Companion handle payments?",
      PaymentContent: `NO, Payments or Tips for being a travel companion has to be
          mutual between the travel companions and are ideally given once
          the destination is reached.`,
    },
    // {
    //   PaymentTitle: "2. How desi community benefit from travel companion?",
    //   PaymentContent: `Travel companion help the desi community to explore a wide variety of information
    //   including educational advantages, available communication methods, exploration chances,
    //   and entertainment options.’`,
    // },
    // {
    //   PaymentTitle: "3.How Can I contact travel companion?",
    //   PaymentContent: `Click the “Contact Us” button in the footer section, you will be redirected to the Contact
    //   form. You can fill out the form and submit it to contact us.`,
    // },
    // {
    //   PaymentTitle: "4.How should I enquiry for an ad?",
    //   PaymentContent: `Click the ad on the home page, it will redirect you to the “Enquiry for Ads” page. You
    //   can fill out and submit the form for the enquiry.
    //   You can also click the Ad’s Enquiry Link in the footer to fill out this form.`,
    // },
  ];

  const WholeData = {
    accordionData,
    ConnectedData,
    GettingStartedData,
    PaymentData,
  };

  return WholeData;
};

export default AccordianContent;