import React, {useEffect, useState} from "react";
import usePagination from "@mui/material/usePagination";
import "../../src/AdminDashboard/CSS/adsmanagement.css";
import axios from "axios";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import {
  Button,
  Card,
  Container,
  Grid,
  Pagination,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Adminsidebar from "./Adminsidebar";
import {Icon} from "@iconify/react";
import {Link, useNavigate} from "react-router-dom";
import AdminHeader from "./Components/AdminHeader";
import {getDomain} from "../domain/Domain";
import ScrollTop from "../ScrollTop";

const AdsManagement = () => {
  const [noresultsFound, setNoresultsFound] = useState(null);
  const [expiredCount, setExpiredCount] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [filteredData, setFilteredData] = useState([]);
  const [switchdata, setSwitchData] = useState();
  const [holdValue, setHoldValue] = useState();
  const [holdOpen, setHoldOpen] = useState(false);
  const [holdId, setHoldId] = useState();
  const [searchValue, setSearchValue] = useState();
  const [counts, setCounts] = useState();
  const [deleteId, setDeleteId] = useState();
  const [open, setOpen] = React.useState(false);

  const deleteCard = () => {
    axios
      .delete(`${getDomain()}/admin/ads/${deleteId}/`)
      .then(response => {
        console.log(response.data, "delete ad");
        viewTripimage();
        setOpen(false);
      })
      .catch(errorResponse => {
        console.log(errorResponse);
      });
  };
  console.log(deleteId, "deleteId");
  const handleClickOpen = id => {
    setOpen(true);
    setDeleteId(id);
  };
  const holdClickOpen = (ads_id, holdVal) => {
    setHoldOpen(true);
    setHoldId(ads_id);
    setHoldValue(holdVal);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const handleSearch = event => {
    const {value} = event.target;
    if(value)   setSearchValue(value);
    else  viewTripimage()
   
  };
  useEffect(() => {
    axios
      .get(`${getDomain()}/ads-location/`)
      .then(response => {
        console.log(response, "adsmanagement");
      })
      .catch(errorResponse => {
        console.log(errorResponse);
      });
  }, []);

  const viewTripimage = async () => {
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(isSearching(), "isSearching()");
    axios
      .get(
        isSearching()
          ? `${getDomain()}/admin/ads-management/?page=${currentPage}&q=${searchValue}`
          : `${getDomain()}/admin/ads-management/?page=${currentPage}`
      )
      .then(response => {
        if (isSearching()) {
          setFilteredData(response.data.results);
          setNoresultsFound(null);
        } else {
          setFilteredData(response.data.results.response_data);
          setExpiredCount(response.data.results.expired_ads);
        }
        setCounts(response.data);
      })
      .catch(error => {
        setNoresultsFound(error.message, "response");
      });
  };

  const Switchhandler = () => {
    axios

      .patch(`${getDomain()}/admin/ads/${holdId}/`, {on_hold: !holdValue})
      .then(response => {
        console.log(response, "onhold");
        setCurrentPage(currentPage);
        viewTripimage();
      })
      .catch(errorResponse => {
        console.log(errorResponse);
      });
    setHoldOpen(false);
  };
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
    viewTripimage(value);
  };
  const isSearching = () => {
    let flag = false;
    if (searchValue) flag = true;

    return flag;
  };

  useEffect(() => {
    viewTripimage();
  }, [currentPage]);
  const {items} = usePagination({
    count: 2,
  });
  const Navigate = useNavigate();

  return (
    <>
      <ScrollTop>
        <AdminHeader></AdminHeader>
        <div className="dbsql-profile-main">
          <div style={{borderRight: "groove", minHeight: "90vh"}}>
            <Adminsidebar />
          </div>
          <Container>
            <div className="admindashboard-heading">Ads Management</div>
            <Breadcrumbs
              separator="›"
              aria-label="breadcrumb"
              className="ads-management-subhead"
            >
              {/* {breadcrumbs} */}
              <Link to={{}} className="ads-manage-home">
                Home
              </Link>
              <Typography className="ads-management-subheading">
                Ads Management
              </Typography>
            </Breadcrumbs>
            <Card>
              <Container>
                <Container>
                  <Grid
                    container
                    spacing={2}
                    className="ads-management-card-maingrid"
                  >
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={3}
                      xs={3}
                      sm={3}
                      style={{marginTop: 40, columnGap: 30}}
                    >
                      <Card className="ads-management-card">
                        <div className="ads-management-number">
                          {counts ? counts.count : ""}
                        </div>
                        <div className="ads-management-content">
                          Total no. of Ads{" "}
                        </div>
                      </Card>
                    </Grid>
                    <Grid
                      item
                      xl={3}
                      lg={3}
                      md={3}
                      xs={3}
                      sm={3}
                      style={{marginTop: 40}}
                    >
                      <Card className="ads-management-second-card">
                        <div className="ads-management-number">
                          {expiredCount}
                        </div>
                        <div className="ads-management-content">
                          Expired Ads{" "}
                        </div>
                      </Card>
                    </Grid>
                  </Grid>
                  <Grid
                    container
                    spacing={2}
                    className="ads-management-main-div"
                  >
                    <Grid item xl={8} lg={8} md={8} xs={8} sm={8}>
                      <div>
                        <TextField
                          className="ads-management-search-feild "
                          id="standard-bare"
                          variant="outlined"
                          name="name"
                          placeholder="Search by Name or email"
                          onChange={handleSearch}
                          fullWidth
                          inputProps={{
                            style: {
                              height: 11,
                            },
                          }}
                          InputProps={{
                            startAdornment: (
                              <Icon
                                onClick={() => {
                                  viewTripimage();
                                }}
                                className="ads-management-searchicon"
                                icon="ic:round-search"
                                style={{cursor: "pointer"}}
                              />
                            ),
                          }}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      xs={4}
                      sm={4}
                      className="ads-management-button-grid"
                    >
                      <Button
                        component={Link}
                        to="/admin/admin-ads-management-create/new"
                        className="ads-management-button"
                      >
                        {" "}
                        <span style={{fontSize: "12"}}>
                          <Icon
                            icon="ic:round-plus"
                            className="ads-management-icon"
                            width={22}
                            height={22}
                          />
                        </span>
                        &nbsp; &nbsp;
                        <span style={{textTransform: "capitalize"}}>C</span>
                        reate new ad
                      </Button>
                    </Grid>
                  </Grid>
                </Container>
                {noresultsFound ? (
                  <Container>
                    <div className="search-bar-no-results-found-main-div">
                      {" "}
                      <img
                        src="../../Assets/Images/Search illustration.jpg"
                        className="search-illustration-img"
                      ></img>
                      <div className="adsmanagement-no-results-found">
                        Ups!... no results found
                      </div>
                      <div>
                        No content matched your keyword. Please try searching
                        for something else
                      </div>
                    </div>
                  </Container>
                ) : (
                  <Container>
                    <table className="ads-management-table">
                      <tr className="ads-manage-heading-tr-main">
                        <th>Ad Id</th>
                        <th>Name&Company Name</th>
                        <th>Website link</th>
                        <th>Expiry Date</th>
                        <th>State</th>
                        <th>Action</th>
                      </tr>
                      <tbody>
                        {filteredData.map(item => (
                          <tr className="ads-manage-heading-tr">
                            <td>
                              <p>{item.id}</p>
                            </td>
                            <td>
                              <p>{item.name}</p>
                              <p className="ads-manage-flipkart">
                                {" "}
                                {item.company_name}
                              </p>
                            </td>
                            <td className="ads-manage-flipkart-com">
                              {item.website}{" "}
                            </td>
                            <td>{item.end_period}</td>
                            <td>
                              {" "}
                              <Switch
                                className="ads-management-switch"
                                // checked={
                                //   item.on_hold ||
                                //   moment(item.end_period).isBefore()
                                // }
                                checked={item.on_hold}
                                onChange={e => {
                                  holdClickOpen(item.id, item.on_hold);
                                  setSwitchData(item);
                                }}
                              />
                              <Dialog
                                open={holdOpen}
                                className="ads-management-delete-dialog"
                                PaperProps={{
                                  style: {
                                    minHeight: "40vh",
                                    minWidth: "35vw",
                                    boxShadow: "none",
                                    border: "2px solid rgb(128 213 184 / 100%)",
                                    strokeWidth: "1px",
                                    stroke: "rgb(128 213 184 )",
                                    borderRadius: 20,
                                  },
                                }}
                                sx={{
                                  "& .MuiBackdrop-root": {
                                    backgroundColor: "transparent",
                                
                                  },
                                  "& .css-hz1bth-MuiDialog-container": {
                                    height: 0,
                                    padding: 0,
                                  },
                                }}
                              >
                                <div>
                                  <DialogTitle id="alert-dialog-title"></DialogTitle>

                                  <DialogContent className="ads-management-delete-dalog">
                                    {holdOpen && (
                                      <h2 className="ads-management-areUSure">
                                        Are you sure, you want to{" "}
                                        {!holdValue ? "hold" : "unhold"} the
                                        user?
                                      </h2>
                                    )}

                                  </DialogContent>
                                  <DialogActions className="ads-management-buttonsContainer">
                                    <Button
                                      id="btn"
                                      className="ads-management-yesButton"
                                      onClick={() => {
                                        console.log(switchdata, "item");
                                        Switchhandler(switchdata.id);
                                      }}
                                    >
                                      Yes
                                    </Button>
                                    <Button
                                      className="ads-management-noButton"
                                      onClick={() => setHoldOpen(false)}
                                    >
                                      No
                                    </Button>
                                  </DialogActions>
                                </div>
                              </Dialog>
                            </td>
                            <td className="ads-manage-main-td">
                              <Icon
                                onClick={() => {
                                  Navigate(
                                    `/admin/admin-ads-management-create/${item.id}`
                                  );
                                }}
                                to="/admin-ads-management-create/new"
                                icon="ic:outline-remove-red-eye"
                                className="ads-manage-eyeicon"
                              />
                              <Icon
                                onClick={() => {
                                  handleClickOpen(item.id);
                                }}
                                icon="ic:baseline-delete"
                                className="ads-manage-deleteicon"
                              />
                              <Dialog
                                open={open}
                                className="ads-management-delete-dialog"
                                PaperProps={{
                                  style: {
                                    minHeight: "40vh",
                                    minWidth: "35vw",
                                    boxShadow: "none",
                                    border: "2px solid rgb(128 213 184 / 100%)",
                                    strokeWidth: "1px",
                                    stroke: "rgb(128 213 184 )",
                                    borderRadius: 20,
                                  },
                                }}
                                sx={{
                                  "& .MuiBackdrop-root": {
                                    backgroundColor: "transparent",
                                
                                  },
                                  "& .css-hz1bth-MuiDialog-container": {
                                    height: 0,
                                    padding: 0,
                                  },
                                }}
                              >
                                <div>
                                  <DialogTitle id="alert-dialog-title"></DialogTitle>
                                  <DialogContent className="ads-management-delete-dalog">
                                    <h2 className="ads-management-areUSure">
                                      {" "}
                                      Are you sure, you want to delete?
                                    </h2>
                                  </DialogContent>
                                  <DialogActions className="ads-management-buttonsContainer">
                                    <Button
                                      id="btn"
                                      className="ads-management-yesButton"
                                      onClick={() => {
                                        deleteCard();
                                      }}
                                    >
                                      Yes
                                    </Button>
                                    <Button
                                      className="ads-management-noButton"
                                      onClick={handleClose}
                                    >
                                      No
                                    </Button>
                                  </DialogActions>
                                </div>
                              </Dialog>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </Container>
                )}
                <div>
                  {console.log(filteredData, "filterdata")}
                  <Pagination
                    className="ads-manage-pagination"
                    remove={noresultsFound}
                    count={Math.ceil(counts ? counts.count / 5 : null)}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                </div>
              </Container>
            </Card>
          </Container>
        </div>
      </ScrollTop>
    </>
  );
};

export default AdsManagement;