import "../CSS/sidebarLink.css";
import React from "react";
import { Link, NavLink } from "react-router-dom";
// import "../../CSS/sidebarLink.css";
import { Drawer, Grid, List, Paper } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import ListSubheader from "@mui/material/ListSubheader";
// import List from '@mui/material/List';
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import StarBorder from "@mui/icons-material/StarBorder";
import AccountCircleTwoToneIcon from "@mui/icons-material/AccountCircleTwoTone";
import ChatBubbleSharpIcon from "@mui/icons-material/ChatBubbleSharp";
import InsertInvitationIcon from "@mui/icons-material/InsertInvitation";
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';

import { logout } from "../actions/auth";
import { useDispatch, useSelector } from "react-redux";
import { Icon } from "@iconify/react";

function SidebarLink() {
  const dispatch = useDispatch();

  const [dashboardState, setDashboardState] = React.useState(false);

  const DashboardMenu = () => {
    setDashboardState(!dashboardState);
  };

  const [MyTripsState, setMyTripsState] = React.useState(false);

  const MyTripsMenu = () => {
    setMyTripsState(!MyTripsState);
  };
  const [InvitationsState, setInvitationsState] = React.useState(false);

  const InvitationsMenu = () => {
    setInvitationsState(!InvitationsState);
  };

  const logOut = () => {
    dispatch(logout());
  };

  return (
    <div className="link">
      <List
        //   sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}
        component="nav"
        aria-labelledby="nested-list-subheader"
        //   subheader={
        //     <ListSubheader component="div" id="nested-list-subheader">
        //       Nested List Items
        //     </ListSubheader>
        //   }
      >
        <ListItemButton onClick={DashboardMenu} style={{}}>
          <ListItemIcon className="listItemicon">
            {/* <InboxIcon /> */}
          </ListItemIcon>
          <ListItemText primary="My Categories" />
          {dashboardState ? (
            <ExpandLess className="sidebaricon" />
          ) : (
            <ExpandMore className="sidebaricon" />
          )}
        </ListItemButton>
        <Collapse in={dashboardState} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {/* <NavLink
              className={({ isActive }) => (isActive ? "active" : "inactive")}
              to="/dashboard"
            > */}
              <ListItemButton
                component={NavLink}
                className={({ isActive }) => (isActive ? "active" : "inactive")}
                to="/dashboard"
                sx={{ pl: 12 }}
              >
                <ListItemIcon>
                  {/* <Icon
                    icon="material-symbols:dashboard-customize-outline"
                    className="sidebaricon"
                  /> */}
                  <DashboardCustomizeIcon icon="material-symbols:dashboard-customize-outline" className="sidebaricon"/>
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
              </ListItemButton>
            {/* </NavLink> */}
            {/* <NavLink
              className={({ isActive }) => (isActive ? "active" : "inactive")}
              to="/profile"
            > */}
              <ListItemButton  component={NavLink} className={({ isActive }) => (isActive ? "active" : "inactive")}
              to="/profile" sx={{ pl: 12 }}>
                <ListItemIcon>
                  <AccountCircleTwoToneIcon className="sidebaricon" />
                </ListItemIcon>
                <ListItemText primary="My Profile" />
              </ListItemButton>
            {/* </NavLink> */}
            <ListItemButton sx={{ pl: 12 }} onClick={MyTripsMenu}>
              <ListItemIcon>
                <StarBorder className="sidebaricon" />
              </ListItemIcon>
              <ListItemText primary="My Trips" />
              {MyTripsState ? (
                <ExpandLess className="sidebaricon" />
              ) : (
                <ExpandMore className="sidebaricon" />
              )}
            </ListItemButton>
            <Collapse in={MyTripsState} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/upcoming-trips"
                > */}
                  <ListItemButton
                    component={NavLink}
                    className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/upcoming-trips"
                    sx={{ pl: 12 }}
                  >
                    <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                    <ListItemText primary="Upcoming Trips" />
                  </ListItemButton>
                {/* </NavLink> */}
                {/* <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/past-trips"
                > */}
                  <ListItemButton
                    component={NavLink}
                    className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/past-trips"
                    sx={{ pl: 12 }}
                  >
                    <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                    <ListItemText primary="Past Trips" />
                  </ListItemButton>
                {/* </NavLink> */}
              </List>
            </Collapse>
            <ListItemButton sx={{ pl: 12 }} onClick={InvitationsMenu}>
              <ListItemIcon>
                <InsertInvitationIcon className="sidebaricon" />
              </ListItemIcon>
              <ListItemText primary="TC Invitations" />
              {InvitationsState ? (
                <ExpandLess className="sidebaricon" />
              ) : (
                <ExpandMore className="sidebaricon" />
              )}
            </ListItemButton>
            <Collapse in={InvitationsState} timeout="auto" unmountOnExit>
              <List component="div" disablePadding>
                {/* <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/connected-list"
                > */}
                  <ListItemButton
                    component={NavLink}
                    className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/connected-list"
                    sx={{ pl: 12 }}
                  >
                    <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                    <ListItemText primary="Connected List" />
                  </ListItemButton>
                {/* </NavLink> */}

                {/* <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/sent-list"
                > */}
                  <ListItemButton
                    component={NavLink}
                    className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/sent-list"
                    sx={{ pl: 12 }}
                  >
                    <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                    <ListItemText primary="Sent List" />
                  </ListItemButton>
                {/* </NavLink> */}
                {/* <NavLink
                  className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/recieved-list"
                > */}
                  <ListItemButton
                    component={NavLink}
                    className={({ isActive }) =>
                    isActive ? "active" : "inactive"
                  }
                  to="/recieved-list"
                    sx={{ pl: 12 }}
                  >
                    <ListItemIcon>{/* <StarBorder /> */}</ListItemIcon>
                    <ListItemText primary="Recieved List" />
                  </ListItemButton>
                {/* </NavLink> */}
              </List>
            </Collapse>
            {/* <NavLink
              className={({ isActive }) => (isActive ? "active" : "inactive")}
              to="/notification"
            > */}
              <ListItemButton
                component={NavLink}
                className={({ isActive }) => (isActive ? "active" : "inactive")}
                to="/notification"
                sx={{ pl: 12 }}
              >
                {/* <Link to='/notification'> */}
                <ListItemIcon>
                  <NotificationsIcon className="sidebaricon" />
                </ListItemIcon>
                <ListItemText primary="Notifications" />
                {/* </Link> */}
              </ListItemButton>
            {/* </NavLink> */}
            {/* <ListItemButton component={Link} to='/message' sx={{ pl: 12 }}>
            <ListItemIcon >
              <ChatBubbleSharpIcon className="sidebaricon"/>
            </ListItemIcon>
            <ListItemText primary="Messages" />
          </ListItemButton> */}
            {/* <ListItemButton
              component={Link}
              to="/dashboard"
              sx={{ pl: 12 }}
            >
              <ListItemIcon>
              </ListItemIcon>
              <ListItemText primary="Dashboard" />
            </ListItemButton> */}
          </List>
        </Collapse>
        {/* <NavLink
         
        > */}
          <ListItemButton component={NavLink}  to="/settings"
          className={({ isActive }) => (isActive ? "active" : "inactive")}>
            <ListItemIcon>{/* <NotificationsIcon /> */}</ListItemIcon>
            <ListItemText primary="Settings" />
          </ListItemButton>
        {/* </NavLink> */}
        {/* <NavLink className={({ isActive }) => (isActive ? "inactive" : "active")}> */}
        <ListItemButton onClick={logOut}>
          <ListItemIcon>{/* <DraftsIcon /> */}</ListItemIcon>
          <ListItemText primary="Logout" />
        </ListItemButton>
        {/* </NavLink> */}
      </List>
    </div>
  );
}
export default SidebarLink;