import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import admin_auth from "./admin_auth";
import admin_message from "./admin_message";
export default combineReducers({
  auth,
  message,
  admin_auth,
  admin_message,
});