import React, { useEffect,useState } from "react";
import {
  Box,
  Button,
  Paper,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
// import * as Yup from 'yup';
import { useForm, FormProvider } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import TravellerDetails from "./TravellerDetails";
import StartTrip from "./DestinationDetail";
import TripDetails from "./TripDetails";
import FullDetails from "./FullDetails";
import "./CreateTripNew.css";
import validation from "./Validation";
import { useSelector,useDispatch } from "react-redux";
import axios from "axios";
import { Link,useNavigate } from "react-router-dom";
import { RemoveExtraField,FormattingField } from "./RemoveExtraField";
import CircularProgress from "@mui/material/CircularProgress";
import { green } from '@mui/material/colors';
import TripCount from "../../UpcomingTrip_count/TripCount";



// const API_URL =
//   "http://ec2-54-185-6-32.us-west-2.compute.amazonaws.com:81/create-trip/";

const VerticalStepper = (props) => {
  const {steps,activeStep,setActiveStep,stepCompleted,setStepCompleted,customError,setCustomError}=props
  // const [activeStep, setActiveStep] = React.useState(0);
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  const [success, setSuccess] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  // console.log("user_id", user.user_id);
  const [limit4,setLimit4]=useState(false)
const navigate=useNavigate()
  
  let validationSchema = validation(activeStep);

  console.log(validationSchema,"validationSchema")


  const {
    register,
    control,
    Controller,
    reset,
    handleSubmit,
    getValues,
    setValue,
    watch,
    methods,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(validationSchema),
    // defaultValues:{...finalIni}
  });
  const values = getValues();
  const watchFields = watch(["booking_status","Declaration","photo","date_range_from"]);

  console.log("errors", errors);
  console.log("values..", values);
  useEffect(() => {
    reset({
      photo: "",
      name: "",
      age_group: "",
      gender: "",
      category: "",
      user_type: "",
      tip_expected: "",
      booking_status: "",
      date_range_from: "",
      date_range_to: "",
      flying_from: "",
      destination: "",
      airline: "Yet to Book",
      prefered_language: [],
      departing_on: "",
      airAndTrans: [
        {
          transit: "",
          airline: "",
        },
      ],
      trip_name: "",
      assistance_needed: "",
      meetup_location: "",
      short_description: "",
      can_assist_with:""
    });
  }, []);

  const handleBack = () => {
    setActiveStep((prevActiveStep) =>
      prevActiveStep === 0 ?  navigate("/dashboard") : prevActiveStep - 1,
    );
    if(stepCompleted[activeStep+1]!=="1"){
      let array=stepCompleted
      stepCompleted[activeStep]="0"
      setStepCompleted([...array]);
    }
   
  }


  const totalSteps = () => {
    return steps.length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 2;
  };

  const onSubmit = (values) => {
    if (isLastStep()) {
      setLoading(true)
     const removed=RemoveExtraField(values)
     if(isLoggedIn){
      removed["user_id"] = user.user_id;
     }
     console.log(FormattingField(removed),"Formattedfield")
     const finalBody=FormattingField(removed)


     console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
     let domain = "";
     console.log(process.env, "domain env", window.location.hostname);
     if (window.location.hostname.trim() === "localhost") {
       domain = process.env.REACT_APP_LOCAL_API;
     } else if (window.location.hostname.trim() === "54.185.6.32") {
       domain = process.env.REACT_APP_UAT_API;
     } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
       domain = process.env.REACT_APP_PROD_UAT_API;
     }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
       domain = process.env.REACT_APP_PROD_API;
     }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
       domain = process.env.REACT_APP_PROD_API;
     }
     console.log(domain, "domain env end");


       axios
        .post(
          `${domain}/create-trip/`,
          finalBody
        )
        .then((response) => {
          setLoading(false)
          console.log(response, "responseCreate");
          if(response.data.message.split(" ")[0]!=="Successfully"){
            setLimit4(true)
            setLoading(false)
          }else{
            setValue("trip_id",response.data.trip_id)
            setActiveStep(activeStep+1) 
            let array=stepCompleted
            stepCompleted[activeStep+1]="1"
            setStepCompleted([...array]); 
            TripCount(user.user_id)
          }
        })
        .catch((error) => {
          console.log(error.values);
        });
     
   } else {
        let array=stepCompleted
        stepCompleted[activeStep+1]="1"
        setStepCompleted([...array]);
        setActiveStep(activeStep + 1);
    }
  
    // console.log(data,"data")
  };


  return (
    <div>
     

      <div className="form_body">
      

        <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
          <div>
            {activeStep === 0 ? (
              <TravellerDetails
                control={control}
                Controller={Controller}
                values={values}
                setValue={setValue}
                errors={errors}
                register={register}
                customError={customError}
                setCustomError={setCustomError}    
              />
            ) : activeStep === 1 ? (
              <StartTrip
                control={control}
                Controller={Controller}
                values={values}
                setValue={setValue}
                errors={errors}
                register={register}
                customError={customError}
                setCustomError={setCustomError}    
              />
            ) : activeStep === 2 ? (
              <TripDetails
                control={control}
                Controller={Controller}
                values={values}
                setValue={setValue}
                errors={errors}
                register={register}
                customError={customError}
                setCustomError={setCustomError}    
              />
            ) : activeStep === 3 ? (
              <FullDetails
              page="createTrip"
                control={control}
                Controller={Controller}
                values={values}
                setValue={setValue}
                errors={errors}
                register={register}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                customError={customError}
                setCustomError={setCustomError}    
              />
            ) : null}
          </div>
          {activeStep !== steps.length - 1 && (
            <div  className="cretae-trip-ain-btn">
              <Button
            style={{margin:"1rem"}}
            type="button"
            onClick={handleBack}
            // disabled={
            //   values.Declaration !== true && activeStep === steps.length - 2
            // }
            // hidden={activeStep===0}
            className="stepperNextBtn"
            variant="contained"
            color="primary">
                Back
            </Button>
              <Button
                type="submit"
                disabled={
                  (values.Declaration !== true && activeStep === steps.length - 2)||loading
                }
                className="stepperNextBtn"
                variant="contained"
                color="primary"
              >
                {activeStep === steps.length - 2 ? "Submit" : "Next"}
                {loading&&<CircularProgress
                size={24}
                sx={{
              color: "#ff8d8d",
              position: 'absolute',
              top: '50%',
              left: '50%',
              marginTop: '-12px',
              marginLeft: '-12px',
            }}
          />}
              </Button>
             
              {/* {activeStep !== 0 && (
                <button
                  type="button"
                  onClick={() => {
                    setActiveStep(activeStep - 1);
                  }}
                >
                  Back
                </button>
              )} */}
            </div>
          )}
          {Object.entries(errors).length>0&&<div style={{color:"#ff8d8d"}}>All fields are mandatory *</div>}
          {limit4&&<div style={{color:"#ff8d8d"}}> Since you have reached the maximum limit of upcoming Trips, you cannot create Trips</div>}
        </form>
      </div>
    </div>
  );
};

export default VerticalStepper;

