import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import "../../CSS/profilePage.css";
import { Grid, Button, Container, CardContent } from "@mui/material";
import SidebarLink from "../SidebarLink";
import EditProfile from "../EditProfile";
import DashboardHeader from "../DashboardHeader";
import CreateIcon from "@mui/icons-material/Create";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import CallIcon from "@mui/icons-material/Call";
import PlaceIcon from "@mui/icons-material/Place";
import AddCircleRoundedIcon from "@mui/icons-material/AddCircleRounded";
import { Icon } from "@iconify/react";
import EditIcon from "@mui/icons-material/Edit";
import { Link, Navigate,useNavigate } from "react-router-dom";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import WcIcon from "@mui/icons-material/Wc";
import EmailIcon from "@mui/icons-material/Email";
import PublicIcon from "@mui/icons-material/Public";
import TempleHinduIcon from "@mui/icons-material/TempleHindu";
// import "../../CSS/sidebarLink.css";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";

function ProfilePage() {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  console.log("user_id", user.user_id);
  const [profileData, setProfileData] = useState({});
  useEffect(() => {


    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");



    axios
      .get(
        `${domain}/user/profile/${user.user_id}/`
      )
      .then((response) => {
        console.log(response, "profile response");

        if (response.data) {
          console.log(response.data, "profile response data");
          setProfileData(response.data[0]);
        }
      })
      .catch((error) => {
        // Error
        if (error.response.status === 400) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  }, []);

  const navigate = useNavigate();
  useEffect(
     ()=>{if(user.update_profile_details!=="False"){
     return navigate('/edit-profile',{state:'true'})
    }}
  ,[]
  )


  // if(user.update_profile_details=="False"){

  return (
    <>
      <DashboardHeader></DashboardHeader>
      <div className="profile-page-main-heading">
        <div className="profile-page-sidebarlink">
          <Box
            sx={{
              width: 300,
              height: 600,
              backgroundColor: "",
              "&:hover": {
                backgroundColor: "aliceblue",
              },
            }}
          >
            <div>
              {/* <div>Dashboard</div>
              <div>Setting</div>
              <div>Logout</div> */}
              <SidebarLink></SidebarLink>
            </div>
          </Box>
        </div>
        <Container>
          <div>
            <p className="profile-page-text">My Profile</p>
          </div>
          <Box
            className="profile-box"
            sx={{
              height: 300,
              borderRadius: "15px",
              boxShadow: "1px 2px 2px 1px #d6e1df",
              backgroundColor: "aliceblue",
              "&:hover": {
                backgroundColor: "",
              },
            }}
          >
            <div className="profile-page-card-main-editMain">
            <Grid container spacing={2} className="">
              <Grid
                item
                xs={12}
                sm={12}
                lg={12}
                md={12}
                xl={12}
                style={{ display: "flex", justifyContent: "end",marginRight:20 }}
              >
                <Button
                  LinkComponent={Link}
                  to="/edit-profile"
                  className="profile-edit-btn1"
                  endIcon={<EditIcon className="profile-page-edit-icon" />}
                >
                  Edit Profile
                </Button>
              </Grid>
            </Grid>
            <Grid container spacing={2} className="profile-page-card-main">
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
                className="profile-page-card-container"
              >
                <div className="profile-page-card-inner">
                  <img
                    className="profile-page-img"
                    variant="top"
                    src={
                      profileData.profile_picture == null
                        ? "./Assets/Images/Favicon 25 WB _ DV.webp"
                        : profileData.profile_picture
                    }
                    alt="..."
                  />
                </div>
                <div>
                  {" "}
                  <p className="profile-page-name-label">
                    {profileData.full_name}
                  </p>
                </div>
              </Grid>
              <Grid item xl={8} lg={8} md={8} sm={8} xs={8}>
                <p className="profile-page-about"> About</p>
                <span className="profile-commas">
                  <Icon
                    icon="fluent:comma-20-filled"
                    rotate={2}
                    color="#1ec28b"
                  />
                  <Icon
                    icon="fluent:comma-20-filled"
                    rotate={2}
                    color="#1ec28b"
                    className="comma2"
                  />
                </span>
                <p className="profile-text">{profileData.about}</p>
              </Grid>
            </Grid>
            </div>
          </Box>
          <Box
            sx={{
              height: 300,
              borderRadius: "15px",
              boxShadow: "1px 2px 2px 1px #d6e1df",
              backgroundColor: "aliceblue",
              "&:hover": {
                backgroundColor: "",
              },
            }}
            className="profile-page-second-box"
          >
            <Grid container spacing={2} className="">
              <Grid item xl={2} lg={2} md={1} sm={1} xs={1}>
                <GroupsOutlinedIcon className="profile-page-dataIcons"></GroupsOutlinedIcon>
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
                className="profile-page-info-text"
              >
                Age Group
              </Grid>
              <Grid item xl={6} lg={6} md={7} sm={7} xs={7}>
                <span className="profile-date">: {profileData.age_group}</span>
              </Grid>
              <Grid item xl={2} lg={2} md={1} sm={1} xs={1}>
                <WcIcon className="profile-page-dataIcons"></WcIcon>
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
                className="profile-page-info-text"
              >
                Gender
              </Grid>
              <Grid item xl={6} lg={6} md={7} sm={7} xs={7}>
                <span className="profile-date">: {profileData.gender}</span>
              </Grid>
              <Grid item xl={2} lg={2} md={1} sm={1} xs={1}>
                <EmailIcon className="profile-page-dataIcons"></EmailIcon>
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
                className="profile-page-info-text"
              >
                Email
              </Grid>
              <Grid item xl={6} lg={6} md={7} sm={7} xs={7}>
                <span className="profile-date">: {profileData.email}</span>
              </Grid>
              <Grid item xl={2} lg={2} md={1} sm={1} xs={1}>
                <CallIcon className="profile-page-dataIcons"></CallIcon>
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
                className="profile-page-info-text"
              >
                Mobile Number
              </Grid>
              <Grid item xl={6} lg={6} md={7} sm={7} xs={7}>
                <span className="profile-date">
                  : {profileData.country_code}&nbsp;&nbsp;
                  {profileData.mobile_number}
                </span>
              </Grid>
              <Grid item xl={2} lg={2} md={1} sm={1} xs={1}>
                <PublicIcon className="profile-page-dataIcons"></PublicIcon>
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
                className="profile-page-info-text"
              >
                Country
              </Grid>
              <Grid item xl={6} lg={6} md={7} sm={7} xs={7}>
                <span className="profile-date">: {profileData.country}</span>
              </Grid>
              <Grid item xl={2} lg={2} md={1} sm={1} xs={1}>
                <TempleHinduIcon className="profile-page-dataIcons"></TempleHinduIcon>
              </Grid>
              <Grid
                item
                xl={4}
                lg={4}
                md={4}
                sm={4}
                xs={4}
                className="profile-page-info-text"
              >
                State/Province
              </Grid>
              <Grid item xl={6} lg={6} md={7} sm={7} xs={7}>
                <span className="profile-date">: {profileData.state}</span>
              </Grid>
            </Grid>
          </Box>
        </Container>
        {/* <EditProfile></EditProfile> */}
      </div>
    </>
  );
// }else{

//   return navigate('/edit-profile',{state:'true'})}
// }
          }
export default ProfilePage;
