import React from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import "../../CSS/upComingTrip.css";
import {
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Modal,
  Popover,
  Typography,
} from "@mui/material";
import SidebarLink from "../SidebarLink";
import EditProfile from "../EditProfile";
import DashboardHeader from "../DashboardHeader";
import ArrowCircleDownSharpIcon from "@mui/icons-material/ArrowCircleDownSharp";
import EastIcon from "@mui/icons-material/East";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FlightIcon from "@mui/icons-material/Flight";
import LanguageIcon from "@mui/icons-material/Language";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import RouteIcon from "@mui/icons-material/Route";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { Icon } from "@iconify/react";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";

import Swal from "sweetalert2";
// import Button from '@mui/material/Button';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import { useState } from "react";
import axios from "axios";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Link, Navigate, useNavigate } from "react-router-dom";
import ConnectPeoplePopup from "../../Pages/ConnectPeople/ConnectPeoplePopup";
import TripCount from "../../UpcomingTrip_count/TripCount";


const UpComingTrip = () => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  console.log("user_id", user.user_id);
  const [upcomingTrip, setupcomingTrip] = useState([]);
  // const [deleteopen, setDeleteOpen] = React.useState(false);
  const [closeIcon, setCloseicon] = useState(false);
  const [dataToPopup, setDataToPopup] = useState();
  const [profiledata, setprofiledata] = useState({});
  const [deleteopen, setdeleteOpen] = React.useState(false);
  const [deleteId, setDeleteId] = useState();
  const [canCreate,setCancreate]=useState()

  const [open, setOpen] = React.useState(false);
  const [upcomingTriperror, setupcomingTriperror] = useState();
  // const handleClickOpen = () => {
  //   setdeleteOpen(true);
  // };

  console.log(deleteId, "deleteId");
  const handleClickOpen = (id) => {
    console.log(id, "id");
    setOpen(true);
    setDeleteId(id);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handledeleteClose = () => {
    setdeleteOpen(false);
  };

  useEffect(() => {


    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");
	


    axios
      .get(
        `${domain}/upcoming-trips/${user.user_id}/`
      )
      .then((response) => {
        console.log(response, "upcoming trip response");

        if (response.data.Trips_created_by_me) {
          console.log(
            response.data.Trips_created_by_me,
            "upcoming trip response data"
          );
          setupcomingTrip(response.data.Trips_created_by_me);
        }
      })
      .catch((error) => {
        // Error
        // console.log("status : ",error.response.status);
        // console.log("data : ",error.response.data);
        if (error.response.status === 400) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log("data : ",error.response.data);
          console.log("data : ", error.response.data.message);
          setupcomingTriperror( error.response.data.message);
          // console.log("status : ",error.response.status);
          // console.log("headers : ",error.response.headers);
        }
      });

      setCancreate(TripCount(user.user_id))

  }, []);

  const deleteCard = () => {
    console.log(deleteId, "deleteId");

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .delete(
        `${domain}/create-trip/${deleteId}/`
      )
      .then((response) => {
        console.log(response, "updateprofileresponse-delete");
        setOpen(false);
        const newList = upcomingTrip.filter((card) => {
          return deleteId !== card.id;
        });
        setupcomingTrip(newList);
        if (response.data) {
          console.log(response.data, " response update profile");
          Swal.fire({
            // position: 'center',
            customClass: {
              popup: "my-swal",
            },
            icon: "success",
            iconColor: "#1ec28b",
            title: "successfully Deleted",
            showCloseButton: true,
            showConfirmButton: false,
          });
          console.log(TripCount(user.user_id),"afterDelete")
            TripCount(user.user_id).then((response)=>{
              console.log(response,"afterDelete")
               setCancreate(response,"afterDelete")
            })
          // setResponseMessage(response.data.message);
        }
      })
      .catch((error) => {
        // Error
        // if (error.response.status === 400) {
        //   console.log(error.response.data, "error");
        //   console.log(error.response.status);
        //   // console.log(error.response.headers);
        //   // setResponseMessage(null);
        // }
      });
  };

  // const patchCard = (id) => {
  //   axios
  //     .patch(
  //       `http://ec2-54-185-6-32.us-west-2.compute.amazonaws.com:81/create-trip/${id}/`
  //     )
  //     .then((res) => {
  //       console.log(res, "update profile res");

  //       if (res.data) {
  //         console.log(res.data, " res update profile");
  //       }
  //     })
  //     .catch((error) => {
  //       // Error
  //       if (error.res.status === 400) {
  //         console.log(error.res.data, "error");
  //         console.log(error.res.status);
  //       }
  //     });
  // };

  console.log(upcomingTrip);

  const handleClick = (item, data) => {
    setDataToPopup(item);
    setCloseicon(true);
    setprofiledata(data);
  };
  const handleCloseDetails = () => {
    {
      setCloseicon(false);
    }
  };

  const openmodal = Boolean(closeIcon);
  const id = openmodal ? "simple-popover" : undefined;


  const navigate = useNavigate();
  useEffect(
     ()=>{if(user.update_profile_details!=="False"){
     return navigate('/edit-profile',{state:'true'})
    }}
  ,[]
  )

  // if (user.update_profile_details == "False") {
    return (
      <>
        <DashboardHeader can={canCreate}></DashboardHeader>
        <div className="upcoming-dashboard-main">
          <div className="upcoming-sidebarlinks">
            <Box
              sx={{
                width: 350,
                height: 600,
                backgroundColor: "",
                "&:hover": {
                  backgroundColor: "aliceblue",
                },
              }}
            >
              <div>
                <SidebarLink></SidebarLink>
              </div>
            </Box>
          </div>
          <div className="upcoming-formobanddesk">
            <div>
              <Box className="upcoming-dashbox">
                <div>
                  <p className="upcomingtrip-text">Upcoming Trips</p>
                  {/* <p className="upcomingCreated-text">Trips Created by me</p> */}
                </div>
                {upcomingTrip.length > 0 ? (
                  <Grid container spacing={2} className="">
                    <Grid item lg={12} className="upcomingContainer">
                      {upcomingTrip.map((post) => (
                        <Card className="upcoming-card-up-coming1">
                          <CardContent>
                            <Grid container>
                              <Grid
                                item
                                xs={6}
                                style={{ textAlign: "initial" }}
                              >
                                <b>{post.name}</b>
                              </Grid>
                              <Grid
                                item
                                xs={6}
                                style={{ textAlign: "end", color: "pink" }}
                              >
                                <DeleteForeverIcon
                                  onClick={() => handleClickOpen(post.id)}
                                  // onClick={() => deleteCard(post.id)}
                                ></DeleteForeverIcon>
                              </Grid>
                            </Grid>
                          </CardContent>
                          <div className="upcoming-card-recentcontainer">
                            <div className="upcoming-card-up-coming-inner">
                              <img
                                className="upcoming-cardrecent-img"
                                variant="top"
                                // src="./Assets/Images/I1.webp"
                                src={
                                  post.photo === null
                                    ? "../Assets/Images/Favicon 25 WB _ DV.webp"
                                    : post.photo
                                }
                                alt="..."
                              />
                            </div>
                          </div>

                          <CardContent
                            style={{
                              fontSize: 12,
                            }}
                          >
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                style={{ display: "flex", columnGap: 16 }}
                              >
                                <ModeOfTravelIcon
                                  style={{
                                    color: "#1ec38b",
                                    marginTop: 4,
                                  }}
                                ></ModeOfTravelIcon>
                                <div className="invitation-card-places">
                                  <b> {post.trip_name}</b>
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                style={{ display: "flex", columnGap: 16 }}
                              >
                                <RouteIcon
                                  style={{
                                    color: "#1ec28b",
                                    transform: "rotate(90deg)",
                                    marginTop: 4,
                                  }}
                                />
                                <div className="invitation-card-places">
                                  {/* ----------------------------------- */}
                                  {/* {post.trip_details.flying_from} */}
                                  <b> {post.flying_from.split(",")[0]}</b>
                                  <b>
                                    {" "}
                                    {post.transit1 ? (
                                      <span>
                                        <EastIcon
                                          style={{
                                            color: "#1ec38b",
                                            marginBottom: "-7px",
                                          }}
                                        />
                                        {post.transit1.split(",")[0]}
                                      </span>
                                    ) : null}
                                  </b>
                                  <b>
                                    {post.transit2 ? (
                                      <span>
                                        <EastIcon
                                          style={{
                                            color: "#1ec38b",
                                            marginBottom: "-7px",
                                          }}
                                        />
                                        {post.transit2.split(",")[0]}
                                      </span>
                                    ) : null}
                                  </b>
                                  <b>
                                    {" "}
                                    {post.transit3 ? (
                                      <span>
                                        <EastIcon
                                          style={{
                                            color: "#1ec38b",
                                            marginBottom: "-7px",
                                          }}
                                        />
                                        {post.transit3.split(",")[0]}
                                      </span>
                                    ) : null}
                                  </b>
                                  <b>
                                    {" "}
                                    {post.transit4 ? (
                                      <span>
                                        <EastIcon
                                          style={{
                                            color: "#1ec38b",
                                            marginBottom: "-7px",
                                          }}
                                        />
                                        {post.transit4.split(",")[0]}
                                      </span>
                                    ) : null}
                                  </b>
                                  <EastIcon
                                    style={{
                                      color: "#1ec38b",
                                      marginBottom: "-7px",
                                    }}
                                  />
                                  <b>{post.destination.split(",")[0]}</b>
                                  {/* {post.destination} */}
                                  {/* ----------------------------------- */}
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                style={{ display: "flex", columnGap: 16 }}
                              >
                                <CalendarMonthOutlinedIcon
                                  style={{
                                    color: "#1ec38b",
                                    marginTop: 4,
                                  }}
                                ></CalendarMonthOutlinedIcon>
                                <div className="invitation-card-places">
                                  <b>
                                    {post.booking_status === 2
                                      ? `Date Range : ${post.date_range_from} to ${post.date_range_to}`
                                      : `Departing on : ${post.departing_on}`}
                                  </b>
                                </div>{" "}
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                style={{ display: "flex", columnGap: 16 }}
                              >
                                <FlightIcon
                                  style={{
                                    color: "#1ec38b",
                                    marginTop: 4,
                                  }}
                                ></FlightIcon>
                                <div className="invitation-card-places">
                                  <b>
                                    {post.booking_status === 2
                                      ? "yet to book"
                                      : "booked"}
                                  </b>
                                </div>
                              </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                style={{ display: "flex", columnGap: 16 }}
                              >
                                <LanguageIcon
                                  style={{
                                    color: "#1ec38b",
                                    marginTop: 4,
                                  }}
                                ></LanguageIcon>{" "}
                                <div className="invitation-card-places">
                                  <b>{post.prefered_language}</b>
                                </div>
                              </Grid>
                            </Grid>
                            {/* <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <ModeOfTravelIcon
                                style={{
                                  color: "#1ec38b",
                                  marginTop: 4,
                                }}
                              ></ModeOfTravelIcon>
                              <div className="invitation-card-places">
                                <b> {post.trip_name}</b>
                              </div>
                            </Grid>
                          </Grid> */}

                            {post.category == 1 ? (
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xs={12}
                                  style={{ display: "flex", columnGap: 16 }}
                                >
                                  <AttachMoneyIcon
                                    style={{ color: "#1ec38b", marginTop: 4 }}
                                  ></AttachMoneyIcon>
                                  <div className="invitation-card-places">
                                    {/^([^0-9]*)$/.test(post.tip_expected)
                                      ? post.tip_expected
                                      : `${post.tip_expected} USD equiv`}
                                  </div>
                                </Grid>
                              </Grid>
                            ) : null}
                            {/* </Grid> */}
                          </CardContent>

                          {/* <Grid
                          style={{ marginTop: "4px", marginBottom: "32px" }}
                        >
                          <Button
                            variant="outlined"
                            sx={{
                              color: "black",
                              borderColor: "pink",
                              borderRadius: "12px",
                              fontSize: "12px",
                            }}
                          >
                            Status-
                            <span style={{ color: "green" }}>ACCEPTED</span>
                          </Button>
                        </Grid> */}
                          <Button
                            className="upcoming-accept-btn"
                            // onClick={handleClick}
                            onClick={() => handleClick(post)}
                          >
                            Details
                          </Button>

                          <Button
                            className="upcoming-cancel-btn"
                            // onClick={() => patchCard(post.editTripid)}
                            LinkComponent={Link}
                            to={`/create-a-trip-edit/${post.id}`}
                          >
                            edit
                          </Button>
                        </Card>
                      ))}
                    </Grid>
                  </Grid>
                ) : upcomingTriperror}
                <Modal id={id} open={openmodal} closeIcon={closeIcon}>
                  <ConnectPeoplePopup
                    onChildClick={handleCloseDetails}
                    connectUserData={dataToPopup}
                    userdetails={profiledata}
                    page="upcomingtrip"
                  />
                </Modal>

                {/* <div>
             
              <p className="upcomingCreated-text">Accepted Trips</p>
            </div> */}
                <Container>
                  <Dialog
                    open={open}
                    onClose={handledeleteClose}
                    // aria-labelledby="alert-dialog-title"
                    // aria-describedby="alert-dialog-description"
                    PaperProps={{
                      style: {
                        minHeight: "40vh",
                        minWidth: "35vw",
                        boxShadow: "none",
                        border: "2px solid rgb(128 213 184 / 100%)",
                        strokeWidth: "1px",
                        stroke: "rgb(128 213 184 )",
                        borderRadius: 20,
                      },
                    }}
                    BackdropProps={{
                      style: {
                        backdrop: "blur(2px)",
                      },
                    }}
                  >
                    <div>
                      <DialogTitle id="alert-dialog-title">
                        <div className="upcoming-upcoming-delete-dalog">
                          <Icon
                            className="upcoming-deleteConfirmationIcon"
                            icon="fluent:delete-12-filled"
                          />
                          Delete
                        </div>
                        <hr
                          style={
                            {
                              // height: "1px",
                              // borderWidth: 0,
                              // color: "#1ec28b",
                              // backgroundColor: "#1ec28b",
                              // marginLeft: "50px",
                              // marginRight: "50px",
                            }
                          }
                          className="upcoming-Hrline"
                        />
                      </DialogTitle>
                      <DialogContent className="upcoming-upcoming-delete-dalog">
                        <h2 className="upcoming-areUSure"> Are you sure?</h2>
                      </DialogContent>
                      <DialogActions className="upcoming-buttonsContainer">
                        <Button
                          id="btn"
                          className="upcoming-yesButton"
                          onClick={deleteCard}
                        >
                          Yes
                        </Button>
                        <Button
                          className="upcoming-noButton"
                          onClick={handleClose}
                        >
                          No
                        </Button>
                      </DialogActions>
                    </div>
                  </Dialog>
                </Container>

                {/* <div style={{ color: "pink", marginTop: 34 }}>
               <span style={{ color: "pink", marginBottom: 34 }}> Load more Results   </span>
                <span  style={{ color: "pink", marginBottom: 34 }}>
                  <ArrowCircleDownSharpIcon />
                </span>
             
              </div> */}
              </Box>
            </div>
            <div>
              <EditProfile></EditProfile>
            </div>
          </div>
        </div>
      </>
    );
  // } else {
  //   return <Navigate replace to={"/edit-profile"}></Navigate>;
  // }
};

export default UpComingTrip;