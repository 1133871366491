import React from "react";
import Button from "@mui/material/Button";
import { Box, Container } from "@mui/material";
import "../../CSS/emailVerification.css";
import { Link, useParams } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { useEffect } from "react";

const Emailverification = () => {
  const { id, token } = useParams();
  console.log(id, token, "id-token");
  const [verifydata, setVerifyData] = useState("");

  const HandleEmail = () => {

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");
	

    axios
      .get(
        `${domain}/email-verification/${id}/${token}/`
      )
      .then((response) => {
        if (response.data.message) {
          setVerifyData(response.data.message);
        }
        console.log(response, "verification");
      })
      .catch((err) => {
        console.log(err, "error");
        if (err.response) {
          setVerifyData(err.response.data.message);
        }
      });
  };
  HandleEmail();
  // useEffect(() => {
  //   document.title =  "Desi Travel Companion-email verification";
  // }, []);

  return (
    <div>
      <Container>
        <div className="email-verification-main">
          <div className="imageDiv">
            <img
              src="../../Assets/Images/desi-travel-campanion-logo.png"
              alt="first"
              className="email-verification-img"
            />
          </div>
          <Box
            sx={{
              height: 300,
              backgroundColor: "white",
              "&:hover": {
                backgroundColor: "aliceblue",
              },
            }}
          >
            <p className="email-verification-content">
              {verifydata}
              {/* Email has been verified. Your account has been activated */}
            </p>
            {verifydata !== "The link is not Valid" ? (
              <Link to="/login" style={{ textDecoration: "none" }}>
                <Button variant="contained" className="email-verification-btn">
                  Click to LogIn
                </Button>
              </Link>
            ) : null}
          </Box>
        </div>
      </Container>
    </div>
  );
};

export default Emailverification;