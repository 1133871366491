import React from "react";
import Box from "@mui/material/Box";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import InputBase from "@mui/material/InputBase";
import "../../CSS/invitationRecievedList.css";
import {
  Button,
  Card,
  CardContent,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Modal,
  Popover,
} from "@mui/material";
import SidebarLink from "../SidebarLink";
import EditProfile from "../EditProfile";
import DashboardHeader from "../DashboardHeader";
import ArrowCircleDownSharpIcon from "@mui/icons-material/ArrowCircleDownSharp";
import RouteIcon from "@mui/icons-material/Route";
import EastIcon from "@mui/icons-material/East";
import CalendarMonthOutlinedIcon from "@mui/icons-material/CalendarMonthOutlined";
import FlightIcon from "@mui/icons-material/Flight";
import LanguageIcon from "@mui/icons-material/Language";
import ModeOfTravelIcon from "@mui/icons-material/ModeOfTravel";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import { useEffect } from "react";
import ConnectPeoplePopup from "../../Pages/ConnectPeople/ConnectPeoplePopup";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import { Icon } from "@iconify/react";
import Swal from "sweetalert2";
import { Navigate, useNavigate } from "react-router-dom";

const InvitationRecievedList = (props) => {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  console.log("user_id", user.user_id);
  const [recievedinvite, setrecievedinvite] = useState([]);
  const [closeIcon, setCloseicon] = useState(false);
  const [dataToPopup, setDataToPopup] = useState();
  const [profiledata, setprofiledata] = useState({});
  const [receivedTriperror, setreceivedTriperror] = useState();

  // ------------iNVITATION API-----------------//
  useEffect(() => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .get(
        `${domain}/received-list/${user.user_id}/
        `
      )
      .then((response) => {
        console.log(response, "recieved trip response");

        if (response.data) {
          console.log(response.data, "recieved trip response data");
          setrecievedinvite(response.data);
        }
      })
      .catch((error) => {
        // Error
        // console.log("status : ",error.response.status);
        // console.log("data : ",error.response.data);
        if (error.response.status === 400) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log("data : ",error.response.data);
          console.log("data : ", error.response.data.message);
          setreceivedTriperror(error.response.data.message);
          // console.log("status : ",error.response.status);
          // console.log("headers : ",error.response.headers);
        }
      });
  }, []);

  // ---------------- ACCEPT BUTTON API--------------//
  const [acceptopen, setacceptOpen] = React.useState(false);
  const handleacceptClose = () => {
    setacceptOpen(false);
  };
  const [receivedacceptAgree, setreceivedacceptAgree] = useState(false);
  const [receivedacceptId, setreceivedacceptId] = useState("");

  const acceptinvitation = (connection_id) => {
    console.log(connection_id);
    console.log(receivedacceptId, "connection id");
    if (receivedacceptId !== "") {


      console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
      let domain = "";
      console.log(process.env, "domain env", window.location.hostname);
      if (window.location.hostname.trim() === "localhost") {
        domain = process.env.REACT_APP_LOCAL_API;
      } else if (window.location.hostname.trim() === "54.185.6.32") {
        domain = process.env.REACT_APP_UAT_API;
      } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
        domain = process.env.REACT_APP_PROD_UAT_API;
      }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
        domain = process.env.REACT_APP_PROD_API;
      }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
        domain = process.env.REACT_APP_PROD_API;
      }
      console.log(domain, "domain env end");

      axios
        .patch(`${domain}/accept-invitation/${receivedacceptId}/`)
        .then((response) => {
          console.log(response, "recieved trip response");

          //   if (response.data) {
          //     console.log(response.data, "recieved trip response data");
          //     const newList = recievedinvite.filter((card) => {
          //       return connection_id !== card.connection_id;
          //     });
          //     setrecievedinvite(newList);
          //     Swal.fire({
          //       // position: 'center',
          //       customClass: {
          //         popup: "my-swal",
          //       },
          //       icon: "success",
          //       iconColor: "#1ec28b",
          //       title: "Invitation is accepted",
          //       showCloseButton: true,
          //       showConfirmButton: false,
          //       // background: 'red',
          //     });
          //   }
          // })
          const newList = recievedinvite.filter((card) => {
            return card.connection_id !== receivedacceptId;
          });

          console.log(newList, "ACCEPT FILTERED CARD...");
          setreceivedacceptId(newList);

          if (response.data) {
            setacceptOpen(false);

            console.log(response.data, " response accept profile");
            setreceivedacceptAgree(true);
            Swal.fire({
              // position: 'center',
              customClass: {
                popup: "my-swal",
              },
              icon: "success",
              iconColor: "#1ec28b",
              title: "Successfully Accepted",
              showCloseButton: true,
              showConfirmButton: false,
              closeButtonColor: "#fffffff",
            });
          }
        })
        .catch((error) => {
          if (error.response.status === 400) {
            console.log(error.response.data, "error");
            console.log(error.response.status);
          }
        });
    }
  };

  // ---------------- DELETE BUTTON API--------------//

  const [deleteopen, setdeleteOpen] = React.useState(false);

  const handleClickOpen = () => {
    setdeleteOpen(true);
  };

  const handledeleteClose = () => {
    setdeleteOpen(false);
  };
  const [receiveddeleteAgree, setreceivedDeleteAgree] = useState(false);
  const [receiveddeleteId, setreceivedDeleteId] = useState("");

  const deleteinvitation = () => {
    //  setOpen(false)
    console.log(receiveddeleteId, "card id");
    if (receiveddeleteId !== "") {


          console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");



      axios
        .delete(`${domain}/delete-invitation/${receiveddeleteId}/`)
        .then((response) => {
          console.log(response, "update delete response");

          const newList = recievedinvite.filter((card) => {
            return card.connection_id !== receiveddeleteId;
          });

          console.log(newList, "FILTERED CARD...");
          setrecievedinvite(newList);

          if (response.data) {
            setdeleteOpen(false);

            console.log(response.data, " response delete profile");
            setreceivedDeleteAgree(true);
            Swal.fire({
              // position: 'center',
              customClass: {
                popup: "my-swal",
              },
              icon: "success",
              iconColor: "#1ec28b",
              title: "successfully Deleted",
              showCloseButton: true,
              showConfirmButton: false,
              closeButtonColor: "#fffffff",
            });
          }
        })
        .catch((error) => {
          // Error
          if (error.response.status === 400) {
            console.log(error.response.data, "error");
            console.log(error.response.status);
            // console.log(error.response.headers);
            // setResponseMessage(null);
          }
        });
    }
  };

  //  ----------------DETAILS CONNECT PEOPLE POPUP-----------------------//
  const handleClick = (item, data) => {
    setDataToPopup(item);
    setCloseicon(true);
    setprofiledata(data);
  };
  const handleClose = () => {
    {
      setCloseicon(false);
    }
  };
  const open = Boolean(closeIcon);
  const id = open ? "simple-popover" : undefined;

  // ------------------------- cards for load more result  --------------------------------------------
  const imagePerRow = 4;
  const [next, setNext] = useState(imagePerRow);
  const handleMoreImage = () => {
    setNext(next + imagePerRow);
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (user.update_profile_details !== "False") {
      return navigate("/edit-profile", { state: "true" });
    }
  }, []);

  // const formatted_full={}
  //   const array=[]
  //   Object.entries(data).map((item)=>{

  //       if(item[0]==="tip_expected"){
  //           if(/^([^0-9]*)$/.test(item[1])){
  //               formatted_full["tip_expected"]=item[1]
  //           }
  //           else{
  //               formatted_full["tip_expected"]=`${item[1]} USD equiv`
  //           }

  //       }
  //     })
  // const [fullDetail, setFulldetail] = useState({});
  // useEffect(() => {
  //   const Final_data = Formatting_Fulldetail(formatted_full);
  //   setFulldetail(Final_data);
  // }, []);

  // const firstCon = [{ label: "Tip I Expected", key: "tip_expected" }];
  // if (user.update_profile_details == "False") {
  return (
    <>
      <DashboardHeader></DashboardHeader>
      <div className="invitation-dashboard-main">
        <div className="invitation-recievedlist-main">
          <Box
            sx={{
              width: 300,
              height: 600,
              backgroundColor: "",
              "&:hover": {
                backgroundColor: "aliceblue",
              },
            }}
          >
            <div>
              <SidebarLink></SidebarLink>
            </div>
          </Box>
        </div>
        <div className="invitation-recievedlist-formob">
          <div className="invitationRecieveddashbox">
            <Box>
              <div>
                <p className="invitation-recieved-text">Received List</p>
              </div>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputBase
                    sx={{ p: "10px" }}
                    className="invitation-search-btn-recievelist"
                    placeholder="Search"
                  />
                  <IconButton
                    className="invitation-search-btn-recievelist-icon"
                    type="submit"
                    sx={{ p: "10px" }}
                    aria-label="search"
                  >
                    <SearchIcon />
                  </IconButton>
                </Grid>
              </Grid>

              {/* <div className="invitation-card-receivedList-main-card"> */}
              <Grid container spacing={2} className="">
                <Grid
                  item
                  lg={12}
                  className="invitation-card-receivedList-main-card"
                >
                  {recievedinvite.slice(0, next).map((post, index) => {
                    return (
                      <Card className="invitation-card-receivedList1">
                        <CardContent>
                          <Grid container>
                            <Grid item xs={6} style={{ textAlign: "initial" }}>
                              {post.trip_details.name}
                            </Grid>
                            <Grid
                              item
                              xs={6}
                              // style={{ textAlign: "end", color: "pink" }}
                              className="invitation-card-deletebutton"
                            >
                              <DeleteForeverIcon
                                onClick={() => {
                                  setreceivedDeleteId(post.connection_id);
                                  setreceivedDeleteAgree(false);
                                  setdeleteOpen(true);
                                }}
                              ></DeleteForeverIcon>
                            </Grid>
                          </Grid>
                        </CardContent>
                        <div className="invitation-card-recentcontainer">
                          <div className="invitation-card-receivedList-inner">
                            <img
                              className="invitation-cardrecent-img"
                              variant="top"
                              // src="./Assets/Images/I1.webp"
                              src={
                                post.trip_details.photo == null
                                  ? "./Assets/Images/Favicon 25 WB _ DV.webp"
                                  : post.trip_details.photo
                              }
                              alt="..."
                            />
                          </div>
                        </div>

                        <CardContent
                          style={{
                            fontSize: 12,
                            fontWeight: 600,
                          }}
                        >
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <RouteIcon
                                style={{
                                  color: "#1ec28b",
                                  transform: "rotate(90deg)",
                                  marginTop: 4,
                                }}
                              />
                              <div className="invitation-card-places">
                                {/* ----------------------------------- */}
                                {/* {post.trip_details.flying_from} */}
                                {post.trip_details.flying_from.split(",")[0]}
                                {post.trip_details.transit1 ? (
                                  <span>
                                    <EastIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginBottom: "-7px",
                                      }}
                                    />
                                    {post.trip_details.transit1.split(",")[0]}
                                  </span>
                                ) : null}
                                {post.trip_details.transit2 ? (
                                  <span>
                                    <EastIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginBottom: "-7px",
                                      }}
                                    />
                                    {post.trip_details.transit2.split(",")[0]}
                                  </span>
                                ) : null}
                                {post.trip_details.transit3 ? (
                                  <span>
                                    <EastIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginBottom: "-7px",
                                      }}
                                    />
                                    {post.trip_details.transit3.split(",")[0]}
                                  </span>
                                ) : null}
                                {post.trip_details.transit4 ? (
                                  <span>
                                    <EastIcon
                                      style={{
                                        color: "#1ec38b",
                                        marginBottom: "-7px",
                                      }}
                                    />
                                    {post.trip_details.transit4.split(",")[0]}
                                  </span>
                                ) : null}
                                <EastIcon
                                  style={{
                                    color: "#1ec38b",
                                    marginBottom: "-7px",
                                  }}
                                />
                                {post.trip_details.destination.split(",")[0]}
                                {/* {post.trip_details.destination} */}
                                {/* ----------------------------------- */}
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <CalendarMonthOutlinedIcon
                                style={{
                                  color: "#1ec38b",
                                  marginTop: 4,
                                }}
                              ></CalendarMonthOutlinedIcon>
                              <div className="invitation-card-places">
                                {post.trip_details.booking_status === 2
                                  ? `Date Range : ${post.trip_details.date_range_from} to ${post.trip_details.date_range_to}`
                                  : `Departing on : ${post.trip_details.departing_on}`}
                              </div>{" "}
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <FlightIcon
                                style={{
                                  color: "#1ec38b",
                                  marginTop: 4,
                                }}
                              ></FlightIcon>
                              <div className="invitation-card-places">
                                {post.trip_details.booking_status === 2
                                  ? "yet to book"
                                  : "booked"}
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <LanguageIcon
                                style={{
                                  color: "#1ec38b",
                                  marginTop: 4,
                                }}
                              ></LanguageIcon>{" "}
                              <div className="invitation-card-places">
                                {post.trip_details.prefered_language}
                              </div>
                            </Grid>
                          </Grid>
                          <Grid container spacing={2}>
                            <Grid
                              item
                              xs={12}
                              style={{ display: "flex", columnGap: 16 }}
                            >
                              <ModeOfTravelIcon
                                style={{
                                  color: "#1ec38b",
                                  marginTop: 4,
                                }}
                              ></ModeOfTravelIcon>
                              <div className="invitation-card-places">
                                {post.trip_details.trip_name}
                              </div>
                            </Grid>
                          </Grid>
                          {/* {post.trip_details.category == 1 ? ( */}
                          {/* {firstCon.map((post, index) => {
                        return (
                          <Grid>
                            {fullDetail[`${post.key}`] && (
                              <Grid className="own_detail_data">
                                <Typography className="font5_label">
                                  {post.label}
                                </Typography>
                                <Typography className="font5">:</Typography>
                                <Typography className="font5">
                                  {fullDetail[`${post.key}`]}
                                </Typography>
                              </Grid>
                            )}
                          </Grid>
                        );
                      })} */}
                          {/* ) : null} */}

                          {post.trip_details.category == 1 ? (
                            <Grid container spacing={2}>
                              <Grid
                                item
                                xs={12}
                                style={{ display: "flex", columnGap: 16 }}
                              >
                                <AttachMoneyIcon
                                  style={{ color: "#1ec38b", marginTop: 4 }}
                                ></AttachMoneyIcon>
                                <div className="invitation-card-places">
                                  {/* {post.trip_details.tip_expected} */}
                                  {/^([^0-9]*)$/.test(
                                    post.trip_details.tip_expected
                                  )
                                    ? post.trip_details.tip_expected
                                    : `${post.trip_details.tip_expected} USD equiv`}

                                  {/* if(item[0]==="tip_expected"){
            if(/^([^0-9]*)$/.test(item[1])){
                formatted_full["tip_expected"]=item[1]
            }
            else{
                formatted_full["tip_expected"]=`${item[1]} USD equiv`
            }

        } */}

                                  {/* {post.trip_details === 'tip_expected' ? (
            if(/^([^0-9]*)$/.test(item[1])){
                ["tip_expected"]=item[1]
            }
      ) :  else{
        ["tip_expected"]=`${item[1]} USD equiv`
     }} */}
                                </div>
                              </Grid>
                            </Grid>
                          ) : null}
                          {/* </Grid> */}
                        </CardContent>
                        <Button
                          className="invitation-accept-btn"
                          onClick={() => handleClick(post.trip_details)}
                        >
                          Details
                        </Button>
                        <Modal id={id} open={open} closeIcon={closeIcon}>
                          <ConnectPeoplePopup
                            onChildClick={handleClose}
                            connectUserData={dataToPopup}
                            userdetails={profiledata}
                            page="invitation"
                          />
                        </Modal>
                        <Button
                          className="invitation-cancel-btn"
                          // onClick={() => acceptinvitation(post.connection_id)}
                          onClick={() => {
                            setreceivedacceptId(post.connection_id);
                            setreceivedacceptAgree(false);
                            setacceptOpen(true);
                          }}
                        >
                          Accept
                        </Button>
                      </Card>
                    );
                  })}
                  <div
                    style={{
                      margin: "auto",
                      justifyContent: "center",
                      display: "flex",
                      marginTop: 45,
                    }}
                  >
                    {receivedTriperror}
                  </div>
                </Grid>
              </Grid>
              {/* ================delete popup==================== */}
              <Container>
                <Dialog
                  open={deleteopen}
                  onClose={handledeleteClose}
                  // aria-labelledby="alert-dialog-title"
                  // aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      minHeight: "40vh",
                      minWidth: "35vw",
                      boxShadow: "none",
                      border: "2px solid rgb(128 213 184 / 100%)",
                      strokeWidth: "1px",
                      stroke: "rgb(128 213 184 )",
                      borderRadius: 20,
                    },
                  }}
                  BackdropProps={{
                    style: {
                      backdrop: "blur(2px)",
                    },
                  }}
                >
                  <div>
                    <DialogTitle id="alert-dialog-title">
                      <div className="invitation-upcoming-delete-dalog">
                        <Icon
                          className="invitation-deleteConfirmationIcon"
                          icon="fluent:delete-12-filled"
                        />
                        Delete
                      </div>
                      <hr
                        style={
                          {
                            // height: "1px",
                            // borderWidth: 0,
                            // color: "#1ec28b",
                            // backgroundColor: "#1ec28b",
                            // marginLeft: "50px",
                            // marginRight: "50px",
                          }
                        }
                        className="invitation-Hrline"
                      />
                    </DialogTitle>
                    <DialogContent className="invitation-upcoming-delete-dalog">
                      <h2 className="invitation-areUSure"> Are you sure?</h2>
                    </DialogContent>
                    <DialogActions className="invitation-buttonsContainer">
                      <Button
                        id="btn"
                        className="invitation-yesButton"
                        onClick={() => {
                          deleteinvitation();
                        }}
                      >
                        Yes
                      </Button>
                      <Button
                        className="invitation-noButton"
                        onClick={handledeleteClose}
                      >
                        No
                      </Button>
                    </DialogActions>
                  </div>
                </Dialog>
              </Container>

              {/* =====================accept popup ===================== */}
              <Container>
                <Dialog
                  open={acceptopen}
                  onClose={handleacceptClose}
                  // aria-labelledby="alert-dialog-title"
                  // aria-describedby="alert-dialog-description"
                  PaperProps={{
                    style: {
                      minHeight: "40vh",
                      minWidth: "35vw",
                      boxShadow: "none",
                      border: "2px solid rgb(128 213 184 / 100%)",
                      // strokeWidth: "1px",
                      // stroke: "rgb(128 213 184 )",
                      borderRadius: 20,
                    },
                  }}
                  BackdropProps={{
                    style: {
                      backdrop: "blur(2px)",
                    },
                  }}
                >
                  <div>
                    <DialogTitle id="alert-dialog-title">
                      {/* <div className="invitation-upcoming-delete-dalog">
                        <Icon
                          className="invitation-deleteConfirmationIcon"
                          icon="fluent:delete-12-filled"
                        />
                        Accept
                      </div> */}
                      {/* <hr
                        
                        className="invitation-Hrline"
                      /> */}
                    </DialogTitle>
                    <DialogContent className="invitationaccept-upcoming-delete-dalog">
                      <div className="invitationaccept-areUSure">
                        By accepting, your contact details will be visible to
                        the other Invited Traveler and vice versa for further
                        communication, and while travelling together take
                        adequate caution like any other travel
                      </div>
                    </DialogContent>
                    <DialogActions className="invitationaccept-buttonsContainer">
                      <Button
                        id="btn"
                        className="invitationaccept-yesButton"
                        onClick={() => {
                          acceptinvitation();
                        }}
                        // onClick={() => acceptinvitation(post.connection_id)}
                      >
                        Ok
                      </Button>
                      <Button
                        className="invitationaccept-noButton"
                        onClick={handleacceptClose}
                      >
                        Cancel
                      </Button>
                    </DialogActions>
                  </div>
                </Dialog>
              </Container>
              {next < recievedinvite.length && (
                <div
                  className="invitation-load-more-btn"
                  onClick={handleMoreImage}
                >
                  Load more
                  <span>
                    <ArrowCircleDownSharpIcon
                      style={{ marginTop: -1, fontSize: 20 }}
                    />
                  </span>
                </div>
              )}
            </Box>
          </div>
          <div>
            <EditProfile></EditProfile>
          </div>
        </div>
      </div>
    </>
  );
  // } else {
  //   return <Navigate replace to={"/edit-profile"}></Navigate>;
  // }
};

export default InvitationRecievedList;
