import React from "react";
import "../../CSS/invitationconncetedList.css";
import {
  Box,
  Button,
  Container,
  Grid,
  IconButton,
  InputBase,
  Modal,
  Popover,
  Typography,
} from "@mui/material";
import SidebarLink from "../SidebarLink";
import EditProfile from "../EditProfile";
import DashboardHeader from "../DashboardHeader";
import ArrowCircleDownSharpIcon from "@mui/icons-material/ArrowCircleDownSharp";
import SearchIcon from "@mui/icons-material/Search";
import { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { useSelector } from "react-redux";
import ConnectPeoplePopup from "../../Pages/ConnectPeople/ConnectPeoplePopup";
import { Navigate, useNavigate } from "react-router-dom";

function InvitationConnectedList() {
  const { isLoggedIn, user } = useSelector((state) => state.auth);
  console.log("user_id", user.user_id);
  const [connectinvite, setconnectinvite] = useState([]);
  const [userdetails, setuserdetails] = useState();
  const [closeIcon, setCloseicon] = useState(false);
  const [dataToPopup, setDataToPopup] = useState();
  const [profiledata, setprofiledata] = useState({});
  const [connectedTriperror, setconnectedTriperror] = useState();


  useEffect(() => {

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_UAT_API;
    }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");
	
	
    
    axios
      .get(
        `${domain}/connected-list/${user.user_id}/
        `
      )
      .then((response) => {
        console.log(response, "connectInvite trip response");
        console.log(response.data[0].user_details, "user details");

        if (response.data) {
          console.log(response.data, "connectInvite trip response data");
          setconnectinvite(response.data);
          setuserdetails(response.data.user_details);
        }
      })
      .catch((error) => {
        // Error
        // console.log("status : ",error.response.status);
        // console.log("data : ",error.response.data);
        if (error.response.status === 400) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          // console.log("data : ",error.response.data);
          console.log("data : ", error.response.data.message);
          setconnectedTriperror(error.response.data.message)
          // console.log("status : ",error.response.status);
          // console.log("headers : ",error.response.headers);
        }
      });
  }, []);

  const handleClick = (item, data) => {
    setDataToPopup(item);
    setCloseicon(true);
    setprofiledata(data);
  };
  const handleClose = () => {
    {
      setCloseicon(false);
    }
  };
  const open = Boolean(closeIcon);
  const id = open ? "simple-popover" : undefined;

  // ------------------------- cards for load more result  --------------------------------------------
  const imagePerRow = 4;
  const [next, setNext] = useState(imagePerRow);
  const handleMoreImage = () => {
    setNext(next + imagePerRow);
  };


  const navigate = useNavigate();
useEffect(
   ()=>{if(user.update_profile_details!=="False"){
   return navigate('/edit-profile',{state:'true'})
  }}
,[]
)



  // if(user.update_profile_details=="False"){
  return (
    <>
      <DashboardHeader></DashboardHeader>
      <div className="connect-invitation-myprofile-main">
        <div className="connect-invitation-sidebarlink">
          <Box
            sx={{
              width: 300,
              height: 600,
              backgroundColor: "",
              "&:hover": {
                backgroundColor: "aliceblue",
              },
            }}
          >
            <div>
              {/* <div>Dashboard</div>
              <div>Setting</div>
              <div>Logout</div> */}
              <SidebarLink></SidebarLink>
            </div>
          </Box>
        </div>
        <div className="connect-invitation-formob">
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Box className="connectedListBox">
                  <p className="connectedListText">Connected List</p>
                  <div>
                    <InputBase
                      sx={{ p: "10px" }}
                      className="connectedListsearchbtn"
                      placeholder="Search"
                    />
                    <IconButton
                      className="connectedListsearchbtnicon"
                      type="submit"
                      sx={{ p: "10px" }}
                      aria-label="search"
                    >
                      <SearchIcon />
                    </IconButton>
                  </div>

                  <Grid
                    container
                    spacing={2}
                    className="connectedListmessageContainer-main"
                  >
                    <Grid item xs={12} className="connectedListmessageGrid">
                      {connectinvite.slice(0, next).map((post, index) => {
                        return (
                          <div className="connectedList-messageMainbox">
                            <Grid container spacing={2}>
                              <Grid item lg={3} xl={3} md={3} sm={4} xs={4}>
                                <div className="connectedListmessagecontainer">
                                  <div className="connectedListmessageinner">
                                    <img
                                      className="connectedListmessageImg"
                                      variant="top"
                                      // src="./Assets/Images/I1.webp"
                                      src={
                                        post.trip_details.photo == null
                                          ? "./Assets/Images/Favicon 25 WB _ DV.webp"
                                          : post.trip_details.photo
                                      }
                                      alt="..."
                                    />
                                  </div>
                                </div>
                              </Grid>

                              <Grid item lg={5} xl={5} md={5} sm={4} xs={4}>
                                <Typography className="connectedListmessageThara">
                                  {post.trip_details.name}
                                </Typography>
                                <Typography className="connectedListTharaText">
                                  {post.trip_details.destination.split(",")[0]}
                                </Typography>
                              </Grid>
                              <Grid item lg={4} xl={4} md={4} sm={4} xs={4}>
                                <Button
                                  className="connectedListProfile"
                                  onClick={() =>
                                    handleClick(
                                      post.trip_details,
                                      post.user_details
                                    )
                                  }
                                >
                                  PROFILE
                                </Button>
                              </Grid>
                            </Grid>
                            <Modal id={id} open={open} closeIcon={closeIcon}>
                              <ConnectPeoplePopup
                                onChildClick={handleClose}
                                connectUserData={dataToPopup}
                                userdetails={profiledata}
                                page="invitationconnected"
                              />
                            </Modal>
                          </div>
                        );
                      })}
                          <div
                      style={{
                        margin: "auto",
                        justifyContent: "center",
                        display: "flex",
                        marginTop: 45,
                      }}
                    >
                      {connectedTriperror}
                    </div>
                    </Grid>
                  </Grid>

                  {next < connectinvite.length && (
                  <div
                    className="connectedList-load-more-btn"
                    onClick={handleMoreImage}
                  >
                    Load more 
                    <span>
                      <ArrowCircleDownSharpIcon
                        style={{ marginTop: -1, fontSize: 20 }}
                      />
                    </span>
                  </div>
                  )}
                </Box>
              </Grid>
            </Grid>
          </div>
          <div>
            <EditProfile></EditProfile>
          </div>
        </div>
      </div>
    </>
  );
// }
// else{

//   return <Navigate replace to={"/edit-profile"}></Navigate>
// }
}

export default InvitationConnectedList;