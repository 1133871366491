import {
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React from "react";

import "../../CSS/settings.css";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import EditProfile from "../EditProfile";
import SidebarLink from "../SidebarLink";
import DashboardHeader from "../DashboardHeader";
import {useFormik} from "formik";
import * as Yup from "yup";
import axios from "axios";
import {useSelector, useDispatch} from "react-redux";
import {useState} from "react";
import Swal from "sweetalert2";
import {logout} from "../../actions/auth";
import {Icon} from "@iconify/react";
import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

function Settings() {
  const {isLoggedIn, user} = useSelector(state => state.auth);

  // console.log("user_id", user.user_id);
  const dispatch = useDispatch();
  const logOut = () => {
    dispatch(logout());
  };
  const [passwordMessage, setPasswordMessage] = React.useState(null);
  const [passwordError, setPasswordError] = React.useState(null);
  const [isVisible, setIsVisible] = useState(true);

  // useEffect(() => {
  //   const timeout = setTimeout(() => {
  //     setIsVisible(false);
  //   }, 20000);

  //   return () => clearTimeout(timeout);
  // }, []);

  const validationSchema = Yup.object({
    OldPassword: Yup.string().required("Please enter Old Password"),
    NewPassword: Yup.string()
      // .notOneOf([Yup.ref("OldPassword"), null], "Old and New Password are same")
      .required("Please enter New Password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    confirmNewpassword: Yup.string()
      .oneOf([Yup.ref("NewPassword"), null], "Passwords must match")
      .required("Passwords must match"),
  });

  const formik = useFormik({
    initialValues: {
      OldPassword: "",
      NewPassword: "",
      confirmNewpassword: "",
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      setIsVisible(true);

      // alert(values);
      // const beginDate = moment(values.FromDate).format('YYYY-MM-DD')
      // console.log(beginDate);
      //   const mydate=new Date(values.FromDate)
      //   const thisdate=mydate.getDate()+'-'+(mydate.getMonth()+1)+'-'+mydate.getFullYear()
      //   console.log(thisdate)
      console.log(values);

      // console.log(JSON.stringify(values))
      // dispatch(login(values.email, values.password))

      console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
      let domain = "";
      console.log(process.env, "domain env", window.location.hostname);
      if (window.location.hostname.trim() === "localhost") {
        domain = process.env.REACT_APP_LOCAL_API;
      } else if (window.location.hostname.trim() === "54.185.6.32") {
        domain = process.env.REACT_APP_UAT_API;
      } else if (
        window.location.hostname.trim() === "uat.desitravelcompanion.com"
      ) {
        domain = process.env.REACT_APP_PROD_UAT_API;
      } else if (
        window.location.hostname.trim() === "desitravelcompanion.com"
      ) {
        domain = process.env.REACT_APP_PROD_API;
      } else if (
        window.location.hostname.trim() === "www.desitravelcompanion.com"
      ) {
        domain = process.env.REACT_APP_PROD_API;
      }
      console.log(domain, "domain env end");

      axios
        .put(`${domain}/change-password/${user.user_id}/`, {
          old_password: values.OldPassword,
          new_password: values.NewPassword,
        })
        .then(response => {
          console.log(response, "change password response");

          if (response.data) {
            formik.resetForm();
            console.log(
              response.data.message,
              "change password response message"
            );
            setPasswordMessage(response.data.message);
            setPasswordError(null);
          }
        })
        .catch(error => {
          // Error
          if (error.response.status) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            console.log(error.response.data, "error message");
            //  console.log(error.response.status);
            //  console.log(error.response.headers);
            setPasswordError(error.response.data.message);
            setPasswordMessage(null);
          }
        });
      setTimeout(() => {
        setIsVisible(false);
      }, 6000);
    },
  });

  const [settingsdeleteopen, setsettingsdeleteOpen] = React.useState(false);
  const [recievedinvite, setrecievedinvite] = useState([]);

  const handleClickOpen = () => {
    setsettingsdeleteOpen(true);
  };

  const handledeleteClose = () => {
    setsettingsdeleteOpen(false);
  };
  const [settingdeleteAgree, setsettingDeleteAgree] = useState(false);
  const [settingdeleteId, setsettingDeleteId] = useState("");

  const settingdeleteinvitation = () => {
    //  setOpen(false)
    console.log(settingdeleteId, "card id");
    if (settingdeleteId !== "") {
      console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
      let domain = "";
      console.log(process.env, "domain env", window.location.hostname);
      if (window.location.hostname.trim() === "localhost") {
        domain = process.env.REACT_APP_LOCAL_API;
      } else if (window.location.hostname.trim() === "54.185.6.32") {
        domain = process.env.REACT_APP_UAT_API;
      } else if (
        window.location.hostname.trim() === "uat.desitravelcompanion.com"
      ) {
        domain = process.env.REACT_APP_PROD_UAT_API;
      } else if (
        window.location.hostname.trim() === "desitravelcompanion.com"
      ) {
        domain = process.env.REACT_APP_PROD_API;
      } else if (
        window.location.hostname.trim() === "www.desitravelcompanion.com"
      ) {
        domain = process.env.REACT_APP_PROD_API;
      }
      console.log(domain, "domain env end");

      axios
        .delete(`${domain}/delete/user/${settingdeleteId}/`)
        .then(response => {
          console.log(response, "update delete response");

          const newList = recievedinvite.filter(user => {
            return user.user_id !== settingdeleteId;
          });

          console.log(newList, "FILTERED CARD...");
          setrecievedinvite(newList);

          if (response.data) {
            setsettingsdeleteOpen(false);

            console.log(response.data, " response delete profile");
            setsettingDeleteAgree(true);
            logOut();
            Swal.fire({
              // position: 'center',
              customClass: {
                popup: "my-swal",
              },
              icon: "success",
              title: "successfully Deleted",
              showCloseButton: true,
              showConfirmButton: false,
            });
          }
        })
        .catch(error => {
          // Error
          if (error.response.status === 400) {
            console.log(error.response.data, "error");
            console.log(error.response.status);
            // console.log(error.response.headers);
            // setResponseMessage(null);
          }
        });
    }
  };

  const navigate = useNavigate();
  useEffect(() => {
    if (user.update_profile_details !== "False") {
      return navigate("/edit-profile", {state: "true"});
    }
  }, []);

  if (user.update_profile_details == "False") {
    return (
      <>
        <DashboardHeader></DashboardHeader>
        <Container>
          <div className="dashboard-main">
            <div className="siderbar-mobile-version">
              <Box
                sx={{
                  width: 300,
                  height: 600,
                  backgroundColor: "",
                  "&:hover": {
                    backgroundColor: "aliceblue",
                  },
                }}
              >
                <SidebarLink></SidebarLink>
              </Box>
            </div>
            {/* <Grid container spacing={2}></Grid> */}
            <div className="settings-mobile-version">
              <div className="settings-outside-container">
                <p className="setting-heading">Settings</p>

                <form onSubmit={formik.handleSubmit}>
                  <div className="setting-inside-container">
                    <b className="user-setting-changepassword-heading">
                      Change Password
                    </b>
                    <div className="user-setting-inside-grid">
                      {/* <b className="setting-changepassword-heading">
                      Change Password
                    </b> */}
                      <Grid container spacing={2}>
                        <Grid item lg={12} xs={12}>
                          <div>
                            <label className="setting-labels">
                              Enter Old Password
                            </label>
                          </div>
                          <TextField
                            type="password"
                            id="standard-basic"
                            variant="standard"
                            className="setting-textfield"
                            name="OldPassword"
                            onBlur={formik.handleBlur}
                            touched={formik.touched}
                            value={formik.values.OldPassword}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.OldPassword &&
                              Boolean(formik.errors.OldPassword)
                            }
                            helperText={
                              formik.touched.OldPassword &&
                              formik.errors.OldPassword
                            }
                          />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <div>
                            <label className="setting-labels">
                              Enter New Password
                            </label>
                          </div>
                          <TextField
                            type="password"
                            variant="standard"
                            className="setting-textfield"
                            name="NewPassword"
                            onBlur={formik.handleBlur}
                            touched={formik.touched}
                            value={formik.values.NewPassword}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.NewPassword &&
                              Boolean(formik.errors.NewPassword)
                            }
                            helperText={
                              formik.touched.NewPassword &&
                              formik.errors.NewPassword
                            }
                          />
                        </Grid>
                        <Grid item lg={12} xs={12}>
                          <div>
                            <label className="setting-labels">
                              Confirm New Password
                            </label>
                          </div>
                          <TextField
                            type="password"
                            variant="standard"
                            className="setting-textfield"
                            name="confirmNewpassword"
                            onBlur={formik.handleBlur}
                            touched={formik.touched}
                            value={formik.values.confirmNewpassword}
                            onChange={formik.handleChange}
                            error={
                              formik.touched.confirmNewpassword &&
                              Boolean(formik.errors.confirmNewpassword)
                            }
                            helperText={
                              formik.touched.confirmNewpassword &&
                              formik.errors.confirmNewpassword
                            }
                          />
                        </Grid>

                        {isVisible && (
                          <span
                            style={{
                              color: "green",
                              marginLeft: "17px",
                              marginTop: "8px",
                              fontSize: "14px",
                            }}
                          >
                            {passwordMessage}
                          </span>
                        )}

                        <span
                          style={{
                            color: "red",
                            marginLeft: "12px",
                            marginTop: "8px",
                            fontSize: "14px",
                          }}
                        >
                          {passwordError}
                        </span>

                        <Grid
                          item
                          lg={12}
                          xs={12}
                          className="save-btn-container"
                        >
                          <button
                            name="Submit"
                            className="setting-save-btn"
                            type="submit"
                            autoFocus
                          >
                            SAVE
                          </button>
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                </form>
              </div>

              <div>
                <EditProfile></EditProfile>
              </div>
            </div>
          </div>
          <div className="settings-delete-btn-container">
            <b className="setting-delete-ac-btn">Delete Account</b>
            <button
              type="submit"
              className="user-setting-delete-btn"
              style={{textTransform: "capitalize"}}
              onClick={post => {
                setsettingDeleteId(user.user_id);
                setsettingDeleteAgree(false);
                setsettingsdeleteOpen(true);
                // logOut();
              }}
            >
              DELETE
            </button>
          </div>
          {/* <div className="setting-delete-main">
          <b className="setting-delete-ac-btn">Delete Account</b>
          <button
            type="submit"
            className="setting-delete-btn"
            onClick={(post) => {
              setsettingDeleteId(user.user_id);
              setsettingDeleteAgree(false);
              setsettingsdeleteOpen(true);
              // logOut();
            }}
          >
            Delete
          </button>
        </div> */}
          <Container>
            <Dialog
              open={settingsdeleteopen}
              onClose={handledeleteClose}
              // aria-labelledby="alert-dialog-title"
              // aria-describedby="alert-dialog-description"
              PaperProps={{
                style: {
                  minHeight: "40vh",
                  minWidth: "35vw",
                  boxShadow: "none",
                  border: "2px solid rgb(128 213 184 / 100%)",
                  strokeWidth: "1px",
                  stroke: "rgb(128 213 184 )",
                  borderRadius: 20,
                },
              }}
              BackdropProps={{
                style: {
                  backdrop: "blur(2px)",
                },
              }}
            >
              <div>
                <DialogTitle id="alert-dialog-title">
                  <div className="invitation-upcoming-delete-dalog">
                    <Icon
                      className="invitation-deleteConfirmationIcon"
                      icon="fluent:delete-12-filled"
                      style={{stroke: "none"}}
                    />
                    Delete
                  </div>
                  <hr
                    style={
                      {
                        // height: "1px",
                        // borderWidth: 0,
                        // color: "#1ec28b",
                        // backgroundColor: "#1ec28b",
                        // marginLeft: "50px",
                        // marginRight: "50px",
                      }
                    }
                    className="invitation-Hrline"
                  />
                </DialogTitle>
                <DialogContent className="invitation-upcoming-delete-dalog">
                  <h2 className="invitation-areUSure"> Are you sure?</h2>
                </DialogContent>
                <DialogActions className="invitation-buttonsContainer">
                  <Button
                    id="btn"
                    className="invitation-yesButton"
                    onClick={() => {
                      settingdeleteinvitation();
                    }}
                  >
                    Yes
                  </Button>
                  <Button
                    className="invitation-noButton"
                    onClick={handledeleteClose}
                  >
                    No
                  </Button>
                </DialogActions>
              </div>
            </Dialog>
          </Container>
        </Container>
      </>
    );
  }
  // else{

  //   return (()=>navigate('/edit-profile',{state:'true'}))
  // }
}

export default Settings;