import { Box, Container, Grid } from '@mui/material'
import React from 'react'
import DashboardHeader from '../Dashboard/DashboardHeader'
import NavBar from '../Components/Header/Header'

export default function TravelLinkPage() {
  return (
    <>
 {/* <div>
          <NavBar />
        </div> */}
        <Container>
         {/* <Grid container className="ads-mainPage"> */}
            <Grid xs={12} sm={12}>
            {/* <img src='./Assets/Images/Des-link.jpg' style={{width: "100%"}}  alt="Desi-link"></img> */}
           <Grid container>
               <Box
                width="100%"
                // style={{width: "100%"}}
                  component="img"
                  // className="studentpro-itTechImage"
                  sx={{
                    content: {
                      xs:`url('./Assets/Images/Business card_MVimage.png')`,
                      md:`url('./Assets/Images/Des-link.jpg')`,
                    },
                  }}
                  alt="Logo"
                />
             </Grid>
            {/* </Grid>  */}
            </Grid>
            </Container>
    </>
  )
}
