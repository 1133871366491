import { Container, Grid, Modal } from "@mui/material";
import React from "react";
import "../../CSS/footerads.css";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Link } from "react-router-dom";
import { Icon } from "@iconify/react";
import { fontWeight } from "@mui/system";
import { useEffect } from "react";
import axios from "axios";
import { useState } from "react";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #ffffff",
  height: "200px",
  width: "30%",
};

const FooterAds = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [ad1_image, setAd1_image] = useState();
  const [ad2_image, setAd2_image] = useState();
  const [ad3_image, setAd3_image] = useState();
  const [ad1_image_MV, setAd1_image_MV] = useState();
  const [ad2_image_MV, setAd2_image_MV] = useState();
  const [ad3_image_MV, setAd3_image_MV] = useState();
  const [Ad1_image_link, setAd1_image_link] = useState("");
  const [Ad2_image_link, setAd2_image_link] = useState("");
  const [Ad3_image_link, setAd3_image_link] = useState("");

  useEffect(() => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    // const responseData = axios
    //   .get(
    //     `${domain}/user-location/`
    //   )
    //   .then((response) => {
    //     console.log(response, "response ad");
    //     // setLocation(response.data)
    //   // setValue("city",response.data.city );
    //   // setValue("region",response.data.region );
    //   // setValue("country",response.data.country );
    //   // setAd1_image(response.data.footer_ad1)
    //   // setAd2_image(response.data.footer_ad2)
    //   // setAd1_image_link(response.data.footer_ad1_link)
    //   // setAd2_image_link(response.data.footer_ad2_link)
    //     // return response.data;
    //   })
    //   .catch((error) => {
    //     console.log(error);
    //   });
    // setcityregcountry(responseData.cityregcountry);
    // setLoading(false);

    // if (loading) {
    //   load();
    // }

    const responseData1 = axios
      .get(`${domain}/admin/ads-location/`)
      .then((response) => {
        console.log(response, "response ad1");
        let i;
        if (response) {
          for (i = 0; i < 7; i++) {
            if (response.data.response_data[i].ad_placement === "footer_ad1") {
              console.log(
                response.data.response_data[i].ad_placement,
                "addd 1"
              );
              setAd1_image(response.data.response_data[i].desktop_ad);
              setAd1_image_MV(response.data.response_data[i].mobile_ad);
              setAd1_image_link(response.data.response_data[i].ad_link);
            }
            if (response.data.response_data[i].ad_placement === "footer_ad2") {
              console.log(
                response.data.response_data[i].ad_placement,
                "addd 2"
              );
              setAd2_image(response.data.response_data[i].desktop_ad);
              setAd2_image_MV(response.data.response_data[i].mobile_ad);
              setAd2_image_link(response.data.response_data[i].ad_link);
            }
            if (response.data.response_data[i].ad_placement === "footer_ad3") {
              console.log(
                response.data.response_data[i].ad_placement,
                "addd 3"
              );
              setAd3_image(response.data.response_data[i].desktop_ad);
              setAd3_image_MV(response.data.response_data[i].mobile_ad);
              setAd3_image_link(response.data.response_data[i].ad_link);
            }
          }
        }
        // setAd1_image(response.data.response_data[0].desktop_ad)
        // setAd2_image(response.data.response_data[1].desktop_ad)
        // setAd1_image_link(response.data.response_data[0].ad_link)
        // setAd2_image_link(response.data.response_data[1].ad_link)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <div>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="footer1-ads-image-desktopversion">
            <div className="footer-ads-img-one-container ">
              <a href={Ad1_image_link} target="_blank">
                {/* <a href={Ad1_image_link?Ad1_image_link:"/ads"} target={"_blank"} > */}
                {/* <a onClick={() => {window.location.href("www.google.com")}} target={"_blank"}> */}
                <img
                  // src="../../Assets/Images/Ads without button (TC).webp"
                  src={
                    ad1_image != "default"
                      ? ad1_image
                      : "../../Assets/Images/Poster 2 _ MV.webp"
                  }
                  alt="ad's"
                  className="footer-ads-img-one"
                />
              </a>
              {/* { ad1_image != "default"?"":   
         <Link to={"/ads"} style={{textDecoration:"none"}}> <button  class="footer-ads-img-one-btn">
              Place your AD with us
              </button></Link>
              } */}
              {ad1_image != "default" ? (
                ""
              ) : (
                <button onClick={handleOpen} class="footer-ads-img-three-btn">
                  More Info
                </button>
              )}
            </div>
         
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="footer1-ads-image-Mobileversion">
           
            <div className="footer-ads-img-one-container ">
              <a href={Ad1_image_link} target="_blank">
                {/* <a href={Ad1_image_link?Ad1_image_link:"/ads"} target={"_blank"} > */}
                {/* <a onClick={() => {window.location.href("www.google.com")}} target={"_blank"}> */}
                <img
                  // src="../../Assets/Images/Ads without button (TC).webp"
                  src={
                    ad1_image_MV != "default"
                      ? ad1_image_MV
                      : "../../Assets/Images/Poster 2 _ MV.webp"
                  }
                  alt="ad's"
                  className="footer-ads-img-one"
                />
              </a>
              {/* { ad1_image != "default"?"":   
         <Link to={"/ads"} style={{textDecoration:"none"}}> <button  class="footer-ads-img-one-btn">
              Place your AD with us
              </button></Link>
              } */}
              {ad1_image_MV != "default" ? (
                ""
              ) : (
                <button onClick={handleOpen} class="footer-ads-img-three-btn">
                  More Info
                </button>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="footer2-ads-image-desktopversion">
            <div className="footer-ads-img-two-container">
              <a href={Ad2_image_link} target="_blank">
                {/* <Link to={Ad2_image_link?Ad2_image_link:""} target={"_blank"}> */}
                <img
                  // src="../../Assets/Images/Poster 2_ Without button.png"
                  src={
                    ad2_image != "default"
                      ? ad2_image
                      : "../../Assets/Images/Poster 2_ Without button.png"
                  }
                  alt="ad's"
                  className="footer-ads-img-two"
                />
              </a>
              {/* </Link> */}
              {ad2_image != "default" ? (
                ""
              ) : (
                <button onClick={handleOpen} class="footer-ads-img-two-btn">
                  More Info
                </button>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="footer2-ads-image-Mobileversion">
            <div className="footer-ads-img-two-container">
              <a href={Ad2_image_link} target="_blank">
                {/* <Link to={Ad2_image_link?Ad2_image_link:""} target={"_blank"}> */}
                <img
                  // src="../../Assets/Images/Poster 2_ Without button.png"
                  src={
                    ad2_image_MV != "default"
                      ? ad2_image_MV
                      : "../../Assets/Images/Poster 2_ Without button.png"
                  }
                  alt="ad's"
                  className="footer-ads-img-two"
                />
              </a>
              {/* </Link> */}
              {ad2_image_MV != "default" ? (
                ""
              ) : (
                <button onClick={handleOpen} class="footer-ads-img-two-btn">
                  More Info
                </button>
              )}
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="footer3-ads-image-desktopversion">
            <div className="footer-ads-img-three-container">
              <a href={Ad3_image_link} target="_blank">
                <img
                  // src="../../Assets/Images/Ads without button (TC).webp"

                  src={
                    ad3_image != "default"
                      ? ad3_image
                      : "../../Assets/Images/Ads without button (TC).webp"
                  }
                  alt="ad's"
                  className="footer-ads-img-three"
                />
              </a>
              {/* <button onClick={handleOpen} class="footer-ads-img-three-btn">
                More Info
              </button> */}
              {ad3_image != "default" ? (
                ""
              ) : (
                <Link to={"/ads"} style={{ textDecoration: "none" }}>
                  {" "}
                  <button class="footer-ads-img-one-btn">
                    Place your AD with us
                  </button>
                </Link>
              )}
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="footer-ads-box">
                <a
                  href="./student-community"
                  style={{ textDecoration: "none" }}
                >
                  <button class="footer-ads-btn">
                    <Icon icon="mdi:account-student" />
                    &nbsp;Student Community
                  </button>
                </a>
                {/* <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 25,
                    color: "#1ec28b",
                    fontWeight: 600,
                  }}
                >
                  OR
                </span> */}
                <div className="footer-ads-horizontal-line">
                  <h1 className="footer-ads-horizontal-text">
                    <span>OR</span>
                  </h1>
                </div>
                <a href="./desi-community" style={{ textDecoration: "none" }}>
                  <button
                    class="footer-ads-btn"
                    style={{ justifyContent: "center" }}
                  >
                    <Icon icon="ic:outline-devices-other" /> &nbsp;Other's
                  </button>
                </a>
              </Box>
            </Modal>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} xl={4} className="footer3-ads-image-Mobileversion">
            <div className="footer-ads-img-three-container">
              <a href={Ad3_image_link} target="_blank">
                <img
                  // src="../../Assets/Images/Ads without button (TC).webp"

                  src={
                    ad3_image_MV != "default"
                      ? ad3_image_MV
                      : "../../Assets/Images/Ads without button (TC).webp"
                  }
                  alt="ad's"
                  className="footer-ads-img-three"
                />
              </a>
              {/* <button onClick={handleOpen} class="footer-ads-img-three-btn">
                More Info
              </button> */}
              {ad3_image != "default" ? (
                ""
              ) : (
                <Link to={"/ads"} style={{ textDecoration: "none" }}>
                  {" "}
                  <button class="footer-ads-img-one-btn">
                    Place your AD with us
                  </button>
                </Link>
              )}
            </div>
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style} className="footer-ads-box">
                <a
                  href="./student-community"
                  style={{ textDecoration: "none" }}
                >
                  <button class="footer-ads-btn">
                    <Icon icon="mdi:account-student" />
                    &nbsp;Student Community
                  </button>
                </a>
                {/* <span
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    marginTop: 25,
                    color: "#1ec28b",
                    fontWeight: 600,
                  }}
                >
                  OR
                </span> */}
                <div className="footer-ads-horizontal-line">
                  <h1 className="footer-ads-horizontal-text">
                    <span>OR</span>
                  </h1>
                </div>
                <a href="./desi-community" style={{ textDecoration: "none" }}>
                  <button
                    class="footer-ads-btn"
                    style={{ justifyContent: "center" }}
                  >
                    <Icon icon="ic:outline-devices-other" /> &nbsp;Other's
                  </button>
                </a>
              </Box>
            </Modal>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default FooterAds;
