import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Select,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import "../CSS/searchbar.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import ClearIcon from '@mui/icons-material/Clear';
import Tab from "@mui/material/Tab";
import FlightTakeoffIcon from "@mui/icons-material/FlightTakeoff";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
// import CountryJson from "../../src/json/Airports.json";
import { createFilterOptions } from "@mui/material/Autocomplete";
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import moment from "moment/moment";
import axios from "axios";
import { Icon } from "@iconify/react";
// import { parse, isDate } from "date-fns";
// import differenceInYears from 'date-fns/differenceInYears';
import searchApi from "../SearchReqody/searchApi";
import SearchReqbodyGen from "../SearchReqody/SearchReqbodyGen";
import { useNavigate,useLocation } from "react-router-dom";
import InputAdornment from '@mui/material/InputAdornment';
// import IconButton from '@mui/material/IconButton';
import EventIcon from '@mui/icons-material/Event';
import { getAirlines,getAirports,getLanguages} from "../Pages/CreateTripNew/OptionsUtility/OptionsUtil";





const color = "#1ec28b";
const SearchBar = ({mySearchData, mySearchDataError,page,filledValue,setSearching}) => {



  const [airlineValue,setAirlineValue]=useState("")
  const [airlineResponse,setairlineResponse]=useState([])


  const [airportValue,setAirportValue]=useState("")
  const [airportResponse,setPortresponse]=useState([])


  const navigate=useNavigate()


    //------------------- Date Range From---------------------------//
    const [openFrom, setOpenFrom] = React.useState(false);
    const handleOpenFrom = () => setOpenFrom(true);
    const handleCloseFrom = () => setOpenFrom(false);
  //------------------- Date Range From---------------------------//
  
  
  //------------------- Date Range From---------------------------//
  const [openTo, setOpenTo] = React.useState(false);
  const handleOpenTo = () => setOpenTo(true);
  const handleCloseTo = () => setOpenTo(false);
  //------------------- Date Range From---------------------------//

  const [open, setOpen] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [openDestination, setOpenDestination] = useState(false);
  const [inputValueDestination, setInputValueDestination] = useState("");




  // const todays=moment(moment(new Date()).format("DD-MMM-yyyy"))
  var today_2month = moment().add(2, 'months');
  var today_6month = moment().add(6, 'months');




  const today = new Date();
  const validationSchema = Yup.object({
    flyingFrom:Yup.object().required("Flying From is required").nullable(),
  });

  const categoryOptions=[
    {
      label:'Available Travel Companion',
      value:'1'
    },
    {
      label:'Looking for Travel Companion',
      value:'2'
    },
  ]
  

  const formik = useFormik({
    initialValues:filledValue?filledValue: {
      flyingFrom: "",
      Destination: "",
      Category: "",
      FromDate: "",
      ToDate: "",
      // password: '',
    },
    validationSchema: validationSchema,
    enableReinitialize:true,
    onSubmit: (values) => {
      console.log(values,"valuesInSearch");
      if (setSearching) setSearching(true)
      const normalized=SearchReqbodyGen(values)
      console.log(normalized,"normalized")
         const response=searchApi(normalized)
         console.log(response,"responseHittog")
         response.then(data=>{
          if(data.status==="S"){
            const state=normalized;
            if (setSearching) setSearching(false)
          navigate("/companion-search",{state})  
          if(page!=="home")   
               mySearchData(data.data);
              //  mySearchDataError("")
  
          }else{
            // errorInsearch,
            if (setSearching) setSearching(false)
            if(page==="home"){
              const state=normalized;
              navigate("/companion-search",{state}) 
              // if (mySearchDataError) mySearchDataError(data.data.data.message)
             

            }else{
              mySearchDataError(data.data.data.message)
              mySearchData([]);

            }
         
          }
          console.log(data,"data....")
         })
        

        
     
      
    },
  });

  console.log(formik, "form values");
  const handleDisable = (option) => {
    let flag = false;
    if (
      JSON.stringify(option) === JSON.stringify(formik.values.Destination) ||
      JSON.stringify(option) === JSON.stringify(formik.values.flyingFrom)
    ) {
      flag = true;
    }


    return flag;
  };
  // const mycountry = CountryJson;


  useEffect(() => { 
    console.log(getAirlines(airlineValue).then(res=>{
      if(res.data.message)
      setairlineResponse([])
       else   
        setairlineResponse(res.data.response)    
  }),"countryResponseTC")
    }, [airlineValue]);


    useEffect(() => { 
      console.log(getAirports(airportValue).then(res=>{
        if(res.data.message)
           setPortresponse([])
        else   
            setPortresponse(res.data.response)
        // console.log(res,"resinpromiseair")
      }),"countryResponseTC")
      }, [airportValue]);



  return (
    <>
      <Container>
        <form onSubmit={formik.handleSubmit}>
          <div className="Onetab-section">
          <Container>
              <Grid container spacing={2} className="OnetabsubHeading">
                <Grid item xl={2.4} lg={2.4} md={2.4} sm={12} xs={12}>
                  <Autocomplete
                    open={open}
                    onOpen={() => {
                      if (airportValue) {
                        setOpen(true);
                      }
                    }}
                    value={formik.values.flyingFrom?formik.values.flyingFrom:null}
                    onClose={() => setOpen(false)}
                    onInputChange={(e, value, reason) => {
                      setAirportValue(value);

                      if (!value) {
                        setOpen(false);
                        setAirportValue("");
                      }
                    }}
                    // inputValue={airportValue}
                    sx={{
                      "& .MuiAutocomplete-popupIndicator": {
                        transform: "none",
                      },
                    }}
                    className="flyfrom"
                    autoHighlight
                    id="country-select-demo"
                    freeSolo={false}
                    popupIcon={
                      <FlightTakeoffIcon
                        style={{ color: "#1ec28b", marginBottom: "auto" }}
                      ></FlightTakeoffIcon>
                    }
                    options={airportResponse}
                    getOptionLabel={(option) =>
                      `${option.city},${option.country}(${option.iata_code}-${option.name})`
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.city},{option.country}({option.iata_code}-
                        {option.name})
                      </Box>
                    )}
                    name="flyingFrom"
                    getOptionDisabled={(option) => {
                      return handleDisable(option);
                    }}
                    onChange={(e, value) =>{
                      formik.setFieldValue("flyingFrom",value)
                    }
                    }
                    renderInput={(params) => (
                      <Box style={{width:"100%"}} className="validationField">
                         <TextField
                         id="flyingFrom"
                        {...params}
                        label="Flying From"
                        variant="outlined"
                        defaultValue="Success"
                        sx={{
                          "&.MuiOutlinedInput-root": {
                            "& fieldset": {
                              borderColor: "#1ec28b",
                            },
                            "&:hover fieldset": {
                              borderColor: "#1ec28b",
                            },
                            "&.Mui-focused fieldset": {
                              borderColor: "#1ec28b",
                            },
                          },
                        }}
                        IconComponent={FlightTakeoffIcon}
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                      </Box>
                     
                    )}
                  />

                  <span id="errormessage" className="search-loginEmailError">
                    {formik.touched.flyingFrom && formik.errors.flyingFrom}
                  </span>
                </Grid>

                <Grid item xl={2.4} lg={2.4} md={2.4} sm={12} xs={12}>
                  <Autocomplete
                    open={openDestination}
                    onOpen={() => {
                      if (airportValue) {
                        setOpenDestination(true);
                      }
                    }}
                    onClose={() => setOpenDestination(false)}
                    // inputValue={airportValue}
                    onInputChange={(e, value, reason) => {
                      setAirportValue(value);

                      if (!value) {
                        setOpenDestination(false);
                        setAirportValue("");
                      }
                    }}
                    sx={{
                      "& .MuiAutocomplete-popupIndicator": {
                        transform: "none",
                      },
                    }}
                    className="destinationTo"
                    autoHighlight
                    freeSolo={false}
                    popupIcon={
                      <LocationOnIcon
                        style={{ color: "#1ec28b", marginBottom: "auto" }}
                      ></LocationOnIcon>
                    }
                    id="country-select-demo"
                    options={airportResponse}
                    getOptionLabel={(option) =>
                      `${option.city},${option.country}(${option.iata_code}-${option.name})`
                    }
                    getOptionDisabled={(option) => {
                      return handleDisable(option);
                    }}
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.city},{option.country}({option.iata_code}-
                        {option.name})
                      </Box>
                    )}
                    name="Destination"
                    value={formik.values.Destination?formik.values.Destination:null}
                    onChange={(e, value) =>{
                      formik.setFieldValue("Destination",value)
                    }
                  }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Destination"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                     <span id="errormessage" className="search-loginEmailError">
                    {formik.touched.Destination && formik.errors.Destination}
                  </span>
                </Grid>
                <Grid item xl={2.4} lg={2.4} md={2.4} sm={12} xs={12}>
                  <Autocomplete
                  popupIcon={<Icon icon="gridicons:dropdown" style={{color:"#1ec28b",fontSize:30,marginBottom:'0.5rem'}} />}
                    // open={openDestination}
                    // onOpen={() => {
                    //   if (airportValue) {
                    //     setOpenDestination(true);
                    //   }
                    // }}
                    // onClose={() => setOpenDestination(false)}
                    // onInputChange={(e, value, reason) => {
                    //   setAirportValue(value);

                    //   if (!value) {
                    //     setOpenDestination(false);
                    //     setAirportValue("");
                    //   }
                    // }}
                    sx={{
                      "& .MuiAutocomplete-popupIndicator": {
                        transform: "none",
                      },
                    }}
                    className="destinationTo"
                    autoHighlight
                    freeSolo={false}
                    // popupIcon={
                    //   <LocationOnIcon
                    //     style={{ color: "#1ec28b", marginBottom: "auto" }}
                    //   ></LocationOnIcon>
                    // }
                    id="country-select-demo"
                    options={categoryOptions}
                    getOptionLabel={(option) =>
                      `${option.label}`
                    }
                    renderOption={(props, option) => (
                      <Box
                        component="li"
                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                        {...props}
                      >
                        {option.label}
                      </Box>
                    )}
                    name="Destination"
                    value={formik.values.Category?formik.values.Category:null}
                    onChange={(e, value) =>{
                      formik.setFieldValue("Category",value)
                    }
                  }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Category"
                        variant="outlined"
                        inputProps={{
                          ...params.inputProps,
                          autoComplete: "new-password",
                        }}
                      />
                    )}
                  />
                     <span id="errormessage" className="search-loginEmailError">
                    {formik.touched.Category && formik.errors.Category}
                  </span>
                </Grid>

                {/* <Grid item xl={2.4} lg={2.4} md={2.4} sm={12} xs={12} className="cancel-symbol-con">
                  <div className="cancel-symbol" onClick={()=>{
                    formik.setFieldValue('Category','')
                  }}><ClearIcon /></div>
                  <FormControl fullWidth>
                    <InputLabel id="demo-simple-select-label">
                      Category
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Category"
                      name="Category"
                      freeSolo={false}
                      value={formik.values.Category}
                      onChange={formik.handleChange}
                      sx={{
                        svg: { color },
                        input: { color },
                        label: { color },
                      }}
                    >
                      <MenuItem value="1">Available Travel Companion</MenuItem>
                      <MenuItem value="2">
                        Looking for Travel Companion
                      </MenuItem>
                    </Select>
                  </FormControl>
                </Grid> */}

                <Grid item xl={2.4} lg={2.4} md={2.4} sm={12} xs={12} className="cancel-symbol-con-from">
                {/* <div className="cancel-symbol" ><ClearIcon onClick={()=>{
                    formik.setFieldValue('FromDate','')
                  }} /></div> */}
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      // views={["day"]}
                      label="From Date"
                      inputFormat="DD-MMM-YY"
                      open={openFrom}
                      onOpen={handleOpenFrom}
                      onClose={handleCloseFrom}
                      value={(formik.values.FromDate)}
                      onChange={(newValue) => {
                        console.log(newValue,"newValue")
                        formik.setFieldValue("FromDate",newValue);
                      }}
                      minDate={today}
                      maxDate={today_2month}  
                      name="FromDate"
                      // minDate={today}
                      PaperProps={
                        {
                          sx:{backgroundColor:"white !important"}
                        }
                        
                      }
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {(formik.values.FromDate) && <IconButton  onClick={()=>{
                                console.log('FromDate')
                                formik.setFieldValue("FromDate",null);
                                  }} 
                                  className="cancel-icon-custom-from">
                                <ClearIcon style={{color:"gray"}}/>
                                </IconButton>}
                                
                                <IconButton edge="end" onClick={handleOpenFrom}>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            svg: { color },
                            input: { color },
                            label: { color },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <span id="errormessage" className="search-loginEmailError">
                    {formik.touched.FromDate && formik.errors.FromDate}
                  </span>
                </Grid>

                <Grid item xl={2.4} lg={2.4} md={2.4} sm={12} xs={12} className="cancel-symbol-con-to">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="To Date"
                      inputFormat="DD-MMM-YY"
                      value={(formik.values.ToDate)}
                      open={openTo}
                      onOpen={handleOpenTo}
                      onClose={handleCloseTo}
                      onChange={(newValue) => {
                        formik.setFieldValue("ToDate",newValue)
                      }}
                      name="ToDate"
                      minDate={moment(
                        moment(formik.values.FromDate).format("DD-MMM-yyyy")
                      )}
                      maxDate={today_6month}
                      PaperProps={
                        {
                          sx:{backgroundColor:"white !important"}
                        }
                        
                      }
                      renderInput={(params) => (
                        <TextField
                          fullWidth
                          {...params}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {(formik.values.ToDate) && <IconButton  onClick={()=>{
                                console.log('FromDate')
                                formik.setFieldValue("ToDate",null);
                                  }} 
                                  className="cancel-icon-custom-to">
                                <ClearIcon style={{color:"gray"}}/>
                                </IconButton>}
                                
                                <IconButton edge="end" onClick={handleOpenTo}>
                                  <EventIcon />
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                          sx={{
                            svg: { color },
                            input: { color },
                            label: { color },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                  <span id="errormessage" className="search-loginEmailError">
                    {formik.touched.ToDate && formik.errors.ToDate}
                  </span>
                </Grid>
              </Grid>
            </Container>

          </div>
          <Button name="Submit" type="submit" className="OnesearchButton">
            {/* <Link to="/" className="OnesearchButtonText"> */}
              Search
            {/* </Link> */}
          </Button>
        </form>
      </Container>
    </>
  );
};

export default SearchBar;