import React, {useEffect, useState} from "react";
import Adminsidebar from "./Adminsidebar";
import "../../src/AdminDashboard/CSS/admincompanionselfie.css";
import {
  Autocomplete,
  Breadcrumbs,
  Card,
  Container,
  Grid,
  Link,
  Pagination,
  TextField,
  Typography,
} from "@mui/material";
import {Icon} from "@iconify/react";
import axios from "axios";
import Admincompanionpopup from "./Admincompanionpopup";
import AdminHeader from "./Components/AdminHeader";
import tcicon from "./Images/Favicon 25 WB _ DV.webp";

function AdminCompanionSelfie() {
  const [createAdtrTripid, setCreateAdtrTripid] = useState({});
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [statusCounts, setStatusCounts] = useState();
  const [searchingValue, setsearchingValue] = useState({
    traveler_name: "",
    approval_status: "",
  });
  const [noresultsFound, setNoresultsFound] = useState(null);
  const [modalOpenAdtrpopInfo, setModalOpenAdtrpopInfo] = useState(false);


  const statusoptions = [
    {label: "Waiting", value: 1},
    {label: "Approved", value: 2},
    {label: "Rejected", value: 3},
  ];
  const [currentPage, setCurrentPage] = useState(1);

  const handleSearch = (e) => {
    const {value} = e.target;
    if (!value) {
      viewTripimage();
    } else {
      setsearchingValue({
        ...searchingValue,
        traveler_name: value,
      });
    }
  };


  const handleStatus=(e,statusvalue)=>{
    setsearchingValue({
      ...searchingValue,
      approval_status: statusvalue?statusvalue.value:"",
    });
}

  const isSearching = () => {
    let flag = false;
    if (searchingValue) {
      if (searchingValue.traveler_name) flag = true;
      if (searchingValue.approval_status) flag = true;
    }

    return flag;
  };
  const viewTripimage = async current => {
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    axios
      .get(
        isSearching()
          ? `${domain}/admin/companion-selfie/?page=${currentPage}&q=${
              isSearching()
                ? searchingValue.traveler_name
                  ? searchingValue.traveler_name
                  : ""
                : ""
            }&status=${
              isSearching()
                ? searchingValue.approval_status
                  ? searchingValue.approval_status
                  : ""
                : ""
            }`
          : `${domain}/admin/companion-selfie/?page=${currentPage}`
      )
      .then(response => {
        setNoresultsFound(null);
        if (isSearching()) {
          setFilteredData(response.data.results);
        } else {
          setFilteredData(response.data.results.trips);
          setStatusCounts({...response.data.results, trips: null});
        }

        setData(response.data);
      })
      .catch(error => {
        if (error.response.data.message === "no results  found") {
          setNoresultsFound("notFound");
        }
      });
  };

  useEffect(() => {
    viewTripimage();
  }, [currentPage,searchingValue?.approval_status]);


  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  const showModalAdtrpopInfo = () => {
    setModalOpenAdtrpopInfo(!modalOpenAdtrpopInfo);
  };

  const popup = item => {
    setModalOpenAdtrpopInfo(!modalOpenAdtrpopInfo);
    setCreateAdtrTripid(item);
  };

  const statusBar = stsValue => {
    let sValue = statusoptions[0];
    if (stsValue !== 0) {
      sValue = statusoptions[stsValue - 1];
    }
    return sValue;
  };

  return (
    <>
      <AdminHeader></AdminHeader>
      <div className="admin-companion-main">
        <div style={{borderRight: "groove", minHeight: "90vh"}}>
          <Adminsidebar />
        </div>
        <div style={{width: "100%"}}>
          <div className="admin-companion-heading">
            Companion Selfie Approval
          </div>
          <div className="admin-subheading">
            <Container className="admin-companion-subheading">
              <Breadcrumbs separator="" aria-label="breadcrumb">
                <Link underline="hover" color="inherit" href=">">
                  Home
                </Link>
                <Typography className="admin-subheading">
                  Companion Selfie Approval
                </Typography>
              </Breadcrumbs>
            </Container>
          </div>

          <Card style={{width: "95%", margin: "auto"}}>
            <Container className="popUp-parent">
              <div className="popup-back">
                {modalOpenAdtrpopInfo && (
                  <Admincompanionpopup
                    createAdtrTripid={createAdtrTripid}
                    setOpenModalpopinfo={showModalAdtrpopInfo}
                    setTrip={viewTripimage}
                  />
                )}
              </div>
              <>
                {statusCounts && (
                  <Grid
                    container
                    spacing={2}
                    className="admin-companion-box-main"
                  >
                    <Grid item xl={3} lg={3} md={3} xs={3} sm={3}>
                      <Card className="admin-box">
                        <div className="admin-companion-number">
                          {" "}
                          {statusCounts ? statusCounts.waiting_list : ""}
                        </div>
                        <div className="admin-box-color">Waiting list</div>
                      </Card>
                    </Grid>

                    <Grid item xl={3} lg={3} md={3} xs={3} sm={3}>
                      <Card className="admin-box">
                        <div className="admin-companion-number">
                          {" "}
                          {statusCounts.approved_list}
                        </div>
                        <div className="admin-box-color">Approved</div>
                      </Card>
                    </Grid>
                    <Grid item xl={3} lg={3} md={3} xs={3} sm={3}>
                      <Card className="admin-box">
                        <div className="admin-companion-number">
                          {" "}
                          {statusCounts.rejected_list}{" "}
                        </div>{" "}
                        <div className="admin-box-color">Rejected </div>
                      </Card>
                    </Grid>
                  </Grid>
                )}
              </>
              <div className="admin-search-main">
                <div>
                  <Grid container spacing={2}>
                    <Grid
                      item
                      xl={8}
                      lg={8}
                      md={8}
                      xs={8}
                      sm={8}
                      className="admin-companion-search"
                    >
                      <label htmlFor="search">
                        <TextField
                          className="admin-companion-srch"
                          id="full_name"
                          variant="outlined"
                          onChange={handleSearch}
                          placeholder="Search by Name or email"
                          name="traveler_name"
                          fullWidth
                          inputProps={{
                            style: {
                              height: "2px",
                            },
                          }}
                          InputProps={{
                            endAdornment: (
                              <Icon
                                onClick={() => {
                                  viewTripimage();
                                }}
                                className="admin-icon"
                                icon="ic:round-search"
                                style={{cursor: "pointer"}}
                              />
                            ),
                          }}
                        />
                      </label>
                    </Grid>

                    <Grid
                      item
                      xl={4}
                      lg={4}
                      md={4}
                      xs={4}
                      sm={4}
                      className="admin-companion-status"
                    >
                      <Autocomplete
                          className="admin-trip-stat"
                          name="trip_status"
                          onChange={handleStatus}
                          disablePortal
                          id="combo-box-demo"
                          options={statusoptions}
                          renderInput={params => (
                            <TextField
                              {...params}
                              label="Status:All"
                              className="custom-notched-outline"
                            />
                        )}
                      />
                    </Grid>
                  </Grid>
                </div>
              </div>
              {noresultsFound ? (
                <Container>
                  <div className="search-bar-no-results-found-main-div">
                    {" "}
                    <img
                      src="../../Assets/Images/Search illustration.jpg"
                      className="search-illustration-img"
                    ></img>
                    <div className="adsmanagement-no-results-found">
                      Ups!... no results found
                    </div>
                    <div>
                      No content matched your keyword. Please try searching for
                      something else
                    </div>
                  </div>
                </Container>
              ) : (
                <Container className="admin-companion-tbl">
                  <table className="admin-companion-table" data={data}>
                    <tr className="admin-companion-border ">
                      <th>Trip Id</th>
                      <th>Name</th>
                      <th>Companion Selfie</th>
                      <th>Travel Experience</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                    <tbody>
                      {filteredData.map(item => (
                        <>
                          <tr className="admin-companion-border1">
                            <td>
                              <div className="admin-color">{item.trip_id}</div>
                            </td>
                            <td>
                              <b>{item.traveler_name}</b>
                            </td>

                            <td className="admin-td-image">
                              <img
                                accept="jfif"
                                src={
                                  item.trip_photos ? item.trip_photos : tcicon
                                }
                                className="admin-companion-image"
                              />
                            </td>
                            <td className="admin-description">
                              {item.whats_on_ur_mind}
                            </td>
                            <td>
                              {item.approval_status === 1
                                ? "Waiting"
                                : item.approval_status === 2
                                ? "Approved"
                                : "Rejected"}
                            </td>
                            <td className="admin-companion-cmicon">
                              <Icon
                                className="admin-companion-icon1"
                                icon="ic:round-remove-red-eye"
                                onClick={() => popup(item)}
                              />
                            </td>
                          </tr>
                        </>
                      ))}
                    </tbody>
                  </table>
                </Container>
              )}

              <div>
                {!(data.count <= 5) && (
                  <Pagination
                    className="admin-trip-bttn"
                    count={Math.ceil(data ? data.count / 5 : null)}
                    page={currentPage}
                    onChange={handlePageChange}
                  />
                )}
              </div>
            </Container>
          </Card>
        </div>
      </div>
    </>
  );
}

export default AdminCompanionSelfie;
