import React from 'react'
import axios from "axios";


const TripCount = (id) => {


  console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
  let domain = "";
  console.log(process.env, "domain env", window.location.hostname);
  if (window.location.hostname.trim() === "localhost") {
    domain = process.env.REACT_APP_LOCAL_API;
  } else if (window.location.hostname.trim() === "54.185.6.32") {
    domain = process.env.REACT_APP_UAT_API;
  } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_UAT_API;
  }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_API;
  }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_API;
  }
  console.log(domain, "domain env end");


    let flag=false
     axios.get(`${domain}/upcoming-trips-count/${id}/`)
    .then((countResponse)=>{
            console.log(countResponse,"countResponse")
            flag=countResponse.data.can_create
            localStorage.setItem("can", JSON.stringify(countResponse.data.can_create))
    }).catch(e=>{
      console.log(e,"countError")
    })
    return flag
}

export default TripCount