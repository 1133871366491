import {Icon} from "@iconify/react";
import {Avatar, Box, Button, Container} from "@mui/material";
import React from "react";
import "../../src/AdminDashboard/CSS/admintrip.css";
import axios from "axios";
import tcicon from "./Images/Favicon 25 WB _ DV.webp";

function Admintrippopup(props) {
  console.log("1234567", props.createAdtrTripid);
  const approvalhandler = (id, value) => {
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }

    axios
      .post(`${domain}/admin/trip-approval/${id}/`, {
        trip_status: value,
      })

      .then(response => {
        props.setOpenModalpopinfo(false);
        props.setTrip();
        console.log(response, "Approve-response");
        console.log(response, "Reject-response");
      })
      .catch(error => {});
  };

  return (
    <div className="admin-modalBackground">
      <div className="admin-modalContaner">
        <Box className="admin-trip-box">
          <span className="admin-wrong">
            <Icon
              icon="system-uicons:cross"
              width="32"
              height="32"
              className="admin-trip-wrong"
              onClick={props.setOpenModalpopinfo}
            />
          </span>
          <Container>
            <div className="admin-trip-img-start1">
              <div className="admin-trip-head">
                <Avatar alt="Travis Howard" src="/static/images/avatar/2.jpg" />
                <div>
                  <div style={{fontSize: 16, fontWeight: 600}}>
                    {props.createAdtrTripid.traveler_name}
                  </div>
                  {/*<div style={{fontSize: 13.5, marginLeft: -12}}>Brazil</div>*/}
                </div>
              </div>
            </div>
            <div className="admin-trip-name">
              <b>Trip Name</b>{" "}
              <span style={{marginLeft: 18, marginRight: 18}}> -- </span>
              {props.createAdtrTripid.trip_name}
            </div>
            <div className="admin-subhead">
              <div className="admin-heading">Traveller Image</div>
              <div className="admin-trip-img">
                <img
                  accept="jfif"
                  src={
                    props.createAdtrTripid.photo
                      ? props.createAdtrTripid.photo
                      : tcicon
                  }
                  className="admin-src-image"
                />
              </div>
            </div>
            <div className="admin-desc">Description</div>
            <div className="admin-descr">
              {props.createAdtrTripid.short_description}
            </div>
            <div className="admin-desc">Assistance Needed</div>
            <div className="admin-descr">
              {props.createAdtrTripid.assistance_needed}
            </div>
            {console.log(props.createAdtrTripid.trip_status)}
            <div className="admin-main-btn">
              {(props.createAdtrTripid.trip_status === 1 ||
                props.createAdtrTripid.trip_status === 3) && (
                <Button
                  onClick={() => {
                    approvalhandler(props.createAdtrTripid.id, 2);
                  }}
                  className="admin-trip-button"
                >
                  APPROVE
                </Button>
              )}
              {(props.createAdtrTripid.trip_status === 1 ||
                props.createAdtrTripid.trip_status === 2) && (
                <Button
                  onClick={() => {
                    approvalhandler(props.createAdtrTripid.id, 3);
                  }}
                  className="admin-trip-button"
                >
                  REJECT
                </Button>
              )}
            </div>
          </Container>
        </Box>
      </div>
    </div>
  );
}

export default Admintrippopup;