import React, { useEffect, useState } from 'react'
import Adminsidebar from '../Adminsidebar'
import "../CSS/addmember.css";
import { Autocomplete, Box, Breadcrumbs, Button, Container, Grid, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import TextField from '@mui/material/TextField';
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from 'axios';
import AdminHeader from '../Components/AdminHeader';

export default function AddMember() {
//   const styles = {
//     floatingLabelFocusStyle: {
//         color: "somecolor"
//     }
// }

const [registerResponseMsg, setRegisterResponseMsg] = useState("");
const [registerErrorResponse, setRegisterErrorResponse] = useState("");

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
// const onlyenglishchar = /^[A-Za-z]*$/;
// const spaces = /^\S*$/;
const englishchar = /[A-Za-z]/;
const onlyenglishchar = /^[A-Za-z ]*$/;
const englishcharacterwithspace=/^([a-zA-Z]+\s)*[a-zA-Z]+$/

const spaces = /^\S*$/;
const spaces1=/^[^\s]+(\s+[^\s]+)*$/;
const validationSchema = Yup.object({
  email: Yup.string("Enter your Email")
    .email("Enter a valid Email")
    .required("Email is required"),
    full_name: Yup.string("Enter your Full Name")
    .min(3, "Too Short!")
    .max(100, "Too Long!")
    .required("Full Name is required")
    .matches(onlyenglishchar,"only English characters allowed")
    .matches(spaces1, "Space is not accepted at  starting and ending of the characters")
    .required("Full Name is required"),
    country_code: Yup.string("Enter your Country Code")
    // .matches(/[0-9]/,"Enter your Country Code")
    .required("Country Code is required"),
    mobile_number: Yup.string("Enter your Mobile Number")
    .matches(phoneRegExp, "Mobile Number is not valid")
      .required("Mobile Number is required")
      .min(4, "Mobile Number must be at least 4 characters")
      .max(12, "Mobile Number must be at most 12 characters"),
});
const formik = useFormik({
  initialValues: {
    email: "",
    full_name: "",
    country_code: "",
    mobile_number: ""
  },

  validationSchema: validationSchema,
  onSubmit: (values) => {
    // alert(JSON.stringify(values, null, 2));
    setRegisterResponseMsg("");

    console.log(JSON.stringify(values));
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
      let domain = "";
      console.log(process.env, "domain env", window.location.hostname);
      if (window.location.hostname.trim() === "localhost") {
        domain = process.env.REACT_APP_LOCAL_API;
      } else if (window.location.hostname.trim() === "54.185.6.32") {
        domain = process.env.REACT_APP_UAT_API;
      } else if (
        window.location.hostname.trim() === "uat.desitravelcompanion.com"
      ) {
        domain = process.env.REACT_APP_PROD_UAT_API;
      } else if (
        window.location.hostname.trim() === "desitravelcompanion.com"
      ) {
        domain = process.env.REACT_APP_PROD_API;
      } else if (
        window.location.hostname.trim() === "www.desitravelcompanion.com"
      ) {
        domain = process.env.REACT_APP_PROD_API;
      }
      console.log(domain, "domain env end");

      axios
        .post(
          `${domain}/admin/register/`,
          
    values
            
          
          // {
          //   method: 'POST',
          //   mode: 'no-cors',
          //   headers: {
          //     'Access-Control-Allow-Origin': '*',
          //     'Content-Type': 'application/json',
          //   },
          //   withCredentials: true,
          //   credentials: 'same-origin',}
        )
        .then((response) => {
          console.log(response, "register response");
          // if (response.data) {
          // ret  localStorage.setItem("user", JSON.stringify(response.data));
          // }
          if (response.data.message) {
            setRegisterErrorResponse("");
            // setgloginErrorMessage("");
            setRegisterResponseMsg(response.data.message);
            formik.resetForm();
            // formik.setFieldValue('signinagreement', checked)
            // values.signinagreement.v
            // formik.values("signinagreement",false)
          }

          // return response.data;
        })
        .catch((error) => {
          // Error
          if (error.response.status === 400) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            // console.log(error.response.data);
            // console.log(error.response.status);
            // console.log(error.response.headers);
            setRegisterResponseMsg("");
            // setgloginErrorMessage("");
            setRegisterErrorResponse(error.response.data.message);
          }
        });
    },
  });
  // },
// });

console.log('values',formik)

const handleChange = e => {
  // console.log("e","input valueeee")
  setRegisterResponseMsg(null);
  setRegisterErrorResponse(null);
};



const [inputValue, setInputValue] = React.useState();
const [country_search_response, setCountries] = useState([]);

const countrystatepincode = () => {
  console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
  let domain = "";
  console.log(process.env, "domain env", window.location.hostname);
  if (window.location.hostname.trim() === "localhost") {
    domain = process.env.REACT_APP_LOCAL_API;
  } else if (window.location.hostname.trim() === "54.185.6.32") {
    domain = process.env.REACT_APP_UAT_API;
  } else if (
    window.location.hostname.trim() === "uat.desitravelcompanion.com"
  ) {
    domain = process.env.REACT_APP_PROD_UAT_API;
  } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_API;
  } else if (
    window.location.hostname.trim() === "www.desitravelcompanion.com"
  ) {
    domain = process.env.REACT_APP_PROD_API;
  }
  console.log(domain, "domain env end");

  axios
    .get(
      `${domain}/country-code/`
    )
    .then((res) => {
      console.log(res, "c response");
      if (res.data.response) {
        setCountries(res.data.response);
      }
    }).catch((error) => {
      // Error
     
    });
};


useEffect(() => {
  // document.title =  "Desi Travel Companion-signup";
  countrystatepincode();
}, [inputValue]);


  return (
    <><AdminHeader></AdminHeader><div className="dbsql-profile-main">
      <div style={{ borderRight: "groove", minHeight: "100vh" }}>
        <Adminsidebar />
      </div>
      <Container style={{ backgroundColor: "whitesmoke" }}>
        <div className="admindashboard-heading">Add Member</div>
        <Breadcrumbs
          separator="›"
          aria-label="breadcrumb"
          className="ads-management-subhead"
        >
          {/* {breadcrumbs} */}
          <Link to={''} className="ads-manage-home">
            Home
          </Link>
          <Typography className="ads-management-subheading">
            Add Member
          </Typography>
        </Breadcrumbs>
        <form onSubmit={formik.handleSubmit}>

          <Box style={{ backgroundColor: "white", border: "1px solid lightgrey", borderRadius: 5, marginTop: 20 }}>
            <Grid style={{ marginTop: 20, marginBottom: 20 }}>Personal Details</Grid>
            <Container>
              <Grid style={{ marginTop: 10, marginBottom: 20 }}>
              {/* <label>
                                  Mobile Number
                                  <span style={{ color: "#ff8d8d" }}>*</span>
                                </label> */}
                <TextField
                  InputLabelProps={{
                    style: { color: "#ff8d8d" }
                  }}
                  name='full_name'
                  id="standard-basic" label={<label>
                    Name
                    <span style={{ color: "#ff8d8d" }}>*</span>
                  </label>} variant="standard"  focused fullWidth
                  onInput={handleChange}
                  onBlur={formik.handleBlur}
                  touched={formik.touched}
                  value={formik.values.full_name}
                  onChange={formik.handleChange}
                  error={formik.touched.full_name && Boolean(formik.errors.full_name)}
                  helperText={formik.touched.full_name && formik.errors.full_name} />
              </Grid>
              <Grid style={{ marginTop: 10, marginBottom: 20 }}>
                <TextField name='email' id="standard-basic" label={<label>
                  Email ID
                    <span style={{ color: "#ff8d8d" }}>*</span>
                  </label>} variant="standard" style={{ color: "#ff8d8d" }} focused fullWidth
                 onInput={handleChange}
                 onBlur={formik.handleBlur}
                  touched={formik.touched}
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email} />
              </Grid>
              <Grid style={{ marginTop: 10, marginBottom: 20,display: "flex" }}>
                {/* <TextField name='country_code' id="standard-basic" label="Country Code*" variant="standard" style={{ color: "#1ec28b",width:"15%",paddingRight: "54px"}} focused fullWidth
                  onBlur={formik.handleBlur}
                  touched={formik.touched}
                  value={formik.values.country_code}
                  onChange={formik.handleChange}
                  error={formik.touched.country_code && Boolean(formik.errors.country_code)}
                  helperText={formik.touched.country_code && formik.errors.country_code} /> */}




<Autocomplete 
                        sx={{
                          "& .MuiAutocomplete-popupIndicator": {
                            transform: "none",
                          },
                        }}
                        // className="profile-edit-country-code"
                        // name="country_code"
                        onInput={handleChange}
                        onBlur={formik.handleBlur}
                        // onBlur={formik.handleBlur && countrycode}

                        touched={formik.touched}
                        value={formik.values.country_code}
                        
                                    // onChange={formik.handleChange}
                        // onChange={formik.handleChange}
                        error={
                          formik.touched.country_code &&
                          Boolean(formik.errors.country_code)
                        }
                        helperText={
                          formik.touched.country_code &&
                          formik.errors.country_code
                        }
                        autoHighlight
                        freeSolo={false}
                        id="country-select-demo"



                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}


                        options={country_search_response?country_search_response:''}
                        getOptionLabel={(option) =>option.name || option}
                        // console.log(option,"option country")
                      //  return option.name || option}}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option.name} ({option.phone_code})

                          </Box>
                        )}
                        // name="country"
                        onChange={(e, value) => {
                          formik.setFieldValue(
                            "country_code",
                            value ? value.phone_code : ""
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="Select a country"
                            id="standard-basic" label={<label>
                              Country Code
                                <span style={{ color: "#ff8d8d" }}>*</span>
                              </label>}  variant="standard" style={{ color: "#1ec28b",paddingRight: "54px"}} focused fullWidth
                            onInput={handleChange}
                            name="country_code"
                            // variant="outlined"
                            error={
                              formik.touched.country_code &&
                              Boolean(formik.errors.country_code)
                            }
                            helperText={
                              formik.touched.country_code &&
                              formik.errors.country_code
                            }


                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "country_code",
                            }}
                          />
                        )}
                      />
{/* <div
                                id="errormessage"
                                className=""
                                style={{ color: "red", fontSize: 12,marginTop:10 }}
                              >
                                {formik.touched.country_code &&
                                  formik.errors.country_code}
                              </div> */}
                <TextField name='mobile_number' id="standard-basic" label={<label>
                  Mobile Number
                                <span style={{ color: "#ff8d8d" }}>*</span>
                              </label>} variant="standard" style={{ color: "#1ec28b", width: "800px",marginLeft: "40px" }} focused fullWidth
                 onInput={handleChange}
                 onBlur={formik.handleBlur}
                  touched={formik.touched}
                  value={formik.values.mobile_number}
                  onChange={formik.handleChange}
                  error={formik.touched.mobile_number && Boolean(formik.errors.mobile_number)}
                  helperText={formik.touched.mobile_number && formik.errors.mobile_number} />
              </Grid>
              {/* <Grid style={{marginTop:10,marginBottom:20}}>
    <TextField name='country_code' id="standard-basic" label="Country Code*" variant="standard" style={{color:"#1ec28b"}} focused fullWidth
    
    onBlur={formik.handleBlur}
    touched={formik.touched}
    value={formik.values.country_code}
    onChange={formik.handleChange}
    error={
      formik.touched.country_code && Boolean(formik.errors.country_code)
    }
    helperText={formik.touched.country_code && formik.errors.country_code}
    />
    </Grid> */}
            </Container>
            <div>
              {" "}
              {registerResponseMsg ? (
                <span style={{ color: "green" }}>
                  {registerResponseMsg}
                </span>
              ) : null}
              {registerErrorResponse ? (
                <span style={{ color: "rgb(211, 47, 47)" }}>
                  {registerErrorResponse}
                </span>
              ) : null}{" "}
            </div>
            <Grid style={{ marginTop: 10, marginBottom: 20 }}>
              <Button variant="contained" style={{ backgroundColor: "#1ec28b" }}
                name="Submit"
                type="submit"
              >Submit</Button></Grid>
          </Box>
        </form>
      </Container>
    </div></>
  )
}
