import React, { useEffect, useState,useRef } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  Label,
  Pie,
  PieChart,
  Cell,
  LabelList,
  LineChart,
  Line,
} from "recharts";
import YearRangePicker from "./Custom/YearRangePicker";
import "../CSS/trips.css";
import AdminHeader from "../Components/AdminHeader";
import Adminsidebar from "../Adminsidebar";
import { Breadcrumbs, Container, Grid, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import axios from "axios";
import SelectComponent from "./Custom/SelectComponent";
import dayjs from "dayjs";
import { Icon } from "@iconify/react";
import Preference from "./Custom/Preference";
import Loading from "./Loading";


var domain = "";
console.log(process.env, "domain env", window.location.hostname);
if (window.location.hostname.trim() === "localhost") {
  domain = process.env.REACT_APP_LOCAL_API;
} else if (window.location.hostname.trim() === "54.185.6.32") {
  domain = process.env.REACT_APP_UAT_API;
} else if (
  window.location.hostname.trim() === "uat.desitravelcompanion.com"
) {
  domain = process.env.REACT_APP_PROD_UAT_API;
} else if (window.location.hostname.trim() === "desitravelcompanion.com") {
  domain = process.env.REACT_APP_PROD_API;
} else if (
  window.location.hostname.trim() === "www.desitravelcompanion.com"
) {
  domain = process.env.REACT_APP_PROD_API;
}



export default function Trips() {
  const currentYear=dayjs().format('YYYY')
  const [dates, setDates] = useState({
    genderwise: currentYear,
    agewise: currentYear,
    usertype: currentYear,
    yearly_monthlywise: currentYear,
    destinationwise: currentYear,
    source_destination: {
      year:currentYear,
      source:'',
      destination:'',
      preference:''
    },
  });
  const [toolTipContent,setTooltipContent]=useState()
  const [chartData, setChartData] = useState({});
  const [userType, setUserType] = useState();
  const [agewise, setAgewise] = useState();
  const [genderwise, setGenderwise] = useState();
  const [yearly, setYearly] = useState();
  const [destination, setdestination] = useState();
  const [source_destination, setsource_desti] = useState();
  const [backOFF,setBackOFF]=useState(false)


  const noResult=(array,name)=>{
    console.log(array,name,'noResultArray')
    let flag=false
   array.map((item)=>{
    if(name==='source_destination'){
      if(item.count) flag=true
    }else if(name==="usertype"){
      if(item.self) flag=true
      if(item.onbehalf) flag=true
    }
    else if(name==='agewise'||name==='yearly_monthlywise'){
      if(item.value) flag=true
    }else if(name===`genderwise`){
      if(item.Male) flag=true
      if(item.Female) flag=true
    }

   }) 
   return flag
  }

  const fillingData = (array, name, endYear,report_name) => {
    console.log(array,name,'arrayFillingData')
    let objKeys=[];

    if(array.length>0)
      objKeys = Object.keys(array[0]);

    let endyear = Array.isArray(endYear)?parseInt(endYear[1]):parseInt(endYear);

    let currentMonth=(Array.isArray(endYear))
    ?null
    :dayjs(endYear,"MMM");

    let newArray = array;
    Array(12 - array.length < 0 ? array.length : 12 - array.length)
      .fill("*")
      .map(() => {
        let obj = {};
        objKeys.map((item) => {
          obj[`${item}`] = "";
          if(item==='data_Female'||item==='data_Male'){
            obj[`${item}`] = []
          }
          if (item === name) {
            obj[`${item}`] = parseInt(endyear) + 1;
            endyear = endyear + 1;
          }
        });
        newArray.push(obj);
      });

      if(!noResult(newArray,report_name))
          newArray="No Results Found!"
      
    return newArray;
  };


  const CustomTooltips = ({ active, payload, label }) => {
  
  let keys
  let values
  if(toolTipContent){
    keys=Object.keys(toolTipContent)
    values=Object.values(toolTipContent)
  }
    return (toolTipContent)?<div className="tooltip_custom">
      <div className="tooltip-head">{values[0]}</div>
      <div className="gender_count">
        {
          values.map((dis,index)=>{
            return index!==0?
            <div className="tooltip-parent">
              <div className="TT-label TT-left-label">{keys[index]}</div>
              <div className="TT-label TT-right-label">
                {`-- ${dis}`}
              </div>
            </div>:null
          })
        }
      </div>

  </div>:null ;
  };
  const YearlyCustomTooltips = ({ active, payload, label }) => {
  
    let keys
    let values
    if(toolTipContent){
      keys=Object.keys(toolTipContent)
      values=Object.values(toolTipContent)
    }
      return (toolTipContent)?<div className="tooltip_custom">
        <div className="tooltip-head">{values[0]}</div>
        <div className="gender_count">
          {
            values.map((dis,index)=>{
              return index!==0?
              <div className="tooltip-parent">
                <div className="TT-label TT-left-label">{keys[index]}</div>
                <div className="TT-label TT-right-label">
                  {`-- ${dis}`}
                </div>
              </div>:null
            })
          }
        </div>
  
    </div>:null ;
    };
  


     const AgewiseYearRange=(data,report_name)=>{
      let array=[]
      data.map((yearData,index)=>{

     if(index===0){
      yearData.data.map((ageData_0,ind)=>{
        let obj={
          f_name:"Female",
          m_name:"Male",
          name: ageData_0.age_group,
          value: parseInt(ageData_0.age_group_count),
          female:parseInt(ageData_0.gender_female),
          male: parseInt(ageData_0.gender_male)
        }
        array.push(obj)
      })
     }else{
      yearData.data.map((ageData,i)=>{
        console.log(array[i],'array[i]')
        let obj={
          f_name:"Female",
          m_name:"Male",
          name: ageData.age_group,
          value: parseInt(array[i].value) + parseInt(ageData.age_group_count),
          female:parseInt(array[i].female) + parseInt(ageData.gender_female),
          male:parseInt(array[i].male) + parseInt(ageData.gender_male)
        }
        array[i]=obj
      })
     }
        
      })
      if(!noResult(array,report_name))
          array="No Results Found!"
      return array
     }
const getAcc=(country)=>{
  const words = country.split(' ');
  if (words.length >1) {
    let Abb="";
    words.map((con)=>{
   
        Abb=Abb+con.charAt(0).toUpperCase()
   
    })
    return Abb
  } else {
    return `${words[0].slice(0,3)}`
  }
}
  const showResults = (report_name) => {
    if(report_name==="destinationwise") setdestination("loading")
    else if(report_name==="usertype") setUserType("loading")
    else if(report_name==="agewise") setAgewise("loading")
    else if(report_name==="genderwise") setGenderwise("loading") 
    else if(report_name==="yearly_monthlywise") setYearly("loading") 
    
    
    if (!Array.isArray(dates[report_name])) {
      axios
        .get(
          `${domain}/admin/trip-report/?year=${dates[report_name]}&report=${report_name}`
        )
        .then((response) => {
          const datas = response.data[report_name];
          if (datas) {
            if (report_name === "agewise") {
              let array = [];
              datas.map((item) => {
                let obj = {
                  name: item.age_group,
                  value: item.total_age_group_count,
                  female:item.total_female,
                  f_name:"Female",
                  m_name:"Male",
                  male:item.total_male
                };
                array.push(obj);
              });
              if(!noResult(array,report_name))
                   array="No Results Found!"
              setAgewise(array);
            } else if (report_name === "destinationwise") {
              if (datas) {
                const data = datas[0];
                if(data.data.length>0){
                  const sliced=data.data.slice(0,5)
                  const array=[]
                  sliced.map((item,index)=>{
                  let obj={
                      ...item,
                      Abbrevation:getAcc(item.destination)
                   }
                  array.push(obj)
                  })
                  setdestination(array);
                }else{setdestination("No Results Found!")}
                
              } else {
                setdestination();
              }
            } else if (report_name === "source_destination") {
              if (datas) {
                const data = datas[0];
                if (data) {
                  setsource_desti(data);
                } else {
                  setsource_desti(undefined);
                }
              } else {
                setChartData(undefined);
              }
            }else if(report_name === "usertype"){
              if (datas) {
                const data = datas[0];
                let array = [];
                data.data.map((item) => {
                  let obj={}
                  obj['name']=item.month
                 obj['self']=item.result_data[0].count!==0?item.result_data[0].count:''
                 obj['Self_f']=item.result_data[0].gender_female
                 obj['Self_m']=item.result_data[0].gender_male
                 if(item.result_data.length===2){
                  obj['onbehalf']= item.result_data[1].count!==0?item.result_data[1].count:''
                  obj['Onbehalf_f']=item.result_data[1].gender_female
                  obj['Onbehalf_m']=item.result_data[1].gender_male
                 }
                  array.push(obj);
                });
                const finalArray = fillingData(
                  array,
                  "name",
                  dates[report_name],
                  report_name
                );
                setUserType(finalArray);
              } else {
                setUserType([]);
              }
            }else if (report_name === "genderwise") {
              const data = response.data[report_name];
              if (data) {
                let array = [];
                data.map((item) => {
                  let obj = {
                    name: item.month,
                    Male: item.gender_male!==0?item.gender_male:"",
                    Female: item.gender_female!==0?item.gender_female:'',
                    data_Female:item.data_female,
                    data_Male:item.data_male
                  };
                  array.push(obj);
                });
                const finalArray = fillingData(
                  array,
                  "name",
                  dates[report_name],
                  report_name
                );
                setGenderwise(finalArray);
              }
            }else if (report_name === "yearly_monthlywise") {
              let data = response.data[report_name];
              let array = [];
              data.data.map((item) => {
                let obj = { 
                  name: item.month.slice(0,3), 
                  value: item.count!==0?item.count:"" };
                array.push(obj);
              });
              const finalArray = fillingData(
                array,
                "name",
                dates[report_name],
                report_name
              );
              setYearly(finalArray);
            }
          }
        });
    } else {
      axios
        .get(
          `${domain}/admin/trip-report/?report=${report_name}&start_year=${dates[report_name][0]}&end_year=${dates[report_name][1]}`
        )
        .then((response) => {
          console.log(response.data, "responseChart");
          if (report_name === "destinationwise") {
            if (response.data[report_name]) {
              const data = response.data[report_name][0];
              if(data.data.length>0){
                const sliced=data.data.slice(0,5)
                const array=[]
                sliced.map((item,index)=>{
                let obj={
                  ...item,
                  Abbrevation:getAcc(item.destination)
                }
                array.push(obj)
                })
                setdestination(array);
              }else{setdestination("No Results Found!")}
             
            } else {
              setdestination();
            }
          } else if (report_name === "source_destination") {
            if (response.data[report_name]) {
              const data = response.data[report_name][0];
              console.log(data, "chartData..");
              if (data) {
                setChartData({ ...chartData, [`${report_name}`]: data.data });
              } else {
                setChartData({ ...chartData, [`${report_name}`]: undefined });
              }
            } else {
              setChartData({ ...chartData, [`${report_name}`]: undefined });
            }
          } else if (report_name === "usertype") {
            if (response.data[report_name]) {
              const data = response.data[report_name][0];
              console.log(data, "chartData*");
              let array = [];
              data.data.map((item) => {
                let obj = {
                  name: item.year,
                  self: item.result_data[0].count!==0?item.result_data[0].count:'',
                  onbehalf: item.result_data[1].count!==0?item.result_data[1].count:'',
                  Self_f:item.result_data[0].gender_female,
                  Self_m:item.result_data[0].gender_male,
                  Onbehalf_f:item.result_data[1].gender_female,
                  Onbehalf_m:item.result_data[1].gender_male
                };
                array.push(obj);
              });
              const finalArray = fillingData(
                array,
                "name",
                dates[report_name][1],
                report_name
              );
              setUserType(finalArray);
            } else {
              setChartData({ ...chartData, [`${report_name}`]: undefined });
            }
          } else if (report_name === "genderwise") {
            console.log(response.data, "responseChart_gender");
            const data = response.data[report_name];
            if (data) {
              let array = [];
              data.map((item) => {
                let obj = {
                  name: item.year,
                  Male: item.gender_male,
                  Female: item.gender_female,
                  data_Female:item.data_female,
                  data_Male:item.data_male
                };
                array.push(obj);
              });
              const finalArray = fillingData(
                array,
                "name",
                dates[report_name][1],
                report_name
              );
              setGenderwise(finalArray);
            }
          } else if (report_name === "yearly_monthlywise") {
            let data = response.data[report_name];
            let array = [];
            data.map((item) => {
              let obj = { 
                name: item.year, 
                value: item.count 
              };
              array.push(obj);
            });
            const finalArray = fillingData(
              array,
              "name",
              dates[report_name][1],
              report_name
            );
            setYearly(finalArray);
          }else if (report_name === "agewise") {
            // const datas = response.data[report_name];
            // console.log(datas,'datasAge')
            // let array = [];
            // datas.map((item) => {
            //   let obj = {
            //     name: item.age_group,
            //     value: item.total_age_group_count,
            //   };
            //   array.push(obj);
            // });
          setAgewise(AgewiseYearRange(response.data[report_name],report_name)) 
          }
        });
    }
  };
  const showResultsSource_desti = (report_name) => {
    setsource_desti("loading")
    if (!Array.isArray(dates[report_name].year)) {
      axios
        .get(
          `${domain}/admin/trip-report/?year=${dates[report_name].year}&report=source_destination&preference=${dates[report_name].preference}&flying_from=${dates[report_name].source}&destination=${dates[report_name].destination}`
        )
        .then((response) => {
          const datas = response.data[report_name];
          if (datas) {
           if (report_name === "source_destination") {
            let array=[]
              if (datas) {
                datas.data.map((item) => {
                  let obj = {
                    name: item.month.slice(0,3),
                    value:item.count
                  };
                  array.push(obj);
                });
                const finalArray = fillingData(
                  array,
                  "name",
                  dates[report_name]
                );
                setsource_desti(finalArray);
              } else {
                setChartData(undefined);
              }
            }
          }
        });
    } else {
      axios
        .get(
          `${domain}/admin/trip-report/?report=source_destination&preference=${dates[report_name].preference}&flying_from=${dates[report_name].source}&destination=${dates[report_name].destination}&start_year=${dates[report_name].year[0]}&end_year=${dates[report_name].year[1]}`
        )
        .then((response) => {
          if (report_name === "source_destination") {
            if (response.data[report_name]) {
              const data = response.data[report_name];
              let array=[]
              if (data) {
                data.data.map((item) => {
                  let obj = {
                    name: item.year,
                    value:item.count
                  };
                  array.push(obj);
                });
                const finalArray = fillingData(
                  array,
                  "name",
                  dates[report_name].year[1]
                );
                setsource_desti(finalArray);
              }
            } else {
              setsource_desti({ ...chartData, [`${report_name}`]: undefined });
            }
          }
        });
    }
  };

  const ageColor = [
    "#0263ff",
    "#ff7723",
    "#8e30ff",
    "#796d80",
    "#8d009a",
    "#bc3433",
    "#44cc11",
    "#146a90",
  ];
  const colors = ["#ff806e", "#d9ba5c", "#20c28c","#6583CA","#717175"];
  
  useEffect(() => {
    const reports = [
      "genderwise",
      "agewise",
      "usertype",
      "yearly_monthlywise",
      "destinationwise",
    ];
    reports.map((report_name) => {
      showResults(report_name);
    });
    showResultsSource_desti("source_destination")


  }, []);


  const splittingData=(value)=>{
    let finalValue=value
    if(value){
        const final=value.split("(")
        finalValue=final[0]
    }
     return finalValue
  }

  return (
    <>
      <AdminHeader></AdminHeader>
      <div className="dbsql-profile-main" id="backside" onClick={(e)=>{
        console.log(e.target.id,"backing")
        setBackOFF(true)
      }}>
        <div style={{ borderRight: "groove", minHeight: "100vh" }}>
          <Adminsidebar />
        </div>
        <Container style={{ backgroundColor: "whitesmoke" }} 
          >
          <div className="admindashboard-heading">Reports</div>
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            className="ads-management-subhead"
          >
            <Link to={""} className="ads-manage-home">
              Home
            </Link>
            <Typography className="ads-management-subheading">
              Reports-Trips
            </Typography>
          </Breadcrumbs>
          <div className="trip-container">
            <Grid container style={{ display: "block",padding: 46 }} id="Chart12Pdf">
              <Grid item className="trip-heading">
                <div
                  className="admindashboard-chart-heading"
                  style={{ fontSize: 20, display: "inline-block" }}
                >
                  Destination wise trip Count
                </div>
                <div className="trips-export">
                  <SelectComponent idName="Chart12Pdf" data={destination}/>
                </div>
              </Grid>

            
                <div className="trip-pieChart">
                {destination==="loading"&&<div className="barModal">
                      <Loading/>
                    </div>}
                  <div className="picker-con">
                    <div className="picker">
                    <YearRangePicker
                      settingState={setDates}
                      name="destinationwise"
                      place="select"
                      wholeYears={dates}
                    />
                    </div>
                    <div className="or_seperator"> OR </div>
                    <div className="picker">
                    <YearRangePicker
                      settingState={setDates}
                      name="destinationwise"
                      place="yearRange"
                      background={backOFF}
                      settingBackOFF={setBackOFF}
                      wholeYears={dates}
                    />
                    </div>
                  </div>
                  <div className="trips-btn-container">
                    <button
                      className="trips-btn"
                      onClick={() => {
                        showResults("destinationwise");
                      }}
                    >
                      Show results
                    </button>
                  </div>

                  {destination&&(typeof destination!=='string' && destination.length > 0 )? (
                    <div className="trip-piecharts">
                      <div className="pie_1">
                      <PieChart width={400} height={350}>
                        <Pie
                          width={400}
                          height={400}
                          data={destination}
                          label
                          outerRadius={100}
                          innerRadius={50}
                          fill="#8884d8"
                          dataKey="count"
                        >
                          <LabelList dataKey="destination" position="outside" fill="black" stroke="none"/>
                          <Label position={"center"}>{(!Array.isArray(dates['destinationwise'])?`Year ${dates['destinationwise']}`:`${dates['destinationwise'][0]} to ${dates['destinationwise'][0]}`) }</Label>
                          {destination.map((entry, index) => (
                            <>
                              <LabelList
                                className="labellist-design"
                                stroke="none"
                                fill="white"
                              >
                                {entry.value}
                              </LabelList>
                              <Cell
                                key={`cell-${index}`}
                                fill={colors[index % colors.length]}
                                className="cell-line"
                              />
                            </>
                          ))}
                        </Pie>
                      </PieChart>
                      {/* <MyResponsivePie data={destination}/> */}
                      </div>
                      <div className="pie_2">
                      <PieChart width={400} height={400}>
                        <Pie
                          data={destination}
                          width={400}
                          height={400}
                          // cx={200}
                          // cy={200}
                          label
                          outerRadius={80}
                          fill="#8884d8"
                          dataKey="count"
                        >
                          {destination.map((entry, index) => {
                         return <>
                         <LabelList
                                className="labellist-design"
                                stroke="none"
                                fill="white"
                                dataKey='Abbrevation'
                              >
                                {/* {entry.destination} */}
                              </LabelList>
                              <Cell
                              key={`cell-${index}`}
                              fill={colors[index % colors.length]}
                            />

                         </>
})}
                        </Pie>
                      </PieChart>

                      </div>
                    </div>
                  )
                  :(destination!=="loading"?<div className="noResult">{destination}</div>:null)}
                </div>
           
            </Grid>
         
              <Grid container style={{ display: "block", padding: 46 }} id="Chart22Pdf">
                <Grid item className="trip-heading">
                  <div
                   className="admindashboard-chart-heading"
                    style={{ fontSize: 20, display: "inline-block" }}
                  >
                    Source - Destination trips count
                  </div>
                  <div className="trips-export">
                    <SelectComponent idName="Chart22Pdf" data={source_destination}/>
                  </div>
                </Grid>

            
                  <div className="trip-pieChart">
                    {source_destination==="loading"&&<div className="barModal">
                      <Loading/>
                    </div>}
                  <div className="picker-con">
                    <div className="picker">
                    <YearRangePicker
                      settingState={setDates}
                      name="source_destination"
                      place="select"
                      wholeYears={dates}
                    />
                    </div>

                    <div className="or_seperator"> OR </div>
                    <div className="picker">
                    <YearRangePicker
                      settingState={setDates}
                      name="source_destination"
                      place="yearRange"
                      wholeYears={dates}
                    />
                    </div>
                  </div>
                  <div>
                    <Preference  settingState={setDates}
                      name="source_destination"/>
                  </div>
                    <div className="trips-btn-container">
                      <button
                        className="trips-btn"
                        onClick={() => {
                          showResultsSource_desti("source_destination");
                        }}
                      >
                        Show results
                      </button>
                    </div>
                    {source_destination&&(typeof source_destination!=='string' && source_destination.length > 0) ? (
                      <>
                    <div className="trip-piecharts">
                       <div className="left-label source-desti-label-left">No. of trips <Icon icon='tabler:arrow-right'/></div>
                    <div className="bottom-label source-desti-label-bottom"> {(!Array.isArray(dates['source_destination'].year)?`Year ${dates['source_destination'].year}`:`${dates['source_destination'].year[0]} to ${dates['source_destination'].year[1]}`) }<Icon icon='tabler:arrow-right'/></div>
                      <ResponsiveContainer width="100%" height={300}>
                        <BarChart
                          width={730}
                          height={400}
                          data={source_destination}
                        >
                          <XAxis
                            dataKey="name"
                            tick={{ fontSize: 10,strokeWidth: 0,color:'#6c6c6c' }}
                            scale="point"
                          />
                          <YAxis
                           tick={{ fontSize: 10,color:'#6c6c6c'}}
                           scale={"linear"}
                           domain={[0, 'dataMax + 25']} 
                          />
                          <Bar dataKey="value" fill="#5aaffc" 
                          />
                        </BarChart>
                      </ResponsiveContainer>
                      </div>
                      {source_destination && source_destination.length > 0&&<p style={{ fontSize: 21, fontWeight: "bold" }}>
                    { ` Trips between ${splittingData(dates['source_destination'].source)} - ${splittingData(dates['source_destination'].destination)}`}
                    </p>}

                    </>)
                    :(source_destination!=="loading"?<div className="noResult">{source_destination}</div>:null)}
                  </div>
         
              </Grid>
       
    
              <Grid container style={{ display: "block", padding: 46 }} id="Chart32Pdf">
                <Grid item className="trip-heading">
                  <div
                    className="admindashboard-chart-heading"
                    style={{ fontSize: 20, display: "inline-block" }}
                  >
                    User type Comparision
                  </div>
                  <div className="trips-export">
                    <SelectComponent idName="Chart32Pdf" data={userType}/>
                  </div>
                </Grid>

             
                  <div className="trip-pieChart">
                  {userType==="loading"&&<div className="barModal">
                      <Loading/>
                    </div>}

                    <div className="picker-con">
                      <YearRangePicker
                        settingState={setDates}
                        name="usertype"
                        place="select"
                        wholeYears={dates}
                      />
                      <p className="or_seperator"> OR </p>
                      <YearRangePicker
                      settingState={setDates}
                      name="usertype"
                      place="yearRange"
                      wholeYears={dates}
                    />
                    </div>
                    <div className="trips-btn-container">
                      <button
                        className="trips-btn"
                        onClick={() => {
                          showResults("usertype");
                        }}
                      >
                        Show results
                      </button>
                    </div>

                  { userType&&(typeof userType!=="string"&&userType.length)? <div className="trip-piecharts userType-design">
                  <div className="left-label">No. of Users   <Icon icon='tabler:arrow-right'/></div>
                    <div className="bottom-label"> {(!Array.isArray(dates['usertype'])?`Year ${dates['usertype']}`:`Years ${dates['usertype'][0]} to ${dates['usertype'][1]}`) }<Icon icon='tabler:arrow-right'/></div>

                      <BarChart width={730} height={400} data={userType}>
                        <XAxis
                          dataKey="name"
                          tick={{ fontSize: 10,strokeWidth: 0,color:'#6c6c6c' }}
                          tickLine={false}
                          width={20}
                          scale="point"
                          padding={{ left: 40, right: 10 }}
                        />
                        <YAxis
                          tick={{ fontSize: 10,color:'#6c6c6c'}}
                          tickLine={false}
                          scale={"linear"}
                          domain={[0, 'dataMax + 25']} 
                          // ticks={[0,25,50,75,100]}
                        />
                        <Tooltip content={<CustomTooltips/>}/>
                        <Legend 
                        align="left" 
                        margin={ {top: 20, left: 20, right: 0, bottom: 0} } 
                        className="userType-legend" />
                        <Bar
                          dataKey="self"
                          fill="#0263ff"
                          maxBarSize={15}
                          margin={{ right: 0, left: 0 }}
                          legendType='square'
                          onMouseOver={(props)=>{
                            console.log(props.tooltipPayload,'tooltipProps')
                            let data=props.tooltipPayload[0]
                              setTooltipContent({
                                Key:"Gender",
                                Male:data.payload.Self_f,
                                Female:data.payload.Self_m
                              })
                        }}                         

                        >
                        <LabelList dataKey="self"  position="top" />
                          </Bar>
                        <Bar
                          dataKey="onbehalf"
                          fill="#608312"
                          margin={{ right: 0, left: 0 }}
                          maxBarSize={15}
                          legendType='square'
                          onMouseOver={(props)=>{
                            console.log(props.tooltipPayload,'tooltipProps')
                            let data=props.tooltipPayload[0]
                              setTooltipContent({
                                Key:"Gender",
                                Male:data.payload.Onbehalf_f,
                                Female:data.payload.Onbehalf_m
                              })
                        }}    
                        >
                          <LabelList dataKey="onbehalf"  position="top" />
                          </Bar>
                      </BarChart>
                    </div>
                    :(userType!=="loading"?<div className="noResult">{userType}</div>:null)}
                  </div>
            
              </Grid>
              
              <Grid container style={{ display: "block", padding: 46 }} id="Chart42Pdf">
                <Grid item className="trip-heading">
                  <div
                    className="admindashboard-chart-heading"
                    style={{ fontSize: 20, display: "inline-block" }}
                  >
                    Age group wise comparision
                  </div>
                  <div className="trips-export">
                    <SelectComponent idName="Chart42Pdf" data={agewise}/>
                  </div>
                </Grid>

              
                  <div className="trip-pieChart">
                  {agewise==="loading"&&<div className="barModal">
                      <Loading/>
                    </div>}
                    <div className="picker-con">
                      <YearRangePicker
                        settingState={setDates}
                        name="agewise"
                        place="select"
                        wholeYears={dates}
                      />
                      <p className="or_seperator"> OR </p>
                      <YearRangePicker
                      settingState={setDates}
                      name="agewise"
                      place="yearRange"
                      wholeYears={dates}
                    />
                    </div>
                    <div className="trips-btn-container">
                      <button
                        className="trips-btn"
                        onClick={() => {
                          showResults("agewise");
                        }}
                      >
                        Show results
                      </button>
                    </div>
                    {agewise&&(typeof agewise!=="string"&&agewise.length)? (
                    <div className="trip-piecharts">
                                          <div className="left-label">Age Group  <Icon icon='tabler:arrow-right'/></div>
                    <div className="bottom-label agewise-label">{` No of Trips (year ${dates['agewise']})`} <Icon icon='tabler:arrow-right'/></div>
                        <BarChart
      width={700}
      height={400}
      data={agewise}
      margin={{
        top: 30,
        right: 5,
        left: 5,
        bottom: 50
      }}
      barSize={20}
      layout="vertical"
    >
      <XAxis
        type="number"
        minTickGap={10}
        scale={"linear"}
        domain={[0, 'dataMax + 25']} 
        // ticks={[0,25,50,75,100]}
        tickFormatter={(value) => `${value}`}
        tickCount={5}
        tickLine={false} />
      <YAxis 
      dataKey="name" 
      minTickGap={10} 
      scale='point' 
      tick={{
         fontSize: 12,
         padding:{bottom:-100}
         }} 
      tickFormatter={(value) => `${value}`} 
      nameKey={"name"}  
      type="category" 
      padding={{ top: 15, right: 0 }}/>
      <Tooltip content={<CustomTooltips />} />
      <Bar 
      dataKey="value"
      onMouseOver={(props)=>{
        console.log(props.tooltipPayload,'tooltipPropsAgewise')
        let data=props.tooltipPayload[0]
          setTooltipContent({
            Key:"Gender",
            Male:data.payload.female,
            Female:data.payload.male
          })
    }}   
      formatter={(female, male,f_name,m_name, props)=>{
             return [female, f_name]
          }}>
                          {agewise.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              height={15}
                              fill={ageColor[index]}
                            />
                          ))}
                          <LabelList dataKey="value" position="right" class={'label-age'} fill='#787878' />
                        </Bar>
    </BarChart>
                    </div>)
                    :(agewise!=="loading"?<div className="noResult">{agewise}</div>:null)}
                  </div>
             
              </Grid>
    
        
              <Grid container style={{ display: "block", padding: 46 }} id="Chart52Pdf">
                <Grid item className="trip-heading">
                  <div
                    className="admindashboard-chart-heading"
                    style={{ fontSize: 20, display: "inline-block" }}
                  >
                    Gender wise Comparision
                  </div>
                  <div className="trips-export">
                    <SelectComponent idName="Chart52Pdf" data={genderwise}/>
                  </div>
                </Grid>

           
                  <div className="trip-pieChart">
                  {genderwise==="loading"&&<div className="barModal">
                      <Loading/>
                    </div>}
                    <div className="picker-con">
                      <YearRangePicker
                        settingState={setDates}
                        name="genderwise"
                        place="select"
                        wholeYears={dates}
                      />
                      <p className="or_seperator"> OR </p>
                      <YearRangePicker
                      settingState={setDates}
                      name="genderwise"
                      place="yearRange"
                      wholeYears={dates}
                    />
                    </div>
                    <div className="trips-btn-container">
                      <button
                        className="trips-btn"
                        onClick={() => {
                          showResults("genderwise");
                        }}
                      >
                        Show results
                      </button>
                    </div>
                    {genderwise&&(typeof genderwise!=='string'&&genderwise.length) ?(
                    <div className="trip-piecharts userType-design">
                     <div className="left-label">No. of Trips   <Icon icon='tabler:arrow-right'/></div>
                    <div className="bottom-label"> {(!Array.isArray(dates['genderwise'])?`Year ${dates['genderwise']}`:`Years ${dates['genderwise'][0]} to ${dates['genderwise'][1]}`) } <Icon icon='tabler:arrow-right'/></div>

                      <BarChart 
                      width={730} 
                      height={400} 
                      data={genderwise}
                      barGap={"-25%"}                      >
                        <XAxis
                          dataKey="name"
                            padding={{ left: 40, right: 10 }}
                          scale="point"
                          tick={{ fontSize: 10,strokeWidth: 0,color:'#6c6c6c' }}
                        />
                        <YAxis 
                        domain={[0, 'dataMax + 25']} 
                        minTickGap={25}
                        scale={"linear"}
                        tick={{ fontSize: 10,strokeWidth: 0,color:'#6c6c6c' }}
                        // ticks={[0,25,50,75,100]}
                        />
                        <Tooltip content={<CustomTooltips/>}/>
                        <Legend 
                        align="left"
                        padding={ {top: 20, left: 50, right: 0, bottom: 0} } 
                        className="userType-legend"
                        iconType="square"
                         />
                        <Bar 
                        dataKey="Male" 
                        fill="#8388bf" 
                        margin={{left:0}}
                        maxBarSize={15}
                        background={false }
                        onMouseOver={(props)=>{
                              console.log(props.tooltipPayload,'tooltipGenderwise')
                              let data=props.tooltipPayload[0]
                              let obj={key:"Age group"}
                              data.payload.data_Male.map((age)=>{
                                 if(age.age_group_count){
                                  obj[age.age_group]=age.age_group_count
                                 }
                              })
                              setTooltipContent(obj)

                          }} 
                        >
                        <LabelList dataKey="Male"  position="top" />
                          </Bar>
                        <Bar 
                        dataKey="Female" 
                        fill="#1daa4f" 
                        maxBarSize={15}
                        margin={{right:0}}
                        onMouseOver={(props)=>{
                          let data=props.tooltipPayload[0]
                          let obj={key:"Age group"}
                          data.payload.data_Female.map((age)=>{
                             if(age.age_group_count){
                              obj[age.age_group]=age.age_group_count
                             }
                          })
                          setTooltipContent(obj)

                      }} 
                        >
                        <LabelList dataKey="Female"  position="top" />
                        </Bar>
                      </BarChart>
                    </div>)
                    :(genderwise!=="loading"?<div className="noResult">{genderwise}</div>:null)}
                  </div>
         
              </Grid>
           

          
              <Grid container style={{ display: "block", padding: 46 }} id="Chart62Pdf">
                <Grid item className="trip-heading">
                  <div
                    className="admindashboard-chart-heading"
                    style={{ fontSize: 20, display: "inline-block" }}
                  >
                    Monthly and Yearly growth range
                  </div>
                  <div className="trips-export">
                    <SelectComponent idName="Chart62Pdf" data={yearly}/>
                  </div>
                </Grid>

            
                  <div className="trip-pieChart">
                  {yearly==="loading"&&<div className="barModal">
                      <Loading/>
                    </div>}
                    <div className="picker-con">
                      <YearRangePicker
                        settingState={setDates}
                        name="yearly_monthlywise"
                        place="select"
                        wholeYears={dates}
                      />
                      <p className="or_seperator"> OR </p>
                      <YearRangePicker
                      settingState={setDates}
                      name="yearly_monthlywise"
                      place="yearRange"
                      wholeYears={dates}
                    />
                    </div>
                    <div className="trips-btn-container">
                      <button
                        className="trips-btn"
                        onClick={() => {
                          showResults("yearly_monthlywise");
                        }}
                      >
                        Show results
                      </button>
                    </div>
                    {yearly&&(typeof yearly!=="string"&&yearly.length)? (
                    <div className="trip-piecharts">
                      <div className="left-label">No. of Users   <Icon icon='tabler:arrow-right'/></div>
                    <div className="bottom-label-monthly"> {(!Array.isArray(dates['yearly_monthlywise'])?`Year ${dates['yearly_monthlywise']}`:`${dates['yearly_monthlywise'][0]} to ${dates['yearly_monthlywise'][0]}`) } <Icon icon='tabler:arrow-right'/></div>
                      <LineChart
                        width={730}
                        height={350}
                        data={yearly}
                        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
                      >
                        <XAxis 
                        dataKey="name"   
                        tick={{ fontSize: 10,strokeWidth: 0,color:'#6c6c6c' }}
                        />
                        <YAxis   
                        tick={{ fontSize: 10,strokeWidth: 0,color:'#6c6c6c' }}
                        scale={"linear"}
                        domain={[0, 'dataMax + 25']} 
                        />
                        {/* <Tooltip content={<YearlyCustomTooltips/>}/> */}
                        <Line 
                        type="linear" 
                        dataKey="value" 
                        stroke="#88b7f3"
                         />
                      </LineChart>
                    </div>)
                    :(yearly!=="loading"?<div className="noResult">{yearly}</div>:null)}
                  </div>
          
              </Grid>
          
          </div>
        </Container>
      </div>
    </>
  );
}
