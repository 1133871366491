import React, { useState, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { Icon } from "@iconify/react";
import { Grid } from "@mui/material";

const UploadImage = ({ dev,isEdit, pageId,name }) => {

    const [pro_status, setPro_status] = useState({
        desk: false,
        mobile: false,
      });
      const [isCancelled,setIscancelled]=useState({
        desk:false,
        mobile:false
      })
  const {
    register,
    getValues,
    setValue,
    formState: { errors },
  } = useFormContext();

  const values = getValues();
  const imageUploader = (id) => {
    const fileInput = document.getElementById(id);
    fileInput.click();
  };

  const deleteAd = (name) => {
    setValue(name, "");
  };

  function formatBytes(bytes, decimals = 0) {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return (
      Math.round(parseFloat((bytes / Math.pow(k, i)).toFixed(dm))) +
      " " +
      sizes[i]
    );
  }

  const setProgress = (device, value) => {
    let max = 100;
    let now = 0;
    let intervalCode = 0;
    const startTo = (step, time) => {
      if (intervalCode !== 0) return;
      intervalCode = setInterval(() => {
        console.log("sss");
        if (now + step > max) {
          now = max;
          syncState();
          clearInterval(intervalCode);
          intervalCode = 0;
          return;
        }
        now += step;
        syncState();
      }, time);
    };
    const syncState = () => {
      let elem = document.querySelector(`.${device}-progress`);
      console.log(elem, "elem");
      if (elem) elem.style.width = now + "%";
    };

    const end = (progress_status) => {
      now = max;
      clearInterval(intervalCode);
      intervalCode = 0;
      syncState();
      if (progress_status)
        setPro_status((pre) => {
          return {
            ...pre,
            [device]: true,
          };
        });
      else
        setPro_status((pre) => {
          return {
            ...pre,
            [device]: false,
          };
        });
    };
    if (value) {
        console.log(value,"end().")
      startTo(5, 500);
      setTimeout(() => {
        end(true);
      }, 5000);
    } else {
        console.log(value,"end()")
        setTimeout(() => {
            end(false);
          }, 5000);
    }
  };
  useEffect(() => {
    setProgress(dev, values[name]);
    console.log(values?.desktop_ad,"values?.desktop_ad")
  }, [values[name]]);

 

  return (
    <>
      <Grid>
        <Grid className="ads-manage-create-upload-ads">
          {values[name] && pageId !== "new" ? (
            <div className="ads-manage-create-desktop-img">
              {isEdit && (
                <div className="ads-management-ads-cancel-btn">
                  <Icon
                    icon="icons8:cancel"
                    onClick={() => deleteAd(name)}
                  />
                </div>
              )}

              <img
                className={
                  isEdit
                    ? "ads-manage-without-disabled"
                    : "ads-manage-with-disabled"
                }
                src={
                  typeof values[name]=== "object"
                    ? URL.createObjectURL(values[name])
                    : values[name]
                }
                {...register(name)}
                error={errors[name] ? true : false}
              ></img>
            </div>
          ) : (
            <div className="ads-manage-create-loading-con">
              <div className="ads-mange-create-upload-main-div bordered largeGaps">
                <div
                  onClick={() => {
                    imageUploader(`upload_ads_${name}`);
                  }}
                >
                  <Icon
                    className="ads-manage-create-image-add-icon"
                    icon="mdi:image-add"
                  />
                  <div className="ads-manage-click-image">
                    Click here to add Image
                  </div>
                  <div className="ads-manage-drap-drop"> or drag and drop</div>
                  <input
                    accept="image/png, image/jpeg, image/webp"
                    disabled={!isEdit}
                    onChange={(e) => {
                      setValue(name, e.target.files[0], {
                        shouldValidate: true,
                      });
                    }}
                    id={`upload_ads_${name}`}
                    type="file"
                    hidden={true}
                  ></input>
                </div>
              </div>
              {values[name] && (
                <div className="progress-con">
                  {!pro_status[dev] && (
                    <div
                      className="cancel-div"
                      onClick={() => {
                        setValue(name,undefined,{shouldValidate:true});
                      }}
                    >
                      Cancel
                    </div>
                  )}
                  <div class="progress">
                    <div className="file-name-con">
                      <div className="file-icon">
                        <Icon icon={"bxs:file-pdf"} />
                      </div>
                      <div className="file-text">
                        <div className="textDisplay">
                          {values[name]?.name}
                        </div>
                        <div className="textDisplay">
                          {formatBytes(values[name].size)}
                        </div>
                      </div>
                    </div>
                    {!pro_status[dev] ? (
                      <div class={`progress-value ${dev}-progress`}></div>
                    ) : (
                      <div className="delete-file-icon">
                        <Icon
                          icon={"material-symbols:cancel-outline-rounded"}
                          onClick={()=>{
                            setIscancelled((pre)=>{
                                return {
                                    ...pre,
                                    [dev]:true
                                }
                            })
                            setValue(name,"",{shouldValidate:true})
                          }}
                        />
                      </div>
                    )}
                  </div>
                </div>
              )}
            </div>
          )}
        </Grid>
        <p className="ads-management-error-message">
          {errors[name] && errors[name].message}
        </p>
      </Grid>
    </>
  );
};

export default UploadImage;
