import React from 'react'
// import sample from "../Images/Capture.PNG"

const StepCircle = (props) => {
  const {imageFile}=props
    let count="1"
  return (
    <div style={{
      height:"7rem",
      width:"7rem",
      borderRadius:"50%",
      backgroundColor:"#1ec28b",
      display:"flex",
      alignItems:"center",
      justifyContent:"center"
      }}>
         <div style={{height:"6rem",width:"6rem",borderRadius:"50%",backgroundColor:"#1ec28b"}}>

<img style={{height:"100%",width:"100%",borderRadius:"50%"}} src={typeof imageFile==="object"?URL.createObjectURL(imageFile):imageFile}/>
    </div>
    </div>
 
  )
}
export default StepCircle