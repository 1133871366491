const isMobile=()=>{
    let flag=false
    const array=(navigator.userAgent).split(" ")
    if(array.includes("Mobile")){
      flag=true
    }

    array.map((str)=>{
      if(str.indexOf("M")!==-1){
        if(str.substring(str.indexOf("M"),6)==="Mobile")
           flag=true
            console.log(str,"userAgent")
        }
     })

    return flag
   }

   export default isMobile