import * as Yup from "yup";

// var schema = Yup.object().shape({});
const englishchar = /[A-Za-z]/; 
const validation = (step) => {
  switch (step) {
    case 0:
      var schema = Yup.object().shape({
        category:Yup.string().required("Category is required"),
        user_type:Yup.string().required("User Type is required"),
        name:Yup.string().required("Traveler Name is required").matches(englishchar, "Only English characters allowed")
        .min(3,"Trip Name should contains minimum 5 characters")
        .max(40,"More than 40 characters not allowed"),
        age_group: Yup.string().required("Age Group is required"),
        gender: Yup.string().required("Gender is required"),
        tip_expected: Yup.string().when("category", {
          is: "1",
          then: Yup.string().required("Tip Expected is reqiured"),
        }),
      });
      break;
    case 1:
      var schema = Yup.object().shape({
        booking_status: Yup.string().required("Ticket Booking Status is required"),
        date_range_from: Yup.string()
        .when('booking_status', (booking_status, schema) => {
          console.log(booking_status,"booking_status...")
          if (booking_status==="2"||booking_status==="") return schema.required('From Date is reqiured');
        })
          .nullable(),
        date_range_to: Yup.string()
        .when('booking_status', (booking_status, schema) => {
          console.log(booking_status,"booking_status...")
          if (booking_status==="2"||booking_status==="") return schema.required('From Date is reqiured');
        })
          .nullable(),
          airline: Yup.object()
          .when("booking_status", {
            is: "1",
            then: Yup.object().required("Airline is reqiured").nullable(),
          }).nullable(),
        flying_from: Yup.object().required("Flying From is reqiured").nullable(),
        destination: Yup.object().required("Destination is  reqiured").nullable(),
        prefered_language: Yup.array().min(1, "Preferred Language is required").nullable(),
        departing_on: Yup.string()
          .when("booking_status", {
            is: "1",
            then: Yup.string().required("Departing on is reqiured").nullable(),
          })
          .nullable(),
        airAndTrans: Yup.array().when("booking_status", {
          is: "1",
          then: Yup.array()
            .of(
              Yup.object().shape({
                transit: Yup.object().required("Transit is required").nullable(),
                airline: Yup.object().required("Airline is required").nullable(),
              })
            )
            .max(4, "Maximum limit is 3")
            .min(0)
        }),
      });

      break;
    case 2:
      var schema = Yup.object().shape({
        trip_name: Yup.string().required("Trip Name is required")
        .min(5,"Trip Name should contains minimum 5 characters")
        .max(40,"More than 40 characters not allowed")
        .matches(englishchar, "Only English characters allowed"),
        
        assistance_needed: Yup.string().when("category", {
          is: "2",
          then: Yup.string().required("Assistance Needed required")
          .min(5,"Assistance Needed should contains minimum 5 characters")
          .max(90,"")
          .matches(englishchar, "Only English characters allowed")
          
        }),

        short_description: Yup.string().required("Short Description is required")
        .min(5,"Short Description should contains minimum 5 characters")
        .max(500,"")
        .matches(englishchar, "only English characters allowed"),
      });
      break;
    case 3:
      break;
  }

  // console.log('schema', schema)
  return schema;
};

export default validation;