import {
  ADMIN_LOGIN_SUCCESS,
  ADMIN_LOGIN_FAIL,
  ADMIN_LOGOUT,
  ADMIN_SET_MESSAGE,
  ADMIN_CLEAR_MESSAGE
  } from "./types";
  import AuthService from "../services/auth.service";
import { clearMessage } from "./messege";
 
  export const login = (username, password) => (dispatch) => {
    console.log("login in actions auth")
    return AuthService.login(username, password).then(
      (data) => {
        dispatch({
          type: ADMIN_LOGIN_SUCCESS,
          payload: { admin: data },
        });
        dispatch({
          type: ADMIN_CLEAR_MESSAGE,
          // payload: message,
        });
        return Promise.resolve();
      },
      (error) => {
        const message =
          (error.response &&
            error.response.data &&
            error.response.data.message) ||
          error.message ||
          error.toString();
        dispatch({
          type: ADMIN_LOGIN_FAIL,
        });
        dispatch({
          type: ADMIN_SET_MESSAGE,
          payload: message,
        });
        return Promise.reject();
      }
    ).catch(error => {
      console.log(error)
      // Handle the error here
    });
  };
  export const logout = () => (dispatch) => {
    AuthService.logout();
    dispatch({
      type: ADMIN_LOGOUT,
    });
  };






 