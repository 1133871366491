import React, {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import {Icon} from "@iconify/react";
import "../../CSS/dashboardpage.css";
import "./ProfileEdit.css";
import {
  Autocomplete,
  Breadcrumbs,
  Button,
  Container,
  FormControl,
  Grid,
  IconButton,
  MenuItem,
  Modal,
  Select,
  TextField,
  Typography,
} from "@mui/material";
// import SidebarLink from "../SidebarLink";
// import EditProfile from "../EditProfile";
// import DashboardHeader from "../DashboardHeader";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import {Formik, Form} from "formik";
import * as Yup from "yup";
// import CountryJson from "../../json/Country-State-City.json";
import {PhotoCamera} from "@mui/icons-material";
import axios from "axios";
import {useSelector, useDispatch} from "react-redux";
import {Link, useLocation} from "react-router-dom";
import {login, glogin} from "../../actions/auth";
// import GSingIn_PopUp from "../GSingIn_PopUp";
import {useRef} from "react";
import CloseIcon from "@mui/icons-material/Close";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import AdminHeader from "../Components/AdminHeader";
import Adminsidebar from "../Adminsidebar";
import InsertPhotoOutlinedIcon from "@mui/icons-material/InsertPhotoOutlined";
const MyProfile = () => {
  const [age, setAge] = React.useState("");
  const [phonecode, setPhonecode] = React.useState("");
  const {isadminLoggedIn, admin} = useSelector(state => state.admin_auth);

  // const { isadminLoggedIn, user } = useSelector((state) => state.auth);
  // const userId = admin.id;
  // console.log("admin_id", admin.user_id);
  const dispatch = useDispatch();
  const formikRef = useRef();
  const [profileData, setProfileData] = useState({});
  const [change, setchange] = useState(0);
  const [initial_values, setInitial_values] = useState({});
  const [file, setFile] = useState(null);
  const [responseMessage, setResponseMessage] = useState(null);
  const [responseMessageError, setResponseMessageError] = useState(null);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [deletePic, setDeletePic] = React.useState();

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const englishchar = /[A-Za-z]/;
  const onlyenglishchar = /^[A-Za-z ]*$/;
  const englishcharacterwithspace = /^([a-zA-Z]+\s)*[a-zA-Z]+$/;

  const spaces = /^\S*$/;
  const spaces1 = /^[^\s]+(\s+[^\s]+)*$/;

  const validationSchema = Yup.object({
    full_name: Yup.string()
      .min(2, "Too Short!")
      .max(50, "Too Long!")
      .required("Name is required")
      .matches(onlyenglishchar, "only English characters allowed")
      .matches(
        spaces1,
        "Space is not accepted at  starting and ending of the characters"
      ),

    // gender: Yup.string().required("Required"),
    // age_group: Yup.string().required("Required"),
    // country: Yup.string().required("Required"),
    // state: Yup.string().required("Required"),
    country_code: Yup.string()
      .nullable()
      // .matches(phoneRegExp, "Phone number is not valid")
      // .min(4, "Mobile Number must be atleast 4 characters")
      // .max(12, "Mobile Number must be atleast 12 characters")
      .required("Country code is required"),
    mobile_number: Yup.string()
      .nullable()
      .matches(phoneRegExp, "Phone number is not valid")
      .matches(spaces, "Phone number is not valid,Spaces are not allowed")
      .min(4, "Mobile Number must be atleast 4 characters")
      .max(12, "Mobile Number must be atleast 12 characters")
      .required("Mobile number is required"),
    email: Yup.string()
      .email("Invalid email")
      .required("Email is required")
      .matches(
        /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9]){1}?$/,
        "Invalid Email"
      ),
    about: Yup.string().matches(englishchar, "only English characters allowed"),
  });

  // const formik = useFormik({

  //   initialValues:

  //   final_initialvalues
  //   ,
  //   validationSchema: validationSchema,
  //   onSubmit: (values) => {
  //     // alert(values);
  //     // const beginDate = moment(values.FromDate).format('YYYY-MM-DD')
  //     // console.log(beginDate);
  //   //   const mydate=new Date(values.FromDate)
  //   //   const thisdate=mydate.getDate()+'-'+(mydate.getMonth()+1)+'-'+mydate.getFullYear()
  //   //   console.log(thisdate)
  //     console.log(values,"edit submit data")
  //     // console.log(JSON.stringify(values))
  //     // dispatch(login(values.email, values.password))
  //   },
  // });
  // console.log("country state", CountryJson);
  // const mycountry = CountryJson;

  const getProfiledata = () => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .get(`${domain}/user/profile/${admin ? admin.id : ""}/`)
      .then(response => {
        console.log(response, "profile response");

        if (response.data) {
          console.log(response.data, "profile response data in edit profile");
          setProfileData(response.data[0]);
          setInitial_values(response.data[0]);
          // formik.setFieldValue("Name",profileData.full_name)
        }
      })
      .catch(error => {
        // Error
        if (error.response.status === 400) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data);
          console.log(error.response.status);
          console.log(error.response.headers);
        }
      });
  };
  // const handleChange=()=>{
  //   formik.setFieldValue('Name',profileData.full_name)
  // }
  useEffect(() => {
    getProfiledata();
    // console.log(deletePic, "deletePic");
    // console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    // let domain = "";
    // console.log(process.env, "domain env", window.location.hostname);
    // if (window.location.hostname.trim() === "localhost") {
    //   domain = process.env.REACT_APP_LOCAL_API;
    // } else if (window.location.hostname.trim() === "54.185.6.32") {
    //   domain = process.env.REACT_APP_UAT_API;
    // } else if (
    //   window.location.hostname.trim() === "uat.desitravelcompanion.com"
    // ) {
    //   domain = process.env.REACT_APP_PROD_UAT_API;
    // } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
    //   domain = process.env.REACT_APP_PROD_API;
    // } else if (
    //   window.location.hostname.trim() === "www.desitravelcompanion.com"
    // ) {
    //   domain = process.env.REACT_APP_PROD_API;
    // }
    // console.log(domain, "domain env end");

    // axios
    //   .get(`${domain}/user/profile/${user.user_id}/`)
    //   .then((response) => {
    //     console.log(response, "profile response");
    //     if (response.data) {
    //       console.log(response.data, "profile response data in edit profile");
    //       setProfileData(response.data[0]);
    //       setInitial_values(response.data[0]);
    //       // formik.setFieldValue("Name",profileData.full_name)
    //     }
    //   })
    //   .catch((error) => {
    //     // Error
    //     if (error.response.status === 400) {
    //       // The request was made and the server responded with a status code
    //       // that falls out of the range of 2xx
    //       console.log(error.response.data);
    //       console.log(error.response.status);
    //       console.log(error.response.headers);
    //     }
    //   });

    // formik.setFieldValue("Name",profileData.full_name)
  }, []);

  // console.log(formik, "form values");
  const onSubmit = values => {
    console.log(values, "values");
    console.log(file, "image data");
    const formData = new FormData();
    formData.append("profile_picture", file);
    formData.append("full_name", values.full_name);
    // formData.append("age_group", values.age_group);
    // formData.append("gender", values.gender);
    // formData.append("country", values.country);
    formData.append("mobile_number", values.mobile_number);
    formData.append("country_code", values.country_code);
    // formData.append("state", values.state);
    formData.append("email", values.email);
    formData.append("about", values.about);
    //  formData.append("_method","PATCH");

    console.log(formData, "formdata");

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .patch(
        `${domain}/user/profile/${admin.id}/`,
        //   {
        //     profile_picture: {formData},
        //     full_name: values.full_name,
        //     age_group: values.age_group,
        //     gender:values.gender,
        //     country: values.country,
        //     mobile_number: values.mobile_number,
        //     country_code: values.country_code,
        //     state: values.state,
        //     email_id: values.email_id,
        //     about:values.about
        // }
        file != null
          ? formData
          : {
              full_name: values.full_name,
              // age_group: values.age_group,
              // gender: values.gender,
              // country: values.country,
              mobile_number: values.mobile_number,
              country_code: values.country_code,
              // state: values.state,
              email: values.email,
              about: values.about,
            }

        // ,{header:{
        //   "Content-Type":"multipart/form-data"
        // }}
      )
      .then(response => {
        console.log(response, "update profile response");

        if (response.data) {
          console.log(response.data, " response update profile");
          //  setProfileData(response.data[0]);
          //  setInitial_values(response.data[0]);
          // formik.setFieldValue("Name",profileData.full_name)

          setResponseMessage(response.data.message);
          setResponseMessageError(null);
          localStorage.setItem("user", JSON.stringify(response.data));
          dispatch(glogin(response.data));
        }
      })
      .catch(error => {
        // Error
        if (error.response.status === 400) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data, "error");
          console.log(error.response.status);
          console.log(error.response.headers);
          setResponseMessageError(error.response.data.mobile_number);
          setResponseMessage(null);
        }
      });
  };

  const {state} = useLocation();
  const [inputValue, setInputValue] = React.useState();
  const [country_search_response, setCountries] = useState();
  const [mystates, setmystates] = useState([]);

  const countrystatepincode = () => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .get(`${domain}/country-code/`)
      .then(res => {
        console.log(res, "c response");
        if (res.data.response) {
          setCountries(res.data.response);
        }
      })
      .catch(error => {
        // Error
      });
  };

  useEffect(() => {
    // document.title =  "Desi Travel Companion-signup";
    countrystatepincode();
  }, [inputValue]);

  const deletePost = () => {
    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .delete(`${domain}/user/profile/${admin.id}/`)
      .then(response => {
        setDeletePic("2");
        console.log(response.data, "delete pic response");
        // getProfiledata();
        if (response.data) {
          setDeletePic("2");
          setOpen(false);
          getProfiledata();
          window.location.reload();
          // setDeletePic("2");
        }
      })
      .catch(error => {
        // Error
        console.log();
      });
  };
  // useEffect (()=>{
  //   deletePost();
  // },[]);

  const uploadpicture = () => {
    const formData = new FormData();
    formData.append("profile_picture", file);

    console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
    let domain = "";
    console.log(process.env, "domain env", window.location.hostname);
    if (window.location.hostname.trim() === "localhost") {
      domain = process.env.REACT_APP_LOCAL_API;
    } else if (window.location.hostname.trim() === "54.185.6.32") {
      domain = process.env.REACT_APP_UAT_API;
    } else if (
      window.location.hostname.trim() === "uat.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_UAT_API;
    } else if (window.location.hostname.trim() === "desitravelcompanion.com") {
      domain = process.env.REACT_APP_PROD_API;
    } else if (
      window.location.hostname.trim() === "www.desitravelcompanion.com"
    ) {
      domain = process.env.REACT_APP_PROD_API;
    }
    console.log(domain, "domain env end");

    axios
      .patch(
        `${domain}/user/profile/${admin.id}/`,
        formData

        // ,{header:{
        //   "Content-Type":"multipart/form-data"
        // }}
      )
      .then(response => {
        console.log(response, "update update profile picture response");
        // setOpen(false);
        // getProfiledata();
        if (response.data) {
          console.log(response.data, " response update profile picture");
          //  setProfileData(response.data[0]);
          //  setInitial_values(response.data[0]);
          // formik.setFieldValue("Name",profileData.full_name)

          // setResponseMessage(response.data.message);
          // setResponseMessageError(null);
          // localStorage.setItem("user", JSON.stringify(response.data));
          // dispatch(glogin(response.data));
          setOpen(false);
          window.location.reload();
        }
      })
      .catch(error => {
        // Error
        if (error.response.status === 400) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          console.log(error.response.data, "error");
          console.log(error.response.status);
          console.log(error.response.headers);
          // setResponseMessageError(error.response.data.mobile_number);
          // setResponseMessage(null);
        }
      });
  };

  const [editmode, setEditmode] = useState(false);

  ////latest 1/29/2024 bug fixed
  const handleChange = e => {
    // console.log("e","input valueeee")
    setResponseMessage(null);
    setResponseMessageError(null);
  };
  ////latest 1/29/2024 bug fixed //for this added oninput change event for 3 password fields

  return (
    <>
      <AdminHeader></AdminHeader>
      <div className="dbsql-profile-main">
        <div style={{borderRight: "groove", minHeight: "90vh"}}>
          <Adminsidebar />
        </div>
        <Container style={{backgroundColor: "whitesmoke"}}>
          <div className="admindashboard-heading">Settings</div>
          <Breadcrumbs
            separator="›"
            aria-label="breadcrumb"
            className="ads-management-subhead"
          >
            {/* {breadcrumbs} */}
            <Link to={{}} className="ads-manage-home">
              Home
            </Link>
            <Typography className="ads-management-subheading">
              My Profile
            </Typography>
          </Breadcrumbs>
          {/* <Container> */}
          <div className="dashboard-main">
            <div className="profile-edit-main-div">
              {Object.entries(initial_values).length > 0 ? (
                <Formik
                  innerRef={formikRef}
                  initialValues={initial_values}
                  validationSchema={validationSchema}
                  enableReinitialize={true}
                  onSubmit={onSubmit}
                >
                  {formik => {
                    console.log("formik val", formik);
                    const countrycode = () => {
                      country_search_response.map(item => {
                        // console.log("country")

                        if (item.name === formik.values.country) {
                          //  setPhonecode(item.phone_code)
                          formik.setFieldValue("country_code", item.phone_code);
                          setmystates(item.states);
                          // formik.setFieldValue("state", "");

                          return true;
                        }
                      });
                    };

                    return (
                      <Form onSubmit={formik.handleSubmit}>
                        {/* <div style={{display:'flex'}}> */}
                        <div
                          // className="profileEditbox"
                          style={{
                            backgroundColor: "white",
                            width: "100%",
                            borderRadius: "5px",
                          }}
                        >
                          <Container>
                            <div
                              style={{
                                display: "flex",
                                paddingTop: "70px",
                                paddingBottom: "35px",
                              }}
                            >
                              <h2 className="myProfile-Heading-text" style={{}}>
                                My Profile
                              </h2>

                              <div
                                // className="ads-manage-edit-main-div"
                                style={{
                                  marginRight: "15px",
                                  marginLeft: "760px",
                                  marginTop: "15px",
                                }}
                              >
                                <Button
                                  className={
                                    editmode
                                      ? "profile-edit-btn-disabled "
                                      : "ads-manage-edit-btn"
                                  }
                                  style={{
                                    alignItems: "center",
                                  }}
                                  disabled={editmode}
                                  onClick={() => {
                                    setEditmode(true);
                                  }}
                                >
                                  <span>
                                    <Icon
                                      icon="ic:baseline-mode-edit"
                                      className={
                                        editmode
                                          ? "profile-edit-icon-disabled "
                                          : "ads-manage-edit-icon"
                                      }
                                      // className="ads-manage-edit-icon"
                                    />
                                  </span>

                                  <span
                                    style={{
                                      textTransform: "lowercase",
                                      fontSize: 16,
                                    }}
                                  >
                                    <span style={{textTransform: "capitalize"}}>
                                      E
                                    </span>
                                    dit
                                  </span>
                                </Button>
                              </div>
                            </div>
                            {/* <h3 className="myProfile-Heading-text">
                            edit profile
                          </h3> */}
                          </Container>
                          {/* <div style={{float:"right",marginRight: "15px"}}> */}

                          {/* </div> */}

                          <div style={{display: "flex"}}>
                            <Container style={{textAlign: "initial"}}>
                              <Grid container spacing={2}>
                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <label>
                                    Name
                                    <span style={{color: "#ff8d8d"}}>*</span>
                                  </label>
                                  <div>
                                    <TextField
                                      className="profile-edit-name"
                                      inputProps={{
                                        maxLength: 32,
                                      }}
                                      style={{borderColor: "#1ec28b"}}
                                      sx={{
                                        "&.MuiOutlinedInput-root": {
                                          "& fieldset": {
                                            borderColor: "#1ec28b",
                                          },
                                          "&:hover fieldset": {
                                            borderColor: "#1ec28b",
                                          },
                                          "&.Mui-focused fieldset": {
                                            borderColor: "#1ec28b",
                                          },
                                        },
                                      }}
                                      // defaultValue={formik.setFieldValue("Name",profileData.full_name)}
                                      name="full_name"
                                      margin="normal"
                                      onInput={handleChange}
                                      onBlur={formik.handleBlur}
                                      touched={formik.touched}
                                      value={formik.values.full_name}
                                      onChange={formik.handleChange}
                                      error={
                                        formik.touched.full_name &&
                                        Boolean(formik.errors.full_name)
                                      }
                                      helperText={
                                        formik.touched.full_name &&
                                        formik.errors.full_name
                                      }
                                      disabled={!editmode}
                                    />
                                  </div>
                                  <div
                                    id="errormessage"
                                    className="emailIdError"
                                  ></div>
                                </Grid>
                                {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <label>
                              First Name
                                <span style={{ color: "#ff8d8d" }}>*</span>
                              </label>
                              <div>
                                <TextField
                                  className="profile-edit-name"
                                  inputProps={{
                                    maxLength: 32,
                                  }}
                                  style={{ borderColor: "#1ec28b" }}
                                  sx={{
                                    "&.MuiOutlinedInput-root": {
                                      "& fieldset": {
                                        borderColor: "#1ec28b",
                                      },
                                      "&:hover fieldset": {
                                        borderColor: "#1ec28b",
                                      },
                                      "&.Mui-focused fieldset": {
                                        borderColor: "#1ec28b",
                                      },
                                    },
                                  }}
                                  // defaultValue={formik.setFieldValue("Name",profileData.full_name)}
                                  name="full_name"
                                  margin="normal"
                                  onBlur={formik.handleBlur}
                                  touched={formik.touched}
                                  value={formik.values.full_name}
                                  onChange={formik.handleChange}
                                  error={
                                    formik.touched.full_name &&
                                    Boolean(formik.errors.full_name)
                                  }
                                  helperText={
                                    formik.touched.full_name &&
                                    formik.errors.full_name
                                  }
                                />
                              </div>
                              <div
                                id="errormessage"
                                className="emailIdError"
                              ></div>
                            </Grid> */}
                                {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <label>
                                Age Group{" "}
                                <span style={{ color: "#ff8d8d" }}>*</span>
                              </label>
                              <Select
                                // label=" Select a Gender"
                                className="profile-edit-age-group-input"
                                name="age_group"
                                onBlur={formik.handleBlur}
                                touched={formik.touched}
                                value={formik.values.age_group}
                                onChange={formik.handleChange}
                                error={
                                  formik.touched.age_group &&
                                  Boolean(formik.errors.age_group)
                                }
                                helperText={
                                  formik.touched.age_group &&
                                  formik.errors.age_group
                                }
                              >
                                <MenuItem value="" label="Select a Gender">
                                  Select Age Group{" "}
                                </MenuItem>
                                <MenuItem value="20-25" label="20-25">
                                  {" "}
                                  20-25
                                </MenuItem>
                                <MenuItem value="26-30" label="26-30">
                                  26-30
                                </MenuItem>
                                <MenuItem value="31-35" label="31-35">
                                  31-35
                                </MenuItem>
                                <MenuItem value="36-40" label="36-40">
                                  36-40
                                </MenuItem>
                                <MenuItem value="41-45" label="41-45">
                                  41-45
                                </MenuItem>
                                <MenuItem value="46-50" label="46-50">
                                  46-50
                                </MenuItem>
                                <MenuItem value="51-60" label="51-60">
                                  51-60
                                </MenuItem>
                                <MenuItem value="61-70" label="61-70">
                                  61-70
                                </MenuItem>
                              </Select>

                              <div
                                id="errormessage"
                                className=""
                                style={{ color: "red", fontSize: 12 }}
                              >
                                {formik.touched.age_group &&
                                  formik.errors.age_group}
                              </div>
                            </Grid> */}

                                {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <label>
                                Gender{" "}
                                <span style={{ color: "#ff8d8d" }}>*</span>
                              </label>
                              <div>
                                <FormControl className="profile-edit-gender-selectInput">
                                  <Select
                                    type="select"
                                    className="selectInput"
                                    name="gender"
                                    onBlur={formik.handleBlur}
                                    touched={formik.touched}
                                    value={formik.values.gender}
                                    onChange={formik.handleChange}
                                    error={
                                      formik.touched.Age_Group &&
                                      Boolean(formik.errors.gender)
                                    }
                                    helperText={
                                      formik.touched.gender &&
                                      formik.errors.gender
                                    }
                                  >
                                    <MenuItem value="">Select Gender</MenuItem>
                                    <MenuItem value="Male">Male</MenuItem>
                                    <MenuItem value="Female">Female</MenuItem>
                                    <MenuItem value="Others">Others</MenuItem>
                                  </Select>
                                </FormControl>
                              </div>
                              <div
                                id="errormessage"
                                className=""
                                style={{ color: "red", fontSize: 12 }}
                              >
                                {formik.touched.gender && formik.errors.gender}
                              </div>
                            </Grid> */}

                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <label>
                                    Email ID
                                    <span style={{color: "#ff8d8d"}}>*</span>
                                  </label>
                                  <div>
                                    <TextField
                                      className="profile-edit-email"
                                      type="text"
                                      name="email"
                                      margin="normal"
                                      onInput={handleChange}
                                      onBlur={formik.handleBlur}
                                      touched={formik.touched}
                                      value={formik.values.email}
                                      onChange={formik.handleChange}
                                      error={
                                        formik.touched.email &&
                                        Boolean(formik.errors.email)
                                      }
                                      helperText={
                                        formik.touched.email &&
                                        formik.errors.email
                                      }
                                      disabled={!editmode}
                                    />
                                  </div>
                                  <div
                                    id="errormessage"
                                    className="emailIdError"
                                  ></div>
                                </Grid>

                                {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <label>
                                Country{" "}
                                <span style={{ color: "#ff8d8d" }}>*</span>
                              </label>
                              <div>
                               
                                 <Autocomplete
                       
                        sx={{
                          "& .MuiAutocomplete-popupIndicator": {
                            transform: "none",
                          },
                        }}
                        className="signup-countryInput"
                        // onBlur={formik.handleBlur}
                        onBlur={formik.handleBlur && countrycode}

                        touched={formik.touched}
                        value={formik.values.country}
                        // onChange={formik.handleChange}
                        error={
                          formik.touched.country &&
                          Boolean(formik.errors.country)
                        }
                        helperText={
                          formik.touched.country && formik.errors.country
                        }
                        autoHighlight
                        freeSolo={false}
                        id="country-select-demo"



                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                        }}


                        options={country_search_response?country_search_response:''}
                        getOptionLabel={(option) =>option.name || option}
                        // console.log(option,"option country")
                      //  return option.name || option}}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                            {...props}
                          >
                            {option  ? option.name : ''}
                          </Box>
                        )}
                        // name="country"
                        onChange={(e, value) => {
                          formik.setFieldValue(
                            "country",
                            value ? value.name : ""
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            // label="Select a country"
                            name="country"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "country",
                            }}
                          />
                        )}
                      />
                              </div>
                              <div
                                id="errormessage"
                                className=""
                                style={{ color: "red", fontSize: 12,marginTop:10 }}
                              >
                                {formik.touched.country &&
                                  formik.errors.country}
                              </div>
                            </Grid> */}

                                {/* <Grid item xl={12} lg={12} md={12} sm={12} xs={12}>
                              <label>
                                State{" "}
                                <span style={{ color: "#ff8d8d" }}>*</span>
                              </label>
                              <div>
                              <Autocomplete
                       
                        sx={{
                          "& .MuiAutocomplete-popupIndicator": {
                            transform: "none",
                          },
                        }}
                        className="signup-stateInput"
                        onBlur={formik.handleBlur}
                        touched={formik.touched}
                        value={
                          (formik.values.state = formik.values.country
                            ? formik.values.state
                            : "")
                        }
                       
                        error={
                          formik.touched.state && Boolean(formik.errors.state)
                        }
                        helperText={formik.touched.state && formik.errors.state}
                        autoHighlight
                        freeSolo={false}
                        id="country-select-demo"
                        options={formik.values.country ? mystates : []}
                        getOptionLabel={(option) => option.name || option}
                        renderOption={(props, option) => (
                          <Box
                            component="li"
                            sx={{ "& > img": { flexShrink: 0 } }}
                            {...props}
                          >
                             {console.log(option?option.name:"","options")}
                            {option ? option.name : ""}
                          </Box>
                        )}
                       
                        onChange={(e, value) => {
                          formik.setFieldValue(
                            "state",
                            value ? value.name : ""
                          );
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                           
                            name="state"
                            variant="outlined"
                            inputProps={{
                              ...params.inputProps,
                              autoComplete: "new-password",
                            }}
                          />
                        )}
                      />
                              </div>
                              <div
                                id="errormessage"
                                className=""
                                style={{ color: "red", fontSize: 12,marginTop:10 }}
                              >
                                {formik.touched.state && formik.errors.state}
                              </div>
                            </Grid> */}

                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <label>
                                    Mobile Number
                                    <span style={{color: "#ff8d8d"}}>*</span>
                                  </label>
                                  <div style={{display: "flex"}}>
                                    {/* <TextField
                                    className="profile-edit-country-code"
                                    name="country_code"
                                    // disabled
                                    disabled={!editmode}

                                    onBlur={formik.handleBlur}
                                    touched={formik.touched}
                                    value={formik.values.country_code}
                                    onChange={formik.handleChange}
                                    error={
                                      formik.touched.country_code &&
                                      Boolean(formik.errors.country_code)
                                    }
                                    helperText={
                                      formik.touched.country_code &&
                                      formik.errors.country_code
                                    }
                                  ></TextField> */}

                                    <Autocomplete
                                      sx={{
                                        "& .MuiAutocomplete-popupIndicator": {
                                          transform: "none",
                                        },
                                      }}
                                      className="profile-edit-country-code"
                                      // name="country_code"
                                      disabled={!editmode}
                                      onInput={handleChange}
                                      onBlur={formik.handleBlur}
                                      // onBlur={formik.handleBlur && countrycode}

                                      touched={formik.touched}
                                      value={formik.values.country_code}
                                      // onChange={formik.handleChange}
                                      // onChange={formik.handleChange}
                                      error={
                                        formik.touched.country_code &&
                                        Boolean(formik.errors.country_code)
                                      }
                                      helperText={
                                        formik.touched.country_code &&
                                        formik.errors.country_code
                                      }
                                      autoHighlight
                                      freeSolo={false}
                                      id="country-select-demo"
                                      inputValue={inputValue}
                                      onInputChange={(event, newInputValue) => {
                                        setInputValue(newInputValue);
                                      }}
                                      options={
                                        country_search_response
                                          ? country_search_response
                                          : ""
                                      }
                                      getOptionLabel={option =>
                                        option.name || option
                                      }
                                      // console.log(option,"option country")
                                      //  return option.name || option}}
                                      renderOption={(props, option) => (
                                        <Box
                                          component="li"
                                          sx={{
                                            "& > img": {mr: 2, flexShrink: 0},
                                          }}
                                          {...props}
                                        >
                                          {option.name} ({option.phone_code})
                                        </Box>
                                      )}
                                      // name="country"
                                      onChange={(e, value) => {
                                        formik.setFieldValue(
                                          "country_code",
                                          value ? value.phone_code : ""
                                        );
                                      }}
                                      renderInput={params => (
                                        <TextField
                                          {...params}
                                          // label="Select a country"

                                          error={
                                            formik.touched.country_code &&
                                            Boolean(formik.errors.country_code)
                                          }
                                          helperText={
                                            formik.touched.country_code &&
                                            formik.errors.country_code
                                          }
                                          name="country_code"
                                          variant="outlined"
                                          inputProps={{
                                            ...params.inputProps,
                                            autoComplete: "country_code",
                                          }}
                                        />
                                      )}
                                    />

                                    {/* <div
                                id="errormessage"
                                className=""
                                style={{ color: "red", fontSize: 12,marginTop:10 }}
                              >
                                {formik.touched.country_code &&
                                  formik.errors.country_code}
                              </div> */}

                                    <TextField
                                      className="profile-edit-country-code-phone"
                                      name="mobile_number"
                                      onInput={handleChange}
                                      onBlur={formik.handleBlur}
                                      touched={formik.touched}
                                      value={formik.values.mobile_number}
                                      onChange={formik.handleChange}
                                      error={
                                        formik.touched.mobile_number &&
                                        Boolean(formik.errors.mobile_number)
                                      }
                                      helperText={
                                        formik.touched.mobile_number &&
                                        formik.errors.mobile_number
                                      }
                                      disabled={!editmode}
                                    />
                                  </div>
                                  <div
                                    id="error_phone"
                                    className="mobileError"
                                  ></div>
                                </Grid>

                                <Grid
                                  item
                                  xl={12}
                                  lg={12}
                                  md={12}
                                  sm={12}
                                  xs={12}
                                >
                                  <label>
                                    About
                                    {/* <span style={{ color: "#ff8d8d" }}>*</span> */}
                                  </label>
                                  <div>
                                    <TextField
                                      className="profile-edit-about"
                                      inputProps={{
                                        maxLength: 90,
                                      }}
                                      // onChange={handleChange}
                                      // defaultValue={profileData.about}
                                      name="about"
                                      margin="normal"
                                      onInput={handleChange}
                                      onBlur={formik.handleBlur}
                                      touched={formik.touched}
                                      value={formik.values.about}
                                      onChange={formik.handleChange}
                                      error={
                                        formik.touched.about &&
                                        Boolean(formik.errors.about)
                                      }
                                      helperText={
                                        formik.touched.about &&
                                        formik.errors.about
                                      }
                                      disabled={!editmode}
                                    />
                                  </div>
                                  <div
                                    id="errormessage"
                                    className="emailIdError"
                                  ></div>
                                </Grid>
                                <div
                                  style={{
                                    display: "flex",
                                    margin: "auto",
                                    fontWeight: "bold",
                                  }}
                                >
                                  <span style={{color: "green"}}>
                                    {responseMessage}
                                  </span>
                                  <span style={{color: "red"}}>
                                    {responseMessageError}
                                  </span>
                                </div>
                                {/* {editmode && (
                                <div className="profile-edit-save-btn-container"> */}
                                {/* <Button
                                component={Link}
                                to="/admin/profile"
                                className="profile-edit-save-btn"
                              >
                                BACK
                              </Button> */}
                                {/* <button className="profile-edit-save-btn">
                                    SAVE
                                  </button>
                                </div>
                              )} */}
                                {/* <div className="profile-edit-save-btn-container">
                             
                              <Button
                                component={Link}
                                to="/profile"
                                className="profile-edit-save-btn"
                              >
                                BACK
                              </Button>
                             
                              <Button className="profile-edit-save-btn">
                                SAVE
                              </Button>
                           
                            </div> */}
                                {/* <div style={{ display: "flex", margin: "auto" }}>
                              <span style={{ color: "green" }}>
                                {responseMessage}
                              </span>
                            </div> */}
                              </Grid>
                            </Container>

                            <Grid container spacing={2}>
                              <Grid
                                xl={5}
                                lg={5}
                                md={5}
                                sm={5}
                                xs={5}
                                // className="profile-edit-outer"
                                style={{
                                  height: "200px",
                                  width: "100%",
                                  marginTop: "36px",
                                  marginBottom: "30px !important",
                                }}
                              >
                                {/* {file?  <span>
                          <Icon   onClick={()=> setFile(null)  }
                                      icon="iconoir:cancel" */}
                                {/* className="profieedit-profile-icon"
                                    /> */}
                                {/* <AddCircleIcon
                                onClick={()=> setFile(null)  }
                                onBlur={()=> formik.setFieldValue(
                                  "profile_picture",
                                 null
                                )}
                                className="plusIcon"
                              ></AddCircleIcon>
                              remove front */}
                                {/* </span>:null} */}
                                <div
                                  // className="profile-edit-inner"
                                  style={{
                                    height: "100%",
                                    width: "94%",
                                    background: "#1ec28b",
                                    // margin: "auto",
                                    borderRadius: "100%",
                                    marginLeft: "175px",
                                  }}
                                >
                                  <img
                                    style={{
                                      height: "161px",
                                      width: "80%",
                                      marginTop: "19px",
                                      borderRadius: "inherit",
                                    }}
                                    // className="profileEditImg"
                                    variant="top"
                                    src={
                                      file != null
                                        ? URL.createObjectURL(file)
                                        : formik.values.profile_picture
                                        ? formik.values.profile_picture
                                        : "../Assets/Images/Favicon 25 WB _ DV.webp"
                                    }
                                    alt="..."
                                  />
                                </div>
                              </Grid>
                              <Grid
                                item
                                xl={7}
                                lg={7}
                                md={7}
                                sm={7}
                                xs={7}
                                className="changePhoto"
                              >
                                <Button
                                  onClick={handleOpen}
                                  style={{
                                    marginTop: "100px",
                                    backgroundColor: "whitesmoke",
                                    color: "back !important",
                                    marginLeft: "-38px",
                                    textTransform: "initial",
                                  }}
                                >
                                  {/*<InsertPhotoOutlinedIcon className="plusIcon"></InsertPhotoOutlinedIcon>*/}

                                  <Icon
                                    icon="solar:gallery-round-linear"
                                    style={{color: "#636363"}}
                                    width={18}
                                    height={18}
                                  />
                                  <span className="changePhotoBtn">
                                    {" "}
                                    <span style={{textTransform: "capitalise"}}>C</span>hange picture
                                  </span>
                                </Button>
                                {/* </label> */}
                              </Grid>
                              <Modal
                                open={open}
                                onClose={handleClose}
                                aria-labelledby="modal-modal-title"
                                aria-describedby="modal-modal-description"
                              >
                                <Box className="profileedit-popupbackground">
                                  <Grid
                                    container
                                    spacing={2}
                                    className="profileedit-main-heading"
                                  >
                                    <Grid
                                      item
                                      xl={10}
                                      lg={10}
                                      md={10}
                                      sm={10}
                                      xs={10}
                                      className="profileedit-heading"
                                    >
                                      <div>Profile Image Update/Delete</div>
                                    </Grid>
                                    <Grid
                                      item
                                      xl={2}
                                      lg={2}
                                      md={2}
                                      sm={2}
                                      xs={2}
                                    >
                                      <span
                                        onClick={handleClose}
                                        className="profiledit-main-icon"
                                      >
                                        <Icon
                                          icon="iconoir:cancel"
                                          className="profieedit-profile-icon"
                                        />
                                      </span>
                                    </Grid>
                                  </Grid>

                                  <div>
                                    {" "}
                                    <Grid container spacing={2}>
                                      <Grid
                                        xl={12}
                                        lg={12}
                                        md={12}
                                        sm={12}
                                        xs={12}
                                        className="profilepopup-editpopup-outer"
                                      >
                                        <div className="profilepopup-editpopup-inner">
                                          <div className="profilepopupEditImg-con">
                                            <img
                                              className="profilepopupEditImg"
                                              variant="top"
                                              src={
                                                file != null
                                                  ? URL.createObjectURL(file)
                                                  : formik.values
                                                      .profile_picture
                                                  ? formik.values
                                                      .profile_picture
                                                  : "../Assets/Images/Favicon 25 WB _ DV.webp"
                                              }
                                              alt="..."
                                            />
                                          </div>
                                        </div>
                                      </Grid>
                                    </Grid>
                                  </div>
                                  <Grid container spacing={2} className="">
                                    {file ? (
                                      <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        md={6}
                                        xs={6}
                                        sm={6}
                                        className="profileedit-btnpart"
                                      >
                                        <Button
                                          onClick={() => setFile(null)}
                                          className="profiledit-removephoto"
                                          // style={{visibility: formik.values.profile_picture?"":'hidden'}}
                                        >
                                          <CloseIcon className="profile-popup-edit-changeicon"></CloseIcon>{" "}
                                          Cancel
                                        </Button>
                                      </Grid>
                                    ) : (
                                      <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        md={6}
                                        xs={6}
                                        sm={6}
                                        className="profileedit-btnpart"
                                      >
                                        <Button
                                          onClick={() => deletePost()}
                                          className="profiledit-removephoto"
                                          activeOpacity={0.2}
                                          style={{
                                            opacity:
                                              formik.values.profile_picture ==
                                              null
                                                ? 0.5
                                                : 1.0,
                                            disabled:
                                              formik.values.profile_picture ==
                                              null
                                                ? true
                                                : false,
                                          }}
                                          // style={{visibility: formik.values.profile_picture==null?"hidden":''}}
                                          disabled={
                                            formik.values.profile_picture ==
                                            null
                                              ? true
                                              : false
                                          }
                                        >
                                          <span>
                                            <Icon
                                              icon="material-symbols:delete-outline"
                                              className="profile-popup-edit-changeicon"
                                            />
                                          </span>
                                          &nbsp; Remove Photo
                                        </Button>
                                      </Grid>
                                    )}
                                    {file ? (
                                      <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        md={6}
                                        xs={6}
                                        sm={6}
                                        className="profileedit-btnpart"
                                        // style={{margin: formik.values.profile_picture?"":'auto'}}
                                      >
                                        <label htmlFor="icon-button-photo">
                                          <Button
                                            onClick={() => uploadpicture()}
                                            variant="text"
                                            component="span"
                                            className="profilepopupedit-addphoto"
                                          >
                                            {/* <span>
                                        <Icon
                                          icon="mdi:image-add"
                                          className="profile-popup-edit-changeicon"
                                        />
                                      </span> */}
                                            <FileUploadIcon className="profile-popup-edit-changeicon"></FileUploadIcon>{" "}
                                            Upload
                                          </Button>
                                        </label>

                                        {/* </div>  */}
                                      </Grid>
                                    ) : (
                                      <Grid
                                        item
                                        xl={6}
                                        lg={6}
                                        md={6}
                                        xs={6}
                                        sm={6}
                                        className="profileedit-btnpart"
                                        // style={{margin: formik.values.profile_picture?"":'auto'}}
                                      >
                                        <input
                                          style={{display: "none"}}
                                          id="icon-button-photo"
                                          type="file"
                                          accept="image/*"
                                          name="profile_picture"
                                          onChange={event => {
                                            console.log(
                                              event.target.files[0],
                                              "data of file"
                                            );
                                            // formik.setFieldValue(
                                            //   "profile_picture",
                                            //   event.target.files[0]
                                            // );
                                            setFile(event.target.files[0]);
                                          }}
                                        />
                                        <label htmlFor="icon-button-photo">
                                          <Button
                                            variant="text"
                                            component="span"
                                            className="profilepopupedit-addphoto"
                                          >
                                            <span>
                                              <Icon
                                                icon="mdi:image-add"
                                                className="profile-popup-edit-changeicon"
                                              />
                                            </span>
                                            &nbsp; Change Photo
                                          </Button>
                                        </label>

                                        {/* </div>  */}
                                      </Grid>
                                    )}
                                  </Grid>
                                </Box>
                              </Modal>
                            </Grid>
                          </div>
                          {editmode && (
                            <div
                              className="profile-edit-save-btn-container"
                              style={{paddingBottom: "20px"}}
                            >
                              {/* <Button
                                component={Link}
                                to="/admin/profile"
                                className="profile-edit-save-btn"
                              >
                                BACK
                              </Button> */}
                              <button className="profile-edit-save-btn">
                                SAVE
                              </button>
                            </div>
                          )}
                        </div>

                        {/* </div>  */}
                      </Form>
                    );
                  }}
                </Formik>
              ) : null}
            </div>
          </div>
          {/* </Container> */}
        </Container>
      </div>
    </>
  );
};

export default MyProfile;