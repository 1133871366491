import React from "react";
import "../../CSS/contactUs.css";
import {
  Autocomplete,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  inputLabelClasses,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
  Typography,
} from "@mui/material";
import { Controller } from "react-hook-form";
import Captcha from "demos-react-captcha";
import { useState } from "react";
import { useEffect } from "react";
import { Container } from "@mui/system";
import { Icon } from "@iconify/react";
import axios from "axios";

function ContactForm(props) {
  const {
    control,
    values,
    setValue,
    errors,
    register,
    reset,
    resetField,
  } = props;
  const [mycountry, setCountries] = useState([]);
  const [state, setState] = useState([]);
  const [phonecode, setPhonecode] = useState("");

  // useEffect(() => {
  //   const data = require("../../json/Country-State-City.json");
  //   console.log("data", data);
  //   setCountries(data);
  // }, []);


  const [inputValue, setInputValue] = React.useState();
  const [country_search_response, setcountry_search_response] = useState([]);



const countrystatepincode=()=>{
  console.log("dashboard env", process.env.REACT_APP_LOCAL_API);
  let domain = "";
  console.log(process.env, "domain env", window.location.hostname);
  if (window.location.hostname.trim() === "localhost") {
    domain = process.env.REACT_APP_LOCAL_API;
  } else if (window.location.hostname.trim() === "54.185.6.32") {
    domain = process.env.REACT_APP_UAT_API;
  } else if (window.location.hostname.trim() === "uat.desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_UAT_API;
  }else if (window.location.hostname.trim() === "desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_API;
  }else if (window.location.hostname.trim() === "www.desitravelcompanion.com") {
    domain = process.env.REACT_APP_PROD_API;
  }
  console.log(domain, "domain env end");

  axios.get( `${domain}/country-state?country_name=${inputValue}
  `).then(res=>{
    console.log(res,"c response")
    if(res.data.response){
      setCountries(res.data.response)}
  }
)
}

 useEffect(() => {
    // document.title =  "Desi Travel Companion-signup";
   countrystatepincode();

  }, [inputValue]);




  return (
    <Container className="contactFormPage">
      <Grid container spacing={2}>
        <Grid item xl={6} lg={12} md={6} sm={12} xs={12}>
          <label className="contactFormfullNameText">
            Name<span style={{ color: "#ff8d8d" }}>*</span>
          </label>

          <Controller
            name="name"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <TextField
                onChange={onChange}
                value={value}
                name={name}
                className="contactFormfullNameInput"
                variant="standard"
                inputProps={{
                  maxLength: 32,
                  
                }}
                
                // color="warning"
                // focused
                // inputProps={{ style: { fontFamily: 'Arial', color: 'white'}}}
              />
            )}
          />

          <div style={{ color: "red" }}>
            {errors.name && errors.name.message}
          </div>
        </Grid>

        <Grid item xl={6} lg={12} md={6} sm={12} xs={12}>
          <div className="col-md-6">
            <div className="form-group">
              <label className="contactFormText">
                Country<span style={{ color: "#ff8d8d" }}>*</span>
              </label>
              <Controller
                control={control}
                name="country"
                render={({ field: { onChange, value, name } }) => (
                  <Autocomplete
                    popupIcon={
                      <Icon
                        icon="gridicons:dropdown"
                        style={{ color: "#ff8d8d", fontSize: 30 }}
                      />
                    }

                    inputValue={inputValue}
                    onInputChange={(event, newInputValue) => {
                      setInputValue(newInputValue);
                    }}
                    
                    options={mycountry}
                    getOptionLabel={(option) => option['name'] || option }
                    onChange={(event, item) => {
                      console.log(item, "countyItem");
                      if (item !== null) {
                        setState(item.states);

                        setValue("country_code", item.phone_code, {
                          shouldValidate: true,
                        });
                        console.log(item.phone_code, "countyItem");
                        setValue("country", item?item['name']:'',{shouldValidate: true});
                      }
                    }}
                    // value={value}
                    value={value ? value : null}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        className="contactFormInput"
                        {...params}
                        InputLabelProps={{
                          sx: {
                            color: "black",
                            [`&.${inputLabelClasses.shrink}`]: {
                              color: "black",
                            },
                          },
                        }}
                      />
                    )}
                  />
                )}
              />
            </div>
          </div>
          <div style={{ color: "red" }}>
            {errors.country && errors.country.message}
          </div>
        </Grid>

        <Grid item xl={6} lg={12} md={6} sm={12} xs={12}>
          <div className="col-md-6">
            <div className="form-group">
              <label className="contactFormText">
                State/Province<span style={{ color: "#ff8d8d" }}>*</span>
              </label>
              <Controller
                control={control}
                name="state"
                render={({ field: { onChange, value, name } }) => (
                  <Autocomplete
                    popupIcon={
                      <Icon
                        icon="gridicons:dropdown"
                        style={{ color: "#ff8d8d", fontSize: 30 }}
                      />
                    }
                    options={state}
                    getOptionLabel={(option) => option['name'] || option}
                    onChange={(event, item) => {
                      console.log(item, "countyItem");
                      setValue("state", item?item['name']:'',{shouldValidate: true});
                    }}
                    // value={value}
                    value={value ? value : null}
                    renderInput={(params) => (
                      <TextField
                        variant="standard"
                        className="contactFormInput"
                        {...params}
                        InputLabelProps={{
                          sx: {
                            // set the color of the label when not shrinked
                            color: "black",
                            [`&.${inputLabelClasses.shrink}`]: {
                              // set the color of the label when shrinked (usually when the TextField is focused)
                              color: "black",
                            },
                          },
                        }}
                        // inputProps={{
                        //   ...params.inputProps,
                        //   autoComplete: "new-password", // disable autocomplete and autofill
                        // }}
                      />
                    )}
                  />
                )}
              />
            </div>
          </div>
          <div style={{ color: "red" }}>
            {errors.state && errors.state.message}
          </div>
        </Grid>

        <div className="contactFormPhoneNumber">
          <Grid item xl={2} lg={2} md={2} sm={3} xs={3}>
            <label className="contactFormCodeText">
              Code<span style={{ color: "#ff8d8d" }}>*</span>
            </label>
            <Controller
              name="country_code"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  // label="code"
                  // placeholder="code"
                  name={name}
                  variant="standard"
                  color="success"
                  className="contactFormCode"
                  disabled
                  // sx={{ width: 70, marginLeft: -12 }}
                  // inputProps={{
                  //   maxLength: 500,
                  // }}
                />
              )}
            />
          </Grid>

          <Grid item xl={10} lg={10} md={10} sm={9} xs={9}>
            <label style={{ display: "flex" }}>
              Phone Number<span style={{ color: "#ff8d8d" }}>*</span>
            </label>
            <Controller
              name="phone_number"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <TextField
                  onChange={onChange}
                  value={value}
                  // label="Phone Number "
                  className="contactFormPhoneInput"
                  variant="standard"
                  color="success"
                />
              )}
            />

            <div style={{ color: "red", transform: "translate(-72px, 10px)" }}>
              {errors.phone_number && errors.phone_number.message}
            </div>
          </Grid>

        </div>
   <Grid item xl={6} lg={12} md={6} sm={12} xs={12}>
          <label className="contactFormfullNameText">
            Email<span style={{ color: "#ff8d8d" }}>*</span>
          </label>

          <Controller
            name="email"
            control={control}
            render={({ field: { onChange, value, name } }) => (
              <TextField
                onChange={onChange}
                value={value}
                name={name}
                className="contactFormfullNameInput"
                variant="standard"
                inputProps={{
                  maxLength: 32,
                  
                }}
                
                // color="warning"
                // focused
                // inputProps={{ style: { fontFamily: 'Arial', color: 'white'}}}
              />
            )}
          />

          <div style={{ color: "red" }}>
            {errors.email && errors.email.message}
          </div>
        </Grid>
        <Grid item xl={6} lg={12} md={6} sm={12} xs={12}>
          <div>
            <Controller
              name="feedback"
              control={control}
              render={({ field: { onChange, value, name } }) => (
                <TextareaAutosize
                  name="feedback"
                  onChange={onChange}
                  aria-label="description"
                  minRows={3}
                  placeholder="Share your Query or Feedback...."
                  className="contactFormShortDescText"
                  value={value}
                  maxLength="500"
                  inputProps={{
                    maxLength: 500,
                  }}
                />
              )}
            />
          </div>
          <div style={{ color: "red" }}>
            {errors.feedback && errors.feedback.message}
          </div>
          {/* <div className="contactFormShortDescMaxChar">
            <span>*</span>maximum of 500 characters
          </div> */}
        </Grid>
     
        <Grid
          item
          xl={12}
          lg={12}
          md={12}
          sm={12}
          xs={12}
          className="contact-Captcha"
        >
          <Typography
            color={errors.acceptTerms ? "error" : "inherit"}
            className="contact-CaptchaLabel"
          >
            Captcha
          </Typography>

          <FormControlLabel
            className="contact-Captchacontent"
            control={
              <Controller
                control={control}
                name="acceptTerms"
                defaultValue="false"
                inputRef={register()}
                render={({ field: { onChange, value } }) => (
                  // <Checkbox
                  //   color="primary"
                  //   onChange={(e) => onChange(e.target.checked)}
                  // />
                  <Captcha
                    onChange={(e) => onChange(e)}
                    value={value}
                    placeholder="Enter Captcha here"
                    length={6}
                  />
                )}
              />
            }
          />
          <br />
        </Grid>
      </Grid>
      <Typography variant="inherit" color="red">
        {errors.acceptTerms ? errors.acceptTerms.message : ""}
      </Typography>
    </Container>
  );
}
export default ContactForm;